import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  API_URL,
  axiosApiInstanceGet,
  FETCH_STATUS,
  onReturnReject,
} from "../../utils";

export const getMigrationStatus = createAsyncThunk(
  "getMigrationStatus",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    let force = true;
    try {
      const response = await axiosApiInstanceGet(
        ` ${API_URL}/FeatureManagements/GetOfficeMigrationFeatureStatus`,
        force
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(onReturnReject(error));
    }
  }
);

const initialState = {
  migrationStatus: {},
  status: FETCH_STATUS.IDLE,
  error: null,
  showModal: false,
};

export const migrationSlice = createSlice({
  name: "migration",
  initialState,
  reducers: {
    resolveMigrationStatus: (state) => {
      state.status = FETCH_STATUS.RESOLVED;
      state.error = null;
    },
    setShowModal: (state, action) => {
      state.showModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMigrationStatus.pending, (state) => {
        state.status = FETCH_STATUS.PENDING;
        state.error = "";
      })
      .addCase(getMigrationStatus.fulfilled, (state, action) => {
        state.status = FETCH_STATUS.RESOLVED;
        state.error = "";
        state.migrationStatus = action.payload;
      })
      .addCase(getMigrationStatus.rejected, (state, action) => {
        state.status = FETCH_STATUS.REJECTED;
        state.error = action.payload;
      });
  },
});

export const selectMigration = (state) => ({
  migrationStatus: state.migration.migrationStatus,
  status: state.migration.status,
  error: state.migration.error,
  showModal: state.migration.showModal,
});

export const { resolveMigrationStatus, setShowModal } = migrationSlice.actions;
