import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  PrivateRoute,
  PageHeader,
  SuccessModal,
  Loading,
  Success,
  ErrorWithWrapper,
  FormError,
  CancelSaveToolbar,
  Input,
  StaffInfo,
  Spinner,
} from "../../../components";

import { useRequest } from "../../../hooks";
import { getUserById } from "../../../redux/slices";

import {
  FETCH_STATUS,
  onReject,
  API_URL,
  axiosApiInstance,
  createPinSchema,
  createPinInit,
} from "../../../utils";

const wrapperClass = "grid-cols-[40%_minmax(0,1fr)] gap-4";

const PinCreate = () => {
  let params = useParams();
  const { err, status, data, setResolved, setPending, setRejected } =
    useRequest();

  const {
    err: editErr,
    status: editStatus,
    setPending: editSetPending,
    setRejected: editSetRejected,
    setResolved: editSetResolved,
    resetErr,
  } = useRequest({});

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(createPinSchema),
    defaultValues: createPinInit,
  });

  const getInfo = useCallback(async () => {
    if (params?.id) {
      try {
        setPending();
        const res = await getUserById(params.id);

        setResolved(res.data.data);
        reset({
          ...createPinInit,
          userId: params.id,
        });
      } catch (error) {
        onReject(
          setRejected,
          error,
          `Unable to retrieve user with id ${params?.id}`
        );
      }
    }
  }, [params?.id, setRejected, setResolved, reset, setPending]);

  const onSubmit = async (values) => {
    try {
      editSetPending();
      const res = await axiosApiInstance.post(
        `${API_URL}/UserPins/Create`,
        values
      );

      editSetResolved(res.data.data);
    } catch (error) {
      onReject(editSetRejected, error, "Unable to create pin for user");
    }
  };

  const restrictToNumber = (e) => {
    const event = { ...e };

    event.target.value = event.target.value.replace(/\D/g, "").substr(0, 6);

    return event;
  };

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  return (
    <PrivateRoute redirectTo={`/dashboard/users/create-pin/${params?.id}`}>
      <PageHeader>
        <h1 className="text-2xl font-semibold text-white ">Create user pin</h1>
      </PageHeader>

      <Loading
        wrapperClassName="relative min-h-[50vh]"
        show={status === FETCH_STATUS.PENDING}
      />

      <ErrorWithWrapper
        error={err}
        show={status === FETCH_STATUS.REJECTED}
        retry={getInfo}
        className="!max-w-md !mx-auto"
      />

      <Success show={status === FETCH_STATUS.RESOLVED}>
        <form
          autoComplete="off"
          className="relative pb-32"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormError
            className="absolute top-0 z-20 transform left-2/4 -translate-x-2/4"
            err={editErr}
            onDismiss={resetErr}
          />
          <CancelSaveToolbar hideSubmit backPath={"/dashboard/users"} />
          <section className="relative w-full px-3 py-8 bg-app-purple-2 grid grid-cols-2 gap-2">
            <StaffInfo user={data} />

            <div className="space-y-6">
              <Input
                label="Enter pin:"
                id="pin"
                autoComplete="new-password"
                wrapperClass={wrapperClass}
                isPwd
                errorMsg={errors?.pin?.message}
                {...register("pin", {
                  onChange: (e) => restrictToNumber(e),
                })}
              />

              <Input
                label="Re-enter pin:"
                id="pinConfirmation"
                autoComplete="new-password"
                wrapperClass={wrapperClass}
                isPwd
                errorMsg={errors?.pinConfirmation?.message}
                {...register("pinConfirmation", {
                  onChange: (e) => restrictToNumber(e),
                })}
              />

              <div className="flex items-center justify-center">
                <button
                  type="submit"
                  disabled={status === FETCH_STATUS.PENDING}
                  className="p-2 rounded min-w-[92px] text-app-yellow bg-app-purple-4 disabled:opacity-30 inline-flex items-center justify-center space-x-2"
                >
                  <span>Save</span>
                  <Spinner
                    className="!w-3 !h-3 text-white fill-app-yellow"
                    status={editStatus}
                  />
                </button>
              </div>
            </div>
          </section>
        </form>
      </Success>

      <SuccessModal
        message="Created user pin successfully."
        show={editStatus === FETCH_STATUS.RESOLVED}
        backLink={"/dashboard/users"}
      />
    </PrivateRoute>
  );
};

export { PinCreate };
