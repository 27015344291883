import {useMemo, useState} from "react";
import {
  AcceptanceConfirmation,
  AcceptanceSuccess,
  ApprovePurchaseOrder,
  ClientPaginatedTable,
  DefaultColumnFilter,
  PrivateRoute,
  Spinner,
} from "../../../../components";
import {DateTimeInput} from "../../../../components/Films/DateTimeInput";
import DatePicker from "react-datepicker";
import addDays from "date-fns/addDays";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getPurchaseOrder, getPurchaseOrders, selectUser, stockData,} from "../../../../redux/slices";

const ActionBtn = ({ label, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={
        "p-2 px-3 rounded-md text-white bg-app-purple-8 hover:font-bold ml-2"
      }
    >
      {label}
    </button>
  );
};

const StatusIndicator = ({ status }) => {
  const colourCode = {
    Completed: { txt: "text-[#007F00]", bg: "bg-[#CDFFCD]" },
    Approved: { txt: "text-[#007F00]", bg: "bg-[#CDFFCD]" },
    Rejected: { txt: "text-[#D30000]", bg: "bg-[#FFE0E0]" },
    Pending: { txt: "text-[#965E00]", bg: "bg-[#FFECCC]" },
    Draft: { txt: "text-[#4A4AFF]", bg: "bg-[#E6E6F2]" },
  };

  return (
    <div
      className={`flex items-center px-3 py-1 justify-center rounded-md ${colourCode[status]?.txt} ${colourCode[status]?.bg} `}
    >
      <span>{status}</span>
    </div>
  );
};

const PurchaseOrdOverview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    purchaseOrders,
    purchaseOrdersStatus,
    stockRequestErr,
    stockRequest,
  } = useSelector(stockData);
  const [startDate, setStartDate] = useState(
    addDays(new Date(), -30).toISOString()
  );

  const {profile} = useSelector(selectUser)

  const [showAcceptanceConfirmation, setShowAcceptanceConfirmation] =
    useState(false);
  const [showAcceptanceSuccess, setShowAcceptanceSuccess] = useState(false);
  const [showApproveOrReject, setShowApproveOrReject] = useState(false);
  const [data, setData] = useState("");
  const [endDate, setEndDate] = useState(new Date().toISOString());
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      getPurchaseOrders({ DateFrom: startDate, DateTo: endDate, force: true, cinemaId: profile.cinemaId })
    );
  };

  const purchaseOrderData = useMemo(() => {
    if (purchaseOrders?.length) {
      return purchaseOrders;
    } else {
      return [];
    }
  }, [purchaseOrders]);

  const columns = useMemo(() => {
    return [
      {
        Header: "Order Id",
        accessor: "id",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Confirmation Date",
        accessor: "confirmationDate",
        Cell: ({ value }) => {
          return value ? new Date(value).toDateString() : "N/A";
        },
      },
      {
        Header: "Vendor",
        accessor: "vendor",
      },
      {
        Header: "Request Id",
        accessor: "stockRequestId",
      },
      {
        Header: "Purchase Rep",
        accessor: "location",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          return <StatusIndicator status={value} />;
        },
      },
      {
        Header: "Actions",

        Cell: ({ value, row }) => {
          return (
            <div className="flex justify-around">
              {" "}
              <ActionBtn
                label={"View"}
                onClick={() => {
                  navigate(`/dashboard/stock/purchaseOrder/${row.original.id}`);
                }}
              />
              {row.original.status === "Pending" && (
                <ActionBtn
                  label={"Approve"}
                  onClick={() => {
                    setShowApproveOrReject(true);
                    setData(row.original.id);
                    dispatch(
                      getPurchaseOrder({ force: true, id: row.original.id })
                    );
                  }}
                />
              )}
            </div>
          );
        },
      },
    ];
  }, []);
  return (
    <PrivateRoute redirectTo="/dashboard/stock/stockRequest">
      <p className="font-bold px-3">Purchase Order</p>
      <div className="w-[95%] mx-auto mt-6 ">
        <p className="text-center text-[20px] p-3">Select Date Range</p>
        <form onSubmit={submitHandler}>
          <div className="flex justify-center">
            <div className=" pb-3.5 ">
              <label
                htmlFor={`value`}
                className="mb-2 text-md font-bold text-[#666666] "
              >
                From
              </label>
              <DatePicker
                placeholderText="Expiry Date"
                selected={new Date(startDate)}
                onChange={(value) => setStartDate(value.toISOString())}
                customInput={
                  <DateTimeInput
                    className="bg-app-purple-8 w-[8rem] md:w-[10rem]"
                    dateFormat={"EEE, MMM dd, yyyy"}
                    value={startDate}
                  />
                }
              />
            </div>
            <div className=" pb-3.5 ">
              <label
                htmlFor={`value`}
                className="mb-2 text-md font-bold text-[#666666] "
              >
                To
              </label>
              <DatePicker
                placeholderText="Expiry Date"
                selected={new Date(endDate)}
                onChange={(value) => setEndDate(value.toISOString())}
                customInput={
                  <DateTimeInput
                    className="bg-app-purple-8 w-[8rem] md:w-[10rem]"
                    dateFormat={"EEE, MMM dd, yyyy"}
                    value={endDate}
                  />
                }
              />
            </div>
          </div>
          <div className="flex justify-center">
            <button
              className=" mb-[30px] p-1 px-3 flex items-center justify-between rounded-md text-white bg-[#7E2080] shadow-sm hover:shadow-xl hover:text-white"
              type="submit"
            >
              Get Purchase Order
              <Spinner
                className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                status={purchaseOrdersStatus}
              />
            </button>
          </div>
        </form>
        <div className="flex items-center justify-end p-4 px-8 rounded-t-lg bg-gradient-to-b from-app-purple-4 to-app-purple-3"></div>
        <div className="overflow-x-auto">
          <ClientPaginatedTable
            filterColumnsKeys={["code"]}
            //   toolbarChildren={}
            // onRowClick={onRowClick}
            tableData={purchaseOrderData}
            columns={columns}
            getHeaderProps={(column) => ({
              className:
                "px-3 pt-8 pb-2 text-base font-medium border border-[#00000033] ",
              ...(column.id === "film" ? column?.getSortByToggleProps() : {}),
            })}
            getCellProps={(cellInfo) => ({
              style: {},
              className: `${
                cellInfo?.column?.id === "film" ? "text-app-purple-9" : ""
              } ${cellInfo?.column?.id === "days" ? "break-all" : ""} `,
            })}
            getColumnProps={() => ({ className: "bg-app-purple-2", style: {} })}
          />
        </div>
      </div>
      <AcceptanceConfirmation
        show={showAcceptanceConfirmation}
        type={"Purchase Order"}
        data={data}
        onClose={() => {
          setShowAcceptanceConfirmation(false);
        }}
        onConfirm={() => {
          setShowAcceptanceConfirmation(false);
          setShowAcceptanceSuccess(true);
        }}
      />

      <AcceptanceSuccess
        show={showAcceptanceSuccess}
        data={data}
        type={"Purchase Order"}
        onClose={() => {
          setShowAcceptanceSuccess(false);
          setShowApproveOrReject(false);
        }}
      />

      <ApprovePurchaseOrder
        show={showApproveOrReject}
        onClose={() => {
          setShowApproveOrReject(false);
          setData({});
        }}
        approveFunc={() => {
          setShowAcceptanceConfirmation(true);
        }}
      />
    </PrivateRoute>
  );
};
export { PurchaseOrdOverview };
