import React from "react";

const CreateSessionErr = ({ onDismiss, errors = [] }) => {
  if (!errors.length) {
    return null;
  }

  const longErrorStyle = "bg-red-300 mt-[35rem] relative rounded-md p-5";
  const shortErrorStyle = "bg-red-300 relative rounded-md p-5";

  return (
    <div className="fixed p-4 overflow-auto flex items-center justify-center top-0 left-0 right-0 bottom-0 z-0 bg-slate-500/25">
      <div
        className={`${errors.length > 9 ? longErrorStyle : shortErrorStyle}`}
      >
        <button
          type="button"
          onClick={onDismiss}
          className="absolute right-0 top-0  text-gray-400 bg-white hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 m-1 inline-flex items-center pointer-events-auto"
        >
          <svg
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>

        <br />

        <ol className="text-sm font-normal divide-y">
          {errors.map((err, ind) => (
            <li key={err.id} className="w-full  px-4 py-2">
              {ind + 1}) {JSON.stringify(err.message)}
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default CreateSessionErr;
