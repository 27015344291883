import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Loading,
  SessionsTable,
  SessionsSummary,
  UpdateSessionModal,
  PrivateRoute,
  ErrorWithWrapper,
  SuccessModal,
} from "../../../components";
import {
  getAllPaymentChannel,
  getStaffSessions,
  selectFinance,
  selectStaffSessions,
} from "../../../redux/slices";
import { FETCH_STATUS } from "../../../utils";
import { useLoggedInuser } from "../../../hooks";

const StaffSessions = () => {
  const [activeSession, setActiveSession] = useState(null);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const {
    profile: { cinemaId },
  } = useLoggedInuser();
  const dispatch = useDispatch();
  const { staffSessions, staffSessionsStatus, staffSessionsErr } =
    useSelector(selectStaffSessions);
  const { allPaymentChannels, allPaymentChannelStatus } =
    useSelector(selectFinance);

  const getInfo = useCallback(
    (force, start, end) => {
      dispatch(getStaffSessions({ force, start, end }));
    },
    [dispatch]
  );

  const handleSuccess = useCallback(() => {
    setShowModalSuccess(false);
    setActiveSession(null);
  }, []);

  useEffect(() => {
    if (staffSessionsStatus === FETCH_STATUS.IDLE) {
      dispatch(getStaffSessions());
    }
  }, [dispatch, staffSessionsStatus]);
  useEffect(() => {
    if (allPaymentChannelStatus === FETCH_STATUS.IDLE) {
      dispatch(getAllPaymentChannel(cinemaId));
    }
  }, [allPaymentChannelStatus, dispatch]);

  return (
    <PrivateRoute redirectTo="/dashboard/sessions">
      <div className="-mt-2">
        <div className="flex items-center justify-between mb-5">
          <h3 className="text-lg font-medium text-black md:text-2xl ">
            Staff Sessions
          </h3>
        </div>

        <div className="bg-white rounded-xl px-6 py-4 relative">
          <h3 className="text-lg font-medium text-black my-1 ">Cashout</h3>

          <ErrorWithWrapper
            error={staffSessionsErr}
            show={staffSessionsStatus === FETCH_STATUS.REJECTED}
            retry={getInfo}
          />

          <div className="relative ">
            <Loading
              wrapperClassName="absolute top-0 left-0 right-0 min-h-[50vh] !z-[1000000000000]"
              show={staffSessionsStatus === FETCH_STATUS.PENDING}
            />

            <SessionsSummary data={staffSessions} />
            <SessionsTable
              setItem={setActiveSession}
              info={staffSessions}
              getSessions={getInfo}
            />

            {activeSession ? (
              <UpdateSessionModal
                onSuccess={() => setShowModalSuccess(true)}
                onCancel={() => setActiveSession(null)}
                activeSession={activeSession}
                channels={allPaymentChannels??[]}
              />
            ) : null}

            <SuccessModal
              message="Updated session"
              show={showModalSuccess}
              onClick={handleSuccess}
            />
          </div>
        </div>
      </div>
    </PrivateRoute>
  );
};

export { StaffSessions };
