import React from "react";
import { Transition } from "@headlessui/react";

const FormError = ({ err, className, onDismiss = () => {} }) => {
  return (
    <Transition
      show={!!err}
      as={React.Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 "
      enterTo="transform opacity-100 "
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 "
      leaveTo="transform opacity-0 "
    >
      <div
        className={`flex items-center w-full max-w-[90vw]  phone-lg:max-w-xs p-4 mx-auto mb-4 text-gray-500 bg-white rounded-lg shadow ${className}`}
        role="alert"
      >
        <button
          type="button"
          onClick={onDismiss}
          className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg"
        >
          <svg
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <div className="ml-3 max-h-64 overflow-auto text-sm font-normal break-all">
          {JSON.stringify(err)}
        </div>
      </div>
    </Transition>
  );
};

export { FormError };
