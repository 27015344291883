import { useEffect, useRef, useState } from "react";
import {
  PrivateRoute,
  Spinner,
  ItemFormErrorModal as FormErrorModal,
  SuccessModal,
  FormError,
} from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  reportData,
  getScheduledReportById,
  getReportTypes,
  getReportFileFormats,
  getReportFrequencyType,
  getDistributors,
  getDistributorFilms,
  createScheduledReport,
  updateScheduledReport,
  getScheduledReports,
} from "../../../redux/slices";
import {
  FETCH_STATUS,
  scheduleReportSchema,
  scheduleReportInit,
} from "../../../utils";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLoggedInuser } from "../../../hooks";

const ScheduleReportForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    scheduledReport,
    scheduledReportErr,
    scheduledReportStatus,
    reportTypes,
    reportFileFormats,
    reportFrequencyTypes,
    reportTypesStatus,
    reportFileFormatsStatus,
    reportFrequencyTypeStatus,
    distributorsList,
    distributorFilms,
    createScheduleStatus,
    createScheduleErr,
    updateScheduleStatus,
    updateScheduleErr,
  } = useSelector(reportData);
  const distributorRef = useRef();
  const [formError, setFormError] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const {
    profile: { cinemaId },
  } = useLoggedInuser();

  const {
    register,

    control,
    watch,
    reset,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(scheduleReportSchema),
    defaultValues: scheduleReportInit,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "emailAddresses",
  });

  useEffect(() => {
    if (
      reportTypesStatus === FETCH_STATUS.IDLE ||
      reportFileFormatsStatus === FETCH_STATUS.IDLE ||
      reportFrequencyTypeStatus === FETCH_STATUS.IDLE
    ) {
      dispatch(getReportFileFormats());
      dispatch(getReportTypes());
      dispatch(getReportFrequencyType());
      dispatch(getDistributors(true));
    }
  }, []);

  const distributorChangeHandler = () => {
    let selectedDistributor = distributorRef?.current?.value;
    if (selectedDistributor !== null) {
      dispatch(getDistributorFilms({ id: selectedDistributor, force: true }));
    }
    setValue("distributorId", selectedDistributor, { shouldValidate: true });
  };

  const daysArray = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  let timeArray = [];
  let timeRange = Array?.from(Array(24).keys());
  (() => {
    timeRange.forEach((time) => {
      if (time === 0) {
        timeArray.push({
          timeSlot: "12:00 AM",
          timeString: "2023-02-15T23:00:00",
        });
      }
      if (time < 12 && time !== 0)
        timeArray.push({
          timeSlot: `${time}:00 AM`,
          timeString: `2023-02-15T${
            time < 10 ? `0${(time - 1).toString()}` : time - 1
          }:00:00`,
        });
      if (time > 11 && time !== 0)
        timeArray.push({
          timeSlot: `${time !== 12 ? time - 12 : time}:00 PM`,
          timeString: `2023-02-15T${(time - 1).toString()}:00:00`,
        });
    });
  })();

  useEffect(() => {
    if (id) {
      dispatch(getScheduledReportById(id))
        .unwrap()
        .then((data) => {
          reset({
            name: data?.name,
            emailAddresses: data?.emailAddresses?.map((x) => {
              return { email: x };
            }),
            sendTime: data.sendTime,
            reportType: reportTypes?.findIndex((x) => {
              return x === data.reportType;
            }),
            distributorId: data.distributorId,
            filmId: data.filmId ? data.filmId : null,
            frequencyType: reportFrequencyTypes?.findIndex((x) => {
              return x === data.frequencyType;
            }),
            reportFileFormat: reportFileFormats?.findIndex((x) => {
              return x === data.reportFileFormat;
            }),
            isActive: data?.isActive,
            daysBefore: data?.daysBefore,
            daysAfter: data?.daysAfter,
          });
          if (distributorRef?.current?.value) {
            
            distributorRef.current.value = data.distributorId;
          }
        });
    }
  }, [id, reportTypes, reportFrequencyTypes, reportFileFormats, dispatch, reset]);

  const onSubmit = (data) => {
    let payloadData = {
      ...data,
      emailAddresses: data.emailAddresses.map((x) => {
        return x.email;
      }),
    };

    //additional data for form validation

    if (payloadData.filmId === "All Films" || payloadData.filmId === "") {
      payloadData.filmId = null;
    }

    if (payloadData.reportType === 1 && !payloadData.distributorId) {
      setFormError("Ensure You Select A Distributor");
      return;
    }

    if (payloadData.frequencyType === 1 && !payloadData.sendDay) {
      setFormError("Ensure you select A day to send Report");
      return;
    }

    if (payloadData.emailAddresses.includes("")) {
      setFormError("Ensure the email(s) is appropriately filled");
      return;
    }

    if (!id) {
      dispatch(createScheduledReport(payloadData))
        .unwrap()
        .then(() => {
          setFormSuccess(true);
          setSuccessMessage("Schedule Created Successfully");
        });
      return;
    }

    if (id) {
      dispatch(updateScheduledReport({ id, data: payloadData }))
        .unwrap()
        .then(() => {
          setFormSuccess(true);
          setSuccessMessage("Schedule Updated Successfully");
        });
      return;
    }
  };

  const onError = (data) => {
    // console.log(data);
  };

  const leaveFormFunc = () => {
    dispatch(getScheduledReports(cinemaId));
    setFormSuccess(false);
    navigate("/dashboard/reports/scheduledReports");
    reset();
  };

  return (
    <PrivateRoute redirectTo="/dashboard/reports/scheduleReportForm">
      <div className="w-[95%] mx-auto mt-6">
        <div className="flex justify-center mb-2">
          {createScheduleStatus === FETCH_STATUS.PENDING ||
          scheduledReportStatus === FETCH_STATUS.PENDING ||
          updateScheduleStatus === FETCH_STATUS.PENDING ? (
            <Spinner
              className="!w-10 !h-10"
              status={FETCH_STATUS.PENDING}
              foreFill="fill-app-purple-4"
              bgFill="text-gray-300"
            />
          ) : null}
        </div>
        <FormError
          className="mt-[5px]"
          err={updateScheduleErr || createScheduleErr}
        />
        <div className="flex items-center justify-between p-4 px-8 rounded-t-lg bg-gradient-to-b from-app-purple-4 to-app-purple-3">
          <div className="flex gap-4">
            <span className="text-xl font-semibold text-white">
              {id ? "Edit Scheduled Report" : " Create Scheduled Report"}
            </span>
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="flex items-center justify-end px-4 py-3 bg-white ">
            <div className="flex gap-3 buttongroup ">
              <button
                className="p-1 px-3 rounded-md text-[#C96FCC] bg-[#ffe583] shadow-md hover:shadow-sm"
                type="button"
                onClick={() => {
                  //   dispatch(clearItem());
                  navigate("/dashboard/reports/scheduledReports");
                }}
              >
                Cancel
              </button>
              <button
                className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm"
                type="submit"
              >
                Save
              </button>
            </div>
          </div>
          <div className="p-3 bg-app-purple-2">
            <p>
              Schedule Report Form{" "}
              {id ? (
                scheduledReportStatus === FETCH_STATUS.PENDING ? (
                  <strong>Loading ...</strong>
                ) : null
              ) : null}{" "}
            </p>
            {/* <p className="text-sm opacity-70">
              {" "}
              For the report frequency, the default range for days is 24 hours
              which runs from 6am to 6am and for weeks its 7days. In a case
              where you want to modify this range simply select the Modify range
              and add days before or after the default range
            </p> */}

            <p className="italic my-1 text-red-400">
              {id
                ? scheduledReportStatus === FETCH_STATUS.REJECTED
                  ? JSON.stringify(scheduledReportErr)
                  : null
                : null}{" "}
            </p>

            <div className=" md:grid md:grid-cols-2  mx-auto pt-5">
              <div className=" pb-3.5 ">
                <label
                  htmlFor={`name`}
                  className="block mb-2 text-md font-bold text-[#666666] "
                >
                  Name
                </label>
                <input
                  id={`name`}
                  {...register("name")}
                  placeholder={"Enter a name for the schedule"}
                  className="block w-[7rem] md:w-[15rem] p-2 h-[40px] text-gray-900 bg-[#d0a3d3] border border-gray-300 rounded-lg sm:text-xs focus:ring-blue-500 focus:border-blue-500 "
                />
                <output className=" text-xs italic text-red-500">
                  {errors?.name?.message}
                </output>
              </div>

              <div className=" pb-3.5 ">
                <label
                  htmlFor={`reportType`}
                  className="block mb-2 text-md font-bold text-[#666666] "
                >
                  Report Type
                </label>
                <select
                  {...register("reportType")}
                  className="block w-[7rem] md:w-[15rem] p-2 h-[40px] text-gray-900 bg-[#d0a3d3] border border-gray-300 rounded-lg sm:text-xs focus:ring-blue-500 focus:border-blue-500 "
                >
                  <option value=""> Select Report Type</option>
                  {reportTypes?.map((reportType, index) => {
                    return (
                      <option value={index} key={index}>
                        {reportType}
                      </option>
                    );
                  })}
                </select>
                <output className="text-xs italic text-red-500">
                  {errors?.reportType?.message ? "Select a report type" : ""}
                </output>
              </div>
              {+watch("reportType") === 1 && (
                <>
                  <div className=" pb-3.5 ">
                    <label
                      htmlFor={`distributor`}
                      className="block mb-2 text-md font-bold text-[#666666] "
                    >
                      Select Distributor
                    </label>
                    <select
                      ref={distributorRef}
                      onChange={distributorChangeHandler}
                      className="block w-[7rem] md:w-[15rem] p-2 h-[40px] text-gray-900 bg-[#d0a3d3] border border-gray-300 rounded-lg sm:text-xs focus:ring-blue-500 focus:border-blue-500 "
                    >
                      <option value=""> Select Distributor</option>
                      {distributorsList?.map((distributor, index) => {
                        return (
                          <option value={distributor.id} key={index}>
                            {distributor.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className=" pb-3.5 ">
                    <label
                      htmlFor={`film`}
                      className="block mb-2 text-md font-bold text-[#666666] "
                    >
                      Select Film
                    </label>
                    <select
                      {...register("filmId")}
                      className="block w-[7rem] md:w-[15rem] p-2 h-[40px] text-gray-900 bg-[#d0a3d3] border border-gray-300 rounded-lg sm:text-xs focus:ring-blue-500 focus:border-blue-500 "
                    >
                      <option value={null}> All Films</option>
                      {distributorFilms?.pagedList?.map((film, index) => {
                        return (
                          <option value={film.id} key={index}>
                            {film.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>{" "}
                </>
              )}
              <div className=" pb-3.5 ">
                <label
                  htmlFor={`reportFileFormat`}
                  className="block mb-2 text-md font-bold text-[#666666] "
                >
                  Report File Format
                </label>
                <select
                  {...register("reportFileFormat")}
                  className="block w-[7rem] md:w-[15rem] p-2 h-[40px] text-gray-900 bg-[#d0a3d3] border border-gray-300 rounded-lg sm:text-xs focus:ring-blue-500 focus:border-blue-500 "
                >
                  <option value={""}> Select Format</option>
                  {reportFileFormats?.map((format, index) => {
                    return (
                      <option value={index} key={index}>
                        {format}
                      </option>
                    );
                  })}
                </select>
                <output className="text-xs italic text-red-500">
                  {errors?.reportFileFormat?.message}
                </output>
              </div>
              <div className=" pb-3.5 ">
                <label
                  htmlFor={`reportFrequencyType`}
                  className="block mb-2 text-md font-bold text-[#666666] "
                >
                  Report Frequency Type
                </label>
                <select
                  {...register("frequencyType")}
                  className="block w-[7rem] md:w-[15rem] p-2 h-[40px] text-gray-900 bg-[#d0a3d3] border border-gray-300 rounded-lg sm:text-xs focus:ring-blue-500 focus:border-blue-500 "
                >
                  <option value={""}> Select Frequency</option>
                  {reportFrequencyTypes?.map((frequencyType, index) => {
                    return (
                      <option value={index} key={index}>
                        {frequencyType}
                      </option>
                    );
                  })}
                </select>
                <output className="text-xs italic text-red-500">
                  {errors?.frequencyType?.message}
                </output>
              </div>
              <div className=" pb-3.5 ">
                <label
                  htmlFor={`frequency`}
                  className="block mb-2 text-md font-bold text-[#666666] "
                >
                  Frequency
                </label>
                <input
                  id={`frequency`}
                  type={"number"}
                  {...register("frequency")}
                  className="block w-[7rem] md:w-[15rem] p-2 h-[40px] text-gray-900 bg-[#d0a3d3] border border-gray-300 rounded-lg sm:text-xs focus:ring-blue-500 focus:border-blue-500 "
                />
                <output className=" text-xs italic text-red-500">
                  {errors?.frequency?.message}
                </output>
              </div>
              <div className=" pb-3.5 ">
                <label
                  htmlFor={`reportFrequencyType`}
                  className="block mb-2 text-md font-bold text-[#666666] "
                >
                  Send Time
                </label>
                <select
                  {...register("sendTime")}
                  className="block w-[7rem] md:w-[15rem] p-2 h-[40px] text-gray-900 bg-[#d0a3d3] border border-gray-300 rounded-lg sm:text-xs focus:ring-blue-500 focus:border-blue-500 "
                >
                  <option value={""}> Select Time</option>
                  {timeArray?.map((singleTime, index) => {
                    return (
                      <option value={singleTime?.timeString} key={index}>
                        {singleTime?.timeSlot}
                      </option>
                    );
                  })}
                </select>
                <output className="text-xs italic text-red-500">
                  {errors?.sendTime?.message}
                </output>
              </div>
              {watch("frequencyType") === "1" && (
                <div className=" pb-3.5 ">
                  <label
                    htmlFor={`reportFrequencyType`}
                    className="block mb-2 text-md font-bold text-[#666666] "
                  >
                    Send Day
                  </label>
                  <select
                    {...register("sendDay")}
                    className="block w-[7rem] md:w-[15rem] p-2 h-[40px] text-gray-900 bg-[#d0a3d3] border border-gray-300 rounded-lg sm:text-xs focus:ring-blue-500 focus:border-blue-500 "
                  >
                    <option value={""}> Select Day</option>
                    {daysArray?.map((day, index) => {
                      return (
                        <option value={index} key={index}>
                          {day}
                        </option>
                      );
                    })}
                  </select>
                  <output className="text-xs italic text-red-500">
                    {errors?.sendDay?.message}
                  </output>
                </div>
              )}

              <div className=" pb-3.5 ">
                <label
                  htmlFor={`daysBefore`}
                  className="block mb-2 text-md font-bold text-[#666666] "
                >
                  Days Before
                </label>
                <input
                  id={`daysBefore`}
                  type={"number"}
                  {...register("daysBefore")}
                  className="block w-[7rem] md:w-[15rem] p-2 h-[40px] text-gray-900 bg-[#d0a3d3] border border-gray-300 rounded-lg sm:text-xs focus:ring-blue-500 focus:border-blue-500 "
                />
              </div>
              <div className=" pb-3.5 ">
                <label
                  htmlFor={`daysAfter`}
                  className="block mb-2 text-md font-bold text-[#666666] "
                >
                  Days After
                </label>
                <input
                  id={`daysAfter`}
                  type={"number"}
                  {...register("daysAfter")}
                  className="block w-[7rem] md:w-[15rem] p-2 h-[40px] text-gray-900 bg-[#d0a3d3] border border-gray-300 rounded-lg sm:text-xs focus:ring-blue-500 focus:border-blue-500 "
                />
                {/* <p className="text-xs italic"> Sample text</p> */}
              </div>
              {id && (
                <div className=" pb-3.5 ">
                  <label
                    htmlFor={`status`}
                    className="block mb-2 text-md font-bold text-[#666666] "
                  >
                    Status
                  </label>
                  <select
                    {...register("isActive")}
                    className="block w-[7rem] md:w-[15rem] p-2 h-[40px] text-gray-900 bg-[#d0a3d3] border border-gray-300 rounded-lg sm:text-xs focus:ring-blue-500 focus:border-blue-500 "
                  >
                    <option value={true}>Active</option>
                    <option value={false}>InActive</option>
                  </select>
                </div>
              )}

              <div className=" pb-3.5 ">
                <label
                  htmlFor={`sendEmpty`}
                  className="block mb-2 text-md font-bold text-[#666666] "
                >
                  Send Empty
                </label>
                <input
                  id={`daysAfter`}
                  type={"checkbox"}
                  {...register("sendEmpty")}
                  className="accent-[#d0a3d3]  mb-3 text-[#C96FCC] !ring-transparent"
                />
              </div>
              <div className=" pb-3.5 col-span-2 ">
                <button
                  className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm"
                  type="button"
                  onClick={() => {
                    append([{ email: null }]);
                  }}
                >
                  Add Email
                </button>
                <p className=" text-xs italic text-red-500">
                  {errors?.emailAddresses
                    ? "Ensure you enter at least one Email"
                    : ""}
                </p>
              </div>
              {fields?.map((field, index) => {
                return (
                  <div className=" pb-3.5 " key={field.id}>
                    <label
                      htmlFor={`daysAfter`}
                      className="block mb-2 text-md font-bold text-[#666666] "
                    >
                      {`Email Address ${index + 1}`}
                    </label>
                    <div className="flex">
                      <input
                        type={"email"}
                        {...register(`emailAddresses.${index}.email`)}
                        className=" mr-2 block w-[5rem] md:w-[12rem] p-2 h-[40px] text-gray-900 bg-[#d0a3d3] border border-gray-300 rounded-lg sm:text-xs focus:ring-blue-500 focus:border-blue-500 "
                      />
                      <button
                        className="p-1 px-3 rounded-md text-[#C96FCC] bg-[#ffe583] shadow-md hover:shadow-sm"
                        type="button"
                        onClick={() => {
                          remove(index);
                        }}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </form>
        {formError && (
          <FormErrorModal
            onClose={() => {
              setFormError(false);
            }}
            show={formError}
            message={formError}
            onClick={() => {
              setFormError(false);
            }}
          />
        )}
        <SuccessModal
          onClose={leaveFormFunc}
          show={formSuccess}
          message={successMessage}
          onClick={leaveFormFunc}
        />
      </div>
    </PrivateRoute>
  );
};

export { ScheduleReportForm };
