import React, { useState, useCallback, useLayoutEffect } from "react";

import { VIEW_MODES } from "../../../utils";

const EventCard = ({ event, renderEvent, useContextTrigger }) => {
  const [bindTrigger] = useContextTrigger({
    collect: () => event,
  });

  return <div {...bindTrigger}>{renderEvent(event, VIEW_MODES.WEEK)}</div>;
};

const DayRender = ({ dayEvents = [], renderEvent, useContextTrigger }) => {
  
  return (
    <div className="relative truncate">
      {dayEvents
        .sort((a, b) => {
          return a.startTime < b.startTime
            ? -1
            : a.startTime > b.startTime
            ? 1
            : 0;
        })
        .map((ev, i) => (
          <EventCard
            renderEvent={renderEvent}
            useContextTrigger={useContextTrigger}
            event={ev}
            key={i}
          />
        ))}
    </div>
  );
};

const WeekRowRender = ({
  setRowHeights,
  horizontalOffset,
  dayEvents,
  renderEvent,
  index,
  useContextTrigger = () => {},
}) => {
  const [node, setNode] = useState(null);

  const dayColRef = useCallback((node) => {
    if (node !== null) {
      setNode(node);
    }
  }, []);

  useLayoutEffect(() => {
    if (node) {
      const measure = () => {
        setRowHeights((heights) => {
          const newHeights = [...heights];
          const { height } = node.getBoundingClientRect();
          newHeights[index] = height + 10;

          return newHeights;
        });
      };

      measure();
      window.addEventListener("resize", measure);

      return () => {
        window.removeEventListener("resize", measure);
      };
    }
  }, [node, setRowHeights, index, dayEvents]);

  return (
    <div
      className="absolute left-0 truncate top-2/4"
      ref={dayColRef}
      style={{
        transform: `translate(${horizontalOffset}px,-50%)`,
        width: `${100 / 7}%`,
      }}
    >
      <DayRender
        offsetHorizontal={horizontalOffset}
        renderEvent={renderEvent}
        dayEvents={dayEvents}
        useContextTrigger={useContextTrigger}
      />
    </div>
  );
};

export default WeekRowRender;
