import { useMemo } from "react";

const useDataMutateWithKey = (defaultData, key) => {
  const mutatedData = useMemo(() => {
    return defaultData.reduce((acc, curr) => {
      acc[curr[key]] = curr;
      return acc;
    }, {});
  }, [defaultData, key]);
  

  return {
    mutatedData,
  };
};

export { useDataMutateWithKey };
