import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  CHECK_SEAT_STATUS,
  FETCH_STATUS,
  getScreenFormat,
  getSelectedItem,
  screenFormat,
  screenInit,
  screenSchema,
  trueFalse,
} from "../../utils";
import {
  FormError,
  InlineErr,
  CancelSaveToolbar,
  Input,
  StatusToggler,
  MultiSearchSelect,
  Spinner,
} from "../UI";
import {
  useDataMutateWithKey,
  useLoggedInuser,
  useSeatLayout,
} from "../../hooks";
import { useDispatch, useSelector } from "react-redux";
import {
  getFilmFormat,
  getSeatClass,
  selectFilms,
  selectScreens,
} from "../../redux/slices";

import { SeatLayoutDefinition } from "./SeatLayoutDefinition";
import { ScreenPreviewModal } from "./ScreenPreviewModal";
import { InfoIcon } from "../Icons";
import RegenerateLayoutForm from "./RegenerateLayoutForm";

const wrapperClass = "grid-cols-[40%_minmax(0,1fr)] gap-4";

const ScreenForm = ({ onSave, isEdit, info, error, status, onReset }) => {
  const { profile, userErr } = useLoggedInuser();
  const [previewModal, setPreviewModal] = useState(false);
  const [viewedCurrent, setViewedCurrent] = useState(false);
  const [regenerate, setRegenerate] = useState(false);
  const [isGenerated, setIsGenerated] = useState(false);
  const { filmFormat, getFilmFormatErr, getFilmFormatStatus } =
    useSelector(selectFilms);
  const { seatClasses, seatClassStatus } = useSelector(selectScreens);
  const dispatch = useDispatch();
  const { mutatedData: seatClassesById } = useDataMutateWithKey(
    seatClasses,
    "id"
  );

  const {
    watch,
    control,
    setValue,
    register,
    reset,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(screenSchema),
    defaultValues: screenInit,
  });

  const seatLayouts = watch("seatLayouts");
  const rowNum = watch("numberOfRows");
  const screenName = watch("shortName");

  const seatNum = watch("numberOfColumns");

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "seatLayouts",
    // rules: { minLength:"5",required:true },
  });

  const {
   
    generateLetters,
    letters,
    setLetters,

  } = useSeatLayout(update, seatLayouts, seatNum, rowNum);

  const onSubmit = async (values) => {
    const { seatLayouts, ...data } = values;

    onSave({
      ...values,
      houseSeats: parseInt(values.houseSeats) ?? 1,
      numberOfColumns: parseInt(values.numberOfColumns) || 0,
      numberOfRows: parseInt(values.numberOfRows) || 0,
      screenNumber: parseInt(values.screenNumber) ?? 1,
      standardSeats: parseInt(values.standardSeats) ?? 1,
      totalSeats: parseInt(values.totalSeats) ?? 1,
      isActive: trueFalse[values.isActive],
      cinemaId: values.cinemaId ?? profile.cinemaId,
      filmFormats: values.filmFormats,
      seatLayouts: values.seatLayouts,
    });
  };

  const getData = useCallback(() => {
    if (
      getFilmFormatStatus === FETCH_STATUS.IDLE ||
      seatClassStatus === FETCH_STATUS.IDLE
    ) {
      dispatch(getFilmFormat());
      dispatch(getSeatClass());
    }
  }, [dispatch, getFilmFormatStatus, seatClassStatus]);

  const generateLayout = useCallback(() => {
    if (!isGenerated) {
      const roww = getValues("numberOfRows");
      const newLetters = generateLetters(
        seatLayouts.length === 0 ? roww : Number(roww) + 1
      );
      const neww = seatLayouts.length > 1 ? newLetters.shift() : newLetters;

      setLetters([...letters, ...newLetters]);

      Array.from({ length: getValues("numberOfRows") }, (_, i) => {
        return append({
          name: newLetters[i],
          numOfSeats: seatNum,
          rowNumber: seatLayouts.length + i + 1,
          definitions: Array.from({ length: seatNum }, (_, i) => {
            return {
              seatClassId: seatClasses[1]?.id,
              seatNumber: i + 1,
              status: 0,
              columnNumber: i + 1,
              // isSelected:false
            };
          }),
        });
      });
      setIsGenerated(true);
    }
    setPreviewModal(true);
    setRegenerate(false);
  }, [
    append,
    generateLetters,
    getValues,
    isGenerated,
    letters,
    seatClasses,
    seatLayouts.length,
    seatNum,
    setLetters,
  ]);

  const resetLayout = useCallback(() => {
    setValue("numberOfColumns", 0);
    setValue("numberOfRows", 0);
    setValue("seatLayouts", []);
    setIsGenerated(false);
    setLetters(["A"]);
  }, [setLetters, setValue]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (isEdit && info?.seatLayouts?.length > 0) {
      setIsGenerated(true);
    }
  }, [info?.seatLayouts.length, isEdit]);

  useEffect(() => {
    if (isEdit) {
      reset({
        ...screenInit,
        ...info,
        filmFormats: !info?.filmFormats?.length
          ? [screenFormat.standard_2d]
          : info?.filmFormats?.map((f) => f.id),
        isActive: info.isActive.toString(),
        seatLayouts: info?.seatLayouts?.map((layout) => ({
          ...layout,
          definitions: layout?.definitions?.map((def) => ({
            ...def,
            status: CHECK_SEAT_STATUS[def.status],
            // isSelected:false
          })),
        })),
      });
    }
  }, [info, reset, isEdit]);

  const props = useMemo(
    () => ({
      seatLayouts,
      layoutValues: fields,
      seatClasses,
      screenName,
    }),
    [fields, screenName, seatClasses, seatLayouts]
  );

  return (
    <>
      <form className="relative pb-32" onSubmit={handleSubmit(onSubmit)}>
        <FormError
          className="absolute top-0 z-20 transform left-2/4 -translate-x-2/4"
          err={error}
          onDismiss={onReset}
        />

        <FormError
          className="absolute top-0 z-20 transform left-2/4 -translate-x-2/4"
          err={userErr}
        />

        <CancelSaveToolbar backPath={"/dashboard/screens"} status={status} />

        <section className="relative w-full px-3 py-8 bg-app-purple-2 ">
          <section className="gap-3 md:grid md:grid-cols-2 md:gap-8 mb-4">
            <div className="space-y-5 mt-11">
              <Input
                inputClass="!bg-transparent"
                readOnly
                disabled
                label="Cinema name:"
                value={profile?.cinema}
                wrapperClass={wrapperClass}
              />

              <Input
                label="Name:"
                id="shortName"
                wrapperClass={wrapperClass}
                errorMsg={errors?.shortName?.message}
                {...register("shortName")}
              />

              <Input
                label="Screen Number:"
                id="screenNumber"
                min={0}
                type="number"
                wrapperClass={wrapperClass}
                errorMsg={errors?.screenNumber?.message}
                {...register("screenNumber")}
              />

              <div
                className={`grid items-center gap-10 grid-cols-[12%_minmax(0,1fr)] ${wrapperClass}`}
              >
                <label className={`text-right `} htmlFor={"filmFormats"}>
                  Screen Format:
                </label>

                <div className="relative">
                  <Controller
                    control={control}
                    name="filmFormats"
                    render={({ field: { value, onChange } }) => {
                      return (
                        <MultiSearchSelect
                          items={filmFormat}
                          id={"filmFormats"}
                          value={getSelectedItem(filmFormat, value, "id")}
                          onChange={(value) => onChange(value.map((v) => v.id))}
                          displayValue={(value) =>
                            value.map((v) => v.name).join(", ")
                          }
                          placeholder={`Screen Format`}
                          searchOptions={{
                            keys: ["name"],
                          }}
                        />
                      );
                    }}
                  />

                  <InlineErr err={errors?.filmFormats?.message} />
                </div>
              </div>
            </div>

            <div className="space-y-5 mt-11">
              <Input
                label="House Seats:"
                id="houseSeats"
                min={0}
                wrapperClass={wrapperClass}
                errorMsg={errors?.houseSeats?.message}
                type="number"
                {...register("houseSeats")}
              />

              <Input
                label="Standard Seats:"
                id="standardSeats"
                min={0}
                wrapperClass={wrapperClass}
                errorMsg={errors?.standardSeats?.message}
                type="number"
                {...register("standardSeats")}
              />

              <Input
                label="Total Seats:"
                id="totalSeats"
                min={0}
                wrapperClass={wrapperClass}
                errorMsg={errors?.totalSeats?.message}
                type="number"
                {...register("totalSeats")}
              />

              <StatusToggler
                radioLabel="Status"
                options={[
                  { key: "Active", value: "true" },
                  { key: "Inactive", value: "false" },
                ]}
                register={register}
                statusKey="isActive"
                errMsg={errors?.isActive?.message}
              />

              <div className="grid text-xs grid-cols-[25px_minmax(0,1fr)] items-center ml-[2%] pt-8">
                <span>
                  <InfoIcon />
                </span>

                <p>
                  Please note that modifying the seats count for any existing
                  screen will only affect new shows and will not take effect on
                  existing shows created before the seat change.
                </p>
              </div>
            </div>
          </section>

          <section>
            <h3 className="font-bold">SET UP DEFAULT SCREEN LAYOUT</h3>

            <div className="flex justify-start mt-4">
              <div className=" flex space-x-4">
                <Input
                  // disabled={isEdit}
                  min={0}
                  label="Number of Rows:"
                  id="numberOfRows"
                  placeholder="Default Number"
                  wrapperClass="!flex !flex-col !gap-0 !items-start"
                  errorMsg={errors?.numberOfRows?.message}
                  type="number"
                  {...register("numberOfRows")}
                />
                <Input
                  // disabled={isEdit}
                  min={0}
                  label="Number of Seats:"
                  id="numberOfColumns"
                  placeholder="Default Number"
                  wrapperClass="!flex !flex-col !gap-0 !items-start"
                  errorMsg={errors?.numberOfColumns?.message}
                  type="number"
                  {...register("numberOfColumns")}
                />

                <div className="flex flex-col justify-end">
                  <button
                    type="button"
                    onClick={generateLayout}
                    className="text-white bg-gradient-[180deg] to-[#c112c1] from-[#841a84] px-4 py-2 rounded-lg text-sm"
                  >
                    Preview Layout
                  </button>
                </div>
                <div className="flex flex-col justify-end">
                  <button
                    type="button"
                    onClick={resetLayout}
                    className="text-white bg-gradient-[180deg] to-[#f70505] from-[#f77777] px-4 py-2 rounded-lg text-sm"
                  >
                    Reset layout
                  </button>
                </div>
              </div>
            </div>
          </section>
        </section>
      </form>
      {previewModal && (
        <ScreenPreviewModal
          {...props}
          // seatStatusCount={seatStatusCount}
          // seatClasses={seatClasses}
          // layoutValues={fields}
          // seatLayouts={seatLayouts}
          update={update}
          remove={remove}
          show={previewModal}
          screenName={screenName}
          onClose={() => setPreviewModal(false)}
        />
      )}
      {regenerate && (
        <RegenerateLayoutForm
          generateLayout={generateLayout}
          show={regenerate}
          register={register}
          onClose={() => setRegenerate(false)}
        />
      )}
    </>
  );
};

export { ScreenForm };
