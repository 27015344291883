import { useCallback } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getLoggedInUserById, selectAuth, selectUser } from "../redux/slices";
import { FETCH_STATUS } from "../utils";

const useLoggedInuser = () => {
  const dispatch = useDispatch();

  const { userId } = useSelector(selectAuth);
  const { profile, userStatus, userErr } = useSelector(selectUser);

  const retry = useCallback(() => {
    if (userId) {
      dispatch(getLoggedInUserById(userId));
    }
  }, [userId, dispatch]);

  useEffect(() => {
    if (
      userId &&
      (userStatus === FETCH_STATUS.IDLE || userStatus === FETCH_STATUS.REJECTED)
    ) {
      dispatch(getLoggedInUserById(userId));
    }
  }, [dispatch, userStatus, userId]);

  return { profile, userStatus, userErr, userId, retry };
};

export { useLoggedInuser };
