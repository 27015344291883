import {useEffect, useMemo, useState} from "react";
import {
  AcceptanceConfirmation,
  AcceptanceSuccess,
  ApproveStockTransfer,
  ClientPaginatedTable,
  DefaultColumnFilter,
  PrivateRoute,
  RejectionConfirmation,
  RejectionReason,
  RejectionSuccess,
  Spinner,
} from "../../../../components";
import {DateTimeInput} from "../../../../components/Films/DateTimeInput";
import DatePicker from "react-datepicker";
import addDays from "date-fns/addDays";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getStockTransfer, getStockTransfers, selectUser, stockData,} from "../../../../redux/slices";
import {useRequest} from "../../../../hooks";
import {API_URL, axiosApiInstance, getstockTransSchema,} from "../../../../utils";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

const ActionBtn = ({ label, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={
        "p-2 px-3 rounded-md text-white bg-app-purple-8 hover:font-bold"
      }
    >
      {label}
    </button>
  );
};

const StatusIndicator = ({ status }) => {
  const colourCode = {
    Completed: { txt: "text-[#007F00]", bg: "bg-[#CDFFCD]" },
    Approved: { txt: "text-[#007F00]", bg: "bg-[#CDFFCD]" },
    Rejected: { txt: "text-[#D30000]", bg: "bg-[#FFE0E0]" },
    Pending: { txt: "text-[#965E00]", bg: "bg-[#FFECCC]" },
    Draft: { txt: "text-[#4A4AFF]", bg: "bg-[#E6E6F2]" },
    Ready: { txt: "text-[#4A4AFF]", bg: "bg-[#E6E6F2]" },
    Waiting: { txt: "text-[#965E00]", bg: "bg-[#FFECCC]" },
  };

  return (
    <div
      className={`flex items-center px-3 py-1 justify-center rounded-md ${colourCode[status]?.txt} ${colourCode[status]?.bg} `}
    >
      <span>{status}</span>
    </div>
  );
};

const InlineError = ({ err }) => {
  if (err) {
    return (
      <p className={`italic  text-red-400 font-light text-[10px] `}>{err}</p>
    );
  }

  return null;
};
const StockTransReqOverview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { stockTransfers, stockRequest, stockTransfersStatus } =
    useSelector(stockData);
  const [startDate, setStartDate] = useState(
    addDays(new Date(), -30).toISOString()
  );

  const [showAcceptanceConfirmation, setShowAcceptanceConfirmation] =
    useState(false);
  const [showAcceptanceSuccess, setShowAcceptanceSuccess] = useState(false);
  const [showRejectionConfirmation, setShowRejectionConfirmation] =
    useState(false);
  const [showRejectionSuccess, setShowRejectionSuccess] = useState(false);
  const [showRejectionReason, setShowRejectionReason] = useState(false);
  const [showApproveStockTransfer, setShowApproveStockTransfer] =
    useState(false);
  const [data, setData] = useState("");

  const {profile} = useSelector(selectUser)

  const [endDate, setEndDate] = useState(new Date().toISOString());

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(getstockTransSchema),
    defaultValues: {
      fetchType: "",
      status: "",
    },
  });

  const {
    data: stockTransFetchTypes,
    setResolved: setResolved1,
    setPending: setPending1,
    setRejected: setRejected1,
  } = useRequest();

  const {
    data: stockTransStatuses,
    setResolved: setResolved2,
    setPending: setPending2,
    setRejected: setRejected2,
  } = useRequest();

  const getStockTransFetchTypes = async () => {
    try {
      setPending1();

      const res = await axiosApiInstance.get(
        `${API_URL}/StockTransfers/StockTransferFetchTypes`
      );
      setResolved1(res.data.data);
    } catch (error) {
      setRejected1(error);
    }
  };

  const getStockTransStatuses = async () => {
    try {
      setPending2();

      const res = await axiosApiInstance.get(
        `${API_URL}/StockTransfers/StockTransferStatuses`
      );
      setResolved2(res.data.data);
    } catch (error) {
      setRejected2(error);
    }
  };

  useEffect(() => {
    getStockTransFetchTypes();
    getStockTransStatuses();
  }, []);

  const submitHandler = async (data) => {
    dispatch(
      getStockTransfers({
        startDate,
        endDate,
        status: data.status,
        fetchType: data.fetchType,
        cinemaId: profile.cinemaId
      })
    );
  };

  const stockTransRequestData = useMemo(() => {
    if (stockTransfers?.length) {
      return stockTransfers;
    } else {
      return [];
    }
  }, [stockTransfers]);

  const columns = useMemo(() => {
    return [
      {
        Header: "Request Id",
        accessor: "id",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "From",
        accessor: "originatingTransferRequest",
      },
      {
        Header: "To",
        accessor: "destinationTransferRequest",
      },
      {
        Header: "Date Sent",
        accessor: "requestDate",
        Cell: ({ value }) => {
          return new Date(value).toDateString();
        },
      },
      {
        Header: "Scheduled Date",
        accessor: "scheduledDate",
        Cell: ({ value }) => {
          return new Date(value).toDateString();
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          return <StatusIndicator status={value} />;
        },
      },
      {
        Header: "Actions",

        Cell: ({ value, row }) => {
          return (
            <div className="flex justify-around">
              {" "}
              <ActionBtn
                label={"View"}
                onClick={() => {
                  setShowApproveStockTransfer(true);
                  setData(row.original.id);
                  dispatch(getStockTransfer(row.original.id));
                }}
              />
              {/* {row.original.status === "Waiting" && ( */}
              <ActionBtn
                label={"Update"}
                onClick={() => {
                  navigate(
                    `/dashboard/stock/stockTransferRequest/update/${row.original.id}`
                  );
                }}
              />
              {/* )} */}
            </div>
          );
        },
      },
    ];
  }, []);

  return (
    <PrivateRoute redirectTo="/dashboard/stock/stockTransferRequest">
      <p className="font-bold px-3">Stock Transfer Requests</p>
      <div className="w-[95%] mx-auto mt-6 ">
        <p className="text-center text-[20px] p-3">Select Date Range</p>
        <form onSubmit={handleSubmit(submitHandler)}>
          <div className="flex justify-center">
            <div className=" pb-3.5 ">
              <label
                htmlFor={`value`}
                className="mb-2 text-md font-bold text-[#666666] "
              >
                From
              </label>
              <DatePicker
                placeholderText="Expiry Date"
                selected={new Date(startDate)}
                onChange={(value) => setStartDate(value.toISOString())}
                customInput={
                  <DateTimeInput
                    className="bg-app-purple-8 w-[8rem] md:w-[10rem]"
                    dateFormat={"EEE, MMM dd, yyyy"}
                    value={startDate}
                  />
                }
              />
            </div>
            <div className=" pb-3.5 ">
              <label
                htmlFor={`value`}
                className="mb-2 text-md font-bold text-[#666666] "
              >
                To
              </label>
              <DatePicker
                placeholderText="Expiry Date"
                selected={new Date(endDate)}
                onChange={(value) => setEndDate(value.toISOString())}
                customInput={
                  <DateTimeInput
                    className="bg-app-purple-8 w-[8rem] md:w-[10rem]"
                    dateFormat={"EEE, MMM dd, yyyy"}
                    value={endDate}
                  />
                }
              />
            </div>
          </div>
          <div className="flex justify-center mb-2">
            <div className="mr-5">
              <label className=" block mb-2 text-md font-bold text-[#666666] ">
                Select Type
              </label>
              <select
                className="rounded bg-[#d0a3d3] border-0 mb-3 w-[160px]"
                {...register("fetchType")}
              >
                <option value={""}>Select Type</option>
                {stockTransFetchTypes?.length > 0 &&
                  stockTransFetchTypes?.map((data, index) => {
                    return (
                      <option value={index} key={index}>
                        {data}
                      </option>
                    );
                  })}
              </select>
              <InlineError
                err={
                  errors?.fetchType?.message ? " Ensure you select a type" : ""
                }
              />
            </div>
            <div className="ml-5">
              <label className="block mb-2 text-md font-bold text-[#666666]">
                Select Status
              </label>
              <select
                className="rounded bg-[#d0a3d3] border-0 mb-3 w-[160px]"
                {...register("status")}
              >
                <option value={""}>Select Status</option>
                {stockTransStatuses?.length > 0 &&
                  stockTransStatuses?.map((data, index) => {
                    return (
                      <option value={index} key={index}>
                        {data}
                      </option>
                    );
                  })}
              </select>
              <InlineError
                err={
                  errors?.status?.message ? "Ensure you select a Status" : ""
                }
              />
            </div>
          </div>
          <div className="flex justify-center">
            <button
              className=" mb-[30px] p-1 px-3 flex items-center justify-between rounded-md text-white bg-[#7E2080] shadow-sm hover:shadow-xl hover:text-white"
              type="submit"
            >
              Get Stock Transfer Requests
              <Spinner
                className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                status={stockTransfersStatus}
              />
            </button>
          </div>
        </form>
        <div className="flex items-center justify-end p-4 px-8 rounded-t-lg bg-gradient-to-b from-app-purple-4 to-app-purple-3">
          {/* <div className="flex buttongroup">
            <button
              className="p-3 px-5 rounded-md text-[#C96FCC] bg-white shadow-md hover:shadow-sm"
              onClick={() => {
                navigate("/dashboard/stock/stockRequest/create");
              }}
            >
              Create
            </button>
          </div> */}
        </div>
        <div className="overflow-x-auto">
          <ClientPaginatedTable
            filterColumnsKeys={["id"]}
            //   toolbarChildren={}
            // onRowClick={onRowClick}
            tableData={stockTransRequestData}
            columns={columns}
            getHeaderProps={(column) => ({
              className:
                "px-3 pt-8 pb-2 text-base font-medium border border-[#00000033] ",
              ...(column.id === "film" ? column?.getSortByToggleProps() : {}),
            })}
            getCellProps={(cellInfo) => ({
              style: {},
              className: `${
                cellInfo?.column?.id === "film" ? "text-app-purple-9" : ""
              } ${cellInfo?.column?.id === "days" ? "break-all" : ""} `,
            })}
            getColumnProps={() => ({ className: "bg-app-purple-2", style: {} })}
          />
        </div>
      </div>
      <AcceptanceConfirmation
        show={showAcceptanceConfirmation}
        type={"Stock Transfer"}
        data={data}
        onClose={() => {
          setShowAcceptanceConfirmation(false);
        }}
        onConfirm={() => {
          setShowAcceptanceConfirmation(false);
          setShowAcceptanceSuccess(true);
        }}
      />

      <RejectionConfirmation
        show={showRejectionConfirmation}
        type={"Stock Transfer"}
        onClose={() => {
          setShowRejectionConfirmation(false);
        }}
        onReject={() => {
          setShowRejectionReason(true);
          setShowRejectionConfirmation(false);
        }}
      />
      <AcceptanceSuccess
        show={showAcceptanceSuccess}
        data={data}
        type={"Stock Transfer"}
        onClose={() => {
          setShowAcceptanceSuccess(false);
          setShowApproveStockTransfer(false);
        }}
      />
      <RejectionSuccess
        show={showRejectionSuccess}
        data={data}
        type={"Stock Transfer"}
        onClose={() => {
          setShowRejectionSuccess(false);
          setShowApproveStockTransfer(false);
        }}
      />
      <RejectionReason
        show={showRejectionReason}
        type={"Stock Transfer"}
        data={data}
        onClose={() => {
          setShowRejectionReason(false);
        }}
        onConfirm={() => {
          setShowRejectionSuccess(true);
          setShowRejectionReason(false);
        }}
      />
      <ApproveStockTransfer
        show={showApproveStockTransfer}
        type={"Stock Transfer"}
        data={stockRequest}
        onClose={() => {
          setShowApproveStockTransfer(false);
          setData({});
        }}
        approveFunc={() => {
          setShowAcceptanceConfirmation(true);
        }}
        declineFunc={() => {
          setShowRejectionConfirmation(true);
        }}
      />
    </PrivateRoute>
  );
};
export { StockTransReqOverview };
