import React, { Fragment, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { Dialog, Transition, Combobox } from "@headlessui/react";
import DatePicker from "react-datepicker";
import differenceInMinutes from "date-fns/differenceInMinutes";
import add from "date-fns/add";

import { DateTimeInput, format } from "./DateTimeInput";
import CreateSessionErr from "./CreateSessionErr";
import { formatDateTime, validateSingleSession } from "../../utils";

import { Spinner, InlineErr } from "../UI";
import { Caret } from "../Icons";

const EditSession = ({
  newSession,
  onClose,
  formats,
  oldSession,
  show,
  handleEdit,
  screens,
  sessionList = [],
  editResetErr,
  editErr,
  editStatus,
  showTimesStatusList,
  films,
  ticketTypes,
  priceCardsErr,
  getStatusOptList,
  showTimesStatusListErr,
  showTimesStatusListStatus,
}) => {
  const [newInfo, setNewInfo] = useState({
    ...newSession,
    startTime: new Date(newSession?.startTime),
  });
  const [search, setSearch] = useState("");
  const [validErrs, setValidErrs] = useState([]);

  const handleChange = (type, value) => {
    const editedSession = { ...newInfo };

    editedSession[type] = value;

    if (type === "priceCardId") {
      editedSession.priceCardName = value;
    }
    setNewInfo(editedSession);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const duration = newInfo?.film?.id
      ? +newInfo.film.duration
      : Math.abs(
          differenceInMinutes(
            new Date(oldSession.endTime),
            new Date(oldSession.startTime)
          ) - oldSession.trailerTime
        );

    const statusIndex = showTimesStatusList.findIndex(
      (s) => s === newInfo.status
    );

    const status = statusIndex > -1 ? statusIndex : 0;

    // console.log(oldSession);

    const body = {
      ...newInfo,
      status: status,
      duration: newInfo?.film?.duration || oldSession.duration,
      filmId: newInfo?.film?.id || oldSession.filmId,
      film: newInfo?.film?.name || oldSession.film,
    };

    body.endTime = add(new Date(body.startTime), {
      minutes: duration + +newInfo.trailerTime,
    });

    const errors = validateSingleSession(body, sessionList);

    // console.log(body);
    // console.log(sessionList);

    if (errors.length) {
      setValidErrs(errors);
    } else {
      handleEdit(body);
    }
  };

  const filteredFilms =
    search?.trim() === ""
      ? films
      : films.filter((film) => {
          return film.title.toLowerCase().includes(search.toLowerCase());
        });

  return (
    <Transition show={show} as={Fragment}>
      <Dialog as="div" className="relative z-[1000000]" onClose={onClose}>
        {/* Overlay */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 p-4 overflow-y-auto">
          <div className="flex items-center justify-center min-h-[400px] p-2 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full min-h-[400px] max-w-2xl p-6  text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title className="relative pb-4 border-b" as="div">
                  <p className="text-lg font-medium leading-6 text-gray-900">
                    Edit Film Session
                  </p>
                  <button
                    type="button"
                    onClick={onClose}
                    className="absolute right-0 top-2/4 -translate-y-2/4 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </Dialog.Title>

                <form className="mt-4" onSubmit={onSubmit}>
                  <h3 className="my-1 text-lg font-medium">
                    Update showtime details from
                  </h3>

                  <div className="relative grid items-center grid-cols-3 gap-2 py-2">
                    <div>
                      <label
                        htmlFor={`filmId`}
                        className="block mb-1 text-sm font-medium text-gray-900 "
                      >
                        Film
                      </label>
                      <select
                        id={`filmId`}
                        className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                        disabled
                        value={oldSession?.filmId}
                      >
                        <option value="">Select film...</option>
                        {films.map((film) => (
                          <option value={film.id} key={film.id}>
                            {film.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <label
                        htmlFor={`PriceCard`}
                        className="block mb-1 text-sm font-medium text-gray-900 "
                      >
                        Price Card
                      </label>

                      <select
                        id={`PriceCard`}
                        className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                        disabled
                        value={oldSession?.priceCardId}
                      >
                        <option value="">PriceCard</option>
                        {ticketTypes.map((t) => (
                          <option value={t.id} key={t.id}>
                            {t.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <label
                        htmlFor={`filmFormatId`}
                        className="block mb-1 text-sm font-medium text-gray-900 "
                      >
                        Film Format
                      </label>

                      <select
                        id={`filmFormatId`}
                        className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                        disabled
                        value={oldSession?.filmFormatId}
                      >
                        <option value="">Select...</option>
                        {formats.map((f) => (
                          <option value={f.id} key={f.id}>
                            {f.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="relative grid items-center grid-cols-3 gap-2 py-2">
                    <div>
                      <label
                        htmlFor={`screenId`}
                        className="block mb-1 text-sm font-medium text-gray-900 "
                      >
                        Screen Id
                      </label>
                      <select
                        id={`screenId`}
                        className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                        disabled
                        value={oldSession?.screenId}
                      >
                        <option value="">Select screen...</option>
                        {screens.map((screen) => (
                          <option value={screen.id} key={screen.id}>
                            {screen.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <label
                        htmlFor={`startTime`}
                        className="block mb-1 text-sm font-medium text-gray-900 "
                      >
                        Start time
                      </label>

                      <input
                        disabled
                        className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                        value={formatDateTime(
                          new Date(oldSession?.startTime),
                          format
                        )}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor={`trailerDuration`}
                        className="block mb-1 text-sm font-medium text-gray-900 "
                      >
                        Trailer Duration(mins)
                      </label>
                      <input
                        type="number"
                        id={`trailerTime`}
                        disabled
                        className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                        value={oldSession?.trailerTime}
                      />
                    </div>
                  </div>

                  <h3 className="my-1 text-lg font-medium">to</h3>

                  <div className="relative grid items-center grid-cols-3 gap-2 py-2">
                    <div className="pb-1 mb-2">
                      <Combobox
                        onChange={(value) => {
                          handleChange("film", value);
                        }}
                        value={newInfo?.film?.name || newInfo.film}
                      >
                        <div className="relative w-full text-left">
                          <Combobox.Label className="block mb-1 text-sm font-medium text-gray-900 ">
                            Select Film
                          </Combobox.Label>
                          <div className="relative">
                            <Combobox.Input
                              className="w-full py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 border border-gray-300 rounded-lg outline-none"
                              displayValue={(film) => film}
                              onChange={(event) =>
                                setSearch(event.target.value)
                              }
                            />
                            <Combobox.Button className="absolute right-0 flex items-center pr-2 top-2/4 -translate-y-2/4">
                              <Caret />
                            </Combobox.Button>

                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                              afterLeave={() => setSearch("")}
                            >
                              <Combobox.Options className="absolute z-20 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {filteredFilms.length === 0 && search !== "" ? (
                                  <div className="relative px-4 py-2 text-gray-700 cursor-default select-none">
                                    Nothing found.
                                  </div>
                                ) : (
                                  filteredFilms.map((film) => (
                                    <Combobox.Option
                                      key={film.id}
                                      className={({ active }) =>
                                        `relative cursor-default select-none p-2 ${
                                          active
                                            ? "bg-app-purple-2 text-white"
                                            : "text-gray-900"
                                        } ${
                                          newInfo?.film?.id === film.id
                                            ? "!bg-app-purple-4 !text-white"
                                            : ""
                                        }
                                        
                                        `
                                      }
                                      value={film}
                                    >
                                      <p>
                                        {film.title}
                                        <small> ({film.duration} mins)</small>
                                      </p>
                                    </Combobox.Option>
                                  ))
                                )}
                              </Combobox.Options>
                            </Transition>
                          </div>
                        </div>
                      </Combobox>
                    </div>

                    <div className="relative pb-1 mb-2">
                      <label
                        htmlFor={`priceCardId`}
                        className="block mb-1 text-sm font-medium text-gray-900 "
                      >
                        Choose price card
                      </label>
                      <select
                        id={`priceCardId`}
                        value={newInfo.priceCardId}
                        onChange={(e) => {
                          handleChange("priceCardId", e.target.value);
                        }}
                        className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg "
                      >
                        <option value="">Select...</option>
                        {ticketTypes.map((type) => (
                          <option value={type.id} key={type.id}>
                            {type.name}
                          </option>
                        ))}
                      </select>

                      <InlineErr err={priceCardsErr} />
                    </div>
                    <div className="relative pb-1 mb-2">
                      <label
                        htmlFor={`status`}
                        className="block mb-1 text-sm font-medium text-gray-900 "
                      >
                        Status
                      </label>
                      <select
                        id={`status`}
                        className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                        value={newInfo?.status}
                        onChange={(e) => handleChange("status", e.target.value)}
                      >
                        <option value="">Select status...</option>
                        {showTimesStatusList.map((status, i) => (
                          <option value={status} key={status}>
                            {status}
                          </option>
                        ))}
                      </select>

                      <InlineErr
                        retry={getStatusOptList}
                        err={showTimesStatusListErr}
                        showTimesStatusListStatus={showTimesStatusListStatus}
                      />
                    </div>
                  </div>

                  <div className="relative grid items-center grid-cols-3 gap-2 py-2">
                    <div>
                      <label
                        htmlFor={`screenId`}
                        className="block mb-1 text-sm font-medium text-gray-900 "
                      >
                        Screen Id
                      </label>
                      <select
                        id={`screenId`}
                        className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg "
                        value={newInfo?.screenId}
                        onChange={(e) =>
                          handleChange("screenId", e.target.value)
                        }
                      >
                        <option value="">Select screen...</option>
                        {screens.map((screen) => (
                          <option value={screen.id} key={screen.id}>
                            {screen.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <label
                        htmlFor={`filmFormatId`}
                        className="block mb-1 text-sm font-medium text-gray-900 "
                      >
                        Film Format
                      </label>
                      <select
                        id={`filmFormatId`}
                        className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg "
                        value={newInfo?.filmFormatId}
                        onChange={(e) =>
                          handleChange("filmFormatId", e.target.value)
                        }
                      >
                        {/* <option value="">Select format...</option> */}
                        {formats.map((f) => (
                          <option value={f.id} key={f.id}>
                            {f.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <label
                        htmlFor={`startTime`}
                        className="block mb-1 text-sm font-medium text-gray-900 "
                      >
                        Start time
                      </label>

                      <DatePicker
                        minDate={new Date()}
                        dateFormat="Pp"
                        showTimeSelect
                        onChange={(value) => handleChange("startTime", value)}
                        selected={new Date(newInfo.startTime)}
                        customInput={<DateTimeInput />}
                        timeInputLabel="Time:"
                        showTimeInput
                      />
                    </div>
                    <div>
                      <label
                        htmlFor={`trailerTime`}
                        className="block mb-1 text-sm font-medium text-gray-900 "
                      >
                        Trailer Duration
                      </label>
                      <input
                        type="number"
                        min="0"
                        id={`trailerTime`}
                        className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg "
                        value={newInfo?.trailerTime}
                        onInput={(e) => {
                          handleChange("trailerTime", e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-center mt-6 mb-2 space-x-4">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md bg-rose-400 "
                      onClick={onClose}
                    >
                      Cancel
                    </button>

                    <button
                      type="submit"
                      className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-green-400 border border-transparent rounded-md"
                    >
                      Continue{" "}
                      <Spinner
                        className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                        status={editStatus}
                      />
                    </button>
                  </div>
                </form>

                <CreateSessionErr
                  onDismiss={() => setValidErrs([])}
                  errors={validErrs}
                />

                <CreateSessionErr
                  onDismiss={editResetErr}
                  errors={
                    !isEmpty(editErr)
                      ? [
                          {
                            id: 0,
                            message: editErr,
                          },
                        ]
                      : []
                  }
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export { EditSession };
