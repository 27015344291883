import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import add from "date-fns/add";
import endOfDay from "date-fns/endOfDay";
import startOfDay from "date-fns/startOfDay";
import {differenceInCalendarDays, differenceInMilliseconds,} from "date-fns";

import {
  CreateSession,
  DeleteSession,
  DuplicateAllSessions,
  DuplicateSession,
  EditSession,
  Error,
  FilmSession,
  LegendItem,
  Loading,
  Portal,
  PrivateRoute,
  Scheduler,
  Screen,
  ScrollUp,
  SessionContextMenu,
  ShowTimeDetails,
  SuccessToast,
} from "../../../components";
import {useLoggedInuser, useScheduling, useShowtimesStatuses,} from "../../../hooks";
import {
  API_URL,
  axiosApiInstance,
  FETCH_STATUS,
  ITEM_TYPES,
  onReject,
  Session,
  validateSessions,
} from "../../../utils";
import {
  getPriceCards,
  getShowtimesPerDay,
  getShowTimesStatusList,
  selectPiceCards,
  selectShowTimes,
} from "../../../redux/slices";
import differenceInMinutes from "date-fns/differenceInMinutes";
import domToPdf from "dom-to-pdf";

import DeleteAllSessions from "../../../components/Films/DeleteAllSession";

const getOldSessions = async ({
  duplicateToList = [],
  showTimesStatusList = [],
  dispatch,
}) => {
  const promises = duplicateToList?.map(async (x) => {
    const data = await dispatch(
      getShowtimesPerDay({
        start: startOfDay(new Date(x?.duplicateToDate)).toISOString(),
        end: endOfDay(new Date(x?.duplicateToDate)).toISOString(),
      })
    ).unwrap();
    const formattedData = data?.map((d) => {
      return {
        screenId: d.screenId,
        filmId: d.filmId,
        priceCardId: d.priceCardId,
        trailerTime: d.trailerTime,
        cleanUpTime: d.cleanUpTime,
        startTime: d.startTime,
        endTime: d.endTime,
        duration: d.duration,
        status:
          showTimesStatusList.findIndex((s) => s === d.status) > -1
            ? showTimesStatusList.findIndex((s) => s === d.status)
            : 0,
      };
    });

    return formattedData;
  });
  const oldSession = await Promise.all(promises);

  return oldSession.flat();
};

const FilmScheduling = () => {
  const scheduleId = document.getElementById("scheduleId");
  const downloadSchedule = () => {
    domToPdf(
      scheduleId,
      {
        filename: "FilmSchedule.pdf",
      },
      function (pdf) {
        pdf.save();
      }
    );
  };
  const {
    state,
    addSessions,
    getFilms,
    getSessions,
    getDaySessions,
    getFilmFormats,
    getScreens,
    updateSession,
    deleteSession,
  } = useScheduling();

  const {
    resetErr,
    setPending,
    setResolved,
    setRejected,
    err,
    status,
    deleteResetErr,
    deleteSetPending,
    deleteSetResolved,
    deleteSetRejected,
    deleteErr,
    deleteStatus,

    editResetErr,
    editSetPending,
    editSetResolved,
    editSetRejected,
    editErr,
    editStatus,
  } = useShowtimesStatuses();

  const [draggedInfo, setDraggedInfo] = useState({
    oldSession: null,
    newSession: null,
  });

  const { activeDate } = useSelector(selectShowTimes);

  const { profile } = useLoggedInuser();

  const dispatch = useDispatch();

  const {
    activePriceCards: priceCards,
    priceCardsStatus,
    priceCardsErr,
  } = useSelector(selectPiceCards);

  const { showTimesStatusList, showtimesPerDay, showTimesStatusListStatus } =
    useSelector(selectShowTimes);
  const [showCreate, setShowCreate] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showDuplicate, setShowDuplicate] = useState(false);
  const [showDeleteAll, setShowDeleteAll] = useState(false);
  const [showView, setShowView] = useState(false);
  const [showDuplicateAll, setShowDuplicateAll] = useState(false);
  const [sessionsDay, setSessionsDay] = useState([]);
  const [duplicateErr, setDuplicateErr] = useState([]);
  const [priceCardError, setPriceCardError] = useState(false);
  const [duplicatingAll, setDuplicatingAll] = useState(false);
  const toggleCreate = () => setShowCreate((s) => !s);
  const toggleDuplicate = () => setShowDuplicateAll((s) => !s);
  const toggleDeleteAll = () => setShowDeleteAll((s) => !s);

  const getInfo = useCallback(
    (force = false, date) => {
      if (force || state.filmsStatus === FETCH_STATUS.REJECTED) {
        getFilms();
      }
      if (force || state.screensStatus === FETCH_STATUS.REJECTED) {
        getScreens(force);
      }

      if (force || state.sessionsStatus === FETCH_STATUS.REJECTED) {
        getSessions(force, date);
      }

      if (force || priceCardsStatus === FETCH_STATUS.REJECTED) {
        dispatch(getPriceCards(force));
      }

      if (force || state.filmFormatStatus === FETCH_STATUS.REJECTED) {
        getFilmFormats();
      }
    },
    [
      state,
      getFilms,
      getSessions,
      dispatch,
      priceCardsStatus,
      getScreens,
      getFilmFormats,
    ]
  );

  const getInfoOnDuplicate = useCallback(
    (force = false, date) => {
      if (force || state.sessionsPerDayStatus === FETCH_STATUS.REJECTED) {
        getDaySessions(force, date);
      }
    },
    [getDaySessions, state]
  );

  const getStatusOptList = () => {
    dispatch(getShowTimesStatusList());
  };

  useEffect(() => {
    if (priceCardsStatus === FETCH_STATUS.IDLE) {
      dispatch(getPriceCards());
    }
  }, [dispatch, priceCardsStatus]);

  useEffect(() => {
    if (showTimesStatusListStatus === FETCH_STATUS.IDLE) {
      dispatch(getShowTimesStatusList());
    }
  }, [dispatch, showTimesStatusListStatus]);

  useEffect(() => {
    if (
      !showCreate ||
      !showEdit ||
      !showDelete ||
      !showDuplicate ||
      showDuplicateAll
    ) {
      setShowSuccess(false);
    }
  }, [showCreate, showEdit, showDelete, showDuplicate, showDuplicateAll]);

  //comment for Oba of Ikorodu

  // FOR SESSIONS PERDAY
  const sessionLoading = state.sessionsPerDayStatus === FETCH_STATUS.PENDING;
  const sessionLoaded = state.sessionsPerDayStatus === FETCH_STATUS.RESOLVED;
  const sessionErr = state.sessionsPerDayStatus === FETCH_STATUS.REJECTED;

  const showLoading =
    state.filmsStatus === FETCH_STATUS.PENDING ||
    state.sessionsStatus === FETCH_STATUS.PENDING ||
    state.screensStatus === FETCH_STATUS.PENDING ||
    state.filmFormatStatus === FETCH_STATUS.PENDING;

  const showErr =
    state.filmsStatus === FETCH_STATUS.REJECTED ||
    state.sessionsStatus === FETCH_STATUS.REJECTED ||
    state.screensStatus === FETCH_STATUS.REJECTED ||
    state.filmFormatStatus === FETCH_STATUS.REJECTED;
  //Console log to see which of these fails and throws the retry error
  // console.log(
  //   "List of status that could be throwing the error for film scheduling page"
  // );
  // console.log(`status for Films Status ${state.filmsStatus}`);
  // console.log(`status for Showtimes ${state.sessionsStatus}`);
  // console.log(`status for Screens ${state.screensStatus}`);
  // console.log(`status for FilmFormat ${state.filmFormatStatus}`);
  const loaded =
    state.filmsStatus === FETCH_STATUS.RESOLVED &&
    state.sessionsStatus === FETCH_STATUS.RESOLVED &&
    state.screensStatus === FETCH_STATUS.RESOLVED &&
    state.filmFormatStatus === FETCH_STATUS.RESOLVED;

  //USE EFFECT TO SET SESSIONS DAY
  useEffect(() => {
    if (sessionLoaded) {
      setSessionsDay(state.sessionsPerDay);
    }
  }, [sessionLoaded, state]);

  const events = useMemo(
    () => (loaded ? state.sessions : []),
    [loaded, state.sessions]
  );

  const screens = useMemo(
    () =>
      loaded
        ? Object.values(state.screens)
            .filter((s) => s.isActive)
            .reverse()
        : [],
    [loaded, state.screens]
  );

  const formats = useMemo(() => {
    return loaded ? state.filmFormats : [];
  }, [loaded, state.filmFormats]);

  //filtering the films here so just the films that are on the schedule would show
  const films = useMemo(
    () => (loaded ? Object.values(state.films) : []),
    [loaded, state.films]
  );

  const handleToastClose = () => {
    setShowCreate(false);
    setShowDelete(false);
    setShowEdit(false);
    setShowDuplicate(false);
    setDraggedInfo({ oldSession: null, newSession: null });
    setShowView(false);
    setShowDuplicateAll(false);
  };

  const onCreateSessions = useCallback(
    (data, callback) => {
      const fn = async () => {
        try {
          setPending();

          const body = {
            filmId: data.film.id,
            showtimes: data.sessions.map((sess) => ({
              screenId: sess.screenId,
              priceCardId: sess.priceCardTicket,
              trailerTime: sess.trailerDuration ?? 1,
              cleanUpTime: 1,
              filmFormatId: sess.filmFormatId,
              startTime: new Date(sess.startTime).toISOString(),
              endTime: new Date(sess.endTime).toISOString(),
              duration: Math.abs(
                differenceInMinutes(
                  new Date(sess.startTime),
                  new Date(sess.endTime)
                )
              ),
            })),
          };

          console.log(body);

          const res = await axiosApiInstance.post(
            `${API_URL}/Showtimes/CreateRange`,
            body
          );

          const sessions = res?.data?.data.map((s) => Session.toUI(s));

          addSessions(sessions);

          setResolved();
          setShowSuccess(true);

          callback && callback();
        } catch (error) {
          onReject(setRejected, error, "Unable to create new sessions");
        }
      };

      fn();
    },
    [addSessions, setPending, setRejected, setResolved]
  );

  const onDuplicateAllSessions = useCallback(
    async (data, selectedDays, callback) => {
      setDuplicatingAll(true);
      let sessionsArray = [];

      selectedDays?.duplicateToList?.forEach((x) => {
        data?.forEach((d) => {
        
          const modifiedSession = {
            screenId: d.screenId,
            filmId: d.filmId,
            priceCardId: x.selectedPriceCard || d.priceCardId,
            trailerTime: d.trailerTime,
            cleanUpTime: d.cleanUpTime,
            startTime: add(new Date(d.startTime), {
              days: differenceInCalendarDays(
                new Date(x.duplicateToDate),
                new Date(selectedDays.duplicateFromDate)
              ),
            }).toISOString(),
            endTime: add(new Date(d.endTime), {
              days: differenceInCalendarDays(
                new Date(x.duplicateToDate),
                new Date(selectedDays.duplicateFromDate)
              ),
            }).toISOString(),
            duration: d.duration,
            status:
              showTimesStatusList.findIndex((s) => s === d.status) > -1
                ? showTimesStatusList.findIndex((s) => s === d.status)
                : 0,
          };
          sessionsArray.push(modifiedSession)
        });

        // sessionsArray = [...sessionsArray, ...sessionsToCreate];
      });

      const oldSession = await getOldSessions({
        duplicateToList: selectedDays.duplicateToList,
        showTimesStatusList,
        dispatch,
      });

      // const body = sessionsArray;

      const errors = validateSessions(sessionsArray, oldSession);

      if (errors.length) {
        setDuplicateErr(errors);
      } else {
        const fn = async () => {
          try {
            setPending();

            const res = await axiosApiInstance.post(
              `${API_URL}/Showtimes/CreateBulk`,
              sessionsArray
            );

            setShowSuccess(true);
            const sessions = res?.data?.data.map((s) => Session.toUI(s));
            addSessions(sessions);
            setDuplicatingAll(false);
            setResolved();

            callback && callback();
          } catch (error) {
            setDuplicateErr(error);
            setRejected(error);

            // onReject(setRejected, error, "Unable to create new sessions");
          }
        };

        fn();
      }
    },
    [
      addSessions,
      setResolved,
      showTimesStatusList,
      setPending,
      events,
      setRejected,
    ]
  );

  const handleCreate = useCallback(
    (data, callback) => {
      onCreateSessions(data, callback);
    },
    [onCreateSessions]
  );

  const handleDuplicateAll = useCallback(
    (data, selectedDays, callback) => {
      onDuplicateAllSessions(data, selectedDays, callback);
    },
    [onDuplicateAllSessions]
  );

  const onDuplicate = useCallback(
    (data, callback) => {
      const body = {
        ...data,
        film: { id: draggedInfo.oldSession.filmId },
        title: draggedInfo.oldSession.film,
      };

      onCreateSessions(body, callback);
    },
    [onCreateSessions, draggedInfo]
  );

  const handleDrop = useCallback(
    ({ event, resourceId, newPosX, newRowWidth, activeDate }) => {
      const dayDiff = differenceInMilliseconds(
        endOfDay(new Date(activeDate)),
        startOfDay(new Date(activeDate))
      );

      const screenRatio = newPosX / newRowWidth;

      const newTime =
        dayDiff * screenRatio + startOfDay(new Date(activeDate)).getTime();

      const startTime = new Date(newTime).toISOString();
      const endTime = add(new Date(startTime), {
        minutes: state.films[event.filmId].duration,
      }).toISOString();

      const data = {
        newSession: {
          ...event,
          screenId: `${resourceId}`,
          startTime,
          endTime,
        },
        oldSession: { ...event },
      };

      setDraggedInfo(data);
      setShowEdit(true);
    },
    [state.films]
  );

  const handleEdit = useCallback(
    (data) => {
      const endTime = add(new Date(data.startTime), {
        minutes: state.films[data.filmId].duration + +data.trailerTime,
      });
      const startTime = new Date(data.startTime);
      const duration = Math.abs(
        differenceInMinutes(new Date(endTime), new Date(startTime))
      );

      const fn = async () => {
        editSetPending();

        try {
          const body = {
            ...data,
            duration: duration,
            startTime: startTime.toISOString(),
            endTime: endTime.toISOString(),
          };

          // if (body.seatsSold > 0) {
          //   editSetRejected("Unable to modify showtime with sold tickets");
          //   return;
          // }

          const res = await axiosApiInstance.patch(
            `${API_URL}/Showtimes/${data.id}/Update`,
            body
          );

          setShowSuccess(true);
          const updatedSession = Session.toUI(res?.data?.data);

          updateSession(updatedSession);
          setDraggedInfo({ oldSession: null, newSession: null });

          editSetResolved();
        } catch (error) {
          onReject(editSetRejected, error, "Unable to update session details");
        }
      };

      fn();
    },
    [
      state.films,
      updateSession,
      editSetPending,
      editSetRejected,
      editSetResolved,
    ]
  );

  const handleDelete = (event) => {
    const data = {
      newSession: { ...event },
      oldSession: { ...event },
    };

    setDraggedInfo(data);
    setShowDelete(true);
  };

  const handleDuplicate = (event) => {
    const data = {
      newSession: { ...event },
      oldSession: { ...event },
    };

    setDraggedInfo(data);
    setShowDuplicate(true);
  };

  const handleDeleteComplete = async (sessionId) => {
    deleteSetPending();

    try {
      await axiosApiInstance.delete(`${API_URL}/Showtimes/${sessionId}/Delete`);

      deleteSession(sessionId);
      deleteSetResolved();
      setShowSuccess(true);
    } catch (error) {
      onReject(deleteSetRejected, error, "Unable to delete show time");
    }
  };

  const handleUpdate = useCallback((event) => {
    const data = {
      newSession: { ...event },
      oldSession: { ...event },
    };

    setDraggedInfo(data);
    setShowEdit(true);
  }, []);

  const handleView = useCallback(
    (event) => {
      const data = {
        newSession: {
          ...event,
          screen: state.screens[event.screenId]?.name || "N/A",
        },
      };

      setDraggedInfo(data);
      setShowView(true);
    },
    [state.screens]
  );

  return (
    <PrivateRoute redirectTo="/dashboard/films-scheduling">
      <div className="-mt-2">
        <div className="flex items-center justify-between mb-10">
          <h3 className="text-lg font-medium text-black md:text-2xl ">
            Films Scheduling
          </h3>

          <nav
            className="flex text-[#C397C3] text-base md:text-lg "
            aria-label="Breadcrumb"
          >
            <ol className="inline-flex items-center space-x-1">
              <li className="inline-flex items-center">
                <span className="inline-flex items-center text-sm font-medium">
                  Film
                </span>
              </li>

              <li aria-current="page">
                <div className="flex items-center">
                  <svg
                    className="w-3.5 h-3.5 "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="ml-1 text-sm font-medium md:ml-2">
                    Film Scheduling
                  </span>
                </div>
              </li>
            </ol>
          </nav>
        </div>
        <div className="flex">
          <button
            className="relative block px-4 py-2 my-2 rounded-md text-app-purple-4 bg-app-yellow"
            type="button"
            onClick={() => getInfo(true, activeDate)}
          >
            Refresh
          </button>
          <button
            className="relative block px-4 py-2 my-2 rounded-md text-app-yellow bg-app-purple-4 ml-4"
            type="button"
            onClick={downloadSchedule}
          >
            Download Schedule
          </button>
        </div>

        <section className="relative" id={"scheduleId"}>
          <Scheduler
            renderContextMenu={({ bindMenu, bindMenuItem, data, hideMenu }) => (
              <SessionContextMenu
                handleDelete={handleDelete}
                handleDuplicate={handleDuplicate}
                handleUpdate={handleUpdate}
                handleView={handleView}
                bindMenuItem={bindMenuItem}
                event={data}
                hideMenu={hideMenu}
                bindMenu={bindMenu}
              />
            )} //wrap in portal cuz of fixed issue
            renderEvent={(event, mode) => (
              <FilmSession mode={mode} list={state.films} event={event} />
            )}
            renderResource={(itemId) => (
              <Screen list={state.screens} resourceId={itemId} />
            )}
            renderLegend={(item) => <LegendItem item={item} />}
            events={events}
            eventGroupByKey="screenId"
            resources={screens}
            resourceIdentifier="id"
            getInfo={(date) => getInfo(true, date)}
            legendItems={films.filter((s) => {
              return events.find((x) => {
                return x.filmId === s.id;
              });
            })}
            toggleCreate={toggleCreate}
            toggleDuplicate={toggleDuplicate}
            toggleDeleteAll={toggleDeleteAll}
            showLegend
            onDrop={handleDrop}
            dragItemType={ITEM_TYPES.SESSION}
            date={activeDate}
          />

          <Loading show={showLoading} wrapperClassName="!z-[1000]" />
          <Error
            error={"Unable to load one (or more) of screens/showtimes/films"}
            wrapperClass="!z-[10000]"
            show={showErr}
            retry={() => {
              getInfo(true, activeDate);
            }}
          />

          <Portal>
            <ScrollUp className="fixed z-30 bottom-3 right-4" />
          </Portal>

          {showCreate ? (
            <CreateSession
              screens={screens}
              formats={formats}
              films={films}
              onClose={() => setShowCreate(false)}
              show={showCreate}
              ticketTypes={priceCards}
              priceCardsErr={priceCardsErr}
              events={events}
              onCreate={handleCreate}
              cinemaId={profile?.cinemaId}
              showTimesStatusList={showTimesStatusList}
              resetErrs={resetErr}
              addingStatus={status}
              addingErr={err}
              showTimesStatusListErr={
                showTimesStatusListStatus === FETCH_STATUS.REJECTED
                  ? "Could not load status options"
                  : null
              }
              getStatusOptList={getStatusOptList}
              showTimesStatusListStatus={showTimesStatusListStatus}
            />
          ) : null}
          {showDuplicateAll ? (
            <DuplicateAllSessions
              onClose={() => setShowDuplicateAll(false)}
              show={showDuplicateAll}
              addingErr={err}
              priceCardsErr={priceCardsErr}
              priceCardError={priceCardError}
              ticketTypes={priceCards}
              resetErrs={resetErr}
              sessionLoaded={sessionLoaded}
              duplicateAllStatus={status}
              duplicatingAll={duplicatingAll}
              getInfo={(date) => getInfoOnDuplicate(true, date)}
              dayEvents={sessionsDay}
              allEvents={events}
              date={activeDate}
              state={state}
              onDuplicateAll={handleDuplicateAll}
              error={duplicateErr}
              setError={setDuplicateErr}
            />
          ) : null}

          {showEdit ? (
            <EditSession
              films={films}
              handleEdit={handleEdit}
              onClose={() => setShowEdit(false)}
              formats={formats}
              screens={screens}
              show={showEdit}
              oldSession={draggedInfo.oldSession}
              newSession={draggedInfo.newSession}
              sessionList={events}
              editResetErr={editResetErr}
              editErr={editErr}
              editStatus={editStatus}
              showTimesStatusList={showTimesStatusList}
              ticketTypes={priceCards}
              priceCardsErr={priceCardsErr}
              showTimesStatusListErr={
                showTimesStatusListStatus === FETCH_STATUS.REJECTED
                  ? "Could not load status options"
                  : null
              }
              getStatusOptList={getStatusOptList}
              showTimesStatusListStatus={showTimesStatusListStatus}
            />
          ) : null}

          <DeleteSession
            show={showDelete}
            screens={screens}
            onDelete={handleDeleteComplete}
            event={draggedInfo.newSession}
            onClose={() => setShowDelete(false)}
            resetDelErrs={deleteResetErr}
            delErr={deleteErr}
            delStatus={deleteStatus}
          />

          <DuplicateSession
            formats={formats}
            screens={screens}
            onClose={() => setShowDuplicate(false)}
            show={showDuplicate}
            ticketTypes={priceCards}
            priceCardsErr={priceCardsErr}
            events={events}
            onDuplicate={onDuplicate}
            activeSession={draggedInfo.oldSession}
            resetErrs={resetErr}
            addingStatus={status}
            addingErr={err}
          />

          <DeleteAllSessions
            openStatus={showDeleteAll}
            closeFunc={() => {
              setShowDeleteAll(false);
            }}
          />

          <ShowTimeDetails
            event={draggedInfo.newSession}
            show={showView}
            onClose={handleToastClose}
          />

          <SuccessToast
            show={showSuccess && showDuplicate}
            handleClose={handleToastClose}
            message={"Session duplicated successfully"}
          />
          <SuccessToast
            show={showSuccess && showDuplicateAll}
            handleClose={handleToastClose}
            message={"Session(s) duplicated successfully"}
          />

          <SuccessToast
            show={showSuccess && showCreate}
            handleClose={handleToastClose}
            message={"Session(s) created successfully"}
          />

          <SuccessToast
            show={showSuccess && showEdit}
            handleClose={handleToastClose}
            message={"Session edited successfully"}
          />

          <SuccessToast
            show={showSuccess && showDelete}
            handleClose={handleToastClose}
            message={"Session deleted successfully"}
          />
        </section>
      </div>
    </PrivateRoute>
  );
};

export { FilmScheduling };
