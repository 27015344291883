import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import add from "date-fns/add";
import differenceInMinutes from "date-fns/differenceInMinutes";
import isEmpty from "lodash/isEmpty";

import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  duplicateSessionInit,
  sessionInit,
  miniSession,
  validateSessions,
  duplicateSessionSchema,
} from "../../utils";
import CreateSessionRow from "./CreateSessionRow";
import CreateSessionErr from "./CreateSessionErr";
import { Spinner } from "../UI";

const DuplicateSession = ({
  formats,
  events,
  show,
  onClose,
  priceCardsErr,
  onDuplicate = () => {},
  screens = [],
  ticketTypes = [],
  activeSession,
  resetErrs,
  addingStatus,
  addingErr,
}) => {
  const {
    control,
    handleSubmit,
    watch,
    reset,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(duplicateSessionSchema),
    defaultValues: duplicateSessionInit,
  });
  const [validErrs, setValidErrs] = useState([]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "sessions",
  });
  const sessions = watch("sessions");
  const activeFilm = {
    duration: Math.abs(
      differenceInMinutes(
        new Date(activeSession?.startTime),
        new Date(activeSession?.endTime)
      )
    ),
  };

  const close = () => {
    reset({ ...sessionInit });
    onClose();
  };

  const onSubmit = (data) => {
    data.sessions = data.sessions.map((d) => ({
      ...d,
      endTime: add(new Date(d.startTime), {
        minutes: activeFilm?.duration || 0,
      }),
    }));

    const errors = validateSessions(data.sessions, events);

    if (errors.length) {
      setValidErrs(errors);
    } else {
      onDuplicate(data, () => reset({ ...duplicateSessionInit }));
    }
  };

  return (
    <Transition show={show} as={Fragment}>
      <Dialog as="div" className="relative z-[1000000]" onClose={close}>
        {/* Overlay */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        {/* Content */}

        <div className="fixed inset-0 p-4 overflow-y-auto">
          <div className="flex items-center justify-center min-h-[400px] p-2 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full min-h-[400px] max-w-2xl p-6  text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title className="relative pb-4 border-b" as="div">
                  <p className="text-lg font-medium leading-6 text-gray-900">
                    Duplicate Film Session<span className="text-sm">(</span>s
                    <span className="text-sm">)</span>
                  </p>
                  <button
                    type="button"
                    onClick={close}
                    className="absolute right-0 top-2/4 -translate-y-2/4 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </Dialog.Title>

                <form className="my-6" onSubmit={handleSubmit(onSubmit)}>
                  <h3 className="my-1 text-lg font-medium">
                    You are about to duplicate sessions for{" "}
                    <i className="text-app-purple-3">
                      {activeSession?.title ||
                        activeSession?.film ||
                        activeSession?.id}
                    </i>{" "}
                    :
                  </h3>

                  <h4 className="mt-5 text-base">Add sessions</h4>
                  <hr className="my-2" />

                  <div className="relative">
                    {!sessions.length ? (
                      <small className="block my-1 font-medium text-center text-rose-500">
                        Please create at least one session
                      </small>
                    ) : null}
                    {fields.map((field, index) => (
                      <CreateSessionRow
                        formats={formats}
                        key={index}
                        register={register}
                        ticketTypes={ticketTypes}
                        activeFilm={activeFilm}
                        index={index}
                        control={control}
                        sessions={sessions}
                        remove={remove}
                        screens={screens}
                        errors={errors}
                        priceCardsErr={priceCardsErr}
                      />
                    ))}
                  </div>

                  <button
                    onClick={() => append({ ...miniSession })}
                    type="button"
                    className="p-2 mt-2 text-sm text-white bg-green-400 rounded-lg"
                  >
                    Add session
                  </button>

                  <div className="flex items-center justify-center mt-4">
                    <button
                      type="submit"
                      className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md bg-app-purple-6 "
                    >
                      Submit{" "}
                      <Spinner
                        className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                        status={addingStatus}
                      />
                    </button>
                  </div>
                </form>

                <CreateSessionErr
                  onDismiss={() => setValidErrs([])}
                  errors={validErrs}
                />

                <CreateSessionErr
                  onDismiss={resetErrs}
                  errors={
                    !isEmpty(addingErr)
                      ? [
                          {
                            id: 0,
                            message: addingErr,
                          },
                        ]
                      : []
                  }
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export { DuplicateSession };
