import * as yup from "yup";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { subDays } from "date-fns";
import { screenFormat } from "./constants";

function isValidPhone(message = "Please input a valid phone number") {
  return this.test("isValidPhone", message, function (value) {
    const { path, createError } = this;

    const num = value ? (value[0] === "+" ? value : `+${value}`) : "";

    if (value && !isPossiblePhoneNumber(num)) {
      return createError({ path, message });
    }

    return true;
  });
}
yup.addMethod(yup.mixed, "isValidPhone", isValidPhone);

const pwdRegex =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

const pinRegex = /^\d{4,6}$/;

// const imgRegEx =
//   /^https?:\/\/(.+\/)+.+(\.(gif|png|jpg|jpeg|webp|svg|psd|bmp|tif))$/i;

const loginSchema = yup
  .object()
  .shape({
    userName: yup.string().required("Email is required"),
    password: yup.string().required("Password is required"),
  })
  .required();

const changePasswordSchema = yup.object().shape({
  defaultPassword: yup.string(),
  newPassword: yup.string().required("Password is required"),

  confirmPassword: yup
    .string()
    .trim()
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Password is required"),
});

const changePasswordInit = {
  defaultPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const sessionSchema = yup
  .object()
  .shape({
    film: yup.object().required("Please select a film"),
    status: yup.string().required("Please select a status"),
    sessions: yup
      .array()
      .min(1, "Select at least one session")
      .of(
        yup.object().shape({
          screenId: yup.string().required("Please select a screen"),
          filmFormatId: yup.string().required("Please select a screen format"),
          priceCardTicket: yup
            .string()
            .required("Please select a price card type"),
          startTime: yup
            .string()
            .required("Please select at least a start time"),
          trailerDuration: yup
            .number()
            .typeError("")
            .min(0, "Min value 0")
            .default(1),
        })
      ),
  })
  .required();

const duplicateSessionSchema = yup
  .object()
  .shape({
    sessions: yup.array().of(
      yup
        .object()
        .shape({
          screenId: yup.string().required("Please select a screen"),
          priceCardTicket: yup
            .string()
            .required("Please select a price card type"),
          startTime: yup
            .string()
            .required("Please select at least a start time"),
          trailerDuration: yup
            .number()
            .typeError("")
            .min(0, "Min value 0")
            .default(1),
        })
        .required()
    ),
  })
  .required();

const priceCardSchema = yup
  .object()
  .shape({
    active: yup.string().default("true").required("Please select a status"),
    name: yup.string().required("Name is required"),
    description: yup.string(),
    days: yup
      .array()
      .min(1, "Select at least one day")
      .of(yup.number().integer().typeError(""))
      .required("Please select at least one valid day"),
    tickets: yup.array().of(
      yup.object().shape({
        id: yup.string(),
        price: yup.number().typeError("").min(0, "Enter a valid price >= 0"),
      })
    ),
  })
  .required();

const ticketSchema = yup
  .object()
  .shape({
    // active: yup.string().default("true").required("Please select a status"),
    isActive: yup.string().default("true").required("Please select a status"),
    isLoyalty: yup.string().default("false").required("Please select a status"),
    openTicket: yup
      .string()
      .default("false")
      .required("Please select a status"),
    name: yup.string().required("Name is required"),
    type: yup.string().required("Short name is required"),
    class: yup.string().required("Class is required"),
    tax: yup.string(),
    seatClassId: yup.string().nullable(true),
    price: yup.string().default("0").required("Price is required"),
    isComplementary: yup.boolean(),
    isVoucherComplementary: yup.boolean(),
    // channels: yup
    //   .array()
    //   .min(1, "Select at least one channel")
    //   .of(yup.string())
    //   .required("Please select at least one valid channel"),
    sourceIds: yup
      .array("Select at least one Sales Source")
      .min(1, "Select at least one Sales Source")
      .of(yup.string("Select at least one Sales Source"))
      .required("Sales source is required"),
  })
  .required();

const screenSchema = yup
  .object()
  .shape({
    shortName: yup.string().required("Screen name is required"),
    screenNumber: yup
      .number()
      .integer()
      .typeError("Please enter a number")
      .required("Screen number is required"),
    filmFormats: yup.array().of(yup.string().required("Select a film")),
    numberOfRows: yup.string(),
    numberOfColumns: yup.string(),
    totalSeats: yup
      .number()
      .integer()
      .typeError("Please enter a valid whole number")
      .required("Total seats count is required"),
    standardSeats: yup.string().default("1"),
    houseSeats: yup.string().default("1"),
    seatLayouts: yup
      .array()

      .of(
        yup.object().shape({
          numOfSeats: yup.string(),
          name: yup.string(),
          rowNumber: yup.string(),
          definitions: yup.array().of(
            yup.object().shape({
              seatClassId: yup.string().nullable(),

              seatNumber: yup.number().nullable(),
              columnNumber: yup.number(),

              status: yup.number(),
            })
          ),
        })
      ),
    isActive: yup.string().default("true").required("Please select a status"),
  })
  .required();

const screenInit = {
  seatLayouts: [
    // {
    //   name: "",
    //   numOfSeats: "",
    //   rowNumber: "",
    //   definitions: [
    //     {
    //       seatClassId: "",
    //       seatName: null,
    //       seatNumber: "",
    //       // rowNumber: "",
    //       status: 0,
    //     },
    //   ],
    // },
  ],
  shortName: "",
  screenNumber: "",
  filmFormats: [screenFormat.standard_2d],
  totalSeats: "",
  standardSeats: "1",
  houseSeats: "1",
  numberOfRows: "0",
  numberOfColumns: "0",

  isActive: "true",
};

const locationSchema = yup
  .object()
  .shape({
    name: yup.string().required(),
    description: yup.string().required(),
    email: yup.string().required(),
    phone: yup.string().required(),
    location: yup.string().required(),
    address: yup.string().required(),
  })
  .required();

const pwdSchema = yup
  .object()
  .shape({
    oldPassword: yup
      .string()
      .required("Enter old password")
      .matches(
        pwdRegex,
        "Must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character"
      ),
    newPassword: yup
      .string()
      .required("Enter new password")
      .matches(
        pwdRegex,
        "Must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character"
      ),
  })
  .required();

const staffSessionSchema = yup
  .object()
  .shape({
    sessionId: yup.string().required("Session Id is required"),
    actualCash: yup
      .number()
      .typeError("Actual cash amount must be>=0")
      .min(0, "Min value 0"),
    actualCard: yup
      .number()
      .typeError("Actual card amount must be>=0")
      .min(0, "Min value 0"),
    actualTransfer: yup
      .number()
      .typeError("Actual transfer amount must be>=0")
      .min(0, "Min value 0"),
    actualExternalVoucher: yup
      .number()
      .typeError("Actual voucher amount must be>=0")
      .min(0, "Min value 0"),
    actualOtherChannel: yup
      .number()
      .typeError("Actual voucher amount must be>=0")
      .min(0, "Min value 0"),
    sessionChannels: yup.array().of(
      yup.object({
        otherChannelId: yup.string(),
        actual:yup.number()
      })
    )
  })
  .required();

const distributorByFilmSchema = yup
  .object()
  .shape({
    CinemaId: yup.string().required("Select a Cinema"),
    DistributorId: yup.string().required("Select a Distributor"),
    FilmId: yup.string().required("Select a Film").nullable(),
    DateFrom: yup.string().required("Select a Start Date"),
    DateTo: yup.string().required("Select an end Date"),
  })
  .required();

const itemSchema = yup
  .object()
  .shape({
    name: yup
      .string()
      .required("Name is required")
      .min(3, "Name should be at least 3 characters"),
    itemGroupId: yup.string().required("Item group is required"),
    itemClassId: yup.string().required("Item class is required"),
    vendorId: yup.string(),
    tax: yup.number(),
    unitOfMeasurementId: yup.string(),
    unit: yup.number(),
    cost: yup.number(),
    price: yup.number().required("Price is required"),
    isPriceModifiable: yup.boolean(),
    serviceArea: yup.number().required("Service Area is required"),
    items: yup.array().nullable(),
    parentItems: yup.array().nullable(),
    isActive: yup.boolean().default(true).required("Please select a status"),
    imageUrl: yup.string(),
    // .url("Please select a valid file")
    // .required("Image is required"),
    sourceIds: yup.array().nullable(),
  })
  .required();

const itemsInit = {
  name: "",
  itemGroupId: "",
  itemClassId: "",
  vendorId: "",
  tax: "",
  unitOfMeasurementId: "",
  unit: 1,
  cost: "",
  price: "",
  items: [],
  parentItems: [],
  isActive: "true",
  imageUrl: "",
  sourceIds: [],
  serviceArea: 0,
};

const editParentItemSchema = yup
  .object()
  .shape({
    name: yup
      .string()
      .required("Name is required")
      .min(3, "Name should be at least 3 characters"),
    itemGroupId: yup.string().required("Item group is required"),
    itemClassId: yup.string().required("Item class is required"),
    vendorId: yup.string(),
    tax: yup.number(),
    unitOfMeasure: yup.string(),
    // unit: yup.string(),
    cost: yup.number(),
    price: yup.number().required("Price is required"),
    items: yup.array().nullable(),
    parentItems: yup.array().nullable(),
    isActive: yup.boolean().default(true).required("Please select a status"),
    imageUrl: yup
      .string()
      .url("Please select a valid file")
      .required("Image is required"),
    // .matches(imgRegEx, "Please select a valid file")
  })
  .required();

const stockRequestSchema = yup.object().shape({
  vendorId: yup.string(),
  deliveryLocationId: yup
    .string()
    .required("Ensure you select a delivery location"),
  senderLocationId: yup.string(),
  senderCinemaId: yup.string(),
  requisitionType: yup
    .number("Ensure you select a requisition type")
    .required("Ensure you select a requisition type"),
  requestDate: yup.string().required("Ensure you select a Request date"),
});

export const createStockTakeSchema = yup
  .object()
  .shape({
    storeLocationId: yup.string().required("Select a store location"),
    expenseAccount: yup.string().required("Select an expense account"),
    effectiveDate: yup.string().required("date is required"),
    reference: yup.string().required("Reference is required"),
    items: yup
      .array(
        yup.object({
          itemStoreId: yup.string().required(),
          unitOfMeasurementId: yup.string().required(),
          quantityOnHand: yup.number().required(),
          stockTakeQuantity: yup.number().required(),
          variance: yup.number().required(),
        })
      )
      .min(1),
  })
  .required();

export const stockTakeInit = {
  storeLocationId: "",
  expenseAccount: "",
  effectiveDate: new Date().toISOString(),
  reference: "",
  items: [],
};

const purchaseOrdSchema = yup.object().shape({
  purchaseAgreement: yup
    .string()
    .required("Ensure you enter a purchase agreement"),
  confirmationDate: yup
    .string()
    .required("Ensure you select a confirmation date"),
  effectiveDate: yup.string().required("Ensure you select an effective date"),
  currency: yup.string().required("Ensure you select a currency"),
});

const stockReceiptSchema = yup.object().shape({
  invoiceDate: yup.string().required("Ensure you select an invoice date"),
  vendorReceipt: yup.string().required("Ensure you add an Invoice Receipt"),
});

const getstockTransSchema = yup.object().shape({
  fetchType: yup.number().required("Fetch type is required"),
  status: yup.number().required("Status is required"),
});

const staffSchema = yup
  .object()
  .shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    //genderId: yup.string().required("Select a gender"),
    userName: yup.string().required("Enter username"),
    email: yup.string().required("Email is required"),
    password: yup
      .string()
      .required("Enter password")
      .matches(
        pwdRegex,
        "Must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character"
      ),
    roleName: yup.string().required("Choose a role"),
    phoneNumber: yup.string().required().isValidPhone(),
  })
  .required();

const createPinSchema = yup
  .object()
  .shape({
    userId: yup.string().required("User id required"),
    pin: yup
      .string()
      .required("Pin required")
      .matches(pinRegex, "Must be 4 to 6 chars digits long"),
    pinConfirmation: yup.string().oneOf([yup.ref("pin")], "Pins must match"),
  })
  .required();

const updatePinSchema = yup
  .object()
  .shape({
    userId: yup.string().required("User id required"),
    pin: yup
      .string()
      .required("New pin required")
      .matches(pinRegex, "Must be 4 to 6 digits long"),
  })
  .required();

const createPinInit = {
  userId: "",
  pin: "",
  pinConfirmation: "",
};

const updatePinInit = {
  userId: "",
  pin: "",
};

const loginInit = {
  userName: "",
  password: "",
};

const ticketInit = {
  name: "",
  // active: "true",
  isActive: "true",
  openTicket: "false",
  isLoyalty: "false",
  type: "",
  class: "",
  price: 0,
  channels: [],
  seatClassId: "",
  tax: 0,
  isComplementary: false,
  isVoucherComplementary: false,
  sourceIds: [],
};

const priceCardInit = {
  name: "",
  days: [],
  active: "true",
  tickets: [],
  description: "",
};

const miniSession = {
  screenId: "",
  filmFormatId: "d4002274-7acd-4e3e-ab8e-154db20ff2c9",
  priceCardTicket: "",
  trailerDuration: "1",
  startTime: new Date(),
};

const sessionInit = {
  film: null,
  status: "Open",
  sessions: [
    {
      ...miniSession,
    },
  ],
};

const duplicateSessionInit = {
  sessions: [
    {
      ...miniSession,
    },
  ],
};

const staffInit = {
  firstName: "",
  lastName: "",
  userName: "",
  email: "",
  password: "",
  roleName: "",
  phoneNumber: "",
};

const pwdInit = {
  oldPassword: "",
  newPassword: "",
};

const staffSessionInit = {
  sessionId: "",
  actualCash: "",
  actualCard: "",
  actualTransfer: "",
  actualExternalVoucher: "",
  actualOtherChannel: "",
  sessionChannels:[]
};

const distributorByFilmInit = {
  CinemaId: "",
  DistributorId: "",
  FilmId: "allFilms",
  DateFrom: "",
  DateTo: "",
};

const itemSalesSchema = yup
  .object()
  .shape({
    CinemaId: yup.string().required("Select a Cinema"),
    DateFrom: yup.string().required("Select a Start Date"),
    DateTo: yup.string().required("Select an end Date"),
  })
  .required();
const itemDetailSalesSchema = yup
  .object()
  .shape({
    CinemaId: yup.string().required("Select a Cinema"),
    DateFrom: yup.string().required("Select a Start Date"),
    DateTo: yup.string().required("Select an end Date"),
    userId: yup.string().required("Select a User"),
    itemId: yup.string().required("Select Item"),
  })
  .required();

const gboPerformanceSchema = yup
  .object()
  .shape({
    CinemaId: yup.string().required("Select a Cinema"),
    DateFrom: yup.string().required("Select a Start Date"),
    DateTo: yup.string().required("Select an end Date"),
  })
  .required();

const sessionReportSchema = yup
  .object()
  .shape({
    userId: yup.string().required("Select a User"),
    dateFilter: yup.string().required("Select a Date"),
  })
  .required();

export const cinemaSessionReportSchema = yup
  .object()
  .shape({
    cinemaId: yup.string().required("Select a Cinema"),
    dateFrom: yup.string().required("Select a Date"),
    dateTo: yup.string().required("Select a Date"),
  })
  .required();

export const cinemaSessionReportInit = {
  cinemaId: "",
  dateFrom: subDays(new Date(), 7).toISOString(),
  dateTo: new Date().toISOString(),
};

const refundsReportSchema = yup
  .object()
  .shape({
    userId: yup.string().required("Select a User"),
    dateFilter: yup.string().required("Select a Date"),
  })
  .required();

const dailyCashupReportSchema = yup
  .object()
  .shape({
    CinemaId: yup.string().required("Select a Cinema"),
    todayDate: yup.string().required("Select a Date"),
  })
  .required();

const adminActionSchema = yup
  .object()
  .shape({
    userId: yup.string().required("Select a User"),
    todayDate: yup.string().required("Select a Date"),
  })
  .required();

const ordersBySourceAndChannelSchema = yup
  .object()
  .shape({
    dateFrom: yup.string().required("Select a  start date"),
    dateTo: yup.string().required("Select an end date"),
    sourceId: yup.string().required("Select a Source"),
    cinemaId: yup.string().required("Select a cinema"),
    channelId: yup.string().required("Select a Channel"),
  })
  .required();

const onlineOrdersSchema = yup
  .object()
  .shape({
    dateFrom: yup.string().required("Select a Date"),
    dateTo: yup.string().required("Select a Date"),
  })
  .required();

const partialRefundSchema = yup
  .object()
  .shape({
    reason: yup.string(),
    supplimentaryOrderId: yup.string(),
    refundItems: yup.array().of(
      yup.object().shape({
        itemSaleId: yup.string(),
        quantity: yup.number(),
        name: yup.string(),
        price: yup.number(),
        max: yup.number(),
      })
    ),
    refundTickets: yup.array().of(
      yup.object().shape({
        ticketSaleId: yup.string(),
        quantity: yup.number(),
        name: yup.string(),
        price: yup.number(),
        max: yup.number(),
      })
    ),
    amountInSession: yup.number(),
    refundPaymentChannels: yup.array().of(
      yup.object().shape({
        channelId: yup.string(),
        amount: yup.number(),
        channel: yup.string(),
        maxAmount: yup.number(),
      })
    ),
  })
  .required();

export const locationInit = {
  id: "",
  name: "",
  description: "",
  email: "",
  phone: "",
  location: "",
  address: "",
  cinemaId: "",
};

export const vendorSchema = yup
  .object()
  .shape({
    name: yup.string().required(),
    email: yup.string().required(),
    phoneNumber: yup.string().required(),
    primaryContact: yup.string().required(),
    website: yup.string().required(),
    currency: yup.string().required(),
    paymentTerms: yup.string().required(),
    streetName: yup.string().required(),
    streetNumber: yup.string().required(),
    streetLocation: yup.string().required(),
    contactPersonName: yup.string().required(),
    contactPersonPhoneNumber: yup.string().required(),
    contactPersonEmail: yup.string().required(),
  })
  .required();

export const vendorInit = {
  name: "",
  email: "",
  phoneNumber: "",
  primaryContact: "",
  website: "",
  currency: "",
  paymentTerms: "",
  streetName: "",
  streetNumber: "",
  streetLocation: "",
  contactPersonName: "",
  contactPersonPhoneNumber: "",
  contactPersonEmail: "",
};

const metaDataSchema = yup.object().shape({
  address: yup.string().required("Input an address"),
  logoUrl: yup.string().required("Enter logo Url"),
  email: yup.string().required("Input an email"),
  phoneNumber: yup.string().required("Select address"),
  filmIds: yup.array().of(
    yup.object().shape({
      filmId: yup.string(),
    })
  ),
  useFilmSchedule: yup.boolean(),
  bannerUrls: yup.array().of(yup.string()),
  // bannerUrls: yup.array().of(yup.string().required("Select a bannerUrl")),
  imageUrls: yup.array().of(yup.string()),
  // imageUrls: yup.array().of(yup.string().required("Select an imageUrl")),
});

const metaDataInit = {
  address: "",
  logoUrl: "",
  useFilmSchedule: false,
  bannerUrls: [],
  imageUrls: [],
  email: "",
  phoneNumber: "",
  filmIds: [],
};

const filmContractSchema = yup
  .object()
  .shape({
    cinemaIds: yup
      .array()
      .of(yup.string().required("Please select a cinema name")),
    film: yup.string().required("Please select a film name"),
    contractType: yup.number().required("Please select a contract type"),
    startDate: yup.string().required("Please select a contract start"),
    contractDates: yup
      .array()
      .min(1, "Select at least one contract date")
      .of(
        yup.object().shape({
          weekNumber: yup.number().default(1),
          percentage: yup
            .number()
            .typeError("")
            .min(0, "Min value 0")
            .default(0)
            .required("Please select a percentage"),
          minGuarantee: yup
            .number()
            .typeError("")
            .min(0, "Min value 0")
            .default(0),
        })
      ),
  })
  .required();

const minicontractDates = {
  weekNumber: null,
  percentage: null,
  minGuarantee: null,
};

const filmContractInit = {
  cinemaIds: [],
  film: null,
  contractType: null,
  startDate: new Date().toISOString(),
  contractDates: [
    {
      ...minicontractDates,
    },
  ],
};

const scheduleReportSchema = yup.object().shape({
  name: yup.string().required("Enter a name for the Schedule"),
  frequency: yup.number().required("Enter the Frequency of the schedule"),
  frequencyType: yup
    .number()
    .required("Enter the Frequency type of the schedule"),
  sendTime: yup.string().required("Select the time the schedule Should send"),
  sendDay: yup.number(),
  sendEmpty: yup.boolean(),
  modifyRange: yup.boolean(),
  reportFileFormat: yup
    .number()
    .required("Select the file format of the schedule"),
  reportType: yup.number().required("Select the report type"),
  daysBefore: yup.number(),
  daysAfter: yup.number(),
  filmId: yup.string().nullable(),
  distributorId: yup.string(),
  emailAddresses: yup
    .array()
    .min(1, "Enter at least one email")
    .of(
      yup.object().shape({
        email: yup.string().default(1),
      })
    ),
  isActive: yup.boolean(),
});

const duplicateAllSessionsSchema = yup.object().shape({
  duplicateFromDate: yup.string().required("Select the date to duplicate from"),
  duplicateToList: yup
    .array()
    .min(1, "Add at least One date to duplicate to ")
    .of(
      yup.object().shape({
        duplicateToDate: yup
          .string()
          .required("Select the date to duplicate to"),
        selectedPriceCard: yup.string(),
      })
    ),
});

const duplicateAllSessionsInit = {
  duplicateFromDate: new Date().toISOString(),
  duplicateToList: [
    {
      duplicateToDate: new Date().toISOString(),
      selectedPriceCard: "",
    },
  ],
};

const scheduleReportInit = {
  name: "",
  frequency: 1,
  frequencyType: 0,
  sendTime: "0000-00-00T08:00:00.000Z",
  sendDay: 0,
  sendEmpty: true,
  reportFileFormat: 0,
  reportType: "",
  daysBefore: 0,
  daysAfter: 0,
  filmId: null,
  distributorId: "",
  isActive: true,
  emailAddresses: [
    {
      email: "",
    },
  ],
};
const contractReportSchema = yup
  .object()
  .shape({
    dateFrom: yup.string().required("Please select a valid date"),
    dateTo: yup.string().required("Please select a valid date"),
    cinemaId: yup.string().required(),
    distributorId: yup.string().required("Please select a valid distributor"),
  })
  .required();

const contractReportInit = {
  dateFrom: subDays(new Date(), 2).toISOString(),
  dateTo: new Date().toISOString(),
  cinemaId: "",
  distributorId: "allDistributors",
};

const editClaimsSchema = yup
  .object()
  .shape({
    userId: yup.string().required("make sure userId is available"),
    claims: yup.array().of(yup.string().required("select a claim")),
  })
  .required();

const editClaimInit = {
  userId: "",
  claims: [],
};

const GBOByTicketSchema = yup
  .object()
  .shape({
    cinemaId: yup.string().required("Select a Cinema"),
    distributorId: yup.string().required("Select a Distributor"),
    filmId: yup.string().required("Select a Film").nullable(),
    dateFrom: yup.string().required("Select a Start Date"),
    dateTo: yup.string().required("Select an end Date"),
  })
  .required();

const GBOByTicketInit = {
  cinemaId: "",
  distributorId: "",
  filmId: "",
  dateFrom: subDays(new Date(), 7).toISOString(),
  dateTo: new Date().toISOString(),
};

const GBOByScreenFormatSchema = yup
  .object()
  .shape({
    screenFormatId: yup.string().required("Select a Film").nullable(),
    dateFrom: yup.string().required("Select a Start Date"),
    dateTo: yup.string().required("Select an end Date"),
  })
  .required();

const GBOByScreenFormatInit = {
  screenFormatId: "allFormats",
  dateFrom: subDays(new Date(), 7).toISOString(),
  dateTo: new Date().toISOString(),
};

const POSLayoutSchema = yup
  .object()
  .shape({
    posLayout: yup
      .array()
      // .max(4)
      .of(
        yup.object().shape({
          serviceArea: yup.number(),
          pages: yup
            .array()
            // .max(2, "you can only add 10 items" )
            .of(
              yup.object().shape({
                pageName: yup.string(),
                pageItems: yup
                  .array()
                  // .max(2, "you can only add 15 items")
                  .of(
                    yup.object().shape({
                      itemId: yup.string(),
                    })
                  ),
              })
            ),
        })
      ),
  })
  .required();

const POSLayoutInit = {
  posLayout: [
    {
      serviceArea: "",
      pages: [{ pageName: "", pageItems: [{ itemId: "" }] }],
    },
  ],
};

const kioskLayoutSchema = yup.object().shape({
  kioskLayout: yup
    .array()
    // .max(4)
    .of(
      yup.object().shape({
        pageName: yup.string(),
        pageItems: yup
          .array()
          .max(24, "you can only add 24 items")
          .of(
            yup.object().shape({
              itemId: yup.string(),
            })
          ),

        // pages: yup
        //   .array()
        //   .max(10)
        //   .of(
        //     yup.object().shape({
        //       pageName: yup.string(),
        //       pageItems: yup
        //         .array()
        //         .max(24, "you can only add 24 items")
        //         .of(
        //           yup.object().shape({
        //             itemId: yup.string(),
        //           })
        //         ),
        //     })
        //   ),
      })
    ),
});

const kioskLayoutInit = {
  kioskLayout: [
    {
      pageName: "",
      pageItems: [
        {
          itemId: "",
        },
      ],
    },
    // {
    //   pages: [
    //     {
    //       pageName: "",
    //       pageItems: [
    //         {
    //           itemId: "",
    //         },
    //       ],
    //     },
    //   ],
    // },
  ],
};
const GBOFilmReportsSchema = yup
  .object()
  .shape({
    cinemaId: yup.string().required("Select a Cinema"),
    sourceId: yup.string().required("Select a source"),
    includeComp: yup.boolean().required(""),
    startDate: yup.string().required("Select a Start Date"),
    endDate: yup.string().required("Select an end Date"),
  })
  .required();

const GBOFilmReportsInit = {
  startDate: new Date().toISOString(),
  endDate: new Date().toISOString(),
  cinemaId: "",
  sourceId: "",
  includeComp: false,
  filmId: "",
  distributorId:""
  
};

const bookingByGenreSchema = yup.object().shape({
  dateFrom: yup.string().required(),
  dateTo: yup.string().required(),
  cinemaId: yup.string().required(),
  genreIds: yup.array().of(yup.string()),
});

const bookingByGenreInit = {
  dateFrom: subDays(new Date(), 7).toISOString(),
  dateTo: new Date().toISOString(),
  cinemaId: "",
  genreIds: [],
};

const rosterSchema = yup.object().shape({
  rosterSessions: yup
    .array()
    .min(1, "Select at least one roster Session")
    .of(
      yup.object().shape({
        shiftId: yup.string().required("select a shift"),
        rosterTaskId: yup.string().required("select a roster task"),
        userId: yup.array().of(yup.string().required("select a staff")),
        scheduledDate: yup.string().required("select a scheduled date"),
      })
    ),
});

const rosterInit = {
  rosterSessions: [
    {
      shiftId: "",
      rosterTaskId: "",
      userId: [],
      scheduledDate: new Date().toISOString(),
    },
  ],
};

const sendRosterSchema = yup.object().shape({
  dateFrom: yup.string().required(),
  dateTo: yup.string().required(),
  includeAllStaff: yup.boolean(),
  staffId: yup.array().of(yup.string()),
});

const sendRosterInit = {
  dateFrom: subDays(new Date(), 7).toISOString(),
  dateTo: new Date().toISOString(),
  includeAllStaff: true,
  staffId: [],
};

const createChannelSchema = yup.object().shape({
  name: yup.string().required("Enter channel name"),
  isActive:yup.string().required()
});

const createChannelInit = {
  name: "",
  isActive:"true"
};

export {
  loginSchema,
  loginInit,
  changePasswordSchema,
  changePasswordInit,
  sessionInit,
  sessionSchema,
  miniSession,
  duplicateSessionSchema,
  duplicateSessionInit,
  priceCardSchema,
  priceCardInit,
  ticketInit,
  ticketSchema,
  locationSchema,
  itemSchema,
  editParentItemSchema,
  screenInit,
  screenSchema,
  staffSchema,
  staffInit,
  staffSessionSchema,
  staffSessionInit,
  pwdRegex,
  distributorByFilmSchema,
  distributorByFilmInit,
  pwdInit,
  pwdSchema,
  createPinInit,
  createPinSchema,
  updatePinInit,
  updatePinSchema,
  itemSalesSchema,
  sessionReportSchema,
  gboPerformanceSchema,
  itemDetailSalesSchema,
  refundsReportSchema,
  dailyCashupReportSchema,
  adminActionSchema,
  ordersBySourceAndChannelSchema,
  onlineOrdersSchema,
  metaDataSchema,
  metaDataInit,
  stockRequestSchema,
  purchaseOrdSchema,
  stockReceiptSchema,
  filmContractInit,
  filmContractSchema,
  contractReportInit,
  contractReportSchema,
  minicontractDates,
  getstockTransSchema,
  scheduleReportSchema,
  scheduleReportInit,
  editClaimInit,
  editClaimsSchema,
  GBOByTicketInit,
  GBOByTicketSchema,
  duplicateAllSessionsInit,
  duplicateAllSessionsSchema,
  partialRefundSchema,
  POSLayoutSchema,
  POSLayoutInit,
  GBOFilmReportsInit,
  GBOFilmReportsSchema,
  bookingByGenreInit,
  bookingByGenreSchema,
  rosterInit,
  rosterSchema,
  sendRosterInit,
  sendRosterSchema,
  kioskLayoutSchema,
  kioskLayoutInit,
  GBOByScreenFormatSchema,
  GBOByScreenFormatInit,
  itemsInit,
  createChannelInit,
  createChannelSchema,
};
