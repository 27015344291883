import React, { useMemo } from "react";
import { formatNumberAsCurrency } from "../../utils";
import { DefaultListTable } from "../UI";
import ReachLogo from "../../assets/images/logo.png";

const GBOByScreenFormatTable = React.forwardRef(
  ({ id, data, paramFilter, total }, ref) => {
    const headers = ["Name", "GBO", "Admits"];
    return (
      <div className="overflow-auto px-3" ref={ref}>
        <div className="flex items-center justify-between">
          <div
            key={ReachLogo}
            className=" w-[5rem] h-[5rem]  bg-no-repeat bg-contain bg-center "
            style={{
              backgroundImage: `url("${ReachLogo}")`,
            }}
          ></div>
          <p className="font-bold text-[20px]">GBO By Screen Format</p>
          <p>{paramFilter.cinema}</p>
        </div>
        <div className=" flex p-2 justify-between">
          {/* <div>
            <p className="font-bold">FILM</p>
            <p>{paramFilter.film}</p>
          </div>
          <div className="font-bold">
            <p>{paramFilter.distributor}</p>
          </div> */}
          <div>
            <p className="font-bold">Data Range</p>
            <p>{new Date(paramFilter?.dateFrom).toDateString()}</p>{" "}
            <p>{new Date(paramFilter?.dateTo).toDateString()}</p>
          </div>
        </div>
        <div className={`overflow-auto`}>
          <table className="w-full mx-auto overflow-hidden text-left border rounded-md border-app-purple-4">
            <thead className="shadow-2xl bg-app-purple-8">
              <tr>
                {headers?.map((header) => (
                  <th
                    className={`px-3 py-3 font-normal tracking-wide uppercase border border-gray-300 text-md`}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="text-[#534C7E]">
              {data?.map((x, index) => {
                return (
                  <>
                    <tr
                      key={index}
                      className={`transition duration-300 border bg-app-purple-2 border-app-purple-4 border-opacity-30`}
                    >
                      <td
                        colSpan={3}
                        className="py-3 min-w-min px-3   font-bold text-[20px]"
                      >
                        {`Screen Format: ${x.filmFormat || ""}`}
                      </td>
                    </tr>
                    {x?.films?.map((x, index) => {
                      return (
                        <>
                          <tr
                            key={index}
                            className={`transition duration-300 border bg-app-purple-2 border-app-purple-4 border-opacity-30`}
                          >
                            <td className="py-3 min-w-min px-3">{x.name}</td>
                            <td className="py-3 min-w-min px-3">
                              {formatNumberAsCurrency(x.gbo)}
                            </td>
                            <td className="py-3 min-w-min px-3">
                              {x.quantity}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
        <p className="text-center text-app-purple-4 my-3">
          {" "}
          {`Report generated at ${new Date(
            paramFilter?.dateTimeGenerated
          ).toLocaleTimeString()} , ${new Date(
            paramFilter?.dateTimeGenerated
          ).toDateString()} by ${paramFilter.firstName}  ${
            paramFilter.lastName
          }`}
        </p>
      </div>
    );
  }
);

export { GBOByScreenFormatTable };
