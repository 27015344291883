import chunk from "lodash/chunk";
import { PAGINATION } from "./constants";

const addItem = (state, action, key, pageSize = PAGINATION.PAGE_SIZE) => {
  const pages = Object.keys(state[key]).sort((a, b) => a - b);

  let mergedList = pages.reduce((list, currentPage) => {
    list = [...list, ...state[key][currentPage]];
    return list;
  }, []);

  mergedList = [action.payload, ...mergedList];

  const maxPage = Math.max(...pages);

  const chunkedRows = chunk(mergedList, pageSize);

  const newState = chunkedRows.reduce((state, rows, index) => {
    state[pages[index] || maxPage + 1] = rows;

    return state;
  }, {});

  state.noOfPages = Math.max(state.noOfPages + 1, chunkedRows.length);

  return newState;
};

const editItem = (state, action, key) => {
  let pageKey = -1;
  let itemIndex = -1;

  const pages = Object.keys(state[key]);

  for (const page of pages) {
    const cards = state[key][page];
    const cardIndex = cards.findIndex((c) => c.id === action.payload.id);

    if (cardIndex > -1) {
      itemIndex = cardIndex;
      pageKey = page;

      break;
    }
  }

  if (itemIndex > -1) {
    state[key][pageKey][itemIndex] = action.payload;
  }
};

const addItemDirect = (state, action, key) => {
 
  const newState = [action.payload, ...state[key]];
  

  return newState;
};

const editItemDirect = (state, action, key) => {

  let itemIndex = state[key].findIndex((c) => c.id === action.payload.id);

  if (itemIndex > -1) {
    state[key][itemIndex] = action.payload;
  } else {
    state[key] = [action.payload, ...state[key]];
  }
};

export { addItem, editItem, addItemDirect, editItemDirect };
