import React from "react";

import { getBgColor, getTextColor } from "../../utils/mockData/sessions";

const LegendItem = ({ item }) => {
  const filmId = item.id;
  const bg = getBgColor(filmId);
  const textColor = getTextColor(bg);

  return (
    <div className="flex items-center justify-between">
      <div
        style={{
          backgroundColor: bg,
          color: textColor,
        }}
        className="w-5 h-5 mr-2 rounded-md"
      />

      <p className="text-base font-medium">{item.title}</p>
    </div>
  );
};

export { LegendItem };
