const API_URL = process.env.REACT_APP_API_URL;
const API_URLV2 = process.env.REACT_APP_API_URLV2;
const SPACE_BUCKET = process.env.REACT_APP_BUCKET;
const SPACE_ENDPOINT = process.env.REACT_APP_SPACE_ENDPOINT;
const SPACE_URL = process.env.REACT_APP_SPACE_URL;
const SPACE_ACCESS_KEY = process.env.REACT_APP_SPACES_KEY_STG;
const SPACE_SECRET_KEY = process.env.REACT_APP_SPACES_SECRET_STG;

const tokenKey = "thrive-admin-token";
const refreshTokenKey = "thrive-admin-rToken";
const lastUpdateKey = "updatedAt";
const expiresKey = "expiresAt";
const currencyKey = "thrive-admin-currencyKey";
const timezoneKey = "thrive-admin-timezoneKey";
const loggedInCinemaName ="thrive-admin-cinemaName"

const refreshInterval = 1000 * 60 * 110; //1hr 5mins

const FETCH_STATUS = {
  IDLE: "IDLE",
  RESOLVED: "RESOLVED",
  REJECTED: "REJECTED",
  PENDING: "PENDING",
};

const fetchArea = {
  Cinema: 0,
  FastFood: 1,
  Arcade: 2,
  Cafe: 3,
};
const seatStatus = [
  { name: "Available", id: 0 },
  { name: "Reserved", id: 1 },
  { name: "Unavailable", id: 2 },
  { name: "Aisle", id: 3 },
];
const CHECK_SEAT_STATUS = {
  Available: 0,
  Reserved: 1,
  Unavailable: 2,
  Aisle: 3,
};
const SEATSTATUS = {
  AVAILABLE: 0,
  RESERVED: 1,
  UNAVAILABLE: 2,
  AISLE: 3,
  SELECTED: "Selected",
  DAMAGED: "Damaged",
};
const currency = localStorage.getItem(currencyKey);
const cinemaName = localStorage.getItem(loggedInCinemaName)
const isCanal = cinemaName?.toLowerCase().includes("canal");
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const ITEM_GROUP = {
  SINGLE: "fdf1e9e5-fcf7-48cc-8bc0-c9bb63b87b41",
  PARENT: "b089b585-2ffb-4ebc-af43-c2f294dbfe8d",
  PACKAGE: "03571310-71a2-4425-93ce-1c65d18b4341",
  RECIPE: "d6eb0b4f-cb89-4c1b-a22c-e9143cd0678b",
  COMPONENT: "76a4f000-03e1-4627-8b0a-85e569a7d5e4",
  SUNDRY: "106dd20b-88e5-48e4-9453-0852d4926fd0",
};

const VIEW_MODES = {
  MONTH: "MONTH",
  DAY: "DAY",
  WEEK: "WEEK",
};

const ITEM_TYPES = {
  SESSION: "SESSION",
};

let dayMarkers = [];

for (let i = 0; i < 24; i++) {
  let num = i.toString().padStart(2, "0");

  dayMarkers.push(num);
}

const BIGNUMBER = 1000;

const dayMap = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};



const trueFalse = {
  true: true,
  false: false,
};

const PAGINATION = {
  PAGE_SIZE: 20,
  SIBLING_COUNT: 2,
};

const ReportLists = [
  {
    name: "Distributor by Film",
    route: "/dashboard/reports/distributor-by-film",
  },
  {
    name: "Box Office Performance",
    route: "/dashboard/reports/box-office-performance",
  },
  {
    name: "Bookings By Genre",
    route: "/dashboard/reports/bookings-by-genre",
  },
  {
    name: "Staff Performance",
    route: "/dashboard/reports/staff-performance",
  },
  // {
  //   name: "Performance By Showtime",
  //   route: "/dashboard/reports/performance-by-showtime",
  // },
  // {
  //   name: "Performance By Film",
  //   route: "/dashboard/reports/performance-by-film",
  // },
  {
    name: "GBO By Tickets",
    route: "/dashboard/reports/GBOByTickets",
  },
  {
    name: "GBO By Films",
    route: "/dashboard/reports/gbo-film-reports",
  },
  {
    name: "GBO by Screen Format",
    route: "/dashboard/reports/gbo-by-screenformat",
  },
  {
    name: "Film Hire Report",
    route: "/dashboard/reports/filmhire-report",
  },
  {
    name: "Showtimes Schedule",
    route: "/dashboard/reports/showtime-report",
  },
  {
    name: "Refund Reports",
    route: "/dashboard/reports/refund-report",
  },
  {
    name: "Cashier Session Reconcilation",
    route: "/dashboard/reports/cashier-session-reconcilation",
  },
  {
    name: "Session Reconciliation",
    route: "/dashboard/reports/session-reconciliation",
  },
  {
    name: "Item Sales Report",
    route: "/dashboard/reports/item-sales-report",
  },
  {
    name: "Item Details Sales Report",
    route: "/dashboard/reports/item-detail-sales-report",
  },
  {
    name: "Performance Report",
    route: "/dashboard/reports/performance-report",
  },
  {
    name: "Marketing Report",
    route: "/dashboard/reports/marketing-report",
  },
  {
    name: "Film By Screen",
    route: "/dashboard/reports/films-by-screen",
  },
  {
    name: "Transactions by user",
    route: "/dashboard/reports/transactions-by-user",
  },
  {
    name: "Daily Cashup Report",
    route: "/dashboard/reports/daily-cashup-report",
  },
  {
    name: "Daily Operations Report",
    route: "/dashboard/reports/daily-operations-report",
  },
  {
    name: "Admin Actions By User",
    route: "/dashboard/reports/admin-actions",
  },
  {
    name: "Orders by Source And Channel",
    route: "/dashboard/reports/orders-by-source-and-channel",
  },
  {
    name: "Online Orders",
    route: "/dashboard/reports/online-orders",
  },
];
const screenFormat = {
  standard_2d: "d4002274-7acd-4e3e-ab8e-154db20ff2c9",
  chronophotographe: "85a3924f-5de3-40c6-a26d-0afb35fe8a40",
  imax_3d: "51596b78-d006-48e5-bfaf-62781afff66f",
  "4dx": "63d4f30f-bb7d-4aa5-b6d2-ac70555e3abb",
  mx4d: "798706c9-5068-4323-a10d-1519e342e047",
};
export {
  API_URL,
  API_URLV2,
  VIEW_MODES,
  FETCH_STATUS,
  ITEM_TYPES,
  tokenKey,
  refreshTokenKey,
  lastUpdateKey,
  expiresKey,
  refreshInterval,
  dayMarkers,
  dayMap,
  trueFalse,
  PAGINATION,
  BIGNUMBER,
  ITEM_GROUP,
  monthNames,
  ReportLists,
  screenFormat,
  fetchArea,
  currencyKey,
  currency,
  seatStatus,
  SEATSTATUS,
  CHECK_SEAT_STATUS,
  SPACE_BUCKET,
  SPACE_ENDPOINT,
  SPACE_URL,
  SPACE_ACCESS_KEY,
  SPACE_SECRET_KEY,
  cinemaName,
  loggedInCinemaName,
  isCanal,
    timezoneKey,
};
