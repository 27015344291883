import React, { useMemo } from "react";

import { ClientPaginatedTable, DefaultColumnFilter } from "../UI";
import { getTableProps } from "../../utils";
import { useNavigate } from "react-router-dom";

const ChannelsTable = ({ channels = [] }) => {
    const navigate = useNavigate();
  const columns = useMemo(
    () => [
      {
        Header: "Channel",
        accessor: "name",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Status",
        accessor: "isActive",
        Cell: ({value} ) => {
       
          return <span>{value ? "Active" : "Inactive"}</span>;
        },
      },
     
    ],
    []
  );
    const onRowClick = (row) => () => {
      navigate (
        `/dashboard/finance/payment-channel/edit/${row.original.id}`
      );
    };

  return (
    <ClientPaginatedTable
      columns={columns}
      tableData={channels}
      filterColumnsKeys={["name"]}
    onRowClick={onRowClick}
      {...getTableProps()}
      rowClass="hover:!bg-white/50 !cursor-pointer"
    />
  );
};

export { ChannelsTable };
