import React from "react";

const Shift = ({ resourceId, list }) => {
  return (
    <>
      <p className="text-base leading-6 text-center text-app-black">
        {list[resourceId]?.name || "N/A"}
      </p>
    </>
  );
};

export default Shift;
