const TextInput = ({
  onChange,
  value,
  withAutoComplete,
  autoCompleteOptions,
  containerClassName,
  inputClassName,
  placeholder,
}) => {
  return (
    <div className={`relative ${containerClassName}`}>
      <div
        className={`absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none`}
      >
        <svg
          className="w-5 h-5 text-[#CEBCCE] "
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          ></path>
        </svg>
      </div>
      <input
        onChange={(e) => onChange(e.currentTarget.value)}
        value={value}
        type="search"
        className={`block min-w-[280px] w-full  p-3 pl-10 text-sm rounded-xl bg-white outline-none ${inputClassName}`}
        placeholder={placeholder}
      />
    </div>
  );
};

export { TextInput };
