import React, { Fragment } from "react";

const Legend = ({ legendItems, renderLegend }) => {
  return (
    <div className="flex flex-wrap gap-4 gap-y-6 items-center justify-center my-11 relative">
      {legendItems.map((item, i) => (
        <Fragment key={i}>{renderLegend(item)}</Fragment>
      ))}
    </div>
  );
};

export default Legend;
