import ReachLogo from "../../assets/images/logo.png"


const ReqLayout = ({ type, data }) => {
  return (
    <div>
      <div
        key={ReachLogo}
        className=" ml-[-5px] w-[5rem] h-[3rem] bg-no-repeat bg-contain bg-center hidden print-source print:block"
        style={{
          backgroundImage: `url("${ReachLogo}")`,
        }}
      ></div>
      <p className="font-bold">{`${
        type === "stockRequest" ? "Stock Request" : "Purchase Order"
      }`}</p>
      <div className="flex justify-between text-[#8B8D97]">
        <div className="flex mr-5">
          <div className="w-[180px]">
            <p className="py-2">Name:</p>
            <p className="py-2">Position:</p>
            <p className="py-2">Cinema:</p>

            <p className="py-2">Submitted Date</p>
            <p className="py-2">Remark</p>
          </div>
          <div>
            <p className="py-2">
              {data?.requestedBy
                ? `${data?.requestedBy?.firstName} ${data?.requestedBy?.lastName}`
                : "No data"}
            </p>
            <p className="py-2">
              {data?.requestedBy ? data?.requestedBy?.roleName : "No data"}
            </p>
            <p className="py-2">{data?.cinema}</p>

            <p className="py-2">
              {new Date(data?.submittedDate).toLocaleString()}
            </p>
            <p className="py-2">{data?.remark}</p>
          </div>
        </div>
        <div>
          <div className="flex ">
            <div className="w-[180px]">
              <p className="py-2">Request ID:</p>
              <p className="py-2">PO ID:</p>
              <p className="py-2">Vendor:</p>
              <p className="py-2">Request Date:</p>
            </div>
            <div>
              <p className="py-2">{data.id || "No data"}</p>
              <p className="py-2">{data.purchaseOrderId || "No data"}</p>
              <p className="py-2"> {data?.vendor ? data?.vendor : "No data"}</p>
              <p className="py-2">
                {" "}
                {new Date(data?.requestDate).toLocaleString()}
              </p>
            </div>
          </div>
        </div>
      </div>
      <table className="w-full mt-8 ">
        <thead>
          <tr className="border-y-2 ">
            <th className="py-3 text-left ">Item Name</th>
            <th className="text-left">Description</th>{" "}
            <th className="text-left">Unit of Measure</th>{" "}
            <th className="text-left">QUoM</th>{" "}
            <th className="text-center">Qty Desired</th>
          </tr>
        </thead>
        <tbody>
          {data?.items?.map((item) => {
            return (
              <tr>
                <td className="py-2">{item?.item}</td>
                <td>{item?.description}</td>
                <td>{item?.unitOfMeasurement}</td>
                <td className="text-center">{item?.quantityUOM}</td>
                <td className="text-center">{item?.quantityDesired}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export { ReqLayout };
