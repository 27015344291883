import { useState, useEffect } from "react";
import { ModalSkeleton } from "../Shared";
import { Input, Spinner } from "../UI";
import { useRequest } from "../../hooks";
import { FETCH_STATUS, axiosApiInstance, API_URL } from "../../utils";

const KioskTerminalModal = ({ openStatus, closeFunc, data, loadList }) => {
  const [terminalName, setTerminalName] = useState("");
  const [terminalId, setTerminalId] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const { status, setResolved, setPending, setRejected } = useRequest();
  const {
    status: status2,
    setResolved: setResolved2,
    setPending: setPending2,
    setRejected: setRejected2,
  } = useRequest();

  const reloadFunc = () => {
    closeFunc();
    loadList();
  };

  useEffect(() => {
    if (data) {
      setTerminalName(data.terminalName);
      setTerminalId(data.terminalId);
    }
  }, [data]);

  const submitFunc = async (e) => {
    e.preventDefault();
    if (!terminalName || !terminalId) {
      setErrorMsg("Ensure you enter Terminal Name and Terminal Id");
      return;
    }

    if (!Object.keys(data)?.length) {
      try {
        setPending();
        await axiosApiInstance.post(`${API_URL}/KioskTerminals/Create`, {
          terminalId,
          terminalName,
        });
        setResolved();
        reloadFunc();
      } catch (error) {
        setRejected(error.message || error);
        setErrorMsg(error.message || error);
      }
    } else {
      try {
        setPending2();
        await axiosApiInstance.patch(
          `${API_URL}/KioskTerminals/${data.id}/Update`,
          {
            terminalId,
            terminalName,
            isActive: true,
          }
        );
        setResolved2();
        reloadFunc();
      } catch (error) {
        setRejected2(error.message || error);
        setErrorMsg(error.message || error);
      }
    }
  };

  return (
    <ModalSkeleton
      openStatus={openStatus}
      closeFunc={() => {
        closeFunc();
        setErrorMsg();
      }}
    >
      <p className="text-center font-bold text-[20px]">Kiosk Terminal Form</p>
      {errorMsg && <p className="text-center text-red-500">{errorMsg}</p>}
      <form className="px-[50px]" onSubmit={submitFunc}>
        <Input
          label="Terminal Name:"
          type="string"
          wrapperClass={"!block pb-4"}
          labelClass={"my-1 block !text-left"}
          inputClass={"w-full !bg-transparent border border-app-purple-4"}
          value={terminalName}
          onChange={(e) => {
            setTerminalName(e.target.value);
          }}
        />
        <Input
          label="Terminal Id:"
          type="string"
          wrapperClass={"!block pb-4"}
          labelClass={"my-1 block !text-left"}
          inputClass={"w-full !bg-transparent border border-app-purple-4"}
          value={terminalId}
          onChange={(e) => {
            setTerminalId(e.target.value);
          }}
        />
        <div className="flex items-center justify-around mt-4">
          <button
            type="submit"
            className="relative min-w-[150px] flex justify-center items-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-app-purple-4"
          >
            Save
            <Spinner
              className="!w-3 !h-3 mx-1 text-white fill-amber-300"
              status={
                status === FETCH_STATUS.PENDING ||
                status2 === FETCH_STATUS.PENDING
                  ? FETCH_STATUS.PENDING
                  : ""
              }
            />
          </button>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export { KioskTerminalModal };
