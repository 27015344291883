import React, { useEffect } from "react";
import { FormError, InlineErr, Spinner, StatusToggler } from "../UI";
import { useForm } from "react-hook-form";
import { FETCH_STATUS } from "../../utils";
import { useLoggedInuser } from "../../hooks";

const TaskForm = ({ isEdit, info, status, onReset, error, onSave }) => {
   
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      status: "true",
     
    },
  });

  useEffect(() => {
    if (isEdit) {
      reset({
        name: info?.name,
        status: !!info?.status? "true":"false",
      });
    }
  }, [info, isEdit, reset]);

  const handleTaskSubmit = async (values) => {
    onSave({ ...values,status: "true"? 1: 0});
  };
  
  return (
    <>
      <form
        onSubmit={handleSubmit(handleTaskSubmit)}
        className="w-full relative h-full bg-white rounded-[12px] py-[28px] px-[30px]"
      >
        <FormError
          className="absolute top-0 z-20 transform left-2/4 -translate-x-2/4"
          err={error}
          onDismiss={onReset}
        />
        <div className="flex flex-col gap-y-7 justify-center items-center">
          <div className="relative w-full md:w-1/2">
            <label
              htmlFor="taskName"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Task Name
            </label>
            <input
              id="taskName"
              type="text"
              className="w-full placeholder:text-[#ABAFB1] px-[16px] py-[14px] border-none focus:ring-0 rounded-lg bg-[#AA7DAA26]"
              placeholder="Enter Task Name"
              {...register("name", { required: true })}
            />
            <InlineErr err={errors?.name ? "Task name is required" : null} />
          </div>
          {/* <div className="w-full relative md:w-1/2">
            <label
              htmlFor=""
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Staff Role
            </label>
            <select
              className="w-full px-[16px] py-[14px] border-none outline-none focus:ring-0 rounded-lg bg-[#AA7DAA26]"
              id=""
              placeholder=""
              //   {...register("")}
            >
              <option value="" disabled selected hidden>
                Select Role
              </option>
            </select>
          </div> */}

          <div className="relative w-full md:w-1/2">
            <label
              htmlFor="name"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Status
            </label>
            <StatusToggler
              options={[
                { key: "Active", value: "true" },
                { key: "Inactive", value: "false" },
              ]}
              register={register}
              statusKey="status"
              className="w-1/2 !justify-start"
              errMsg=""
            />
          </div>
        </div>
        <div className="flex justify-center mt-20">
          <button
            type="submit"
            className="px-10 py-2 rounded-md text-white bg-app-purple-4 inline-flex items-center justify-center space-x-2"
          >
            <span>Save</span>
            <Spinner
              className="!w-3 !h-3 text-white fill-app-yellow"
              status={status}
            />
          </button>
        </div>
      </form>
    </>
  );
};

export { TaskForm };
