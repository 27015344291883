import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import {
  rejectStockRequest,
  stockData,
  rejectStockReceipt,
  updateFetchedStockRequest,
  updateFetchedStockReceipt,
  rejectTransferReceipt,
} from "../../../redux/slices";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "../../UI";
import { FETCH_STATUS, replaceInArray } from "../../../utils";

const RejectionReason = ({
  onClose = () => {},
  show,
  data,
  type,
  onConfirm,
}) => {
  const dispatch = useDispatch();
  const {
    rejectStockRequestStatus,
    rejectStockRequestErr,
    rejectStockReceiptStatus,
    rejectStockReceiptErr,
    stockRequests,
    stockReceipts,
    rejectTransferReceiptErr,
    rejectTransferReceiptStatus,
  } = useSelector(stockData);
  const [showApiErr, setShowApiErr] = useState(false);
  const [remark, setRemark] = useState("");
  const rejectFunc = () => {
    if (remark.length < 3) {
      setShowApiErr(true);
      return;
    }
    if (type === "Stock Request") {
      dispatch(rejectStockRequest({ id: data, data: { remark: remark } }))
        .unwrap()
        .then((data) => {
          const newData = replaceInArray(stockRequests, {
            ...data,
            requestedBy:
              data?.requestedBy?.firstName + data?.requestedBy?.lastName,
          });
          dispatch(updateFetchedStockRequest(newData));
          setShowApiErr(false);
          onConfirm();
        })
        .catch((err) => {
          setShowApiErr(true);
        });
    }
    if (type === "Stock Receipt") {
      dispatch(rejectStockReceipt({ id: data, data: { remark: remark } }))
        .unwrap()
        .then((data) => {
          const newData = replaceInArray(stockReceipts, {
            ...data,
            requestedBy:
              data?.requestedBy?.firstName + data?.requestedBy?.lastName,
          });
          dispatch(updateFetchedStockReceipt(newData));
          setShowApiErr(false);
          onConfirm();
        })
        .catch((err) => {
          setShowApiErr(true);
        });
    }

    if (type === "Stock Transfer Receipt") {
      dispatch(rejectTransferReceipt({ id: data, data: { reference: remark } }))
        .unwrap()
        .then((data) => {
          // const newData = replaceInArray(stockReceipts, {
          //   ...data,
          //   requestedBy:
          //     data?.requestedBy?.firstName + data?.requestedBy?.lastName,
          // });
          // dispatch(updateFetchedStockReceipt(newData));
          setShowApiErr(false);
          onConfirm();
        })
        .catch((err) => {
          setShowApiErr(true);
        });
    }
  };

  return (
    <>
      <Transition show={show} appear as={Fragment}>
        <Dialog as="div" className="relative z-[10000]" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg px-5 py-2 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <div
                    className="flex justify-between "
                    onClick={() => {
                      onClose();
                      setShowApiErr(false);
                    }}
                  >
                    <p className=" text-[20px]">{`${type} Rejected`}</p>
                    <div
                      className={
                        "rounded-xl text-[15px] font-bold bg-[#E5E5E5] px-3 py-1 cursor-pointer"
                      }
                    >
                      X
                    </div>
                  </div>
                  {showApiErr && remark.length > 3 && (
                    <p className="italic text-center text-red-500">
                      {rejectStockRequestErr ||
                        rejectStockReceiptErr ||
                        rejectTransferReceiptErr}
                    </p>
                  )}
                  {showApiErr && remark.length < 3 && (
                    <p className="italic text-center text-red-500">
                      Ensure you enter a remark or reason for rejection
                    </p>
                  )}
                  <p className="py-3"> State your reason</p>
                  <textarea
                    className="w-full"
                    rows={6}
                    placeholder=" Enter Comment here"
                    onChange={(e) => {
                      setRemark(e.target.value);
                    }}
                  ></textarea>

                  <div className="flex justify-end my-2">
                    <button
                      className="rounded bg-[#E6E4F7] px-3 py-1 text-[#8028C6] hover:font-bold"
                      onClick={() => {
                        onClose();
                        setShowApiErr(false);
                      }}
                    >
                      {" "}
                      No, Cancel
                    </button>
                    <button
                      className="rounded bg-[#8028C6] flex items-center justify-between px-3 py-1 text-[#E6E4F7] hover:font-bold ml-3"
                      onClick={rejectFunc}
                    >
                      {" "}
                      Yes, Confirm
                      <Spinner
                        className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                        status={
                          rejectStockRequestStatus === FETCH_STATUS.PENDING ||
                          rejectStockReceiptStatus === FETCH_STATUS.PENDING ||
                          rejectTransferReceiptStatus === FETCH_STATUS.PENDING
                            ? FETCH_STATUS.PENDING
                            : null
                        }
                      />
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export { RejectionReason };
