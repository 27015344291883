import React, { Fragment } from "react";
import { ModalSkeleton } from "../Shared";
import { Input } from "../UI";
import { formatNumberAsCurrency } from "../../utils";

const wrapperClass = "!block pb-4";
const labelClass = "my-1 block !text-left";
const inputClass = "w-full !bg-transparent";
const inputClass2 = "w-full !bg-transparent border-none";
const inputClass3 = "w-full !bg-transparent border-none text-red-500";

const OtherChannelModal = ({
  show,
  setChannelValues,
  channelValues,
  channels,
  activeSession,
  onClose,
}) => {

  return (
    <ModalSkeleton openStatus={show} closeFunc={onClose}>
      <p className="text-center mb-4 text-app-purple-4">
        Reconcile custom channels for {activeSession?.name}
      </p>

      <div className="grid grid-cols-3 gap-5">
        {channels?.map((channel) => {
        
          return (
            <Fragment key={channel?.id}>
              <Input
                label={`Expected ${channel?.name}`}
                type="text"
                wrapperClass={wrapperClass}
                labelClass={labelClass}
                inputClass={inputClass2}
                value={formatNumberAsCurrency(
                  channelValues[channel?.id]?.expected
                )}
                disabled
              />
              <Input
                label={`Actual ${channel?.name}`}
                id={`actual${channel?.name}`}
                type="number"
                wrapperClass={wrapperClass}
                labelClass={labelClass}
                inputClass={inputClass}
                value={channelValues[channel?.id]?.actual}
                onChange={(e) =>
                  setChannelValues((prev) => ({
                    ...prev,
                    [channel?.id]: {
                      ...prev[channel?.id],
                      actual: Number(e.target.value),
                    },
                  }))
                }
              />
              <Input
                label={`${channel?.name} Variance:`}
                type="text"
                wrapperClass={wrapperClass}
                labelClass={labelClass}
                inputClass={
                  channelValues[channel?.id]?.actual -
                    channelValues[channel?.id]?.expected !==
                  0
                    ? inputClass3
                    : inputClass2
                }
                disabled
                value={formatNumberAsCurrency(
                  channelValues[channel?.id]?.actual -
                    channelValues[channel?.id]?.expected
                )}
              />
            </Fragment>
          );
        })}
      </div>
      <div className="flex justify-center">
        <button
          type="button"
          onClick={onClose}
          className="relative min-w-[150px] flex justify-center items-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-app-purple-4"
        >
          <span>Close</span>
        </button>
      </div>
    </ModalSkeleton>
  );
};

export { OtherChannelModal };
