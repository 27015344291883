import get from "lodash/get";
import { transformErr } from "./transformErr";

export const extractErrorMessages = (error, path, altPath, altPath2) => {
  if (typeof error === "string") {
    return error;
  }

  return get(error, path) || get(error, altPath) || error?.message;
};

export const getAPIErr = (error, defaultMsg = "Unable to complete action") => {
  let msg = "";

  msg = extractErrorMessages(
    error,
    "response.data.data.errors[0].errorMessages[0]",
    "response.data.errors[0].errorMessages[0]"
  );

  if (!msg) {
    msg =
      get(error, "response.data.errors")?.constructor === Object
        ? transformErr(get(error, "response.data.errors"))
        : defaultMsg;
  }

  return msg || defaultMsg;
};

export const onReject = (callback, error, defaultMsg) => {
  if (error?.name?.includes("Axios")) {
    callback(getAPIErr(error, defaultMsg));
  } else {
    console.log({ error });
    callback( transformErr({ ...error }));
  }
};

export const onReturnReject = (error, defaultMsg) => {
  if (error.name.includes("Axios")) {
    return getAPIErr(error, defaultMsg);
  } else {
    return transformErr({ ...error });
  }
};
