import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { FETCH_STATUS, API_URL, getAPIErr } from "../../utils";
import { axiosApiInstance } from "../../utils";

export const updateMetaData = createAsyncThunk(
  "updateMetaData",
  async (cinemaId, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.patch(
        `${API_URL}/Cinemas/${cinemaId}/UpdateMetaData`
      );

      return fulfillWithValue(response?.data?.data);
    } catch (err) {
      let message = "Unable to complete request";

      return rejectWithValue(getAPIErr(err || message));
    }
  }
);
export const getMetaData = createAsyncThunk(
  "getMetaData",
  async (cinemaId, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Cinemas/${cinemaId}/MetaData`
      );

      
      return fulfillWithValue(response?.data?.data);
    } catch (err) {
      
      let message = "Unable to complete request";

      return rejectWithValue(getAPIErr(err || message));
    }
  }
);

const initialState = {
  
  metaData: {},
  metaDataErr: null,
  metaDataStatus: FETCH_STATUS.IDLE,
};

export const metaDataSlice = createSlice({
  name: "metaData",
  initialState,
  reducers: {
    resetMetaDataErr(state) {
      state.metaDataErr = null;
      state.metaDataStatus = FETCH_STATUS.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMetaData.pending, (state) => {
        state.metaDataStatus = FETCH_STATUS.PENDING;
        state.metaDataErr = "";
      })
      .addCase(getMetaData.fulfilled, (state, action) => {
        state.metaDataStatus = FETCH_STATUS.RESOLVED;
        state.metaData = action.payload;
        state.metaDataErr = "";
      })
      .addCase(getMetaData.rejected, (state, action) => {
        state.metaDataStatus = FETCH_STATUS.REJECTED;
        state.metaDataErr = action.payload || "Unable to get metaData";
      });
  },
});
export const { resetMetaDataErr } = metaDataSlice.actions;
export const selectMetaData = (state) => state.metaData;
