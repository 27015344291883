import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { FETCH_STATUS } from "../../../utils";
import {
  Loading,
  PageHeader,
  PrivateRoute,
  ErrorWithWrapper,
} from "../../../components";

const IssueDetails = () => {
  return (
    <PrivateRoute redirectTo="/dashboard/issues/create">
      <PageHeader>
        <h1 className="items-center text-2xl font-semibold text-white ">
          Update Issue
        </h1>
      </PageHeader>
    </PrivateRoute>
  );
};

export { IssueDetails };
