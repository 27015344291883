import React, {useRef, useState} from "react";
import DatePicker from "react-datepicker";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Element, scroller} from "react-scroll";
import {ExportExcelBtn, PdfButton, PrivateRoute, Spinner, Success,} from "../../../components";
import {DateTimeInput} from "../../../components/Films/DateTimeInput";
import ShowtimesReportTable from "../../../components/Reports/ShowtimesReportTable";
import {useLoggedInuser} from "../../../hooks";
import {getShowtimeScheduleReport, reportData} from "../../../redux/slices";
import {export2Word, FETCH_STATUS, formatDateForApi} from "../../../utils";

const ShowtimeReport = () => {
  const [date, setDate] = useState(new Date().toISOString());
  const { showtimesReport, showtimesReportStatus } = useSelector(reportData);
  const { profile } = useLoggedInuser();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const reportRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (date && profile.cinemaId) {
        dispatch(
          getShowtimeScheduleReport({
            cinemaId: profile?.cinemaId,
            todayDate: date,
          })
        );

        scroller.scrollTo("reportContainer", {
          duration: 1500,
          delay: 0,
          smooth: true,
          offset: -100, // Scrolls to element + 50 pixels down the page
        });
      }
    } catch (error) {}
  };
 

  // const handleWord =() => {
  //   Export2Word("showtimes-doc", "showtimes.docx")
  // }

  return (
    <>
      <PrivateRoute redirectTo="/dashboards/reports/showtimes-report">
        <div className="w-[95%] mx-auto mt-6">
          <p className="font-semi-bold px-3 pb-5">
            Showtimes Schedule Report - Set Report Parameters
          </p>
          <div className="flex items-center justify-between p-4 px-8 h-[50px] rounded-t-lg bg-[#C96FCC]">
            <div className="flex gap-4">
              <span className="text-xl font-semibold text-white"></span>
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="flex items-center justify-end py-3 px-4 bg-white ">
              <div className="flex gap-3 buttongroup ">
                <button
                  className="p-1 px-3 rounded-md text-[#C96FCC] bg-[#ffe583] shadow-md hover:shadow-sm"
                  type="button"
                  onClick={() => {
                    navigate("/dashboard/reports");
                  }}
                >
                  Cancel
                </button>
                <button
                  className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm flex items-center"
                  type="submit"
                >
                  <span> Run Report </span>
                  <Spinner
                    className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                    status={showtimesReportStatus}
                  />
                </button>
              </div>
            </div>
            <div className="bg-app-purple-2 p-3 pt-[30px]">
              <div className="flex justify-center items-center">
                <p className="mr-6">Today Date:</p>

                <div className="relative ">
                  <DatePicker
                    placeholderText="Start Date"
                    selected={new Date(date)}
                    onChange={(value) => setDate(formatDateForApi(new Date(value)))}
                    customInput={
                      <DateTimeInput
                        className="bg-app-purple-8"
                        dateFormat={"EEE, MMM dd, yyyy"}
                      />
                    }
                  />
                </div>
              </div>
            </div>
            <Success show={showtimesReportStatus === FETCH_STATUS.RESOLVED}>
              <Element name="reportContainer">
                {showtimesReport?.result?.length ? (
                  <>
                    <div className="flex justify-center mt-5 space-x-4">
                      <PdfButton
                        refValue={reportRef}
                        docTitle={"showtimes-report"}
                      />
                      <button
                        type={"button"}
                        className="p-1 px-3 rounded-md text-white bg-[#00A4EF] shadow-md hover:shadow-sm"
                        // onClick={handlePrint}
                        onClick={() =>
                          export2Word("showtimes-doc", "showtimes schedule")
                        }
                        // onClick={() => Export2Word("showtimes-doc","showtimes schedule")}
                      >
                        Generate WORD
                      </button>
                      <ExportExcelBtn
                        id={"showtimes-xlsx"}
                        name={"showtimes.xlsx"}
                      />
                    </div>
                    <div ref={reportRef}>
                      <ShowtimesReportTable
                        data={showtimesReport?.result}
                        paramFilter={showtimesReport?.paramFilter}
                        id={"showtimes-xlsx"}
                      />
                    </div>
                  </>
                ) : (
                  <p className="text-sm text-red-500 flex justify-center mt-10">
                    No showtimes report available
                  </p>
                )}
              </Element>
            </Success>
          </form>
        </div>
      </PrivateRoute>
    </>
  );
};

export { ShowtimeReport };
