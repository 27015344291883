import React from "react";
import { InlineErr } from "./InlineErr";

const StatusToggler = ({
  register,
  statusKey,
  className,

  options,
  isChecked,
  radioLabel = "",
  getValues=() => {},
  errMsg,
}) => {
 
  return (
    <div
      className={`relative flex items-center space-x-12 md:justify-end ${className}`}
    >
      <p>{`${radioLabel}:`}</p>
      <div className="inline-flex p-2.5 rounded-xl bg-app-purple-10">
        {options?.map((option) => (
          <div className="flex items-center mr-4" key={option.key}>
            <input
              id={option.key}
              type="radio"
              value={option.value}
              onChange={() => {}}
              // checked={getValues(statusKey) === option.value}
              name={option.key}
              className="w-4 h-4 bg-white focus:!border-none text-app-black !ring-transparent "
              {...register(statusKey)}
            />
            <label
              htmlFor={option.key}
              className="ml-2 text-sm font-medium text-app-black "
            >
              {option.key}
            </label>
          </div>
        ))}
      </div>
      <InlineErr err={errMsg} />
    </div>
  );
};

export { StatusToggler };
