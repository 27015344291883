import { yupResolver } from "@hookform/resolvers/yup";

import React, { useEffect, useMemo } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import {
  FETCH_STATUS,
  formatDateTime,
  getAPIErrMessage,
  rosterInit,
  rosterSchema,
} from "../../../utils";
import { ErrorModal, InlineErr, Spinner, SuccessModal } from "../../UI";
import CreateRosterRow from "../CreateRosterRow";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { addHours } from "date-fns";
import { Calendar } from "../../Icons";


const EditRosterModal = ({
  oldSession,
  resetErr,
  newSession,
  tasks,
  shifts,
  show,
  close,
  editStatus,
  editErr,
  cinemaId,
  onEdit,
  staffs,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      shiftId: "",
      rosterTaskId: "",
      userId: "",
      scheduledDate: new Date().toISOString(),
    },
  });
  useEffect(() => {
    if (oldSession) {
      reset({
        rosterTaskId: oldSession?.rosterTaskId,
        shiftId: oldSession?.shiftId,
        userId: oldSession?.userId,
        scheduledDate: oldSession?.scheduledDate,
      });
    }
  }, [oldSession, reset]);

  const handleEditRoster = async (values) => {
    const updatedRoster = {
      ...values,
      scheduledDate: addHours(new Date(values.scheduledDate), 1).toISOString(),
      cinemaId: cinemaId,
      isActive: true,
    };

    onEdit(updatedRoster, oldSession.id);
  };

  return (
    <>
      <Transition show={show} as={Fragment}>
        <Dialog as="div" className="relative z-[1000000]" onClose={close}>
          {/* Overlay */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          {/* Content */}

          <div className="fixed inset-0 p-4 overflow-y-auto">
            <div className="flex items-center justify-center min-h-[400px] p-2 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-100"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full min-h-[400px] max-w-2xl p-6  text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    className="relative flex justify-between pb-4 border-b"
                    as="div"
                  >
                    <p className="text-lg font-medium leading-6 text-gray-900">
                      Edit Roster Session
                    </p>
                    <button
                      type="button"
                      onClick={close}
                      className="absolute right-0 top-2/4 -translate-y-2/4 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    >
                      <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </Dialog.Title>

                  <form
                    onSubmit={handleSubmit(handleEditRoster)}
                    className="my-6"
                  >
                    <div className="grid grid-cols-2 gap-4">
                      <div className="relative w-full">
                        <label
                          htmlFor="scheduledDate"
                          className="block text-gray-700 text-sm font-bold mb-2"
                        >
                          Date
                        </label>

                        <Controller
                          name="scheduledDate"
                          control={control}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <>
                                <DatePicker
                                  selected={
                                    value ? new Date(value) : new Date()
                                  }
                                  onChange={(value) =>
                                    onChange(value.toISOString())
                                  }
                                  dateFormat="yyyy-MM-dd HH:mm"
                                  showTimeInput
                                  timeInputLabel="Time:"
                                  customInput={
                                    <input
                                      id="scheduledDate"
                                     value={value}
                                      onChange={onChange}
                                      className="placeholder:text-[#ABAFB1] px-[25px] py-[10px] border-none outline-none focus:ring-0 rounded-lg bg-[#AA7DAA26] w-full"
                                    />
                                  }
                                />

                                <Calendar className="aspect-square absolute top-[50%] !w-4 text-sec-alt-200 ml-2" />
                              </>
                            );
                          }}
                        />
                        <InlineErr err={errors?.scheduledDate?.message} />
                      </div>
                      <div className="w-full relative">
                        <label
                          htmlFor="taskId"
                          className="block text-gray-700 text-sm font-bold mb-2"
                        >
                          Set Task
                        </label>
                        <select
                          className="w-full px-[16px] py-[10px] border-none outline-none focus:ring-0 rounded-lg bg-[#AA7DAA26]"
                          id="taskId"
                          placeholder=""
                          {...register(`rosterTaskId`)}
                        >
                          <option value="" disabled selected hidden>
                            Select Task
                          </option>
                          {tasks.map((task) => {
                            return (
                              <option key={task?.id} value={task?.id}>
                                {task?.name}
                              </option>
                            );
                          })}
                        </select>
                        <InlineErr err={errors?.rosterTaskId?.message} />
                      </div>
                      <div className="w-full relative">
                        <label
                          htmlFor="shiftId"
                          className="block text-gray-700 text-sm font-bold mb-2"
                        >
                          Set Shift
                        </label>
                        <select
                          className="w-full px-[16px] py-[10px] border-none outline-none focus:ring-0 rounded-lg bg-[#AA7DAA26]"
                          id="shiftId"
                          placeholder=""
                          {...register(`shiftId`)}
                        >
                          <option value="" disabled selected hidden>
                            Select Shift
                          </option>
                          {shifts.map((shift) => {
                            return (
                              <option key={shift?.id} value={shift?.id}>
                                {shift?.name}
                              </option>
                            );
                          })}
                        </select>
                        <InlineErr err={errors?.shiftId?.message} />
                      </div>
                      <div className="w-full relative">
                        <label
                          htmlFor="userId"
                          className="block text-gray-700 text-sm font-bold mb-2"
                        >
                          Set Staff
                        </label>
                        <select
                          className="w-full px-[16px] py-[10px] border-none outline-none focus:ring-0 rounded-lg bg-[#AA7DAA26]"
                          id="userId"
                          placeholder=""
                          {...register(`userId`)}
                        >
                          <option value="" disabled selected hidden>
                            Select Staff
                          </option>
                          {staffs.map((staff) => {
                            return (
                              <option
                                key={staff?.id}
                                value={staff?.id}
                              >{`${staff?.firstName} ${staff?.lastName}`}</option>
                            );
                          })}
                        </select>
                        <InlineErr err={errors?.userId?.message} />
                      </div>
                    </div>

                    <hr className="col-span-3 my-2 border-dashed" />

                    <div className="flex justify-center mt-10">
                      <button
                        type="submit"
                        disabled={editStatus === FETCH_STATUS.PENDING}
                        className="px-10 py-2 rounded-md text-white bg-app-purple-4 inline-flex items-center justify-center space-x-2"
                      >
                        <span>Edit</span>
                        <Spinner
                          className="!w-3 !h-3 text-white fill-app-yellow"
                          status={editStatus}
                        />
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <ErrorModal
        show={editStatus === FETCH_STATUS.REJECTED}
        message={getAPIErrMessage(editErr, "Unable to edit roster")}
        onClose={resetErr}
        onClick={resetErr}
      />
    </>
  );
};

export default EditRosterModal;
