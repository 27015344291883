import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import {
  DistributorByFilmTable,
  ExportExcelBtn,
  FormGroup,
  InlineErr,
  InputErr,
  Label,
  PdfButton,
  Pdiv,
  Pdiv2,
  PrivateRoute,
  SearchSelect,
  Spinner,
} from "../../../components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import addDays from "date-fns/addDays";
import { DateTimeInput } from "../../../components/Films/DateTimeInput";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Element, scroller } from "react-scroll";
import {
  dateAndTime,
  distributorByFilmInit,
  distributorByFilmSchema,
  formatDateForApi,
  formatDateTime,
  getSelectedItem2,
} from "../../../utils";
import { useLoggedInuser } from "../../../hooks";
import {
  getDistributorByFilmReport,
  getDistributorFilms,
  getDistributors,
  getFilms,
  reportData,
} from "../../../redux/slices";
import { useGlobalFilter, useTable } from "react-table";
import ReachLogo from "../../../assets/images/logo.png";
import { addHours, setHours, setMinutes } from "date-fns";

const DistributorByFilm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [startDate, setStart] = useState(addDays(new Date(), -2).toISOString());
  const [endDate, setEnd] = useState(new Date().toISOString());
  const { profile } = useLoggedInuser();
  const {
    distributorsList,
    distributorByFilmReport,
    distributorByFilmReportStatus,
    distributorFilms,
  } = useSelector(reportData);
  const reportRef = useRef(null);

  const formattedDistributorFilm = useMemo(() => {
     if (distributorFilms?.pagedList?.length > 0) {
       return [
         { name: "All Films", id: "allfilms" },
         ...distributorFilms?.pagedList,
       ];
     }
  }, [distributorFilms?.pagedList]);
  useEffect(() => {
    dispatch(getDistributors(true));
    dispatch(getFilms());
  }, [dispatch]);

  const {
    register,
    setValue,
    control,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(distributorByFilmSchema),
    defaultValues: {
      ...distributorByFilmInit,
      DateFrom: startDate,
      DateTo: endDate,
      CinemaId: profile.cinemaId,
    },
  });

  useEffect(() => {
    setValue("DateFrom", startDate);
    setValue("DateTo", endDate);
  }, [startDate, endDate, setValue]);

  const getInfo = useCallback(
    (formData) => {
      dispatch(
        getDistributorByFilmReport({
          ...formData,
          force: true,
          DateFrom: formatDateForApi(new Date(formData.DateFrom)),
          DateTo: formatDateForApi(new Date(formData.DateTo)),
        })
      );
    },
    [dispatch]
  );

  const onSubmit = (data) => {
    dispatch(
      getDistributorByFilmReport({
        ...data,
        force: true,
        DateFrom: addHours(new Date(data.DateFrom), 1).toISOString(),
        DateTo: addHours(new Date(data.DateTo), 1).toISOString(),
      })
    );
    scroller.scrollTo("reportContainer", {
      duration: 1500,
      delay: 0,
      smooth: true,
      offset: -100, // Scrolls to element + 50 pixels down the page
    });
  };

  const columns = useMemo(() => {
    return [
      {
        Header: "Film",
        accessor: "film",
      },
      {
        Header: "Sales",
        accessor: "quantity",
      },
      {
        Header: "Admits",
        accessor: "admits",
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }) => value,
      },
      {
        Header: "Tax",
        accessor: "tax",
        Cell: ({ value }) => value.toFixed(2),
      },
      {
        Header: "Net Total",
        accessor: "netTotal",
        Cell: ({ value }) => value.toFixed(2),
      },
      {
        Header: "Gross Total",
        accessor: "grossTotal",
        Cell: ({ value }) => value,
      },

      // {
      //   Header: "Default Price",
      //   accessor: "price",
      //   Cell: ({ value }) => formatNumberAsCurrency(value),
      // },
    ];
  }, []);

  const filteredData = useMemo(() => {
    if (distributorByFilmReport?.result) {
      return distributorByFilmReport?.result;
    } else {
      return [];
    }
  }, [distributorByFilmReport]);

  const tableInstance = useTable(
    { data: filteredData, columns },
    useGlobalFilter
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <PrivateRoute redirectTo={"/dashboard/reports/distributor-by-film"}>
      <div className="w-[95%] mx-auto mt-6" id={"parentContainer"}>
        <p className="font-semi-bold px-3 pb-5">
          Distributors by Film - Set Report Parameters
        </p>
        <div className="flex items-center justify-between p-4 px-8 h-[50px] rounded-t-lg bg-[#C96FCC]">
          <div className="flex gap-4">
            <span className="text-xl font-semibold text-white"></span>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex items-center justify-end py-3 px-4 bg-white ">
            <div className="flex gap-3 buttongroup ">
              <button
                className="p-1 px-3 rounded-md text-[#C96FCC] bg-[#ffe583] shadow-md hover:shadow-sm"
                type="button"
                onClick={() => {
                  navigate("/dashboard/reports");
                }}
              >
                Cancel
              </button>
              <button
                className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm flex items-center"
                type="submit"
              >
                <span> Run Report </span>
                <Spinner
                  className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                  status={distributorByFilmReportStatus}
                />
              </button>
            </div>
          </div>
          <div className="bg-app-purple-2 p-3 pt-[30px]">
            <Pdiv label={"Date Range"}>
              <div className="flex items-center">
                <DatePicker
                  placeholderText="Start Date"
                  selected={new Date(startDate)}
                  dateFormat="Pp"
                  showTimeSelect
                  onChange={(value) => {
                    setStart(value.toISOString());
                  }}
                  customInput={
                    <DateTimeInput
                      className="bg-app-purple-8  w-[12rem]"
                      // dateFormat={"EEE, MMM dd, yyyy"}
                    />
                  }
                />
                <p className="w-[6rem] text-center"> To:</p>
                <DatePicker
                  placeholderText="End Date"
                  selected={new Date(endDate)}
                  dateFormat="Pp"
                  showTimeSelect
                  injectTimes={[setHours(setMinutes(new Date(), 59), 23)]}
                  onChange={(value) => {
                    setEnd(value.toISOString());
                  }}
                  customInput={
                    <DateTimeInput
                      className="bg-app-purple-8"
                      // dateFormat={"EEE, MMM dd, yyyy"}
                    />
                  }
                />
              </div>
            </Pdiv>
            <InputErr err={errors?.DateTo?.message} />
            <InputErr err={errors?.DateFrom?.message} />
            <Pdiv2 label={"Site"} name={"CinemaId"} {...register("CinemaId")}>
              <option value={""}>Select Cinema</option>
              <option value={profile.cinemaId}>{profile.cinema}</option>
            </Pdiv2>
            <InputErr err={errors?.CinemaId?.message} />
            <FormGroup className={"mb-[20px] w-full relative"}>
              <Label className={"w-[23rem] mr-[30px]"}>Select Distributor:</Label>

              <Controller
                control={control}
                name={"DistributorId"}
                render={({ field: { value, onChange } }) => {
                  return (
                    <SearchSelect
                      items={distributorsList}
                      inputClassName="!w-full p-2 px-4 py-2 border-none !bg-app-purple-10"
                      listClassName="!py-2"
                      id={`DistributorId`}
                      value={getSelectedItem2(
                        distributorsList,
                        value,
                        "id"
                      )}
                      onChange={(value) => {
                        onChange(value?.id);
                        dispatch(
                          getDistributorFilms({
                            id: value?.id,
                            force: true,
                          })
                        );
                      }}
                      displayValue={(value) => {
                        return value?.name;
                      }}
                      placeholder={`Select Distributor`}
                      searchOptions={{
                        keys: ["name"],
                      }}
                    />
                  );
                }}
              />
              <InlineErr
                err={errors?.DistributorId?.message}
                className={"left-[25rem]"}
              />
            </FormGroup>
            <FormGroup className={"mb-[20px] w-full relative"}>
              <Label className={"w-[23rem] mr-[30px]"}>Select Film:</Label>

              <Controller
                control={control}
                name={"FilmId"}
                render={({ field: { value, onChange } }) => {
                  return (
                    <SearchSelect
                      items={formattedDistributorFilm}
                      inputClassName="!w-full p-2 px-4 py-2 border-none !bg-app-purple-10"
                      listClassName="!py-2"
                      id={`FilmId`}
                      value={getSelectedItem2(
                        formattedDistributorFilm,
                        value,
                        "id"
                      )}
                      onChange={(value) => onChange(value?.id)}
                      displayValue={(value) => {
                        return value?.name;
                      }}
                      placeholder={`Select Film`}
                      searchOptions={{
                        keys: ["name"],
                      }}
                    />
                  );
                }}
              />
              <InlineErr
                err={errors?.FilmId?.message}
                className={"left-[25rem]"}
              />
            </FormGroup>
          </div>
        </form>
      </div>
      <Element name="reportContainer">
        {distributorByFilmReport.paramFilter && (
          <>
            <div className="flex justify-center mt-5 space-x-4">
              <PdfButton
                refValue={reportRef}
                docTitle={"distributor-by-film"}
              />
              <ExportExcelBtn id={"d-byFilm"} name={"distributorByFilm.xlsx"} />
            </div>
            <DistributorByFilmTable getInfo={getInfo} />

            <div className="hidden">
              <div className="p-3 bg-white relative z-10" ref={reportRef}>
                <div className="flex items-center justify-between">
                  <div
                    key={ReachLogo}
                    className=" w-[5rem] h-[5rem]  bg-no-repeat bg-contain bg-center "
                    style={{
                      backgroundImage: `url("${ReachLogo}")`,
                    }}
                  ></div>{" "}
                  <p className="font-bold text-[20px]">Distributor By Film</p>{" "}
                  <p>{profile.cinema}</p>
                </div>
                <div className="flex justify-between p-2">
                  <div>
                    <p>
                      {" "}
                      <span className="font-bold">User Name:</span>
                      {` ${profile.firstName} ${profile.lastName}`}
                    </p>
                    <p>
                      <span className="font-bold">Date Time Generated: </span>
                      {formatDateTime(
                        distributorByFilmReport?.paramFilter?.dateTimeGenerated,
                        dateAndTime
                      )}
                    </p>
                  </div>
                  <div>
                    <p>Distributor</p>
                    <p>{distributorByFilmReport?.paramFilter?.distributor}</p>
                  </div>{" "}
                  <div>
                    <p>Film</p>{" "}
                    <p>{distributorByFilmReport?.paramFilter?.film}</p>
                  </div>
                  <div>
                    <p className="font-bold">Data Range</p>
                    <p>
                      {new Date(distributorByFilmReport?.paramFilter?.dateFrom)
                        .toUTCString()
                        .substring(0, 16)}
                    </p>{" "}
                    <p>
                      {new Date(distributorByFilmReport?.paramFilter?.dateTo)
                        .toUTCString()
                        .substring(0, 16)}
                    </p>
                  </div>
                </div>
                <table
                  id={"d-byFilm"}
                  {...getTableProps()}
                  className="w-full mx-auto mt-8 overflow-hidden text-left border rounded-md border-app-purple-4"
                  style={{ border: "1px" }}
                >
                  <thead className="shadow-2xl bg-app-purple-8">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps()}
                            className="p-4 font-normal tracking-wide uppercase border border-gray-300 text-md"
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>

                  <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr
                          {...row.getRowProps()}
                          className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30"
                        >
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()} className="p-4">
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </Element>
    </PrivateRoute>
  );
};
export { DistributorByFilm };
