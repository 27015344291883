import React, { useState } from "react";
import { PrivateRoute } from "../../../components";
import KioskLayoutForm from "../../../components/Items/KioskLayoutForm";

const KioskLayout = () => {


  return (
    <>
      <PrivateRoute redirectTo="/dashboard/items/kioskLayout">
        <KioskLayoutForm />
      </PrivateRoute>
    </>
  );
};

export { KioskLayout };
