import { yupResolver } from "@hookform/resolvers/yup";
import DatePicker from "react-datepicker";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import {
  FETCH_STATUS,
  getAPIErrMessage,
  getSelectedItem,
  sendRosterInit,
  sendRosterSchema,
} from "../../../utils";
import { ErrorModal, InlineErr, MultiSearchSelect, Spinner } from "../../UI";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { Calendar } from "../../Icons";
import { differenceInDays } from "date-fns";

const SendRosterModal = ({
  show,
  onClose,
  onSend,
  sendStatus,
  sendErr,
  resetSendErr,
  staffs,
}) => {
  const {
    control,
    handleSubmit,
    watch,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(sendRosterSchema),
    defaultValues: sendRosterInit,
  });

  const handleSendRoster = async (values) => {

    onSend(values);
  };
  return (
    <>
      <Transition show={show} as={Fragment}>
        <Dialog as="div" className="relative z-[1000000]" onClose={onClose}>
          {/* Overlay */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          {/* Content */}

          <div className="fixed inset-0 p-4 overflow-y-auto">
            <div className="flex items-center justify-center min-h-[400px] p-2 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-100"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full min-h-[400px] max-w-2xl p-6  text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    className="relative flex justify-between pb-4 border-b"
                    as="div"
                  >
                    <p className="text-lg font-medium leading-6 text-gray-900">
                      Send Roster
                    </p>
                    <button
                      type="button"
                      onClick={onClose}
                      className="absolute right-0 top-2/4 -translate-y-2/4 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    >
                      <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </Dialog.Title>

                  <form
                    onSubmit={handleSubmit(handleSendRoster)}
                    className="my-6"
                  >
                    <div className=" relative grid grid-cols-2 pt-8 gap-4 items-center">
                      <div className="relative w-full">
                        <label
                          htmlFor="dateFrom"
                          className="block text-gray-700 text-sm font-bold mb-2"
                        >
                          Date From
                        </label>

                        <Controller
                          name={`dateFrom`}
                          control={control}
                          render={({ field: { onChange, value } }) => {
                            
                            return (
                              <>
                                <DatePicker
                                  selected={
                                    value ? new Date(value) : new Date()
                                  }
                                  onChange={(value) =>
                                    onChange(value.toISOString())
                                  }
                                  dateFormat="yyyy-MM-dd-HH:mm"
                                  // showTimeInput
                                  // timeInputLabel="Time:"
                                  customInput={
                                    <input
                                      id="dateFrom"
                                      value={value}
                                      onChange={onChange}
                                      className="placeholder:text-[#ABAFB1] px-[30px] py-[10px] border-none outline-none focus:ring-0 rounded-lg bg-[#AA7DAA26] w-full"
                                    />
                                  }
                                />

                                <Calendar className="aspect-square absolute top-[50%] !w-4 text-sec-alt-200 ml-2" />
                              </>
                            );
                          }}
                        />
                        <InlineErr err={errors?.dateFrom?.message} />
                      </div>
                      <div className="relative w-full">
                        <label
                          htmlFor="dateTo"
                          className="block text-gray-700 text-sm font-bold mb-2"
                        >
                          Date To
                        </label>

                        <Controller
                          name={`dateTo`}
                          control={control}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <>
                                <DatePicker
                                  selected={
                                    value ? new Date(value) : new Date()
                                  }
                                  onChange={(value) =>
                                    onChange(value.toISOString())
                                  }
                                  dateFormat="yyyy-MM-dd"
                                  // showTimeInput
                                  // timeInputLabel="Time:"
                                  customInput={
                                    <input
                                      id="dateTo"
                                      value={value}
                                      onChange={onChange}
                                      className="placeholder:text-[#ABAFB1] px-[30px] py-[10px] border-none outline-none focus:ring-0 rounded-lg bg-[#AA7DAA26] w-full"
                                    />
                                  }
                                />

                                <Calendar className="aspect-square absolute top-[50%] !w-4 text-sec-alt-200 ml-2" />
                              </>
                            );
                          }}
                        />
                        <InlineErr err={errors?.dateTo?.message} />
                      </div>
                      <div>
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          htmlFor="filmFormats"
                        >
                          Send to all with shift:
                        </label>

                        <div className="relative">
                          <Controller
                            name="includeAllStaff"
                            control={control}
                            render={({ field: { value, onChange } }) => {
                              return (
                                <input
                                  checked={value}
                                  id="includeAllStaff"
                                  type="checkbox"
                                  onChange={onChange}
                                  className="border border-gray-300 rounded-sm focus:!border-none text-[#C96FCC] !ring-transparent mr-2 items-center w-8 h-8"
                                />
                              );
                            }}
                          />

                          <InlineErr err={errors?.staffId?.message} />
                        </div>
                      </div>

                      <div>
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          htmlFor="filmFormats"
                        >
                          Addtional recipients
                        </label>

                        <div className="relative">
                          <Controller
                            control={control}
                            name="staffId"
                            render={({ field: { value, onChange } }) => {
                              return (
                                <>
                                  <MultiSearchSelect
                                    inputClassName="!bg-[#AA7DAA26]"
                                    listClassName="!py-2"
                                    items={staffs}
                                    id={"claims"}
                                    value={getSelectedItem(staffs, value, "id")}
                                    onChange={(value) =>
                                      onChange(value.map((v) => v.id))
                                    }
                                    displayValue={(value) => {
                                      return value
                                        .map((v) => v.firstName)
                                        .join(", ");
                                    }}
                                    placeholder={`Select Staff`}
                                    searchOptions={{
                                      keys: ["firstName", "lastName"],
                                    }}
                                  />
                                </>
                              );
                            }}
                          />

                          <InlineErr err={errors?.staffId?.message} />
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-center mt-10">
                      <button
                        type="submit"
                        disabled={sendStatus === FETCH_STATUS.PENDING}
                        className="px-10 py-2 rounded-md text-white bg-app-purple-4 inline-flex items-center justify-center space-x-2"
                      >
                        <span>Send</span>
                        <Spinner
                          className="!w-3 !h-3 text-white fill-app-yellow"
                          status={sendStatus}
                        />
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <ErrorModal
        show={sendStatus === FETCH_STATUS.REJECTED}
        message={getAPIErrMessage(sendErr, "Unable to send roster")}
        onClose={resetSendErr}
        onClick={resetSendErr}
      />
    </>
  );
};

export default SendRosterModal;
