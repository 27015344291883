import React, { useMemo } from "react";
import { DefaultListTable } from "../UI";
import { dateAndTime, formatDateTime, formatNumberAsCurrency } from "../../utils";
import ReachLogo from "../../assets/images/logo.png";
import { useLoggedInuser } from "../../hooks";

const PerformanceByFilmTable = ({ data, id, paramFilter }) => {
  const {profile} = useLoggedInuser()
  const columns = useMemo(() => {
    return [
      {
        Header: "Film",
        accessor: "film",
      },
      {
        Header: "Shows",
        accessor: "shows",
      },
      {
        Header: "Admits Capacity",
        accessor: "totalCapacity",
      },
      {
        Header: "Admits",
        accessor: "admits",
      },
      {
        Header: "Occupancy",
        Cell: ({ row }) => {
          return `${Math.round(
            (row.original.admits / row.original.totalCapacity) * 100
          )}%`;
        },
      },
      {
        Header: "Total GBO",
        accessor: "totalGBO",
        Cell: ({ value }) => (id ? value : formatNumberAsCurrency(value)),
      },
      {
        Header: "Estimated concession sales",
        accessor: "estimatedConcessionSales",

        Cell: ({ value }) => (id ? value : formatNumberAsCurrency(value)),
      },
      {
        Header: "Estimated Total Revenue",
        Cell: ({ value, row }) =>
          id
            ? row.original.totalGBO + row.original.estimatedConcessionSales
            : formatNumberAsCurrency(
                row.original.totalGBO + row.original.estimatedConcessionSales
              ),
      },
    ];
  }, [id]);
  return (
    <>
      <div className="flex justify-between items-center">
        <div
          key={ReachLogo}
          className=" ml-[-5px] w-[5rem] h-[3rem] bg-no-repeat bg-contain bg-center"
          style={{
            backgroundImage: `url("${ReachLogo}")`,
          }}
        ></div>

        {/* <div>
          <p>
            {" "}
            <span className="font-bold">User Name:</span>
            {` ${profile?.firstName} ${profile?.lastName}`}
          </p>
          <p>
            <span className="font-bold">Date Time Generated: </span>
            {formatDateTime(
              paramFilter?.dateTimeGenerated,
              dateAndTime
            )}
          </p>
        </div>  */}
      </div>
      <DefaultListTable columns={columns} id={id} data={data} />
    </>
  );
};

export { PerformanceByFilmTable };
