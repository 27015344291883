import { Dialog, Transition } from "@headlessui/react";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { DefaultListTable, ErrorWithWrapper, Spinner } from "../UI";
import { useDispatch, useSelector } from "react-redux";
import {
  getSeatClass,
  getSeatClassById,
  selectScreens,
} from "../../redux/slices";
import { FETCH_STATUS, formatDateTime } from "../../utils";
import { format } from "../Films/DateTimeInput";
import { SeatClassModalForm } from "./SeatClassModalForm";

const SeatClassModal = ({ show, onClose = () => {} }) => {
  const {
    seatClasses,
    seatClassStatus,
    seatClassErr,
    singleSeatClass,
    singleSeatClassStatus,
  } = useSelector(selectScreens);
  const [openSeatClassFormModal, setOpenSeatClassFormModal] = useState(false);
  const [seatClassId, setSeatClassId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const force = true;
  const dispatch = useDispatch();
  // useEffect(() => {
  //   if (seatClassStatus === FETCH_STATUS.IDLE) {
  //     dispatch(getSeatClass(force));
  //   }
  //   // dispatch(getSeatClass())
  // }, [dispatch, seatClassStatus, force]);

  const getInfo = useCallback(() => {
    dispatch(getSeatClass(force));
  }, [dispatch, force]);

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  const handleUpdateSeatClass = useCallback(
    async (seatClassId) => {
      setSeatClassId(seatClassId);
      setIsEdit(true);
      setOpenSeatClassFormModal(true);
      try {
        dispatch(
          getSeatClassById({ seatClassId: seatClassId, force: force })
        ).unwrap();
      } catch (error) {}
    },
    [dispatch, force]
  );

  const handleAddSeatClass = () => {
    setOpenSeatClassFormModal(true);
    setIsEdit(false);
  };
  const columns = useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Status",
        accessor: "isActive",
        Cell: ({ value }) => <span>{value ? "Active" : "Inactive"}</span>,
      },
      {
        Header: "Action",
        Cell: ({ row: { original } }) => {
          return (
            <>
              <div className="flex gap-4">
                <button
                  className="bg-app-purple-4 p-2 rounded-md text-white"
                  onClick={() => handleUpdateSeatClass(original.id)}
                >
                  <span>Update</span>
                </button>
                {/* <button
                  className="bg-app-purple-4 p-2 rounded-md text-white"
                  // onClick={() => handleUpdateSeatClass(original.id)}
                >
                  <span>Delete</span>
                </button> */}
              </div>
            </>
          );
        },
      },
    ];
  }, [handleUpdateSeatClass]);

  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog as="div" className="relative z-[10000000]" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-75"
            leave="ease-in duration-75"
            leaveFrom="opacity-75"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full pb-6 max-w-2xl max-h-max overflow-hidden text-left transition-all transform bg-app-purple-2 shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-center text-white bg-gradient-to-b from-app-purple-4 to-app-purple-3 p-3.5 "
                  >
                    SEAT CLASS
                  </Dialog.Title>
                  <>
                    <div className="flex items-center justify-end p-2 bg-app-purple-5">
                      <div className="inline-flex items-center space-x-7">
                        <button
                          className="p-2 rounded min-w-[92px] text-app-yellow bg-app-purple-4 disabled:opacity-30 inline-flex items-center justify-center space-x-2"
                          onClick={handleAddSeatClass}
                        >
                          Add Seat Class
                        </button>
                        <button
                          className="p-2 text-center rounded min-w-[92px] bg-app-yellow text-app-purple-6"
                          onClick={onClose}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                    <ErrorWithWrapper
                      error={seatClassErr}
                      show={seatClassStatus === FETCH_STATUS.REJECTED}
                      retry={getInfo}
                    />
                    {seatClassStatus === FETCH_STATUS.PENDING && (
                      <>
                        <div className="flex justify-center my-[30px]">
                          <Spinner
                            className="!w-[40px] !h-[40px] mx-1 m-auto text-white fill-pink-400"
                            status={seatClassStatus}
                          />
                        </div>
                      </>
                    )}
                    {seatClassStatus === FETCH_STATUS.RESOLVED && (
                      <div className="overflow-auto h-96">
                        <DefaultListTable
                          data={seatClasses?.length ? seatClasses : []}
                          columns={columns}
                        />
                      </div>
                    )}
                    {openSeatClassFormModal && (
                      <SeatClassModalForm
                        seatClassId={seatClassId}
                        singleSeatClassStatus={singleSeatClassStatus}
                        isEditData={singleSeatClass ? singleSeatClass : {}}
                        isEdit={isEdit}
                        show={openSeatClassFormModal}
                        onClose={() => setOpenSeatClassFormModal(false)}
                      />
                    )}
                  </>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export { SeatClassModal };
