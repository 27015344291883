import React, { Component } from "react";
//import { NavLink } from "react-router-dom";

class ErrorBoundary extends Component {
  state = {
    error: null,
  };
  static getDerivedStateFromError(error) {
    return { error };
  }

  render() {
    const { error } = this.state;

    if (error) {
      return (
        <div className="bg-app-purple-2">
          <div className="flex items-center justify-center w-9/12 min-h-screen py-16 m-auto">
            <div className="p-8 overflow-hidden bg-white shadow sm:rounded-lg">
              <div className="pt-8 text-center border-gray-200">
                <h1 className="my-2 text-5xl font-bold text-purple-400">
                  Oops, something went wrong
                </h1>

                <p>{error.message}</p>

                <hr />

                <code>
                  <pre>{JSON.stringify({ ...error }, null, 2)}</pre>
                </code>

                {/* <div className="flex items-center justify-center">
                  <NavLink
                    to="/dashboard"
                    className="px-6 py-3 mr-6 font-semibold text-white rounded-md bg-gradient-to-r from-purple-400 to-blue-500 hover:from-pink-500 hover:to-orange-500 "
                  >
                    Back to Dashboard
                  </NavLink>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export { ErrorBoundary };
