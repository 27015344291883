import * as React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { vendorInit, vendorSchema } from "../../utils";
import { InlineErr, Spinner } from "../UI";

const VendorForm = ({ onSubmit, vendor, isLoading }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(vendorSchema),
    defaultValues: vendor || vendorInit,
  });

  useEffect(() => {
    if (vendor) reset(vendor);
  }, [vendor]);

  return (
    <form
      className="w-full h-full bg-white rounded-[12px] py-[28px] px-[30px] flex flex-col gap-5"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex flex-col md:grid grid-cols-1 md:grid-cols-2 gap-y-7 gap-x-5">
        <div className="contents relative w-full">
          <input
            className="w-full placeholder:text-[#ABAFB1] px-[16px] py-[14px] border-none rounded-lg bg-[#AA7DAA26]"
            id="name"
            type="text"
            placeholder="Vendor Name"
            {...register("name")}
          />
          <InlineErr err={errors?.name?.message} />
        </div>
        <div className="contents relative w-full">
          <input
            className="w-full placeholder:text-[#ABAFB1] px-[16px] py-[14px] border-none rounded-lg bg-[#AA7DAA26]"
            id="website"
            type="text"
            placeholder="Website"
            {...register("website")}
          />
          <InlineErr err={errors?.website?.message} />
        </div>
        <div className="contents relative w-full">
          <input
            className="w-full placeholder:text-[#ABAFB1] px-[16px] py-[14px] border-none rounded-lg bg-[#AA7DAA26]"
            id="phoneNumber"
            type="tel"
            placeholder="Phone Number"
            {...register("phoneNumber")}
          />
          <InlineErr err={errors?.phoneNumber?.message} />
        </div>
        <div className="contents relative w-full">
          <input
            className="w-full placeholder:text-[#ABAFB1] px-[16px] py-[14px] border-none rounded-lg bg-[#AA7DAA26]"
            id="primaryContact"
            type="text"
            placeholder="Primary Contact"
            {...register("primaryContact")}
          />
          <InlineErr err={errors?.primaryContact?.message} />
        </div>
        <div className="contents relative w-full">
          <input
            className="w-full placeholder:text-[#ABAFB1] px-[16px] py-[14px] border-none rounded-lg bg-[#AA7DAA26]"
            id="email"
            type="email"
            placeholder="Email"
            {...register("email")}
          />
          <InlineErr err={errors?.email?.message} />
        </div>

        <h4 className="col-span-2 text-lg mt-7">Other Details</h4>
        <div className="contents relative w-full mt-[-15px]">
          <select
            className="w-full px-[16px] py-[14px] border-none rounded-lg bg-[#AA7DAA26]"
            id="currency"
            placeholder="Currency"
            {...register("currency")}
          >
            <option value="">Select Currency</option>
            <option>NGN</option>
          </select>
          <InlineErr err={errors?.currency?.message} />
        </div>
        <div className="contents relative w-full  mt-[-15px]">
          <input
            className="w-full placeholder:text-[#ABAFB1] px-[16px] py-[14px] border-none rounded-lg bg-[#AA7DAA26]"
            id="paymentTerms"
            type="text"
            placeholder="Payment Terms"
            {...register("paymentTerms")}
          />
          <InlineErr err={errors?.paymentTerms?.message} />
        </div>

        <h4 className="col-span-2 text-lg mt-7">Address</h4>
        <div className="contents relative w-full mt-[-15px]">
          <input
            className="w-full placeholder:text-[#ABAFB1] px-[16px] py-[14px] border-none rounded-lg bg-[#AA7DAA26]"
            id="streetName"
            type="text"
            placeholder="Street Name"
            {...register("streetName")}
          />
          <InlineErr err={errors?.streetName?.message} />
        </div>
        <div className="contents relative w-full  mt-[-15px]">
          <input
            className="w-full placeholder:text-[#ABAFB1] px-[16px] py-[14px] border-none rounded-lg bg-[#AA7DAA26]"
            id="streetNumber"
            type="number"
            placeholder="Street Number"
            {...register("streetNumber")}
          />
          <InlineErr err={errors?.streetNumber?.message} />
        </div>
        <div className="contents relative w-full">
          <input
            className="w-full placeholder:text-[#ABAFB1] px-[16px] py-[14px] border-none rounded-lg bg-[#AA7DAA26]"
            id="streetLocation"
            type="number"
            placeholder="Street Location"
            {...register("streetLocation")}
          />
          <InlineErr err={errors?.streetLocation?.message} />
        </div>

        <h4 className="col-span-2 text-lg mt-7">Contact Person</h4>
        <div className="contents relative w-full mt-[-15px]">
          <input
            className="w-full placeholder:text-[#ABAFB1] px-[16px] py-[14px] border-none rounded-lg bg-[#AA7DAA26]"
            id="contactPersonName"
            type="text"
            placeholder="Name"
            {...register("contactPersonName")}
          />
          <InlineErr err={errors?.contactPersonName?.message} />
        </div>
        <div className="contents relative w-full  mt-[-15px]">
          <input
            className="w-full placeholder:text-[#ABAFB1] px-[16px] py-[14px] border-none rounded-lg bg-[#AA7DAA26]"
            id="contactPersonPhoneNumber"
            type="number"
            placeholder="Phone Number"
            {...register("contactPersonPhoneNumber")}
          />
          <InlineErr err={errors?.contactPersonPhoneNumber?.message} />
        </div>
        <div className="contents relative w-full">
          <input
            className="w-full placeholder:text-[#ABAFB1] px-[16px] py-[14px] border-none rounded-lg bg-[#AA7DAA26]"
            id="contactPersonEmail"
            type="email"
            placeholder="Email"
            {...register("contactPersonEmail")}
          />
          <InlineErr err={errors?.contactPersonEmail?.message} />
        </div>

        <div className="flex justify-end col-span-2">
          <button
            type="submit"
            className="py-[12px] px-[36px] rounded-[14px] bg-gradient-to-b from-[#764877] to-[#A86AAA] text-[#fff] w-auto"
            disabled={isLoading}
          >
            Save & Publish
            <Spinner
              className="!w-3 !h-3 text-white fill-app-yellow"
              status={isLoading}
            />
          </button>
        </div>
      </div>
    </form>
  );
};

export default VendorForm;
