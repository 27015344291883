/* eslint-disable react/jsx-key */
import * as React from "react";
import { useMemo, useState } from "react";
import { Icon } from "@iconify/react";
import {
  useAsyncDebounce,
  useGlobalFilter,
  useSortBy,
  useTable,
} from "react-table";
import { useLocation, useNavigate } from "react-router-dom";

const VendorList = ({ vendors }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const columns = useMemo(
    () => [
      {
        Header: "Company Name",
        accessor: "name",
      },
      {
        Header: "Phone",
        accessor: "phoneNumber",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Contact Person",
        accessor: "contactPersonName",
      },
      {
        Header: "Action",
        Cell: (props) => {
          // console.log(props);
          return (
            <div className="flex items-center gap-3">
              <button
                onClick={() => navigate(`${pathname}/${props.row.original.id}`)}
              >
                <Icon icon="lucide:pencil" color="#534C7E" fontSize={20} />
              </button>
              <button>
                <Icon
                  icon="mingcute:delete-2-line"
                  color="#534C7E"
                  fontSize={20}
                />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);

  const totalCount = useMemo(() => {
    return Math.ceil(vendors.length / pageSize);
  }, [vendors, pageSize]);

  const prev = () => {
    if (pageIndex !== 0) {
      setPageIndex(pageIndex - 1);
    }
  };

  const next = () => {
    if (pageIndex < totalCount - 1) {
      setPageIndex(pageIndex + 1);
    }
  };

  const createVendor = () => {
    navigate("/dashboard/stock/vendors/create");
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state: { globalFilter },
  } = useTable({ columns, data: vendors }, useGlobalFilter, useSortBy);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="bg-[#E6C4E7] rounded-[20px] border overflow-hidden border-[#AA7DAA]">
      <div className="px-5 py-5 flex flex-col md:flex-row justify-between items-center gap-4">
        <form className="w-full md:w-[250px] lg:[350px] xl:w-[400px]">
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
          >
            Search
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <Icon
                icon="carbon:search"
                color="#8B83BA"
                fontSize={24}
                fontWeight={580}
              />
            </div>
            <input
              type="search"
              id="default-search"
              className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:border-[#E6C4E7]"
              placeholder="Search vendors by Name or Contact person"
              onChange={(e) => {
                onChange(e.target.value);
              }}
              value={globalFilter || ""}
            />
          </div>
        </form>
        <div className="flex gap-4">
          <button
            className="py-[12px] px-[36px] rounded-[14px] bg-gradient-to-b from-[#764877] to-[#A86AAA] text-[#fff]"
            onClick={createVendor}
          >
            Add
          </button>
          <button className="py-[11px] px-[26px] rounded-[14px] bg-[#fff] text-[##915A92]">
            Export
          </button>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table
          className="w-full block whitespace-nowrap overflow-x-auto md:table "
          {...getTableProps()}
        >
          <thead className="bg-[#C080C5]">
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column, index, array) => (
                      // Apply the header cell props
                      <th
                        className="text-start text-white px-[15px] py-[13px] bg-[#C080C5]"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        <span className="flex items-center gap-3">
                          {
                            // Render the header
                            column.render("Header")
                          }
                          {index < array.length - 1 && (
                            <span className="flex flex-col">
                              <Icon
                                icon="material-symbols:keyboard-arrow-up"
                                style={{
                                  visibility:
                                    !column.isSorted ||
                                    (column.isSorted && !column.isSortedDesc)
                                      ? "visible"
                                      : "hidden",
                                }}
                              />
                              {(!column.isSorted ||
                                (column.isSorted && column.isSortedDesc)) && (
                                <Icon
                                  icon="material-symbols:keyboard-arrow-down"
                                  className="mt-[-7px]"
                                  style={{
                                    visibility:
                                      !column.isSorted ||
                                      (column.isSorted && column.isSortedDesc)
                                        ? "visible"
                                        : "hidden",
                                  }}
                                />
                              )}
                            </span>
                          )}
                        </span>
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows
                .slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)
                .map((row, index, array) => {
                  // Prepare the row for display
                  prepareRow(row);
                  return (
                    // Apply the row props
                    <tr
                      className={`bg-[#E6C4E7] border-[#8177B3] ${
                        index < array.length - 1 && "border-b"
                      }`}
                      {...row.getRowProps()}
                    >
                      {
                        // Loop over the rows cells
                        row.cells.map((cell) => {
                          // Apply the cell props
                          return (
                            <td
                              className="bg-[#E6C4E7] pt-[16px] pb-[12px] pl-[18px] font-sans text-[#534C7E] text-lg"
                              {...cell.getCellProps()}
                            >
                              {
                                // Render the cell contents
                                cell.render("Cell")
                              }
                            </td>
                          );
                        })
                      }
                    </tr>
                  );
                })
            }
          </tbody>
        </table>
      </div>
      <div className="flex text-sm items-center gap-4 justify-end  py-[14px] px-[18px] font-sans text-[#F1F1F1] font-semibold bg-[#C080C5]">
        <span className="flex">
          Rows per page:
          <select
            className="bg-transparent border-0 text-sm py-0 pr-[20px] tiny-select"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[1, 10, 20, 30, 40, 50].map((pageSize) => (
              <option
                className="bg-[#C080C5] border-0"
                key={pageSize}
                value={pageSize}
              >
                {pageSize}
              </option>
            ))}
          </select>
        </span>
        <span>
          {pageIndex + 1}-
          {(pageIndex + 1) * pageSize > vendors.length
            ? vendors.length
            : (pageIndex + 1) * pageSize}{" "}
          of {vendors.length}
        </span>
        <span className="flex gap-3">
          <button onClick={prev}>
            <Icon
              icon="ic:baseline-keyboard-arrow-left"
              color="#fff"
              fontSize={24}
            />
          </button>
          <button onClick={next}>
            <Icon
              icon="ic:baseline-keyboard-arrow-right"
              color="#fff"
              fontSize={24}
            />
          </button>
        </span>
      </div>
    </div>
  );
};

export default VendorList;
