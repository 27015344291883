import { currency, currencyKey } from "./constants";

const formatNumber = (value) => {
  return value ? new Intl.NumberFormat("en-NG", {}).format(value) : 0;
};

const truncateAndFormat = (integer, fraction) => {
  if (fraction.toString().length) {
    return `${formatNumber(integer)}.${fraction}`;
  }

  return formatNumber(integer);
};

const cleanNumberString = (value) => {
  let normalized = value.toString();

  //remove all chars except number and dot
  normalized = normalized.replace(/[^\d.-]/g, "");

  //replace all dots except first
  let index = 0;
  let result = normalized.replace(/\./g, (item) => (!index++ ? item : ""));

  return result;
};

const formatNumberAsCurrency = (result, noSymbol) => {
  if (result === "" || isNaN(result)) {
    return isNaN(result) ? "" : result;
  }

  const val = cleanNumberString(result?.toString());

  let configuredCurrency = localStorage.getItem(currencyKey) || "";

  const symbol = noSymbol ? "" : configuredCurrency;

  if (val.includes(".")) {
    const split = val.split(".");

    if (split[1].length) {
      const formatted = truncateAndFormat(+split[0], split[1]);

      return `${symbol}${formatted}`;
    }

    return `${symbol}${formatNumber(+split[0])}.`;
  }

  return `${symbol}${formatNumber(val)}`;
};

export { formatNumberAsCurrency, cleanNumberString, truncateAndFormat };
