import React from "react";
import { getBgColor, getTextColor } from "../../utils/mockData/sessions";
import { formatAMPM } from "../../utils";

const TaskSession = ({ list, event }) => {
  const task = list[event?.rosterTaskId];
  const bg = getBgColor(event?.rosterTaskId);
  const textColor = getTextColor(bg);
  return (
    <>
      <div
        style={{
          backgroundColor: bg,
          color: textColor,
        }}
        className="p-2 mx-2 my-1 rounded-lg space-y-5"
      >
        <h3 className="mb-1 text-xs font-medium truncate ">
          {`${event?.firstName} ${event?.lastName}`}
        </h3>
        <div className="text-[10px] font-normal truncate">
          <p>{task?.name}</p>
          <p>{formatAMPM(event?.scheduledDate)}</p>
        </div>
      </div>
    </>
  );
};

export default TaskSession;
