import React from "react";
import { formatNumberAsCurrency } from "../../utils";

const getSum = (list = [], key) => {
  const sum = list.reduce((sum, item) => (sum += +item[key]), 0);
  return sum;
};

const getSumActive = (list = []) => {
  return list.filter((s) => s.isActive).length;
};

const SessionsSummary = ({ data }) => {
  return (
    <div className="flex flex-wrap my-4 -mx-2">
      <article className="flex items-center justify-between p-4 mx-2 my-2 space-x-2 text-base font-medium rounded-lg bg-app-purple-2 grow">
        <div>
          <p>All active sessions</p>
          <p className="my-2 text-lg">
            {formatNumberAsCurrency(getSumActive(data), true)}
          </p>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          fill="none"
          viewBox="0 0 50 50"
        >
          <circle
            cx="25"
            cy="25"
            r="25"
            fill="url(#paint0_linear_1871_4752)"
          ></circle>
          <path
            fill="#fff"
            fillOpacity="0.87"
            d="M25.258 16.809a.834.834 0 00-.933 0c-.25.167-5.992 4.167-5.992 10.067a6.458 6.458 0 1012.917 0c0-6-5.75-9.909-5.992-10.067zm-.467 14.858A4.8 4.8 0 0120 26.876c0-4.167 3.508-7.309 4.791-8.334 1.292 1.009 4.792 4.167 4.792 8.334a4.8 4.8 0 01-4.792 4.791z"
          ></path>
          <defs>
            <linearGradient
              id="paint0_linear_1871_4752"
              x1="-8.333"
              x2="59.167"
              y1="0"
              y2="50"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.02" stopColor="#7E2080"></stop>
              <stop offset="1" stopColor="#7E2080" stopOpacity="0"></stop>
            </linearGradient>
          </defs>
        </svg>
      </article>

      <article className="flex items-center justify-between p-4 mx-2 my-2 space-x-2 text-base font-medium rounded-lg bg-app-purple-2 grow">
        <div>
          <p>Expected Revenue </p>
          <p className="my-2 text-lg">
            {formatNumberAsCurrency(getSum(data, "totalExpected"))}
          </p>{" "}
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          fill="none"
          viewBox="0 0 50 50"
        >
          <circle
            cx="25"
            cy="25"
            r="25"
            fill="url(#paint0_linear_1871_4761)"
          ></circle>
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M24.791 16.666l-4.566 4.881a7.075 7.075 0 00-1.796 3.698 7.334 7.334 0 00.521 4.13A6.747 6.747 0 0021.6 32.43a6.116 6.116 0 003.812.87 6.231 6.231 0 003.346-1.423 6.903 6.903 0 002.113-3.116 7.357 7.357 0 00.223-3.842 7.051 7.051 0 00-1.735-3.373l-4.567-4.881zm-3.552 5.966l3.552-3.797v12.963a4.795 4.795 0 01-2.79-.905 5.309 5.309 0 01-1.85-2.41 5.715 5.715 0 01-.286-3.102 5.494 5.494 0 011.375-2.749z"
            clipRule="evenodd"
          ></path>
          <defs>
            <linearGradient
              id="paint0_linear_1871_4761"
              x1="-8.333"
              x2="59.167"
              y1="0"
              y2="50"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.02" stopColor="#7E2080"></stop>
              <stop offset="1" stopColor="#7E2080" stopOpacity="0"></stop>
            </linearGradient>
          </defs>
        </svg>
      </article>

      <article className="flex items-center justify-between p-4 mx-2 my-2 space-x-2 text-base font-medium rounded-lg bg-app-purple-2 grow">
        <div>
          <p>Total Revenue </p>
          <p className="my-2 text-lg">
            {formatNumberAsCurrency(getSum(data, "totalActual"))}
          </p>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          fill="none"
          viewBox="0 0 50 50"
        >
          <circle
            cx="25"
            cy="25"
            r="25"
            fill="url(#paint0_linear_1871_4770)"
          ></circle>
          <path
            fill="#fff"
            fillOpacity="0.87"
            d="M27.5 15.834h-9.166c-.917 0-1.667.75-1.667 1.667v10.833c0 .458.375.833.833.833a.836.836 0 00.834-.833v-10c0-.458.375-.833.833-.833H27.5a.836.836 0 00.834-.834.836.836 0 00-.834-.833zm.492 3.825l4.025 4.025c.308.308.483.733.483 1.175v7.642c0 .916-.75 1.666-1.666 1.666h-9.175c-.917 0-1.659-.75-1.659-1.666l.009-11.667c0-.917.741-1.667 1.658-1.667h5.142c.441 0 .866.175 1.183.492zm-.492 5.342h3.75l-4.583-4.584v3.75c0 .459.375.834.833.834z"
          ></path>
          <defs>
            <linearGradient
              id="paint0_linear_1871_4770"
              x1="-8.333"
              x2="59.167"
              y1="0"
              y2="50"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.02" stopColor="#7E2080"></stop>
              <stop offset="1" stopColor="#7E2080" stopOpacity="0"></stop>
            </linearGradient>
          </defs>
        </svg>
      </article>
    </div>
  );
};

export { SessionsSummary };
