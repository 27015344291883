import { useMemo } from "react";

const useUniqueId = (arraySize = 1) => {
  const memoizedIds = useMemo(() => {
    const ids = [];

    for (let i = 0; i < arraySize; i = i + 1) {
      ids.push(crypto.randomUUID());
      // ids.push(_uniqueId());
    }
    return ids;
  }, [arraySize]);

  return memoizedIds;
};

export  {useUniqueId};
