import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  createMigrate,
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";

import {
  authSlice,
  dashboardSlice,
  filmsSlice,
  itemsSlice,
  locationSlice,
  metaDataSlice,
  priceCardsSlice,
  reportsSlice,
  screensSlice,
  showTimesSlice,
  staffSessionsSlice,
  stockSlice,
  filmContractSlice,
  ticketsSlice,
  userSlice,
  vendorSlice,
  distributorSlice,
  claimsSlice,
  refundReportSlice,
  rosterSlice,
  layoutSlice,
  cinemaSettingsSlice,
  financeSlice,
  migrationSlice,
  // connectSlice,
} from "./slices";

const migrations = {
  10: (state) => {
    // migration to keep only device state
    return {
      priceCards: [],
    };
  },
};

const persistConfig = {
  key: "reach",
  storage,
  version: 10,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(migrations, { debug: false }),
  // migrate: (state) => {
  //   console.log("Migration Running!");
  //   return Promise.resolve(state);
  // },
  blacklist: [staffSessionsSlice.name, showTimesSlice.name],
};

const syncConfig = {
  blacklist: [
    "flush/FLUSH",
    "rehydrate/REHYDRATE",
    "pause/PAUSE",
    "persist/PERSIST",
    "purge/PURGE",
    "register/REGISTER",
  ],
};

const rootReducer = combineReducers({
  [authSlice.name]: authSlice.reducer,
  [priceCardsSlice.name]: priceCardsSlice.reducer,
  [ticketsSlice.name]: ticketsSlice.reducer,
  [itemsSlice.name]: itemsSlice.reducer,
  [userSlice.name]: userSlice.reducer,
  [filmsSlice.name]: filmsSlice.reducer,
  [screensSlice.name]: screensSlice.reducer,
  [staffSessionsSlice.name]: staffSessionsSlice.reducer,
  [showTimesSlice.name]: showTimesSlice.reducer,
  [reportsSlice.name]: reportsSlice.reducer,
  [dashboardSlice.name]: dashboardSlice.reducer,
  [metaDataSlice.name]: metaDataSlice.reducer,
  [stockSlice.name]: stockSlice.reducer,
  [filmContractSlice.name]: filmContractSlice.reducer,
  [locationSlice.name]: locationSlice.reducer,
  [vendorSlice.name]: vendorSlice.reducer,
  [distributorSlice.name]: distributorSlice.reducer,
  [claimsSlice.name]: claimsSlice.reducer,
  [refundReportSlice.name]: refundReportSlice.reducer,
  [cinemaSettingsSlice.name]: cinemaSettingsSlice.reducer,
  [rosterSlice.name]: rosterSlice.reducer,
  [layoutSlice.name]: layoutSlice.reducer,
  [financeSlice.name]: financeSlice.reducer,
  // [connectSlice.name]: connectSlice.reducer
  [migrationSlice.name]: migrationSlice.reducer,
});

const initState = {
  [authSlice.name]: authSlice.getInitialState(),
  [priceCardsSlice.name]: priceCardsSlice.getInitialState(),
  [ticketsSlice.name]: ticketsSlice.getInitialState(),
  [itemsSlice.name]: itemsSlice.getInitialState(),
  [userSlice.name]: userSlice.getInitialState(),
  [filmsSlice.name]: filmsSlice.getInitialState(),
  [screensSlice.name]: screensSlice.getInitialState(),
  [staffSessionsSlice.name]: staffSessionsSlice.getInitialState(),
  [showTimesSlice.name]: showTimesSlice.getInitialState(),
  [reportsSlice.name]: reportsSlice.getInitialState(),
  [dashboardSlice.name]: dashboardSlice.getInitialState(),
  [metaDataSlice.name]: metaDataSlice.getInitialState(),
  [stockSlice.name]: stockSlice.getInitialState(),
  [filmContractSlice.name]: filmContractSlice.getInitialState(),
  [locationSlice.name]: locationSlice.getInitialState(),
  [vendorSlice.name]: vendorSlice.getInitialState(),
  [distributorSlice.name]: distributorSlice.getInitialState(),
  [claimsSlice.name]: claimsSlice.getInitialState(),
  [refundReportSlice.name]: refundReportSlice.getInitialState(),
  [cinemaSettingsSlice.name]: cinemaSettingsSlice.getInitialState(),
  [rosterSlice.name]: rosterSlice.getInitialState(),
  [layoutSlice.name]: layoutSlice.getInitialState(),
  [financeSlice.name]: financeSlice.getInitialState(),
  // [connectSlice.name]:connectSlice.getInitialState()
  [migrationSlice.name]: migrationSlice.getInitialState(),
};

const appReducer = (rootState, action) => {
  if (action.type === "auth/logout") {
    localStorage.clear();
    return rootReducer(initState, action);
  }
  return rootReducer(rootState, action);
};

const persistedReducer = persistReducer(persistConfig, appReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(createStateSyncMiddleware(syncConfig)),
});

initMessageListener(store);

export const persistor = persistStore(store);
