import React, { useMemo, useCallback } from "react";

// import { ConfirmModal, DefaultListTable, ErrorModal, SuccessModal } from "../UI";
import { API_URL, FETCH_STATUS, axiosApiInstance, formatAMPM, getAPIErrMessage } from "../../utils";
import { NavLink, useNavigate } from "react-router-dom";
import { Delete, Edit } from "../Icons";
import { useRequest, useToggle } from "../../hooks";
import { useState } from "react";
import { getShifts } from "../../redux/slices";
import { ConfirmModal, DefaultListTable, ErrorModal, SuccessModal } from "../UI";


const ShiftTable = ({ shifts,getInfo }) => {
  const [isOpen, setOpen] = useToggle(false)
  const [isSuccess, setSuccess] = useToggle(false)
  const [shiftId, setShiftId] = useState("");
   const {
    err,
    status: deleteStatus,
    resetErr,
    setResolved,
    setPending,
    setRejected,
  } = useRequest();

   const handleOpen = useCallback((id) => {
    setShiftId(id);
    setOpen()
   }, [setOpen]);
  
   const handleCloseSuccessModal = useCallback(() => {
   setSuccess()
    setOpen()
  },[setOpen, setSuccess]);
  
  const handleDeleteShift = async () => {
    try {
      setPending();
      await axiosApiInstance
        .delete(`${API_URL}/Shifts/${shiftId}`)
        .then(() => {
          getInfo();
        });
      setResolved();
      setSuccess()
    } catch (err) {
      setRejected(err || "unable to delete shift");
    }
  };

  const columns = useMemo(() => {
    return [
      {
        Header: "#",
        Cell: (row) => Number(row.row.id) + 1,
      },
      {
        Header: "Shift Name",
        accessor: "name",
      },
      {
        Header: "Time Range",
        // accessor: "timeRange",
        Cell: ({ row: { original } }) => {
          return (
            <>{`${formatAMPM(original.dateFrom)} - ${formatAMPM(
              original.dateTo
            )}`}</>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          
          return <>{value === 1 ? "Active" : "Inactive"}</>;
        },
      },
      // {
      //   Header: "Action",
      //   Cell: ({ row: { original } }) => {
      //     return (
      //       <span className="flex space-x-4">
      //         <button className="pl-2" onClick={() => handleOpen(original.id)}>
      //           <Delete />
      //         </button>
      //         <NavLink to={`/dashboard/shifts/${original?.id}`}>
      //           <Edit />
      //         </NavLink>
      //       </span>
      //     );
      //   },
      // },
    ];
  }, [handleOpen]);

  return (
    <>
      <DefaultListTable
        columns={columns}
        data={shifts}
        tableClass=""
        bodyClass="!bg-white !border !border-gray-200"
        headerClass="!bg-[#EEE1EE] !border-none"
      />
       {isOpen && (
        <>
          <ConfirmModal
            onClick={handleDeleteShift}
            text="Are you sure you want to delete this shift?"
            onClose={setOpen}
            show={isOpen}
            status={deleteStatus}
          />
        </>
      )}
        <SuccessModal
        onClick={handleCloseSuccessModal}
        message="Shift deleted successfully."
        show={isSuccess}
      />

      <ErrorModal
        show={deleteStatus === FETCH_STATUS.REJECTED}
        message={getAPIErrMessage(err, " Unable to delete shift")}
        onClose={resetErr}
        onClick={resetErr}
      />
    </>
  );
};

export { ShiftTable };
