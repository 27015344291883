import DatePicker from "react-datepicker";
import {
    ExportExcelBtn,
    PdfButton,
    PerformanceByShowtimeTable,
    PrivateRoute,
    ReportWrapper,
    Success,
} from "../../../components";
import React, {useRef, useState} from "react";
import {useLoggedInuser} from "../../../hooks";
import {useDispatch, useSelector} from "react-redux";
import {DateTimeInput} from "../../../components/Films/DateTimeInput";
import {getPerformanceByShowtime, reportData} from "../../../redux/slices";
import {Element, scroller} from "react-scroll";
import {FETCH_STATUS, formatDateForApi} from "../../../utils";

const PerformanceByShowtime = () => {
  const [date, setDate] = useState(new Date().toISOString());
  const {
    performanceByShowtime,
    performanceByShowtimeStatus,
    performanceByShowtimeErr,
  } = useSelector(reportData);
  const { profile } = useLoggedInuser();
  const dispatch = useDispatch();
  const reportRef = useRef(null);

  const handleSubmitPerformanceByShowtime = async (e) => {
    e.preventDefault();
    try {
      await dispatch(
        getPerformanceByShowtime({
          todayDate: date,
          cinemaId: profile?.cinemaId,
        })
      ).then(() => {
        scroller.scrollTo("reportContainer", {
          duration: 1500,
          delay: 0,
          smooth: true,
          offset: -100, // Scrolls to element + 50 pixels down the page
        });
      });
    } catch (error) {}
  };
  return (
    <>
      <PrivateRoute redirectTo={"/dashboard/reports/performance-by-film"}>
        <div className="w-[95%] mx-auto mt-6">
          <ReportWrapper
            reportName="Performance By Showtime"
            goBackRoute={"/dashboard/reports"}
            submitFor={"performanceByShowtime"}
            reportStatus={performanceByShowtimeStatus}
          >
            <form
              onSubmit={handleSubmitPerformanceByShowtime}
              id={"performanceByShowtime"}
            >
              <div className="flex justify-center items-center">
                <p className="mr-6">Today Date:</p>

                <div className="relative ">
                  <DatePicker
                    placeholderText="Start Date"
                    selected={new Date(date)}
                    onChange={(value) => setDate(formatDateForApi(new Date(value)))}
                    customInput={
                      <DateTimeInput
                        className="bg-app-purple-8"
                        dateFormat={"EEE, MMM dd, yyyy"}
                      />
                    }
                  />
                </div>
              </div>
            </form>
          </ReportWrapper>
          <Success show={performanceByShowtimeStatus === FETCH_STATUS.RESOLVED}>
            <Element name="reportContainer">
              {performanceByShowtime?.length ? (
                <>
                  <div className="flex justify-center mt-5 space-x-4">
                    <PdfButton
                      refValue={reportRef}
                      docTitle={"performance-by-showtime"}
                    />
                    <ExportExcelBtn
                      id={"performanceByShowtime-xlsx"}
                      name={"performanceByShowtime.xlsx"}
                    />
                  </div>
                  <div ref={reportRef} className="m-2">
                    <PerformanceByShowtimeTable
                      data={performanceByShowtime ?? []}
                     
                    />
                  </div>
                  <div  className="m-2 hidden">
                    <PerformanceByShowtimeTable
                      data={performanceByShowtime ?? []}
                      id={"performanceByShowtime-xlsx"}
                    />
                  </div>
                </>
              ):null}
            </Element>
            {!performanceByShowtime?.length && (
              <p className="text-red-700 flex justify-center mt-3">
                No Data Found
              </p>
            )}
          </Success>
          {performanceByShowtimeStatus === FETCH_STATUS.REJECTED && (
            <p className="text-red-700 flex justify-center mt-3">
              Sorry an error occured...
            </p>
          )}
        </div>
      </PrivateRoute>
    </>
  );
};

export { PerformanceByShowtime };
