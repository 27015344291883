import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";
import TicketsModal from "./TicketsModal";

const TicketsTableToolbar = ({
  globalFilter,
  setGlobalFilter,
  tickets = [],
  addTickets = () => {},
}) => {
  const [value, setValue] = useState(globalFilter);
  const [showModal, setShowModal] = useState(false);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <>
      <div className="flex items-center justify-between mx-3 my-6">
        <div className="relative">
          <div
            className={`absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none`}
          >
            <svg
              className="w-5 h-5 text-[#CEBCCE] "
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </div>
          <input
            type="search"
            id="default-search"
            value={value || ""}
            className={`block min-w-[280px] w-full p-3 text-sm pl-10 rounded-xl bg-white outline-none `}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
            placeholder={`Search`}
          />
        </div>

        <button
          onClick={() => setShowModal(true)}
          className="px-4 py-3 text-base font-medium bg-white rounded-md text-app-purple-9"
          type="button"
        >
          Add Ticket
        </button>
      </div>

      <TicketsModal
        show={showModal}
        onClose={() => setShowModal(false)}
        tickets={tickets}
        addTickets={addTickets}
      />
    </>
  );
};

export default TicketsTableToolbar;
