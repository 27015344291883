import React from "react";

const Logout = ({ fill = "#AA7DAA" }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <path
          d="M18.6667 10.6667V7.99999C18.6667 7.29274 18.3857 6.61447 17.8856 6.11437C17.3855 5.61427 16.7072 5.33332 16 5.33332H6.66667C5.95942 5.33332 5.28115 5.61427 4.78105 6.11437C4.28095 6.61447 4 7.29274 4 7.99999V24C4 24.7072 4.28095 25.3855 4.78105 25.8856C5.28115 26.3857 5.95942 26.6667 6.66667 26.6667H16C16.7072 26.6667 17.3855 26.3857 17.8856 25.8856C18.3857 25.3855 18.6667 24.7072 18.6667 24V21.3333"
          stroke={fill}
          strokeWidth="2.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.6665 15.6365L9.33317 15.6365L22.6665 15.6365L26.6665 15.6365ZM26.6665 15.6365L22.6665 11.6365"
          stroke={fill}
          strokeWidth="2.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.5454 20L26.5454 16.0001"
          stroke={fill}
          strokeWidth="2.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export { Logout };
