import React from "react";
import { useLayoutEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";

const EventCard = ({ event, renderEvent, useContextTrigger }) => {
  const [bindTrigger] = useContextTrigger({
    collect: () => event,
  });

  return <div {...bindTrigger}>{renderEvent(event)}</div>;
};

const DayRender = ({ dayEvents = [], renderEvent, useContextTrigger }) => {
  return (
    <div className="relative truncate">
      {dayEvents.map((ev, i) => (
        <EventCard
          renderEvent={renderEvent}
          useContextTrigger={useContextTrigger}
          event={ev}
          key={i}
        />
      ))}
    </div>
  );
};
const RosterWeekRowRender = ({
  setRowHeights,
  horizontalOffset,

  dayEvents,
  renderEvent,
  index,
  useContextTrigger = () => {},
}) => {
  const [node, setNode] = useState(null);

  const dayColRef = useCallback((node) => {
    if (node !== null) {
      setNode(node);
    }
  }, []);

  useLayoutEffect(() => {
    if (node) {
      const measure = () => {
        setRowHeights((heights) => {
          const newHeights = [...heights];
          const { height } = node.getBoundingClientRect();
          newHeights[index] = height + 10;

          return newHeights;
        });
      };

      measure();
      window.addEventListener("resize", measure);

      return () => {
        window.removeEventListener("resize", measure);
      };
    }
  }, [node, setRowHeights, index, dayEvents]);
  return (
    <>
      <div
        className="absolute left-0 truncate top-2/4"
        ref={dayColRef}
        style={{
          transform: `translate(${horizontalOffset}px,-50%)`,
          width: `${100 / 7}%`,
        }}
      >
        <DayRender
          offsetHorizontal={horizontalOffset}
          renderEvent={renderEvent}
          dayEvents={dayEvents}
          useContextTrigger={useContextTrigger}
        />
      </div>
    </>
  );
};

export default RosterWeekRowRender;
