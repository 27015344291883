import * as React from "react";
const Stock = ({ fill = process.env.REACT_APP_COLOR_10 }) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    // {...props}
  >
    <path
      d="M31.3679 9.49436C31.3654 9.38201 31.3502 9.2703 31.3227 9.16135C31.3118 9.12245 31.29 9.0851 31.276 9.0462C31.2433 8.9575 31.2138 8.87036 31.1671 8.78944C31.1422 8.74743 31.1079 8.71319 31.0784 8.67274C31.0286 8.60271 30.9803 8.53113 30.9196 8.47044C30.8838 8.43621 30.8371 8.4082 30.7982 8.37552C30.7375 8.32572 30.6815 8.27126 30.6114 8.22925C30.6052 8.22458 30.5974 8.22458 30.5896 8.21991L30.5772 8.21057L16.6019 0.445603C16.371 0.317576 16.1113 0.250273 15.8473 0.250001C15.5832 0.249729 15.3234 0.316497 15.0922 0.444047L1.05157 8.20902C1.0469 8.21368 1.04223 8.21991 1.03444 8.22458L1.01888 8.2308C0.964405 8.26192 0.923938 8.30705 0.872575 8.3444C0.816543 8.38641 0.757398 8.42376 0.707592 8.472C0.660899 8.52024 0.625101 8.57626 0.584634 8.63072C0.54261 8.68518 0.495917 8.73342 0.461675 8.79256C0.421208 8.85947 0.399418 8.93572 0.369846 9.00885C0.348055 9.06021 0.32004 9.10845 0.306032 9.16446C0.279151 9.27143 0.264016 9.381 0.260895 9.49125C0.259339 9.5177 0.25 9.54104 0.25 9.56905V22.021C0.25 22.5859 0.556618 23.1072 1.05157 23.381L15.0253 31.1413L15.0268 31.1429L15.0299 31.1445L15.0611 31.1616C15.128 31.1989 15.2011 31.2192 15.2712 31.2456C15.321 31.2658 15.3692 31.2923 15.4222 31.3063C15.6804 31.3729 15.9514 31.3729 16.2097 31.3063C16.2611 31.2923 16.3093 31.2658 16.3607 31.2456C16.4307 31.2192 16.5039 31.2005 16.5708 31.1616L16.6019 31.1445L16.605 31.1429L16.6066 31.1413L30.5803 23.381C31.0721 23.1072 31.3787 22.5859 31.3787 22.021V9.56905C31.3787 9.5426 31.3694 9.52081 31.3679 9.49436ZM15.7708 15.5974L5.00959 9.57995L9.30847 7.20222L19.9452 13.2788L15.7708 15.5974ZM15.8455 3.58582L26.6176 9.57216L23.125 11.5126L12.4945 5.43915L15.8455 3.58582ZM17.3708 27.1577L17.3739 18.3206L22.0401 15.7094V20.4649L25.153 18.9088V13.9682L28.2659 12.2269V21.1045L17.3708 27.1577Z"
      fill={fill}
    />
  </svg>
);
export { Stock };
