import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { Spinner } from '.';

const ConfirmModal = ({
  show,
  onClose,
  onClick,
  status,
  text,
  formId,
  isSubmit,
}) => {
  return (
    <>
      <Transition show={show} appear as={Fragment}>
        <Dialog as="div" className="relative z-[10000]" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-sm max-h-[90vh] p-2 text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
                  {/* <div> */}
                  <div className="flex justify-end items-center mb-6">
                    <button
                      className="border border-gray-400 text-[9px] bg-gray-300 rounded-full px-[0.3rem]"
                      onClick={onClose}
                    >
                      x
                    </button>
                  </div>
                  {/* </div> */}
                  <div className="flex justify-center items-center mb-3">
                    <p className="text-gray-600 font-semibold">
                      Sure you want to accept?
                    </p>
                  </div>
                  <div className="flex justify-center items-center mb-3">
                    <p className="text-gray-500 text-sm">{text}</p>
                  </div>
                  <div className=" flex justify-around mb-3">
                    <button
                      type="button"
                      className="bg-transparent w-[12rem] mx-2 py-2 border border-gray-500 rounded-md text-sm text-black"
                      onClick={onClose}
                    >
                      No, cancel
                    </button>
                    <button
                      onClick={onClick}
                      type={isSubmit ? 'submit' : 'button'}
                      form={formId}
                      className="bg-gradient-to-b to-[#764877] from-[#A86AAA] w-[12rem] relative py-2 mx-2 rounded-md text-sm text-white"
                    >
                      Yes, Confirm
                      <Spinner
                        className="!w-3 !h-3 text-white mx-1 !absolute top-[0.8rem] right-4"
                        status={status}
                      />
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export {ConfirmModal};
