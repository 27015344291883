import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  API_URL,
  axiosApiInstance,
  axiosApiInstanceGet,
  editItemDirect,
  FETCH_STATUS,
  getErrMsg,
} from "../../utils";

export const getClaims = createAsyncThunk(
  "getClaims",
  async (force, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Claims/ViewAll`,
        force
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getStaffClaims = createAsyncThunk(
  "getStaffClaims",
  async (props, { fulfillWithValue, rejectWithValue }) => {
    const { force, cinemaId } = {
      force: false,
      cinemaId: "",
      ...props,
    };
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Users/GetWithClaims?cinemaId=${cinemaId}`,
        force
      );

      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      console.log({ ...error });

      return rejectWithValue(error?.response?.data);
    }
  }
);

const initialState = {
  claims: [],
  claimsStatus: FETCH_STATUS.IDLE,
  claimsErr: null,

  userClaims: [],
  userClaimsStatus: FETCH_STATUS.IDLE,
  userClaimsErr: null,
};

export const claimsSlice = createSlice({
  name: "claims",
  initialState,
  reducers: {
    updateUserClaims: (state, action) => {
      editItemDirect(state, action, "userClaims");
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getClaims.pending, (state) => {
        state.claimsStatus = FETCH_STATUS.PENDING;
        state.claimsErr = "";
      })
      .addCase(getClaims.fulfilled, (state, action) => {
        state.claimsStatus = FETCH_STATUS.RESOLVED;
        state.claims = action.payload;
        state.claimsErr = "";
      })
      .addCase(getClaims.rejected, (state, action) => {
        state.claimsStatus = FETCH_STATUS.REJECTED;
        state.claimsErr = action.payload || "Unable to get claims list";
      })

      .addCase(getStaffClaims.pending, (state) => {
        state.userClaimsStatus = FETCH_STATUS.PENDING;
        state.userClaimsErr = "";
      })
      .addCase(getStaffClaims.fulfilled, (state, action) => {
        state.userClaimsStatus = FETCH_STATUS.RESOLVED;
        state.userClaims = action.payload;
        state.userClaimsErr = "";
      })
      .addCase(getStaffClaims.rejected, (state, action) => {
        state.userClaimsStatus = FETCH_STATUS.REJECTED;
        state.userClaimsErr = action.payload || "Unable to get staff claims";
      });
  },
});

export const selectClaims = (state) => state.claims;
export const { updateUserClaims } = claimsSlice.actions;
