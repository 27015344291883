import React, { useMemo } from "react";
import { SEATSTATUS } from "../../utils";

const SeatIcon = ({ className, status, isSelected, seatName, onClick }) => {
  // console.log({isSelected})
  const borderColor = useMemo(() => {
    switch (status) {
      case SEATSTATUS.UNAVAILABLE:
      case SEATSTATUS.DAMAGED:
        return "#666771";
      case SEATSTATUS.AISLE:
        return "none";
      default:
        return "white";
    }
  }, [status]);

  const fillColor = useMemo(() => {
    switch (status) {
      case SEATSTATUS.SELECTED:
        return "#1A9D27";
      case SEATSTATUS.RESERVED:
        return "#D93030";
      case SEATSTATUS.AISLE:
        return "transparent";
      default:
        return "none";
    }
  }, [status]);
  return (
    <button
      //   disabled={
      //     [SEATSTATUS.RESERVED, SEATSTATUS.UNAVAILABLE, SEATSTATUS.AISLE].indexOf(
      //       status
      //     ) !== -1
      //   }
      title={seatName}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="37"
        height="38"
        viewBox="0 0 37 38"
        className={className}
        fill={isSelected ? "#1A9D27" : "none"}
      >
        <path
          d="M9.43633 34.75H27.5637C29.3048 34.75 30.178 34.75 30.8973 34.575C33.3559 33.9765 35.2738 31.929 35.8355 29.3075C36 28.5375 36 27.61 36 25.7498V17.6805C35.9982 15.5105 34.3481 13.75 32.3147 13.75C30.2795 13.75 28.6311 15.5088 28.6311 17.6805V26.5828H8.36713V17.6805C8.36713 15.5105 6.72046 13.75 4.68532 13.75C2.65017 13.75 1 15.5088 1 17.6805V25.7498C1 27.61 1 28.5393 1.16449 29.3075C1.72446 31.929 3.64412 33.9765 6.10274 34.575C6.82196 34.75 7.69517 34.75 9.43633 34.75Z"
          fill={fillColor}
          stroke={borderColor}
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          d="M8 25.75V20.1543C8 13.1824 8 9.69347 8.8435 7.09569C9.39155 5.40369 10.187 3.99132 11.15 3.00047C12.6113 1.5 14.5747 1.5 18.5 1.5C22.4253 1.5 24.3888 1.5 25.85 3.00047C26.8073 3.98259 27.6035 5.39516 28.1565 7.09569C29 9.69347 29 13.1794 29 20.1543V25.75"
          fill={fillColor}
        />
        <path
          d="M8 25.75V20.1543C8 13.1824 8 9.69347 8.8435 7.09569C9.39155 5.40369 10.187 3.99132 11.15 3.00047C12.6113 1.5 14.5747 1.5 18.5 1.5C22.4253 1.5 24.3888 1.5 25.85 3.00047C26.8073 3.98259 27.6035 5.39516 28.1565 7.09569C29 9.69347 29 13.1794 29 20.1543V25.75"
          stroke={borderColor}
          strokeWidth="1.5"
        />
        <path
          d="M31.1862 36.5V34.75V36.5ZM4.9375 36.5V34.75V36.5Z"
          fill={fillColor}
        />
        <path
          d="M31.1862 36.5V34.75M4.9375 36.5V34.75"
          stroke={borderColor}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </button>
  );
};

export default SeatIcon;
