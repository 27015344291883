import React, { Fragment, useEffect } from "react";
import DatePicker from "react-datepicker";
import { Calendar } from "../Icons";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  duplicateAllSessionsInit,
  duplicateAllSessionsSchema,
  FETCH_STATUS,
} from "../../utils";
import { Spinner } from "../UI";
import { getShowtimesPerDay, selectShowTimes } from "../../redux/slices";
import { useDispatch } from "react-redux";
import { startOfDay, endOfDay } from "date-fns";
import { useSelector } from "react-redux";

const DuplicateAllForm = ({
  dateFrom,
  dateTo,
  setDateTo,
  setDateFrom,
  ticketTypes,
  setTicketType,
  ticketType,
  priceCardError,
  duplicateAllStatus,
  handleDuplicate,
  getShowtimes,
  setEvents,
  duplicatingAll,
}) => {
  const dispatch = useDispatch();
  const {
    register,
    control,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(duplicateAllSessionsSchema),
    defaultValues: duplicateAllSessionsInit,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "duplicateToList",
  });

  const { showTimesPerDayStatus } = useSelector(selectShowTimes);

  const submitFunction = (data) => {
   
    handleDuplicate(data);
  };

  const duplicateFromDate = watch("duplicateFromDate");

  useEffect(() => {
    const start = startOfDay(new Date(duplicateFromDate)).toISOString();
    const end = endOfDay(new Date(duplicateFromDate)).toISOString();
    dispatch(getShowtimesPerDay({ start, end }))
      .unwrap()
      .then((data) => {
        setEvents(data);
      });
  }, [duplicateFromDate, dispatch]);

  const isSubmitting =
    duplicatingAll &&
    (duplicateAllStatus === FETCH_STATUS.PENDING ||
      showTimesPerDayStatus === FETCH_STATUS.PENDING);

  return (
    <>
      <form onSubmit={handleSubmit(submitFunction)}>
        <h1>Select Day to Duplicate From</h1>
        <div className="relative block text-sm text-gray-900 bg-white my-3 w-[12rem]">
          <span
            role="presentation"
            className="absolute left-0 scale-75 translate-x-1 top-2/4 -translate-y-2/4"
          >
            <Calendar />
          </span>

          <span
            role="presentation"
            className="absolute right-0 -translate-x-2 top-2/4 -translate-y-2/4 "
          >
            <svg
              width="9"
              height="5"
              viewBox="0 0 9 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.232562 0.256018C0.55332 -0.0721195 0.999622 -0.0978287 1.39159 0.256018L4.26534 3.01112L7.13902 0.256018C7.53107 -0.0978287 7.97818 -0.0721195 8.29626 0.256018C8.44596 0.417516 8.52913 0.629597 8.52913 0.849804C8.52913 1.07001 8.44596 1.28209 8.29626 1.44359C7.99756 1.75105 4.84392 4.75392 4.84392 4.75392C4.76889 4.83183 4.67891 4.8938 4.57937 4.93613C4.47983 4.97846 4.37278 5.00028 4.26461 5.00028C4.15645 5.00028 4.04939 4.97846 3.94985 4.93613C3.85032 4.8938 3.76034 4.83183 3.6853 4.75392C3.6853 4.75392 0.533125 1.75105 0.233049 1.44359C0.0832454 1.28215 0 1.07004 0 0.849804C0 0.629564 0.0832454 0.417463 0.233049 0.256018H0.232562Z"
                fill="#222222"
              />
            </svg>
          </span>
          <Controller
            control={control}
            name="duplicateFromDate"
            render={({ field: { onChange, value } }) => (
              <DatePicker
                dateFormat="MMMM d, yyyy"
                placeholderText="Select Date"
                selected={new Date(value)}
                onChange={(value) => {
                  onChange(new Date(value).toISOString());
                }}
                className="block w-full text-center text-sm bg-transparent px-2 mr-1 py-[10px] rounded-lg border-gray-300"
              />
            )}
          />
        </div>
        <h1>{"Select Day(s) to Duplicate to"}</h1>
        {fields?.map((field, index) => {
          return (
            <Fragment key={field.id}>
              <div className="flex items-center my-3 " key={field.id}>
                <div className="relative block text-sm text-gray-900 bg-white w-[12rem] mr-4 ">
                  <span
                    role="presentation"
                    className="absolute left-0 scale-75 translate-x-1 top-2/4 -translate-y-2/4"
                  >
                    <Calendar />
                  </span>

                  <span
                    role="presentation"
                    className="absolute right-0 -translate-x-2 top-2/4 -translate-y-2/4 "
                  >
                    <svg
                      width="9"
                      height="5"
                      viewBox="0 0 9 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.232562 0.256018C0.55332 -0.0721195 0.999622 -0.0978287 1.39159 0.256018L4.26534 3.01112L7.13902 0.256018C7.53107 -0.0978287 7.97818 -0.0721195 8.29626 0.256018C8.44596 0.417516 8.52913 0.629597 8.52913 0.849804C8.52913 1.07001 8.44596 1.28209 8.29626 1.44359C7.99756 1.75105 4.84392 4.75392 4.84392 4.75392C4.76889 4.83183 4.67891 4.8938 4.57937 4.93613C4.47983 4.97846 4.37278 5.00028 4.26461 5.00028C4.15645 5.00028 4.04939 4.97846 3.94985 4.93613C3.85032 4.8938 3.76034 4.83183 3.6853 4.75392C3.6853 4.75392 0.533125 1.75105 0.233049 1.44359C0.0832454 1.28215 0 1.07004 0 0.849804C0 0.629564 0.0832454 0.417463 0.233049 0.256018H0.232562Z"
                        fill="#222222"
                      />
                    </svg>
                  </span>
                  <Controller
                    control={control}
                    name={`duplicateToList.${index}.duplicateToDate`}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        dateFormat="MMMM d, yyyy"
                        placeholderText="Select Date"
                        selected={new Date(value)}
                        onChange={(value) => {
                          onChange(new Date(value).toISOString());
                        }}
                        className="block w-full text-center text-sm bg-transparent px-2 mr-1 py-[10px] rounded-lg border-gray-300"
                      />
                    )}
                  />
                </div>
                <select
                  // onChange={handleTicketTypeChange}
                  {...register(`duplicateToList.${index}.selectedPriceCard`)}
                  className="text-sm p-2 text-gray-900 bg-white border-gray-300 focus:outline-none rounded-lg mr-4  "
                >
                  <option value="">Select...</option>
                  {ticketTypes?.map((type) => (
                    <option value={type.id} key={type.id}>
                      {type.name}
                    </option>
                  ))}
                </select>
                <button
                  className="px-3 py-1  rounded-md text-white bg-red-500 shadow-md hover:shadow-sm text-sm "
                  type="button"
                  onClick={() => {
                    remove(index);
                  }}
                >
                  Remove
                </button>
              </div>

              {errors?.duplicateToList?.[index]?.duplicateToDate?.message && (
                <p className={` text-xs italic text-red-500 py-1 `}>
                  {errors?.duplicateToList?.[index]?.duplicateToDate?.message}
                </p>
              )}
              {errors?.duplicateToList?.[index]?.selectedPriceCard?.message && (
                <p className={` text-xs italic text-red-500 py-1 `}>
                  {errors?.duplicateToList?.[index]?.selectedPriceCard?.message}
                </p>
              )}
            </Fragment>
          );
        })}
        <div className=" pb-3.5 col-span-2 flex justify-center my-2 ">
          <button
            className="p-1 px-3 rounded-md text-white bg-app-purple-6 shadow-md hover:shadow-sm"
            type="button"
            onClick={() => {
              append([
                {
                  duplicateToDate: new Date().toISOString(),
                  selectedPriceCard: "",
                },
              ]);
            }}
          >
            Add Day
          </button>
        </div>

        {errors?.duplicateToList?.message && (
          <p className={` text-xs italic text-red-500 text-center py-1`}>
            {errors?.duplicateToList?.message}
          </p>
        )}
        <div className="flex items-center justify-end mt-4">
          <button
            type={"submit"}
            disabled={isSubmitting}
            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md bg-app-purple-6 "
          >
            Duplicate{" "}
            <Spinner
              className="!w-3 !h-3 mx-1 my-1 text-white fill-red-900 items-center"
              status={isSubmitting ? FETCH_STATUS.PENDING : ""}
            />
          </button>
        </div>
      </form>
    </>
  );
};

export { DuplicateAllForm };
