import { useLoggedInuser } from "../../hooks";
import ReachLogo from "../../assets/images/logo.png";
import {dateAndTime, formatDateTime, formatNumberAsCurrency,} from "../../utils";
import {Th} from "./styles";
import {useSelector} from "react-redux";
import {reportData} from "../../redux/slices";

const SessionReportTable = ({ id1, id2, id3, sessionReport }) => {
  // const { sessionReport } = useSelector(reportData);
  const { profile } = useLoggedInuser();
  
  return (
    <div className="px-3">
      <div className="flex items-center justify-between">
        <div
          key={ReachLogo}
          className=" w-[5rem] h-[5rem]  bg-no-repeat bg-contain bg-center "
          style={{
            backgroundImage: `url("${ReachLogo}")`,
          }}
        ></div>
        <p className="font-bold text-[20px]">Cash Reconciliation Report</p>
        <p>{profile.cinema}</p>
      </div>
      <div className=" flex p-2 justify-between">
        <div>
          <p>
            {" "}
            <span className="font-bold">User Name:</span>
            {` ${profile.firstName} ${profile.lastName}`}
          </p>
          <p>
            <span className="font-bold">Date Time Generated: </span>
            {formatDateTime(sessionReport?.dateTimeGenerated, dateAndTime)}
          </p>
        </div>

        <div>
          <p className="font-bold">Report Date</p>
          <p>
            {(sessionReport?.session?.dateCreated &&
              new Date(sessionReport?.session?.dateCreated).toDateString()) ||
              "N/A"}
          </p>{" "}
        </div>
      </div>
      <div className="flex justify-between bg-[#A489A5] my-[20px] py-3 px-2 font-bold">
        <p>
          {sessionReport?.session?.user?.firstName ||
          sessionReport?.session?.user?.lastName
            ? `Username: ${
                sessionReport?.session?.user?.firstName +
                sessionReport?.session?.user?.lastName
              }`
            : "Username: N/A"}
        </p>
        <p>
          {(sessionReport?.session?.dateCreated &&
            `Date: ${new Date(
              sessionReport?.session?.dateCreated
            ).toDateString()}`) ||
            "Date: N/A"}
        </p>
        <p>
          {(sessionReport?.session?.dateCreated &&
            `Time: ${new Date(
              sessionReport?.session?.dateCreated
            ).toLocaleTimeString()}`) ||
            "Time: N/A"}
        </p>
      </div>
      <p className="font-bold">Sales</p>
      <table
        className="table-auto w-full mx-auto mt-4 overflow-hidden text-left border rounded-md border-app-purple-4"
        id={id1}
      >
        <thead className="shadow-2xl bg-app-purple-8">
          <tr className="text-center">
            {" "}
            <Th rowSpan={2}> </Th>
            <Th colSpan={3}>Sales</Th> <Th colSpan={2}> Refunds</Th>
            <Th colSpan={2}> Gross</Th>
            <Th colSpan={2}> Net</Th>
          </tr>
          <tr>
            <Th>Price</Th>
            <Th>Qty</Th> <Th>Value</Th> <Th>Qty</Th>
            <Th>Value</Th>
            <Th>Qty</Th>
            <Th>Value</Th>
            <Th>Value</Th>
          </tr>
        </thead>
        <tbody>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30">
            <td colSpan={8} className="font-bold py-4 px-2">
              {" "}
              Ticket Sales By Price Cards
            </td>
          </tr>
          {sessionReport?.priceCardProperty?.map((priceCard) => {
            return (
              <>
                <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30">
                  <td colSpan={8} className=" py-4 pl-6 font-bold">
                    {" "}
                    {priceCard?.priceCard}
                  </td>
                </tr>
                {priceCard.tickets?.map((ticket) => {
                  return (
                    <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30 ">
                      <td className="pl-6 ">{ticket.ticket}</td>
                      <td className=" border-l border-app-purple-4 border-opacity-100 text-right">
                        {" "}
                        {formatNumberAsCurrency(ticket?.price)}
                      </td>
                      <td className="text-center"> {ticket?.quantity}</td>
                      <td className=" border-r border-app-purple-4 border-opacity-100">
                        {" "}
                        {id1
                          ? ticket?.amount
                          : formatNumberAsCurrency(ticket?.amount)}
                      </td>
                      <td className="text-center"> {ticket?.refundQuantity}</td>
                      <td>
                        {" "}
                        {id1
                          ? ticket?.refundAmount
                          : formatNumberAsCurrency(ticket?.refundAmount)}
                      </td>
                      <td className="text-center border-l border-app-purple-4 border-opacity-100">
                        {" "}
                        {ticket?.grossQuantity}
                      </td>
                      <td>
                        {" "}
                        {id1
                          ? ticket?.grossAmount
                          : formatNumberAsCurrency(ticket?.grossAmount)}
                      </td>
                      <td>
                        {" "}
                        {id1
                          ? ticket?.netAmount
                          : formatNumberAsCurrency(
                              ticket?.netAmount?.toFixed(2)
                            )}
                      </td>
                    </tr>
                  );
                })}
              </>
            );
          })}
          <tr className="transition duration-300 border bg-[#A489A5] border-app-purple-4 border-opacity-30">
            <td colSpan={2} className="font-bold py-4 px-2">
              {" "}
              Ticket Sales Total
            </td>
            <td className="text-center">
              {" "}
              {sessionReport?.totalPriceCards?.quantity}
            </td>
            <td className=" border-r border-app-purple-4 border-opacity-100">
              {" "}
              {id1
                ? sessionReport?.totalPriceCards?.amount
                : formatNumberAsCurrency(
                    sessionReport?.totalPriceCards?.amount
                  )}
            </td>
            <td className="text-center">
              {" "}
              {sessionReport?.totalPriceCards?.refundQuantity}
            </td>
            <td>
              {" "}
              {id1
                ? sessionReport?.totalPriceCards?.refundAmount
                : formatNumberAsCurrency(
                    sessionReport?.totalPriceCards?.refundAmount
                  )}
            </td>
            <td className="text-center border-l border-app-purple-4 border-opacity-100">
              {" "}
              {sessionReport?.totalPriceCards?.grossQuantity}
            </td>
            <td>
              {" "}
              {id1
                ? sessionReport?.totalPriceCards?.grossAmount
                : formatNumberAsCurrency(
                    sessionReport?.totalPriceCards?.grossAmount
                  )}
            </td>
            <td>
              {" "}
              {id1
                ? sessionReport?.totalPriceCards?.netAmount
                : formatNumberAsCurrency(
                    sessionReport?.totalPriceCards?.netAmount?.toFixed(2)
                  )}
            </td>
          </tr>

          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30">
            <td colSpan={8} className="font-bold py-4 px-2">
              {" "}
              Concession Sales
            </td>
          </tr>

          {sessionReport?.itemClass?.map((itemClass) => {
            return (
              <>
                <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30">
                  <td colSpan={8} className=" py-4 pl-6 font-bold">
                    {" "}
                    {itemClass?.itemClass}
                  </td>
                </tr>
                {itemClass.items?.map((item) => {
                  return (
                    <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30 ">
                      <td className="pl-6 ">{item.name}</td>
                      <td className=" border-l border-app-purple-4 border-opacity-100 text-right">
                        {" "}
                        {id1
                          ? item?.price
                          : formatNumberAsCurrency(item?.price)}
                      </td>
                      <td className="text-center"> {item?.quantity}</td>
                      <td className=" border-r border-app-purple-4 border-opacity-100">
                        {" "}
                        {id1
                          ? item?.amount
                          : formatNumberAsCurrency(item?.amount)}
                      </td>
                      <td className="text-center"> {item?.refundQuantity}</td>
                      <td>
                        {" "}
                        {id1
                          ? item?.refundAmount
                          : formatNumberAsCurrency(item?.refundAmount)}
                      </td>
                      <td className="text-center border-l border-app-purple-4 border-opacity-100">
                        {" "}
                        {item?.grossQuantity}
                      </td>
                      <td>
                        {" "}
                        {id1
                          ? item?.grossAmount
                          : formatNumberAsCurrency(item?.grossAmount)}
                      </td>
                      <td>
                        {" "}
                        {id1
                          ? item?.netAmount
                          : formatNumberAsCurrency(item?.netAmount?.toFixed(2))}
                      </td>
                    </tr>
                  );
                })}
                <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30">
                  <td className="font-bold pl-6">{`${itemClass?.itemClass} Total`}</td>
                  {/* <td className="pl-6 ">{item.name}</td> */}
                  <td className=" border-l border-app-purple-4 border-opacity-100 text-right"></td>
                  <td className="text-center"> {itemClass?.total?.quantity}</td>
                  <td className=" border-r border-app-purple-4 border-opacity-100">
                    {" "}
                    {formatNumberAsCurrency(itemClass?.total?.amount)}
                  </td>
                  <td className="text-center">
                    {" "}
                    {itemClass?.total?.refundQuantity}
                  </td>
                  <td>
                    {" "}
                    {id1
                      ? itemClass?.total?.refundAmount
                      : formatNumberAsCurrency(itemClass?.total?.refundAmount)}
                  </td>
                  <td className="text-center border-l border-app-purple-4 border-opacity-100">
                    {" "}
                    {itemClass?.total?.grossQuantity}
                  </td>
                  <td>
                    {" "}
                    {id1
                      ? itemClass?.total?.grossAmount
                      : formatNumberAsCurrency(itemClass?.total?.grossAmount)}
                  </td>
                  <td>
                    {" "}
                    {id1
                      ? itemClass?.total?.netAmount
                      : formatNumberAsCurrency(
                          itemClass?.total?.netAmount?.toFixed(2)
                        )}
                  </td>
                </tr>
              </>
            );
          })}
          {sessionReport?.itemClass &&
            Object.keys(sessionReport?.itemClass).length < 1 && (
              <tr>
                <td colSpan={8} className="text-center">
                  {" "}
                  No data Found for Concession Sales
                </td>
              </tr>
            )}

          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30">
            <td colSpan={8} className="font-bold py-4 px-2">
              {" "}
              Item Packages
            </td>
          </tr>
          {sessionReport?.itemPackges?.items?.map((item) => {
            return (
              <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30 ">
                <td className="pl-6 ">{item?.name}</td>
                <td className=" border-l border-app-purple-4 border-opacity-100 text-right">
                  {" "}
                  {id1 ? item?.price : formatNumberAsCurrency(item?.price)}
                </td>
                <td className="text-center"> {item?.quantity}</td>
                <td className=" border-r border-app-purple-4 border-opacity-100">
                  {" "}
                  {id1 ? item?.amount : formatNumberAsCurrency(item?.amount)}
                </td>
                <td className="text-center"> {item?.refundQuantity}</td>
                <td>
                  {" "}
                  {id1
                    ? item?.refundAmount
                    : formatNumberAsCurrency(item?.refundAmount)}
                </td>
                <td className="text-center border-l border-app-purple-4 border-opacity-100">
                  {" "}
                  {item?.grossQuantity}
                </td>
                <td>
                  {" "}
                  {id1
                    ? item?.grossAmount
                    : formatNumberAsCurrency(item?.grossAmount)}
                </td>
                <td>
                  {" "}
                  {id1
                    ? item?.netAmount
                    : formatNumberAsCurrency(item?.netAmount?.toFixed(2))}
                </td>
              </tr>
            );
          })}
          {sessionReport?.itemPackges?.items?.length < 1 && (
            <tr>
              <td colSpan={8} className="text-center">
                {" "}
                No data Found for Item package sales
              </td>
            </tr>
          )}

          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30">
            <td colSpan={8} className="font-bold py-4 px-2">
              {" "}
              Ticket Package Items
            </td>
          </tr>
          {sessionReport?.ticketPackges?.items?.map((item) => {
            return (
              <>
                <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30">
                  <td colSpan={8} className=" py-4 pl-6 font-bold">
                    {" "}
                    {item?.packageTicket}
                  </td>
                </tr>
                {item?.items?.map((item) => {
                  return (
                    <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30 ">
                      <td className="pl-6 ">{item?.name}</td>
                      <td className=" border-l border-app-purple-4 border-opacity-100 text-right">
                        {" "}
                        {id1
                          ? item?.price
                          : formatNumberAsCurrency(item?.price)}
                      </td>
                      <td className="text-center"> {item?.quantity}</td>
                      <td className=" border-r border-app-purple-4 border-opacity-100">
                        {" "}
                        {id1
                          ? item?.amount
                          : formatNumberAsCurrency(item?.amount)}
                      </td>
                      <td className="text-center"> {item?.refundQuantity}</td>
                      <td>
                        {" "}
                        {id1
                          ? item?.refundAmount
                          : formatNumberAsCurrency(item?.refundAmount)}
                      </td>
                      <td className="text-center border-l border-app-purple-4 border-opacity-100">
                        {" "}
                        {item?.grossQuantity}
                      </td>
                      <td>
                        {" "}
                        {id1
                          ? item?.grossAmount
                          : formatNumberAsCurrency(item?.grossAmount)}
                      </td>
                      <td>
                        {" "}
                        {id1
                          ? item?.netAmount
                          : formatNumberAsCurrency(item?.netAmount?.toFixed(2))}
                      </td>
                    </tr>
                  );
                })}
              </>
            );
          })}
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30">
            <td colSpan={2} className="font-bold py-4 px-2">
              {" "}
              Ticket Package Item Sales Total
            </td>
            <td className="text-center">
              {" "}
              {sessionReport?.ticketPackges?.total?.quantity}
            </td>
            <td className=" border-r border-app-purple-4 border-opacity-100">
              {" "}
              {id1
                ? sessionReport?.ticketPackges?.total?.amount
                : formatNumberAsCurrency(
                    sessionReport?.ticketPackges?.total?.amount
                  )}
            </td>
            <td className="text-center">
              {" "}
              {sessionReport?.ticketPackges?.total?.refundQuantity}
            </td>
            <td>
              {" "}
              {id1
                ? sessionReport?.ticketPackges?.total?.refundAmount
                : formatNumberAsCurrency(
                    sessionReport?.ticketPackges?.total?.refundAmount
                  )}
            </td>
            <td className="text-center border-l border-app-purple-4 border-opacity-100">
              {" "}
              {sessionReport?.ticketPackges?.total?.grossQuantity}
            </td>
            <td>
              {" "}
              {id1
                ? sessionReport?.ticketPackges?.total?.grossAmount
                : formatNumberAsCurrency(
                    sessionReport?.ticketPackges?.total?.grossAmount
                  )}
            </td>
            <td>
              {" "}
              {id1
                ? sessionReport?.ticketPackges?.total?.netAmount
                : formatNumberAsCurrency(
                    sessionReport?.ticketPackges?.total?.netAmount?.toFixed(2)
                  )}
            </td>
          </tr>
          {sessionReport?.ticketPackges?.items?.length < 1 && (
            <tr>
              <td colSpan={8} className="text-center">
                {" "}
                No data Found for Ticket package sales
              </td>
            </tr>
          )}
          <tr className="transition duration-300 border bg-[#A489A5] border-app-purple-4 border-opacity-30">
            <td colSpan={2} className="font-bold py-4 px-2">
              {" "}
              Concession Sales Total
            </td>
            <td className="text-center">
              {" "}
              {sessionReport?.totalConcessions?.quantity}
            </td>
            <td className=" border-r border-app-purple-4 border-opacity-100">
              {" "}
              {id1
                ? sessionReport?.totalConcessions?.amount
                : formatNumberAsCurrency(
                    sessionReport?.totalConcessions?.amount
                  )}
            </td>
            <td className="text-center">
              {" "}
              {sessionReport?.totalConcessions?.refundQuantity}
            </td>
            <td>
              {" "}
              {id1
                ? sessionReport?.totalConcessions?.refundAmount
                : formatNumberAsCurrency(
                    sessionReport?.totalConcessions?.refundAmount
                  )}
            </td>
            <td className="text-center border-l border-app-purple-4 border-opacity-100">
              {" "}
              {sessionReport?.totalConcessions?.grossQuantity}
            </td>
            <td>
              {" "}
              {id1
                ? sessionReport?.totalConcessions?.grossAmount
                : formatNumberAsCurrency(
                    sessionReport?.totalConcessions?.grossAmount
                  )}
            </td>
            <td>
              {" "}
              {id1
                ? sessionReport?.totalConcessions?.netAmount
                : formatNumberAsCurrency(
                    sessionReport?.totalConcessions?.netAmount?.toFixed(2)
                  )}
            </td>
          </tr>
          <tr>
            <td colSpan={3}></td>
          </tr>
          <tr>
            <td colSpan={3}></td>
          </tr>
          <tr>
            <td colSpan={3}></td>
          </tr>
          <tr className="transition duration-300 border bg-[#A489A5] border-app-purple-4 border-opacity-30">
            <td colSpan={2} className="font-bold py-4 px-2">
              Total Sales
            </td>
            <td className="text-center">
              {" "}
              {sessionReport?.totalSales?.quantity}
            </td>
            <td className=" border-r border-app-purple-4 border-opacity-100">
              {" "}
              {id1
                ? sessionReport?.totalSales?.amount
                : formatNumberAsCurrency(sessionReport?.totalSales?.amount)}
            </td>
            <td className="text-center">
              {" "}
              {sessionReport?.totalSales?.refundQuantity}
            </td>
            <td>
              {" "}
              {id1
                ? sessionReport?.totalSales?.refundAmount
                : formatNumberAsCurrency(
                    sessionReport?.totalSales?.refundAmount
                  )}
            </td>
            <td className="text-center border-l border-app-purple-4 border-opacity-100">
              {" "}
              {sessionReport?.totalSales?.grossQuantity}
            </td>
            <td>
              {" "}
              {id1
                ? sessionReport?.totalSales?.grossAmount
                : formatNumberAsCurrency(
                    sessionReport?.totalSales?.grossAmount
                  )}
            </td>
            <td>
              {" "}
              {id1
                ? sessionReport?.totalSales?.netAmount
                : formatNumberAsCurrency(
                    sessionReport?.totalSales?.netAmount?.toFixed(2)
                  )}
            </td>
          </tr>
        </tbody>
      </table>

      <p className="font-bold my-2">Admin Actions Occurences</p>
      <table
        className="table-auto w-full mx-auto mt-4 overflow-hidden text-left border rounded-md border-app-purple-4"
        id={id2}
      >
        <thead className="shadow-2xl bg-app-purple-8">
          <tr>
            <Th>Abort</Th>
            <Th>Delete</Th> <Th>Pos Sales Summary</Th>
          </tr>
        </thead>
        <tbody>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30 text-center ">
            <td className="text-center">
              {sessionReport?.adminActions?.abort}
            </td>
            <td className="text-center">
              {sessionReport?.adminActions?.delete}
            </td>
            <td className="text-center">
              {sessionReport?.adminActions?.todaySale}
            </td>
          </tr>
        </tbody>
      </table>

      <p className="font-bold my-2">Session Reconcilation</p>
      <table
        className="table-auto w-full mx-auto mt-4 overflow-hidden text-left border rounded-md border-app-purple-4"
        id={id3}
      >
        <thead className="shadow-2xl bg-app-purple-8">
          <tr>
            <Th>Payment Channel</Th>
            <Th>Expected Amount</Th> <Th>Amount Remitted</Th> <Th>Variance</Th>
          </tr>
        </thead>
        <tbody>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30 text-center ">
            <td>Cash</td>
            <td>
              {id3
                ? sessionReport?.session?.expectedCash
                : formatNumberAsCurrency(sessionReport?.session?.expectedCash)}
            </td>
            <td>
              {id3
                ? sessionReport?.session?.actualCash
                : formatNumberAsCurrency(sessionReport?.session?.actualCash)}
            </td>
            <td>
              {id3
                ? sessionReport?.session?.cashVariance
                : formatNumberAsCurrency(sessionReport?.session?.cashVariance)}
            </td>
          </tr>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30 text-center ">
            <td>Card</td>
            <td>
              {id3
                ? sessionReport?.session?.expectedCard
                : formatNumberAsCurrency(sessionReport?.session?.expectedCard)}
            </td>
            <td>
              {id3
                ? sessionReport?.session?.actualCard
                : formatNumberAsCurrency(sessionReport?.session?.actualCard)}
            </td>
            <td>
              {id3
                ? sessionReport?.session?.cardVariance
                : formatNumberAsCurrency(sessionReport?.session?.cardVariance)}
            </td>
          </tr>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30 text-center">
            <td>Transfer</td>
            <td>
              {id3
                ? sessionReport?.session?.expectedTransfer
                : formatNumberAsCurrency(
                    sessionReport?.session?.expectedTransfer
                  )}
            </td>
            <td>
              {id3
                ? sessionReport?.session?.actualTransfer
                : formatNumberAsCurrency(
                    sessionReport?.session?.actualTransfer
                  )}
            </td>
            <td>
              {id3
                ? sessionReport?.session?.transferVariance
                : formatNumberAsCurrency(
                    sessionReport?.session?.transferVariance
                  )}
            </td>
          </tr>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30 text-center">
            <td>External Voucher</td>
            <td>
              {id3
                ? sessionReport?.session?.expectedExternalVoucher
                : formatNumberAsCurrency(
                    sessionReport?.session?.expectedExternalVoucher
                  )}
            </td>
            <td>
              {id3
                ? sessionReport?.session?.actualExternalVoucher
                : formatNumberAsCurrency(
                    sessionReport?.session?.actualExternalVoucher
                  )}
            </td>
            <td>
              {id3
                ? sessionReport?.session?.externalVoucherVariance
                : formatNumberAsCurrency(
                    sessionReport?.session?.externalVoucherVariance
                  )}
            </td>
          </tr>
        </tbody>
      </table>
      {!sessionReport && <p className="text-center py-3"> No Data Found</p>}
    </div>
  );
};
export { SessionReportTable };
