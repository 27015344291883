import { useEffect, useState, useRef } from "react";
import { formatDateTime } from "../utils";

const useCountdown = (targetDate) => {
  const countDownDate = useRef(new Date(targetDate).getTime());

  const [countDown, setCountDown] = useState(
    countDownDate.current - new Date().getTime()
  );

  const [done, setDone] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const diff = countDownDate.current - new Date().getTime();
      setCountDown(diff);

      if (diff <= 1) {
        setDone(true);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return { time: formatDateTime(countDown, "mm:ss"), done };
};

export { useCountdown };
