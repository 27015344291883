import { useCallback, useMemo } from "react";

const usePosLayout = (update, posLayout, itemsList) => {
  const addPage = useCallback(
    (layoutIndex) => {
      update(layoutIndex, {
        serviceArea: posLayout[layoutIndex]?.serviceArea,
        pages: [
          ...posLayout[layoutIndex].pages,
          { pageName: "", pageItems: [{ itemId: "" }] },
        ],
      });
    },
    [posLayout, update]
  );

  const removePage = useCallback(
    ({ layoutIndex, pageIndex }) => {
      update(layoutIndex, {
        serviceArea: posLayout[layoutIndex]?.serviceArea,
        pages: posLayout[layoutIndex]?.pages?.filter((page, i) => {
          return i !== pageIndex;
        }),
      });
    },
    [posLayout, update]
  );

  const addItem = useCallback(
    ({ layoutIndex, pageIndex }) => {
      update(layoutIndex, {
        serviceArea: posLayout[layoutIndex]?.serviceArea,
        pages: posLayout[layoutIndex]?.pages?.map((page, i) => {
          if (i !== pageIndex) {
            return page;
          } else {
            return {
              pageName: page?.pageName,
              pageItems: [...page.pageItems, { itemId: "" }],
            };
          }
        }),
      });
    },
    [posLayout, update]
  );

  const removeItem = useCallback(
    ({ layoutIndex, pageIndex, itemIndex }) => {
      update(layoutIndex, {
        serviceArea: posLayout[layoutIndex]?.serviceArea,
        pages: posLayout[layoutIndex]?.pages?.map((page, i) => {
          if (i !== pageIndex) {
            return page;
          } else {
            return {
              pageName: page?.pageName,
              pageItems: page?.pageItems?.filter((item, i) => {
                return i !== itemIndex;
              }),
            };
          }
        }),
      });
    },
    [posLayout, update]
  );

  const itemsWithPosSource = useMemo(() => {
    return itemsList.filter(
      (item) =>
        item?.itemSources?.some((source) => source?.source === "POS") ||
        item?.itemPackageSources?.some((source) => source?.source === "POS")
    );
  }, [itemsList]);

  return {
    removePage,
    addItem,
    removeItem,
    addPage,
    itemsWithPosSource,
  };
};

export { usePosLayout };
