class Ticket {
  static toUI(info) {
    return {
      ...info,
      id: info.id,
      name: info.ticketName,
      type: info.shortName,
      class: info.ticketGroup,
      status: info.isActive,
      channels: info.ticketChannels
        ? info.ticketChannels.map((c) => c.channelId)
        : [],
      ticketChannel: info.ticketChannels
        ? info.ticketChannels.map((c) => c.channelId)
        : [],
    };
  }

  static toDTO(info) {
    return {
      ...info,
      ticketChannel: info.channels || info.ticketChannels || [],
      isActive: info.active || info.isActive,
      ticketGroupId: info.class || info.ticketGroupId,
      shortName: info.type || info.shortName,
      ticketName:
        info?.name || info.ticketName || `Ticket ${new Date().getTime()}`,
    };
  }
}

export { Ticket };
