import React, { useCallback, useMemo, useState } from "react";
import TaskSession from "../../../components/RosterScheduler/TaskSession";
import Shift from "../../../components/RosterScheduler/Shift";
import { RosterContextMenu } from "../../../components/RosterScheduler/RosterContextMenu";
import { Portal } from "@headlessui/react";
import CreateRosterModal from "../../../components/RosterScheduler/modal/CreateRosterModal";
import {
  ErrorWithWrapper,
  Loading,
  PrivateRoute,
  Roster,
  ScrollUp,
  SuccessToast,
} from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  useRequest,
  useRosterScheduling,
  useRosterStatuses,
} from "../../../hooks";
import {
  API_URL,
  FETCH_STATUS,
  axiosApiInstance,
  onReject,
} from "../../../utils";
import { selectRoster } from "../../../redux/slices";
import EditRosterModal from "../../../components/RosterScheduler/modal/EditRosterModal";
import DeleteRosterModal from "../../../components/RosterScheduler/modal/DeleteRosterModal";
import { useEffect } from "react";
import ViewRosterTask from "../../../components/RosterScheduler/modal/ViewRosterTask";
import SendRosterModal from "../../../components/RosterScheduler/modal/SendRosterModal";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { differenceInDays, subDays } from "date-fns";

const RosterSchedule = React.memo(() => {
  const [showCreate, setShowCreate] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showView, setShowView] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showSend, setShowSend] = useState(false);
  const toggleCreate = () => setShowCreate((c) => !c);
  const { state, getShifts, getTasks, getRosters, fetchRoster, getStaffs } =
    useRosterScheduling();
  const { activeRosterDate } = useSelector(selectRoster);
  const dispatch = useDispatch();
  const {
    err: sendErr,
    status: sendStatus,
    setPending: setSendPending,
    setResolved: setSendResolved,
    setRejected: setSendRejected,
    resetErr: sendResetErr,
  } = useRequest();
  const {
    resetErr,
    setPending,
    setResolved,
    setRejected,
    err,
    status,

    deleteResetErr,
    deleteSetPending,
    deleteSetResolved,
    deleteSetRejected,
    deleteErr,
    deleteStatus,

    editResetErr,
    editSetPending,
    editSetResolved,
    editSetRejected,
    editErr,
    editStatus,
  } = useRosterStatuses();
  const [draggedInfo, setDraggedInfo] = useState({
    oldSession: null,
    newSession: null,
  });

  const rosterRef = useRef(null);

  const getInfo = useCallback(
    (force, date) => {
      if (force || state.getShiftsStatus === FETCH_STATUS.REJECTED) {
        getShifts();
      }
      if (force || state.getTasksStatus === FETCH_STATUS.REJECTED) {
        getTasks();
      }
      if (force || state.rosterSessionStatus === FETCH_STATUS.REJECTED) {
        fetchRoster(force, date);
      }
      // if (force || state.rosterSessionStatus === FETCH_STATUS.REJECTED) {
      //   getAllRoster()
      // }
      if (force || state.staffStatus === FETCH_STATUS.REJECTED) {
        getStaffs();
      }
    },
    [
      fetchRoster,
      getShifts,
      getStaffs,
      getTasks,
      state.getShiftsStatus,
      state.getTasksStatus,
      state.rosterSessionStatus,
      state.staffStatus,
    ]
  );

  useEffect(() => {
    if (!showCreate || !showEdit || !showDelete || !showSend) {
      setShowSuccess(false);
    }
  }, [showCreate, showEdit, showDelete, showSend]);

  const showLoading =
    state.getShiftsStatus === FETCH_STATUS.PENDING ||
    state.getTasksStatus === FETCH_STATUS.PENDING ||
    state.rosterSessionStatus === FETCH_STATUS.PENDING ||
    state.staffStatus === FETCH_STATUS.PENDING;

  const showErr =
    state.getShiftsStatus === FETCH_STATUS.REJECTED ||
    state.getTasksStatus === FETCH_STATUS.REJECTED ||
    state.rosterSessionStatus === FETCH_STATUS.REJECTED ||
    state.staffStatus === FETCH_STATUS.REJECTED;

  const loaded =
    state.getShiftsStatus === FETCH_STATUS.RESOLVED &&
    state.getTasksStatus === FETCH_STATUS.RESOLVED &&
    state.rosterSessionStatus === FETCH_STATUS.RESOLVED &&
    state.staffStatus === FETCH_STATUS.RESOLVED;

  const shifts = useMemo(() => {
    return loaded
      ? Object.values(state.shifts)
          .filter((s) => s?.isActive)
          .reverse()
      : [];
  }, [loaded, state.shifts]);

  const events = useMemo(() => {
    return loaded ? state.rosterSession : [];
  }, [loaded, state.rosterSession]);

  const tasks = useMemo(() => {
    return loaded ? Object.values(state.tasks) : [];
  }, [loaded, state.tasks]);

  const staffs = useMemo(() => {
    return loaded ? state.staff : [];
  }, [loaded, state.staff]);

  const handleToastClose = () => {
    setShowCreate(false);
    setShowDelete(false);
    setShowEdit(false);
    setDraggedInfo({ oldSession: null, newSession: null });
    setShowView(false);
    setShowSend(false);
  };

  const onCreate = useCallback(
    async (values) => {
      try {
        setPending();

        const response = await axiosApiInstance.post(
          `${API_URL}/Rosters/Create`,
          values
        );
        setResolved(response?.data?.data);
        setShowSuccess(true);
        getInfo(true, activeRosterDate);
      } catch (error) {
        onReject(setRejected, error, `unable to create roster task`);
      }
    },
    [activeRosterDate, getInfo, setPending, setRejected, setResolved]
  );

  const onEdit = useCallback(
    async (values, id) => {
      try {
        editSetPending();
        const response = await axiosApiInstance.patch(
          `${API_URL}/Rosters/${id}/Update`,
          values
        );
        editSetResolved(response?.data?.data);
        setShowSuccess(true);
        // dispatch(getScheduledRoster())
        getInfo(true, activeRosterDate);
      } catch (error) {
        onReject(editSetRejected, error, `unable to edit roster task`);
      }
    },
    [
      activeRosterDate,
      editSetPending,
      editSetRejected,
      editSetResolved,
      getInfo,
    ]
  );

  const onDelete = useCallback(
    async (id) => {
      try {
        deleteSetPending();
        await axiosApiInstance.delete(`${API_URL}/Rosters/${id}`);
        deleteSetResolved();
        setShowSuccess(true);
        getInfo(true, activeRosterDate);
      } catch (error) {
        onReject(deleteSetRejected, error, `unable to delete roster task`);
      }
    },
    [
      activeRosterDate,
      deleteSetPending,
      deleteSetRejected,
      deleteSetResolved,
      getInfo,
    ]
  );
  const onSendRoster = useCallback(
    async (values) => {
      const dateDiff = differenceInDays(
        new Date(values.dateTo),
        new Date(values.dateFrom)
      );
  
      try {
        if (dateDiff > 7) {
          throw new Error("Difference in date range must be 7");
        }
        setSendPending();
        await axiosApiInstance.post(`${API_URL}/Rosters/SendRoster`, values);
        setSendResolved();
        setShowSuccess(true);
      } catch (error) {
        setSendRejected(error?.message || error, `unable to send roster task`);
        
      }
    },
    [setSendPending, setSendRejected, setSendResolved]
  );
  
  const handleDelete = useCallback((event) => {
    const data = {
      newSession: { ...event },
      oldSession: { ...event },
    };

    setDraggedInfo(data);
    setShowDelete(true);
  }, []);

  const handleView = useCallback(
    (event) => {
      const data = {
        newSession: {
          ...event,
          shift: state.shifts[event.shiftId]?.shiftName || "N/A",
        },
      };

      setDraggedInfo(data);
      setShowView(true);
    },
    [setShowView, state.shifts]
  );

  const handleUpdate = useCallback(
    (event) => {
      const data = {
        newSession: { ...event },
        oldSession: { ...event },
      };

      setDraggedInfo(data);
      setShowEdit(true);
    },
    [setShowEdit]
  );

  const handlePrint = useReactToPrint({
    pageStyle: `@media print {
      @page {
        size: 297mm 250mm;
        margin: 0;
      }
    }`,
    content: () => rosterRef.current,
    documentTitle: "roster-Schedule",
  });

  return (
    
      <PrivateRoute redirectTo="/dashboard/users/roster">
        <div className="flex justify-start">
          <div className="flex justify-between">
            <button
              className="relative block px-4 py-2 my-2 rounded-md text-app-purple-4 bg-app-yellow"
              type="button"
              onClick={() => getInfo(true, activeRosterDate)}
            >
              Refresh
            </button>
            <button
              className="relative block px-4 py-2 my-2 rounded-md text-app-yellow bg-app-purple-4 ml-4"
              type="button"
              onClick={() => setShowSend(true)}
            >
              Send Roster
            </button>
            <button
              className="relative block px-4 py-2 my-2 rounded-md text-app-yellow bg-app-purple-4 ml-4"
              type="button"
              onClick={handlePrint}
            >
              Download Roster
            </button>
          </div>
        </div>
        <section className="relative" ref={rosterRef}>
          <Roster
            renderContextMenu={({ bindMenu, bindMenuItem, data, hideMenu }) => (
              <RosterContextMenu
                handleDelete={handleDelete}
                handleUpdate={handleUpdate}
                handleView={handleView}
                bindMenuItem={bindMenuItem}
                event={data}
                hideMenu={hideMenu}
                bindMenu={bindMenu}
              />
            )}
            renderEvent={(event) => (
              // <></>
              <TaskSession event={event} list={state.tasks} />
            )}
            renderResource={(itemId) => (
              // <></>
              <Shift resourceId={itemId} list={state.shifts} />
            )}
            events={events}
            eventGroupByKey="shiftId"
            resources={shifts}
            resourceIdentifier="id"
            getRosterInfo={(date) => getInfo(true, date)}
            toggleCreate={toggleCreate}
            date={activeRosterDate}
          />

          <Loading show={showLoading} wrapperClassName="!z-[1000]" />
          <ErrorWithWrapper
            error={"Unable to load one (or more) of tasks/shifts/roster"}
            wrapperClass="!z-[10000]"
            show={showErr}
            retry={getInfo}
          />

          <Portal>
            <ScrollUp className="fixed z-30 bottom-3 right-4" />
          </Portal>

          {showCreate && (
            <CreateRosterModal
              close={() => setShowCreate(false)}
              show={showCreate}
              tasks={tasks}
              shifts={shifts}
              staffs={staffs}
              resetErrs={resetErr}
              createStatus={status}
              createErr={err}
              onCreate={onCreate}
              cinemaId={state.cinemaId}
              resetErr={resetErr}
            />
          )}
          {showEdit && (
            <EditRosterModal
              close={() => setShowEdit(false)}
              show={showEdit}
              tasks={tasks}
              shifts={shifts}
              staffs={staffs}
              oldSession={draggedInfo.oldSession}
              newSession={draggedInfo.newSession}
              // resetErrs={resetErr}
              editStatus={editStatus}
              editErr={editErr}
              onEdit={onEdit}
              cinemaId={state.cinemaId}
              resetErr={editResetErr}
            />
          )}

          <DeleteRosterModal
            show={showDelete}
            tasks={tasks}
            shifts={shifts}
            onDelete={onDelete}
            event={draggedInfo.newSession}
            onClose={() => setShowDelete(false)}
            resetDelErrs={deleteResetErr}
            deleteErr={deleteErr}
            deleteStatus={deleteStatus}
          />

          <ViewRosterTask
            event={draggedInfo.newSession}
            show={showView}
            onClose={() => setShowView(false)}
          />

          <SendRosterModal
            show={showSend}
            onClose={() => setShowSend(false)}
            staffs={staffs}
            sendErr={sendErr}
            onSend={onSendRoster}
            sendStatus={sendStatus}
            resetSendErr={sendResetErr}
          />

          <SuccessToast
            show={showSuccess && showEdit}
            handleClose={handleToastClose}
            message={"Roster task edited successfully"}
          />

          <SuccessToast
            show={showSuccess && showDelete}
            handleClose={handleToastClose}
            message={"Roster task deleted successfully"}
          />

          <SuccessToast
            show={showSuccess && showCreate}
            handleClose={handleToastClose}
            message={"Roster created successfully"}
          />

          <SuccessToast
            show={showSuccess && showSend}
            handleClose={handleToastClose}
            message={"Roster schedule sent successfully"}
          />
        </section>
      </PrivateRoute>
    
  );
});

export { RosterSchedule };
