import React from "react";
import BreadCrumb from "../../../components/BreadCrumbs/BreadCrumbs";
import { ErrorModal, PrivateRoute, ShiftForm, SuccessModal } from "../../../components";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { useRequest } from "../../../hooks";
import { useDispatch } from "react-redux";
import { API_URL, FETCH_STATUS, axiosApiInstance, getAPIErrMessage } from "../../../utils";
import { addShift } from "../../../redux/slices";

const CreateShift = () => {
  const navigate = useNavigate();
  const { err, data, status, resetErr, setResolved, setPending, setRejected } =
    useRequest();

  const dispatch = useDispatch();

 

  const onSave = async (data) => {
    try {
      setPending();

      const res = await axiosApiInstance.post(`${API_URL}/Shifts/Create`, data)
      setResolved(res?.data?.data);
      
      // dispatch(addShift(res?.data?.data));
    } catch (error) {
      setRejected(error || "Unable to create shift");
    }
  };
  return (
    <>
      <PrivateRoute redirectTo="/dashboard/users/create-shift">
        <div className="flex items-center flex-col md:flex-row gap-5 justify-between mb-[25px]">
          <h3 className="text-xl font-[550]">Shift</h3>
          <BreadCrumb />
        </div>
        <div className="bg-[#E6C4E7] rounded-[20px] border overflow-hidden border-[#AA7DAA]">
          <div className="px-5 py-5 flex justify-between items-center gap-4 bg-[#7E208080]">
            <h4 className="text-white text-lg font-sans">Create New Shift</h4>
            <button
              type="button"
              onClick={() => navigate("/dashboard/users/shifts")}
            >
              <Icon
                icon="ion:close-circle-sharp"
                fontSize={26}
                color="#E5E5E5"
              />
            </button>
          </div>
          <div className="p-[19px] pb-[30px] bg-[#DDC4DD] min-h-[699px]">
            <div className="px-6 md:px-0 mx-auto md:w-[590px] lg:w-[700px] xl:w-[900px]">
              <ShiftForm
                onReset={resetErr}
                status={status}
                error={err}
                onSave={onSave}
              />
            </div>
          </div>
        </div>
         {/* <ErrorModal
        show={status === FETCH_STATUS.REJECTED}
        message={getAPIErrMessage(err, "Unable to create shift")}
        onClose={resetErr}
        onClick={resetErr}
      /> */}
        <SuccessModal
          message="Shift created successfully"
          show={status === FETCH_STATUS.RESOLVED}
          backLink={`/dashboard/users/shifts`}
        />
      </PrivateRoute>
    </>
  );
};

export { CreateShift };
