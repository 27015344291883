import React, { useMemo } from "react";
import groupBy from "lodash/groupBy";
import isEmpty from "lodash/isEmpty";

import useContextMenu from "react-use-context-menu";

import endOfDay from "date-fns/endOfDay";
import startOfDay from "date-fns/startOfDay";
import isWithinInterval from "date-fns/isWithinInterval";

import DayRow from "./DayRow";

import { dayMarkers, loggedInCinemaName } from "../../../utils";

//filterTodayEvents is used to filter movies that would be shown for a particular day(activeDate)
const filterTodayEvents = (events = [], activeDate = new Date()) => {
  
  return events.filter((ev) => {
    const startValid =  isWithinInterval(new Date(ev.startTime), {
      start: startOfDay(new Date(activeDate)),
      end: endOfDay(new Date(activeDate)),
    });
   

    const endValid = isWithinInterval(new Date(ev.endTime), {
      start: startOfDay(new Date(activeDate)),
      end: endOfDay(new Date(activeDate)),
    });

    //to allow midnight movies to render partially

    return startValid || endValid;
  });
};

const DayView = ({
  resources = [],
  renderEvent,
  renderResource,
  activeDate,
  resourceIdentifier,
  eventGroupByKey,
  events = [],
  dragItemType,
  onDrop,
  renderContextMenu,
}) => {
  const [
    bindMenu,
    bindMenuItem,
    useContextTrigger,
    { data, coords, setVisible },
  ] = useContextMenu();
  const hideMenu = () => setVisible(false);

 

  const rowProps = useMemo(
    () => ({
      renderEvent,
      renderResource,
      resourceIdentifier,
      dragItemType,
      onDrop,
      useContextTrigger,
    }),
    [
      renderEvent,
      renderResource,
      resourceIdentifier,
      dragItemType,
      onDrop,
      useContextTrigger,
    ]
  );

  
  const eventsByResource = useMemo(
    // this groups the movies by their screenId {screenId:[...movies]}
    () => groupBy(filterTodayEvents(events, activeDate), eventGroupByKey),
    [events, activeDate, eventGroupByKey]
  );

 

  return (
    <div>
      {/* LABEL */}
      <div className="relative grid grid-cols-[8%_minmax(0,1fr)] bg-app-purple-5">
        <div />

        <div className="mode-day-grid grid grid-cols-[repeat(24,minmax(0,1fr))] relative">
          {dayMarkers.map((hr) => (
            <p
              key={hr}
              className="relative !font-mono break-words px-2 py-5 text-base font-semibold text-center text-app-black"
            >
              {hr}
            </p>
          ))}

          <p className="absolute left-0 z-10 text-xs font-normal text-[#7E2080] p-1 top-0">
            AM
          </p>

          <p className="absolute left-2/4 z-10 text-xs font-normal text-[#7E2080] p-1 top-0">
            PM
          </p>
        </div>
      </div>

      <div>
        {resources.map((res, i) => (
          <DayRow
            events={
              !isEmpty(eventsByResource[res[resourceIdentifier]])
                ? eventsByResource[res[resourceIdentifier]]
                : []
            }
            resource={res}
            key={res[resourceIdentifier]}
            activeDate={activeDate}
            {...rowProps}
          />
        ))}

        {renderContextMenu({ bindMenu, data, bindMenuItem, coords, hideMenu })}
      </div>
    </div>
  );
};

export default React.memo(DayView);
