import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getItems } from "../../../redux/slices";
import { FETCH_STATUS } from "../../../utils";
import { Spinner2 } from "./Spinner2";

const ComponentItemsModal = ({
  onClose = () => {},
  show,
  setSelectedComponentItems,
  selectedComponentItems,
  initialItemsArray,
}) => {
  const dispatch = useDispatch();
  const { getItemsStatus } = useSelector((state) => state.items);
  const [initialArray, setArray] = useState([
    // ...initialItemsArray,
    // ...selectedComponentItems,
  ]);

  const [enteredValue, setEnteredValue] = useState("");

  const { register, reset, watch, handleSubmit } = useForm();

  useEffect(() => {
    dispatch(getItems());
  }, [dispatch]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      return;
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onChangeHandler = (event) => {
    setEnteredValue(event.target.value);
    const searchResults = initialItemsArray.filter((item) => {
      return item.name.toLowerCase().includes(enteredValue.toLowerCase(), 0);
    });
    setArray(searchResults);
  };

  const watchAll = initialArray
    .map((item) => {
      return {
        id: item.id,
        itemAdded: watch(item.id),
        itemId: item.id,
        //using find here so that it either takes the already existing value for that item or it sets a default value
        unit:
          selectedComponentItems.find((x) => {
            return x.id === item.id;
          })?.unit || 1,
        name: item.name,
        unitOfMeasurementId:
          selectedComponentItems.find((x) => {
            return x.id === item.id;
          })?.unitOfMeasurementId || "",
        quantity:
          selectedComponentItems.find((x) => {
            return x.id === item.id;
          })?.quantity || 1,
        description:
          selectedComponentItems.find((x) => {
            return x.id === item.id;
          })?.description || "",
        quantityUOM:
          selectedComponentItems.find((x) => {
            return x.id === item.id;
          })?.quantityUOM || 1,
      };
    })
    .filter((item) => {
      return item.itemAdded !== false;
    });

  const submitHandler = (data) => {
    setSelectedComponentItems(watchAll);

    reset();
    onClose();
  };

  useEffect(() => {
    if (enteredValue.length === 0) {
      setArray(initialItemsArray);
    }
  }, [enteredValue, initialItemsArray]);

  return (
    <>
      <Transition show={show} appear as={Fragment}>
        <Dialog as="div" className="relative z-[10000]" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-sm max-h-[90vh] text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-white bg-[#a86aaa] rounded-t-2xl px-2 py-2 text-center"
                  >
                    Pick An Item
                  </Dialog.Title>
                  <div className=" px-[50px] py-2">
                    <div className="flex items-center bg-[#ececec] rounded-lg  px-2 ">
                      <svg
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.9381 10.915L16.4215 15.3983C16.579 15.556 16.6675 15.7698 16.6674 15.9928C16.6674 16.2157 16.5787 16.4295 16.421 16.5871C16.2633 16.7446 16.0495 16.8331 15.8266 16.833C15.6036 16.833 15.3899 16.7443 15.2323 16.5866L10.749 12.1033C9.40871 13.1414 7.72335 13.6299 6.03573 13.4694C4.3481 13.309 2.78498 12.5117 1.66436 11.2396C0.543731 9.96763 -0.0502209 8.31648 0.00333027 6.62209C0.0568814 4.9277 0.753913 3.31735 1.95262 2.11864C3.15134 0.919928 4.76169 0.222897 6.45608 0.169346C8.15046 0.115795 9.80161 0.709746 11.0736 1.83037C12.3456 2.951 13.143 4.51412 13.3034 6.20174C13.4639 7.88937 12.9754 9.57473 11.9373 10.915H11.9381ZM6.66729 11.8325C7.99337 11.8325 9.26514 11.3057 10.2028 10.368C11.1405 9.43032 11.6673 8.15855 11.6673 6.83247C11.6673 5.50639 11.1405 4.23462 10.2028 3.29694C9.26514 2.35925 7.99337 1.83247 6.66729 1.83247C5.3412 1.83247 4.06944 2.35925 3.13175 3.29694C2.19407 4.23462 1.66729 5.50639 1.66729 6.83247C1.66729 8.15855 2.19407 9.43032 3.13175 10.368C4.06944 11.3057 5.3412 11.8325 6.66729 11.8325Z"
                          fill="black"
                          fillOpacity="0.5"
                        />
                      </svg>

                      <input
                        name="Search"
                        id="search"
                        type="text"
                        className="border-none outline-none bg-[#ececec] "
                        placeholder="Search"
                        value={enteredValue}
                        onChange={onChangeHandler}
                      />
                    </div>
                  </div>
                  <div className=" py-2">
                    <form onSubmit={handleSubmit(submitHandler)}>
                      <div className=" max-h-[60vh] overflow-y-auto border-t-[1px] border-[#7E208080]">
                        {initialArray.map((item) => {
                          let checkedState;
                          selectedComponentItems.length > 0
                            ? (checkedState = selectedComponentItems.find(
                                (x) => {
                                  return x.id === item.id;
                                }
                              )
                                ? true
                                : false)
                            : (checkedState = false);

                          return (
                            <div
                              className="flex items-center justify-between px-2 py-1 border-b-[1px] border-[#7E208080]"
                              key={item.id}
                            >
                              {" "}
                              <span className="text-[#C96FCC]">
                                {item.name}
                              </span>{" "}
                              <input
                                type="checkbox"
                                className=" focus:!border-none text-[#C96FCC] !ring-transparent"
                                defaultChecked={checkedState}
                                {...register(item.id)}
                              />{" "}
                            </div>
                          );
                        })}
                        {initialArray.length < 1 &&
                          getItemsStatus === FETCH_STATUS.RESOLVED && (
                            <p className="text-center text-[#C96FCC] py-3">
                              Item not found
                            </p>
                          )}
                        {getItemsStatus === FETCH_STATUS.PENDING && (
                          <div className="flex items-center justify-center p-3">
                            {" "}
                            <Spinner2 />
                          </div>
                        )}
                      </div>
                      <div className="flex justify-center gap-3 buttongroup mt-2 ">
                        <button
                          className="p-1 px-3 rounded-md text-[#C96FCC] bg-[#ffe583] shadow-md hover:shadow-sm"
                          type="button"
                          onClick={onClose}
                        >
                          Cancel
                        </button>
                        <button
                          className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm"
                          type="submit"
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export { ComponentItemsModal };
