import { useReducer, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import {
  addShowTimes,
  deleteShowTime,
  getFilms,
  getScreens,
  getShowTimes,
  selectFilms,
  selectScreens,
  selectShowTimes,
  setActiveDate,
  editShowTime,
  getShowtimesPerDay,
  getFilmFormat,
} from "../redux/slices";

import { FETCH_STATUS, cinemaName, isCanal, loggedInCinemaName } from "../utils";
import { addDays, endOfDay, startOfDay } from "date-fns";

const init = {
  screensStatus: FETCH_STATUS.IDLE,
  screensErr: null,

  deleteSessionStatus: FETCH_STATUS.IDLE,
  deleteSessionErr: null,

  addSessionsStatus: FETCH_STATUS.IDLE,
  addSessionsErr: null,

  editSessionStatus: FETCH_STATUS.IDLE,
  editSessionErr: null,

  films: {},
  screens: {},
};

const actions = {
  getFilmsPending: "getFilmsPending",
  getFilmsResolved: "getFilmsResolved",
  getFilmsRejected: "getFilmsRejected",

  getScreensResolved: "getScreensResolved",

  reset: "reset",

  resetErr: "reset",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.getFilmsResolved:
      return {
        ...state,
        filmsStatus: FETCH_STATUS.RESOLVED,
        films: action.payload,
        filmsErr: null,
      };
    case actions.getScreensResolved:
      return {
        ...state,
        screensStatus: FETCH_STATUS.RESOLVED,
        screens: action.payload,
        screensErr: null,
      };

    case actions.addSessionsResolved:
      return {
        ...state,
        addSessionsStatus: FETCH_STATUS.RESOLVED,
      };

    case actions.editSessionResolved:
      const sessions = state.sessions.map((sess) => {
        if (sess.id === action.payload.id) {
          sess = action.payload;
        }
        return sess;
      });

      return {
        ...state,
        editSessionStatus: FETCH_STATUS.RESOLVED,
        sessions,
      };

    case actions.deleteSessionResolved:
      return {
        ...state,
        sessions: state.sessions.filter((sess) => sess.id !== action.payload),
        deleteSessionStatus: FETCH_STATUS.RESOLVED,
      };

    case actions.reset:
      return { ...init };

    case actions.resetErr:
      return {
        ...state,

        deleteSessionStatus: FETCH_STATUS.IDLE,
        deleteSessionErr: null,

        addSessionsStatus: FETCH_STATUS.IDLE,
        addSessionsErr: null,

        editSessionStatus: FETCH_STATUS.IDLE,
        editSessionErr: null,
      };

    default:
      return state;
  }
};

const useScheduling = () => {
  const reduxDispatch = useDispatch();

  const [state, dispatch] = useReducer(reducer, init);

  const { screens, screensStatus, screensErr } = useSelector(selectScreens);

  const { filmFormat, getFilmFormatErr, getFilmFormatStatus } =
    useSelector(selectFilms);

  const {
    showTimes,
    showTimesStatus,
    showTimesPerDay,
    showTimesPerDayErr,
    showTimesPerDayStatus,
    showTimesErr,
    activeDate,
  } = useSelector(selectShowTimes);

  const { films, getFilmsStatus, getFilmsError } = useSelector(selectFilms);

  useEffect(() => {
    if (screensStatus === FETCH_STATUS.IDLE) {
      reduxDispatch(getScreens());
    }

    if (showTimesStatus === FETCH_STATUS.IDLE) {
      reduxDispatch(getShowTimes());
    }

    if (getFilmFormatStatus === FETCH_STATUS.IDLE) {
      reduxDispatch(getFilmFormat());
    }

    if (getFilmsStatus === FETCH_STATUS.IDLE) {
      reduxDispatch(getFilms());
    }
  }, [
    reduxDispatch,
    screensStatus,
    showTimesStatus,
    getFilmsStatus,
    showTimesPerDayStatus,
    getFilmFormatStatus,
  ]);

  useEffect(() => {
    if (screensStatus === FETCH_STATUS.RESOLVED && screens.length) {
      const copyScreens = [...screens];
      dispatch({
        type: actions.getScreensResolved,
        payload: copyScreens.reduce((screensColl, screen) => {
          const updatedScreen = { ...screen, name: screen.shortName };
          screensColl[screen.id] = updatedScreen;
          return screensColl;
        }, {}),
      });
    }
  }, [screensStatus, screens]);

  useEffect(() => {
    if (getFilmsStatus === FETCH_STATUS.RESOLVED && films.length) {
      const copyFilms = [...films];
      dispatch({
        type: actions.getFilmsResolved,
        payload: copyFilms.reduce((movies, mov) => {
          const updatedMov = {
            ...mov,
            title: mov?.name || mov?.film || "Film name",
          };
          movies[mov.id] = updatedMov;

          return movies;
        }, {}),
      });
    }
  }, [films, getFilmsStatus]);

  const getSessions = useCallback(
    (force, date = new Date()) => {
      
      const cinemaName = localStorage.getItem(loggedInCinemaName);
      const isCanal = cinemaName?.toLowerCase().includes("canal");
      
      const oldStart = isCanal
        ? startOfWeek(new Date(activeDate), { weekStartsOn: 1 }).toISOString()
        : startOfWeek(new Date(activeDate)).toISOString();

      const start = isCanal
        ? startOfWeek(new Date(date), { weekStartsOn: 1 }).toISOString()
        : startOfWeek(new Date(date)).toISOString();
        const end = isCanal
        ? endOfWeek(new Date(date), { weekStartsOn: 1 }).toISOString()
        : endOfWeek(new Date(date)).toISOString();
        

     

      const updatedDate = new Date(date).toISOString();

      if (force || oldStart !== start) {
        reduxDispatch(getShowTimes({ start, end }));
      }

      reduxDispatch(setActiveDate(updatedDate));
    },
    [reduxDispatch, activeDate]
  );

  const getFilmFormats = useCallback(() => {
    reduxDispatch(getFilmFormat());
  }, [reduxDispatch]);

  const getDaySessions = useCallback(
    (force, date = new Date()) => {
      // const oldStart = startOfDay(new Date(activeDate)).toISOString();

      const start = startOfDay(new Date(date)).toISOString();
      const end = endOfDay(new Date(date)).toISOString();

      // const updatedDate = new Date(date).toISOString();
      // const test = addDays(new Date(start), 2).toISOString();

      if (force && start && end) {
        reduxDispatch(getShowtimesPerDay({ start, end }));
      }
    },
    [reduxDispatch]
  );

  const addSessions = useCallback(
    (sessions = []) => {
      reduxDispatch(addShowTimes(sessions));
    },
    [reduxDispatch]
  );

  const updateSession = useCallback(
    (session) => {
      reduxDispatch(editShowTime(session));
    },
    [reduxDispatch]
  );

  const deleteSession = useCallback(
    (sessionId) => {
      reduxDispatch(deleteShowTime(sessionId));
    },
    [reduxDispatch]
  );

  const fetchFilms = useCallback(
    (force) => {
      reduxDispatch(getFilms(force));
    },
    [reduxDispatch]
  );

  const fetchScreens = useCallback(
    (force) => {
      reduxDispatch(getScreens(force));
    },
    [reduxDispatch]
  );

  return {
    state: {
      ...state,
      screensStatus,
      screensErr,
      sessions: showTimes,
      sessionsPerDay: showTimesPerDay,
      sessionsPerDayStatus: showTimesPerDayStatus,
      sessionsPerDayErr: showTimesPerDayErr,
      sessionsStatus: showTimesStatus,
      sessionsErr: showTimesErr,
      filmsErr: getFilmsError,
      filmsStatus: getFilmsStatus,
      filmFormatStatus: getFilmFormatStatus,
      filmFormatErr: getFilmFormatErr,
      filmFormats: filmFormat,
    },
    getSessions,
    getDaySessions,
    getFilmFormats,
    getFilms: fetchFilms,
    getScreens: fetchScreens,
    addSessions,
    updateSession,
    deleteSession,
  };
};

export { useScheduling };
