import { useState, useEffect } from "react";
import { useTable } from "react-table";
import { useMemo } from "react";
import { ComponentItemsModal } from "./ComponentItemsModal";
import { useSelector, useDispatch } from "react-redux";
import { ITEM_GROUP } from "../../../utils";
const ComponenentItemTable = ({
  selectedComponentItems,
  setSelectedComponentItems,
  itemGroup,
  showComponentModal,
  setShowComponentModal,
  useCase,
}) => {
  const { unitOfMeasurement, itemsList } = useSelector((state) => state.items);
  const [itemsArray, setItemsArray] = useState([]);
  const dispatch = useDispatch();
  const defaultFields = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Unit Of Measure",
      accessor: "unitOfMeasurementId",
      Cell: (props) => {
        return (
          <select
            defaultValue={props.value || ""}
            className="px-2 py-1 w-[150px]"
            onChange={(e) => {
              selectedComponentItems[props.row.id].unitOfMeasurementId =
                e.target.value;
            }}
          >
            <option value="">Select Unit</option>
            {unitOfMeasurement.map((unit) => {
              return <option value={unit.id}> {unit.name}</option>;
            })}
          </select>
        );
      },
    },
    {
      Header: "Unit",
      accessor: "unit",
      Cell: (props) => {
        return (
          <input
            defaultValue={props.value}
            type="number"
            min={0.1}
            className="px-2 py-1 w-[100px]"
            onChange={(e) => {
              selectedComponentItems[props.row.id].unit = +e.target.value;
            }}
          />
        );
      },
    },
    {
      Header: "Quantity",
      accessor: "quantity",
      Cell: (props) => {
        return (
          <input
            defaultValue={props.value}
            type="number"
            min={0.1}
            className="px-2 py-1 w-[100px]"
            onChange={(e) => {
              selectedComponentItems[props.row.id].quantity = +e.target.value;
            }}
          />
        );
      },
    },
    {
      Header: "Action",
      Cell: (props) => {
        return (
          <button
            className="bg-white py-1 px-2 rounded-md text-[#a86aaa]"
            onClick={() => {
              setSelectedComponentItems(
                selectedComponentItems.filter((item, index) => {
                  return index !== +props.row.id;
                })
              );
            }}
          >
            Remove
            <span className="bg-[#a86aaa] text-white px-1 rounded-full ">
              X
            </span>
          </button>
        );
      },
    },
  ];

  const stockRequestFields = [
    defaultFields[0],
    {
      Header: "Description",
      accessor: "description",
      Cell: (props) => {
        return (
          <input
            defaultValue={props.value}
            className="px-2 py-1 w-[100px]"
            onChange={(e) => {
              selectedComponentItems[props.row.id].description = e.target.value;
            }}
          />
        );
      },
    },
    defaultFields[1],
    {
      Header: "QUoM",
      accessor: "quantityUOM",
      Cell: (props) => {
        return (
          <input
            defaultValue={props.value}
            className="px-2 py-1 w-[100px]"
            type="number"
            min={0.1}
            onChange={(e) => {
              selectedComponentItems[props.row.id].quantityUOM =
                +e.target.value;
            }}
          />
        );
      },
    },
    ...defaultFields.slice(3),
  ];

  let columns = useMemo(() => {
    if (useCase === "componentItems") {
      return [...defaultFields];
    }
    if (useCase === "stockRequest") {
      return [...stockRequestFields];
    }
    return [...defaultFields];
  }, [selectedComponentItems, itemGroup, useCase]);

  useEffect(() => {
    if (useCase === "componentItems" && itemsList.length > 0) {
      setItemsArray(
        itemsList.filter((item) => {
          return (
            item.itemGroupId === ITEM_GROUP.COMPONENT ||
            item.itemGroupId === ITEM_GROUP.SUNDRY
          );
        })
      );
    }
    if (useCase === "stockRequest" && itemsList.length > 0) {
      setItemsArray(
        itemsList.filter((item) => {
          return (
            item.itemGroupId === "76a4f000-03e1-4627-8b0a-85e569a7d5e4" ||
            item.itemGroupId === ITEM_GROUP.SINGLE
          );
        })
      );
    }
  }, [useCase, itemsList]);

  const data = useMemo(() => {
    return [...selectedComponentItems];
  }, [selectedComponentItems]);

  const tableInstance = useTable({
    columns,
    data,
  });

  // <option value=ITEM_GROUP.SINGLE>Single</option>
  // <option value="b089b585-2ffb-4ebc-af43-c2f294dbfe8d">Parent</option>
  // <option value="03571310-71a2-4425-93ce-1c65d18b4341">Package</option>

  const { headerGroups, getTableProps, getTableBodyProps, rows, prepareRow } =
    tableInstance;

  return (
    <div className="w-[95%] mx-auto ">
      <div className="flex items-center justify-between px-2 py-3 bg-[#a86aaa] ">
        <p className="text-white">{`Your ${
          useCase === "componentItems" ? "Component" : ""
        } Items`}</p>
        <button
          className="rounded border-0 bg-white p-1 text-[#C96FCC]"
          onClick={() => {
            setShowComponentModal(true);
          }}
          type="button"
        >
          Add Item
        </button>
      </div>
      <table {...getTableProps()} className="w-full">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className="shadow-lg p-4 border border-[#7E208080] bg-white text-left"
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);

            return (
              <tr
                {...row.getRowProps()}
                className="transition duration-300 bg-app-purple-1 hover:bg-gray-100"
              >
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className="p-4 border border-[#7E208080] bg-"
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      {showComponentModal && (
        <ComponentItemsModal
          show={showComponentModal}
          onClose={() => {
            setShowComponentModal(false);
          }}
          setSelectedComponentItems={setSelectedComponentItems}
          selectedComponentItems={selectedComponentItems}
          initialItemsArray={itemsArray}
        />
      )}
    </div>
  );
};
export { ComponenentItemTable };
