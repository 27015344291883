import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  API_URL,
  axiosApiInstance,
  axiosApiInstanceGet,
  editItemDirect,
  FETCH_STATUS,
  getErrMsg,
} from "../../utils";

export const getRefundReport = createAsyncThunk(
  "getRefundReport",
  async ({ dateFrom, dateTo }, { fulfillWithValue, rejectWithValue }) => {
  const force = true
    try {
      const resp = await axiosApiInstanceGet(
        `${API_URL}/RefundReports/ListAll?DateFrom=${dateFrom}&DateTo=${dateTo}&page=1&perPage=1000`,force
      );
    
      return fulfillWithValue(resp?.data?.data||[]);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      // console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

const initialState = {
  refundReports: [],
  refundReportsStatus: FETCH_STATUS.IDLE,
  refundReportsErr: null,
};

export const refundReportSlice = createSlice({
  name: "refundReports",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getRefundReport.pending, (state) => {
        state.refundReportsStatus = FETCH_STATUS.PENDING;
        state.refundReportsErr = "";
      })
      .addCase(getRefundReport.fulfilled, (state, action) => {
        state.refundReportsStatus = FETCH_STATUS.RESOLVED;
        state.refundReports = action.payload;
        state.refundReportsErr = "";
      })
      .addCase(getRefundReport.rejected, (state, action) => {
        state.refundReportsStatus = FETCH_STATUS.REJECTED;
        state.refundReportsErr =
          action.payload || "Unable to get refund reports";
      });
  },
});
export const selectRefundReports = (state) => state.refundReports;
