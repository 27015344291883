import { useMemo, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTable } from "react-table";
import { useRequest } from "../../hooks";
import {
  formatNumberAsCurrency,
  API_URL,
  axiosApiInstance,
  FETCH_STATUS,
} from "../../utils";
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "../../redux/slices";
import { Loading, Spinner, ErrorWithWrapper } from "../UI";
import { getSubscriptionStatus } from "../../redux/slices";

const InvoiceTable = () => {
  const { profile } = useSelector(selectUser);
  const { status, setResolved, setPending, setRejected, err, data } =
    useRequest();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    status: status2,
    setResolved: setResolved2,
    setPending: setPending2,
    setRejected: setRejected2,
    // err: err2,
    // data:data2,
  } = useRequest();
  const [errorCode, setErrorCode] = useState("");

  const params = useParams();

  const getInvoice = async () => {
    try {
      setPending();
      const response = await axiosApiInstance.get(
        `${API_URL}/CircuitSubscriptions/GetPendingInvoice?circuitId=${profile.circuitId}`
      );
      setResolved(response.data.data);
      // reloadFunc();
    } catch (error) {
      setRejected(error.message || error);
      setErrorCode(error.response.status);
    }
  };

  const makePayment = async () => {
    try {
      setPending2();
      const response = await axiosApiInstance.get(
        `${API_URL}/CircuitSubscriptions/InitializePayment?circuitId=${profile.circuitId}`
      );
      if (response?.data?.data?.authorization_url) {
        window.location.href = response?.data?.data?.authorization_url;
      }
      // setResolved2(response.data.data);
    } catch (error) {
      setRejected2(error.message || error);
    }
  };

  useEffect(() => {
    if (status === FETCH_STATUS.IDLE) {
      getInvoice();
      dispatch(
        getSubscriptionStatus({ force: true, circuitId: profile.circuitId })
      );
    }
  }, [profile]);

  useEffect(() => {
    // console.log("use effect rerendered");
    if (params?.status) {
      // console.log("in the if check");
      dispatch(
        getSubscriptionStatus({ force: true, circuitId: profile.circuitId })
      );
      getInvoice().then(() => {
        navigate("/dashboard/finance/invoice");
      });
    }
  }, [params]);

  const columns = useMemo(() => {
    return [
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Date",
        accessor: "paymentPeriod",
        Cell: ({ value }) => new Date(value).toDateString(),
      },
      {
        Header: "Amount (incl Tax)",
        accessor: "amount",
        Cell: ({ value }) => formatNumberAsCurrency(value),
      },
    ];
  }, []);

  const columns2 = useMemo(() => {
    return [
      {
        Header: "Cinema",
        accessor: "name",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Amount (without Tax)",
        accessor: "amount",
        Cell: ({ value }) => formatNumberAsCurrency(value),
      },
    ];
  }, []);

  const pastInvoice = useMemo(() => {
    if (data?.overDueSubscriptionPaymentDetails) {
      return data?.overDueSubscriptionPaymentDetails;
    }
    return [];
  }, [data]);

  const currentInvoice = useMemo(() => {
    if (data?.subscriptionPaymentDetails) {
      return data?.subscriptionPaymentDetails;
    }
    return [];
  }, [data]);

  const tableInstance = useTable({
    data: pastInvoice,
    columns: columns,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const tableInstance2 = useTable({
    data: currentInvoice,
    columns: columns2,
  });

  const {
    getTableProps: getTableProps2,
    getTableBodyProps: getTableBodyProps2,
    headerGroups: headerGroups2,
    rows: rows2,
    prepareRow: prepareRow2,
  } = tableInstance2;

  return (
    <div className="px-[100px]">
      {/* When the user is authorized */}
      <ErrorWithWrapper
        show={errorCode === 403}
        error={"You do not have access to view this page"}
      />

      {/* When it is making request to load the invoice */}
      <Loading
        wrapperClassName="relative min-h-[50vh]"
        show={status === FETCH_STATUS.PENDING}
      />

      {/* When the user is authorized and there is a pending invoice */}
      {status === FETCH_STATUS.RESOLVED && (
        <>
          {pastInvoice?.length > 0 && (
            <>
              <p className="text-[22px]">Past Overdue Invoice(s)</p>
              <table
                {...getTableProps()}
                className="w-full mx-auto mt-8 overflow-hidden text-left border rounded-md border-app-purple-4"
                style={{ border: "1px" }}
              >
                <thead className="shadow-2xl bg-app-purple-8">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps()}
                          className="p-4 font-normal tracking-wide uppercase border border-gray-300 text-md"
                        >
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30"
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()} className="p-4">
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="flex justify-end text-[22px] py-3">
                {" "}
                <p> Sub-Total:</p>{" "}
                <p className="w-[200px] text-right">
                  {formatNumberAsCurrency(data?.balanceBroughtForward)}
                </p>
              </div>
            </>
          )}
          <p className="text-[22px]">{`Current Invoice (${new Date(
            data?.paymentPeriod
          )?.toDateString()})`}</p>
          <table
            {...getTableProps2()}
            className="w-full mx-auto mt-8 overflow-hidden text-left border rounded-md border-app-purple-4"
            style={{ border: "1px" }}
          >
            <thead className="shadow-2xl bg-app-purple-8">
              {headerGroups2.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      className="p-4 font-normal tracking-wide uppercase border border-gray-300 text-md"
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps2()}>
              {rows2.map((row) => {
                prepareRow2(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30"
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} className="p-4">
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="flex justify-end text-[22px] py-3">
            {" "}
            <p> Sub-Total:</p>{" "}
            <p className="w-[200px] text-right">
              {formatNumberAsCurrency(data?.subTotal)}
            </p>
          </div>
          <div className="flex justify-end text-[22px] py-3">
            {" "}
            <p> VAT (7.5%):</p>{" "}
            <p className="w-[200px] text-right">
              {formatNumberAsCurrency(data?.vat)}
            </p>
          </div>
          {data?.balanceBroughtForward ? (
            <div className="flex justify-end text-[22px] py-3">
              {" "}
              <p> Balance Brought Forward:</p>{" "}
              <p className="w-[200px] text-right">
                {formatNumberAsCurrency(data?.balanceBroughtForward)}
              </p>
            </div>
          ) : (
            <></>
          )}
          <div className="flex justify-end text-[22px] py-3">
            {" "}
            <p>Total Due:</p>{" "}
            <p className="w-[200px] text-right">
              {formatNumberAsCurrency(data?.total)}
            </p>
          </div>
          <div className="flex items-center justify-center mt-4">
            <button
              type="button"
              className="relative min-w-[150px] flex justify-center items-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500"
              onClick={makePayment}
            >
              Make Payment
              <Spinner
                className="!w-3 !h-3 mx-1 text-white fill-amber-300"
                status={
                  status2 === FETCH_STATUS.PENDING ? FETCH_STATUS.PENDING : ""
                }
              />
            </button>
          </div>
        </>
      )}

      {/* When the user is authorised and there is no pending invoice */}
      {errorCode === 404 && (
        <p className="text-center text-[20px] p-3">
          {" "}
          No Pending Invoice was Found
        </p>
      )}
    </div>
  );
};
export { InvoiceTable };
