import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  ActiveRequestSpinner,
  PdfButton,
  PrivateRoute,
  PurchaseOrderLayout,
} from "../../../../components";
import { DateTimeInput } from "../../../../components/Films/DateTimeInput";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import {
  stockData,
  getPurchaseOrder,
  getCurrencyList,
  updatePurchaseOrder,
} from "../../../../redux/slices";
import {
  purchaseOrdSchema,
  FETCH_STATUS,
  formatNumberAsCurrency,
} from "../../../../utils";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ItemFormErrorModal as FormErrorModal } from "../../../../components";
import POItemsTable from "./POItemsTable";
import { FormError } from "../../../../components";
import { useReactToPrint } from "react-to-print";

const InlineError = ({ err }) => {
  if (err) {
    return (
      <p className={`italic  text-red-400 font-light text-[10px] `}>{err}</p>
    );
  }

  return null;
};
const PurchaseOrdForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [confirmationDate, setConfirmationDate] = useState(
    new Date().toISOString()
  );
  const [effectiveDate, setEffectiveDate] = useState(new Date().toISOString());
  const [items, setItems] = useState([]);

  const [formError, setFormError] = useState(false);
  const {
    purchaseOrder,
    purchaseOrderStatus,
    currencyList,
    updatePurchaseOrderStatus,
    updatePurchaseOrderErr,
  } = useSelector(stockData);
  const dispatch = useDispatch();
  const pdfRef = useRef(null);

  const {
    register,
    setValue,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(purchaseOrdSchema),
    defaultValues: {
      purchaseAgreement: "" || purchaseOrder.purchaseAgreement,
      currency: "" || purchaseOrder.currency,
      confirmationDate: confirmationDate,
      effectiveDate: effectiveDate,
    },
  });

  useEffect(() => {
    dispatch(getCurrencyList());
  }, [dispatch]);

  useEffect(() => {
    setValue("confirmationDate", confirmationDate);
    setValue("effectiveDate", effectiveDate);
  }, [confirmationDate, effectiveDate, setValue]);

  useEffect(() => {
    dispatch(getPurchaseOrder({ id, force: true }))
      .unwrap()
      .then((data) => {
        const newData = data?.items.map((item) => {
          return item;
        });
        setItems([...newData]);
        reset({
          purchaseAgreement: data.purchaseAgreement,
          currency: data.currency,
        });
        if (data?.effectiveDate) {
          setEffectiveDate(new Date(data?.effectiveDate).toISOString());
        }
        if (data?.confirmationDate) {
          setConfirmationDate(new Date(data?.confirmationDate).toISOString());
        }
        setValue("confirmationDate", confirmationDate);
        setValue("effectiveDate", effectiveDate);
      });
  }, [id, dispatch]);

  const getTotal = () => {
    let POTotal = 0;
    items.forEach((item) => {
      const subTotal = item.unitPrice * item.quantity;
      const total = subTotal + subTotal * (item.tax / 100);
      POTotal += total;
    });
    return POTotal;
  };

  const formatData = (data) => {
    return {
      ...data,
      items: items.map((item) => {
        const subTotal = item.unitPrice * item.quantity;
        const total = subTotal + subTotal * (item.tax / 100);
        return { ...item, subTotal, total };
      }),
      total: getTotal(),
    };
  };

  /*additional validations for the form entry which are not catered for
with react hook form
*/
  const validateData = (data) => {
    if (
      data.items.find((item) => {
        return !item.unitPrice;
      })
    ) {
      setFormError("Ensure You enter the unit Price for each Item");
      return false;
    }
    return true;
  };

  const saveFunction = () => {
    handleSubmit((data) => {
      const cleanedData = formatData(data);
      const dataIsValid = validateData(cleanedData);
      if (dataIsValid) {
        dispatch(updatePurchaseOrder({ id, data: cleanedData })).then(() => {
          reset();
          setItems([]);
          navigate("/dashboard/stock/purchaseOrder");
        });
      }
    })();
  };
  // console.log(purchaseOrder);
  // const handlePrint = useReactToPrint({
  //   content: () => pdfRef.current,
  //   documentTitle: "purchase-order",
  // });
  return (
    <PrivateRoute redirectTo="/dashboard/stock/purchaseOrder">
      {purchaseOrder?.status === "Pending" && (
        <>
          <div className="flex items-center justify-end p-4 px-8 rounded-t-lg bg-gradient-to-b from-app-purple-4 to-app-purple-3">
            <div className="flex buttongroup">
              <button
                className="p-3 px-5 rounded-md text-[#C96FCC] bg-white shadow-md mr-4 hover:font-bold"
                onClick={saveFunction}
              >
                Save
              </button>

              <button
                className="p-3 px-5 rounded-md text-[#C96FCC] bg-white shadow-md hover:font-bold"
                onClick={() => {
                  navigate("/dashboard/stock/purchaseOrder");
                }}
              >
                Back
              </button>
            </div>
          </div>
          <FormError className="mt-[20px]" err={updatePurchaseOrderErr} />
          <form className="flex justify-center mb-5">
            <div className=" max-w-[800px]  grid grid-cols-2 gap-5 mt-[30px] ">
              <div>
                <label className="block"> Purchase Agreement</label>
                <textarea
                  className="rounded bg-[#d0a3d3] border-0 mb-3 w-[250px] text-black"
                  placeholder="Enter agreement here"
                  rows={5}
                  {...register("purchaseAgreement")}
                ></textarea>

                <InlineError
                  err={
                    errors?.purchaseAgreement?.message
                      ? "Ensure you select a requisition type"
                      : ""
                  }
                />
              </div>
              <div>
                <label htmlFor={`confirmationDate`}>Confirmation Date</label>
                <DatePicker
                  id="confirmationDate"
                  placeholderText="Confirmation Date"
                  selected={new Date(confirmationDate)}
                  onChange={(value) => setConfirmationDate(value.toISOString())}
                  customInput={
                    <DateTimeInput
                      className="rounded bg-[#d0a3d3] border-0 mb-3 w-[180px]"
                      dateFormat={"EEE, MMM dd, yyyy"}
                      value={confirmationDate}
                    />
                  }
                />
                <InlineError err={errors?.confirmationDate?.message} />
              </div>
              <div>
                <label className="block"> Currency</label>
                <select
                  className="rounded bg-[#d0a3d3] border-0 mb-3 w-[180px]"
                  {...register("currency")}
                >
                  <option value={""}>Select Currency</option>
                  {currencyList?.length > 0 &&
                    currencyList?.map((currency, index) => {
                      return (
                        <option value={currency} key={index}>
                          {currency}
                        </option>
                      );
                    })}
                </select>
                <InlineError
                  err={
                    errors?.currency?.message
                      ? "Ensure you select a currency"
                      : ""
                  }
                />
              </div>
              <div>
                <label htmlFor={`effectiveDate`}>Effective Date</label>
                <DatePicker
                  id="effectiveDate"
                  placeholderText="Effective Date"
                  selected={new Date(effectiveDate)}
                  onChange={(value) => setEffectiveDate(value.toISOString())}
                  customInput={
                    <DateTimeInput
                      className="rounded bg-[#d0a3d3] border-0 mb-3 w-[180px]"
                      dateFormat={"EEE, MMM dd, yyyy"}
                      value={effectiveDate}
                    />
                  }
                />
                <InlineError err={errors?.requestDate?.message} />
              </div>
            </div>
          </form>
          <div className="overflow-x-scroll">
            <POItemsTable items={items} setItems={setItems} />
            <p className="text-right font-bold text-[20px] py-3 ">
              {" "}
              <span className="mr-5">{"Total (incd Taxes):"}</span>
              <span className="mr-[5rem]">
                {formatNumberAsCurrency(getTotal())}
              </span>
            </p>
          </div>
        </>
      )}
      {purchaseOrder?.status === "Completed" && (
        <div className="bg-white mx-[50px] py-5 px-[100px] rounded">
          <div className="flex justify-center space-x-2 mb-[30px]">
            <button
              onClick={() => {
                navigate("/dashboard/stock/purchaseOrder");
              }}
              className=" mb-[] p-1 px-3 flex items-center justify-between rounded-md text-white bg-[#7E2080] shadow-sm hover:shadow-xl hover:text-white"
            >
              Back
            </button>
            {/* <button
              type={"button"}
              className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm"
              onClick={handlePrint}
            >
              Generate PDF
            </button> */}
            <PdfButton refValue={pdfRef} docTitle={"purchase-order"} />
          </div>
          <div ref={pdfRef} className="m-2">
            <PurchaseOrderLayout type="purchaseOrder" />
          </div>
        </div>
      )}
      <ActiveRequestSpinner
        show={
          purchaseOrderStatus === FETCH_STATUS.PENDING ||
          updatePurchaseOrderStatus === FETCH_STATUS.PENDING
            ? true
            : false
        }
      />
      <FormErrorModal
        onClose={() => {
          setFormError(false);
        }}
        show={formError}
        message={formError}
        onClick={() => {
          setFormError(false);
        }}
      />
    </PrivateRoute>
  );
};

export { PurchaseOrdForm };
