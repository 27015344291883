import React from "react";
import { FETCH_STATUS } from "../../utils";

const InlineErr = ({ err, className, retry, status }) => {
  if (err) {
    return (
      <small
        className={`italic absolute left-0 top-full text-red-400 font-light text-[10px] ${className}`}
      >
        {err}

        {retry ? (
          <>
            &nbsp; &nbsp;
            <button
              type="button"
              onClick={retry}
              className="bg-red-400 text-white p-1 inline-block my-2 text-center rounded"
            >
              {FETCH_STATUS.PENDING !== status ? "Retry ?" : "Wait..."}
            </button>
          </>
        ) : null}
      </small>
    );
  }

  return null;
};

export { InlineErr };
