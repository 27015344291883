const sum = (card, cash, transfer,externalVoucher,otherChannel) => {
  return parseFloat(cash) + parseFloat(card) + parseFloat(transfer) + parseFloat(externalVoucher)+parseFloat(otherChannel);
};

class StaffSession {
  static toUI(info) {
    return {
      ...info,
      name:
        info.user?.firstName || info.user?.lastName
          ? `${info.user.firstName} ${info.user.lastName}`
          : info.user.id,
      isActive: !!info.closedById ? false : true,
      closedBy: !!info.dateTimeClosed
        ? `${info.closedBy.firstName} ${info.closedBy.lastName}`
        : "N/A",
      closedOn: info.dateTimeClosed,
      totalExpected: sum(
        info.expectedCard,
        info.expectedCash,
        info.expectedTransfer,
        info.expectedExternalVoucher,
        info.expectedOtherChannel
      ),
      totalActual: sum(info.actualCard, info.actualCash, info.actualTransfer,info.actualExternalVoucher,info.actualOtherChannel),
    };
  }

  static toDTO(info) {
    return {
      ...info,
    };
  }
}

export { StaffSession };
