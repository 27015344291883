import React from "react";
import { Input } from "../UI";

const wrapperClass = "grid-cols-[40%_minmax(0,1fr)] gap-4";

const StaffInfo = ({ user }) => {
  return (
    <div className="space-y-6">
      <Input label="Id:" wrapperClass={wrapperClass} disabled value={user.id} />
      <Input
        label="Name:"
        wrapperClass={wrapperClass}
        disabled
        value={`${user.lastName} ${user.firstName}`}
      />

      <Input
        label="Username:"
        wrapperClass={wrapperClass}
        disabled
        value={`${user.userName}`}
      />
      <Input
        label="Email:"
        wrapperClass={wrapperClass}
        disabled
        value={`${user.email}`}
      />
    </div>
  );
};

export { StaffInfo };
