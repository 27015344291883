import {useSelector} from "react-redux";
import {reportData} from "../../redux/slices";
import {useLoggedInuser} from "../../hooks";
import ReachLogo from "../../assets/images/logo.png";
import {dateAndTime, formatDateTime, formatNumberAsCurrency,} from "../../utils";
import {Th} from "./styles";

const PerformanceReportTable = ({ id1, id2, id3, id4 }) => {
  const { performanceReport } = useSelector(reportData);
  const { profile } = useLoggedInuser();
  return (
    <div className="px-3">
      <div className="flex items-center justify-between">
        <div
          key={ReachLogo}
          className=" w-[5rem] h-[5rem]  bg-no-repeat bg-contain bg-center "
          style={{
            backgroundImage: `url("${ReachLogo}")`,
          }}
        ></div>
        <p className="font-bold text-[20px]">Performance Report</p>
        <p>{profile.cinema}</p>
      </div>
      <div className=" flex p-2 justify-between">
        <div>
          <p>
            {" "}
            <span className="font-bold">User Name:</span>
            {` ${profile.firstName} ${profile.lastName}`}
          </p>
          <p>
            <span className="font-bold">Date Time Generated: </span>
            {formatDateTime(
              performanceReport?.paramFilter?.dateTimeGenerated,
              dateAndTime
            )}
          </p>
        </div>
        <div>
          <p className="font-bold">Data Range</p>
          <p>
            {new Date(performanceReport?.paramFilter?.dateFrom).toDateString()}
          </p>{" "}
          <p>
            {new Date(performanceReport?.paramFilter?.dateTo).toDateString()}
          </p>
        </div>
      </div>
      <table
        className="table-auto w-full mx-auto mt-8 overflow-hidden text-left border rounded-md border-app-purple-4"
        id={id1}
      >
        <thead className="shadow-2xl bg-app-purple-8">
          <tr className="text-center">
            {" "}
            <Th colSpan={3}>Base Information</Th>
            <Th colSpan={3}>Peformance Indicators</Th>{" "}
            <Th colSpan={2}>
              Concessions Profit <br /> Performance
            </Th>
          </tr>
        </thead>
        <tbody>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30 px-6">
            <td className="pl-2" colSpan={2}>
              Box office admits incl Comps
            </td>
            <td className="text-center px-2 border border-app-purple-4 border-opacity-30 border-l-0 border-r-app-purple-4">
              {performanceReport?.result?.boxOfficeAdmitsInclComps}
            </td>
            <td className="pl-2" colSpan={2}>
              Items Per head
            </td>
            <td className="text-center px-2 border border-app-purple-4 border-opacity-30 border-l-0 border-r-app-purple-4">
              {performanceReport?.result.itemsPerHead}
            </td>
            <td className="pl-2">Profit at standard cost</td>
            <td>
              {" "}
              {performanceReport?.result?.profitAtStdCost?.toFixed(3) ||
                "N/A"}{" "}
            </td>
          </tr>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30 px-6">
            <td className="pl-2" colSpan={2}>
              Comps
            </td>
            <td className="text-center px-2 border border-app-purple-4 border-opacity-30 border-l-0 border-r-app-purple-4">
              {performanceReport?.result.comps}
            </td>
            <td className="pl-2" colSpan={2}>
              Items Per Transaction
            </td>
            <td className="text-center px-2 border border-app-purple-4 border-opacity-30 border-l-0 border-r-app-purple-4">
              {performanceReport?.result.itemsPerTransaction}
            </td>
            <td className="pl-2">Average profit %</td>
            <td>
              {performanceReport?.result?.avgProfitPerc?.toFixed(3) || "N/A"}
            </td>
          </tr>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30 px-6">
            <td className="pl-2" colSpan={2}>
              Transactions with Tickets
            </td>
            <td className="text-center px-2 border border-app-purple-4 border-opacity-30 border-l-0 border-r-app-purple-4">
              {performanceReport?.result.transactionWithTickets}
            </td>
            <td className="pl-2" colSpan={2}>
              Occupancy rate %
            </td>
            <td className="text-center px-2 border border-app-purple-4 border-opacity-30 border-l-0 border-r-app-purple-4">
              {performanceReport?.result?.occupancyRate?.toFixed(3) || "N/A"}
            </td>
            <td className="pl-2">Average profit per Item</td>
            <td>{performanceReport?.result?.avgProfitPerItem?.toFixed(3) || "N/A"}</td>
          </tr>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30 px-6">
            <td className="pl-2" colSpan={2}>
              Transactions with Concessions
            </td>
            <td className="text-center px-2 border border-app-purple-4 border-opacity-30 border-l-0 border-r-app-purple-4">
              {performanceReport?.result.trnsactionsWithConcessions}
            </td>
            <td className="pl-2" colSpan={2}>
              Admissions Strike Rate %
            </td>
            <td className="text-center px-2 border border-app-purple-4 border-opacity-30 border-l-0 border-r-app-purple-4">
              {performanceReport?.result.admissionStrRate.toLocaleString() ||
                "N/A"}
            </td>
            <td className="pl-2"> Profit per Admit</td>
            <td>
              {performanceReport?.result?.profitPerAdmit?.toFixed(3) || "N/A"}
            </td>
          </tr>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30 px-6">
            <td className="pl-2" colSpan={2}>
              Quantity of item sold
            </td>
            <td className="text-center px-2 border border-app-purple-4 border-opacity-30 border-l-0 border-r-app-purple-4">
              {performanceReport?.result.quntityItemSold}
            </td>
            <td className="pl-2" colSpan={2}>
              Transaction Strike Rate %
            </td>
            <td className="text-center px-2 border border-app-purple-4 border-opacity-30 border-l-0 border-r-app-purple-4">
              {performanceReport?.result?.transactionStrRate.toLocaleString() ||
                "N/A"}
            </td>
            <td className="pl-2" colSpan={2}></td>
          </tr>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30 px-6">
            <td colSpan={8} className="h-[30px]"></td>
          </tr>
          <tr className=" transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30 px-6">
            <td></td>
            <td className="text-right">Net</td>
            <td className="text-right">Gross</td>
            <td></td>
            <td className="text-right">Net</td>
            <td className="text-right">Gross</td>
            <td colSpan={2}></td>
          </tr>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30 px-6">
            <td className="pl-2"> Box Office sales</td>
            <td className="text-right">
              {id1
                ? performanceReport?.result?.boxOfficeSalesNet
                : formatNumberAsCurrency(
                    performanceReport?.result?.boxOfficeSalesNet.toFixed(2)
                  )}
            </td>
            <td className="text-right">
              {id1
                ? performanceReport?.result?.boxOfficeSales
                : formatNumberAsCurrency(
                    performanceReport?.result?.boxOfficeSales
                  )}
            </td>
            <td className="pl-2">Spend Per Head</td>
            <td className="text-right">
              {id1
                ? Math.round(performanceReport?.result?.spendPerHead)
                : formatNumberAsCurrency(
                    Math.round(performanceReport?.result?.spendPerHead)
                  )}
            </td>
            <td className="text-right">
              {id1
                ? Math.round(performanceReport?.result?.spendPerHead)
                : formatNumberAsCurrency(
                    Math.round(performanceReport?.result?.spendPerHead)
                  )}
            </td>
            <td colSpan={2}></td>
          </tr>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30 px-6">
            <td className="pl-2"> Voucher sales</td>
            <td className="text-right">
              {" "}
              {id1
                ? performanceReport?.result?.voucherSalesNet || "N/A"
                : formatNumberAsCurrency(
                    performanceReport?.result?.voucherSalesNet
                  ) || "N/A"}
            </td>
            <td className="text-right">
              {" "}
              {id1
                ? performanceReport?.result?.voucherSalesGross || "N/A"
                : formatNumberAsCurrency(
                    performanceReport?.result?.voucherSalesGross
                  ) || "N/A"}
            </td>
            <td className="pl-2">Avg price per concession</td>
            <td className="text-right">
              {id1
                ? Math.round(
                    performanceReport?.result?.avgPricePerConcessionNet
                  )
                : formatNumberAsCurrency(
                    Math.round(
                      performanceReport?.result?.avgPricePerConcessionNet
                    )
                  )}
            </td>
            <td className="text-right">
              {" "}
              {id1
                ? Math.round(
                    performanceReport?.result?.avgPricePerConcessionGross
                  )
                : formatNumberAsCurrency(
                    Math.round(
                      performanceReport?.result?.avgPricePerConcessionGross
                    )
                  )}
            </td>
            <td colSpan={2}></td>
          </tr>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30 px-6">
            <td className="pl-2"> Box Office sundries</td>
            <td className="text-right">
              {formatNumberAsCurrency(
                performanceReport?.result?.boxOfficeSundriesNet?.toFixed(2)
              ) || "N/A"}
            </td>
            <td className="text-right">
              {formatNumberAsCurrency(
                performanceReport?.result?.boxOfficeSundriesGross?.toFixed(2)
              ) || "N/A"}
            </td>
            <td className="pl-2">Avg price per concession transaction</td>
            <td className="text-right">
              {" "}
              {id1
                ? Math.round(
                    performanceReport?.result
                      ?.avgPricePerConcessionTransactionNet
                  ) || "N/A"
                : formatNumberAsCurrency(
                    Math.round(
                      performanceReport?.result
                        ?.avgPricePerConcessionTransactionNet
                    )
                  ) || "N/A"}
            </td>
            <td className="text-right">
              {id1
                ? Math.round(
                    performanceReport?.result
                      ?.avgPricePerConcessionTransactionGross
                  ) || "N/A"
                : formatNumberAsCurrency(
                    Math.round(
                      performanceReport?.result
                        ?.avgPricePerConcessionTransactionGross
                    )
                  ) || "N/A"}
            </td>
            <td colSpan={2}></td>
          </tr>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30 px-6">
            <td className="pl-2"> Concession Sales</td>
            <td className="text-right">
              {id1
                ? performanceReport?.result?.concessionSalesNet
                : formatNumberAsCurrency(
                    Math.round(performanceReport?.result?.concessionSalesNet)
                  )}
            </td>
            <td className="text-right">
              {id1
                ? performanceReport?.result?.concessionSalesGross
                : formatNumberAsCurrency(
                    performanceReport?.result?.concessionSalesGross
                  )}
            </td>
            <td className="pl-2">Avg ticket price incl comps</td>
            <td className="text-right">
              {" "}
              {id1
                ? Math.round(
                    performanceReport?.result?.avgTicketPriceInclCompsNet
                  ) || "N/A"
                : formatNumberAsCurrency(
                    Math.round(
                      performanceReport?.result?.avgTicketPriceInclCompsNet
                    )
                  ) || "N/A"}
            </td>
            <td className="text-right">
              {" "}
              {id1
                ? Math.round(
                    performanceReport?.result?.avgTicketPriceInclCompsGross
                  ) || "N/A"
                : formatNumberAsCurrency(
                    Math.round(
                      performanceReport?.result?.avgTicketPriceInclCompsGross
                    )
                  ) || "N/A"}
            </td>
            <td colSpan={2}></td>
          </tr>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30 px-6">
            <td className="pl-2"> Concession Std Cost</td>
            <td className="text-right">
              {formatNumberAsCurrency(
                performanceReport?.result?.concessionStdNet?.toFixed(2)
              ) || "N/A"}
            </td>
            <td className="text-right">
              {formatNumberAsCurrency(
                performanceReport?.result?.concessionStdGross?.toFixed(2)
              ) || "N/A"}
            </td>
            <td className="pl-2">Avg ticket price not incl comps</td>
            <td className="text-right">
              {id1
                ? Math.round(
                    performanceReport?.result?.avgTicketPriceNotInclCompsNet
                  )
                : formatNumberAsCurrency(
                    Math.round(
                      performanceReport?.result?.avgTicketPriceNotInclCompsNet
                    )
                  )}
            </td>
            <td className="text-right">
              {id1
                ? Math.round(
                    performanceReport?.result?.avgTicketPriceNotInclCompsGross
                  )
                : formatNumberAsCurrency(
                    Math.round(
                      performanceReport?.result?.avgTicketPriceNotInclCompsGross
                    )
                  )}
            </td>
            <td colSpan={2}></td>
          </tr>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30 px-6">
            <td className="pl-2"> Seats in Cinema</td>
            <td className="text-right">
              {performanceReport?.result?.seatsInCinema}
            </td>
            <td className="text-right"></td>
            <td className="pl-2">Box revenue per seat</td>
            <td className="text-right">
              {" "}
              {id1
                ? Math.round(performanceReport?.result?.boxRevenuePerSeatNet) ||
                  "N/A"
                : formatNumberAsCurrency(
                    Math.round(performanceReport?.result?.boxRevenuePerSeatNet)
                  ) || "N/A"}
            </td>
            <td className="text-right">
              {id1
                ? Math.round(
                    performanceReport?.result?.boxRevenuePerSeatGross
                  ) || "N/A"
                : formatNumberAsCurrency(
                    Math.round(
                      performanceReport?.result?.boxRevenuePerSeatGross
                    )
                  ) || "N/A"}
            </td>
            <td colSpan={2}></td>
          </tr>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30 px-6">
            <td colSpan={3}></td>

            <td className="pl-2">Total revenue per seat</td>
            <td className="text-right">
              {id1
                ? Math.round(
                    performanceReport?.result?.totalRevenuePerSeatNet
                  ) || "N/A"
                : formatNumberAsCurrency(
                    Math.round(
                      performanceReport?.result?.totalRevenuePerSeatNet
                    )
                  ) || "N/A"}
            </td>
            <td className="text-right">
              {id1
                ? Math.round(
                    performanceReport?.result?.totalRevenuePerSeatGross
                  ) || "N/A"
                : formatNumberAsCurrency(
                    Math.round(
                      performanceReport?.result?.totalRevenuePerSeatGross
                    )
                  ) || "N/A"}
            </td>
            <td colSpan={2}></td>
          </tr>
        </tbody>
      </table>
      <p className="text-app-purple-4 text-center py-5 font-bold text-[20px] ">
        {" "}
        Box office sales table
      </p>
      <table
        className="table-auto w-full mx-auto mt-8 overflow-hidden text-left border rounded-md border-app-purple-4"
        id={id2}
      >
        <thead className="shadow-2xl bg-app-purple-8">
          <tr className="text-center">
            <Th>Film</Th>
            <Th>Opening Date</Th>
            <Th>Week</Th>
            <Th>Shows</Th>
            <Th>Admits</Th>
            <Th>Comps</Th>
            <Th>Average Perf</Th>
            <Th>Net</Th>
            <Th>Gross</Th>
          </tr>
        </thead>
        <tbody>
          {performanceReport?.result.movies.map((film, index) => {
            return (
              <tr
                key={index}
                className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30 px-6 text-center"
              >
                <td className="text-left px-2">{film.film}</td>
                <td className="text-left px-2">
                  {new Date(film.openingDate).toDateString()}
                </td>
                <td>{film.weekNumber}</td>
                <td>{film.shows}</td>
                <td>{film.admits}</td>
                <td>{film.complimentry}</td>
                <td className="text-left px-2">
                  {id2
                    ? Math.round(film.amount / film.shows)
                    : formatNumberAsCurrency(
                        Math.round(film.amount / film.shows)
                      )}
                </td>
                <td className="text-left px-2">
                  {id2 ? film.net : formatNumberAsCurrency(film.net.toFixed(2))}
                </td>
                <td className="text-left px-2">
                  {id2 ? film.amount : formatNumberAsCurrency(film.amount)}
                </td>
              </tr>
            );
          })}
          {performanceReport?.result.movies.length > 0 && (
            <tr className="transition duration-300 border-y-2  text-center  bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[16px] text-app-purple-4 font-bold ">
              <td colSpan={2} className="text-left px-2">
                Total
              </td>
              <td>{performanceReport?.result.total.weekNumber}</td>
              <td>{performanceReport?.result.total.shows}</td>
              <td>{performanceReport?.result.total.admits}</td>
              <td>{performanceReport?.result.total.complimentry}</td>
              <td className="text-left px-2">
                {id2
                  ? Math.round(
                      performanceReport?.result.total.amount /
                        performanceReport?.result.total.shows
                    )
                  : formatNumberAsCurrency(
                      Math.round(
                        performanceReport?.result.total.amount /
                          performanceReport?.result.total.shows
                      )
                    )}
              </td>
              <td className="text-left px-2">
                {id2
                  ? performanceReport?.result.total.net
                  : formatNumberAsCurrency(
                      performanceReport?.result.total.net.toFixed(2)
                    )}
              </td>
              <td className="text-left px-2">
                {id2
                  ? performanceReport?.result.total.amount
                  : formatNumberAsCurrency(
                      performanceReport?.result.total.amount
                    )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {performanceReport?.result.movies.length < 1 && (
        <p className="text-center py-5"> No data found</p>
      )}
      <p className="text-app-purple-4 text-center py-5 font-bold text-[20px] ">
        {" "}
        Box Office sundries table
      </p>
      <table
        className="table-auto w-full mx-auto mt-8 overflow-hidden text-left border rounded-md border-app-purple-4"
        id={id4}
      >
        <thead className="shadow-2xl bg-app-purple-8">
          <tr className="text-center">
            <Th>Item</Th>
            <Th>Quantity</Th>
            <Th>Net</Th>
            <Th>Gross</Th>
          </tr>
        </thead>
        <tbody>
          {performanceReport?.result.sundries.map((item, index) => {
            return (
              <tr
                key={index}
                className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30 px-6 text-center"
              >
                <td className="text-left px-2">{item.item}</td>
                <td>{item.quantity}</td>
                <td className="text-left px-2">
                  {id4 ? item.net : formatNumberAsCurrency(item.net.toFixed(2))}
                </td>
                <td className="text-left px-2">
                  {id4 ? item.amount : formatNumberAsCurrency(item.amount)}
                </td>
              </tr>
            );
          })}
          {performanceReport?.result.sundries.length > 0 && (
            <tr className="transition duration-300 border-y-2  text-center  bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[16px] text-app-purple-4 font-bold ">
              <td className="text-left px-2">Total</td>
              <td>
                {performanceReport?.result?.sundries?.reduce(
                  (prev, curr) => curr.quantity + prev,
                  0
                )}
              </td>
              <td className="text-left px-2">
                {id3
                  ? performanceReport?.result?.sundries?.reduce(
                      (prev, curr) => curr.net + prev,
                      0
                    )
                  : formatNumberAsCurrency(
                      performanceReport?.result?.sundries
                        ?.reduce((prev, curr) => curr.net + prev, 0)
                        .toFixed(2)
                    )}
              </td>
              <td className="text-left px-2">
                {id4
                  ? performanceReport?.result?.sundries?.reduce(
                      (prev, curr) => curr.amount + prev,
                      0
                    )
                  : formatNumberAsCurrency(
                      performanceReport?.result?.sundries
                        ?.reduce((prev, curr) => curr.amount + prev, 0)
                        .toFixed(2)
                    )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {performanceReport?.result.sundries.length < 1 && (
        <p className="text-center py-5"> No data found</p>
      )}
      <p className="text-app-purple-4 text-center py-5 font-bold text-[20px] ">
        {" "}
        Concession sales table
      </p>
      <table
        className="table-auto w-full mx-auto mt-8 overflow-hidden text-left border rounded-md border-app-purple-4"
        id={id3}
      >
        <thead className="shadow-2xl bg-app-purple-8">
          <tr className="text-center">
            <Th>Item</Th>
            <Th>Quantity</Th>
            <Th>Net</Th>
            <Th>Gross</Th>
          </tr>
        </thead>
        <tbody>
          {performanceReport?.result.concessions.map((item, index) => {
            return (
              <tr
                key={index}
                className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30 px-6 text-center"
              >
                <td className="text-left px-2">{item.item}</td>
                <td>{item.quantity}</td>
                <td className="text-left px-2">
                  {id3 ? item.net : formatNumberAsCurrency(item.net.toFixed(2))}
                </td>
                <td className="text-left px-2">
                  {id3 ? item.amount : formatNumberAsCurrency(item.amount)}
                </td>
              </tr>
            );
          })}
          {performanceReport?.result.concessions.length > 0 && (
            <tr className="transition duration-300 border-y-2  text-center  bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[16px] text-app-purple-4 font-bold ">
              <td className="text-left px-2">Total</td>
              <td>{performanceReport?.result?.concessionsTotal?.quantity}</td>
              <td className="text-left px-2">
                {id3
                  ? performanceReport?.result?.concessionsTotal?.net
                  : formatNumberAsCurrency(
                      performanceReport?.result?.concessionsTotal?.net.toFixed(
                        2
                      )
                    )}
              </td>
              <td className="text-left px-2">
                {id3
                  ? performanceReport?.result?.concessionsTotal?.amount
                  : formatNumberAsCurrency(
                      performanceReport?.result?.concessionsTotal?.amount
                    )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {performanceReport?.result.concessions.length < 1 && (
        <p className="text-center py-5"> No data found</p>
      )}
      {Object.keys(performanceReport?.result).length < 1 && (
        <p className="text-center py-3"> No Data Found</p>
      )}
    </div>
  );
};
export { PerformanceReportTable };
