import React from "react";
import { NavLink } from "react-router-dom";
import { ErrorWithWrapper, Loading, PageHeader, PrivateRoute, TaskTable } from "../../../components";
import BreadCrumb from "../../../components/BreadCrumbs/BreadCrumbs";
import tasks from "../../../utils/mockData/task.json" 
import { useDispatch, useSelector } from "react-redux";
import { getTasks, selectRoster } from "../../../redux/slices";
import { useCallback } from "react";
import { useEffect } from "react";
import { FETCH_STATUS } from "../../../utils";
import { useLoggedInuser } from "../../../hooks";

const StaffTasks = () => {
  const dispatch = useDispatch();
    const { profile } = useLoggedInuser();
  const { tasks, getTasksStatus, getTasksErr } = useSelector(selectRoster);
  
  
  const getInfo = useCallback(() => {
    let force = true
    dispatch(getTasks({circuitId:profile.circuitId}));
  }, [dispatch, profile.circuitId]);

  useEffect(() => {
    // if (getTasksStatus === FETCH_STATUS.IDLE ) {
    //   dispatch(getTasks());
    // }
    getInfo()
  }, [getInfo]);

  
  return (
    <>
      <PrivateRoute redirectTo="/dashboard/users/tasks">
        <div className="flex justify-end">
          <BreadCrumb />
        </div>
        <PageHeader>
          <h1 className=" text-white font-semibold text-2xl">Tasks</h1>
          <div className="flex justify-end space-x-4">
            <NavLink
              to="/dashboard/users/create-task"
              type="button"
              className="px-4 py-3 text-base font-medium bg-white rounded-md 
          text-app-purple-9"
            >
              Create Task
            </NavLink>
          </div>
        </PageHeader>
        <ErrorWithWrapper
            error={getTasksErr}
            show={getTasksStatus === FETCH_STATUS.REJECTED}
            retry={getInfo}
          />

        <div className="relative p-[19px] pb-[30px] bg-[#DDC4DD] min-h-[699px] rounded-b-lg">
           <Loading 
          wrapperClassName="absolute top-0 left-0 right-0 min-h-[50vh]"
          show={getTasksStatus === FETCH_STATUS.PENDING}
        />
          {getTasksStatus === FETCH_STATUS.RESOLVED ?
          <div className="w-full h-full bg-white rounded-[12px] py-[28px] px-[30px]">
            <TaskTable tasks={tasks?.pagedList ?? []} getInfo={ getInfo} />
          </div>:null
}
        </div>
      </PrivateRoute>
    </>
  );
};

export { StaffTasks };
