import React from "react";

const KioskConcessions = ({
  layoutMutateWithPageName,
  pageName,
  itemsMutateWithId,
}) => {
    return (
      
    <>
      <div className="grid grid-cols-5 gap-3 bg-purple-700 p-2">
        {layoutMutateWithPageName[pageName]?.pageItems?.map((item) => {
          return !item.itemId ||
            layoutMutateWithPageName[pageName]?.pageItems?.length < 1 ? (
            <p className="text-white p-4 grid items-center">
              No item available
            </p>
          ) : (
            <div className="p3 bg-white flex flex-col items-center">
              <p> {itemsMutateWithId[item.itemId]?.name}</p>
              <p> {itemsMutateWithId[item.itemId]?.price}</p>
            </div>
          );
        })}
        {layoutMutateWithPageName[pageName]?.pageItems?.length < 1 && (
          <p className="text-white p-4 grid items-center">No item available</p>
        )}
      </div>
    </>
  );
};

export { KioskConcessions };
