import React, {useRef} from "react";
import DatePicker from "react-datepicker";
import {
    ExportExcelBtn,
    PdfButton,
    Pdiv,
    PrivateRoute,
    ReportWrapper,
    StaffPerformanceTable,
    Success,
} from "../../../components";
import {useLoggedInuser} from "../../../hooks";
import {useDispatch, useSelector} from "react-redux";
import {getStaffPerformance, reportData} from "../../../redux/slices";
import {Controller, useForm} from "react-hook-form";
import {subDays} from "date-fns";
import {DateTimeInput} from "../../../components/Films/DateTimeInput";
import {Element, scroller} from "react-scroll";
import {FETCH_STATUS, formatDateForApi} from "../../../utils";

const StaffPerformance = () => {
  const { profile } = useLoggedInuser();
  const dispatch = useDispatch();
  const {
    staffPerformance,
    getStaffPerformanceStatus,
    getStaffPerformanceErr,
  } = useSelector(reportData);

  
  const reportRef = useRef(null);
  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({
    // resolver: yupResolver(bookingByGenreSchema),
    defaultValues: {
      dateFrom: subDays(new Date(), 7).toISOString(),
      dateTo: new Date().toISOString(),
      cinemaId: profile?.cinemaId,
    },
  });
  const cinemaId = watch("cinemaId");
 

  const handleFetchStaffPerformance = async (values) => {
    try {
      await dispatch(getStaffPerformance(values)).then(() => {
        scroller.scrollTo("reportContainer", {
          duration: 1500,
          delay: 0,
          smooth: true,
          offset: -100, // Scrolls to element + 50 pixels down the page
        });
      });
    } catch (error) {}
  };

  return (
    <>
      <PrivateRoute redirectTo={"/dashboard/reports/staff-performance"}>
        <div className="w-[95%] mx-auto mt-6">
          <ReportWrapper
            reportName="Staff Performance"
            goBackRoute={"/dashboard/reports"}
            submitFor={"staffPerformance"}
            reportStatus={getStaffPerformanceStatus}
          >
            <form
              onSubmit={handleSubmit(handleFetchStaffPerformance)}
              id={"staffPerformance"}
            >
              <Pdiv label={"Date Range"}>
                <div className="flex items-center">
                  <Controller
                    control={control}
                    name="dateFrom"
                    render={({ field: { value, onChange } }) => (
                      <div className="relative ">
                        <DatePicker
                          placeholderText="Start Date"
                          selected={new Date(value)}
                          onChange={(value) =>
                              onChange(formatDateForApi(new Date(value)))
                          }
                          customInput={
                            <DateTimeInput
                              className="bg-app-purple-8"
                              dateFormat={"EEE, MMM dd, yyyy"}
                            />
                          }
                        />
                      </div>
                    )}
                  />

                  <p className="w-[6rem] text-center"> To:</p>
                  <Controller
                    control={control}
                    name="dateTo"
                    render={({ field: { value, onChange } }) => (
                      <div className="relative ">
                        <DatePicker
                          placeholderText="End Date"
                          selected={new Date(value)}
                          onChange={(value) =>
                              onChange(formatDateForApi(new Date(value)))
                          }
                          customInput={
                            <DateTimeInput
                              className="bg-app-purple-8"
                              dateFormat={"EEE, MMM dd, yyyy"}
                            />
                          }
                        />
                      </div>
                    )}
                  />
                </div>
              </Pdiv>
            </form>
          </ReportWrapper>
        </div>
        <Success show={getStaffPerformanceStatus === FETCH_STATUS.RESOLVED}>
          <Element name="reportContainer">
            {staffPerformance?.result?.length > 0 ? (
              <>
                <div className="flex justify-center mt-5 space-x-4">
                  <PdfButton
                    refValue={reportRef}
                    docTitle={"staff-performance"}
                  />
                  <ExportExcelBtn
                    id={"staffPerformance-xlsx"}
                    name={"staffPerformance.xlsx"}
                  />
                </div>
                <div ref={reportRef} className="m-2">
                  <StaffPerformanceTable
                    data={staffPerformance?.result ?? []}
                    paramFilter={staffPerformance?.paramFilter}
                    
                  />
                </div>
                <div  className="m-2 hidden">
                  <StaffPerformanceTable
                    data={staffPerformance?.result ?? []}
                    paramFilter={staffPerformance?.paramFilter}
                    id={"staffPerformance-xlsx"}
                  />
                </div>
              </>
            ) : null}
          </Element>
          {!staffPerformance?.result?.length && (
            <p className="text-red-700 flex justify-center mt-3">
              No Data Found
            </p>
          )}
        </Success>
        {getStaffPerformanceStatus === FETCH_STATUS.REJECTED && (
          <p className="text-red-700 flex justify-center mt-3">
            Sorry an error occured...
          </p>
        )}
      </PrivateRoute>
    </>
  );
};

export { StaffPerformance };
