import {useMemo, useState} from "react";
import {
  AcceptanceConfirmation,
  AcceptanceSuccess,
  ApproveOrReject,
  ClientPaginatedTable,
  DefaultColumnFilter,
  PrivateRoute,
  RejectionConfirmation,
  RejectionReason,
  RejectionSuccess,
  Spinner,
} from "../../../../components";
import {DateTimeInput} from "../../../../components/Films/DateTimeInput";
import DatePicker from "react-datepicker";
import addDays from "date-fns/addDays";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getStockRequest, getStockRequests, selectUser, stockData,} from "../../../../redux/slices";

const ActionBtn = ({ label, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={
        "p-2 px-3 rounded-md text-white bg-app-purple-8 hover:font-bold"
      }
    >
      {label}
    </button>
  );
};

const StatusIndicator = ({ status }) => {
  const colourCode = {
    Completed: { txt: "text-[#007F00]", bg: "bg-[#CDFFCD]" },
    Approved: { txt: "text-[#007F00]", bg: "bg-[#CDFFCD]" },
    Rejected: { txt: "text-[#D30000]", bg: "bg-[#FFE0E0]" },
    Pending: { txt: "text-[#965E00]", bg: "bg-[#FFECCC]" },
    Draft: { txt: "text-[#4A4AFF]", bg: "bg-[#E6E6F2]" },
  };

  return (
    <div
      className={`flex items-center px-3 py-1 justify-center rounded-md ${colourCode[status]?.txt} ${colourCode[status]?.bg} `}
    >
      <span>{status}</span>
    </div>
  );
};

const StockReqOverview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { stockRequests, stockRequestsStatus, stockRequestErr, stockRequest } =
    useSelector(stockData);
  const {profile} = useSelector(selectUser)
  const [startDate, setStartDate] = useState(
    addDays(new Date(), -30).toISOString()
  );

  const [showAcceptanceConfirmation, setShowAcceptanceConfirmation] =
    useState(false);
  const [showAcceptanceSuccess, setShowAcceptanceSuccess] = useState(false);
  const [showRejectionConfirmation, setShowRejectionConfirmation] =
    useState(false);
  const [showRejectionSuccess, setShowRejectionSuccess] = useState(false);
  const [showRejectionReason, setShowRejectionReason] = useState(false);
  const [showApproveOrReject, setShowApproveOrReject] = useState(false);
  const [data, setData] = useState("");

  const [endDate, setEndDate] = useState(new Date().toISOString());
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      getStockRequests({ DateFrom: startDate, DateTo: endDate, force: true, cinemaId: profile.cinemaId })
    );
  };

  const stockRequestData = useMemo(() => {
    if (stockRequests?.length) {
      return stockRequests;
    } else {
      return [];
    }
  }, [stockRequests]);

  const columns = useMemo(() => {
    return [
      {
        Header: "Request Id",
        accessor: "id",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Requested Date",
        accessor: "requestDate",
        Cell: ({ value }) => {
          return new Date(value).toDateString();
        },
      },
      {
        Header: "Requested By",
        accessor: "requestedBy",
      },
      {
        Header: "Requisition Type",
        accessor: "requisitionType",
      },
      {
        Header: "Location",
        accessor: "location",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          return <StatusIndicator status={value} />;
        },
      },
      {
        Header: "Actions",

        Cell: ({ value, row }) => {
          return (
            <div className="flex justify-around">
              {" "}
              <ActionBtn
                label={"View"}
                onClick={() => {
                  navigate(
                    `/dashboard/stock/stockRequest/view/${row.original.id}`
                  );
                }}
              />
              {row.original.status === "Pending" && (
                <ActionBtn
                  label={"Approve/Reject"}
                  onClick={() => {
                    setShowApproveOrReject(true);
                    setData(row.original.id);
                    dispatch(
                      getStockRequest({ force: true, id: row.original.id })
                    );
                  }}
                />
              )}
            </div>
          );
        },
      },
    ];
  }, []);

  return (
    <PrivateRoute redirectTo="/dashboard/stock/stockRequest">
      <p className="font-bold px-3">Stock Requests</p>
      <div className="w-[95%] mx-auto mt-6 ">
        <p className="text-center text-[20px] p-3">Select Date Range</p>
        <form onSubmit={submitHandler}>
          <div className="flex justify-center">
            <div className=" pb-3.5 ">
              <label
                htmlFor={`value`}
                className="mb-2 text-md font-bold text-[#666666] "
              >
                From
              </label>
              <DatePicker
                placeholderText="Expiry Date"
                selected={new Date(startDate)}
                onChange={(value) => setStartDate(value.toISOString())}
                customInput={
                  <DateTimeInput
                    className="bg-app-purple-8 w-[8rem] md:w-[10rem]"
                    dateFormat={"EEE, MMM dd, yyyy"}
                    value={startDate}
                  />
                }
              />
            </div>
            <div className=" pb-3.5 ">
              <label
                htmlFor={`value`}
                className="mb-2 text-md font-bold text-[#666666] "
              >
                To
              </label>
              <DatePicker
                placeholderText="Expiry Date"
                selected={new Date(endDate)}
                onChange={(value) => setEndDate(value.toISOString())}
                customInput={
                  <DateTimeInput
                    className="bg-app-purple-8 w-[8rem] md:w-[10rem]"
                    dateFormat={"EEE, MMM dd, yyyy"}
                    value={endDate}
                  />
                }
              />
            </div>
          </div>
          <div className="flex justify-center">
            <button
              className=" mb-[30px] p-1 px-3 flex items-center justify-between rounded-md text-white bg-[#7E2080] shadow-sm hover:shadow-xl hover:text-white"
              type="submit"
            >
              Get Stock Requests
              <Spinner
                className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                status={stockRequestsStatus}
              />
            </button>
          </div>
        </form>
        <div className="flex items-center justify-end p-4 px-8 rounded-t-lg bg-gradient-to-b from-app-purple-4 to-app-purple-3">
          <div className="flex buttongroup">
            <button
              className="p-3 px-5 rounded-md text-[#C96FCC] bg-white shadow-md hover:shadow-sm"
              onClick={() => {
                navigate("/dashboard/stock/stockRequest/create");
              }}
            >
              Create
            </button>
          </div>
        </div>
        <div className="overflow-x-auto">
          <ClientPaginatedTable
            filterColumnsKeys={["code"]}
            //   toolbarChildren={}
            // onRowClick={onRowClick}
            tableData={stockRequestData}
            columns={columns}
            getHeaderProps={(column) => ({
              className:
                "px-3 pt-8 pb-2 text-base font-medium border border-[#00000033] ",
              ...(column.id === "film" ? column?.getSortByToggleProps() : {}),
            })}
            getCellProps={(cellInfo) => ({
              style: {},
              className: `${
                cellInfo?.column?.id === "film" ? "text-app-purple-9" : ""
              } ${cellInfo?.column?.id === "days" ? "break-all" : ""} `,
            })}
            getColumnProps={() => ({ className: "bg-app-purple-2", style: {} })}
          />
        </div>
      </div>
      <AcceptanceConfirmation
        show={showAcceptanceConfirmation}
        type={"Stock Request"}
        data={data}
        onClose={() => {
          setShowAcceptanceConfirmation(false);
        }}
        onConfirm={() => {
          setShowAcceptanceConfirmation(false);
          setShowAcceptanceSuccess(true);
        }}
      />

      <RejectionConfirmation
        show={showRejectionConfirmation}
        type={"Stock Request"}
        onClose={() => {
          setShowRejectionConfirmation(false);
        }}
        onReject={() => {
          setShowRejectionReason(true);
          setShowRejectionConfirmation(false);
        }}
      />
      <AcceptanceSuccess
        show={showAcceptanceSuccess}
        data={data}
        type={"Stock Request"}
        onClose={() => {
          setShowAcceptanceSuccess(false);
          setShowApproveOrReject(false);
        }}
      />
      <RejectionSuccess
        show={showRejectionSuccess}
        data={data}
        type={"Stock Request"}
        onClose={() => {
          setShowRejectionSuccess(false);
          setShowApproveOrReject(false);
        }}
      />
      <RejectionReason
        show={showRejectionReason}
        type={"Stock Request"}
        data={data}
        onClose={() => {
          setShowRejectionReason(false);
        }}
        onConfirm={() => {
          setShowRejectionSuccess(true);
          setShowRejectionReason(false);
        }}
      />
      <ApproveOrReject
        show={showApproveOrReject}
        type={"Stock Request"}
        data={stockRequest}
        onClose={() => {
          setShowApproveOrReject(false);
          setData({});
        }}
        approveFunc={() => {
          setShowAcceptanceConfirmation(true);
        }}
        declineFunc={() => {
          setShowRejectionConfirmation(true);
        }}
      />
    </PrivateRoute>
  );
};
export { StockReqOverview };
