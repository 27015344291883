import React from "react";

const Arrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="10"
      fill="none"
      viewBox="0 0 15 10"
    >
      <path
        fill="currentColor"
        d="M.75 10h13.5c.137 0 .271-.034.388-.097a.709.709 0 00.276-.261.609.609 0 00-.046-.69L8.118.27c-.28-.36-.953-.36-1.234 0L.134 8.952a.607.607 0 00-.048.69c.063.108.159.199.276.262A.82.82 0 00.75 10z"
      ></path>
    </svg>
  );
};

export { Arrow };
