import React, { useState, useMemo } from "react";

import Toolbar from "./Toolbar";
import DayView from "./views/DayView";
import WeekView from "./views/WeekView";
import Legend from "./Legend";

import { VIEW_MODES } from "../../utils";

const Scheduler = ({
  events = [],
  resources = [],
  renderEvent,
  renderResource,
  resourceIdentifier,
  eventGroupByKey,
  getInfo,
  showLegend,
  renderLegend,
  legendItems,
  toggleDuplicate,
  toggleCreate,
  toggleDeleteAll,
  dragItemType,
  onDrop,
  date,
  renderContextMenu = () => {},
}) => {
  const [mode, setMode] = useState(VIEW_MODES.DAY);
  const [activeDate, setActiveDate] = useState(
    date ? new Date(date) : new Date()
  );

  const props = useMemo(
    () => ({
      resources,
      events,
      renderEvent,
      renderResource,
      activeDate,
      resourceIdentifier,
      eventGroupByKey,
      renderContextMenu,
    }),
    [
      events,
      resources,
      resourceIdentifier,
      eventGroupByKey,
      renderEvent,
      renderResource,
      activeDate,
      renderContextMenu,
    ]
  );

  const onDateChange = (date) => {
    setActiveDate(date);
    getInfo(date);
  };

  return (
    <div className="bg-white rounded-lg px-2.5 py-1">
      <Toolbar
        setActiveDate={onDateChange}
        mode={mode}
        activeDate={activeDate}
        setMode={setMode}
        toggleCreate={toggleCreate}
        toggleDuplicate={toggleDuplicate}
        toggleDeleteAll={toggleDeleteAll}
      />

      {mode === VIEW_MODES.DAY ? (
        <DayView dragItemType={dragItemType} onDrop={onDrop} {...props} />
      ) : null}

      {mode === VIEW_MODES.WEEK ? <WeekView {...props} /> : null}

      {showLegend ? (
        <Legend renderLegend={renderLegend} legendItems={legendItems} />
      ) : null}
    </div>
  );
};

export { Scheduler };
