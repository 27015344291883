import { Combobox, Transition } from "@headlessui/react";
import classNames from "classnames";
import { matchSorter } from "match-sorter";
import React, { Fragment, useEffect, useState } from "react";
import { Caret, CheckIcon } from "../Icons";

const MultiSearchSelect = ({
  value,
  items = [],
  onChange,
  searchOptions,
  displayValue,
  inputClassName = "",
  listClassName = "",
  placeholder = "",
  id = "",
  itemNameRender = "",
  iconClass = "",
  ItemRender,
  children,
}) => {
  const [selected, setSelected] = useState(value ?? []);
  const [query, setQuery] = useState("");
  useEffect(() => {
    if (value) {
      setSelected(value);
    }
  }, [value]);

  const handleSelect = (value) => {
    setSelected(value);
    onChange(value);
  };

  const filteredItems =
    query === "" ? items : matchSorter(items, query, searchOptions);

  return (
    
      <div className="relative">
        <Combobox multiple onChange={handleSelect} value={selected}>
          <div className="relative">
            <Combobox.Input
              placeholder={placeholder}
              id={id}
              className={`bg-[#d0a3d3] inline-block text-pry p-2 w-full rounded-md text-base disabled:opacity-50 disabled:cursor-not-allowed outline-none transition-colors ${inputClassName}`}
              displayValue={displayValue}
              onChange={(event) => setQuery(event.target.value)}
            />
            <label htmlFor={id} className="sr-only">
              {placeholder || id}
            </label>
            <Combobox.Button className="absolute right-1 text-app-gray-3 flex items-center pr-2 top-5 -translate-y-2/4">
              <Caret />
              <span className="sr-only">toggle dropdown </span>
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options
              className={`absolute mt-1 mb-4 max-h-60 w-full overflow-auto z-[1] rounded-md bg-[#d0a3d3] p-2 text-base shadow-lg ring-1 ring-pry ring-opacity-5 focus:outline-none sm:text-sm ${listClassName}`}
            >
              {/* {loadingStatus === FETCH_STATUS.PENDING && "Loading..."} */}
              {filteredItems?.length === 0 && query !== "" ? (
                <div className="relative px-4 py-2 text-gray-700 cursor-default select-none">
                  Nothing found.
                </div>
              ) : (
                filteredItems?.map((item) => (
                  <Combobox.Option
                    key={item.id ?? item}
                    value={item}
                    className={({ active }) =>
                      `relative cursor-default select-none p-2 ${
                        active ? "bg-app-purple-2 text-white" : "text-gray-900"
                      } 
                                `
                    }
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "ml-3 block truncate"
                            )}
                          >
                            {item.name
                              ? item.name
                              : itemNameRender
                              ? item[itemNameRender]
                              : item.firstName
                              ? `${item.firstName} ${item.lastName}`
                              : item}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </Combobox>
      </div>
    
  );
};

export { MultiSearchSelect };
