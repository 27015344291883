import { useDispatch, useSelector } from "react-redux"
import { getRoster, getShifts, getStaff, getTasks, selectRoster, selectUser } from "../redux/slices"
import { useCallback, useEffect,useReducer, useState } from "react"
import { FETCH_STATUS } from "../utils"
import { useLoggedInuser } from "./useLoggedInuser"
import { endOfWeek, startOfWeek } from "date-fns"
import { getAllRoster, getScheduledRoster, setActiveDate, setActiveRosterDate } from "../redux/slices/rosterSlice"


const init = {
    shiftsStatus: FETCH_STATUS.IDLE,
    shiftsErr: null,
    tasksStatus: FETCH_STATUS.IDLE,
    taskErr: null,
    tasks:{},
    shifts:{}
}

const actions = {
    getShiftResolved: "getShiftResolved",
    getTaskResolved: "getTaskResolved",
    reset: "reset"
}

const  reducer = (state, action)=> { 
    switch (action.type) {
        case actions.getShiftResolved:
            return {
                ...state,
                shiftsStatus: FETCH_STATUS.RESOLVED,
                shifts: action.payload,
                shiftsErr:null
            }
        case actions.getTaskResolved:
            return {
                ...state,
                tasksStatus: FETCH_STATUS.RESOLVED,
                tasks:action.payload
            }
        case actions.reset:
            return { ...init }
        default:
            return state
    }
}



const useRosterScheduling = () => {
    const reduxDispatch = useDispatch()
    const [state, dispatch] = useReducer(reducer, init)

    const {activeDate, tasks, getTasksStatus, getTasksErr, shifts, getShiftsStatus, getShiftsErr, allRoster,getAllRosterStatus, getAllRosterErr,getScheduledRosterErr,getScheduledRosterStatus,scheduledRoster } = useSelector(selectRoster)
    const { staff, staffStatus, staffErr } = useSelector(selectUser)
    const [cinemaId, setCinemaId] =  useState("")
    const {profile} = useLoggedInuser()

    useEffect(() => {
        if (getShiftsStatus === FETCH_STATUS.IDLE) {
            reduxDispatch(getShifts({circuitId:profile?.circuitId}))
        }

        if (getTasksStatus === FETCH_STATUS.IDLE) {
            reduxDispatch(getTasks({ circuitId: profile?.circuitId }));
        }

        if (staffStatus === FETCH_STATUS.IDLE) {
            reduxDispatch(getStaff({ force: true, cinemaId: profile?.cinemaId }))
            
        }
        if (profile?.cinemaId) {
            setCinemaId(profile?.cinemaId)
        }

        // if (getAllRosterStatus === FETCH_STATUS.IDLE) {
        // reduxDispatch(getAllRoster())
        // }
        if (getScheduledRosterStatus === FETCH_STATUS.IDLE) {
            reduxDispatch(getScheduledRoster())
        }

    },[getAllRosterStatus, getScheduledRosterStatus, getShiftsStatus, getTasksStatus, profile?.cinemaId, profile?.circuitId, reduxDispatch, staffStatus])
    
    useEffect(() => {
        if (getShiftsStatus === FETCH_STATUS.RESOLVED && shifts?.pagedList?.length) {
            dispatch({
                type: actions.getShiftResolved,
                payload: [...shifts.pagedList].reduce((shiftAcc, shift) => {
                    shiftAcc[shift.id] = shift
                    return shiftAcc
                },{})
            })
        }
    }, [dispatch, getShiftsStatus, shifts])
    
    useEffect(() => {
        if (getTasksStatus === FETCH_STATUS.RESOLVED && tasks?.pagedList?.length) {
             
            dispatch({
                type: actions.getTaskResolved,
                payload: [...tasks.pagedList].reduce((taskAcc, task) => {
                    taskAcc[task.id] = task
                    return taskAcc
                },{})
            })
        }
    },[getTasksStatus, tasks.pagedList])

   


    const fetchRoster = useCallback((force, date = new Date()) => {
       
        const oldStart = new Date(activeDate)
        const start = startOfWeek(new Date(date)).toISOString()
        const end = endOfWeek(new Date(date)).toISOString()
        const body = {
            dateFrom: start,
            dateTo: end,
            includeAllStaff:true
        }
        
        const newDate = new Date(date).toISOString()

        if (force || oldStart !== start) {
            reduxDispatch(getScheduledRoster(body))
        }
        reduxDispatch(setActiveRosterDate(newDate))

    }, [activeDate, reduxDispatch])


    // const fetchAllRoster = useCallback(() => {
    
    //   reduxDispatch(getAllRoster())

    // }, [reduxDispatch])
    

    const fetchTasks = useCallback(() => {
        reduxDispatch(getTasks({circuitId:profile?.circuitId}))
    }, [profile?.circuitId, reduxDispatch])
    
    const fetchShifts = useCallback(() => {
       reduxDispatch(getShifts({ circuitId: profile?.circuitId })); 
    }, [profile?.circuitId, reduxDispatch])

    const fetchStaffs = useCallback(() => {
        reduxDispatch(getStaff({force:true, cinemaId:profile?.cinemaId}))
    },[profile?.cinemaId, reduxDispatch])

    // const addRosterSession = useCallback((data) => {
    //    reduxDispatch(createRoster(data))
    // }, [reduxDispatch])
    
    // const updateRosterSession = useCallback((id,data) => {
    //     reduxDispatch(editRoster(id,data))
    // }, [reduxDispatch])
    
    // const deleteRosterSession = useCallback((id) => {
    //     reduxDispatch(deleteRoster(id))
    // },[reduxDispatch])


    return {
        state: {
            ...state,
            staff,
            staffStatus,
            staffErr,
            getShiftsErr,
            getShiftsStatus,
            getTasksErr,
            getTasksStatus,
            // rosterSession: roster,
            rosterSession: scheduledRoster?.pagedList,
            rosterSessionErr: getScheduledRosterErr,
            rosterSessionStatus: getScheduledRosterStatus,
            // rosterSession: allRoster?.pagedList,
            // rosterSessionErr: getAllRosterErr,
            // rosterSessionStatus: getAllRosterStatus,
            cinemaId:cinemaId

        },
        fetchRoster: fetchRoster,
        // getRosters: fetchAllRoster,
        getShifts: fetchShifts,
        getTasks: fetchTasks,
        getStaffs: fetchStaffs,
        // addRosterSession,
        // updateRosterSession,
        // deleteRosterSession
    }

}

export {useRosterScheduling}