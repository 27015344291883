import { stockData } from "../../redux/slices";
import { useSelector } from "react-redux";
import { formatNumberAsCurrency } from "../../utils";
import ReachLogo from "../../assets/images/logo.png"
const PurchaseOrderLayout = ({ type, data }) => {
  const { purchaseOrder } = useSelector(stockData);
  return (
    <div>
       <div
        key={ReachLogo}
        className=" ml-[-5px] w-[5rem] h-[3rem] bg-no-repeat bg-contain bg-center hidden print-source print:block"
        style={{
          backgroundImage: `url("${ReachLogo}")`,
        }}
      ></div>
      <p className="font-bold">{`${
        type === "stockRequest" ? "Stock Request" : "Purchase Order"
      }`}</p>
      <div className="flex justify-between text-[#8B8D97]">
        <div className="flex mr-5">
          <div className="w-[180px]">
            <p className="py-2">Purchase Order Id:</p>
            <p className="py-2">Requisition Id:</p>
            <p className="py-2">Vendor:</p>
            <p className="py-2">Currency:</p>
          </div>
          <div>
            <p className="py-2">{purchaseOrder?.id}</p>
            <p className="py-2">{purchaseOrder?.stockRequestId}</p>
            <p className="py-2">
              {purchaseOrder?.vendor ? purchaseOrder?.vendor : "No data"}
            </p>
            <p className="py-2">
              {purchaseOrder?.currency ? purchaseOrder?.currency : "No data"}
            </p>
          </div>
        </div>
        <div>
          <div className="flex ">
            <div className="w-[180px]">
              <p className="py-2">Confirmation Date:</p>
              <p className="py-2">Effective Date:</p>
              <p className="py-2">Purchase Agreement:</p>
            </div>
            <div>
              <p className="py-2">
                {purchaseOrder?.confirmationDate
                  ? new Date(purchaseOrder?.confirmationDate).toLocaleString()
                  : "No Data"}
              </p>
              <p className="py-2">
                {purchaseOrder?.effectiveDate
                  ? new Date(purchaseOrder?.effectiveDate).toLocaleString()
                  : "No Data"}
              </p>
              <p className="py-2">
                {purchaseOrder?.purchaseAgreement
                  ? purchaseOrder?.purchaseAgreement
                  : "No data"}
              </p>
            </div>
          </div>
        </div>
      </div>
      <table className="w-full mt-8 ">
        <thead>
          <tr className="border-y-2 ">
            <th className="py-3 text-left ">Item Name</th>
            <th className="text-left">Description</th>{" "}
            <th className="text-left">Unit of Measure</th>{" "}
            <th className="text-left">QUoM</th>{" "}
            <th className="text-center">Qty Desired</th>
            <th className="text-center">Unit Price</th>
            <th className="text-center">Tax</th>
            <th className="text-center">Sub total</th>
            <th className="text-center">Total</th>
          </tr>
        </thead>
        <tbody>
          {purchaseOrder?.items?.map((item) => {
            return (
              <tr>
                <td className="py-2">{item?.item}</td>
                <td>{item?.description}</td>
                <td>{item?.unitOfMeasurement}</td>
                <td className="text-center">{item?.quantityUOM}</td>
                <td className="text-center">{item?.quantity}</td>
                <td className="text-center">{item?.unitPrice}</td>
                <td className="text-center">{item?.tax}</td>
                <td className="text-center">
                  {formatNumberAsCurrency(item?.subTotal)}
                </td>
                <td className="text-center">
                  {formatNumberAsCurrency(item?.total)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export { PurchaseOrderLayout };
