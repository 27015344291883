import React, { Fragment, forwardRef, useImperativeHandle, useState } from "react";
import { FETCH_STATUS, capitalizeFirstLetter } from "../../utils";
import { Transition } from "@headlessui/react";
import classNames from "classnames";
import { DismissIcon } from "../Icons";

const Alert = forwardRef(
  (
    {
      type = "error",
      className = "",
      dismissable = false,
      hidePreText = false,
      preTextClass = "",
      contentClass = "",
      buttonClass = "",
      children,
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    
    
    useImperativeHandle(ref, () => {
      return {
        open: () => setIsOpen(true),
        close: () => setIsOpen(false),
        getIsOpen: () => isOpen,
      };
    });

    return (
      <Transition
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
        appear
        show={isOpen}
        as={Fragment}
      >
        <div
          className={classNames(
            "p-2 text-sm rounded-lg text-left flex items-center",
            {
              ["bg-rose-300 text-rose-600"]: type === "error",
              ["bg-green-300 text-green-800"]: type === "success",
              ["text-blue-700 bg-blue-200"]: type === "info",
              ["text-yellow-700 bg-yellow-200"]: type === "warn",
            },
            className
          )}
          role="alert"
        >
          <span
            className={classNames(
              "font-semibold italic ",
              {
                ["hidden"]: hidePreText,
              },
              preTextClass
            )}
          >
            {capitalizeFirstLetter(type)}
          </span>
          <div
            className={classNames("inline-block ml-2.5 flex-1", contentClass)}
          >
            {children}
          </div>
          {dismissable ? (
            <button
              onClick={() => setIsOpen(false) }
              type="button"
              className={classNames(
                "p-1 rounded-lg inline-block h-8 w-8 transition-colors self-start",
                {
                  ["hover:bg-rose-400 text-rose-600"]: type === "error",
                  ["hover:bg-green-600 text-green-800"]: type === "success",
                  ["text-blue-700 hover:bg-blue-300"]: type === "info",
                  ["text-yellow-700 hover:bg-yellow-300"]: type === "warn",
                },
                buttonClass
              )}
            >
              <DismissIcon />
              <span className="sr-only">Close</span>
            </button>
          ) : null}
        </div>
      </Transition>
    );
  }
);

// Alert.displayName = "Alert";


export {Alert};
