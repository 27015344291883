import {
  PrivateRoute,
  ReportLi,
  GenerateBtn,
  ReportTitle,
} from "../../../components";
import { useNavigate } from "react-router-dom";
import { DefaultSearchField } from "../../../components/UI/DefaultSearchField";
import { ReportLists } from "../../../utils";
import { useState, useMemo } from "react";

const ReportsList = () => {
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };
  const ReportList = useMemo(() => ReportLists, []);

  const filteredReport =
    searchValue?.trim() === ""
      ? ReportList
      : ReportList?.filter((report) => {
          return report.name.toLowerCase().includes(searchValue?.toLowerCase());
        });
  const navigate = useNavigate();
  return (
    <PrivateRoute redirectTo={`/dashboard/reports`}>
      <div className=" w-[60%] mx-auto mt-6">
        <div className="flex items-center justify-between p-4 px-8 rounded-t-lg bg-gradient-to-b from-app-purple-4 to-app-purple-3">
          <div className="flex gap-4">
            <span className="text-xl font-semibold text-white">Reports</span>
          </div>
          <button
            className="p-1 px-3 rounded-md text-[#C96FCC] bg-white shadow-md hover:font-bold"
            type="button"
            onClick={() => {
              navigate("/dashboard/reports/scheduledReports");
            }}
          >
            Scheduled Reports
          </button>
        </div>
        <div className="my-3">
          <DefaultSearchField
            onChange={handleSearch}
            value={searchValue}
            placeholder={"Search reports..."}
          />
        </div>
        <ul className=" bg-app-purple-2 rounded-b-lg pb-3">
          {filteredReport.map((report, i) => (
            <ReportLi key={i}>
              <ReportTitle>{report.name}</ReportTitle>{" "}
              <GenerateBtn
                onclick={() => {
                  navigate(report.route);
                }}
              />
            </ReportLi>
          ))}
        </ul>
      </div>
    </PrivateRoute>
  );
};
export { ReportsList };
