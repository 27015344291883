import { Fragment, useState, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { useDispatch, useSelector } from "react-redux";
import {
  getShowtimesPerDay,
  deleteSelectedShowtimes,
  selectShowTimes,
} from "../../redux/slices";
import { startOfDay, endOfDay } from "date-fns";
import { useRequest } from "../../hooks";
import { SuccessToast } from "./SuccessToast";
import { Spinner } from "../UI";
import { API_URL, axiosApiInstance, FETCH_STATUS } from "../../utils";

const getOldSessions = async ({ daysArray = [], dispatch }) => {
  const promises = daysArray?.map(async (x) => {
    const data = await dispatch(
      getShowtimesPerDay({
        start: startOfDay(new Date(x)).toISOString(),
        end: endOfDay(new Date(x)).toISOString(),
      })
    ).unwrap();
    const formattedData = data?.map((d) => {
      return {
        id: d.id,
        startTime: d.startTime,
        seatsSold: d.seatsSold,
      };
    });

    return formattedData;
  });
  const oldSession = await Promise.all(promises);
  return oldSession.flat();
};

const DeleteAllSessions = ({ openStatus, closeFunc }) => {
  const [selectedDays, setSelectedDays] = useState([]);
  const [dateWithSales, setDateWithSales] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const datePickerRef = useRef();
  const dispatch = useDispatch();
  const { status, setResolved, setPending, setRejected, resetErr } =
    useRequest();
  const { showTimesPerDayStatus } = useSelector(selectShowTimes);

  const deleteSessions = async () => {
    const sessionList = await getOldSessions({
      daysArray: selectedDays,
      dispatch,
    });

    const sessionWithSales = sessionList.find((x) => x.seatsSold > 0);

    if (sessionWithSales) {
      setDateWithSales(
        new Date(sessionWithSales?.startTime).toLocaleDateString("en-us", {
          weekday: "long",
          year: "numeric",
          month: "short",
          day: "numeric",
        })
      );
      return;
    } else {
      setDateWithSales("");
      const arrayOfIds = sessionList?.map((x) => {
        return x.id;
      });

      const body = {
        showtimeIds: arrayOfIds,
      };

      try {
        setPending();

        await axiosApiInstance.delete(`${API_URL}/Showtimes/BulkDelete`, {
          data: body,
        });

        try {
          dispatch(deleteSelectedShowtimes(arrayOfIds));
        } catch (error) {
          
        }

        setShowSuccess(true);

        setResolved();
      } catch (error) {
        setRejected(error);
      }
    }
  };

  return (
    <Transition appear show={openStatus} as={Fragment}>
      <Dialog as="div" className=" z-[300000]" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Delete Sessions In a Day(s)
                </Dialog.Title>
                <p className="italic text-sm py-2 text-center font-light">
                  {" "}
                  Ensure you select only days where no sales have been made on
                  any session
                </p>

                <DatePicker
                  value={selectedDays}
                  placeholder="Select Dates"
                  ref={datePickerRef}
                  onClick={() => {
                    datePickerRef.current.openCalendar();
                  }}
                  onChange={setSelectedDays}
                  plugins={[<DatePanel />]}
                  multiple
                  portal
                  calendarPosition="left"
                  //   className="z-[5000000000]  relative "
                >
                  <div className="flex justify-center py-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => {
                        datePickerRef.current.closeCalendar();
                      }}
                    >
                      Close
                    </button>
                  </div>
                </DatePicker>
                {dateWithSales && (
                  <p className="text-red-500 italic text-center py-3">
                    {`Seems a session on ${dateWithSales}  already has some sales`}
                  </p>
                )}
                <div className="mt-4 flex justify-around">
                  <button
                    type="submit"
                    className="inline-flex justify-center items-center rounded-md border border-transparent bg-red-700 px-4 py-2 text-sm font-medium text-white mr-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={() => {
                      closeFunc();
                      setDateWithSales("");
                      setSelectedDays([]);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={() => {
                      deleteSessions();
                    }}
                  >
                    Delete Sessions
                    <Spinner
                      className="!w-3 !h-3 mx-1 text-white fill-amber-300"
                      status={
                        status === FETCH_STATUS.PENDING ||
                        showTimesPerDayStatus === FETCH_STATUS.PENDING
                          ? FETCH_STATUS.PENDING
                          : ""
                      }
                    />
                  </button>
                </div>
                <SuccessToast
                  show={showSuccess}
                  handleClose={() => {
                    closeFunc();
                    setShowSuccess(false);
                    setSelectedDays([]);
                  }}
                  message={"Sessions deleted successfully"}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default DeleteAllSessions;
