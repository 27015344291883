import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  API_URL,
  axiosApiInstanceGet,
  FETCH_STATUS,
  addItemDirect,
  editItemDirect,
  PriceCard,
  onReturnReject,
} from "../../utils";

export const getPriceCards = createAsyncThunk(
  "getPriceCards",
  async (force, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/PriceCards/ListAll`,
        force
      );

      const data = response?.data?.data.map((d) => PriceCard.toUI(d));

      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(onReturnReject(error));
    }
  }
);

const initialState = {
  priceCards: [],
  priceCardsStatus: FETCH_STATUS.IDLE,
  priceCardsErr: null,
};

export const priceCardsSlice = createSlice({
  name: "priceCards",
  initialState,
  reducers: {
    addCard: (state, action) => {
      state.priceCards = addItemDirect(state, action, "priceCards"); //addItem(state, action, "priceCards");
    },
    editCard: (state, action) => {
      editItemDirect(state, action, "priceCards");
      //editItem(state, action, "priceCards");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPriceCards.pending, (state) => {
        state.priceCardsStatus = FETCH_STATUS.PENDING;
        state.priceCardsErr = "";
      })
      .addCase(getPriceCards.fulfilled, (state, action) => {
        state.priceCardsStatus = FETCH_STATUS.RESOLVED;
        state.priceCards = action.payload;
        state.priceCardsErr = "";
      })
      .addCase(getPriceCards.rejected, (state, action) => {
        state.priceCardsStatus = FETCH_STATUS.REJECTED;
        state.priceCardsErr = action.payload || "Unable to fetch price cards";
      });
  },
});

export const priceCardsReducer = priceCardsSlice.reducer;
export const selectPiceCards = (state) => {
  return {
    priceCards: state.priceCards.priceCards,
    activePriceCards: state.priceCards.priceCards.filter((p) => p.isActive),
    priceCardsStatus: state.priceCards.priceCardsStatus,
    priceCardsErr: state.priceCards.priceCardsErr,
  };
};
export const { addCard, editCard } = priceCardsSlice.actions;
