import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  Loading,
  PageHeader,
  AllTicketsTable,
  PrivateRoute,
  ErrorWithWrapper,
} from "../../../../components";
import { selectTickets, getTickets } from "../../../../redux/slices";
import { FETCH_STATUS } from "../../../../utils";

const AllTickets = () => {
  const dispatch = useDispatch();
  const { tickets, ticketsStatus, ticketsErr } = useSelector(selectTickets);

  const getInfo = useCallback(
    (force) => {
      //config = {force:false,page}
      dispatch(getTickets(force));
    },
    [dispatch]
  );

  useEffect(() => {
    if (ticketsStatus === FETCH_STATUS.IDLE) {
      dispatch(getTickets());
    }
  }, [dispatch, ticketsStatus]);

  return (
    <PrivateRoute redirectTo="/dashboard/tickets">
      <PageHeader>
        <h1 className=" text-white font-semibold text-2xl items-center gap-3.5 grid grid-cols-[34px_minmax(0,1fr)]">
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="24"
              fill="none"
              viewBox="0 0 30 24"
            >
              <path
                fill="#fff"
                d="M15.654 0c.488 0 .884.385.884.86v3.607c0 .578.493 1.057 1.088 1.057.609 0 1.088-.479 1.088-1.057V.86c0-.475.396-.86.885-.86h4.729C27.46 0 30 2.466 30 5.51v3.552c0 .282-.116.55-.32.747-.202.197-.478.31-.768.31-1.073 0-1.944.845-1.944 1.874 0 1.036.86 1.877 1.924 1.888.599.006 1.108.412 1.108.994v3.615c0 3.042-2.539 5.51-5.672 5.51h-4.73a.872.872 0 01-.884-.86v-3.044c0-.592-.479-1.057-1.088-1.057-.595 0-1.088.465-1.088 1.057v3.044c0 .475-.396.86-.884.86H5.672C2.553 24 0 21.534 0 18.49v-3.615c0-.582.51-.988 1.108-.994 1.065-.01 1.924-.852 1.924-1.888 0-1-.841-1.762-1.944-1.762-.29 0-.566-.112-.769-.31A1.04 1.04 0 010 9.174v-3.65C0 2.486 2.559 0 5.687 0h9.967zm1.972 8.554c-.595 0-1.088.48-1.088 1.057v5.637c0 .578.493 1.057 1.088 1.057.609 0 1.088-.479 1.088-1.057V9.611c0-.578-.479-1.057-1.088-1.057z"
              ></path>
            </svg>
          </span>
          <span>Tickets</span>
        </h1>

        <NavLink
          to="/dashboard/tickets/create"
          type="button"
          className="px-4 py-3 text-base font-medium bg-white rounded-md text-app-purple-9"
        >
          Create Ticket
        </NavLink>
      </PageHeader>

      <ErrorWithWrapper
        error={ticketsErr}
        show={ticketsStatus === FETCH_STATUS.REJECTED}
        retry={getInfo}
      />

      <Loading
        wrapperClassName="relative min-h-[50vh]"
        show={ticketsStatus === FETCH_STATUS.PENDING}
      />

      {tickets?.length ? <AllTicketsTable tickets={tickets} /> : null}
    </PrivateRoute>
  );
};

export { AllTickets };
