import { Menu } from "@headlessui/react";
import { Portal } from "../UI";
import { Delete, Edit, Eye } from "../Icons";

const RosterContextMenu = ({
  bindMenuItem,
  bindMenu,
  event,
  handleUpdate = () => {},
  handleDelete = () => {},
  handleView = () => {},
  hideMenu,
}) => {
  const onClick = (callback) => () => {
    callback(event);
    hideMenu();
  };

  return (
    <Portal>
      <div></div>
      <Menu
        className="flex items-center justify-center max-w-xs"
        as="div"
        {...bindMenu}
      >
        <div>
          <Menu.Items
            static
            className="min-w-[150px] bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <div className="px-1 py-1 ">
              <Menu.Item {...bindMenuItem}>
                {({ active }) => (
                  <button
                    onClick={onClick(handleView)}
                    className={`${
                      active ? "bg-violet-500 text-white" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm space-x-4`}
                  >
                    <Eye />
                    <span>View</span>
                  </button>
                )}
              </Menu.Item>
            </div>

            <div className="px-1 py-1 ">
              <Menu.Item {...bindMenuItem}>
                {({ active }) => (
                  <button
                    onClick={onClick(handleUpdate)}
                    className={`${
                      active ? "bg-violet-500 text-white" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm space-x-4`}
                  >
                    <Edit />
                    <span>Edit</span>
                  </button>
                )}
              </Menu.Item>
            </div>

            <div className="px-1 py-1 ">
              <Menu.Item {...bindMenuItem}>
                {({ active }) => (
                  <button
                    onClick={onClick(handleDelete)}
                    className={`${
                      active ? "bg-violet-500 text-white" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm space-x-4`}
                  >
                    <Delete />
                    <span>Delete</span>
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </div>
      </Menu>
    </Portal>
  );
};

export { RosterContextMenu };
