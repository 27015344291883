import { useMemo, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { reportData, getAdminActionsByUser } from "../../redux/slices";
import { ClientPaginatedTable, ErrorWithWrapper, Loading, Success } from "..";

import { FETCH_STATUS } from "../../utils";
import { TransactionDetailsModal } from "./TransactionDetailsModal";
const AdminActionsByUserTable = ({ funcProps }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [orderData, setOrderData] = useState({});

  const {
    adminActionsByUser,
    adminActionsByUserStatus,
    adminActionsByUserErr,
  } = useSelector(reportData);

  const getInfo = useCallback(
    (force) => {
      dispatch(getAdminActionsByUser(funcProps));
    },
    [dispatch]
  );

  const columns = useMemo(() => {
    return [
      {
        Header: "Name of User",
        accessor: "onBehalfOf",
        Cell: ({ value }) => {
          return `${value.lastName}  ${value.firstName}`;
        },
      },
      {
        Header: "Name of Admin",
        accessor: "doneBy",
        Cell: ({ value }) => {
          return `${value.lastName}  ${value.firstName}`;
        },
      },
      {
        Header: "Action",
        accessor: "action",
      },
      {
        Header: "Time",
        accessor: "dateCreated",
        Cell: ({ value }) => new Date(value).toLocaleTimeString(),
      },
    ];
  }, []);

  const onRowClick = (row) => () => {
    // setOrderData(row.original);
    // setShowModal(true);
  };

  return (
    <>
      <div className=" mt-6">
        <ErrorWithWrapper
          error={adminActionsByUserErr}
          show={adminActionsByUserStatus === FETCH_STATUS.REJECTED}
          retry={getInfo}
        />

        <Loading
          wrapperClassName="relative min-h-[50vh]"
          show={adminActionsByUserStatus === FETCH_STATUS.PENDING}
        />

        <Success show={adminActionsByUserStatus === FETCH_STATUS.RESOLVED}>
          {/* Main Table */}
          <ClientPaginatedTable
            filterColumnsKeys={["id"]}
            onRowClick={onRowClick}
            tableData={adminActionsByUser}
            columns={columns}
            getHeaderProps={(column) => ({
              className:
                "px-3 pt-8 pb-2 text-base font-medium border border-[#00000033] ",
              ...(column.id === "name" ? column?.getSortByToggleProps() : {}),
            })}
            getCellProps={(cellInfo) => ({
              style: {},
              className: `${
                cellInfo?.column?.id === "" ? "text-app-purple-9" : ""
              } ${cellInfo?.column?.id === "days" ? "break-all" : ""} `,
            })}
            getColumnProps={() => ({ style: {} })}
          />
        </Success>
        {showModal && (
          <TransactionDetailsModal
            funcProps={funcProps}
            orderData={orderData}
            show={showModal}
            onClose={() => {
              setShowModal(false);
              setOrderData({});
            }}
          />
        )}
      </div>
    </>
  );
};

export { AdminActionsByUserTable };
