import React, {forwardRef, useCallback, useState} from "react";
import {formatDateTime, VIEW_MODES} from "../../utils";
import DatePicker from "react-datepicker";
import { Calendar } from "../Icons";
import { Icon } from "@iconify/react";
import { addDays, nextMonday, nextSunday, previousSunday } from "date-fns";
import { subDays } from "date-fns";
import { previousMonday } from "date-fns";
import { useFetchSchedule } from "../../hooks/useFetchSchedule";
import dayjs from "dayjs";

const getBtnClass = (isActive) =>
  isActive ? "bg-app-purple-5 text-app-purple-6" : "text-app-black bg-white";

const CustomInput = forwardRef((props, ref) => {
  const { value, onChange, onClick } = props;

  return (
    <>
      <input
        value={formatDateTime(new Date(value), "EEEE, MMM dd, yyyy")}
        onChange={onChange}
        onClick={onClick} //to trigger calenda open
        className="block w-full text-center bg-transparent  px-5 mr-1 py-[18px]"
        ref={ref}
      />
    </>
  );
});

const Toolbar = ({
  setActiveDate,
  mode,
  activeDate,
  setMode,
  toggleCreate,
  toggleDuplicate,
  toggleDeleteAll,
}) => {
  const { isCanal } = useFetchSchedule();
  const handleClick = (mode) => () => setMode(mode);
  const [activeDay, setActiveDay] = useState(activeDate);
  
  const handlePreviousWeek = useCallback(() => {
    const date = isCanal
      ? previousMonday(activeDay)
      : previousSunday(activeDay);
    setActiveDate(date);
    setActiveDay(date);
  }, [activeDay, isCanal, setActiveDate]);

  const handleNextWeek = useCallback(() => {
    const date = isCanal ? nextMonday(activeDay) : nextSunday(activeDay);
    setActiveDate(date);
    setActiveDay(date);
  }, [activeDay, isCanal, setActiveDate]);

  const handleNextDay = useCallback(() => {
    setActiveDate(addDays(activeDate, 1));
  }, [activeDate, setActiveDate]);
  const handlePreviousDay = useCallback(() => {
    setActiveDate(subDays(activeDate, 1));
  }, [activeDate, setActiveDate]);

  return (
    <div className="flex items-center justify-between">
      <div
        className="flex self-stretch overflow-hidden rounded-t-md"
        role="group"
      >
        <button
          type="button"
          onClick={handleClick(VIEW_MODES.DAY)}
          className={`py-4 px-[25px] transition-colors text-base font-medium ${getBtnClass(
            mode === VIEW_MODES.DAY
          )}`}
        >
          Day
        </button>
        <button
          type="button"
          onClick={handleClick(VIEW_MODES.WEEK)}
          className={`py-4 px-[25px] transition-colors text-base font-medium ${getBtnClass(
            mode === VIEW_MODES.WEEK
          )}`}
        >
          Week
        </button>
      </div>

      <div className="flex gap-2 items-center bg-app-purple-5 rounded-[10px] my-0.5 px-2">
        <button
          className=""
          onClick={(ev) => {
            handlePreviousWeek();
            ev.stopPropagation();
          }}
        >
          <Icon
            icon="carbon:previous-filled"
            className="w-6 h-6 text-app-purple-12"
          />
        </button>
        <button
          onClick={(ev) => {
            handlePreviousDay();
            ev.stopPropagation();
          }}
        >
          <Icon icon="uiw:d-arrow-left" fontSize={14} color="#000000" />
        </button>
        <div>
          <Calendar />
        </div>

        <DatePicker
          selected={activeDate}
          onChange={(date) => setActiveDate(date)}
          customInput={<CustomInput />}
        />
        <div>
          <svg
            width="9"
            height="5"
            viewBox="0 0 9 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.232562 0.256018C0.55332 -0.0721195 0.999622 -0.0978287 1.39159 0.256018L4.26534 3.01112L7.13902 0.256018C7.53107 -0.0978287 7.97818 -0.0721195 8.29626 0.256018C8.44596 0.417516 8.52913 0.629597 8.52913 0.849804C8.52913 1.07001 8.44596 1.28209 8.29626 1.44359C7.99756 1.75105 4.84392 4.75392 4.84392 4.75392C4.76889 4.83183 4.67891 4.8938 4.57937 4.93613C4.47983 4.97846 4.37278 5.00028 4.26461 5.00028C4.15645 5.00028 4.04939 4.97846 3.94985 4.93613C3.85032 4.8938 3.76034 4.83183 3.6853 4.75392C3.6853 4.75392 0.533125 1.75105 0.233049 1.44359C0.0832454 1.28215 0 1.07004 0 0.849804C0 0.629564 0.0832454 0.417463 0.233049 0.256018H0.232562Z"
              fill="#222222"
            />
          </svg>
        </div>

        <button
          onClick={(ev) => {
            handleNextDay();
            ev.stopPropagation();
          }}
        >
          <Icon icon="uiw:d-arrow-right" fontSize={14} color="#000000" />
        </button>
        <button
          className=""
          onClick={(ev) => {
            handleNextWeek();
            ev.stopPropagation();
          }}
        >
          <Icon
            icon="carbon:next-filled"
            className="w-6 h-6 text-app-purple-12"
          />
        </button>
      </div>

      <button
        className="relative bg-app-purple-8 rounded-[10px] text-app-yellow px-4 py-[13px]"
        type="button"
        onClick={toggleCreate}
      >
        Create Session
      </button>

      <button
        className="relative text-app-purple-8 rounded-[10px] bg-app-yellow px-4 py-[13px]"
        type="button"
        onClick={toggleDuplicate}
      >
        Duplicate Sessions
      </button>
      <button
        className="relative bg-red-500 rounded-[10px] text-app-yellow px-4 py-[13px]"
        type="button"
        onClick={toggleDeleteAll}
      >
        Delete Sessions
      </button>
    </div>
  );
};

export default Toolbar;
