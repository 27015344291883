import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import CheckImage from "../../../assets/images/check.png";

const AcceptanceSuccess = ({ onClose = () => {}, show, data, type }) => {
  return (
    <>
      <Transition show={show} appear as={Fragment}>
        <Dialog as="div" className="relative z-[10000]" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg px-5 py-2 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <div className="flex justify-end " onClick={onClose}>
                    <div
                      className={
                        "rounded-xl text-[15px] font-bold bg-[#E5E5E5] px-3 py-1 cursor-pointer"
                      }
                    >
                      X
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <div
                      className=" rounded-md  bg-no-repeat bg-contain bg-center p-6 "
                      style={{
                        backgroundImage: `url("${CheckImage}")`,
                      }}
                    ></div>
                  </div>
                  <p className={"font-bold text-center my-2 "}>
                    Successfully Approved!
                  </p>
                  <p
                    className={"text-[#54595E] text-center my-2"}
                  >{` ${type} with reference ID ${data} has been successfully accepted`}</p>
                  <div className="flex justify-center my-2">
                    <button
                      className="rounded bg-[#61C876] px-3 py-1 text-white hover:font-bold"
                      onClick={onClose}
                    >
                      {" "}
                      Return to Homepage
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export { AcceptanceSuccess };
