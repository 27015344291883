import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addItemDirect,
  API_URL,
  axiosApiInstanceGet,
  editItemDirect,
  FETCH_STATUS,
  onReturnReject,
  axiosApiInstance,
} from "../../utils";

export const getSubscriptionStatus = createAsyncThunk(
  "getSubscriptionStatus",
  async ({ force, circuitId }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/CircuitSubscriptions/GetSubscriptionStatus?circuitId=${circuitId}`,
        force
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(onReturnReject(error));
    }
  }
);

export const getAllPaymentChannel = createAsyncThunk(
  "getAllPaymentChannel",
  async (cinemaId, { fulfillWithValue, rejectWithValue }) => {
    let force = true;
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/OtherChannels/ListAll?cinemaId=${cinemaId}`,
        force
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(onReturnReject(error));
    }
  }
);
export const getPaymentChannel = createAsyncThunk(
  "getPaymentChannel",
  async (otherChannelId, { fulfillWithValue, rejectWithValue }) => {
    let force = true;
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/OtherChannels/${otherChannelId}`,
        force
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(onReturnReject(error));
    }
  }
);

export const createPaymentChannel = createAsyncThunk(
  "createPaymentChannel",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/OtherChannels/Create`,
        data
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(onReturnReject(error));
    }
  }
);
export const editPaymentChannel = createAsyncThunk(
  "editPaymentChannel",
  async ({ otherChannelId, data }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.patch(
        `${API_URL}/OtherChannels/${otherChannelId}/Update`,
        data
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(onReturnReject(error));
    }
  }
);

const initialState = {
  subscriptionStatus: [],
  status: FETCH_STATUS.IDLE,
  err: null,
  allPaymentChannels: [],
  allPaymentChannelStatus: FETCH_STATUS.IDLE,
  allPaymentChannelErr: null,
  paymentChannel: {},
  paymentChannelStatus: FETCH_STATUS.IDLE,
  paymentChannelErr: null,
  createChannelStatus: FETCH_STATUS.IDLE,
  createChannelErr: null,
  editChannelStatus: FETCH_STATUS.IDLE,
  editChannelErr: null,
};
export const financeSlice = createSlice({
  name: "finance",
  initialState,
  reducers: {
    resetCreateChannel: (state) => {
      state.createChannelStatus = FETCH_STATUS.IDLE;
      state.createChannelErr = null;
    },
    resetEditChannel: (state) => {
      state.editChannelStatus = FETCH_STATUS.IDLE;
      state.editChannelErr = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubscriptionStatus.pending, (state) => {
        state.status = FETCH_STATUS.PENDING;
        state.err = "";
      })
      .addCase(getSubscriptionStatus.fulfilled, (state, action) => {
        state.status = FETCH_STATUS.RESOLVED;
        state.err = "";
        state.subscriptionStatus = action.payload;
      })
      .addCase(getSubscriptionStatus.rejected, (state, action) => {
        state.status = FETCH_STATUS.REJECTED;
        state.err = action.payload || "Unable to retrieve subscription status";
      })
      .addCase(getAllPaymentChannel.pending, (state) => {
        state.allPaymentChannelStatus = FETCH_STATUS.PENDING;
        state.allPaymentChannelErr = "";
      })
      .addCase(getAllPaymentChannel.fulfilled, (state, action) => {
        state.allPaymentChannelStatus = FETCH_STATUS.RESOLVED;
        state.allPaymentChannelErr = "";
        state.allPaymentChannels = action.payload;
      })
      .addCase(getAllPaymentChannel.rejected, (state, action) => {
        state.allPaymentChannelStatus = FETCH_STATUS.REJECTED;
        state.allPaymentChannelErr =
          action.payload || "Unable to get payment channel";
      })
      .addCase(getPaymentChannel.pending, (state) => {
        state.paymentChannelStatus = FETCH_STATUS.PENDING;
        state.paymentChannelErr = "";
      })
      .addCase(getPaymentChannel.fulfilled, (state, action) => {
        state.paymentChannelStatus = FETCH_STATUS.RESOLVED;
        state.paymentChannelErr = "";
        state.paymentChannel = action.payload;
      })
      .addCase(getPaymentChannel.rejected, (state, action) => {
        state.paymentChannelStatus = FETCH_STATUS.REJECTED;
        state.paymentChannelErr =
          action.payload || "Unable to get payment channel";
      })
      .addCase(createPaymentChannel.pending, (state) => {
        state.createChannelStatus = FETCH_STATUS.PENDING;
        state.createChannelErr = "";
      })
      .addCase(createPaymentChannel.fulfilled, (state, action) => {
       
        state.createChannelStatus = FETCH_STATUS.RESOLVED;
        state.allPaymentChannels = [
          ...state.allPaymentChannels,
          action.payload,
        ];
        state.createChannelErr = "";
      })
      .addCase(createPaymentChannel.rejected, (state, action) => {
        state.createChannelStatus = FETCH_STATUS.REJECTED;
        state.createChannelErr =
          action.payload || "Unable to create payment channel";
      })
      .addCase(editPaymentChannel.pending, (state) => {
        state.editChannelStatus = FETCH_STATUS.PENDING;
        state.editChannelErr = "";
      })
      .addCase(editPaymentChannel.fulfilled, (state, action) => {
        
        state.editChannelStatus = FETCH_STATUS.RESOLVED;
        state.allPaymentChannels = state.allPaymentChannels.map((channel) =>
          channel.id === action.payload.id
            ? (channel = action.payload)
            : channel
        );
        state.editChannelErr = "";
      })
      .addCase(editPaymentChannel.rejected, (state, action) => {
        state.editChannelStatus = FETCH_STATUS.REJECTED;
        state.editChannelErr =
          action.payload || "Unable to edit payment channel";
      });
  },
});

export const { resetCreateChannel, resetEditChannel } = financeSlice.actions;
export const selectFinance = (state) => state.finance;

// export const { addVendors, editVendors } = financeSlice.actions;
