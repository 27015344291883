import add from "date-fns/add";

import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";

import { formatDateTime } from "../../utils";
import { InlineErr } from "../UI";
import { DateTimeInput, format } from "./DateTimeInput";


const CreateSessionRow = ({
  register,
  formats,
  ticketTypes,
  activeFilm,
  index,
  control,
  sessions,
  remove,
  screens,
  errors,
  priceCardsErr,
}) => {
  const sessionErr =
    errors?.sessions && errors?.sessions[index]
      ? errors?.sessions[index]
      : null;

  return (
    <div
      key={index}
      className="relative grid items-center grid-cols-3 gap-2 pt-8 mb-1"
    >
      <div className="relative pb-1 mb-2">
        <label
          htmlFor={`sessions.${index}.screenId`}
          className="block mb-1 text-sm font-medium text-gray-900 "
        >
          Choose screen
        </label>
        <select
          id={`sessions.${index}.screenId`}
          className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg "
          {...register(`sessions.${index}.screenId`)}
        >
          <option value="">Select screen...</option>
          {screens.map((screen) => (
            <option value={screen.id} key={screen.id}>
              {screen.name}
            </option>
          ))}
        </select>

        <InlineErr err={sessionErr?.screenId?.message} />
      </div>

      <div className="relative pb-1 mb-2">
        <label
          htmlFor={`sessions.${index}.priceCardTicket`}
          className="block mb-1 text-sm font-medium text-gray-900 "
        >
          Choose price card
        </label>
        <select
          id={`sessions.${index}.priceCardTicket`}
          className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg "
          {...register(`sessions.${index}.priceCardTicket`)}
        >
          <option value="">Select...</option>
          {ticketTypes.map((type) => (
            <option value={type.id} key={type.id}>
              {type.name}
            </option>
          ))}
        </select>

        <InlineErr
          err={priceCardsErr || sessionErr?.priceCardTicket?.message}
        />
      </div>
      <div className="relative pb-1 mb-2">
        <label
          htmlFor={`sessions.${index}.filmFormat`}
          className="block mb-1 text-sm font-medium text-gray-900 "
        >
          Choose screen format
        </label>
        <select
          id={`sessions.${index}.filmFormatId`}
          className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg "
          {...register(`sessions.${index}.filmFormatId`)}
        >
          <option value="">Select...</option>
          {formats.map((type) => (
            <option value={type.id} key={type.id}>
              {type.name}
            </option>
          ))}
        </select>

        <InlineErr
          err={sessionErr?.filmFormatId?.message}
        />
      </div>

      <div className="relative pb-1 mb-2">
        <label
          htmlFor={`sessions.${index}.trailerDuration`}
          className="block mb-1 text-sm font-medium text-gray-900 "
        >
          Trailer Duration (mins)
        </label>

        <input
          type="number"
          min="0"
          id={`sessions.${index}.trailerDuration`}
          className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg "
          {...register(`sessions.${index}.trailerDuration`)}
        />

        <InlineErr err={sessionErr?.trailerDuration?.message} />
      </div>

      {/* relative postioning breaks time picker styles */}
      <div>
        <label
          htmlFor={`sessions.${index}.startTime`}
          className="block mb-1 text-sm font-medium text-gray-900 "
        >
          Start time
        </label>

        <Controller
          control={control}
          name={`sessions.${index}.startTime`}
          render={({ field: { onChange, value } }) => {
            return (
              <DatePicker
                minDate={new Date()}
                dateFormat="Pp"
                showTimeSelect
                timeFormat="HH:mm"
                selected={value ? new Date(value) : new Date()}
                onChange={(value) => {
                  onChange(value);
                }}
                customInput={<DateTimeInput onChange={onChange} />}
                timeInputLabel="Time:"
                showTimeInput
              />
            );
          }}
        />

        <div className="0">
          <InlineErr
            className="!relative !top-0 "
            err={sessionErr?.startTime?.message}
          />
        </div>
      </div>

      <div>
        <label
          htmlFor={`sessions.${index}.endTime`}
          className="block mb-1 text-sm font-medium text-gray-900 "
        >
          End Time
        </label>

        <input
          type="text"
          disabled
          id={`sessions.${index}.endTime`}
          className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
          value={formatDateTime(
            add(new Date(sessions[index]?.startTime), {
              minutes:
                activeFilm?.duration + +sessions[index]?.trailerDuration || 0,
            }),
            format
          )}
        />
      </div>

      <div>
        <label
          htmlFor={`sessions.${index}.cleanUpTime`}
          className="block mb-1 text-sm font-medium text-gray-900 "
        >
          Cleanup Time
        </label>

        <input
          type="text"
          disabled
          id={`sessions.${index}.cleanUpTime`}
          className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
          value={formatDateTime(
            add(new Date(sessions[index].startTime), {
              minutes:
                activeFilm?.duration + +sessions[index]?.trailerDuration || 0,
            }),
            format
          )}
        />
      </div>

      <button
        onClick={() => remove(index)}
        type="button"
        className="bg-rose-500 text-white rounded-lg text-sm p-1.5 lex items-center absolute top-0 right-0 "
      >
        <svg
          className="w-4 h-4"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>

      <hr className="col-span-3 my-2 border-dashed" />
    </div>
  );
};

export default CreateSessionRow;
