import { loggedInCinemaName } from "../utils";

export const useFetchSchedule = () => {
  const cinemaName = localStorage.getItem(loggedInCinemaName);
  const isCanal = cinemaName?.toLowerCase().includes("canal");

  return {
    cinemaName,
    isCanal,
  };
};
