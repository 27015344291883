import {useEffect, useRef, useState} from "react";
import {
    DailyOperationsReportTable,
    ExportExcelBtn,
    InputErr,
    PdfButton,
    Pdiv,
    Pdiv2,
    PrivateRoute,
    Spinner,
} from "../../../components";
import {useNavigate} from "react-router-dom";
import DatePicker from "react-datepicker";
import {DateTimeInput} from "../../../components/Films/DateTimeInput";
import {useLoggedInuser} from "../../../hooks";
import {
    getDailyOperationsReport,
    getDailyOperationsRevReport,
    getDailyOperationsStatReport,
    getStaff,
    reportData,
} from "../../../redux/slices";
import {dailyCashupReportSchema, formatDateForApi} from "../../../utils";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Element, scroller} from "react-scroll";

import {useDispatch, useSelector} from "react-redux";
import addHours from "date-fns/addHours";

const DailyOperationsReport = () => {
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { dailyOperationsReport, dailyOperationsReportStatus } =
    useSelector(reportData);
  const { profile } = useLoggedInuser();
  const dispatch = useDispatch();
  const reportRef = useRef(null);

  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(dailyCashupReportSchema),
    defaultValues: {
      todayDate: selectedDate,
      CinemaId: profile.cinemaId,
    },
  });

  useEffect(() => {
    setValue("todayDate", selectedDate);
    dispatch(getStaff({ force: true, cinemaId: profile.cinemaId }));
  }, [selectedDate, setValue, dispatch, profile]);

  const onSubmit = (data) => {
    dispatch(
      getDailyOperationsReport({
        ...data,
        todayDate: formatDateForApi(new Date(data.todayDate)),
        force: true,
      })
    );
    dispatch(
      getDailyOperationsRevReport({
        ...data,
        todayDate: formatDateForApi(new Date(data.todayDate)),
        force: true,
      })
    );
    dispatch(
      getDailyOperationsStatReport({
        ...data,
        todayDate: addHours(new Date(data.todayDate), 1).toISOString(),
        force: true,
      })
    );

    scroller.scrollTo("reportContainer", {
      duration: 1500,
      delay: 0,
      smooth: true,
      offset: -100, // Scrolls to element + 50 pixels down the page
    });
  };

 
  const xlsId = ["dailyop1", "dailyop2", "dailyop3", "dailyop4", "dailyop5"];
  return (
    <PrivateRoute redirectTo={"/dashboard/reports/daily-cashup-report"}>
      <div className="w-[95%] mx-auto mt-6">
        <p className="font-semi-bold px-3 pb-5">Daily Operations Report</p>
        <div className="flex items-center justify-between p-4 px-8 h-[50px] rounded-t-lg bg-[#C96FCC]">
          <div className="flex gap-4">
            <span className="text-xl font-semibold text-white"></span>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex items-center justify-end py-3 px-4 bg-white ">
            <div className="flex gap-3 buttongroup ">
              <button
                className="p-1 px-3 rounded-md text-[#C96FCC] bg-[#ffe583] shadow-md hover:shadow-sm"
                type="button"
                onClick={() => {
                  navigate("/dashboard/reports");
                }}
              >
                Cancel
              </button>
              <button
                className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm flex items-center"
                type="submit"
              >
                <span> Run Report </span>
                <Spinner
                  className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                  status={dailyOperationsReportStatus}
                />
              </button>
            </div>
          </div>
          <div className="bg-app-purple-2 p-3 pt-[30px]">
            <Pdiv label={"Select Date"}>
              <DatePicker
                placeholderText="End Date"
                selected={new Date(selectedDate)}
                onChange={(value) => setSelectedDate(new Date(value))}
                customInput={
                  <DateTimeInput
                    className="bg-app-purple-8"
                    dateFormat={"EEE, MMM dd, yyyy"}
                  />
                }
              />
            </Pdiv>

            <InputErr err={errors?.todayDate?.message} />
            <Pdiv2 label={"Site"} name={"CinemaId"} {...register("CinemaId")}>
              <option value={""}>Select Cinema</option>
              <option value={profile.cinemaId}>{profile.cinema}</option>
            </Pdiv2>
            <InputErr err={errors?.CinemaId?.message} />
          </div>
        </form>
        <Element name="reportContainer">
          {dailyOperationsReport?.paramFilter && (
            <>
              <div className="flex justify-center mt-5 space-x-4">
                <PdfButton refValue={reportRef} docTitle={"daily-operations"} />
                <ExportExcelBtn id={xlsId} name={"dailyOperations.xlsx"} />
              </div>
              <div  className="hidden">
                <DailyOperationsReportTable
                  id1={"dailyop1"}
                  id2={"dailyop2"}
                  id3={"dailyop3"}
                  id4={"dailyop4"}
                  id5={"dailyop5"}
                />
              </div>
              <div ref={reportRef}>
                <DailyOperationsReportTable />
              </div>
            </>
          )}
        </Element>
      </div>
    </PrivateRoute>
  );
};
export { DailyOperationsReport };
