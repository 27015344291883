import { PrivateRoute, POSLayoutForm } from "../../../components";

const POSLayout = () => {
  return (
    <PrivateRoute redirectTo="/dashboard/items/posLayout">
      <POSLayoutForm />
    </PrivateRoute>
  );
};

export { POSLayout };
