import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState, useCallback, useEffect } from "react";
import { Controller, useForm, useFieldArray, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  FileUploadInput,
  FormError,
  InlineErr,
  MultiSearchSelect,
  PageHeader,
  Spinner,
  Success,
  SuccessModal,
} from "../../../../components";
import { useLoggedInuser, useRequest } from "../../../../hooks";
import {
  getFilms,
  getMetaData,
  resetMetaDataErr,
  selectFilms,
  selectMetaData,
} from "../../../../redux/slices";
import {
  API_URL,
  FETCH_STATUS,
  SPACE_BUCKET,
  SPACE_URL,
  axiosApiInstance,
  metaDataInit,
  metaDataSchema,
  s3Client,
  storage,
} from "../../../../utils";
import ItemDefault from "../../../../assets/images/itemDefaultImg.png";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

import BannerInputField from "../../../../components/Hive/BannerInputField";
import FilmRow from "../../../../components/Hive/FilmRow";
import { PutObjectCommand } from "@aws-sdk/client-s3";
import { Spinner2 } from "../../../../components/UI/ItemsComponents/Spinner2";
import { useFileUpload } from "../../../../hooks/useFileUpload";

const MetaData = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [logoError, setLogoError] = useState(false);
  const [bannerError, setBannerError] = useState(false);
  const { getFilmsStatus, films } = useSelector(selectFilms);
  const { metaData, metaDataStatus, metaDataErr } = useSelector(selectMetaData);
  const { profile } = useLoggedInuser();
  const { err, setPending, setResolved, status, resetErr, setRejected } =
    useRequest();

  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    reset,
    watch,
    getValues,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(metaDataSchema),
    defaultValues: metaDataInit,
  });

  const banners = useWatch({ control, name: "bannerUrls" });
  const imageUrls = useWatch({ control, name: "imageUrls" });
  const logoUrl = useWatch({ control, name: "logoUrl" });
  const automateBanner = watch("useFilmSchedule");

  const filmIds = watch("filmIds");

  const {
    handleFileUpload: handleCinemaImageChange,
    imageSelected: cinemaImageSelected,
    imageUploaded: cinemaImageUploaded,
    multiImage: cinemaImages,
    setMultiImage: setCinemaImages,
  } = useFileUpload({ fieldName: imageUrls });
  const {
    handleFileUpload: handleBannerChange,
    imageSelected: bannerSelected,
    imageUploaded: bannerUploaded,
    multiImage: bannerImages,
    setMultiImage: setBannerImages,
  } = useFileUpload({ fieldName: banners });
  const {
    handleFileUpload: handleLogoChange,
    imageSelected: logoSelected,
    imageUploaded: logoUploaded,
    singleImage: logoImage,
    setSingleImage: setLogoImage,
  } = useFileUpload({
    fieldName: "logoUrl",
    setValue: setValue,
    imageDefault: ItemDefault,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "filmIds",
    defaultValue: [{ filmId: "" }],

  });

  const getAllFilms = useCallback(() => {
    dispatch(getFilms());
  }, [dispatch]);

  useEffect(() => {
    if (bannerImages?.length) {
      setValue("bannerUrls", bannerImages, { shouldValidate: true });
    }
  }, [bannerImages, setValue]);

  useEffect(() => {
    if (cinemaImages?.length) {
      setValue("imageUrls", cinemaImages, { shouldValidate: true });
    }
  }, [cinemaImages, setValue]);

  useEffect(() => {
    getAllFilms();
  }, [getAllFilms]);

  useEffect(() => {
    dispatch(getMetaData(profile?.cinemaId))
      .unwrap()
      .then((metaData) => {
        if (metaData) {
          reset({
            address: metaData.address,
            logoUrl: metaData.logoUrl,
            phoneNumber: metaData.phoneNumber,
            email: metaData.email,
            filmIds:
              metaData?.filmsShowCase.length > 0
                ? metaData?.filmsShowCase?.reduce((acc, curr) => {
                    acc.push({ filmId: curr.id });
                    return acc;
                  }, [])
                : [],
            aboutUs: metaData.aboutUs,
            bannerUrls: metaData.bannerUrls,
            imageUrls: metaData.imageUrls,
            useFilmSchedule: metaData.useFilmSchedule,
          });
        } else {
          reset({ ...metaDataInit });
        }
        if (metaData) {
          setBannerImages(metaData.bannerUrls);
          setLogoImage(metaData.logoUrl);
          setCinemaImages(metaData.imageUrls);
        }
      });
  }, [profile, reset, dispatch, setBannerImages, setLogoImage, setCinemaImages]);


  const submitMetaData = async (data) => {
    
    try {
      setPending();
      await axiosApiInstance.patch(
        `${API_URL}/Cinemas/${profile?.cinemaId}/UpdateMetaData`,
        {
          ...data,
          filmIds: data.filmIds.reduce((acc, curr) => {
            acc.push(curr.filmId);
            return acc;
          }, []),
        }
      );
      setResolved();
      setShowSuccess(true);
    } catch (error) {
      let errMsgs = "Unable to update meta data";

      setRejected(errMsgs);
    }
  };

  const handleRemove = (image) => {
    const updatedImages = bannerImages.filter((img) => img !== image);
    const bannerImgs = banners.filter((img) => img !== image);

    return (
      setBannerImages(updatedImages),
      setValue("bannerUrls", bannerImgs, { shouldValidate: true })
    );
  };
  const handleCinemaImageDelete = (image) => {
    const updatedImages = cinemaImages.filter((img) => img !== image);
    const cinemaImgs = imageUrls.filter((img) => img !== image);

    return (
      setCinemaImages(updatedImages),
      setValue("imageUrls", cinemaImgs, { shouldValidate: true })
    );
  };

  return (
    <>
      <FormError
        className="fixed z-20 transform -top-4 left-2/4 -translate-x-2/4"
        err={err}
        onDismiss={() => resetErr}
      />
      <FormError
        className="fixed z-20 transform -top-4 left-2/4 -translate-x-2/4"
        err={metaDataErr}
        onDismiss={() => dispatch(resetMetaDataErr())}
      />
      <div className="w-full mx-auto">
        <PageHeader className="!flex !justify-center">
          <h1 className="text-2xl font-semibold text-white">
            Cinema Meta Data
          </h1>
        </PageHeader>
        {metaDataStatus === FETCH_STATUS.PENDING && (
          <div className="flex items-center justify-center p-3">
            {" "}
            <Spinner2 className="w-20 h-20" />
          </div>
        )}
        <Success show={metaDataStatus === FETCH_STATUS.RESOLVED}>
          <form onSubmit={handleSubmit(submitMetaData)}>
            <div className="bg-app-purple-2 p-3 pt-[30px] rounded-b-lg">
              <div className="flex items-center justify-center mb-4">
                <FileUploadInput
                  headerName="Image"
                  id={"logoUrl"}
                  uploadName="Select Logo"
                  onChange={handleLogoChange}
                  isMultiple={false}
                >
                  <img
                    src={`${logoImage}`}
                    alt="logoImage"
                    loading="lazy"
                    className=" w-[16rem] h-[13rem] my-3 bg-no-repeat bg-contain bg-center"
                  />
                  <div className="flex justify-center">
                    {logoSelected && (
                      <>
                        {logoSelected && !logoUploaded && (
                          <p>Upload In-Progress...</p>
                        )}
                        {getValues("logoUrl")?.length > 0 && logoUploaded && (
                          <p>Upload Successful</p>
                        )}

                        {getValues("logoUrl")?.length < 1 &&
                          logoUploaded &&
                          logoError && <p> Oops, Upload Failed</p>}
                      </>
                    )}
                  </div>
                </FileUploadInput>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 items-center">
                <div className="font-medium">
                  <label
                    htmlFor="address"
                    // className="flex justify-end mr-3 w-[10rem]"
                  >
                    Address
                  </label>
                  <input
                    id={"address"}
                    name={"address"}
                    {...register("address")}
                    type="text"
                    aria-label=""
                    className="rounded bg-[#d0a3d3] border-0 mb-3 h-[35px] pl-2 w-full"
                  />
                </div>
                <div className="font-medium">
                  <label
                    htmlFor="email"
                    // className="flex justify-end mr-3 w-[10rem]"
                  >
                    Email
                  </label>
                  <input
                    name={"email"}
                    id={"email"}
                    {...register("email")}
                    type="email"
                    aria-label=""
                    className="rounded bg-[#d0a3d3] border-0 mb-3 h-[35px] pl-2 w-full"
                  />
                </div>
                <div className="font-medium">
                  <label
                    htmlFor="phoneNumber"
                    // className="flex justify-end mr-3 w-[12rem]"
                  >
                    Phone Number
                  </label>
                  <input
                    id={"phoneNumber"}
                    name={"phoneNumber"}
                    {...register("phoneNumber")}
                    type="tel"
                    aria-label=""
                    className="rounded bg-[#d0a3d3] border-0 mb-3 h-[35px] pl-2 w-full"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                <div className="font-medium">
                  <label
                    htmlFor="aboutUs"
                    // className="flex justify-end mr-3 w-[10rem]"
                  >
                    About Us
                  </label>
                  <textarea
                    rows={6}
                    id={"aboutUs"}
                    name={"aboutUs"}
                    {...register("aboutUs")}
                    type="text"
                    aria-label=""
                    className="rounded bg-[#d0a3d3] border-0 mb-3 pl-2 w-full"
                  />
                </div>
                <div>
                  <p className="font-medium">Banner</p>
                  <div className="mx-auto border-2 border-[#AA7DAA] rounded-xl">
                    <div className="text-white  bg-[#a480a4] p-2 rounded-t-lg">
                      <div className="flex items-center gap-4 justify-start">
                        <button
                          type="button"
                          disabled={automateBanner}
                          onClick={() => {
                            append({
                              ...filmIds,
                            });
                          }}
                          className="text-black disabled:cursor-not-allowed bg-gradient-[180deg] to-[#b4aeb4] from-[#f5eef5] px-4 py-2 rounded-lg text-sm"
                        >
                          Add Film
                        </button>
                        <div className="flex items-center gap-2">
                          <p className="block text-gray-700 text-sm font-bold mb-2">
                            Automate Banner:
                          </p>
                          <div>
                            <Controller
                              name="useFilmSchedule"
                              control={control}
                              render={({ field: { value, onChange } }) => {
                                return (
                                  <input
                                    checked={value}
                                    id="useFilmSchedule"
                                    type="checkbox"
                                    onChange={onChange}
                                    className="border border-gray-300 rounded-sm focus:!border-none text-[#C96FCC] !ring-transparent mr-2 items-center w-6 h-6"
                                  />
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-3 p-2 gap-y-4">
                      {!automateBanner &&
                        fields?.map((field, index) => {
                          return (
                            <FilmRow
                              items={films}
                              control={control}
                              key={field?.id}
                              index={index}
                              remove={remove}
                            />
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
              {/* ADD Banner SECTION */}
              <BannerInputField
                onChange={handleBannerChange}
                isMultiple={true}
                id={"bannerUrls"}
                uploadName={"Select Promotional Banner(s)"}
              >
                {!bannerImages?.length ? (
                  <div className="flex justify-center">
                    <img
                      src={`${ItemDefault}`}
                      alt="logoImage"
                      loading="lazy"
                      className=" w-[16rem] h-[13rem] my-3 bg-no-repeat bg-contain bg-center"
                    />
                  </div>
                ) : (
                  <div className="grid grid-cols-3 gap-4 border border-[#A86AAA] border-x-0 border-t-0 bg-app-purple-13">
                    {bannerImages?.map((image, i) => (
                      <>
                        <div className="flex flex-col items-center" key={image}>
                          <img
                            src={`${image}`}
                            alt="logoImage"
                            loading="lazy"
                            className=" w-[25rem] h-[15rem] my-3 bg-no-repeat bg-contain bg-center"
                          />
                          <div>
                            <button
                              type="button"
                              className="bg-gradient-[180deg] block to-[#764877] from-[#A86AAA] px-2 py-1 rounded-md text-[12px] mb-2 text-white w-[4rem]"
                              onClick={() => handleRemove(image)}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                )}
                <div className="flex justify-center">
                  {bannerSelected && (
                    <>
                      {bannerSelected && !bannerUploaded && (
                        <p>Upload In-Progress...</p>
                      )}
                      {getValues("bannerUrls")?.length > 0 &&
                        bannerUploaded && <p>Upload Successful</p>}

                      {getValues("bannerUrls")?.length < 1 &&
                        bannerUploaded &&
                        bannerError && <p> Oops, Upload Failed</p>}
                    </>
                  )}
                </div>
              </BannerInputField>
              <div className="my-6" />

              {/* ADD Cinema Image SECTION */}
              <BannerInputField
                onChange={handleCinemaImageChange}
                isMultiple={true}
                id={"imageUrls"}
                uploadName={"Select Cinema Image(s)"}
              >
                {!cinemaImages?.length ? (
                  <div className="flex justify-center">
                    <img
                      src={`${ItemDefault}`}
                      alt="logoImage"
                      loading="lazy"
                      className=" w-[16rem] h-[13rem] my-3 bg-no-repeat bg-contain bg-center"
                    />
                  </div>
                ) : (
                  <div className="grid grid-cols-3 gap-4 border border-[#A86AAA] border-x-0 border-t-0 bg-app-purple-13">
                    {cinemaImages?.map((image, i) => (
                      <>
                        <div className="flex flex-col items-center" key={image}>
                          <img
                            src={`${image}`}
                            alt="logoImage"
                            loading="lazy"
                            className=" w-[25rem] h-[15rem] my-3 bg-no-repeat bg-contain bg-center"
                          />
                          <div>
                            <button
                              type="button"
                              className="bg-gradient-[180deg] block to-[#764877] from-[#A86AAA] px-2 py-1 rounded-md text-[12px] mb-2 text-white w-[4rem]"
                              onClick={() => handleCinemaImageDelete(image)}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                )}
                <div className="flex justify-center">
                  {cinemaImageSelected && (
                    <>
                      {cinemaImageSelected && !cinemaImageUploaded && (
                        <p>Upload In-Progress...</p>
                      )}
                      {getValues("imageUrls")?.length > 0 &&
                        cinemaImageUploaded && <p>Upload Successful</p>}

                      {getValues("imageUrls")?.length < 1 &&
                        cinemaImageUploaded && <p> Oops, Upload Failed</p>}
                    </>
                  )}
                </div>
              </BannerInputField>

              <div className="flex justify-center my-4">
                <button
                  className="px-2 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm flex items-center justify-center w-[20rem] py-2"
                  type="submit"
                >
                  <span className="uppercase"> Update Meta Data </span>
                  <Spinner
                    className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                    status={status}
                  />
                </button>
              </div>
              <SuccessModal
                show={showSuccess}
                onClick={() => setShowSuccess(false)}
                message={"Meta data updated successfully"}
              />
            </div>
          </form>
        </Success>
      </div>
    </>
  );
};

export { MetaData };
