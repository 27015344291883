import React from "react";

const Dashboard = ({ fill = process.env.REACT_APP_COLOR_10 }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2 4H6.13333C4.93333 4 4 4.93333 4 6.13333V17.4667C4 18.6667 4.93333 19.6 6.13333 19.6H13.2C14.4 19.6 15.3333 18.6667 15.3333 17.4667V6.13333C15.3333 4.93333 14.4 4 13.2 4ZM12.5333 16.6667H6.8V6.8H12.4V16.6667H12.5333ZM13.2 20.9333H6.13333C4.93333 20.9333 4 21.8667 4 23.0667V25.8667C4 27.0667 4.93333 28 6.13333 28H13.2C14.4 28 15.3333 27.0667 15.3333 25.8667V23.0667C15.3333 21.8667 14.4 20.9333 13.2 20.9333ZM12.5333 25.2H6.8V23.7333H12.4V25.2H12.5333ZM25.8667 12.5333H18.8C17.6 12.5333 16.6667 13.4667 16.6667 14.6667V26C16.6667 27.2 17.6 28.1333 18.8 28.1333H25.8667C27.0667 28.1333 28 27.2 28 26V14.6667C28 13.4667 27.0667 12.5333 25.8667 12.5333ZM25.2 25.2H19.6V15.3333H25.2V25.2ZM25.8667 4H18.8C17.6 4 16.6667 4.93333 16.6667 6.13333V8.93333C16.6667 10.1333 17.6 11.0667 18.8 11.0667H25.8667C27.0667 11.0667 28 10.1333 28 8.93333V6.13333C28 4.93333 27.0667 4 25.8667 4ZM25.2 8.26667H19.6V6.8H25.2V8.26667Z"
        fill={fill}
      />
    </svg>
  );
};

export { Dashboard };
