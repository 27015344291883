import add from "date-fns/add";
import isBefore from "date-fns/isBefore";
import areIntervalsOverlapping from "date-fns/areIntervalsOverlapping";
import groupBy from "lodash/groupBy";

import { formatDateTime } from "./dateTimeHelpers";

const checkSelfConflictingSessions = (
  sessionList,
  resourceIdentifier = "screenId"
) => {
  const errors = [];

  const existingSessions = {};

  outerloop: for (const event of sessionList) {
    if (!existingSessions[event[resourceIdentifier]]) {
      existingSessions[event[resourceIdentifier]] = [
        {
          startTime: event.startTime,
          endTime: event.endTime,
        },
      ];
    } else {
      for (const exEvt of existingSessions[event[resourceIdentifier]]) {
        const isOverlapping = areIntervalsOverlapping(
          { start: new Date(event.startTime), end: new Date(event.endTime) },
          { start: new Date(exEvt.startTime), end: new Date(exEvt.endTime) }
        );

        if (isOverlapping) {
          errors.push({
            id: errors.length,
            message: `Two or more new sessions will clash/overlap`,
          });

          break outerloop;
        }
      }
    }
  }

  return errors;
};

/**
 *
 * @param {Array.<{startTime: Date, endTime: Date [resourceIdKey]:String}>} newEvents Array of possible events
 * @param {Array.<{startTime: Date, [resourceIdKey]:String}>}  existingEvents Array of possible events. Duration in minutes
 * @param {String} resourceIdentifier , resource key to identify resource in the event object eg screenId
 * @param {String | Function} groupByIdOrFxn  How to group exsiting events-- see lodash groupBy for more details
 * @returns
 */

const validateSessions = (
  newEvents = [],
  existingEvents = [],
  resourceIdentifier = "screenId",
  groupByIdOrFxn = "screenId" //or a function
) => {

  const selfConflictedErrs = checkSelfConflictingSessions(
    newEvents,
    resourceIdentifier
  );

  if (selfConflictedErrs.length) {
    return selfConflictedErrs;
  }

  if (!newEvents.length) {
    return [
      {
        id: 0,
        message: `No  new events were passed to function`,
      },
    ];
  }

  const groupedEventsByResource = groupBy(existingEvents, groupByIdOrFxn);
  const errors = [];
  const thirtyMinAgo = add(new Date(), { minutes: -30 });

  for (const event of newEvents) {
    if (!isBefore(thirtyMinAgo, new Date(event.startTime))) {
      errors.push({
        id: errors.length,
        message: "Cannot schedule an event earlier than 30mins before now",
      });

      continue;
    }

    const existingEventsUsingResource =
      groupedEventsByResource[event[resourceIdentifier]] ?? [];

    for (const exEvt of existingEventsUsingResource) {
      const isOverlapping = areIntervalsOverlapping(
        { start: new Date(event.startTime), end: new Date(event.endTime) },
        { start: new Date(exEvt.startTime), end: new Date(exEvt.endTime) }
      );

      if (isOverlapping) {
        errors.push({
          id: errors.length,
          message: `New session (with start ${formatDateTime(
            new Date(event.startTime),
            "yyyy-LL-dd, HH:mm"
          )} and end  ${formatDateTime(
            new Date(event.endTime),
            "yyyy-LL-dd, HH:mm"
          )}) will clash/overlap with existing session (with start ${formatDateTime(
            new Date(exEvt.startTime),
            "yyyy-LL-dd, HH:mm"
          )} and end  ${formatDateTime(
            new Date(exEvt.endTime),
            "yyyy-LL-dd, HH:mm"
          )})`,
        });
      }
    }
  }

  return errors;
};

/**
 *
 * @param {Array.<{startTime: Date, endTime: Date [resourceIdKey]:String}>} newEvents Array of possible events
 * @param {Array.<{startTime: Date, [resourceIdKey]:String}>}  existingEvents Array of possible events. Duration in minutes
 * @param {String} resourceIdentifier , resource key to identify resource in the event object eg screenId
 * @param {String | Function} groupByIdOrFxn  How to group exsiting events-- see lodash groupBy for more details
 * @returns
 */

const validateSingleSession = (
  event,
  existingEvents = [],
  resourceIdentifier = "screenId",
  eventThingId = "filmId"
) => {
  const errors = [];

  const thirtyMinAgo = add(new Date(), { minutes: -30 });

  if (!isBefore(thirtyMinAgo, new Date(event.startTime))) {
    errors.push({
      id: errors.length,
      message: "Cannot schedule an event earlier than 30mins before now",
    });

    return errors;
  }

  if (!event[resourceIdentifier]) {
    errors.push({
      id: errors.length,
      message: "Please select a screen",
    });

    return errors;
  }

  try {
    const time = new Date(event.startTime);

    if (!event.startTime || !time) {
      errors.push({
        id: errors.length,
        message: "Invalid date/time selected",
      });

      return errors;
    }
  } catch {
    errors.push({
      id: errors.length,
      message: "Invalid date/time selected",
    });

    return errors;
  }

  const eventUsingResource = existingEvents.filter(
    (ev) => ev[resourceIdentifier] === event[resourceIdentifier]
  );

  for (const exEvt of eventUsingResource) {
    try {
      const isOverlapping = areIntervalsOverlapping(
        { start: new Date(event.startTime), end: new Date(event.endTime) },
        { start: new Date(exEvt.startTime), end: new Date(exEvt.endTime) }
      );

      if (
        event.id === exEvt.id &&
        exEvt[eventThingId] !== event[eventThingId]
      ) {
        //don't throw error for updating film for an existing session
        continue;
      }

      if (isOverlapping) {
        if (
          !(
            exEvt[eventThingId] === event[eventThingId] &&
            exEvt[resourceIdentifier] === event[resourceIdentifier]
          )
        ) {
          errors.push({
            id: errors.length,
            message: `New session (with start ${formatDateTime(
              new Date(event.startTime),
              "yyyy-LL-dd, HH:mm"
            )} and end  ${formatDateTime(
              new Date(event.endTime),
              "yyyy-LL-dd, HH:mm"
            )}) will clash/overlap with existing session (with start ${formatDateTime(
              new Date(exEvt.startTime),
              "yyyy-LL-dd, HH:mm"
            )} and end  ${formatDateTime(
              new Date(exEvt.endTime),
              "yyyy-LL-dd, HH:mm"
            )})`,
          });
        }
      }
    } catch (error) {
      errors.push({ id: errors.length, message: error.toString() });
    }
  }

  return errors;
};

export { validateSessions, validateSingleSession };
