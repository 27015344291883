import differenceBy from "lodash/differenceBy";
import isEqual from "lodash/isEqual";

const didUpdateFields = (oldData, newData) => {
  for (const key in oldData) {
    if (!isNaN(oldData[key])) {
      if (
        Array.isArray(oldData[key]) ||
        oldData[key] === "" ||
        oldData[key] === null ||
        oldData[key] === true ||
        oldData[key] === false
      ) {
        return oldData[key];
      } else {
        return +oldData[key];
      }
    } else if (isNaN(oldData[key])) {
      return oldData[key];
    }
  }

  for (const key in newData) {
    if (!isNaN(newData[key])) {
      if (
        Array.isArray(newData[key]) ||
        newData[key] === "" ||
        newData[key] === null ||
        newData[key] === true ||
        newData[key] === false
      ) {
        return newData[key];
      } else {
        return +newData[key];
      }
    } else if (isNaN(newData[key])) {
      return newData[key];
    }
  }
 

  return !isEqual(oldData, newData);
};

const removedTickets = (oldTickets, newTickets) => {
  return differenceBy(oldTickets, newTickets, "id");
};

const addedTickets = (oldTickets, newTickets) => {
  return differenceBy(newTickets, oldTickets, "id");
};

const modifiedTickets = (oldTickets, newTickets) => {
  return differenceBy(newTickets, oldTickets, "price");
};

const getAddedAndRemovedTickets = (oldData, newData) => {
  const added = addedTickets(oldData.tickets, newData.tickets);
  const removed = removedTickets(oldData.tickets, newData.tickets);
  const modified = modifiedTickets(oldData.tickets, newData.tickets);

  return { added, removed, modified };
};

export { didUpdateFields, getAddedAndRemovedTickets };
