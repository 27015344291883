import React, { Fragment, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Transition } from "@headlessui/react";

import {
  Audit,
  Dashboard,
  Films,
  Items,
  Logout,
  Reports,
  Screens,
  Sessions,
  Staff,
  Stock,
} from "../../Icons";
import { useLogout } from "../../../hooks";
import ReachLogo from "../../../assets/images/logo.png";

const paths = [
  {
    text: "Dashboard",
    icon: Dashboard,
    path: "",
  },
  {
    text: "Film",
    icon: Films,
    path: "vehicle",
    subMenu: [
      {
        text: "All Films",
        path: "films",
      },
      {
        text: "Film Scheduling",
        path: "films-scheduling",
      },
      {
        text: "Film Hire",
        path: "film-contracts",
      },
      {
        text: "All Tickets",
        path: "tickets",
      },
      {
        text: "Price Cards",
        path: "price-cards",
      },
    ],
  },
  {
    text: "Items",
    icon: Items,
    path: "rentals",
    subMenu: [
      {
        text: "All Items",
        path: "items",
      },
      {
        text: "POS Layout",
        path: "items/posLayout",
      },
      {
        text: "Kiosk Layout",
        path: "items/kioskLayout",
      },
    ],
  },
  {
    text: "Stock",
    icon: Stock,
    path: "stock",
    subMenu: [
      {
        text: "Overview",
        path: "stock",
      },
      {
        text: "Stock Requests",
        path: "stock/stockRequest",
      },
      {
        text: "Purchase Orders",
        path: "stock/purchaseOrder",
      },
      {
        text: "Stock Receipts",
        path: "stock/stockReceipt",
      },
      {
        text: "Stock Take",
        path: "stock/stockTake",
      },
      {
        text: "Stock Transfer Requests",
        path: "stock/stockTransferRequest",
      },
      {
        text: "Stock Transfer Receipts",
        path: "stock/stockTransferReceipt",
      },
      {
        text: "Vendors",
        path: "stock/vendors",
      },
      {
        text: "Locations",
        path: "stock/locations",
      },
    ],
  },
  {
    text: "Staff",
    icon: Staff,
    path: "Users",
    subMenu: [
      { text: "All Staff", path: "users" },
      { text: "Staff Claims", path: "users/claims" },
      { text: "Roster", path: "users/roster" },
      { text: "Shifts", path: "users/shifts" },
      { text: "Tasks", path: "users/tasks" },
    ],
  },
  {
    text: "Screens",
    icon: Screens,
    path: "screens",
  },
  {
    text: "Sessions",
    icon: Sessions,
    path: "sessions",
  },
  {
    text: "Finance",
    icon: Reports,
    path: "finance",
    subMenu: [
      {
        text: "Invoice",
        path: "finance/invoice",
      },
      {
        text: "Kiosk Terminals",
        path: "finance/kiosk-terminals",
      },
      {
        text: "Payment Channel",
        path: "finance/payment-channel",
      },
    ],
  },
  // {
  //   text: "Connect Panel:",
  //   icon: Films,
  //   path: "connect",
  //   subMenu: [
  //     {
  //       text: "Ticket",
  //       path: "connect/connect-tickets",
  //     },
  //     {
  //       text: "Items",
  //       path: "connect/connect-items",
  //     },
  //     {
  //       text: "Showtimes",
  //       path: "connect/connect-showtimes",
  //     },
  //   ],
  // },
  {
    text: "Settings",
    icon: Films,
    path: "rentals",
    subMenu: [
      {
        text: "Hive",
        path: "settings/hive",
      },
      {
        text: "Cinema Setting",
        path: "settings/cinema-settings",
      },
    ],
  },
  // {
  //   text: "Issues",
  //   icon: Audit,
  //   path: "issues",
  // },
  {
    text: "Reports",
    icon: Reports,
    path: "reports",
  },
];

const className =
  "mb-4 px-7 w-[342px] grid gap-x-5 items-center grid-cols-[35px_minmax(0,1fr)] text-left relative";

const textClass = "text-lg font-medium text-app-purple-3";

const getActive = (path, isButton, index, loc) => {
  if (isButton) {
    return paths[index].subMenu.find((sub) => loc?.pathname.endsWith(sub.path))
      ? true
      : false;
  }

  const url = path.path ? `/${path.path}` : "";

  return loc?.pathname.endsWith(`/dashboard${url}`);
};

const NavItem = ({ path, isButton, index, children, navOpen }) => {
  const [open, setOpen] = useState(false);
  const loc = useLocation();

  useEffect(() => {
    if (!navOpen) {
      setOpen(false);
    }
  }, [navOpen]);

  const Icon = path?.icon || Fragment;
  const isActive = getActive(path, isButton, index, loc);

  let fillProps = isActive ? { fill: process.env.REACT_APP_COLOR_4 } : {};

  if (isButton) {
    return (
      <Fragment>
        <button
          disabled={!navOpen}
          className={`${className} mb-7`}
          onClick={() => setOpen((s) => !s)}
        >
          <span className="object-contain text-center scale-75 min-h-40">
            <Icon {...fillProps} />
          </span>
          <p
            className={`${textClass} gap-x-2 grid grid-cols-[minmax(0,1fr)_12px]`}
          >
            <span
              className={`${
                isActive ? "text-app-purple-4" : "text-app-purple-3"
              }`}
            >
              {path.text}
            </span>
            <svg
              width="14"
              height="8"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={`${
                open ? "rotate-0" : "rotate-180"
              } transition-transform  `}
            >
              <path
                d="M1.5 6.75L7 1.25L12.5 6.75"
                stroke="#892E89"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </p>
        </button>
        <div
          className={`overflow-hidden transition-max-height h-auto ${
            open ? "max-h-[500px]" : "max-h-0"
          }`}
        >
          {children}
        </div>
      </Fragment>
    );
  }

  const url = path.path ? `/${path.path}` : "";

  return (
    <NavLink className={`${className} group`} to={`/dashboard${url}`}>
      <span className="object-contain text-center scale-75 min-h-40">
        {path?.icon ? <Icon {...fillProps} /> : null}
      </span>
      <p
        className={`${textClass} group-hover:text-app-purple-4 ${
          isActive ? "text-app-purple-4" : "text-app-purple-3"
        }`}
      >
        {path.text}
      </p>
    </NavLink>
  );
};

const SideBar = ({ navOpen, setNavOpen }) => {
  const logout = useLogout();

  return (
    <nav
      onMouseLeave={() => setNavOpen(false)}
      onMouseEnter={() => setNavOpen(true)}
      className={`fixed py-4 top-0 left-0 h-screen overflow-x-hidden overflow-y-auto bg-app-purple-2 z-10 transition-width ${
        navOpen ? "w-[342px]" : "w-[80px]"
      }`}
    >
      <NavLink
        to="/dashboard"
        className={`  flex justify-center px-7 font-sans min-w-[140px] transition-transform origin-top-left  text-transparent bg-clip-text bg-gradient-to-r from-purple-400  to-pink-600 font-semibold mb-3 ${
          navOpen
            ? "translate-x-0 scale-100 "
            : "-translate-x-[8px] scale-[0.6]"
        }`}
      >
        <div
          key={ReachLogo}
          className=" w-[12rem] h-[6rem] bg-no-repeat bg-contain bg-center "
          style={{
            backgroundImage: `url("${ReachLogo}")`,
          }}
        ></div>
        {/* Reach */}
      </NavLink>

      <Transition
        show={navOpen}
        enter="transition-opacity transition-transform duration-75"
        enterFrom="opacity-0 scale-0"
        enterTo="opacity-100 scale-100"
        leave="transition-opacity transition-transform duration-75"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-0"
      >
        <h3 className="text-base font-semibold leading-5 text-center uppercase  text-app-purple-3">
          Module
        </h3>
      </Transition>

      <div className="mt-3 h-[70%] overflow-hidden overflow-y-auto inner-scroll">
        {paths.map((p, i) => {
          if (p.subMenu) {
            return (
              <NavItem navOpen={navOpen} index={i} isButton path={p} key={i}>
                {p.subMenu.map((sub, j) => (
                  <NavItem key={j} navOpen={navOpen} path={sub} />
                ))}
              </NavItem>
            );
          }
          return <NavItem navOpen={navOpen} key={i} path={p} />;
        })}
      </div>

      <button className={`${className}`} onClick={logout}>
        <span className="object-contain text-center min-h-40">
          <Logout fill={process.env.REACT_APP_COLOR_4} />
        </span>
        <p className={`${textClass}`}>
          <span className={`text-app-purple-4`}>Logout</span>
        </p>
      </button>
    </nav>
  );
};

export { SideBar };
