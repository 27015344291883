import { useMemo, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getItems } from "../../../redux/slices";
import {
  PrivateRoute,
  ClientPaginatedTable,
  ErrorWithWrapper,
  Loading,
  Success,
  DefaultColumnFilter,
} from "../../../components";

import { FETCH_STATUS, formatNumberAsCurrency } from "../../../utils";

const AllItems = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { itemsList, getItemsStatus, getItemsError } = useSelector(
    (state) => state.items
  );

  const getInfo = useCallback(
    (force) => {
      dispatch(getItems(force));
    },
    [dispatch]
  );

  useEffect(() => {
    if (getItemsStatus === FETCH_STATUS.IDLE) {
      dispatch(getItems());
    }
  }, [dispatch, getItemsStatus]);

  const columns = useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: "name",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "ITEM Class",
        accessor: "itemClass",
      },
      {
        Header: "ITEM Group",
        accessor: "itemGroup",
      },
      {
        Header: "Default Price",
        accessor: "price",
        Cell: ({ value }) => formatNumberAsCurrency(value),
      },
    ];
  }, []);

  const onRowClick = (row) => () => {
    navigate(
      `/dashboard/items/edit/${row.original.itemGroup}/${row.original.id}`
    );
  };

  //comment to test push

  return (
    <PrivateRoute redirectTo="/dashboard/items">
      <div className="w-[95%] mx-auto mt-6">
        {/* Table Header */}
        <div className="flex items-center justify-between p-4 px-8 rounded-t-lg bg-gradient-to-b from-app-purple-4 to-app-purple-3">
          <div className="flex gap-4">
            <svg
              width="23"
              height="21"
              viewBox="0 0 23 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.75 0.5V6.5H10.75V9.5H4.75C3.64 9.5 2.75 10.39 2.75 11.5V14.5H0.75V20.5H6.75V14.5H4.75V11.5H10.75V14.5H8.75V20.5H14.75V14.5H12.75V11.5H18.75V14.5H16.75V20.5H22.75V14.5H20.75V11.5C20.75 10.39 19.86 9.5 18.75 9.5H12.75V6.5H14.75V0.5H8.75Z"
                fill="white"
              />
            </svg>
            <span className="text-xl font-semibold text-white">
              Food & Items
            </span>
          </div>

          <div className="flex gap-3 buttongroup">
            {/* <button className="p-3 px-5 rounded-md text-white bg-[#C96FCC] shadow-md hover:shadow-sm">
              Import
            </button> */}
            <button
              className="p-3 px-5 rounded-md text-[#C96FCC] bg-white shadow-md hover:shadow-sm"
              onClick={() => {
                navigate("/dashboard/items/create");
              }}
            >
              Create
            </button>
          </div>
        </div>

        <ErrorWithWrapper
          error={getItemsError}
          show={getItemsStatus === FETCH_STATUS.REJECTED}
          retry={getInfo}
        />

        <Loading
          wrapperClassName="relative min-h-[50vh]"
          show={getItemsStatus === FETCH_STATUS.PENDING}
        />

        <Success show={getItemsStatus === FETCH_STATUS.RESOLVED}>
          {/* Main Table */}
          <ClientPaginatedTable
            filterColumnsKeys={["name"]}
            toolbarChildren={
              <p className="w-1/2 ml-2 text-sm opacity-70">
                if you can’t find the item you need, Use the “Create" button
                above...
              </p>
            }
            onRowClick={onRowClick}
            tableData={itemsList}
            columns={columns}
            getHeaderProps={(column) => ({
              className:
                "px-3 pt-8 pb-2 text-base font-medium border border-[#00000033] ",
              ...(column.id === "name" ? column?.getSortByToggleProps() : {}),
            })}
            getCellProps={(cellInfo) => ({
              style: {},
              className: `${
                cellInfo?.column?.id === "name" ? "text-app-purple-9" : ""
              } ${cellInfo?.column?.id === "days" ? "break-all" : ""} `,
            })}
            getColumnProps={() => ({ style: {} })}
          />
        </Success>
      </div>
    </PrivateRoute>
  );
};

export { AllItems };
