import stc from "string-to-color";
import add from "date-fns/add";
import { Session } from "../models";

import sessions from "./sessions.json";
import movies from "./movies.json";
import screens from "./screens.json";
import formats from "./filmFormat.json";
import cards from "./priceCardTickets.json";
import priceCards from "./priceCards.json";
import cinemas from "./cinemas.json";
import tickets from "./tickets.json";

const getBgColor = (value) => {
  return stc(JSON.stringify(value));
};

const getTextColor = (
  hexcolor,
  darkTextColor = "#222222",
  lightTextColor = "#fff"
) => {
  hexcolor = hexcolor.replace("#", "");

  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  return yiq >= 128 ? darkTextColor : lightTextColor;
};

const fakeGetMovies = () => {
  return new Promise((resolve) => setTimeout(resolve({ data: movies }), 1000));
};

const fakeGetSessions = () => {
  const sessionsWithToday = [...sessions];
  const movieKeys = Object.keys(movies);

  const randomIndex = Math.floor(Math.random() * (movieKeys.length - 1));
  const movie = movies[movieKeys[randomIndex]];

  sessionsWithToday.push(
    Session.create({
      filmId: movie.id,
      title: movie.title,
      screenId: "1",
      startTime: new Date(),
      endTime: add(new Date(), { minutes: movie.duration }),
      trailerTime: 5,
      priceCard: "Test",
    })
  );
  return new Promise((resolve) =>
    setTimeout(
      resolve({
        data: sessionsWithToday.sort((a, b) => {
          return (
            new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
          );
        }),
      }),
      1000
    )
  );
};

const fakeGetScreens = () => {
  return new Promise((resolve) => setTimeout(resolve({ data: screens }), 1000));
};

const fakeGetFormats = () => {
  return new Promise((resolve) => setTimeout(resolve({ data: formats }), 1000));
};

const fakeGetPriceCards = () => {
  return new Promise((resolve) => setTimeout(resolve({ data: cards }), 1000));
};

const fakeGetPriceCards2 = () => {
  return new Promise((resolve) =>
    setTimeout(resolve({ data: priceCards }), 1000)
  );
};

const fakeGetCinemas = () => {
  return new Promise((resolve) => setTimeout(resolve({ data: cinemas }), 1000));
};

const fakeGetTickets = () => {
  return new Promise((resolve) => setTimeout(resolve({ data: tickets }), 1000));
};

const fakeGetCardInfo = (id) => {
  return new Promise((resolve, reject) =>
    setTimeout(() => {
      const card = priceCards.find((c) => c.id === +id);

      if (!card) {
        reject();
      } else {
        resolve(card);
      }
    }, 1000)
  );
};

const fakeFetch = (callback = () => {}, time = 1000) => {
  return new Promise((resolve) =>
    setTimeout(() => {
      callback();
      resolve();
    }, time)
  );
};

export {
  fakeGetMovies,
  fakeGetSessions,
  fakeGetScreens,
  fakeGetFormats,
  fakeGetPriceCards,
  getBgColor,
  fakeFetch,
  getTextColor,
  fakeGetPriceCards2,
  fakeGetCinemas,
  fakeGetTickets,
  fakeGetCardInfo,
};
