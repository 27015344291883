const getDisplayName = (
  activeItem,
  items,
  value,
  defVal,
  defInput,
  endpointId
) => {
  // console.log({value,activeItem,items})
  if (defVal === value) {
    return defInput;
  }

  const item = items?.find((i) => i.id === value || i[endpointId] === value);
  // console.log({item})

  return item?.name;
};

// const getScreenFormat = (items, value) => {

//   const filterFormat = items.filter((item) => value.includes(item.id));

//   return filterFormat;
// };

// const getFilmDropdown = (items, value) => {
//   return items.filter(item => value.includes(item.id))
// }

// this is for multiselect dropdown
const getSelectedItem = (items, value, objPropt) => {
  return items?.filter((item) =>
    value?.includes(objPropt ? item[objPropt] : item)
  );
};

// this is for search select dropdown
const getSelectedItem2 = (items, value, objPropt) => {
  if (!items?.length) {
    return undefined;
  }
  return items.find((item) => item[objPropt] === value);
};

// const getClaimItem = (items, value) => {
//   return items.filter((item) => value.includes(item));
// };

const getAPIErrMessage = (resp, defaultMessage) => {
  return (
    resp ||
    resp?.error?.data?.errors[0]?.errorMessages[0] ||
    resp?.response?.data?.errors[0]?.errorMessages[0] ||
    resp?.data?.title ||
    resp?.data?.errors[0]?.errorMessages[0] ||
    defaultMessage ||
    "Error occurred"
  );
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

const export2Word = (element, title) => {
  let html, link, blob, url, css;

  css =
    "<style>" +
    "@page showtimes-doc{size: 841.95pt 595.35pt;mso-page-orientation: landscape;}" +
    // "img{display: block;}" +
    "table{border-collapse:collapse;}td{border:1px gray solid;width:5em;padding:2px;}" +
    "</style>";
  html = document.getElementById(element).innerHTML;
  blob = new Blob(["\ufeff", css + html], {
    // type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    type: "application/msword",
  });

  url = URL.createObjectURL(blob);
  link = document.createElement("A");
  link.href = url;
  link.download = title; // default name without extension
  document.body.appendChild(link);
  if (navigator.msSaveOrOpenBlob)
    navigator.msSaveOrOpenBlob(blob, `${title}.doc`); // IE10-11
  else link.click(); // other browsers
  document.body.removeChild(link);
};

const checkSeatNumber = () => {};
const getDefinitionValues = ({ layout, value, index }) => {
  const definition = layout?.definitions.find(
    (def) => def?.columnNumber === index + 1
  );

  if (definition && definition[value] !== undefined) {
    return definition[value];
  } else {
    return undefined;
  }
};

const getSelectedSeat = ({ formDetails, index, selectedSeats }) => {
  if (selectedSeats.length > 0) {
    const definition = formDetails?.layout?.definitions.find(
      (def) => def?.columnNumber === index + 1
    );
    const isSelected = selectedSeats.some(
      (seat) =>
        seat.columnNumber === definition?.columnNumber &&
        formDetails?.layout.rowNumber === seat.rowNumber
    );
    // console.log({ definition});
    return isSelected;
  }
};

const changeArrShape = (prop, arr, shape = {}) => {
  const newShape = arr?.reduce((acc, curr) => {
    shape[curr[prop]] = curr;
    return acc;
  }, shape);

  return newShape || [];
};

export {
  getDisplayName,
  getSelectedSeat,
  export2Word,
  changeArrShape,
  getAPIErrMessage,
  capitalizeFirstLetter,
  getSelectedItem,
  getSelectedItem2,
  getDefinitionValues,
};
