import React, { forwardRef } from "react";

const BannerInputField = forwardRef(
  ({ uploadName, isMultiple, children, id, ...props }, ref) => {
    return (
      <>
        <div className="mx-auto w-[100%] border-2 border-[#AA7DAA] rounded-xl pb-2">
          <div className="text-white  bg-[#a480a4] p-2 rounded-t-lg">
            <div className="flex justify-start">
              <label htmlFor={id}>
                <div
                  type="button"
                  className="text-black bg-gradient-[180deg] to-[#b4aeb4] from-[#f5eef5] px-4 py-2 rounded-lg text-sm"
                >
                  {uploadName}
                </div>
              </label>
            </div>
          </div>
          <input
            ref={ref}
            type="file"
            multiple={isMultiple}
            id={id}
            className={`w-0 hidden`}
            //   onChange={onChange}
            // {...register("")}
            // ${className}`}
            {...props}
          />
          {children}
        </div>
      </>
    );
  }
);

export default BannerInputField;

//  <div className="grid grid-cols-4 border border-[#A86AAA] border-x-0 border-t-0 bg-app-purple-13">
//                     {itemImages.map((image,i) => (
//                       <>
//                         <div className="flex flex-col items-center" key={i}>

//                       <div
//                       key={image}
//                       className=" w-[9rem] h-[13rem] my-3 bg-no-repeat bg-contain bg-center "
//                       style={{
//                         backgroundImage: `url("${image}")`,
//                       }}
//                           ></div>
//                           <div>

//                       <button
//                        type="button"
//                         className="bg-gradient-[180deg] block to-[#764877] from-[#A86AAA] px-2 py-1 rounded-md text-[12px] text-white m-2 w-[4rem]"
//                         onClick={() =>
//                         handleRemove(i)
//                            }
//                            >
//                         Remove
//                       </button>
//                           </div>
//                       </div>
//                         </>
//                     ))}
//                  </div>
