import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import ContractTable from "../../../../components/FilmContract/ContractTable";
import {Delete, Edit} from "../../../../components/Icons";
import {
  ConfirmModal,
  DefaultColumnFilter,
  ErrorModal,
  ErrorWithWrapper,
  Loading,
  Success,
  SuccessModal,
} from "../../../../components";
import {getAllContracts, selectContract} from "../../../../redux/slices";
import {
  API_URL,
  axiosApiInstance,
  FETCH_STATUS,
  formatDateTime,
  getAPIErrMessage,
  getTableProps,
} from "../../../../utils";
import {useLoggedInuser, useRequest} from "../../../../hooks";

const AllContracts = () => {
  const [contractId, setContractId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allContracts, allContractsStatus, allContractsErr } =
    useSelector(selectContract);
  const { profile } = useLoggedInuser();
  const {
    err,
    status: deleteStatus,
    resetErr,
    setResolved,
    setPending,
    setRejected,
  } = useRequest();

  const getInfo = useCallback(
    (force) => {
      dispatch(getAllContracts(force));
    },
    [dispatch]
  );

  const getContracts = useCallback(() => {
    profile?.cinemaId && dispatch(getAllContracts(profile?.cinemaId));
  }, [dispatch, profile?.cinemaId]);

  useEffect(() => {
    getContracts();
  }, [getContracts]);

  const onEdit = useCallback(
    (id) => {
      navigate(`edit-contract/${id}`);
    },
    [navigate]
  );

  const handleOpen = useCallback((id) => {
    setContractId(id);
    setOpenModal(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleCloseSuccessModal = useCallback(() => {
    setShowSuccess(false);
    setOpenModal(false);
  },[]);

  const handleDeleteContract = async () => {
    try {
      setPending();
      await axiosApiInstance
        .delete(`${API_URL}/MovieContracts/${contractId}/Delete`)
        .then(() => {
          getContracts();
        });
      setResolved();
      setShowSuccess(true);
    } catch (err) {
      setRejected(err || "unable to delete contract");
    }
  };

  const columns = useMemo(() => {
    return [
      {
        Header: "Contract Start Date",
        accessor: "startDate",
        Cell: ({ value }) =>
          formatDateTime(new Date(value), "EEE, MMM dd, yyyy"),
      },
      {
        Header: "Date Created",
        accessor: "dateCreated",
        Cell: ({ value }) =>
          formatDateTime(new Date(value), "EEE, MMM dd, yyyy"),
      },
      {
        Header: "Cinema",
        accessor: "cinema",
      },
      {
        Header: "Film",
        accessor: "film",
        id:"film",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Action",
        Cell: ({ cell }) => {
          const contract = cell?.row?.original;
          return (
            <span className="flex">
              <button onClick={() => handleOpen(contract.id)}>
                <Delete />
              </button>
              <button className="pl-2" onClick={() => onEdit(contract.id)}>
                <Edit />
              </button>
            </span>
          );
        },
      },
    ];
  }, [onEdit, handleOpen]);

  return (
    <>
      <div className="flex justify-end mb-8">
        <button
          onClick={() => navigate("/dashboard/film-contracts/create-contract")}
          className="text-white bg-gradient-[180deg] to-[#764877] from-[#A86AAA] px-4 py-2 rounded-lg text-sm"
        >
          Create
        </button>
      </div>

      <ErrorWithWrapper
        error={allContractsErr}
        show={allContractsStatus === FETCH_STATUS.REJECTED}
        retry={getInfo}
      />
      <div className="relative">
        <Loading
          wrapperClassName="absolute top-0 left-0 right-0 min-h-[50vh]"
          show={allContractsStatus === FETCH_STATUS.PENDING}
        />

        <Success show={allContractsStatus === FETCH_STATUS.RESOLVED}>
          <p className="uppercase text-lg font-semibold">
            list of film contracts
          </p>
          <ContractTable
            columns={columns}
            filterColumnsKeys={["film",  "isActive"]}
            tableData={allContracts}
            {...getTableProps()}
            rowClass="hover:!bg-transparent !cursor-default"
          />
          {allContracts?.length === 0 && (
            <p className="flex justify-center">No contract available</p>
          )}
        </Success>
      </div>
      {openModal && (
        <>
          <ConfirmModal
            onClick={handleDeleteContract}
            text="Are you sure you want to delete this contract?"
            onClose={handleClose}
            show={openModal}
            status={deleteStatus}
          />
        </>
      )}

      <SuccessModal
        onClick={handleCloseSuccessModal}
        message="Contract deleted successfully."
        show={showSuccess}
      />

      <ErrorModal
        show={deleteStatus === FETCH_STATUS.REJECTED}
        message={getAPIErrMessage(err, " Unable to delete contract")}
        onClose={resetErr}
        onClick={resetErr}
      />
    </>
  );
};

export { AllContracts };
