import { useMemo,useCallback } from "react";


const useKioskLayout = (update, kioskLayout, remove, kioskItems) => {

  const removePage = useCallback(
    ({ pageIndex }) => {
      remove(pageIndex);
    },
    [remove]
  );

  const addItem = useCallback(
    ({ pageIndex }) => {
      update(pageIndex, {
        pageName: kioskLayout[pageIndex].pageName,
        pageItems: [...kioskLayout[pageIndex].pageItems, { itemId: "" }],
      });
    },
    [kioskLayout, update]
  );

  

  const removeItem = useCallback(
    ({ pageIndex, itemIndex }) => {
      update(pageIndex, {
        pageName: kioskLayout[pageIndex].pageName,
        pageItems: kioskLayout[pageIndex].pageItems.filter((item, i) => {
          return i !== itemIndex;
        }),
      });
    },
    [kioskLayout, update]
  );

  const itemsWithKioskSource = useMemo(() => {
    return kioskItems?.filter(
      (item) =>
        item?.itemSources?.some((source) => source?.source === "Kiosk") ||
        item?.itemPackageSources?.some((source) => source?.source === "Kiosk")
    );
  }, [kioskItems]);

  return { removePage, addItem, removeItem, itemsWithKioskSource };
};

export { useKioskLayout };
