import React from "react";
import { useRequest } from "../../../../hooks";
import { useDispatch } from "react-redux";
import { API_URL, axiosApiInstance, FETCH_STATUS } from "../../../../utils";
import { addVendors } from "../../../../redux/slices";
import {
  ItemFormErrorModal,
  PageHeader,
  PrivateRoute,
  SuccessModal,
} from "../../../../components";
import VendorForm from "../../../../components/vendors/VendorForm";

const CreateVendor = (props) => {
  const { err, status, setResolved, setPending, setRejected, resetErr } =
    useRequest();

  const dispatch = useDispatch();

  const onSave = async (data) => {
    try {
      setPending();

      const res = await axiosApiInstance.post(
        `${API_URL}/Vendors/Create`,
        data
      );

      const vendor = res.data.data;
      dispatch(addVendors(vendor));

      setResolved("Success");
    } catch (e) {
      setRejected(e || "Unable to create vendor");
    }
  };

  return (
    <PrivateRoute redirectTo="/dashboard/stock/vendors/create">
      <PageHeader>
        <h1 className="text-2xl font-semibold text-white ">Create Vendor</h1>
      </PageHeader>
      <VendorForm
        isLoading={status === FETCH_STATUS.PENDING}
        onSubmit={onSave}
      />
      <SuccessModal
        message="Created Vendor."
        show={status === FETCH_STATUS.RESOLVED}
        backLink="/dashboard/stock/vendors"
      />
      <ItemFormErrorModal
        message={err}
        show={!!err}
        onClick={resetErr}
        onClose={resetErr}
      />
    </PrivateRoute>
  );
};

export { CreateVendor };
