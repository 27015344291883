import React, {lazy, Suspense} from "react";
import {Portal, Spinner} from "./components";

import {DBHome, FilmScheduling, Login, NotFound} from "./pages";

//lazy load the rest to help with bundle size
const AllFilms = lazy(() =>
  import("./pages").then((module) => ({ default: module.AllFilms }))
);
const FilmDetails = lazy(() =>
  import("./pages").then((module) => ({ default: module.FilmDetails }))
);

const PriceCards = lazy(() =>
  import("./pages").then((module) => ({ default: module.PriceCards }))
);

const PriceCardDetails = lazy(() =>
  import("./pages").then((module) => ({ default: module.PriceCardDetails }))
);

const PriceCardCreate = lazy(() =>
  import("./pages").then((module) => ({ default: module.PriceCardCreate }))
);
const AllItems = lazy(() =>
  import("./pages").then((module) => ({ default: module.AllItems }))
);

const AllTickets = lazy(() =>
  import("./pages").then((module) => ({ default: module.AllTickets }))
);

const CreateTicket = lazy(() =>
  import("./pages").then((module) => ({ default: module.CreateTicket }))
);

const EditTicket = lazy(() =>
  import("./pages").then((module) => ({ default: module.EditTicket }))
);

const ItemForm = lazy(() =>
  import("./pages").then((module) => ({ default: module.ItemForm }))
);

const AllScreens = lazy(() =>
  import("./pages").then((module) => ({ default: module.AllScreens }))
);

const CreateScreen = lazy(() =>
  import("./pages").then((module) => ({ default: module.CreateScreen }))
);

const EditScreen = lazy(() =>
  import("./pages").then((module) => ({ default: module.EditScreen }))
);

const AllStaff = lazy(() =>
  import("./pages").then((module) => ({ default: module.AllStaff }))
);

const StaffClaims = lazy(() =>
  import("./pages").then((module) => ({ default: module.StaffClaims }))
);

const CreateStaff = lazy(() =>
  import("./pages").then((module) => ({ default: module.CreateStaff }))
);

const EditStaff = lazy(() =>
  import("./pages").then((module) => ({ default: module.EditStaff }))
);
const EditTask = lazy(() =>
  import("./pages").then((module) => ({ default: module.EditTask }))
);
const EditShift = lazy(() =>
  import("./pages").then((module) => ({ default: module.EditShift }))
);

const StaffShifts = lazy(() =>
  import("./pages").then((module) => ({ default: module.StaffShifts }))
);
const CreateStaffShift = lazy(() =>
  import("./pages").then((module) => ({ default: module.CreateShift }))
);
const StaffTasks = lazy(() =>
  import("./pages").then((module) => ({ default: module.StaffTasks }))
);
const CreateStaffTask = lazy(() =>
  import("./pages").then((module) => ({ default: module.CreateTask }))
);
const StaffRoster = lazy(() =>
  import("./pages").then((module) => ({ default: module.RosterSchedule }))
);

const StaffSessions = lazy(() =>
  import("./pages").then((module) => ({ default: module.StaffSessions }))
);

const ReportsList = lazy(() =>
  import("./pages").then((module) => ({ default: module.ReportsList }))
);

const DistributorByFilm = lazy(() =>
  import("./pages").then((module) => ({ default: module.DistributorByFilm }))
);

const BoxOfficePerformance = lazy(() =>
  import("./pages").then((module) => ({ default: module.BoxOfficePerformance }))
);

const CashierSessionReconcilation = lazy(() =>
  import("./pages").then((module) => ({
    default: module.CashierSessionReconcilation,
  }))
);

const SessionReconciliation = lazy(() =>
    import("./pages").then((module) => ({
        default: module.SessionReconciliation,
    }))
);

const FilmsByScreen = lazy(() =>
  import("./pages").then((module) => ({
    default: module.FilmsByScreen,
  }))
);

const PerformanceReport = lazy(() =>
  import("./pages").then((module) => ({
    default: module.PerformanceReport,
  }))
);

const ItemSalesReport = lazy(() =>
  import("./pages").then((module) => ({
    default: module.ItemSalesReport,
  }))
);

const ItemDetailSalesReport = lazy(() =>
  import("./pages").then((module) => ({
    default: module.ItemDetailSalesReport,
  }))
);

const TransactionsByUserReport = lazy(() =>
  import("./pages").then((module) => ({
    default: module.TransactionsByUserReport,
  }))
);

const DailyCashupReport = lazy(() =>
  import("./pages").then((module) => ({
    default: module.DailyCashupReport,
  }))
);

const DailyOperationsReport = lazy(() =>
  import("./pages").then((module) => ({
    default: module.DailyOperationsReport,
  }))
);

const AdminActions = lazy(() =>
  import("./pages").then((module) => ({
    default: module.AdminActions,
  }))
);

const OrdersBySourceAndChannel = lazy(() =>
  import("./pages").then((module) => ({
    default: module.OrdersBySourceAndChannel,
  }))
);

const OnlineOrders = lazy(() =>
  import("./pages").then((module) => ({
    default: module.OnlineOrders,
  }))
);
const FilmHireReport = lazy(() =>
  import("./pages").then((module) => ({
    default: module.FilmHireReport,
  }))
);

const PinCreate = lazy(() =>
  import("./pages").then((module) => ({
    default: module.PinCreate,
  }))
);

const PinUpdate = lazy(() =>
  import("./pages").then((module) => ({
    default: module.PinUpdate,
  }))
);

const CreateIssue = lazy(() =>
  import("./pages").then((module) => ({
    default: module.CreateIssue,
  }))
);

const IssuesList = lazy(() =>
  import("./pages").then((module) => ({
    default: module.IssuesList,
  }))
);

const IssueDetails = lazy(() =>
  import("./pages").then((module) => ({
    default: module.IssueDetails,
  }))
);
const MetaData = lazy(() =>
  import("./pages").then((module) => ({
    default: module.MetaData,
  }))
);
const CinemaSetting = lazy(() =>
  import("./pages").then((module) => ({
    default: module.CinemaSetting,
  }))
);

const StockRequestOverview = lazy(() =>
  import("./pages").then((module) => ({
    default: module.StockReqOverview,
  }))
);

const StockRequestForm = lazy(() =>
  import("./pages").then((module) => ({
    default: module.StockReqForm,
  }))
);

const PurchaseOrderOverview = lazy(() =>
  import("./pages").then((module) => ({
    default: module.PurchaseOrdOverview,
  }))
);

const PurchaseOrderForm = lazy(() =>
  import("./pages").then((module) => ({
    default: module.PurchaseOrdForm,
  }))
);

const StockReceiptOverview = lazy(() =>
  import("./pages").then((module) => ({
    default: module.StockReceiptOverview,
  }))
);

const StockReceiptForm = lazy(() =>
  import("./pages").then((module) => ({
    default: module.StockReceiptForm,
  }))
);

const AllContracts = lazy(() =>
  import("./pages").then((module) => ({
    default: module.AllContracts,
  }))
);
const ContractForm = lazy(() =>
  import("./pages").then((module) => ({
    default: module.ContractForm,
  }))
);
const StockTransferRequestOverview = lazy(() =>
  import("./pages").then((module) => ({
    default: module.StockTransReqOverview,
  }))
);

const StockTransferRequestForm = lazy(() =>
  import("./pages").then((module) => ({
    default: module.StockTransReqForm,
  }))
);
const AllLocation = lazy(() =>
  import("./pages").then((module) => ({
    default: module.AllLocation,
  }))
);

const CreateLocation = lazy(() =>
  import("./pages").then((module) => ({
    default: module.CreateLocation,
  }))
);

const AllVendor = lazy(() =>
  import("./pages").then((module) => ({
    default: module.AllVendors,
  }))
);

const CreateVendor = lazy(() =>
  import("./pages").then((module) => ({
    default: module.CreateVendor,
  }))
);

const UpdateVendor = lazy(() =>
  import("./pages").then((module) => ({
    default: module.UpdateVendor,
  }))
);

const TransferReceiptOverview = lazy(() =>
  import("./pages").then((module) => ({
    default: module.TransferReceiptOverview,
  }))
);

const TransferReceiptForm = lazy(() =>
  import("./pages").then((module) => ({
    default: module.TransferReceiptForm,
  }))
);

// const StockOverview = lazy(() =>
//   import('./pages').then((module) => ({
//     default: module.StockCount,
//   }))
// );
const StockOverview = lazy(() =>
  import("./pages").then((module) => ({
    default: module.StockCount2,
  }))
);
const UpdateLocation = lazy(() =>
  import("./pages").then((module) => ({
    default: module.UpdateLocation,
  }))
);

const ScheduledReports = lazy(() =>
  import("./pages").then((module) => ({
    default: module.ScheduledReportsList,
  }))
);

const ScheduleReportForm = lazy(() =>
  import("./pages").then((module) => ({
    default: module.ScheduleReportForm,
  }))
);

const GBOByTickets = lazy(() =>
  import("./pages").then((module) => ({
    default: module.GBOByTickets,
  }))
);

const ShowtimeReport = lazy(() =>
  import("./pages").then((module) => ({
    default: module.ShowtimeReport,
  }))
);

const POSLayout = lazy(() =>
  import("./pages").then((module) => ({
    default: module.POSLayout,
  }))
);
const GBOFilmReports = lazy(() =>
  import("./pages").then((module) => ({
    default: module.GBOFilmReports,
  }))
);
const RefundReports = lazy(() =>
  import("./pages").then((module) => ({
    default: module.RefundReports,
  }))
);

const BookingsByGenre = lazy(() =>
  import("./pages").then((module) => ({
    default: module.BookingsByGenre,
  }))
);
const StaffPerformance = lazy(() =>
  import("./pages").then((module) => ({
    default: module.StaffPerformance,
  }))
);
const PerformanceByShowtime = lazy(() =>
  import("./pages").then((module) => ({
    default: module.PerformanceByShowtime,
  }))
);
const PerformanceByFilm = lazy(() =>
  import("./pages").then((module) => ({
    default: module.PerformanceByFilm,
  }))
);

const AllStockTake = lazy(() =>
  import("./pages").then((module) => ({
    default: module.AllStockTake,
  }))
);

const CreateStockTake = lazy(() =>
  import("./pages").then((module) => ({
    default: module.CreateStockTake,
  }))
);

const UpdateStockTake = lazy(() =>
  import("./pages").then((module) => ({
    default: module.UpdateStockTake,
  }))
);
const KioskLayout = lazy(() =>
  import("./pages").then((module) => ({
    default: module.KioskLayout,
  }))
);
const KioskTerminals = lazy(() =>
  import("./pages").then((module) => ({
    default: module.KioskTerminals,
  }))
);
const GBOByScreenFormat = lazy(() =>
  import("./pages").then((module) => ({
    default: module.GBOByScreenFormat,
  }))
);

const MarketingReport = lazy(() =>
  import("./pages").then((module) => ({
    default: module.MarketingReport,
  }))
);

const InvoicePage = lazy(() =>
  import("./pages").then((module) => ({
    default: module.InvoicePage,
  }))
);

const ConnectItemsPage = lazy(() =>
  import("./pages").then((module) => ({
    default: module.AllConnectItems,
  }))
);
const ConnectShowtimesPage = lazy(() =>
  import("./pages").then((module) => ({
    default: module.AllConnectShowtimes,
  }))
);
const ConnectTicketsPage = lazy(() =>
  import("./pages").then((module) => ({
    default: module.AllConnectTickets,
  }))
);
const PaymentChannelPage = lazy(() =>
  import("./pages").then((module) => ({
    default: module.PaymentChannel,
  }))
);
const CreatePaymentChannelPage = lazy(() =>
  import("./pages").then((module) => ({
    default: module.CreatePaymentChannel,
  }))
);
const EditPaymentChannelPage = lazy(() =>
  import("./pages").then((module) => ({
    default: module.EditPaymentChannel,
  }))
);




const PageLoader = () => {
  return (
    <Portal>
      <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex flex-col items-center justify-center w-full h-screen overflow-hidden opacity-75 bg-gray-700/50">
        <Spinner />

        <h2 className="text-xl font-semibold text-center text-white">
          Loading...
        </h2>
        <p className="w-1/3 text-center text-white">
          This may take a few seconds, please don't close this page.
        </p>
      </div>
    </Portal>
  );
};

const Page = ({ component = <></>, ...props }) => {
  const Comp = component;
  return (
    <Suspense fallback={<PageLoader />}>
      <Comp {...props} />
    </Suspense>
  );
};

export {
  NotFound,
  Login,
  DBHome,
  FilmScheduling,
  Page,
  AllFilms,
  FilmDetails,
  PriceCards,
  PriceCardDetails,
  PriceCardCreate,
  AllItems,
  AllTickets,
  EditTicket,
  CreateTicket,
  ItemForm,
  AllScreens,
  CreateScreen,
  EditScreen,
  AllStaff,
  StaffClaims,
  CreateStaff,
  EditStaff,
  EditTask,
  EditShift,
  StaffShifts,
  StaffTasks,
  CreateStaffShift,
  CreateStaffTask,
  StaffRoster,
  StaffSessions,
  ReportsList,
  DistributorByFilm,
  BoxOfficePerformance,
    SessionReconciliation,
  CashierSessionReconcilation,
  FilmsByScreen,
  PerformanceReport,
  ItemSalesReport,
  ItemDetailSalesReport,
  PinCreate,
  PinUpdate,
  CreateIssue,
  IssuesList,
  IssueDetails,
  TransactionsByUserReport,
  DailyCashupReport,
  DailyOperationsReport,
  AdminActions,
  OrdersBySourceAndChannel,
  OnlineOrders,
  MetaData,
  StockRequestOverview,
  StockRequestForm,
  PurchaseOrderOverview,
  PurchaseOrderForm,
  StockReceiptForm,
  StockReceiptOverview,
  AllContracts,
  ContractForm,
  StockTransferRequestOverview,
  StockTransferRequestForm,
  AllLocation,
  CreateLocation,
  UpdateLocation,
  AllVendor,
  CreateVendor,
  UpdateVendor,
  TransferReceiptForm,
  TransferReceiptOverview,
  StockOverview,
  ScheduledReports,
  ScheduleReportForm,
  FilmHireReport,
  GBOByTickets,
  ShowtimeReport,
  GBOFilmReports,
  RefundReports,
  POSLayout,
  BookingsByGenre,
  StaffPerformance,
  PerformanceByFilm,
  PerformanceByShowtime,
  CinemaSetting,
  AllStockTake,
  CreateStockTake,
  UpdateStockTake,
  KioskLayout,
  KioskTerminals,
  GBOByScreenFormat,
  MarketingReport,
  InvoicePage,
  ConnectItemsPage,
  ConnectShowtimesPage,
  ConnectTicketsPage,
  PaymentChannelPage,
  CreatePaymentChannelPage,
  EditPaymentChannelPage
};
