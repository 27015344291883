import { useSelector } from "react-redux";
import { reportData } from "../../redux/slices";
import { useLoggedInuser } from "../../hooks";
import ReachLogo from "../../assets/images/logo.png";
import {
  dateAndTime,
  formatDateTime,
  formatNumberAsCurrency,
} from "../../utils";
import { Th } from "./styles";
const ItemDetailSalesTable = ({ id }) => {
  const { itemSalesDetailReport } = useSelector(reportData);
  const { profile } = useLoggedInuser();
  return (
    <div className="px-3">
      <div className="flex items-center justify-between">
        <div
          key={ReachLogo}
          className=" w-[5rem] h-[5rem]  bg-no-repeat bg-contain bg-center "
          style={{
            backgroundImage: `url("${ReachLogo}")`,
          }}
        ></div>
        <p className="font-bold text-[20px]">Item Detail Sales Report</p>
        <p>{profile.cinema}</p>
      </div>
      <div className=" flex p-2 justify-between">
        <div>
          <p>
            {" "}
            <span className="font-bold">User Name:</span>
            {` ${profile?.firstName} ${profile?.lastName}`}
          </p>
          <p>
            <span className="font-bold">Date Time Generated: </span>
            {formatDateTime(
              itemSalesDetailReport?.paramFilter?.dateTimeGenerated,
              dateAndTime
            )}
          </p>
        </div>
        <div>
          <p className="font-bold">Data Range</p>
          <p>
            {new Date(
              itemSalesDetailReport?.paramFilter?.dateFrom
            ).toDateString()}
          </p>{" "}
          <p>
            {new Date(
              itemSalesDetailReport?.paramFilter?.dateTo
            ).toDateString()}
          </p>
        </div>
      </div>
      <table
        className="table-auto w-full mx-auto mt-8 overflow-hidden text-left border rounded-md border-app-purple-4"
        id={id}
      >
        <thead className="shadow-2xl bg-app-purple-8">
          <tr>
            {" "}
            <Th>S/N</Th>
            <Th>Transaction Time</Th> <Th>Item</Th> <Th>Quantity</Th>{" "}
            <Th>Price</Th> <Th>Sales</Th> <Th>Discount</Th>
            <Th>Cashier</Th>
            <Th>Net</Th>
          </tr>
        </thead>
        <tbody>
          {itemSalesDetailReport?.result.items.length > 0
            ? itemSalesDetailReport?.result.items.map((data, index) => {
                return (
                  <tr
                    className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30"
                    key={data?.id}
                  >
                    <td className="text-center px-2">{index + 1}</td>
                    <td className=" px-2">
                      {new Date(data?.dateCreated)?.toDateString() +
                        " " +
                        " " +
                        new Date(data?.dateCreated)?.toLocaleTimeString()}
                    </td>
                    <td>{data?.item}</td>
                    <td className=" px-2">{data?.quantity}</td>
                    <td className=" px-2">
                      {id ? data?.price : formatNumberAsCurrency(data?.price)}
                    </td>
                    <td className=" px-2">
                      {id ? data?.amount : formatNumberAsCurrency(data?.amount)}
                    </td>
                    <td className=" px-2">{data?.discount}</td>
                    <td className=" px-2">{data?.user}</td>
                    <td className=" px-2">{data?.net?.toFixed(2)}</td>
                  </tr>
                );
              })
            : null}
          <tr className="transition duration-300 border-y-2  bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[13px] font-bold">
            <td className="text-center pt-8 pb-3 text-[16px]"> Total</td>
            <td></td>
            <td></td>
            <td>{itemSalesDetailReport?.result?.total?.quantity}</td>
            <td></td>
            <td>
              {id
                ? itemSalesDetailReport?.result?.total?.amount
                : formatNumberAsCurrency(
                    itemSalesDetailReport?.result?.total?.amount
                  )}
            </td>
            <td></td>
            <td></td>
            <td>
              {id
                ? itemSalesDetailReport?.result?.total?.totalNet
                : formatNumberAsCurrency(
                    itemSalesDetailReport?.result?.total?.totalNet?.toFixed(2)
                  )}
            </td>
          </tr>
        </tbody>
      </table>
      {itemSalesDetailReport?.result?.items?.length < 1 && (
        <p className="text-center py-3"> No Data Found</p>
      )}
    </div>
  );
};
export { ItemDetailSalesTable };
