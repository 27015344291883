import {useEffect, useRef, useState} from "react";
import {
    ExportExcelBtn,
    InputErr,
    PdfButton,
    Pdiv,
    Pdiv2,
    PrivateRoute,
    SessionReportTable,
    Spinner,
} from "../../../components";
import {useNavigate, useParams} from "react-router-dom";
import DatePicker from "react-datepicker";
import {DateTimeInput} from "../../../components/Films/DateTimeInput";
import {useLoggedInuser} from "../../../hooks";
import {getSessionReport, getStaff, reportData, selectStaff,} from "../../../redux/slices";
import {FETCH_STATUS, formatDateForApi, sessionReportSchema} from "../../../utils";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Element, scroller} from "react-scroll";

import {useDispatch, useSelector} from "react-redux";

const CashierSessionReconcilation = () => {
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { sessionReport, sessionReportStatus } = useSelector(reportData);
  const { orderedStaff } = useSelector(selectStaff);
  const { profile } = useLoggedInuser();
  const dispatch = useDispatch();
  const reportRef = useRef(null);
  const { sessionDate, userId } = useParams();

  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(sessionReportSchema),
    defaultValues: {
      userId: "",
      dateFilter: selectedDate,
      // CinemaId: profile.cinemaId,
    },
  });

  useEffect(() => {
    setValue("dateFilter", selectedDate);
    dispatch(getStaff({ force: true, cinemaId: profile.cinemaId }));
  }, [selectedDate, setValue, dispatch, profile]);

  const onSubmit = (data) => {
    dispatch(
      getSessionReport({
        ...data,
        dateFilter: formatDateForApi(new Date(data.dateFilter)),
        force: true,
      })
    );
    scroller.scrollTo("reportContainer", {
      duration: 1500,
      delay: 0,
      smooth: true,
      offset: -100, // Scrolls to element + 50 pixels down the page
    });
  };

  useEffect(() => {
    if ((sessionDate, userId)) {
      setSelectedDate(new Date(sessionDate));
      setValue("dateFilter", sessionDate);
      setValue("userId", userId);
      onSubmit({ userId, dateFilter: sessionDate });
    }
  }, [sessionDate, userId]);

  const xlsId = ["cash1", "cash2", "cash3"];
  return (
    <PrivateRoute
      redirectTo={"/dashboard/reports/cashier-session-reconcilation"}
    >
      <div className="w-[95%] mx-auto mt-6">
        <p className="font-semi-bold px-3 pb-5">
          Cashier Session Reconcilation
        </p>
        <div className="flex items-center justify-between p-4 px-8 h-[50px] rounded-t-lg bg-[#C96FCC]">
          <div className="flex gap-4">
            <span className="text-xl font-semibold text-white"></span>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex items-center justify-end py-3 px-4 bg-white ">
            <div className="flex gap-3 buttongroup ">
              <button
                className="p-1 px-3 rounded-md text-[#C96FCC] bg-[#ffe583] shadow-md hover:shadow-sm"
                type="button"
                onClick={() => {
                  navigate("/dashboard/reports");
                }}
              >
                Cancel
              </button>
              <button
                className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm flex items-center"
                type="button"
                onClick={() => {
                  navigate("/dashboard/sessions");
                }}
              >
                <span> View Sessions </span>
              </button>
              <button
                className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm flex items-center"
                type="submit"
              >
                <span> Run Report </span>
                <Spinner
                  className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                  status={sessionReportStatus}
                />
              </button>
            </div>
          </div>
          <div className="bg-app-purple-2 p-3 pt-[30px]">
            <Pdiv label={"Select Date"}>
              <DatePicker
                placeholderText="End Date"
                selected={new Date(selectedDate)}
                onChange={(value) => setSelectedDate(new Date(value))}
                customInput={
                  <DateTimeInput
                    className="bg-app-purple-8"
                    dateFormat={"EEE, MMM dd, yyyy"}
                  />
                }
              />
            </Pdiv>
            <Pdiv2 label={"User"} name={"userId"} {...register("userId")}>
              <option value={""}>Select User</option>
              {orderedStaff?.map((singleStaff) => {
                return (
                  <option value={singleStaff.id}>
                    {singleStaff.firstName + " " + singleStaff.lastName}
                  </option>
                );
              })}
            </Pdiv2>
            <InputErr err={errors?.userId?.message} />

            {/* <Pdiv2 label={"Site"} name={"CinemaId"} {...register("CinemaId")}>
              <option value={""}>Select Cinema</option>
              <option value={profile.cinemaId}>{profile.cinema}</option>
            </Pdiv2>
            <InputErr err={errors?.CinemaId?.message} /> */}
          </div>
        </form>
        <Element name="reportContainer">
          {Object.keys(sessionReport).length > 0 &&
            sessionReportStatus !== FETCH_STATUS.REJECTED && (
              <>
                <div className="flex justify-center mt-5 space-x-4">
                  <PdfButton
                    refValue={reportRef}
                    docTitle={"cashier-session"}
                  />
                  <ExportExcelBtn id={xlsId} name={"cashier-session.xlsx"} />
                </div>
                <div className="hidden">
                  <SessionReportTable
                      sessionReport={sessionReport}
                    id1={"cash1"}
                    id2={"cash2"}
                    id3={"cash3"}
                  />
                </div>
                <div ref={reportRef}>
                  <SessionReportTable sessionReport={sessionReport} />
                </div>
              </>
            )}
          {sessionReportStatus === FETCH_STATUS.REJECTED && (
            <p className="text-center py-3">No data Found</p>
          )}
        </Element>
      </div>
    </PrivateRoute>
  );
};
export { CashierSessionReconcilation };
