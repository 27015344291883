const FormGroup = (props) => {
  return (
    <div className={`flex items-center ${props.className}`}>
      {props.children}
    </div>
  );
};

const Label = (props) => {
  return (
    <>
      <label className={`flex justify-end w-[10rem] mr-3 ${props.className}`}>
        {props.children}
      </label>
    </>
  );
};

export { FormGroup, Label };
