import classNames from "classnames";
import React from "react";
import { useTable } from "react-table";
import styles from "./defaultTable.module.css";

const DefaultListTable = ({
  columns,
  data,
  id,
  isFooter = false,
  footerTDStyle,
  headerClass,
  bodyClass,
  tableClass,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = useTable({ data: data, columns: columns });

  if (!data.length) {
    return (
      <div>
        <h4 className="m-4 text-base font-semibold text-center text-app-purple-4">
          No data
        </h4>
      </div>
    )
  }
  return (
    <>
      <div className={`overflow-auto`}>
        <table
          id={id}
          {...getTableProps()}
          className={classNames(
            `${tableClass} w-full mx-auto overflow-hidden text-left border rounded-md border-app-purple-4`
            // "w-full m-auto mt-6 text-sm text-left",
            // styles.table
          )}
        >
          <thead className="shadow-2xl bg-app-purple-8">
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, i) => (
                  <th
                    {...column.getHeaderProps()}
                    className={`px-3 py-3 font-normal tracking-wide uppercase ${headerClass} border border-gray-300 text-md`}
                    key={i}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="text-[#534C7E]">
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={index}
                  className={`transition duration-300 border bg-app-purple-2 ${bodyClass} border-app-purple-4 border-opacity-30`}
                >
                  {row.cells.map((cell, i) => {
                    return (
                      <td
                        data-label={cell.column.Header}
                        {...cell.getCellProps()}
                        className="py-3 min-w-min px-3"
                        key={i}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          {isFooter && (
            <tfoot>
              {footerGroups.map((footerGroup, i) => (
                <tr
                  key={i}
                  {...footerGroup.getFooterGroupProps()}
                  className="bg-app-purple-8 border-b"
                >
                  {footerGroup.headers.map((column, i) => (
                    <td
                      key={i}
                      {...column.getFooterProps}
                      className={`px-3 py-3 text-[#534C7E] min-w-min ${footerTDStyle}`}
                    >
                      {column.render("Footer")}
                    </td>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </table>
      </div>
    </>
  );
};

export { DefaultListTable };
