import React, { useState } from "react";
import { useAuth } from "../../hooks";

import { SideBar, Header, Footer } from "./Navigation";

const Memoize = ({ children }) => (
  <section className="-mr-[255px]">{children}</section>
);

const className = "transition-transform origin-left";

const Children = React.memo(Memoize);

const WithSideBar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { valid, loading } = useAuth(null, false);

  if (valid && !loading) {
    return (
      <div className="w-full  min-h-screen grid grid-cols-[80px_262px_minmax(0,1fr)] overflow-x-hidden overflow-y-auto">
        <aside className={`min-h-screen`}></aside>

        <div
          className={`${className}   min-h-screen ${
            isOpen ? "scale-x-100" : "scale-x-0"
          }`}
        >
          p
        </div>

        <SideBar navOpen={isOpen} setNavOpen={setIsOpen} />
        <main>
          <Header navOpen={isOpen} setNavOpen={setIsOpen} />
          <div
            className={`${className} pt-4 px-4 w-full min-h-screen ${
              isOpen ? "translate-x-0" : "-translate-x-[262px]"
            }`}
          >
            <Children>{children}</Children>
          </div>
          <div
            className={`${isOpen ? "translate-x-0" : "-translate-x-[262px]"}`}
          >
            <Footer />
          </div>
        </main>
      </div>
    );
  }

  return children; //nested child will now redirect
};

export { WithSideBar };
