import { useCallback, useState } from "react";
import dayjs from 'dayjs'


const useTimeToDateTime = () => {
   
    const convertStringToDateTime =  useCallback((timeString) => {
        const parts = timeString?.split(":");
        const hours = parseInt(parts[0]);
        const minutes = parseInt(parts[1]);
        const now = new Date()

        return dayjs( new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          hours,
          minutes
        ));

        
    }, [])
  
  return [convertStringToDateTime]
}


export {useTimeToDateTime}