import { Transition } from "@headlessui/react";
import { NavLink } from "react-router-dom";
import { Portal } from "./Portal";

const SuccessModal = ({
  onClick = () => {},
  show,
  message = "Your're good to go",
  backLink,
  buttonLinkText = "Done",
}) => {
  return show ? (
    <Portal>
      <Transition show={show}>
        <div className="fixed z-[10000000000] top-0 left-0 right-0 bottom-0 bg-black bg-opacity-25" />

        <div className="fixed z-[10000000001] top-0 left-0 right-0 bottom-0 flex items-center justify-center p-4 overflow-y-auto">
          <div className="flex items-center justify-center p-2 text-center">
            <Transition.Child
              enter="ease-out duration-200"
              enterFrom="scale-0"
              enterTo="scale-100"
              leave="ease-in duration-100"
              leaveFrom="scale-100"
              leaveTo="scale-0"
            >
              <div className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Success
                </h3>

                <div className="mt-2">
                  <div className="text-sm text-gray-500">{message}</div>
                </div>

                <div className="mt-4">
                  {backLink ? (
                    <NavLink
                      to={backLink}
                      className="inline-flex justify-center rounded-md border border-transparent bg-app-purple-4 px-4 py-2 text-sm font-medium text-white "
                      onClick={onClick}
                    >
                      {buttonLinkText}
                    </NavLink>
                  ) : (
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-app-purple-4 px-4 py-2 text-sm font-medium text-white "
                        onClick={onClick}
                    >
                      {buttonLinkText}
                    </button>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Transition>
    </Portal>
  ) : null;
};

export { SuccessModal };
