import { initializeApp } from "firebase/app";
import "firebase/storage";
import "firebase/firestore";
import { getStorage } from "firebase/storage";

let firebaseConfig = {
  apiKey: "AIzaSyCYpg2tBE5xgHoH3qLu-YJz4EcmEDAetHs",
  authDomain: "http://reachcinema-8bcaf.firebaseapp.com/",
  projectId: "reachcinema-8bcaf",
  storageBucket: "reachcinema-8bcaf.appspot.com",
  messagingSenderId: 68418618851,
  appId: "1:68418618851:web:aae2694407528fa8a0ccd6",
};

const app = initializeApp(firebaseConfig);



export const storage = getStorage(app);


