import { useSelector } from "react-redux";
import { reportData } from "../../redux/slices";
import { useLoggedInuser } from "../../hooks";
import ReachLogo from "../../assets/images/logo.png";
import { Th } from "./styles";
import {
  dateAndTime,
  formatDateTime,
  formatNumberAsCurrency,
} from "../../utils";
const DailyOperationsReportTable = ({ id1, id2, id3, id4, id5 }) => {
  const {
    dailyOperationsReport,
    dailyOperationsRevReport,
    dailyOperationsStatReport,
  } = useSelector(reportData);
  const { profile } = useLoggedInuser();

  const revSource1 =
    dailyOperationsRevReport?.result?.revenueSource?.boxOfficeAndBar;

  const revSource2 =
    dailyOperationsRevReport?.result?.revenueSource?.internetBanking;
  const revSourceTotal = dailyOperationsRevReport?.result?.revenueSourceTotal;

  const overOrShort1 =
    dailyOperationsRevReport?.result?.cashierOver?.boxOfficeAndBar;

  const overOrShort2 =
    dailyOperationsRevReport?.result?.cashierOver?.internetBanking;
  const overOrShortTotal = dailyOperationsRevReport?.result?.cashierOverTotal;

  const banking = dailyOperationsRevReport?.result?.expectedBanking;

  const statInfo = dailyOperationsStatReport?.result?.baseInformation;
  const statData = dailyOperationsStatReport?.result?.statisticsReport;

  return (
    <div className="px-3">
      <div className="flex items-center justify-between">
        <div
          key={ReachLogo}
          className=" w-[5rem] h-[5rem]  bg-no-repeat bg-contain bg-center "
          style={{
            backgroundImage: `url("${ReachLogo}")`,
          }}
        ></div>
        <p className="font-bold text-[20px]">Daily Operations Report</p>
        <p>{profile.cinema}</p>
      </div>
      <div className=" flex p-2 justify-between">
        <div>
          <p>
            {" "}
            <span className="font-bold">User Name:</span>
            {` ${profile.firstName} ${profile.lastName}`}
          </p>
          <p>
            <span className="font-bold">Date Time Generated: </span>
            {formatDateTime(
              dailyOperationsReport?.paramFilter?.dateTimeGenerated,
              dateAndTime
            )}
          </p>
        </div>
        <div>
          <p className="font-bold">Report Date</p>
          <p>
            {new Date(
              dailyOperationsReport?.paramFilter?.todayDate
            ).toDateString()}
          </p>
        </div>
      </div>
      <table
        className="table-auto w-full mx-auto mt-4 overflow-hidden text-left border rounded-md border-app-purple-4"
        id={id1}
      >
        <thead className="shadow-2xl bg-app-purple-8">
          <tr className="text-center">
            <Th rowSpan={2}> Film</Th>
            <Th>Admits</Th>
            <Th>Shows</Th> <Th colSpan={2}> Gross ATP</Th>
            <Th>Net Bo</Th>
            <Th>Gross Bo</Th>
            <Th>Adv</Th>
            <Th>Redemptions</Th>
            <Th>Gross</Th>
            <Th>Net</Th>
          </tr>
          <tr>
            <Th colSpan={2}></Th>
            <Th>Incl Comps</Th> <Th>Excl Comps</Th>
            <Th>Revenue</Th>
            <Th>Revenue</Th>
            <Th>Sales</Th>
            <Th></Th>
            <Th>Receipts</Th>
            <Th>Receipts</Th>
          </tr>
        </thead>
        <tbody>
          {dailyOperationsReport?.result?.films?.map((data, index) => {
            return (
              <tr
                key={index}
                className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30"
              >
                <td className="pl-1">{data?.name}</td>
                <td className="text-center">{data?.admits}</td>
                <td className="text-center">{data?.shows}</td>
                <td className="text-center">
                  {id1
                    ? data?.inclComps
                    : formatNumberAsCurrency(data?.inclComps)}
                </td>
                <td className="text-center">
                  {id1
                    ? data?.exclComps
                    : formatNumberAsCurrency(data?.exclComps)}
                </td>

                <td className="text-center">
                  {id1
                    ? data?.netBORevenue
                    : formatNumberAsCurrency(data?.netBORevenue?.toFixed(2))}
                </td>
                <td className="text-center">
                  {id1
                    ? data?.grossBORevenue
                    : formatNumberAsCurrency(data?.grossBORevenue)}
                </td>
                <td className="text-center">
                  {id1
                    ? data?.advSales
                    : formatNumberAsCurrency(data?.advSales)}
                </td>
                <td className="text-center">
                  {id1
                    ? data?.redemptions
                    : formatNumberAsCurrency(data?.redemptions)}
                </td>
                <td className="text-center">
                  {id1
                    ? data?.grossReceipts
                    : formatNumberAsCurrency(data?.grossReceipts)}
                </td>
                <td className="text-center">
                  {id1
                    ? data?.netReceipts
                    : formatNumberAsCurrency(data?.netReceipts?.toFixed(2))}
                </td>
              </tr>
            );
          })}
          <tr className="transition duration-300 border-y-2   bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[16px] text-app-purple-4 font-bold ">
            <td className="pl-1">Total For films</td>
            <td className="text-center">
              {dailyOperationsReport?.result?.filmsTotal?.admits}
            </td>
            <td className="text-center">
              {dailyOperationsReport?.result?.filmsTotal?.shows}
            </td>
            <td className="text-center">
              {id1
                ? dailyOperationsReport?.result?.filmsTotal?.inclComps
                : formatNumberAsCurrency(
                    dailyOperationsReport?.result?.filmsTotal?.inclComps
                  )}
            </td>
            <td className="text-center">
              {id1
                ? dailyOperationsReport?.result?.filmsTotal?.exclComps
                : formatNumberAsCurrency(
                    dailyOperationsReport?.result?.filmsTotal?.exclComps
                  )}
            </td>

            <td className="text-center">
              {id1
                ? dailyOperationsReport?.result?.filmsTotal?.netBORevenue
                : formatNumberAsCurrency(
                    dailyOperationsReport?.result?.filmsTotal?.netBORevenue?.toFixed(
                      2
                    )
                  )}
            </td>
            <td className="text-center">
              {id1
                ? dailyOperationsReport?.result?.filmsTotal?.grossBORevenue
                : formatNumberAsCurrency(
                    dailyOperationsReport?.result?.filmsTotal?.grossBORevenue
                  )}
            </td>
            <td className="text-center">
              {id1
                ? dailyOperationsReport?.result?.filmsTotal?.advSales
                : formatNumberAsCurrency(
                    dailyOperationsReport?.result?.filmsTotal?.advSales
                  )}
            </td>
            <td className="text-center">
              {id1
                ? dailyOperationsReport?.result?.filmsTotal?.redemptions
                : formatNumberAsCurrency(
                    dailyOperationsReport?.result?.filmsTotal?.redemptions
                  )}
            </td>
            <td className="text-center">
              {id1
                ? dailyOperationsReport?.result?.filmsTotal?.grossReceipts
                : formatNumberAsCurrency(
                    dailyOperationsReport?.result?.filmsTotal?.grossReceipts
                  )}
            </td>
            <td className="text-center">
              {id1
                ? dailyOperationsReport?.result?.filmsTotal?.netReceipts
                : formatNumberAsCurrency(
                    dailyOperationsReport?.result?.filmsTotal?.netReceipts?.toFixed(
                      2
                    )
                  )}
            </td>
          </tr>
        </tbody>
      </table>

      <div className=" bg-app-purple-8 h-[60px] flex items-center justify-between pl-3 border-b-2 border-app-purple-4 border-opacity-30">
        <p>Other Sales</p>
        <div className="flex items-center">
          <p className="w-[10rem]">Total Net</p>
          <p className="w-[10rem]">Total Gross</p>
        </div>
      </div>
      <div className="  h-[60px] flex items-center justify-between pl-3 border-b-2 border-app-purple-4 border-opacity-30">
        <p>Total Concession Sales</p>
        <div className="flex items-center">
          <p className="w-[10rem]">
            {id1
              ? dailyOperationsReport?.result?.concessions?.totalNet
              : formatNumberAsCurrency(
                  dailyOperationsReport?.result?.concessions?.totalNet?.toFixed(
                    2
                  )
                )}
          </p>
          <p className="w-[10rem]">
            {id1
              ? dailyOperationsReport?.result?.concessions?.totalGross
              : formatNumberAsCurrency(
                  dailyOperationsReport?.result?.concessions?.totalGross
                )}
          </p>
        </div>
      </div>
      <div className="  h-[60px] flex items-center justify-between pl-3 border-b-2 border-app-purple-4 border-opacity-30">
        <p>Total Other Sales</p>
        <div className="flex items-center">
          <p className="w-[10rem]">
            {id1
              ? dailyOperationsReport?.result?.otherSales?.totalNet
              : formatNumberAsCurrency(
                  dailyOperationsReport?.result?.otherSales?.totalNet?.toFixed(
                    2
                  )
                )}
          </p>
          <p className="w-[10rem]">
            {id1
              ? dailyOperationsReport?.result?.otherSales?.totalGross
              : formatNumberAsCurrency(
                  dailyOperationsReport?.result?.otherSales?.totalGross
                )}
          </p>
        </div>
      </div>
      <div className="  h-[60px] flex items-center justify-between pl-3 border-b-2 border-app-purple-4 border-opacity-30">
        <p>Total Daily Receipts from operations</p>
        <div className="flex items-center">
          <p className="w-[10rem]">
            {id1
              ? dailyOperationsReport?.result?.totalDailyOperationsReceipt
                  ?.totalNet
              : formatNumberAsCurrency(
                  dailyOperationsReport?.result?.totalDailyOperationsReceipt?.totalNet?.toFixed(
                    2
                  )
                )}
          </p>
          <p className="w-[10rem]">
            {id1
              ? dailyOperationsReport?.result?.totalDailyOperationsReceipt
                  ?.totalGross
              : formatNumberAsCurrency(
                  dailyOperationsReport?.result?.totalDailyOperationsReceipt
                    ?.totalGross
                )}
          </p>
        </div>
      </div>
      <table
        className="table-auto w-full mx-auto mt-4 overflow-hidden text-left border rounded-md border-app-purple-4"
        id={id2}
      >
        <thead className="shadow-2xl bg-app-purple-8">
          <Th>Source of Revenue</Th>
          <Th>Cash</Th>
          <Th>Cards</Th>
          <Th>Transfers</Th>
          <Th>Online</Th>
          <Th>Ext. Vouchers</Th>
          <Th>Vouchers</Th>
          <Th>Others</Th>
          <Th>Total Gross</Th>
          <Th>Total Net</Th>
        </thead>
        <tbody>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30">
            <td className="pl-1">Box Office and Candy Bar</td>
            <td className="text-center">
              {" "}
              {id2
                ? revSource1?.cash
                : formatNumberAsCurrency(revSource1?.cash)}
            </td>
            <td className="text-center">
              {" "}
              {id2
                ? revSource1?.cards
                : formatNumberAsCurrency(revSource1?.cards)}
            </td>
            <td className="text-center">
              {id2
                ? revSource1?.transfer
                : formatNumberAsCurrency(revSource1?.transfer)}
            </td>
            <td className="text-center">
              {" "}
              {id2
                ? revSource1?.online
                : formatNumberAsCurrency(revSource1?.online)}
            </td>
            <td className="text-center">
              {" "}
              {id2
                ? revSource1?.externalVouchers
                : formatNumberAsCurrency(revSource1?.externalVouchers)}
            </td>
            <td className="text-center">
              {id2
                ? revSource1?.vouchers
                : formatNumberAsCurrency(revSource1?.vouchers)}
            </td>
            <td className="text-center">
              {" "}
              {id2
                ? revSource1?.others
                : formatNumberAsCurrency(revSource1?.others)}
            </td>
            <td className="text-center">
              {id2
                ? revSource1?.totalGross
                : formatNumberAsCurrency(revSource1?.totalGross)}
            </td>
            <td className="text-center">
              {id2
                ? revSource1?.totalGross
                : formatNumberAsCurrency(revSource1?.totalNet?.toFixed(2))}
            </td>
          </tr>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30">
            <td className="pl-1">Internet Ticketing</td>
            <td className="text-center">
              {" "}
              {id2
                ? revSource2?.cash
                : formatNumberAsCurrency(revSource2?.cash)}
            </td>
            <td className="text-center">
              {" "}
              {id2
                ? revSource2?.cards
                : formatNumberAsCurrency(revSource2?.cards)}
            </td>
            <td className="text-center">
              {id2
                ? revSource2?.transfer
                : formatNumberAsCurrency(revSource2?.transfer)}
            </td>
            <td className="text-center">
              {" "}
              {id2
                ? revSource2?.online
                : formatNumberAsCurrency(revSource2?.online)}
            </td>
            <td className="text-center">
              {" "}
              {id2
                ? revSource2?.cheques
                : formatNumberAsCurrency(revSource2?.cheques)}
            </td>
            <td className="text-center">
              {id2
                ? revSource2?.vouchers
                : formatNumberAsCurrency(revSource2?.vouchers)}
            </td>
            <td className="text-center">
              {" "}
              {id2
                ? revSource2?.others
                : formatNumberAsCurrency(revSource2?.others)}
            </td>
            <td className="text-center">
              {id2
                ? revSource2?.totalGross
                : formatNumberAsCurrency(revSource2?.totalGross)}
            </td>
            <td className="text-center">
              {id2
                ? revSource2?.totalGross
                : formatNumberAsCurrency(revSource2?.totalNet?.toFixed(2))}
            </td>
          </tr>
          <tr className="transition duration-300 border-y-2   bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[16px] text-app-purple-4 font-bold ">
            <td className="pl-1">Totals</td>
            <td className="text-center">
              {id2
                ? revSourceTotal?.cash
                : formatNumberAsCurrency(revSourceTotal?.cash)}
            </td>
            <td className="text-center">
              {id2
                ? revSourceTotal?.cards
                : formatNumberAsCurrency(revSourceTotal?.cards)}
            </td>
            <td className="text-center">
              {id2
                ? revSourceTotal?.transfer
                : formatNumberAsCurrency(revSourceTotal?.transfer)}
            </td>
            <td className="text-center">
              {id2
                ? revSourceTotal?.online
                : formatNumberAsCurrency(revSourceTotal?.online)}
            </td>
            <td className="text-center">
              {id2
                ? revSourceTotal?.cheques
                : formatNumberAsCurrency(revSourceTotal?.cheques)}
            </td>
            <td className="text-center">
              {id2
                ? revSourceTotal?.vouchers
                : formatNumberAsCurrency(revSourceTotal?.vouchers)}
            </td>
            <td className="text-center">
              {" "}
              {id2
                ? revSourceTotal?.others
                : formatNumberAsCurrency(revSourceTotal?.others)}
            </td>
            <td className="text-center">
              {id2
                ? revSourceTotal?.totalGross
                : formatNumberAsCurrency(revSourceTotal?.totalGross)}
            </td>
            <td className="text-center">
              {id2
                ? revSourceTotal?.totalNet
                : formatNumberAsCurrency(revSourceTotal?.totalNet?.toFixed(2))}
            </td>
          </tr>
        </tbody>
      </table>
      <table
        className="table-auto w-full mx-auto mt-4 overflow-hidden text-left border rounded-md border-app-purple-4"
        id={id3}
      >
        <thead className="shadow-2xl bg-app-purple-8">
          <Th>Cashier Over/Short</Th>
          <Th>Cash</Th>
          <Th>Cards</Th>
          <Th>Transfers</Th>
          <Th>Online</Th>
          <Th>Ext. Vouchers</Th>
          <Th>Vouchers</Th>
          <Th>Others</Th>
          <Th>Total Gross</Th>
          <Th>Total Net</Th>
        </thead>
        <tbody>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30">
            <td className="pl-1">Box Office and Candy Bar</td>
            <td className="text-center">
              {" "}
              {id3
                ? overOrShort1?.cash
                : formatNumberAsCurrency(overOrShort1?.cash)}
            </td>
            <td className="text-center">
              {" "}
              {id3
                ? overOrShort1?.cards
                : formatNumberAsCurrency(overOrShort1?.cards)}
            </td>
            <td className="text-center">
              {id3
                ? overOrShort1?.transfer
                : formatNumberAsCurrency(overOrShort1?.transfer)}
            </td>
            <td className="text-center">
              {" "}
              {id3
                ? overOrShort1?.online
                : formatNumberAsCurrency(overOrShort1?.online)}
            </td>
            <td className="text-center">
              {" "}
              {id3
                ? overOrShort1?.externalVouchers
                : formatNumberAsCurrency(overOrShort1?.externalVouchers)}
            </td>
            <td className="text-center">
              {id3
                ? overOrShort1?.vouchers
                : formatNumberAsCurrency(overOrShort1?.vouchers)}
            </td>
            <td className="text-center">
              {" "}
              {id3
                ? overOrShort1?.others
                : formatNumberAsCurrency(overOrShort1?.others)}
            </td>
            <td className="text-center">
              {id3
                ? overOrShort1?.totalGross
                : formatNumberAsCurrency(overOrShort1?.totalGross)}
            </td>
            <td className="text-center">
              {id3
                ? overOrShort1?.totalGross
                : formatNumberAsCurrency(overOrShort1?.totalNet?.toFixed(2))}
            </td>
          </tr>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30">
            <td className="pl-1">Internet Ticketing</td>
            <td className="text-center">
              {" "}
              {id3
                ? overOrShort2?.cash
                : formatNumberAsCurrency(overOrShort2?.cash)}
            </td>
            <td className="text-center">
              {" "}
              {id3
                ? overOrShort2?.cards
                : formatNumberAsCurrency(overOrShort2?.cards)}
            </td>
            <td className="text-center">
              {id3
                ? overOrShort2?.transfer
                : formatNumberAsCurrency(overOrShort2?.transfer)}
            </td>
            <td className="text-center">
              {" "}
              {id3
                ? overOrShort2?.online
                : formatNumberAsCurrency(overOrShort2?.online)}
            </td>
            <td className="text-center">
              {" "}
              {id3
                ? overOrShort2?.cheques
                : formatNumberAsCurrency(overOrShort2?.cheques)}
            </td>
            <td className="text-center">
              {id3
                ? overOrShort2?.vouchers
                : formatNumberAsCurrency(overOrShort2?.vouchers)}
            </td>
            <td className="text-center">
              {" "}
              {id3
                ? overOrShort2?.others
                : formatNumberAsCurrency(overOrShort2?.others)}
            </td>
            <td className="text-center">
              {id3
                ? overOrShort2?.totalGross
                : formatNumberAsCurrency(overOrShort2?.totalGross)}
            </td>
            <td className="text-center">
              {id3
                ? overOrShort2?.totalNet
                : formatNumberAsCurrency(overOrShort2?.totalNet?.toFixed(2))}
            </td>
          </tr>
          <tr className="transition duration-300 border-y-2   bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[16px] text-app-purple-4 font-bold ">
            <td className="pl-1">Totals</td>
            <td className="text-center">
              {id3
                ? overOrShortTotal?.cash
                : formatNumberAsCurrency(overOrShortTotal?.cash)}
            </td>
            <td className="text-center">
              {id3
                ? overOrShortTotal?.cards
                : formatNumberAsCurrency(overOrShortTotal?.cards)}
            </td>
            <td className="text-center">
              {id3
                ? overOrShortTotal?.transfer
                : formatNumberAsCurrency(overOrShortTotal?.transfer)}
            </td>
            <td className="text-center">
              {id3
                ? overOrShortTotal?.online
                : formatNumberAsCurrency(overOrShortTotal?.online)}
            </td>
            <td className="text-center">
              {id3
                ? overOrShortTotal?.cheques
                : formatNumberAsCurrency(overOrShortTotal?.cheques)}
            </td>
            <td className="text-center">
              {id3
                ? overOrShortTotal?.vouchers
                : formatNumberAsCurrency(overOrShortTotal?.vouchers)}
            </td>
            <td className="text-center">
              {" "}
              {id3
                ? overOrShortTotal?.others
                : formatNumberAsCurrency(overOrShortTotal?.others)}
            </td>
            <td className="text-center">
              {id3
                ? overOrShortTotal?.totalGross
                : formatNumberAsCurrency(overOrShortTotal?.totalGross)}
            </td>
            <td className="text-center">
              {id3
                ? overOrShortTotal?.totalNet
                : formatNumberAsCurrency(
                    overOrShortTotal?.totalNet?.toFixed(2)
                  )}
            </td>
          </tr>
        </tbody>
      </table>
      <table
        className="table-auto w-full mx-auto mt-4 overflow-hidden text-left border rounded-md border-app-purple-4"
        id={id4}
      >
        <thead className="shadow-2xl bg-app-purple-8">
          <Th>Expected Banking</Th>
          <Th>Cash</Th>
          <Th>Cards</Th>
          <Th>Transfers</Th>
          <Th>Online</Th>
          <Th>Ext. Vouchers</Th>
          <Th>Vouchers</Th>
          <Th>Others</Th>
          <Th>Total Gross</Th>
          <Th>Total Net</Th>
        </thead>
        <tbody>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30">
            <td className="pl-1">Banking</td>
            <td className="text-center">
              {" "}
              {id4 ? banking?.cash : formatNumberAsCurrency(banking?.cash)}
            </td>
            <td className="text-center">
              {" "}
              {id4 ? banking?.cards : formatNumberAsCurrency(banking?.cards)}
            </td>
            <td className="text-center">
              {id4
                ? banking?.transfer
                : formatNumberAsCurrency(banking?.transfer)}
            </td>
            <td className="text-center">
              {" "}
              {id4 ? banking?.online : formatNumberAsCurrency(banking?.online)}
            </td>
            <td className="text-center">
              {" "}
              {id4
                ? banking?.externalVouchers
                : formatNumberAsCurrency(banking?.externalVouchers)}
            </td>
            <td className="text-center">
              {id4
                ? banking?.vouchers
                : formatNumberAsCurrency(banking?.vouchers)}
            </td>
            <td className="text-center">
              {" "}
              {id4 ? banking?.others : formatNumberAsCurrency(banking?.others)}
            </td>
            <td className="text-center">
              {id4
                ? banking?.totalGross
                : formatNumberAsCurrency(banking?.totalGross)}
            </td>
            <td className="text-center">
              {id4
                ? banking?.totalNet
                : formatNumberAsCurrency(banking?.totalNet?.toFixed(2))}
            </td>
          </tr>
          <tr className="transition duration-300 border-y-2   bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[16px] text-app-purple-4 font-bold ">
            <td className="pl-1">Totals</td>
            <td className="text-center">
              {id4 ? banking?.cash : formatNumberAsCurrency(banking?.cash)}
            </td>
            <td className="text-center">
              {id4 ? banking?.cards : formatNumberAsCurrency(banking?.cards)}
            </td>
            <td className="text-center">
              {id4
                ? banking?.transfer
                : formatNumberAsCurrency(banking?.transfer)}
            </td>
            <td className="text-center">
              {id4 ? banking?.online : formatNumberAsCurrency(banking?.online)}
            </td>
            <td className="text-center">
              {id4
                ? banking?.externalVouchers
                : formatNumberAsCurrency(banking?.externalVouchers)}
            </td>
            <td className="text-center">
              {id4
                ? banking?.vouchers
                : formatNumberAsCurrency(banking?.vouchers)}
            </td>
            <td className="text-center">
              {" "}
              {id4 ? banking?.others : formatNumberAsCurrency(banking?.others)}
            </td>
            <td className="text-center">
              {id4
                ? banking?.totalGross
                : formatNumberAsCurrency(banking?.totalGross)}
            </td>
            <td className="text-center">
              {id4
                ? banking?.totalNet
                : formatNumberAsCurrency(banking?.totalNet?.toFixed(2))}
            </td>
          </tr>
        </tbody>
      </table>
      <table
        className="table-auto w-full mx-auto mt-4 overflow-hidden text-left border rounded-md border-app-purple-4"
        id={id5}
      >
        <thead className="shadow-2xl bg-app-purple-8">
          <tr className="text-center">
            <Th rowSpan={2}> Base Information</Th>
            <Th>Today</Th>
            <Th>Same Day</Th> <Th>Key Statistics</Th>
            <Th>Today</Th>
            <Th>Same day</Th>
            <Th>% Increase/</Th>
            <Th>Operator</Th>
            <Th>Per Operator</Th>
          </tr>
          <tr>
            <Th></Th>
            <Th>Last Week</Th> <Th colSpan={2}></Th>
            <Th>Last Week</Th>
            <Th>Decrease</Th>
            <Th>Hours</Th>
            <Th>Hour</Th>
          </tr>
        </thead>
        <tbody>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30">
            <td className="pl-1">Box Office Admits incl Comps</td>
            <td className="text-center">
              {statInfo?.boxOfficeInclCompss?.today}
            </td>
            <td className="text-center px-2 border border-app-purple-4 border-opacity-30 border-l-0 border-r-4 border-r-app-purple-4">
              {statInfo?.boxOfficeInclCompss?.sameDayLastWeek}
            </td>
            <td className="pl-1">Total Attendance</td>
            <td>{statData?.totalAttendance.today}</td>
            <td>{statData?.totalAttendance.sameDayLastWeek}</td>
            <td className="text-center">
              {Math.round(statData?.totalAttendance.percDiff) + "%"}
            </td>
            <td className="text-center">
              {statData?.totalAttendance.operatorHrs}
            </td>
            <td className="text-center">
              {statData?.totalAttendance.perOperatorHrs}
            </td>
          </tr>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30">
            <td className="pl-1">Comps</td>
            <td className="text-center">{statInfo?.comps?.today}</td>
            <td className="text-center px-2 border border-app-purple-4 border-opacity-30 border-l-0 border-r-4 border-r-app-purple-4">
              {statInfo?.comps?.sameDayLastWeek}
            </td>
            <td className="pl-1 font-bold">Box Office Revenue</td>
            <td colSpan={5}></td>
          </tr>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30">
            <td className="pl-1">Transactions With Tickets</td>
            <td className="text-center">
              {statInfo?.ticketTranscactions?.today}
            </td>
            <td className="text-center px-2 border border-app-purple-4 border-opacity-30 border-l-0 border-r-4 border-r-app-purple-4">
              {statInfo?.ticketTranscactions?.sameDayLastWeek}
            </td>
            <td className="pl-4">GROSS</td>
            <td>
              {id5
                ? statData?.boxOfficeRevenue?.gross?.today
                : formatNumberAsCurrency(
                    statData?.boxOfficeRevenue?.gross?.today?.toFixed(2)
                  )}
            </td>
            <td>
              {id5
                ? statData?.boxOfficeRevenue?.gross?.sameDayLastWeek
                : formatNumberAsCurrency(
                    statData?.boxOfficeRevenue?.gross?.sameDayLastWeek?.toFixed(
                      2
                    )
                  )}
            </td>
            <td className="text-center">
              {Math.round(statData?.boxOfficeRevenue?.gross?.percDiff) + "%"}
            </td>
          </tr>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30">
            <td className="pl-1">Transactions With Concessions</td>
            <td className="text-center">
              {statInfo?.concessionsTransactions?.today}
            </td>
            <td className="text-center px-2 border border-app-purple-4 border-opacity-30 border-l-0 border-r-4 border-r-app-purple-4">
              {statInfo?.concessionsTransactions?.sameDayLastWeek}
            </td>
            <td className="pl-4">NET</td>
            <td>
              {id5
                ? statData?.boxOfficeRevenue?.net?.today
                : formatNumberAsCurrency(
                    statData?.boxOfficeRevenue?.net?.today?.toFixed(2)
                  )}
            </td>
            <td>
              {id5
                ? statData?.boxOfficeRevenue?.net?.sameDayLastWeek
                : formatNumberAsCurrency(
                    statData?.boxOfficeRevenue?.net?.sameDayLastWeek?.toFixed(2)
                  )}
            </td>
            <td className="text-center">
              {Math.round(statData?.boxOfficeRevenue?.gross?.percDiff) + "%"}
            </td>
          </tr>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30">
            <td className="pl-1">Number of operators</td>
            <td className="text-center">{statInfo?.operators?.today}</td>
            <td className="text-center px-2 border border-app-purple-4 border-opacity-30 border-l-0 border-r-4 border-r-app-purple-4">
              {statInfo?.operators?.sameDayLastWeek}
            </td>
            <td className="pl-1 font-bold">Concessions Revenue</td>
            <td colSpan={3}></td>
          </tr>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30">
            <td
              colSpan={3}
              className="text-center px-2 border border-app-purple-4 border-opacity-30 border-l-0 border-r-4 border-r-app-purple-4"
            ></td>
            <td className="pl-4">GROSS</td>
            <td>
              {id5
                ? statData?.concessionsRevenue?.gross?.today
                : formatNumberAsCurrency(
                    statData?.concessionsRevenue?.gross?.today
                  )}
            </td>
            <td>
              {id5
                ? statData?.concessionsRevenue?.gross?.sameDayLastWeek
                : formatNumberAsCurrency(
                    statData?.concessionsRevenue?.gross?.sameDayLastWeek
                  )}
            </td>
            <td className="text-center">
              {Math.round(statData?.concessionsRevenue?.gross?.percDiff) + "%"}
            </td>
          </tr>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30">
            <td
              colSpan={3}
              className="text-center px-2 border border-app-purple-4 border-opacity-30 border-l-0 border-r-4 border-r-app-purple-4"
            ></td>
            <td className="pl-4">NET</td>
            <td>
              {id5
                ? statData?.concessionsRevenue?.net?.today
                : formatNumberAsCurrency(
                    statData?.concessionsRevenue?.net?.today?.toFixed(2)
                  )}
            </td>
            <td>
              {id5
                ? statData?.concessionsRevenue?.net?.sameDayLastWeek
                : formatNumberAsCurrency(
                    statData?.concessionsRevenue?.net?.sameDayLastWeek?.toFixed(
                      2
                    )
                  )}
            </td>
            <td className="text-center">
              {Math.round(statData?.concessionsRevenue?.net?.percDiff) + "%"}
            </td>
          </tr>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30">
            <td
              colSpan={3}
              className="text-center px-2 border border-app-purple-4 border-opacity-30 border-l-0 border-r-4 border-r-app-purple-4"
            ></td>
            <td className="pl-1 font-bold">Spend Per Head</td>
            <td colSpan={3}></td>
          </tr>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30">
            <td
              colSpan={3}
              className="text-center px-2 border border-app-purple-4 border-opacity-30 border-l-0 border-r-4 border-r-app-purple-4"
            ></td>
            <td className="pl-4">GROSS</td>
            <td>
              {id5
                ? statData?.spendPerHead?.gross?.today
                : formatNumberAsCurrency(
                    statData?.spendPerHead?.gross?.today?.toFixed(2)
                  )}
            </td>
            <td>
              {id5
                ? statData?.spendPerHead?.gross?.sameDayLastWeek
                : formatNumberAsCurrency(
                    statData?.spendPerHead?.gross?.sameDayLastWeek?.toFixed(2)
                  )}
            </td>
            <td className="text-center">
              {Math.round(statData?.spendPerHead?.gross?.percDiff) + "%"}
            </td>
          </tr>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30">
            <td
              colSpan={3}
              className="text-center px-2 border border-app-purple-4 border-opacity-30 border-l-0 border-r-4 border-r-app-purple-4"
            ></td>
            <td className="pl-4">NET</td>
            <td>
              {id5
                ? statData?.spendPerHead?.net?.today
                : formatNumberAsCurrency(
                    statData?.spendPerHead?.net?.today?.toFixed(2)
                  )}
            </td>
            <td>
              {id5
                ? statData?.spendPerHead?.net?.sameDayLastWeek
                : formatNumberAsCurrency(
                    statData?.spendPerHead?.net?.sameDayLastWeek?.toFixed(2)
                  )}
            </td>
            <td className="text-center">
              {Math.round(statData?.spendPerHead?.net?.percDiff) + "%"}
            </td>
          </tr>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30">
            <td
              colSpan={3}
              className="text-center px-2 border border-app-purple-4 border-opacity-30 border-l-0 border-r-4 border-r-app-purple-4"
            ></td>
            <td className="pl-1 font-bold">Avg Concession Transaction Value</td>
            <td colSpan={3}></td>
          </tr>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30">
            <td
              colSpan={3}
              className="text-center px-2 border border-app-purple-4 border-opacity-30 border-l-0 border-r-4 border-r-app-purple-4"
            ></td>
            <td className="pl-4">GROSS</td>
            <td>
              {id5
                ? statData?.avgConsessionsTransactionsValue?.gross?.today
                : formatNumberAsCurrency(
                    statData?.avgConsessionsTransactionsValue?.gross?.today?.toFixed(
                      2
                    )
                  )}
            </td>
            <td>
              {id5
                ? statData?.avgConsessionsTransactionsValue?.gross
                    ?.sameDayLastWeek
                : formatNumberAsCurrency(
                    statData?.avgConsessionsTransactionsValue?.gross?.sameDayLastWeek?.toFixed(
                      2
                    )
                  )}
            </td>
            <td className="text-center">
              {Math.round(
                statData?.avgConsessionsTransactionsValue?.gross?.percDiff
              ) + "%"}
            </td>
          </tr>
          <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30">
            <td
              colSpan={3}
              className="text-center px-2 border border-app-purple-4 border-opacity-30 border-l-0 border-r-4 border-r-app-purple-4"
            ></td>
            <td className="pl-4">NET</td>
            <td>
              {id5
                ? statData?.avgConsessionsTransactionsValue?.net?.today
                : formatNumberAsCurrency(
                    statData?.avgConsessionsTransactionsValue?.net?.today?.toFixed(
                      2
                    )
                  )}
            </td>
            <td>
              {id5
                ? statData?.avgConsessionsTransactionsValue?.net
                    ?.sameDayLastWeek
                : formatNumberAsCurrency(
                    statData?.avgConsessionsTransactionsValue?.net?.sameDayLastWeek?.toFixed(
                      2
                    )
                  )}
            </td>
            <td className="text-center">
              {Math.round(
                statData?.avgConsessionsTransactionsValue?.net?.percDiff
              ) + "%"}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export { DailyOperationsReportTable };
