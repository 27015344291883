import { useSelector } from "react-redux";
import { reportData } from "../../redux/slices";
import { useLoggedInuser } from "../../hooks";
import ReachLogo from "../../assets/images/logo.png";
import { Th } from "./styles";
import {
  dateAndTime,
  formatDateTime,
  formatNumberAsCurrency,
} from "../../utils";

const DailyCashupReportTable = ({ id1, id2 }) => {
  //comment for push
  const { dailyCashupReport } = useSelector(reportData);
  const { profile } = useLoggedInuser();

  return (
    <div className="px-3">
      <div className="flex items-center justify-between">
        <div
          key={ReachLogo}
          className=" w-[5rem] h-[5rem]  bg-no-repeat bg-contain bg-center "
          style={{
            backgroundImage: `url("${ReachLogo}")`,
          }}
        ></div>
        <p className="font-bold text-[20px]">Daily Cashup Report</p>
        <p>{profile.cinema}</p>
      </div>
      <div className=" flex p-2 justify-between">
        <div>
          <p>
            {" "}
            <span className="font-bold">User Name:</span>
            {` ${profile.firstName} ${profile.lastName}`}
          </p>
          <p>
            <span className="font-bold">Date Time Generated: </span>
            {formatDateTime(
              dailyCashupReport?.paramFilter?.dateTimeGenerated,
              dateAndTime
            )}
          </p>
        </div>
        <div>
          <p className="font-bold">Report Date</p>
          <p>
            {new Date(dailyCashupReport?.paramFilter?.todayDate).toDateString()}
          </p>{" "}
        </div>
      </div>
      <table
        className="table-auto w-full mx-auto mt-8 overflow-hidden text-left border rounded-md border-app-purple-4"
        id={id1}
      >
        <thead className="shadow-2xl bg-app-purple-8">
          <tr className="">
            <Th colSpan={11}> Sales</Th>
          </tr>
        </thead>
        <tbody>
          <tr className="transition duration-300 border-y-2  bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[16px] text-app-purple-4 ">
            <td className=" pl-3" colSpan={11}>
              Box Office
            </td>
          </tr>
          <tr className="transition duration-300 border-y-2 bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[16px] text-app-purple-4  ">
            <td className=" pl-6" colSpan={5}>
              Gross Box Office
            </td>
            <td colSpan={6} className="text-right pr-6">
              {id1
                ? dailyCashupReport?.result?.grossBoxOffice
                : formatNumberAsCurrency(
                    dailyCashupReport?.result?.grossBoxOffice
                  )}
            </td>
          </tr>
          <tr className="transition duration-300 border-y-2  bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[16px] text-app-purple-4 ">
            <td className=" pl-3" colSpan={11}>
              Box Office Sundry
            </td>
          </tr>
          {dailyCashupReport?.result.concessions.map((sundry) => (
            <tr
              key={sundry.item}
              className="transition duration-300 border-y-2 bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[16px] text-app-purple-4  "
            >
              <td className=" pl-6" colSpan={5}>
                {sundry.item}
              </td>
              <td colSpan={3}>
                {id1 ? sundry.amount : formatNumberAsCurrency(sundry.amount)}
              </td>
              <td colSpan={3}></td>
            </tr>
          ))}
          <tr className="transition duration-300 border-y-2 bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[16px] text-app-purple-4  ">
            <td className=" pl-6" colSpan={5}>
              Total Box Office Sundry
            </td>
            <td colSpan={3}>
              {id1
                ? dailyCashupReport?.result?.boxOfficeSundries
                : formatNumberAsCurrency(
                    dailyCashupReport?.result?.boxOfficeSundries
                  )}
            </td>
            <td colSpan={3}></td>
          </tr>
          <tr className="transition duration-300 border-y-2  bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[16px] text-app-purple-4 ">
            <td className=" pl-3" colSpan={11}>
              Concessions
            </td>
          </tr>
          <tr className="transition duration-300 border-y-2 bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[16px] text-app-purple-4  ">
            <td className=" pl-6" colSpan={5}>
              Total Concessions
            </td>
            <td colSpan={3}>
              {id1
                ? dailyCashupReport?.result?.totalConcessions
                : formatNumberAsCurrency(
                    dailyCashupReport?.result?.totalConcessions
                  )}
            </td>
            <td colSpan={3}></td>
          </tr>
          <tr className="transition duration-300 border-y-2 bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[16px] text-app-purple-4  ">
            <td className=" pl-3" colSpan={5}>
              Total Sales
            </td>
            <td colSpan={6} className="text-right pr-6">
              {id1
                ? dailyCashupReport?.result?.totalSales
                : formatNumberAsCurrency(dailyCashupReport?.result?.totalSales)}
            </td>
          </tr>
        </tbody>
      </table>

      <table
        className="table-auto w-full mx-auto mt-8 overflow-hidden text-left border rounded-md border-app-purple-4"
        id={id2}
      >
        <thead className="shadow-2xl bg-app-purple-8">
          <tr className="">
            <Th colSpan={11}> Reconciliation</Th>
          </tr>
        </thead>
        <tbody>
          <tr className="transition duration-300 border-y-2 bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[16px] text-app-purple-4  ">
            <td className=" pl-3" colSpan={5}>
              Total Sales
            </td>
            <td colSpan={6} className="text-right pr-6">
              {id2
                ? dailyCashupReport?.result?.totalSales
                : formatNumberAsCurrency(dailyCashupReport?.result?.totalSales)}
            </td>
          </tr>

          <tr className="transition duration-300 border-y-2 bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[16px] text-app-purple-4  ">
            <td className=" pl-6" colSpan={5}>
              Plus Advance Sales
            </td>
            <td colSpan={3}>
              {id2
                ? dailyCashupReport?.result?.advanceSales
                : formatNumberAsCurrency(
                    dailyCashupReport?.result?.advanceSales
                  )}
            </td>
            <td colSpan={3}></td>
          </tr>
          <tr className="transition duration-300 border-y-2 bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[16px] text-app-purple-4  ">
            <td className="pl-6" colSpan={5}>
              Less Maturing Sales
            </td>
            <td colSpan={3}>
              {id2
                ? dailyCashupReport?.result?.lessMaturingSales
                : formatNumberAsCurrency(
                    dailyCashupReport?.result?.lessMaturingSales
                  )}
            </td>
            <td colSpan={3}></td>
          </tr>
          <tr className="transition duration-300 border-y-2 bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[16px] text-app-purple-4  ">
            <td colSpan={5} className="pl-3">Advanced Sales</td>

            <td colSpan={3} className="text-right pr-6">
              {id2
                ? dailyCashupReport?.result?.sumSalesAdvance
                : formatNumberAsCurrency(
                    dailyCashupReport?.result?.sumSalesAdvance
                  )}
            </td>
          </tr>
          <tr className="transition duration-300 border-y-2 bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[16px] text-app-purple-4  ">
            <td className=" pl-3" colSpan={5}>
              Expected Receipts
            </td>
            <td colSpan={6} className="text-right pr-6"></td>
          </tr>
          {/* <tr className="transition duration-300 border-y-2 bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[16px] text-app-purple-4  ">
            <td className=" pl-3" colSpan={11}>
              Non-cash payments
              
            </td>
          </tr> */}
          <tr className="transition duration-300 border-y-2 bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[16px] text-app-purple-4  ">
            <td className=" pl-6" colSpan={5}>
              Online Payment
            </td>
            <td colSpan={6} className="text-right pr-6">
              {id2
                ? dailyCashupReport?.result?.onlinePayments
                : formatNumberAsCurrency(
                    dailyCashupReport?.result?.onlinePayments
                  )}
            </td>
            {/* <td colSpan={3}></td> */}
          </tr>
          <tr className="transition duration-300 border-y-2 bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[16px] text-app-purple-4  ">
            <td className=" pl-6" colSpan={5}>
              Connect
            </td>
            <td colSpan={6} className="text-right pr-6">
              {id2
                ? dailyCashupReport?.result?.connect
                : formatNumberAsCurrency(dailyCashupReport?.result?.connect)}
            </td>
            {/* <td colSpan={3}></td> */}
          </tr>
          {/* <tr className="transition duration-300 border-y-2 bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[16px] text-app-purple-4  ">
            <td className=" pl-6" colSpan={5}>
              Cheques
            </td>
            <td colSpan={6} className="text-right pr-6">
              {id2
                ? dailyCashupReport?.result?.chequesPayments
                : formatNumberAsCurrency(
                    dailyCashupReport?.result?.chequesPayments
                  )}
            </td>
            <td colSpan={3}></td>
          </tr> */}
          {/* <tr className="transition duration-300 border-y-2 bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[16px] text-app-purple-4  ">
            <td className=" pl-6" colSpan={5}></td>
            <td colSpan={3}>
              {id2
                ? dailyCashupReport?.result?.onlinePayments +
                  dailyCashupReport?.result?.chequesPayments
                : formatNumberAsCurrency(
                    dailyCashupReport?.result?.onlinePayments +
                      dailyCashupReport?.result?.chequesPayments
                  )}
            </td>
            <td colSpan={3}></td>
          </tr> */}
          <tr className="transition duration-300 border-y-2 bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[16px] text-app-purple-4  ">
            <td className=" pl-6" colSpan={5}>
              Expected Cash
            </td>
            <td colSpan={6} className="text-right pr-6">
              {id2
                ? dailyCashupReport?.result?.expectedCash
                : formatNumberAsCurrency(
                    dailyCashupReport?.result?.expectedCash
                  )}
            </td>
          </tr>
          <tr className="transition duration-300 border-y-2 bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[16px] text-app-purple-4  ">
            <td className=" pl-6" colSpan={5}>
              Expected Card
            </td>
            <td colSpan={6} className="text-right pr-6">
              {id2
                ? dailyCashupReport?.result?.expectedCard
                : formatNumberAsCurrency(
                    dailyCashupReport?.result?.expectedCard
                  )}
            </td>
          </tr>
          <tr className="transition duration-300 border-y-2 bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[16px] text-app-purple-4  ">
            <td className=" pl-6" colSpan={5}>
              Expected Transfer
            </td>
            <td colSpan={6} className="text-right pr-6">
              {id2
                ? dailyCashupReport?.result?.expectedTransfer
                : formatNumberAsCurrency(
                    dailyCashupReport?.result?.expectedTransfer
                  )}
            </td>
          </tr>
          <tr className="transition duration-300 border-y-2 bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[16px] text-app-purple-4  ">
            <td className=" pl-6" colSpan={5}>
              Expected External Voucher
            </td>
            <td colSpan={6} className="text-right pr-6">
              {id2
                ? dailyCashupReport?.result?.expectedExternalVoucher
                : formatNumberAsCurrency(
                    dailyCashupReport?.result?.expectedExternalVoucher
                  )}
            </td>
          </tr>
          {/* <tr className="transition duration-300 border-y-2 bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[16px] text-app-purple-4  ">
            <td className=" pl-3" colSpan={5}>
              Expected Cash Deposits
            </td>
            <td colSpan={6} className="text-right pr-6">
              {id2
                ? dailyCashupReport?.result?.expectedDeposits
                : formatNumberAsCurrency(
                    dailyCashupReport?.result?.expectedDeposits
                  )}
            </td>
          </tr> */}
          <tr className="transition duration-300 border-y-2 bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[16px] text-app-purple-4  ">
            <td className=" pl-3" colSpan={5}>
              Total
            </td>
            <td colSpan={6} className="text-right pr-6">
              {id2
                ? dailyCashupReport?.result?.breakdownTotal
                : formatNumberAsCurrency(
                    dailyCashupReport?.result?.breakdownTotal
                  )}
            </td>
          </tr>
        </tbody>
      </table>
      <div className=" bg-app-purple-8 h-[60px] flex items-center pl-3 border-b-2 border-app-purple-4 border-opacity-30">
        <p>Comments</p>
      </div>
      <div className=" bg-app-purple-8 h-[60px] flex items-center pl-3">
        <p>Deposits</p>
      </div>
      <div className="flex py-3">
        <p className="pl-3 w-[50%]">Bag Number</p>
        <p className="w-[13%]">Cash</p>
        <p className="w-[13%]">Cheque</p>
        <p className="w-[14%]">Total</p>
      </div>
      {/* {dailyCashupReport?.result.movies.length < 1 && (
        <p className="text-center py-5"> No data found</p>
      )}
      {Object.keys(dailyCashupReport?.result).length < 1 && (
        <p className="text-center py-3"> No Data Found</p>
      )} */}
    </div>
  );
};
export { DailyCashupReportTable };
