import React, { useMemo } from "react";
import { dateAndTime, formatDateTime, formatNumberAsCurrency } from "../../utils";
import { DefaultListTable } from "../UI";
import ReachLogo from "../../assets/images/logo.png";
import { useLoggedInuser } from "../../hooks";

const GBOByTicketTable = React.forwardRef(
  ({ id, data, paramFilter, total }, ref) => {

    const {profile} = useLoggedInuser()
    const columns = useMemo(() => {
      return [
        {
          Header: "Name",
          accessor: "name",
          Footer: `TOTAL`,
        },
        {
          Header: "GBO",
          accessor: "gbo",
          Footer: `${formatNumberAsCurrency(total.gbo)}`,
          Cell: ({ value }) => (id ? value : formatNumberAsCurrency(value)),
        },
        {
          Header: "Price",
          accessor: "price",
          Cell: ({ value }) => (id ? value : formatNumberAsCurrency(value)),
        },
        {
          Header: "Quantity",
          accessor: "quantity",
          Footer: `${total.quantity}`,
        },
      ];
    }, [id, total.gbo, total.quantity]);
    return (
      <div className="overflow-auto px-3" ref={ref}>
        <div className="flex items-center justify-between">
          <div
            key={ReachLogo}
            className=" w-[5rem] h-[5rem]  bg-no-repeat bg-contain bg-center "
            style={{
              backgroundImage: `url("${ReachLogo}")`,
            }}
          ></div>
          <p className="font-bold text-[20px]">GBO By Tickets Report</p>
          <p>{paramFilter.cinema}</p>
        </div>
        <div className=" flex p-2 justify-between">
          <div>
            <p>
              {" "}
              <span className="font-bold">User Name:</span>
              {` ${profile.firstName} ${profile.lastName}`}
            </p>
            <p>
              <span className="font-bold">Date Time Generated: </span>
              {formatDateTime(
                paramFilter?.dateTimeGenerated,
                dateAndTime
              )}
            </p>
          </div>
          <div>
            <p className="font-bold">FILM</p>
            <p>{paramFilter.film}</p>
          </div>
          <div className="font-bold">
            <p>{paramFilter.distributor}</p>
          </div>
          <div>
            <p className="font-bold">Data Range</p>
            <p>{new Date(paramFilter?.dateFrom).toDateString()}</p>{" "}
            <p>{new Date(paramFilter?.dateTo).toDateString()}</p>
          </div>
        </div>
        <DefaultListTable
          columns={columns}
          data={data}
          id={id}
          isFooter={true}
        />
      </div>
    );
  }
);

export default GBOByTicketTable;
