import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import isArray from "lodash/isArray";

import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";

import {
  editItemDirect,
  API_URL,
  axiosApiInstance,
  FETCH_STATUS,
  Session,
  axiosApiInstanceGet,
  isCanal,
  loggedInCinemaName,
} from "../../utils";


export const getShowTimes = createAsyncThunk(
  "getShowTimes",
  async (config, { fulfillWithValue, rejectWithValue }) => {
    const cinemaName = localStorage.getItem(loggedInCinemaName);
    const isCanal=cinemaName?.toLowerCase().includes("canal")
    const startDate = isCanal? startOfWeek(new Date(),{weekStartsOn:1}).toISOString():startOfWeek(new Date()).toISOString();
    const endDate = isCanal
      ? endOfWeek(new Date(), { weekStartsOn: 1 }).toISOString()
      : endOfWeek(new Date()).toISOString();
    try {
      const { start, end } = {
        start: config?.start || startDate,
        end: config?.end || endDate,
      };

      const res = await axiosApiInstance.get(
        `${API_URL}/Showtimes/ListAll?DateFrom=${encodeURIComponent(
          start
        )}&DateTo=${encodeURIComponent(end)}`
      );

      const showTimes = res?.data?.data?.map((s) => Session.toUI(s)) ?? [];

      return fulfillWithValue(showTimes);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const getShowtimesPerDay = createAsyncThunk(
  "getShowTimesPerDay",
  async (config, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { start, end } = {
        start: config?.start,
        end: config?.end,
      };

      const res = await axiosApiInstance.get(
        `${API_URL}/Showtimes/ListAll?DateFrom=${encodeURIComponent(
          start
        )}&DateTo=${encodeURIComponent(end)}`
      );

      const showTimesPerDay =
        res?.data?.data?.map((s) => Session.toUI(s)) ?? [];

      return fulfillWithValue(showTimesPerDay);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getShowTimesStatusList = createAsyncThunk(
  "getShowTimesStatusList",
  async (force, { fulfillWithValue, rejectWithValue }) => {
    try {
      const res = await axiosApiInstanceGet(
        `${API_URL}/Showtimes/ShowtimeStatus`,
        force
      );

      return fulfillWithValue(res?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const initialState = {
  showTimes: [],
  showTimesStatus: FETCH_STATUS.IDLE,
  showTimesErr: null,

  showTimesPerDay: [],
  showTimesPerDayStatus: FETCH_STATUS.IDLE,
  showTimesPerDayErr: null,

  activeDate: new Date().toISOString(),

  showTimesStatusList: [],
  showTimesStatusListStatus: FETCH_STATUS.IDLE,
  showTimesStatusListErr: null,
};

export const showTimesSlice = createSlice({
  name: "showTimes",
  initialState,
  reducers: {
    addShowTimes: (state, action) => {
      if (isArray(action.payload)) {
        state.showTimes = [...action.payload, ...state.showTimes];
      }
    },
    editShowTime: (state, action) => {
      editItemDirect(state, action, "showTimes");
    },
    deleteShowTime: (state, action) => {
      const ind = state.showTimes.findIndex((s) => s.id === action.payload);

      if (ind > -1) {
        state.showTimes.splice(ind, 1);
      }
    },
    deleteSelectedShowtimes: (state, action) => {
      const newShowtimes = state.showTimes.filter((x) => {
        return !action.payload.includes(x.id);
      });

      state.showTimes = newShowtimes;
    },
    setActiveDate: (state, action) => {
      state.activeDate = new Date(action.payload).toISOString();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShowTimes.pending, (state) => {
        state.showTimesStatus = FETCH_STATUS.PENDING;
        state.showTimesErr = "";
      })
      .addCase(getShowTimes.fulfilled, (state, action) => {
        state.showTimesStatus = FETCH_STATUS.RESOLVED;
        state.showTimes = action.payload;
        state.showTimesErr = "";
      })
      .addCase(getShowTimes.rejected, (state, action) => {
        state.showTimesStatus = FETCH_STATUS.REJECTED;
        state.showTimesErr = action.payload || "Unable to get showtimes";
      })

      .addCase(getShowtimesPerDay.pending, (state) => {
        state.showTimesPerDayStatus = FETCH_STATUS.PENDING;
        state.showTimesPerDayErr = "";
      })
      .addCase(getShowtimesPerDay.fulfilled, (state, action) => {
        state.showTimesPerDayStatus = FETCH_STATUS.RESOLVED;
        state.showTimesPerDay = action.payload;
        state.showTimesPerDayErr = "";
      })
      .addCase(getShowtimesPerDay.rejected, (state, action) => {
        state.showTimesPerDayStatus = FETCH_STATUS.REJECTED;
        state.showTimesPerDayErr = action.payload || "Unable to get showtimes";
      })
      .addCase(getShowTimesStatusList.pending, (state) => {
        state.showTimesStatusListStatus = FETCH_STATUS.PENDING;
        state.showTimesStatusListErr = "";
      })
      .addCase(getShowTimesStatusList.fulfilled, (state, action) => {
        state.showTimesStatusListStatus = FETCH_STATUS.RESOLVED;
        state.showTimesStatusList = action.payload;
        state.showTimesStatusListErr = "";
      })
      .addCase(getShowTimesStatusList.rejected, (state, action) => {
        state.showTimesStatusListStatus = FETCH_STATUS.REJECTED;
        state.showTimesStatusListErr =
          action.payload || "Unable to get showtimes statuses";
      });
  },
});

export const selectShowTimes = (state) => state.showTimes;

export const {
  deleteShowTime,
  addShowTimes,
  editShowTime,
  setActiveDate,
  deleteSelectedShowtimes,
} = showTimesSlice.actions;
