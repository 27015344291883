import {useSelector} from "react-redux";
import {reportData} from "../../redux/slices";
import {useLoggedInuser} from "../../hooks";
import ReachLogo from "../../assets/images/logo.png";
import {formatDateTime, formatNumberAsCurrency} from "../../utils";
import {Th} from "./styles";

const BoxOfficePerformanceTable = ({ id1, id2 }) => {
  const { gboPerformance, gboAdmitsPerformance } = useSelector(reportData);
  const { profile } = useLoggedInuser();
  const gboTotal = gboPerformance?.result?.total;
  const gboAdmitsTotal = gboAdmitsPerformance?.result?.total;
  return (
    <div className="px-3 phone-mini:overflow-auto">
      <div className="flex items-center justify-between">
        <div
          key={ReachLogo}
          className=" w-[5rem] h-[5rem]  bg-no-repeat bg-contain bg-center "
          style={{
            backgroundImage: `url("${ReachLogo}")`,
          }}
        ></div>
        <p className="font-bold text-[20px] phone-mini:text-[1rem] phone-lg:text-[20px] mr-1  ">
          Box Office Performance
        </p>
        <p>{profile.cinema}</p>
      </div>
      <div className=" flex p-2 justify-between">
        <div>
          <p>
            {" "}
            <span className="font-bold">User Name:</span>
            {` ${profile.firstName} ${profile.lastName}`}
          </p>
          <p>
            <span className="font-bold">Date Time Generated: </span>
            {formatDateTime(
              gboPerformance?.paramFilter?.dateTimeGenerated,
              "yyyy-MM-dd, HH:mm a"
            )}
          </p>
        </div>
        <div>
          <p className="font-bold">Data Range</p>
          <p>
            {new Date(gboPerformance?.paramFilter?.dateFrom)
              .toDateString()}
          </p>{" "}
          <p>
            {new Date(gboPerformance?.paramFilter?.dateTo)
              .toDateString()
              }
          </p>
        </div>
      </div>
      <p> Gross Box Office</p>

      <div className=" overflow-auto">
        <table
          className="table-auto w-full mx-auto mt-8 text-left border rounded-md border-app-purple-4 "
          id={id1}
        >
          <thead className="shadow-2xl bg-app-purple-8">
            <tr>
              <Th>Film</Th>
              <Th>Weeks</Th>
              <Th>Mon</Th> <Th>Tue</Th> <Th>Wed</Th>
              <Th>Thu</Th> <Th>Fri</Th> <Th>Sat</Th>
              <Th>Sun</Th>
              <Th>Current Week</Th> <Th>Last week</Th>
              <Th>Life to Date</Th> <Th>Weekly Diff</Th>
              <Th>% Diff</Th>
            </tr>
          </thead>
          <tbody>
            {gboPerformance?.result?.reports?.map((data, index) => {
              return (
                <tr
                  className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30 text-[15px]"
                  key={data.id}
                >
                  <td className="text-center">{data?.film}</td>
                  <td className="text-center">{data?.weeks}</td>
                  <td className="text-center">
                    {id1 ? data?.monday : formatNumberAsCurrency(data?.monday)}
                  </td>
                  <td className="text-center">
                    {id1
                      ? data?.tuesday
                      : formatNumberAsCurrency(data?.tuesday)}
                  </td>
                  <td className="text-center">
                    {id1
                      ? data?.wednesday
                      : formatNumberAsCurrency(data?.wednesday)}
                  </td>
                  <td className="text-center">
                    {id1
                      ? data?.thursday
                      : formatNumberAsCurrency(data?.thursday)}
                  </td>
                  <td className="text-center">
                    {id1 ? data?.friday : formatNumberAsCurrency(data?.friday)}
                  </td>
                  <td className="text-center">
                    {id1
                      ? data?.saturday
                      : formatNumberAsCurrency(data?.saturday)}
                  </td>
                  <td className="text-center">
                    {id1 ? data?.sunday : formatNumberAsCurrency(data?.sunday)}
                  </td>
                  <td className="text-center">
                    {id1
                      ? data?.currentWeek
                      : formatNumberAsCurrency(data?.currentWeek)}
                  </td>
                  <td className="text-center">
                    {id1
                      ? data?.lastWeek
                      : formatNumberAsCurrency(data?.lastWeek)}
                  </td>
                  <td className="text-center">
                    {id1
                      ? data?.lifeToDate
                      : formatNumberAsCurrency(data?.lifeToDate)}
                  </td>
                  <td className="text-center">
                    {id1
                      ? data?.weeklyDiff
                      : formatNumberAsCurrency(data?.weeklyDiff)}
                  </td>
                  <td className="text-center">
                    {Math.round(data?.weeklyDiffPerc) + "%"}
                  </td>
                </tr>
              );
            })}
            <tr className="transition duration-300 border-y-2  bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[13px] font-bold">
              <td className="text-center pt-8 pb-3 text-[16px]"> Total</td>
              <td></td>
              <td className="text-center pt-8 pb-3 ">
                {id1
                  ? gboTotal?.monday
                  : formatNumberAsCurrency(gboTotal?.monday)}
              </td>
              <td className="text-center pt-8 pb-3">
                {id1
                  ? gboTotal?.tuesday
                  : formatNumberAsCurrency(gboTotal?.tuesday)}
              </td>
              <td className="text-center pt-8 pb-3">
                {id1
                  ? gboTotal?.wednesday
                  : formatNumberAsCurrency(gboTotal?.wednesday)}
              </td>
              <td className="text-center pt-8 pb-3">
                {id1
                  ? gboTotal?.thursday
                  : formatNumberAsCurrency(gboTotal?.thursday)}
              </td>
              <td className="text-center pt-8 pb-3">
                {id1
                  ? gboTotal?.friday
                  : formatNumberAsCurrency(gboTotal?.friday)}
              </td>
              <td className="text-center pt-8 pb-3">
                {id1
                  ? gboTotal?.saturday
                  : formatNumberAsCurrency(gboTotal?.saturday)}
              </td>
              <td className="text-center pt-8 pb-3">
                {id1
                  ? gboTotal?.sunday
                  : formatNumberAsCurrency(gboTotal?.sunday)}
              </td>
              <td className="text-center pt-8 pb-3">
                {id1
                  ? gboTotal?.currentWeek
                  : formatNumberAsCurrency(gboTotal?.currentWeek)}
              </td>
              <td className="text-center pt-8 pb-3">
                {id1
                  ? gboTotal?.lastWeek
                  : formatNumberAsCurrency(gboTotal?.lastWeek)}
              </td>
              <td className="text-center pt-8 pb-3">
                {id1
                  ? gboTotal?.lifeToDate
                  : formatNumberAsCurrency(gboTotal?.lifeToDate)}
              </td>
              <td className="text-center pt-8 pb-3">
                {id1
                  ? gboTotal?.weeklyDiff
                  : formatNumberAsCurrency(gboTotal?.weeklyDiff)}
              </td>
              <td className="text-center pt-8 pb-3">
                {Math.round(gboTotal?.weeklyDiffPerc) + "%"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <p className="mt-[30px]"> Admits</p>
      <div className=" overflow-auto">
        <table
          className="table-auto w-full mx-auto mt-8 overflow-scroll text-left border rounded-md border-app-purple-4"
          id={id2}
        >
          <thead className="shadow-2xl bg-app-purple-8">
            <tr>
              <Th>Film</Th>
              <Th>Weeks</Th>
              <Th>Mon</Th> <Th>Tue</Th> <Th>Wed</Th>
              <Th>Thu</Th> <Th>Fri</Th> <Th>Sat</Th>
              <Th>Sun</Th>
              <Th>Current Week</Th> <Th>Last week</Th>
              <Th>Life to Date</Th> <Th>Weekly Diff</Th>
              <Th>% Diff</Th>
            </tr>
          </thead>
          <tbody>
            {gboAdmitsPerformance?.result?.reports?.map((data, index) => {
              return (
                <tr
                  className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30 text-[15px]"
                  key={data.id}
                >
                  <td className="text-center">{data?.film}</td>
                  <td className="text-center">{data?.weeks}</td>
                  <td className="text-center">{data?.monday}</td>
                  <td className="text-center">{data?.tuesday}</td>
                  <td className="text-center">{data?.wednesday}</td>
                  <td className="text-center">{data?.thursday}</td>
                  <td className="text-center">{data?.friday}</td>
                  <td className="text-center">{data?.saturday}</td>
                  <td className="text-center">{data?.sunday}</td>
                  <td className="text-center">{data?.currentWeek}</td>
                  <td className="text-center">{data?.lastWeek}</td>
                  <td className="text-center">{data?.lifeToDate}</td>
                  <td className="text-center">{data?.weeklyDiff}</td>
                  <td className="text-center">
                    {Math.round(data?.weeklyDiffPerc) + "%"}
                  </td>
                </tr>
              );
            })}
            <tr className="transition duration-300 border-y-2  bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[13px] font-bold">
              <td className="text-center pt-8 pb-3 text-[16px]"> Total</td>
              <td></td>
              <td className="text-center pt-8 pb-3 ">
                {gboAdmitsTotal?.monday}
              </td>
              <td className="text-center pt-8 pb-3">
                {gboAdmitsTotal?.tuesday}
              </td>
              <td className="text-center pt-8 pb-3">
                {gboAdmitsTotal?.wednesday}
              </td>
              <td className="text-center pt-8 pb-3">
                {gboAdmitsTotal?.thursday}
              </td>
              <td className="text-center pt-8 pb-3">
                {gboAdmitsTotal?.friday}
              </td>
              <td className="text-center pt-8 pb-3">
                {gboAdmitsTotal?.saturday}
              </td>
              <td className="text-center pt-8 pb-3">
                {gboAdmitsTotal?.sunday}
              </td>
              <td className="text-center pt-8 pb-3">
                {gboAdmitsTotal?.currentWeek}
              </td>
              <td className="text-center pt-8 pb-3">
                {gboAdmitsTotal?.lastWeek}
              </td>
              <td className="text-center pt-8 pb-3">
                {gboAdmitsTotal?.lifeToDate}
              </td>
              <td className="text-center pt-8 pb-3">
                {gboAdmitsTotal?.weeklyDiff}
              </td>
              <td className="text-center pt-8 pb-3">
                {Math.round(gboAdmitsTotal?.weeklyDiffPerc) + "%"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export { BoxOfficePerformanceTable };
