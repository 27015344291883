import { useSelector } from "react-redux";
import { reportData } from "../../redux/slices";
import { useLoggedInuser } from "../../hooks";
import ReachLogo from "../../assets/images/logo.png";

import { Th } from "./styles";
import { dateAndTime, formatDateTime } from "../../utils";
const TransactionsByUserTable2 = ({ selectedDate, id }) => {
  const { ordersByUser } = useSelector(reportData);
  const { profile } = useLoggedInuser();

  const channelsData = (data) => {
    let channels = "";
    data?.map((channel) => {
      return (channels = channels + `${channel.channel}: ${channel.amount}  `);
    });

    return channels;
  };
  return (
    <div className="px-3">
      <div className="flex items-center justify-between">
        <div
          key={ReachLogo}
          className=" w-[5rem] h-[5rem]  bg-no-repeat bg-contain bg-center "
          style={{
            backgroundImage: `url("${ReachLogo}")`,
          }}
        ></div>
        <p className="font-bold text-[20px]">Transactions by User Report</p>
        <p>{profile.cinema}</p>
      </div>
      <div className=" flex p-2 justify-between">
        <div>
          <p>
            {" "}
            <span className="font-bold">User Name:</span>
            {` ${profile.firstName} ${profile.lastName}`}
          </p>
          <p>
            <span className="font-bold">Date Time Generated: </span>
            {formatDateTime(ordersByUser?.session?.dateTimeGenerated, dateAndTime)}
          </p>
        </div>
        <div>
          <p className="font-bold">Report Date</p>
          <p>{new Date(selectedDate).toDateString()}</p>{" "}
        </div>
      </div>

      {/* <div className="flex justify-between bg-white my-[20px] py-3 px-2 font-bold">
        <p>{`Name of User: ${
          ordersByUser?.session?.user?.firstName +
          ordersByUser?.session?.user?.lastName
        }`}</p>
        <p>{`Date: ${new Date(
          ordersByUser?.session?.dateCreated
        ).toDateString()}`}</p>
        <p>{`Time: ${new Date(
          ordersByUser?.session?.dateCreated
        ).toLocaleTimeString()}`}</p>
      </div> */}

      <table
        className="table-auto w-full mx-auto mt-4 overflow-hidden text-left border rounded-md border-app-purple-4"
        id={id}
      >
        <thead className="shadow-2xl bg-app-purple-8">
          <tr>
            <Th>Transaction Id</Th> <Th>Offline Sale Id</Th>
            <Th>Staff Name</Th> <Th>Total</Th>
            <Th>Time</Th> <Th>Has Refunds</Th> <Th>Offline Sale</Th>
            <Th>Payment Channels</Th>
          </tr>
        </thead>
        <tbody>
          {ordersByUser?.pagedList?.map((data) => {
            return (
              <tr
                className="transition duration-300 border bg-white border-black "
                key={data.id}
              >
                <td className="text-center">{data?.id}</td>
                <td className="text-center">{data?.offlineId}</td>
                <td className="text-center">{data?.user}</td>
                <td className="text-center">{data?.amount}</td>
                <td className="text-center">
                  {new Date(data?.dateCreated).toLocaleTimeString()}
                </td>

                <td className="text-center">
                  {data?.hasBeenRefunded ? "Yes" : "No"}
                </td>
                <td className="text-center">
                  {data?.offlineId ? "Yes" : "No"}
                </td>
                <td className="pl-2">{channelsData(data?.paymentChannels)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {ordersByUser?.pagedList.length < 1 && (
        <p className="text-center py-3"> No Data Found</p>
      )}
    </div>
  );
};
export { TransactionsByUserTable2 };
