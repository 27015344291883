import React, { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useGoBack } from "./hooks";

import {
  AdminActions,
  AllContracts,
  AllFilms,
  AllItems,
  AllLocation,
  AllScreens,
  AllStaff,
  AllStockTake,
  AllTickets,
  AllVendor,
  BookingsByGenre,
  BoxOfficePerformance,
  CashierSessionReconcilation,
  CinemaSetting,
  ContractForm,
  CreateIssue,
  CreateLocation,
  CreateScreen,
  CreateStaff,
  CreateStaffShift,
  CreateStaffTask,
  CreateStockTake,
  CreateTicket,
  CreateVendor,
  DailyCashupReport,
  DailyOperationsReport,
  DBHome,
  DistributorByFilm,
  EditScreen,
  EditShift,
  EditStaff,
  EditTask,
  EditTicket,
  FilmDetails,
  FilmHireReport,
  FilmsByScreen,
  FilmScheduling,
  GBOByScreenFormat,
  GBOByTickets,
  GBOFilmReports,
  InvoicePage,
  IssueDetails,
  IssuesList,
  ItemDetailSalesReport,
  ItemForm,
  ItemSalesReport,
  KioskLayout,
  KioskTerminals,
  Login,
  MarketingReport,
  MetaData,
  NotFound,
  OnlineOrders,
  OrdersBySourceAndChannel,
  Page,
  PerformanceByFilm,
  PerformanceByShowtime,
  PerformanceReport,
  PinCreate,
  PinUpdate,
  POSLayout,
  PriceCardCreate,
  PriceCardDetails,
  PriceCards,
  PurchaseOrderForm,
  PurchaseOrderOverview,
  RefundReports,
  ReportsList,
  ScheduledReports,
  ScheduleReportForm,
  SessionReconciliation,
  ShowtimeReport,
  StaffClaims,
  StaffPerformance,
  StaffRoster,
  StaffSessions,
  StaffShifts,
  StaffTasks,
  StockOverview,
  StockReceiptForm,
  StockReceiptOverview,
  StockRequestForm,
  StockRequestOverview,
  StockTransferRequestForm,
  StockTransferRequestOverview,
  TransactionsByUserReport,
  TransferReceiptForm,
  TransferReceiptOverview,
  UpdateLocation,
  UpdateStockTake,
  UpdateVendor,
  ConnectItemsPage,
  ConnectTicketsPage,
  ConnectShowtimesPage,
  PaymentChannelPage,
  CreatePaymentChannelPage,
  EditPaymentChannelPage,
} from "./Routes";

import {
  ErrorBoundary,
  IdleWarning,
  OfflineWarning,
  PaymentReminder,
  WithSideBar,
} from "./components";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
// import { GBOByScreenFormat, POSLayout } from "./pages";
import ResetPassword from "./pages/Auth/ResetPassword";

const AppLayout = () => {
  const [showWarning, setShowWarning] = useState(false);

  const handleOnIdle = () => {
    setShowWarning(true);
  };

  const handleOnAction = () => {
    setShowWarning(false);
  };

  useIdleTimer({
    timeout: 1000 * 60 * 30, //30 mins
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 500,
  });

  return (
    <WithSideBar>
      <PaymentReminder />
      <Outlet />
      {showWarning ? <IdleWarning show={showWarning} /> : null}
    </WithSideBar>
  );
};

const EmptyLayout = () => {
  return <Outlet />;
};

const Test = ({ name = "Test" }) => (
  <h1 className="p-4 text-xl font-bold text-center capitalize">{name}</h1>
);

function App() {
  const { monitorPath } = useGoBack();

  useEffect(() => {
    monitorPath();
  }, [monitorPath]);

  return (
    <div className="font-sans">
      <OfflineWarning />
      <ErrorBoundary>
        <Routes>
          <Route path="/dashboard" element={<AppLayout />}>
            <Route index element={<DBHome />} />
            <Route path="analytics" element={<Test name="analytics" />} />

            {/* Items route */}
            <Route path="items" element={<EmptyLayout />}>
              <Route index element={<Page component={AllItems} />} />
              <Route path="import" element={<Test name="import items" />} />
              <Route path="create" element={<Page component={ItemForm} />} />
              <Route
                path="edit/:itemGroup/:id"
                element={<Page component={ItemForm} />}
              />
              <Route
                path="posLayout"
                element={<Page component={POSLayout} />}
              />
              <Route
                path="kioskLayout"
                element={<Page component={KioskLayout} />}
              />
            </Route>

            {/* CONTRACTS ROUTEs */}
            <Route path="film-contracts" element={<EmptyLayout />}>
              <Route index element={<Page component={AllContracts} />} />
              <Route
                path="create-contract"
                element={<Page component={ContractForm} />}
              />
              <Route
                path="edit-contract/:id"
                element={<Page component={ContractForm} />}
              />
            </Route>
            {/* CONNECT ROUTES */}

            {/* <Route
                path="connect/connect-items"
                element={<Page component={ConnectItemsPage} />}
              />
              <Route
                path="connect/connect-tickets"
                element={<Page component={ConnectTicketsPage} />}
              />
              <Route
                path="connect/connect-showtimes"
                element={<Page component={ConnectShowtimesPage} />}
              /> */}

            {/* Stock route */}
            <Route path="stock" element={<EmptyLayout />}>
              <Route index element={<Page component={StockOverview} />} />
              {/* Stock request route */}
              <Route path="stockRequest" element={<EmptyLayout />}>
                <Route
                  index
                  element={<Page component={StockRequestOverview} />}
                />
                <Route
                  path="create"
                  element={<Page component={StockRequestForm} />}
                />
                <Route
                  path="view/:id"
                  element={<Page component={StockRequestForm} />}
                />
              </Route>
              {/* Purchase Order route */}
              <Route path="purchaseOrder" element={<EmptyLayout />}>
                <Route
                  index
                  element={<Page component={PurchaseOrderOverview} />}
                />
                <Route
                  path=":id"
                  element={<Page component={PurchaseOrderForm} />}
                />
              </Route>
              {/* Stock Receipt route */}
              <Route path="stockReceipt" element={<EmptyLayout />}>
                <Route
                  index
                  element={<Page component={StockReceiptOverview} />}
                />
                <Route
                  path="create"
                  element={<Page component={StockReceiptForm} />}
                />
              </Route>
              {/* Stock Transfer Request route */}
              <Route path="stockTransferRequest" element={<EmptyLayout />}>
                <Route
                  index
                  element={<Page component={StockTransferRequestOverview} />}
                />
                <Route
                  path="update/:id"
                  element={<Page component={StockTransferRequestForm} />}
                />
              </Route>

              {/* Stock Transfer Receipt Route*/}
              <Route path="stockTransferReceipt" element={<EmptyLayout />}>
                <Route
                  index
                  element={<Page component={TransferReceiptOverview} />}
                />
                <Route
                  path="create"
                  element={<Page component={TransferReceiptForm} />}
                />
              </Route>
              {/* Stock Take Routes */}
              <Route path="stockTake" element={<EmptyLayout />}>
                <Route index element={<Page component={AllStockTake} />} />
                <Route
                  path="create"
                  element={<Page component={CreateStockTake} />}
                />
                <Route
                  path=":id"
                  element={<Page component={UpdateStockTake} />}
                />
              </Route>
              <Route path="locations" element={<EmptyLayout />}>
                <Route index element={<Page component={AllLocation} />} />
                <Route
                  path="create"
                  element={<Page component={CreateLocation} />}
                />
                <Route
                  path=":id"
                  element={<Page component={UpdateLocation} />}
                />
              </Route>
              {/* <Route path="stockTake" element={<EmptyLayout />}>
                <Route index element={<Page component={AllStockTake} />} />
                <Route
                  path="create"
                  element={<Page component={CreateStockTake} />}
                />
                <Route
                  path=":id"
                  element={<Page component={UpdateStockTake} />}
                />
              </Route> */}
              <Route path="vendors" element={<EmptyLayout />}>
                <Route index element={<Page component={AllVendor} />} />
                <Route
                  path="create"
                  element={<Page component={CreateVendor} />}
                />
                <Route path=":id" element={<Page component={UpdateVendor} />} />
              </Route>
            </Route>

            {/* Seeting Route */}
            <Route path="settings" element={<EmptyLayout />}>
              <Route path="hive" element={<Page component={MetaData} />} />
              <Route
                path="cinema-settings"
                element={<Page component={CinemaSetting} />}
              />
              <Route path="others" element={<Test name="Others" />} />
            </Route>

            {/* Reports route */}
            <Route path="reports" element={<EmptyLayout />}>
              <Route index element={<Page component={ReportsList} />} />
              <Route
                path="distributor-by-film"
                element={<Page component={DistributorByFilm} />}
              />
              <Route
                path="box-office-performance"
                element={<Page component={BoxOfficePerformance} />}
              />
              <Route
                path="session-reconciliation"
                element={<Page component={SessionReconciliation} />}
              />
              <Route
                path="cashier-session-reconcilation"
                element={<Page component={CashierSessionReconcilation} />}
              />
              <Route
                path="cashier-session-reconcilation/:sessionDate/:userId"
                element={<Page component={CashierSessionReconcilation} />}
              />
              <Route
                path="item-sales-report"
                element={<Page component={ItemSalesReport} />}
              />
              <Route
                path="item-detail-sales-report"
                element={<Page component={ItemDetailSalesReport} />}
              />
              <Route
                path="performance-report"
                element={<Page component={PerformanceReport} />}
              />
              <Route
                path="films-by-screen"
                element={<Page component={FilmsByScreen} />}
              />
              <Route
                path="transactions-by-user"
                element={<Page component={TransactionsByUserReport} />}
              />
              <Route
                path="daily-cashup-report"
                element={<Page component={DailyCashupReport} />}
              />
              <Route
                path="daily-operations-report"
                element={<Page component={DailyOperationsReport} />}
              />
              <Route
                path="admin-actions"
                element={<Page component={AdminActions} />}
              />
              <Route
                path="orders-by-source-and-channel"
                element={<Page component={OrdersBySourceAndChannel} />}
              />
              <Route
                path="online-orders"
                element={<Page component={OnlineOrders} />}
              />
              <Route
                path="scheduledReports"
                element={<Page component={ScheduledReports} />}
              />
              <Route
                path="scheduleReportForm"
                element={<Page component={ScheduleReportForm} />}
              />
              <Route
                path="scheduleReportForm/edit/:id"
                element={<Page component={ScheduleReportForm} />}
              />
              <Route
                path="filmhire-report"
                element={<Page component={FilmHireReport} />}
              />
              <Route
                path="GBOByTickets"
                element={<Page component={GBOByTickets} />}
              />
              <Route
                path="showtime-report"
                element={<Page component={ShowtimeReport} />}
              />
              <Route
                path="gbo-film-reports"
                element={<Page component={GBOFilmReports} />}
              />
              <Route
                path="refund-report"
                element={<Page component={RefundReports} />}
              />
              <Route
                path="bookings-by-genre"
                element={<Page component={BookingsByGenre} />}
              />
              <Route
                path="staff-performance"
                element={<Page component={StaffPerformance} />}
              />
              <Route
                path="performance-by-showtime"
                element={<Page component={PerformanceByShowtime} />}
              />
              <Route
                path="performance-by-film"
                element={<Page component={PerformanceByFilm} />}
              />
              <Route
                path="gbo-by-screenformat"
                element={<Page component={GBOByScreenFormat} />}
              />
              <Route
                path="marketing-report"
                element={<Page component={MarketingReport} />}
              />
            </Route>

            {/* FILMS ROUTES */}
            <Route path="films" element={<Page component={AllFilms} />} />
            <Route
              path="films/details/:filmId"
              element={<Page component={FilmDetails} />}
            />
            <Route path="films-rental" element={<Test name="films-rental" />} />
            <Route
              path="films-scheduling"
              element={<Page component={FilmScheduling} />}
            />
            <Route
              path="private-booking"
              element={<Test name="private-booking" />}
            />

            {/* Finance */}
            <Route path="finance" element={<EmptyLayout />}>
              {/* <Route index element={<Page component={AllContracts} />} /> */}
              <Route
                path="kiosk-terminals"
                element={<Page component={KioskTerminals} />}
              />
              <Route
                path="Invoice"
                element={<Page component={InvoicePage} />}
              />
              <Route
                path="Invoice/:status"
                element={<Page component={InvoicePage} />}
              />
              <Route
                path="payment-channel"
                element={<Page component={PaymentChannelPage} />}
              />
              <Route
                path="payment-channel/create"
                element={<Page component={CreatePaymentChannelPage} />}
              />
              <Route
                path="payment-channel/edit/:id"
                element={<Page component={EditPaymentChannelPage} />}
              />
            </Route>

            {/* TICKET ROUTES */}

            <Route path="tickets" element={<Page component={AllTickets} />} />

            <Route
              path="tickets/create"
              element={<Page component={CreateTicket} />}
            />

            <Route
              path="tickets/:id"
              element={<Page component={EditTicket} />}
            />
            {/*
            <Route
              path="ticket-classes"
              element={<Test name="ticket-classes" />}
            /> */}

            {/* PRICE CARD ROUTES */}

            <Route
              path="price-cards"
              element={<Page component={PriceCards} />}
            />
            <Route
              path="price-cards/create"
              element={<Page component={PriceCardCreate} />}
            />

            <Route
              path="price-cards/:id"
              element={<Page component={PriceCardDetails} />}
            />

            {/* SCREEN ROUTES */}

            <Route path="screens" element={<Page component={AllScreens} />} />

            <Route
              path="screens/create"
              element={<Page component={CreateScreen} />}
            />

            <Route
              path="screens/:id"
              element={<Page component={EditScreen} />}
            />

            {/* USER ROUTES */}

            <Route path="users" element={<Page component={AllStaff} />} />
            <Route
              path="users/claims"
              element={<Page component={StaffClaims} />}
            />

            <Route
              path="users/create"
              element={<Page component={CreateStaff} />}
            />
            <Route
              path="users/shifts"
              element={<Page component={StaffShifts} />}
            />
            <Route
              path="users/tasks"
              element={<Page component={StaffTasks} />}
            />
            <Route
              path="users/create-shift"
              element={<Page component={CreateStaffShift} />}
            />
            <Route
              path="users/create-task"
              element={<Page component={CreateStaffTask} />}
            />
            <Route
              path="users/roster"
              element={<Page component={StaffRoster} />}
            />

            <Route
              path="users/create-pin/:id"
              element={<Page component={PinCreate} />}
            />
            <Route
              path="users/update-pin/:id"
              element={<Page component={PinUpdate} />}
            />

            <Route path="users/:id" element={<Page component={EditStaff} />} />
            <Route path="tasks/:id" element={<Page component={EditTask} />} />
            <Route path="shifts/:id" element={<Page component={EditShift} />} />

            <Route
              path="sessions"
              element={<Page component={StaffSessions} />}
            />

            <Route
              path="issues/create"
              element={<Page component={CreateIssue} />}
            />

            <Route
              path="issues/:id"
              element={<Page component={IssueDetails} />}
            />

            <Route path="issues" element={<Page component={IssuesList} />} />

            {/* <Route path="reports" element={<Test name="reports" />} /> */}
            <Route path="settings" element={<Test name="settings" />} />
          </Route>

          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          <Route path="/" element={<Navigate replace to="/dashboard" />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </ErrorBoundary>

      <TawkMessengerReact
        propertyId="636e3b57daff0e1306d6e8f0"
        widgetId="1ghj9fjtn"
      />
    </div>
  );
}

export default App;
