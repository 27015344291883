import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  PrivateRoute,
  ScreenForm,
  Loading,
  PageHeader,
  Success,
  ErrorWithWrapper,
  SuccessModal,
} from '../../../components';

import { useRequest } from '../../../hooks';
import { editScreen } from '../../../redux/slices';
import {
  API_URL,
  axiosApiInstance,
  axiosApiInstanceGet,
  FETCH_STATUS,
  onReject,
  //trueFalse,
  didUpdateFields,
} from '../../../utils';

const EditScreen = () => {
  let params = useParams();

  const dispatch = useDispatch();
  const { err, status, data, setResolved, setPending, setRejected } =
    useRequest();
  const {
    err: editErr,
    status: editStatus,
    setPending: editSetPending,
    setRejected: editSetRejected,
    setResolved: editSetResolved,
    resetErr,
  } = useRequest({});

  const getInfo = useCallback(async () => {
    if (params?.id) {
      try {
        setPending();
        const res = await axiosApiInstanceGet(
          `${API_URL}/Screens/${params.id}`,
          true
        );

        setResolved(res.data.data);
      } catch (error) {
        onReject(
          setRejected,
          error,
          `Unable to retrieve screen with id ${params?.id}`
        );
      }
    }
  }, [params?.id, setRejected, setResolved, setPending]);

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  const onSave = async (values) => {
    console.log(values);
    try {
      editSetPending();
      const promises = [];

      // if (trueFalse[values.isActive] !== data.isActive) {
      //   promises.push(
      //     await axiosApiInstance.patch(`${API_URL}/Screens/${params.id}/Toggle`)
      //   );
      // }

      if (didUpdateFields(data, values)) {
        promises.push(
          await axiosApiInstance.patch(
            `${API_URL}/Screens/${params.id}/Update`,
            { ...values, isActive: !!values.isActive }
          )
        );
      }

      const res = await Promise.all(promises);

      if (res.length) {
        const card = {
          ...res[0].data.data,
          isActive: !!values.isActive,
        };

        dispatch(editScreen(card));
      }

      editSetResolved();
    } catch (error) {
      onReject(editSetRejected, error, 'Unable to update screen details');
    }
  };

  return (
    <PrivateRoute redirectTo="/dashboard/screens">
      <PageHeader>
        <h1 className="text-2xl font-semibold text-white ">Edit Screen</h1>
      </PageHeader>
      <Loading
        wrapperClassName="relative min-h-[50vh]"
        show={status === FETCH_STATUS.PENDING}
      />

      <ErrorWithWrapper
        error={err}
        show={status === FETCH_STATUS.REJECTED}
        retry={getInfo}
        className="!max-w-md !mx-auto"
      />

      <Success show={status === FETCH_STATUS.RESOLVED}>
        <ScreenForm
          error={editErr}
          onReset={resetErr}
          onSave={onSave}
          isEdit
          info={data}
          status={editStatus}
        />

        <SuccessModal
          message="Successfully edited screen."
          show={editStatus === FETCH_STATUS.RESOLVED}
          backLink="/dashboard/screens"
        />
      </Success>
    </PrivateRoute>
  );
};

export { EditScreen };
