import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { Spinner2 } from "./Spinner2";
import { useSelector } from "react-redux";
import { FETCH_STATUS } from "../../../utils";

const ActiveActionModal = ({
  onClose = () => {},
  show,
  message = "Seems There is an error",
  onClick,
}) => {
  const { createItemStatus, editItemStatus, getItemStatus } = useSelector(
    (state) => state.items
  );

  return (
    <>
      <Transition
        show={
          createItemStatus === FETCH_STATUS.PENDING ||
          editItemStatus === FETCH_STATUS.PENDING ||
          getItemStatus === FETCH_STATUS.PENDING
            ? true
            : false
        }
        appear
        as={Fragment}
      >
        <Dialog as="div" className="relative z-[10000]" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <div className="flex justify-center">
                    <Spinner2 />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export { ActiveActionModal };
