import { useMemo, useState, useEffect } from "react";
import {
  PrivateRoute,
  ClientPaginatedTable,
  DefaultColumnFilter,
  Spinner,
  EditStoreItemModal,
} from "../../../../components";
import { ITEM_GROUP } from "../../../../utils";
import {
  getItemsStock,
  getStoreLocations,
  getCinemasByCircuitId,
} from "../../../../redux/slices";
import { useDispatch, useSelector } from "react-redux";
import { stockData, selectCinemas } from "../../../../redux/slices";
import { useLoggedInuser } from "../../../../hooks";

const StockCount2 = () => {
  const [itemGroup, setItemGroup] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [itemData, setItemData] = useState(null);
  const [storeLocationId, setStoreLocationId] = useState("");
  const { profile } = useLoggedInuser();
  const [cinemaId, setCinemaId] = useState(profile?.cinemaId);
  const [errorMessage, setErrorMessage] = useState("");
  const { itemsStock, itemsStockStatus } = useSelector(stockData);
  const { cinemasInCircuit } = useSelector(selectCinemas);

  const dispatch = useDispatch();
  const { storeLocations } = useSelector(stockData);

  useEffect(() => {
    dispatch(getStoreLocations(true));
    dispatch(getCinemasByCircuitId(profile?.circuitId));
  }, [dispatch, profile]);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!itemGroup) {
      setErrorMessage("Ensure you select an Item Group");
      return;
    }
    if (!storeLocationId) {
      setErrorMessage("Ensure you select a Store Location");
      return;
    }
    if (!cinemaId) {
      setErrorMessage("Ensure you select a Cinema");
      return;
    }
    setErrorMessage("");
    dispatch(getItemsStock({ itemGroup, storeLocationId, cinemaId }));
  };

  const data = useMemo(() => {
    if (itemsStock?.length) {
      return itemsStock;
    } else {
      return [];
    }
  }, [itemsStock]);

  const columns = useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: "item",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Unit Of Measurement",
        accessor: "unitOfMeasurement",
      },
      {
        Header: "Available Quantity",
        accessor: "availableQuantity",
      },
      {
        Header: "Threshhold Quantity",
        accessor: "thresholdQuantity",
      },
      {
        Header: "Re-order Level",
        accessor: "reorderLevel",
      },
      {
        Header: "Store Location",
        accessor: "storeLocation",
      },
    ];
  }, []);
  const onRowClick = (row) => () => {
    setItemData(row?.original);
   
    setShowModal(true);
  };
  return (
    <PrivateRoute redirectTo="/dashboard/stock">
      <p className="font-bold px-3">Items Stock</p>
      <div className="w-[95%] mx-auto mt-6 ">
        <form onSubmit={submitHandler}>
          <div className="flex justify-start">
            <div className="mr-5">
              <select
                className="rounded bg-[#d0a3d3] border-0 mb-3 w-[160px]"
                onChange={(e) => {
                  setCinemaId(e.target.value);
                  if (e.target.value) {
                    setErrorMessage("");
                  }
                }}
              >
                <option value={""}>Select A Cinema</option>
                {cinemasInCircuit.length > 0 &&
                  cinemasInCircuit?.map((cinema, index) => {
                    return (
                      <option
                        value={cinema.id}
                        key={index}
                        selected={
                          profile?.cinemaId === cinema.id ? "selected" : ""
                        }
                      >
                        {cinema.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="mr-5">
              <select
                className="rounded bg-[#d0a3d3] border-0 mb-3 w-[160px]"
                onChange={(e) => {
                  setItemGroup(e.target.value);
                  if (e.target.value) {
                    setErrorMessage("");
                  }
                }}
              >
                <option value={""}>Select Item Group</option>
                <option value={ITEM_GROUP.SINGLE}>Single</option>
                <option value={ITEM_GROUP.COMPONENT}>Component</option>
                <option value={ITEM_GROUP.SUNDRY}>Sundry</option>
              </select>
            </div>
            <div className="mr-5">
              <select
                className="rounded bg-[#d0a3d3] border-0 mb-3 w-[160px]"
                onChange={(e) => {
                  setStoreLocationId(e.target.value);
                  if (e.target.value) {
                    setErrorMessage("");
                  }
                }}
              >
                <option value={""}>Select Location</option>
                <option value={"allStores"}>All Stores</option>
                {storeLocations.length > 0 &&
                  storeLocations?.map((location, index) => {
                    return (
                      <option value={location.id} key={index}>
                        {location.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <button
              className=" mb-[30px] p-1 px-3 flex items-center justify-between rounded-md text-white bg-[#7E2080] shadow-sm hover:shadow-xl hover:text-white"
              type="submit"
            >
              Get Item Stock
              <Spinner
                className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                status={itemsStockStatus}
              />
            </button>
          </div>

          {errorMessage && (
            <p className={`italic  text-red-400 font-light text-[10px] mb-5 `}>
              {errorMessage}
            </p>
          )}
        </form>
        <div className="flex items-center justify-end p-4 px-8 rounded-t-lg bg-gradient-to-b from-app-purple-4 to-app-purple-3"></div>
        <div className="overflow-x-auto">
          <ClientPaginatedTable
            filterColumnsKeys={["item"]}
            //   toolbarChildren={}
            onRowClick={onRowClick}
            tableData={data}
            columns={columns}
            getHeaderProps={(column) => ({
              className:
                "px-3 pt-8 pb-2 text-base font-medium border border-[#00000033] ",
              ...(column.id === "film" ? column?.getSortByToggleProps() : {}),
            })}
            getCellProps={(cellInfo) => ({
              style: {},
              className: `${
                cellInfo?.column?.id === "film" ? "text-app-purple-9" : ""
              } ${cellInfo?.column?.id === "days" ? "break-all" : ""} `,
            })}
            getColumnProps={() => ({ style: {} })}
          />
        </div>
      </div>
      {showModal && (
        <EditStoreItemModal
          show={showModal}
          prevItemData={itemData}
          onClose={() => {
            setItemData(null);
            setShowModal(false);
          }}
        />
      )}
    </PrivateRoute>
  );
};
export { StockCount2 };
