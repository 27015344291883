import React from "react";

const Films = ({ fill = process.env.REACT_APP_COLOR_10 }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_355_1073)">
        <path
          d="M23.9529 1.27295H4.04749C2.51516 1.27295 1.27295 2.51516 1.27295 4.04749V23.9529C1.27295 25.4853 2.51516 26.7275 4.04749 26.7275H23.9529C25.4853 26.7275 26.7275 25.4853 26.7275 23.9529V4.04749C26.7275 2.51516 25.4853 1.27295 23.9529 1.27295Z"
          stroke={fill}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.3639 7.63659H26.7275M7.63659 1.27295V26.7275V1.27295ZM20.3639 1.27295V26.7275V1.27295ZM1.27295 14.0002H26.7275H1.27295ZM1.27295 7.63659H7.63659H1.27295ZM1.27295 20.3639H7.63659H1.27295ZM20.3639 20.3639H26.7275H20.3639Z"
          stroke={fill}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_355_1073">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { Films };
