import {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
    ActiveRequestSpinner,
    ComponenentItemTable,
    FormError,
    ItemFormErrorModal as FormErrorModal,
    PdfButton,
    PrivateRoute,
    ReqLayout,
} from "../../../../components";
import {DateTimeInput} from "../../../../components/Films/DateTimeInput";
import DatePicker from "react-datepicker";
import {useDispatch, useSelector} from "react-redux";
import {
    createStockRequest,
    getCinemasByCircuitId,
    getRequisitionTypes,
    getStockRequest,
    getStoreLocations,
    getUnitOfMeasurements,
    getVendors,
    selectCinemas,
    stockData,
    submitStockRequest,
    updateFetchedStockRequest,
    updateStockRequest,
} from "../../../../redux/slices";
import {FETCH_STATUS, replaceInArray, stockRequestSchema,} from "../../../../utils";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useLoggedInuser} from "../../../../hooks";

const InlineError = ({ err }) => {
  if (err) {
    return (
      <p className={`italic  text-red-400 font-light text-[10px] `}>{err}</p>
    );
  }

  return null;
};
const StockReqForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString());
  const [selectedItems, setSelectedItems] = useState([]);
  const [showItemsModal, setShowItemsModal] = useState(false);
  const [formError, setFormError] = useState(false);
  const { vendorsList } = useSelector((state) => state.items);
  const { cinemasInCircuit } = useSelector(selectCinemas);
  const { profile } = useLoggedInuser();
  const pdfRef = useRef(null);
  const {
    requisitionTypes,
    storeLocations,
    createStockRequestStatus,
    stockRequest,
    stockRequestStatus,
    updateStockRequestStatus,
    submitStockRequestStatus,
    stockRequests,
    createStockRequestErr,
    submitStockRequestErr,
    updateStockRequestErr,
  } = useSelector(stockData);
  const dispatch = useDispatch();

  const {
    register,
    reset,
    watch,
    setValue,
      getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(stockRequestSchema),
    defaultValues: {
      deliveryLocationId: "",
      senderLocationId: "",
      senderCinemaId: "",
      vendorId: "",
      requisitionType: "",
      requestDate: selectedDate,
    },
  });

  useEffect(() => {
    dispatch(getVendors());
    dispatch(getRequisitionTypes(true));
    dispatch(getStoreLocations(true));
    dispatch(getUnitOfMeasurements());
    dispatch(getCinemasByCircuitId(profile?.circuitId));
  }, []);

  useEffect(() => {
    setValue("requestDate", selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    if (id) {
      dispatch(getStockRequest({ id, force: true }))
        .unwrap()
        .then((data) => {
          reset({
            deliveryLocationId: data.deliveryLocationId ?? "",
            vendorId: data.vendorId ? data.vendorId : "",
            senderLocationId: data.senderLocationId ? data.senderLocationId : "",
            senderCinemaId: data.senderCinemaId ? data.senderCinemaId : "",
            locationId: data?.locationId,
            // requestDate: new Date(data?.requestDate).toISOString(),
            requisitionType: requisitionTypes.findIndex(
              (type) => type === data.requisitionType
            ),
          });
          setSelectedDate(new Date(data?.requestDate).toISOString());
          setValue("requestDate", selectedDate);
          setSelectedItems(
            data?.items?.map((item) => {
              return {
                ...item,
                name: item.item,
                quantity: item.quantityDesired,
                id: item.itemId,
              };
            })
          );
        });
    }
  }, [id, requisitionTypes]);

  const formatData = (data) => {
    return {
      ...data,
      vendorId: data.vendorId ? data.vendorId : null,
      senderLocationId: data.senderLocationId ? data.senderLocationId : null,
      senderCinemaId: data.senderCinemaId ? data.senderCinemaId : null,
      items: selectedItems?.map((data) => {
        return {
          itemId: data.itemId,
          description: data.description,
          unitOfMeasurementId: data.unitOfMeasurementId,
          quantityDesired: data.quantity,
          quantityUOM: data.quantityUOM,
        };
      }),
      submittedDate: new Date().toISOString(),
    };
  };
  /*additional validations for the form entry which are not catered for
with react hook form
*/

  const validateData = (data) => {
    if (data?.items.length < 1) {
      setFormError("Ensure you add at least 1 item to the Stock request");
      return false;
    }
    if (
      data.items.find((item) => {
        return (
          !item.unitOfMeasurementId ||
          !item.quantityDesired ||
          !item.quantityUOM
        );
      })
    ) {
      setFormError(
        "Ensure all details for each selected item are appropriately filled"
      );
      return false;
    }
    if (data?.requisitionType === 0 && !data?.vendorId) {
      setFormError("Ensure you select a Vendor");
      return false;
    }

    if (data?.requisitionType === 0 && !data?.deliveryLocationId) {
      setFormError("Ensure you select a delivery location");
      return false;
    }
    if (data?.requisitionType === 1 && !data?.senderLocationId) {
      setFormError("Ensure you select a Sender Location");
      return false;
    }
    if (data?.requisitionType === 2 && !data?.senderCinemaId) {
      setFormError("Ensure you select a Sender Cinema");
      return false;
    }
    return true;
  };
  const saveDraftFunction = () => {
    handleSubmit((data) => {
      const cleanedData = formatData(data);
      const dataIsValid = validateData(cleanedData);
      if (dataIsValid) {
        if (!id) {
          dispatch(createStockRequest(cleanedData))
            .unwrap()
            .then((data) => {
              dispatch(
                updateFetchedStockRequest([
                  {
                    ...data,
                    requestedBy: `${data?.requestedBy?.firstName} ${data?.requestedBy?.lastName}`,
                  },
                  ...stockRequests,
                ])
              );
              reset();
              setSelectedItems([]);
            });
        }
        if (id) {
          dispatch(updateStockRequest({ id, data: cleanedData }))
            .unwrap()
            .then((data) => {
              const newData = replaceInArray(stockRequests, {
                ...data,
                requestedBy: `${data?.requestedBy?.firstName} ${data?.requestedBy?.lastName}`,
              });

              dispatch(updateFetchedStockRequest(newData));
              navigate("/dashboard/stock/stockRequest");
            });
        }
      }
    })();
  };

  const submitForApprovalFunction = () => {
    console.log(getValues())
    handleSubmit((data) => {
      const cleanedData = formatData(data);
      const dataIsValid = validateData(cleanedData);
      if (dataIsValid) {
        if (!id) {
          dispatch(createStockRequest(cleanedData))
            .unwrap()
            .then((data) => {
              dispatch(submitStockRequest(data.id))
                .unwrap()
                .then((data) => {
                  dispatch(
                    updateFetchedStockRequest([
                      {
                        ...data,
                        requestedBy:
                          data?.requestedBy?.firstName +
                          data?.requestedBy?.lastName,
                      },
                      ...stockRequests,
                    ])
                  );
                  reset();
                  setSelectedItems([]);
                  navigate("/dashboard/stock/stockRequest");
                });
            });
        }
        if (id) {
          dispatch(updateStockRequest({ id, data: cleanedData }))
            .unwrap()
            .then(() => {
              dispatch(submitStockRequest(id))
                .unwrap()
                .then((data) => {
                  const newData = replaceInArray(stockRequests, {
                    ...data,
                    requestedBy:
                      data?.requestedBy?.firstName +
                      data?.requestedBy?.lastName,
                  });
                  dispatch(updateFetchedStockRequest(newData));
                  navigate("/dashboard/stock/stockRequest");
                });
            });
        }
      }
    }, console.log)();
  };

  // const handlePrint = useReactToPrint({
  //   content: () => pdfRef.current,
  //   documentTitle: "stockRequest",
  // });

  return (
    <PrivateRoute redirectTo="/dashboard/stock/stockRequest">
      {(!id || stockRequest?.status === "Draft") && (
        <>
          <div className="flex items-center justify-end p-4 px-8 rounded-t-lg bg-gradient-to-b from-app-purple-4 to-app-purple-3">
            <div className="flex buttongroup">
              <button
                className="p-3 px-5 rounded-md text-[#C96FCC] bg-white shadow-md mr-4 hover:font-bold"
                onClick={saveDraftFunction}
              >
                Save as Draft
              </button>
              <button
                className="p-3 px-5 rounded-md text-[#C96FCC] mr-4 bg-white shadow-md hover:font-bold"
                onClick={submitForApprovalFunction}
              >
                Submit for approval
              </button>

              <button
                className="p-3 px-5 rounded-md text-[#C96FCC] bg-white shadow-md hover:font-bold"
                onClick={() => {
                  navigate("/dashboard/stock/stockRequest");
                }}
              >
                Back
              </button>
            </div>
          </div>

          <FormError
            className="mt-[20px]"
            err={
              createStockRequestErr ||
              updateStockRequestErr ||
              submitStockRequestErr
            }
          />

          <form className="flex justify-center mb-5">
            <div className=" max-w-[800px]  grid grid-cols-2 gap-5 mt-[30px] ">
              <div>
                <label className="block"> Requisition Type</label>
                <select
                  className="rounded bg-[#d0a3d3] border-0 mb-3 w-[180px]"
                  {...register("requisitionType")}
                >
                  <option value={""}>Select Requisition type</option>
                  {requisitionTypes.length > 0 &&
                    requisitionTypes?.map((type, index) => {
                      return (
                        <option value={index} key={index}>
                          {type}
                        </option>
                      );
                    })}
                </select>

                <InlineError
                  err={
                    errors?.requisitionType?.message
                      ? "Ensure you select a requisition type"
                      : ""
                  }
                />
              </div>
              <div>
                <label className="block">Delivery Location</label>
                <select
                  className="rounded bg-[#d0a3d3] border-0 mb-3 w-[180px]"
                  {...register("deliveryLocationId")}
                >
                  <option value={""}>Select Location</option>
                  {storeLocations.length > 0 &&
                    storeLocations?.map((location, index) => {
                      return (
                        <option value={location.id} key={index}>
                          {location.name}
                        </option>
                      );
                    })}
                </select>
                <InlineError err={errors?.deliveryLocationId?.message} />
              </div>
              <div>
                <label htmlFor={`datePicker`}>Request Date</label>
                <DatePicker
                  id="datePicker"
                  placeholderText="Expiry Date"
                  selected={new Date(selectedDate)}
                  onChange={(value) => setSelectedDate(value.toISOString())}
                  customInput={
                    <DateTimeInput
                      className="rounded bg-[#d0a3d3] border-0 mb-3 w-[180px]"
                      dateFormat={"EEE, MMM dd, yyyy"}
                      value={selectedDate}
                    />
                  }
                />
                <InlineError err={errors?.requestDate?.message} />
              </div>
              {watch("requisitionType") === "0" && (
                <div>
                  <label className="block"> Vendor</label>
                  <select
                    className="rounded bg-[#d0a3d3] border-0 mb-3 w-[180px]"
                    {...register("vendorId")}
                  >
                    <option value={""}>Select Vendor</option>
                    {vendorsList.length > 0 &&
                      vendorsList?.map((vendor, index) => {
                        return (
                          <option value={vendor.id} key={index}>
                            {vendor.name}
                          </option>
                        );
                      })}
                  </select>
                  <InlineError err={errors?.vendorId?.message} />
                </div>
              )}
              {watch("requisitionType") === "1" && (
                <div>
                  <label className="block">Sender Location</label>
                  <select
                    className="rounded bg-[#d0a3d3] border-0 mb-3 w-[180px]"
                    {...register("senderLocationId")}
                  >
                    <option value={""}>Select Location</option>
                    {storeLocations.length > 0 &&
                      storeLocations?.map((location, index) => {
                        return (
                          <option value={location.id} key={index}>
                            {location.name}
                          </option>
                        );
                      })}
                  </select>
                  <InlineError err={errors?.senderLocationId?.message} />
                </div>
              )}
              {watch("requisitionType") === "2" && (
                <div>
                  <label className="block">Sender Cinema</label>
                  <select
                    className="rounded bg-[#d0a3d3] border-0 mb-3 w-[180px]"
                    {...register("senderCinemaId")}
                  >
                    <option value={""}>Select Cinema</option>
                    {cinemasInCircuit.length > 0 &&
                      cinemasInCircuit?.map((cinema, index) => {
                        return (
                          <option value={cinema.id} key={index}>
                            {cinema.name}
                          </option>
                        );
                      })}
                  </select>
                  <InlineError err={errors?.senderCinemaId?.message} />
                </div>
              )}
            </div>
          </form>

          <ComponenentItemTable
            selectedComponentItems={selectedItems}
            setSelectedComponentItems={setSelectedItems}
            itemGroup={"coke"}
            showComponentModal={showItemsModal}
            setShowComponentModal={setShowItemsModal}
            useCase={"stockRequest"}
          />
        </>
      )}
      {id && stockRequest?.status !== "Draft" && (
        <div className="bg-white mx-[50px] p-5 rounded">
          <div className="flex justify-center space-x-2 mb-[30px] ">
            <button
              onClick={() => {
                navigate("/dashboard/stock/stockRequest");
              }}
              className="p-1 px-3 rounded-md text-white bg-[#7E2080] shadow-sm hover:shadow-xl hover:text-white"
            >
              Back
            </button>
            {/* <button
              type={"button"}
              className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm"
              onClick={handlePrint}
            >
              Generate PDF
            </button> */}
            <PdfButton refValue={pdfRef} docTitle={"stock-request"} />
          </div>
          <div ref={pdfRef} className="m-2">
            <ReqLayout type={"stockRequest"} data={stockRequest} />
          </div>
        </div>
      )}
      <ActiveRequestSpinner
        show={
          createStockRequestStatus === FETCH_STATUS.PENDING ||
          updateStockRequestStatus === FETCH_STATUS.PENDING ||
          submitStockRequestStatus === FETCH_STATUS.PENDING ||
          stockRequestStatus === FETCH_STATUS.PENDING
            ? true
            : false
        }
      />
      <FormErrorModal
        onClose={() => {
          setFormError(false);
        }}
        show={formError}
        message={formError}
        onClick={() => {
          setFormError(false);
        }}
      />
    </PrivateRoute>
  );
};

export { StockReqForm };
