class PriceCard {
  static toUI(info) {
    return {
      ...info,
      id: info.id,
      days: info.daysAvailable,
      active: info.isActive,
    };
  }

  static toDTO(info) {
    return {
      ...info,
      name: info.name,
      description: info.description || `description for ${info.name}`,
      cinemaId: info.cinemaId,
      tickets: info.tickets.map((t) => ({
        ticketId: t.id,
        price: t.price,
      })),
      daysAvailable: info.days,
    };
  }
}

export { PriceCard };
