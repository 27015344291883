import React, { useEffect,useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorWithWrapper, Loading, PageHeader, PrivateRoute } from '../../../components';
import { ClaimsTable } from '../../../components/Users/ClaimsTable';
import { useLoggedInuser } from '../../../hooks';
import { getStaffClaims, selectClaims } from '../../../redux/slices';
import { FETCH_STATUS } from '../../../utils';

const StaffClaims = () => {
    const dispatch = useDispatch();
    const { userClaims, userClaimsStatus, userClaimsErr } = useSelector(selectClaims);
    const { profile } = useLoggedInuser();
    

    const getInfo = useCallback(
      (force) => {
        dispatch(getStaffClaims({ force, cinemaId: profile?.cinemaId }));
      },
      [dispatch, profile?.cinemaId]
    );

    useEffect(() => {
      if (userClaimsStatus === FETCH_STATUS.IDLE && profile?.cinemaId) {
        dispatch(getStaffClaims({force:true, cinemaId: profile?.cinemaId }));
      }
    
    }, [dispatch,userClaimsStatus,profile?.cinemaId]);
  return (
    <>
      <PrivateRoute redirectTo="/dashboard/users/claims">
        <PageHeader>
          <h1 className="text-2xl font-semibold text-white ">Staff Claims</h1>

        </PageHeader>

        <ErrorWithWrapper
          error={userClaimsErr}
          show={userClaimsStatus === FETCH_STATUS.REJECTED}
          retry={getInfo}
        />
        <div className="relative">
          <Loading
            wrapperClassName="absolute top-0 left-0 right-0 min-h-[50vh]"
            show={userClaimsStatus === FETCH_STATUS.PENDING}
          />

          {userClaims?.length ? <ClaimsTable claims={userClaims} getInfo={getInfo} /> : null}
        </div>
      </PrivateRoute>
    </>
  );
}

export {StaffClaims}
