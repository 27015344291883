import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { formatDateTime } from "../../../utils";

const fakeInputClass =
  "w-full py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 border-none rounded-lg shadow-md outline-none";

const ViewRosterTask = ({ show, onClose, event }) => {
  return (
    <>
      <Transition show={show} as={Fragment}>
        <Dialog as="div" className="relative z-[1000000]" onClose={onClose}>
          {/* Overlay */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 p-4 overflow-y-auto">
            <div className="flex items-center justify-center min-h-[400px] p-2 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-100"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full min-h-[400px] max-w-2xl p-6  text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title className="relative pb-4 border-b" as="div">
                    <p className="text-lg font-medium leading-6 text-gray-900">
                      Roster Task Details
                    </p>
                    <button
                      type="button"
                      onClick={onClose}
                      className="absolute right-0 top-2/4 -translate-y-2/4 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    >
                      <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </Dialog.Title>

                  <div className="my-6">
                    <div className="grid grid-cols-2 gap-x-2 gap-y-4">
                      <div className="relative">
                        <p>Staff Name</p>
                        <div className={fakeInputClass}>
                          {`${event?.firstName} ${event?.lastName}`}
                        </div>
                      </div>
                      <div className="relative">
                        <p>Task</p>
                        <div className={fakeInputClass}>
                          {event?.rosterTaskName || event?.rosterTaskId}
                        </div>
                      </div>
                      <div className="relative">
                        <p>Shift</p>
                        <div className={fakeInputClass}>
                          {event?.shiftName || event?.id}
                        </div>
                      </div>
                      <div className="relative">
                        <p>Scheduled Date</p>
                        <div className={fakeInputClass}>
                          {formatDateTime(
                            new Date(event?.scheduledDate),
                            "EEEE, do LLLL yyyy @ HH:mm"
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center justify-center my-3">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md bg-rose-400 "
                      onClick={onClose}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ViewRosterTask;
