import { yupResolver } from "@hookform/resolvers/yup";
import DatePicker from "react-datepicker";
import React, { useEffect, useMemo, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ExportExcelBtn,
  FormGroup,
  InlineErr,
  Label,
  PdfButton,
  Pdiv,
  PrivateRoute,
  SearchSelect,
  Spinner,
  Success,
} from "../../../components";
import { useLoggedInuser } from "../../../hooks";
import {
  getDistributorFilms,
  getDistributors,
  getFilmsByCinema,
  getGBOByTicketsReport,
  reportData,
  selectFilms,
} from "../../../redux/slices";
import {
  FETCH_STATUS,
  formatDateForApi,
  GBOByTicketInit,
  GBOByTicketSchema,
  getSelectedItem2,
} from "../../../utils";
import { DateTimeInput } from "../../../components/Films/DateTimeInput";
import { Element, scroller } from "react-scroll";
import GBOByTicketTable from "../../../components/GBOByTicket/GBOByTicketTable";

const GBOByTickets = () => {
  const { profile } = useLoggedInuser();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    distributorsList,
    distributorsListStatus,
    distributorFilms,
    GBOByTickets,
    GBOByTicketsStatus,
  } = useSelector(reportData);
  const { filmsByCinema, getFilmsByCinemaStatus } = useSelector(selectFilms);
  const reportRef = useRef(null);

  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(GBOByTicketSchema),
    defaultValues: { ...GBOByTicketInit, cinemaId: profile?.cinemaId },
  });

  const selectedDistributor = watch("distributorId")


  const formattedDistributorList = useMemo(() => {
    if (distributorsList?.length > 0) {
      
      return [
        { name: "All Distributors", id: "allDistributors" },
        ...distributorsList,
      ];
    }
  }, [distributorsList]);
  const formattedDistributorFilm = useMemo(() => {
    if (distributorFilms?.pagedList?.length > 0 &&selectedDistributor!=="allDistributors") {
      
      return [
        { name: "All Films", sourceFilmId: "allFilms" },
        ...distributorFilms?.pagedList,
      ];
    }
     if (
       selectedDistributor === "allDistributors" &&
       filmsByCinema?.length > 0
     ) {
       console.log({ isallfilm: "allfilms" });
       return [
         { name: "All Films", sourceFilmId: "allFilms" },
         ...filmsByCinema,
       ];
     }
  }, [distributorFilms?.pagedList, filmsByCinema, selectedDistributor]);

  useEffect(() => {
    if (distributorsListStatus === FETCH_STATUS.IDLE) {
      dispatch(getDistributors());
    }
  }, [dispatch, distributorsListStatus]);
  useEffect(() => {
    if (
      selectedDistributor !== "allDistributors" &&
      selectedDistributor !== ""
    ) {
      dispatch(
        getDistributorFilms({
          id: selectedDistributor,
          force: true,
        })
      );
    } else {
       dispatch(getFilmsByCinema({ force: true, cinemaId: profile?.cinemaId }));
    }
  },[profile?.cinemaId, selectedDistributor])

  const onSubmit = async (values) => {
    try {
      await dispatch(
        getGBOByTicketsReport({
          ...values,
          dateFrom: formatDateForApi(new Date(values.dateFrom)),
          dateTo: formatDateForApi(new Date(values.dateTo)),
        })
      ).then(() => {
        scroller.scrollTo("reportContainer", {
          duration: 1500,
          delay: 0,
          smooth: true,
          offset: -100, // Scrolls to element + 50 pixels down the page
        });
      });
    } catch (error) {}
  };

  return (
    <PrivateRoute redirectTo={"/dashboard/reports/GBOByTickets"}>
      <div className="w-[95%] mx-auto mt-6">
        <p className="font-semi-bold px-3 pb-5">
          GBO By Tickets Report - Set Report Parameters
        </p>
        <div className="flex items-center justify-between p-4 px-8 h-[50px] rounded-t-lg bg-[#C96FCC]">
          <div className="flex gap-4">
            <span className="text-xl font-semibold text-white"></span>
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex items-center justify-end py-3 px-4 bg-white ">
            <div className="flex gap-3 buttongroup ">
              <button
                className="p-1 px-3 rounded-md text-[#C96FCC] bg-[#ffe583] shadow-md hover:shadow-sm"
                type="button"
                onClick={() => {
                  navigate("/dashboard/reports");
                }}
              >
                Cancel
              </button>
              <button
                className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm flex items-center"
                type="submit"
              >
                <span> Run Report </span>
                <Spinner
                  className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                  status={GBOByTicketsStatus}
                />
              </button>
            </div>
          </div>
          <div className="bg-app-purple-2 p-3 pt-[30px]">
            <Pdiv label={"Date Range"}>
              <div className="flex items-center">
                <Controller
                  control={control}
                  name="dateFrom"
                  render={({ field: { value, onChange } }) => (
                    <div className="relative ">
                      <DatePicker
                        placeholderText="Start Date"
                        selected={new Date(value)}
                        onChange={(value) =>
                          onChange(new Date(value).toISOString())
                        }
                        customInput={
                          <DateTimeInput
                            className="bg-app-purple-8"
                            dateFormat={"EEE, MMM dd, yyyy"}
                          />
                        }
                      />
                    </div>
                  )}
                />

                <p className="w-[6rem] text-center"> To:</p>
                <Controller
                  control={control}
                  name="dateTo"
                  render={({ field: { value, onChange } }) => (
                    <div className="relative ">
                      <DatePicker
                        placeholderText="End Date"
                        selected={new Date(value)}
                        onChange={(value) =>
                          onChange(new Date(value).toISOString())
                        }
                        customInput={
                          <DateTimeInput
                            className="bg-app-purple-8"
                            dateFormat={"EEE, MMM dd, yyyy"}
                          />
                        }
                      />
                    </div>
                  )}
                />
              </div>
            </Pdiv>

            <FormGroup className={"mb-[20px] relative"}>
              <Label className={"w-[23rem] mr-[30px]"}>
                Select Distributor:
              </Label>

              <Controller
                control={control}
                name={"distributorId"}
                render={({ field: { value, onChange } }) => {
                  return (
                    <SearchSelect
                      items={formattedDistributorList}
                      inputClassName="!w-full p-2 px-4 py-2 border-none !bg-app-purple-10"
                      listClassName="!py-2"
                      id={`distributorId`}
                      value={getSelectedItem2(
                        formattedDistributorList,
                        value,
                        "id"
                      )}
                      onChange={(value) => onChange(value?.id) }
                      displayValue={(value) => {
                        return value?.name;
                      }}
                      placeholder={`Select Distributor`}
                      searchOptions={{
                        keys: ["name"],
                      }}
                    />
                  );
                }}
              />
              <InlineErr
                err={errors?.distributorId?.message}
                className={"left-[25rem]"}
              />
            </FormGroup>

            <FormGroup className={"mb-[20px] relative "}>
              <Label className={"w-[23rem] mr-[30px]"}>Select Film:</Label>
              <Controller
                control={control}
                name={"filmId"}
                render={({ field: { value, onChange } }) => {
                  return (
                    <SearchSelect
                      items={formattedDistributorFilm}
                      dropDownNameKey="name"
                      inputClassName="!w-full p-2 px-4 py-2 border-none !bg-app-purple-10"
                      listClassName="!py-2"
                      id={`filmId`}
                      value={getSelectedItem2(
                        formattedDistributorFilm,
                        value,
                        "sourceFilmId"
                      )}
                      onChange={(value) => onChange(value?.sourceFilmId)}
                      displayValue={(value) => {
                        return value?.name;
                      }}
                      placeholder={`Select Film`}
                      searchOptions={{
                        keys: ["name"],
                      }}
                    />
                  );
                }}
              />
              <InlineErr
                err={errors?.filmId?.message}
                className={"left-[25rem]"}
              />
            </FormGroup>
          </div>
        </form>
      </div>
      <Success show={GBOByTicketsStatus === FETCH_STATUS.RESOLVED}>
        <Element name="reportContainer">
          {GBOByTickets?.result?.length ? (
            <div>
              <div className="flex justify-center mt-5 space-x-4">
                <PdfButton refValue={reportRef} docTitle={"gbo-by-ticket"} />
                <ExportExcelBtn
                  id={"GBOTicket-xlsx"}
                  name={"GBOByTicket.xlsx"}
                />
              </div>

              <GBOByTicketTable
                ref={reportRef}
                data={GBOByTickets?.result}
                total={GBOByTickets?.total}
                paramFilter={GBOByTickets?.paramFilter}
              />
              <div className="hidden">
                <GBOByTicketTable
                  id={"GBOTicket-xlsx"}
                  data={GBOByTickets?.result}
                  total={GBOByTickets?.total}
                  paramFilter={GBOByTickets?.paramFilter}
                />
              </div>
            </div>
          ) : (
            <p className="text-red-700 flex justify-center mt-3">
              No Data Found
            </p>
          )}
        </Element>
      </Success>
    </PrivateRoute>
  );
};

export { GBOByTickets };
