import React from "react";
import intervalToDuration from "date-fns/intervalToDuration";

import { formatDateTime, VIEW_MODES } from "../../utils";
import { getBgColor, getTextColor } from "../../utils/mockData/sessions";
import { OpenStatus, ClosedStatus, PrivateStatus, PlanStatus } from "../Icons";

const FilmSession = ({ event, list, mode }) => {
  const statusIcon = {
    Open: OpenStatus,
    Closed: ClosedStatus,
    Private: PrivateStatus,
    Planned: PlanStatus,
  };

  const Icon = statusIcon[event.status];

  const film = list[event.filmId];
  const bg = getBgColor(event.filmId);
  const textColor = getTextColor(bg);

  const { hours, minutes } = intervalToDuration({
    start: new Date(event.startTime),
    end: new Date(event.endTime),
  });

  if (mode === VIEW_MODES.WEEK) {
    return (
      <div
        style={{
          backgroundColor: bg,
          color: textColor,
        }}
        className="p-2 mx-2 my-1 rounded-lg"
      >
        <h3 className="mb-1 text-xs font-medium truncate ">{film?.title}</h3>

        <div className="text-[10px] font-normal truncate">
          <p className="">{formatDateTime(event.startTime, "EEE do, HH:mm")}</p>
          <p className="">
            Duration : {hours ? `${hours} hr${hours > 1 ? "s" : ""}` : null}{" "}
            {minutes ? `${minutes} min${minutes > 1 ? "s" : ""}` : null}
          </p>

          <p className="text-[10px]  font-normal  ">
            {event.seatsSold}/{event.seatsAvailable}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundColor: bg,
        color: textColor,
      }}
      className="w-full p-2 h-full rounded-lg "
    >
      <h3 className="text-sm font-medium truncate ">{film?.title}</h3>

      <p className="my-2 text-xs font-normal ">
        {formatDateTime(event.startTime, "HH:mm")} -{" "}
        {formatDateTime(event.endTime, "HH:mm")}
      </p>
      <div className="flex justify-between">
        <div className="bg-transparent p-[1px]">
          <Icon />
        </div>
        <div className="relative">
          <p className="text-[10px]  font-normal absolute bottom-0 right-0 ">
            {event.seatsSold}/{event.seatsAvailable}
          </p>
        </div>
      </div>
    </div>
  );
};

export { FilmSession };
