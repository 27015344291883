import { expiresKey, refreshTokenKey, tokenKey } from "./constants";

export const setTokenService = (refreshToken, token, expires) => {
  localStorage.setItem(refreshTokenKey, refreshToken);
  localStorage.setItem(tokenKey, token);
  localStorage.setItem(expiresKey, expires);
};

export const clearTokenService = () => {
  localStorage.removeItem(refreshTokenKey);
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(expiresKey);
};
