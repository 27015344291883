import {addDays, subDays} from "date-fns";
import React, {useCallback, useMemo} from "react";
import {dateAndTime, formatAMPM, formatDateTime, monthNames,} from "../../utils";
import ReachLogo from "../../assets/images/logo.png";
import {ExportTable} from "../UI";
import {useLoggedInuser} from "../../hooks";

const ShowtimesReportTable = ({ data, id, paramFilter }) => {
  let dayFrom = new Date(paramFilter.dateFrom).getDay();
  let dayTo = new Date(paramFilter.dateTo).getDay();
  const { profile } = useLoggedInuser();

  const getDays = useCallback(
    (num) => {
      let dayFromUpdate = dayFrom === 0 ? 7 : dayFrom;
      let dayToUpdate = dayTo === 0 ? 7 : dayTo;

      if (num === dayFromUpdate) {
        return new Date(paramFilter.dateFrom);
      }

      if (num > dayFromUpdate) {
        return addDays(new Date(paramFilter.dateFrom), num - dayFromUpdate);
      } else if (num < dayFromUpdate) {
        return subDays(new Date(paramFilter.dateTo), dayToUpdate - num);
      }
    },
    [paramFilter.dateFrom, paramFilter.dateTo, dayFrom, dayTo]
  );

  const columns = useMemo(() => {
    return [
      {
        Header: "Film",
        accessor: "film",
      },
      {
        Header: "Duration",
        accessor: "duration",
        Cell: ({ value }) => `${value} mins`,
      },
      {
        Header: "Schedules",

        columns: [
          {
            Header: () => {
              const value = getDays(5);
              return (
                <>
                  <p>FRI</p>
                  <p>{`${value.getDate()} ${monthNames[value.getMonth()]}`}</p>
                </>
              );
            },
            accessor: "schedules",
            Cell: ({ value }) => {
              return value.friday.length
                ? value.friday.map((v) => (
                    <p>{formatAMPM(v)}</p>
                  ))
                : "N/A";
            },
          },
          {
            Header: () => {
              const value = getDays(6);
              return (
                <>
                  <p>SAT</p>
                  <p>{`${value.getDate()} ${monthNames[value.getMonth()]}`}</p>
                </>
              );
            },
            id: "saturday",
            Cell: ({ cell }) => {
              const value = cell?.row?.original?.schedules;
              return value.saturday.length
                ? value.saturday.map((v) => (
                    <p>{formatAMPM(v)}</p>
                  ))
                : "N/A";
            },
          },
          {
            Header: () => {
              const value = getDays(7);
              return (
                <>
                  <p>SUN</p>
                  <p>{`${value.getDate()} ${monthNames[value.getMonth()]}`}</p>
                </>
              );
            },
            id: "sunday",
            Cell: ({ cell }) => {
              const value = cell?.row?.original?.schedules;
              return value.sunday.length
                ? value.sunday.map((v) => (
                    <p>{formatAMPM(v)}</p>
                  ))
                : "N/A";
            },
          },
          {
            Header: () => {
              const value = getDays(1);
              return (
                <>
                  <p>MON</p>
                  <p>{`${value.getDate()} ${monthNames[value.getMonth()]}`}</p>
                </>
              );
            },
            id: "monday",
            Cell: ({ cell }) => {
              const value = cell?.row?.original?.schedules;
              return value.monday.length
                ? value.monday.map((v) => (
                    <p>{formatAMPM(v)}</p>
                  ))
                : "N/A";
            },
          },
          {
            Header: () => {
              const value = getDays(2);
              return (
                <>
                  <p>TUES</p>
                  <p>{`${value.getDate()} ${monthNames[value.getMonth()]}`}</p>
                </>
              );
            },
            id: "tuesday",
            Cell: ({ cell }) => {
              const value = cell?.row?.original?.schedules;
              return value.tuesday.length
                ? value.tuesday.map((v) => (
                    <p>{formatAMPM(v)}</p>
                  ))
                : "N/A";
            },
          },
          {
            Header: () => {
              const value = getDays(3);
              return (
                <>
                  <p>WED</p>
                  <p>{`${value.getDate()} ${monthNames[value.getMonth()]}`}</p>
                </>
              );
            },
            id: "wednesday",
            Cell: ({ cell }) => {
              const value = cell?.row?.original?.schedules;
              return value.wednesday.length
                ? value.wednesday.map((v) => (
                    <p>{formatAMPM(v)}</p>
                  ))
                : "N/A";
            },
          },
          {
            Header: () => {
              const value = getDays(4);
              return (
                <>
                  <p>THUR</p>
                  <p>{`${value.getDate()} ${monthNames[value.getMonth()]}`}</p>
                </>
              );
            },
            id: "thursday",

            Cell: ({ cell }) => {
              const value = cell?.row?.original?.schedules;
              return value.thurday.length
                ? value.thurday.map((v) => (
                    <p>{formatAMPM(v)}</p>
                  ))
                : "N/A";
            },
          },
        ],
      },
    ];
  }, [getDays]);
  return (
    <>
      <div className="mx-2 overflow-auto" id={"showtimes-doc"}>
        <div className="flex items-center justify-between">
          <div
            key={ReachLogo}
            className=" w-[5rem] h-[5rem]  bg-no-repeat bg-contain bg-center "
            style={{
              backgroundImage: `url("${ReachLogo}")`,
            }}
          ></div>
          {/* <img
            alt="reach-logo"
            src={ReachLogo}
            className=" w-[5rem] h-[3rem]"
          /> */}

          <p className="font-bold text-[20px]">Showtimes Schedule</p>
          <p>{paramFilter.cinema}</p>
        </div>
        <div className=" flex p-2 justify-between">
          <div>
            <p className="font-bold">Data Range</p>
            <p>{new Date(paramFilter?.dateFrom).toDateString()}</p>{" "}
            <p>{new Date(paramFilter?.dateTo).toDateString()}</p>
          </div>
          <div>
            <p>
              {" "}
              <span className="font-bold">User Name:</span>
              {` ${profile.firstName} ${profile.lastName}`}
            </p>
            <p>
              <span className="font-bold">Date Time Generated: </span>
              {formatDateTime(paramFilter?.dateTimeGenerated, dateAndTime)}
            </p>
          </div>
        </div>
        <ExportTable filteredData={data} columns={columns} id={id} />
      </div>
    </>
  );
};

export default ShowtimesReportTable;
