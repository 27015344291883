import React from "react";
import { ErrorModal, Spinner, SuccessModal } from "../../UI";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { FETCH_STATUS, formatDateTime, getAPIErrMessage } from "../../../utils";

const DeleteRosterModal = ({
  show,
  onClose,
  onDelete,
  event,
  tasks,
  shifts,
  resetDelErrs,
  deleteErr,
  deleteStatus,
}) => {
  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog as="div" className="relative z-[1000000000]" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="max-w-2xl p-6 m-2 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Delete Roster Task
                  </Dialog.Title>
                  <div className="mt-4">
                    <h3 className="my-1 text-lg font-medium">
                      You are about to delete a roster task for{" "}
                      <i className="text-app-purple-3">
                        {`${event?.firstName}${event?.lastName}` || event?.id}
                      </i>{" "}
                      with details:
                    </h3>

                    <div className="relative grid items-center grid-cols-3 gap-2 py-2">
                      <div>
                        <label
                          htmlFor={`scheduledDate`}
                          className="block text-gray-700 text-sm font-bold mb-2"
                        >
                          Scheduled Date
                        </label>

                        <input
                          disabled
                          className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                          value={formatDateTime(
                            new Date(event?.scheduledDate),
                            "yyyy-LL-dd @ HH:mm"
                          )}
                        />
                      </div>
                      <div className="w-full relative">
                        <label
                          htmlFor="task"
                          className="block text-gray-700 text-sm font-bold mb-2"
                        >
                          Task
                        </label>
                        <select
                          disabled
                          value={event?.rosterTaskId}
                          className="w-full px-[16px] py-[10px] border-none outline-none focus:ring-0 rounded-lg bg-[#AA7DAA26]"
                          id="task"
                        >
                          {tasks.map((task) => {
                            return (
                              <option key={task?.id} value={task?.id}>
                                {task?.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="w-full relative">
                        <label
                          htmlFor="shift"
                          className="block text-gray-700 text-sm font-bold mb-2"
                        >
                          Shift
                        </label>
                        <select
                          disabled
                          value={event?.shiftId}
                          className="w-full px-[16px] py-[10px] border-none outline-none focus:ring-0 rounded-lg bg-[#AA7DAA26]"
                          id="shift"
                        >
                          {shifts.map((shift) => {
                            return (
                              <option key={shift?.id} value={shift?.id}>
                                {shift?.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center justify-center mt-6 mb-2 space-x-4">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md bg-rose-400 "
                      onClick={onClose}
                    >
                      Cancel
                    </button>

                    <button
                      onClick={() => onDelete(event.id)}
                      disabled={deleteStatus === FETCH_STATUS.PENDING}
                      className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-green-400 border border-transparent rounded-md "
                    >
                      Continue{" "}
                      <Spinner
                        className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                        status={deleteStatus}
                      />
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <ErrorModal
        show={deleteStatus === FETCH_STATUS.REJECTED}
        message={getAPIErrMessage(deleteErr, "Unable to delete roster task")}
        onClose={resetDelErrs}
        onClick={resetDelErrs}
      />
    </>
  );
};

export default DeleteRosterModal;
