import React, { useMemo, useCallback } from "react";
import {
  ConfirmModal,
  DefaultListTable,
  ErrorModal,
  SuccessModal,
} from "../UI";
import { Delete, Edit } from "../Icons";
import { NavLink, useNavigate } from "react-router-dom";
import { useRequest, useToggle } from "../../hooks";
import { useState } from "react";
import {
  API_URL,
  FETCH_STATUS,
  axiosApiInstance,
  getAPIErrMessage,
} from "../../utils";

const TaskTable = ({ tasks, getInfo }) => {
  const [isOpen, setOpen] = useToggle(false);
  const [isSuccess, setSuccess] = useToggle(false);
  const [taskId, setTaskId] = useState("");
  const {
    err,
    status: deleteStatus,
    resetErr,
    setResolved,
    setPending,
    setRejected,
  } = useRequest();

  const handleOpen = useCallback(
    (id) => {
      setTaskId(id);
      setOpen();
    },
    [setOpen]
  );

  const handleCloseSuccessModal = useCallback(() => {
    setSuccess();
    setOpen();
  }, [setOpen, setSuccess]);

  const handleDeleteShift = async () => {
    try {
      setPending();
      await axiosApiInstance
        .delete(`${API_URL}/RosterTasks/${taskId}`)
        .then(() => {
          getInfo();
        });
      setResolved();
      setSuccess();
    } catch (err) {
      setRejected(err || "unable to delete task");
    }
  };

  const columns = useMemo(() => {
    return [
      {
        Header: "#",
        Cell: (row) => Number(row.row.id) + 1,
      },
      {
        Header: "Task Name",
        accessor: "name",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          return <>{value === 1 ? "Active" : "Inactive"}</>;
        },
      },
      // {
      //   Header: "Action",
      //   Cell: ({ row: { original } }) => {
      //     return (
      //       <span className="flex space-x-4">
      //         <button className="pl-2" onClick={() => handleOpen(original.id)}>
      //           <Delete />
      //         </button>
      //         <NavLink to={`/dashboard/tasks/${original?.id}`}>
      //           <Edit />
      //         </NavLink>
      //       </span>
      //     );
      //   },
      // },
    ];
  }, [handleOpen]);
  return (
    <>
      <DefaultListTable
        columns={columns}
        data={tasks}
        tableClass=""
        bodyClass="!bg-white !border !border-gray-200"
        headerClass="!bg-[#EEE1EE] !border-none"
      />
      {isOpen && (
        <>
          <ConfirmModal
            onClick={handleDeleteShift}
            text="Are you sure you want to delete this task?"
            onClose={setOpen}
            show={isOpen}
            status={deleteStatus}
          />
        </>
      )}
      <SuccessModal
        onClick={handleCloseSuccessModal}
        message="Shift deleted successfully."
        show={isSuccess}
      />

      <ErrorModal
        show={deleteStatus === FETCH_STATUS.REJECTED}
        message={getAPIErrMessage(err, " Unable to delete task")}
        onClose={resetErr}
        onClick={resetErr}
      />
    </>
  );
};

export { TaskTable };
