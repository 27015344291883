import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  FETCH_STATUS,
  API_URL,
  API_URLV2,
  //axiosApiInstanceGet,
  axiosApiInstance,
  ITEM_GROUP,
  getAPIErr,
  axiosApiInstanceGet,
} from "../../utils";
import { addItemDirect, editItemDirect } from "../../utils";

const force = true;

export const getItems = createAsyncThunk(
  "get_Items",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Items/ListAll`,
        force
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getKioskItems = createAsyncThunk(
  "getKioskItems",
  async (cinemaId, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Items/ByKioskCinema?cinemaId=${cinemaId}`,
        force
      );
     
      const allItems = [
        ...response?.data?.data?.itemPackages,
        ...response?.data?.data?.items,
      ];
      return fulfillWithValue(allItems || []);
    } catch (error) {
      return rejectWithValue(
        getAPIErr(
          error?.response?.data ||
            error?.message ||
            "unable to fetch kiosk items"
        )
      );
    }
  }
);

export const getPosItems = createAsyncThunk(
  "getPosItems",
  async (cinemaId, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Items/POSListAll?cinemaId=${cinemaId}`,
        force
      );
      return fulfillWithValue(response?.data?.data || []);
    } catch (error) {
      return rejectWithValue(
        getAPIErr(
          error?.response?.data || error?.message || "unable to fetch POS items"
        )
      );
    }
  }
);

export const getItem = createAsyncThunk(
  "get_Item",
  async ({ id, itemGroup }, { fulfillWithValue, rejectWithValue }) => {
    let url;
    if (
      itemGroup === "Single" ||
      itemGroup === "Component" ||
      itemGroup === "Recipe"
    ) {
      url = `${API_URL}/Items/${id}`;
    }
    if (itemGroup === "Parent") {
      url = `${API_URL}/ItemParents/${id}`;
    }
    if (itemGroup === "Package") {
      url = `${API_URL}/ItemPackages/${id}`;
    }

    try {
      const response = await axiosApiInstance.get(url);

      const Item = response.data.data;

      return fulfillWithValue(Item);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getItemClasses = createAsyncThunk(
  "get_Item_classes",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/ItemClasses/GetItemClasses`
      );

      const Items = response.data.data;

      return fulfillWithValue(Items);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getUnitOfMeasurements = createAsyncThunk(
  "get_unit_of_measurement",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/UnitOfMeasurements/ListAll?page=1&perPage=100`
      );

      const units = response.data.data.pagedList;

      return fulfillWithValue(units);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getItemGroups = createAsyncThunk(
  "get_Item_groups",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/ItemGroups/GetItemGroups`
      );

      const Items = response.data.data;

      return fulfillWithValue(Items);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getVendors = createAsyncThunk(
  "get_vendors",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.get(`${API_URL}/Vendors/ListAll`);

      const Items = response.data.data;

      return fulfillWithValue(Items);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const createItem = createAsyncThunk(
  "create_Item",
  async (itemData, { fulfillWithValue, rejectWithValue }) => {
    let url;
    //for single,component and recipe items
    if (
      itemData.itemGroupId === "fdf1e9e5-fcf7-48cc-8bc0-c9bb63b87b41" ||
      itemData.itemGroupId === "d6eb0b4f-cb89-4c1b-a22c-e9143cd0678b" ||
      itemData.itemGroupId === "76a4f000-03e1-4627-8b0a-85e569a7d5e4" ||
      itemData.itemGroupId === ITEM_GROUP.SUNDRY
    ) {
      url = `${API_URLV2}/Items/Create`;
    }
    //for parent items
    if (itemData.itemGroupId === "b089b585-2ffb-4ebc-af43-c2f294dbfe8d") {
      url = `${API_URL}/ItemParents/Create`;
    }
    //for package items
    if (itemData.itemGroupId === "03571310-71a2-4425-93ce-1c65d18b4341") {
      url = `${API_URL}/ItemPackages/Create`;
    }

    try {
      const response = await axiosApiInstance.post(url, { ...itemData });

      if (response.data) {
        return fulfillWithValue(response?.data?.data ?? []);
      }
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const editItem = createAsyncThunk(
  "edit_Item",
  async ({ itemData, itemGroup }, { fulfillWithValue, rejectWithValue }) => {
    //console.log("in edit");

    // if item is a single item
    if (
      itemGroup === "Single" ||
      itemGroup === "Component" ||
      itemGroup === "Recipe"
    ) {
      try {
        const response = await axiosApiInstance.patch(
          `${API_URLV2}/Items/${itemData.id}/Update`,
          { ...itemData }
        );

        if (response.data) {
          return fulfillWithValue(response.data.data);
        }
      } catch (err) {
        const error = { ...err };
        let message = "Unable to complete request";

        return rejectWithValue(
          error?.response.data.errors[0].errorMessages[0] || message
        );
      }
    }

    //if item is a parent Item
    if (itemGroup === "Parent") {
      try {
        const response1 = await axiosApiInstance.patch(
          `${API_URL}/ItemParents/Update?itemParentId=${itemData.id}`,
          { ...itemData }
        );

        const newData = itemData.itemList.map((item) => {
          return { itemId: item.itemId, price: item.price };
        });
        const response2 = await axiosApiInstance.patch(
          `${API_URL}/ItemParents/${itemData.id}/AddItems`,
          newData
        );

        if (response1.data && response2.data) {
          return fulfillWithValue({
            ...response1.data.data,
            ...response2.data.data,
          });
        }
      } catch (err) {
        const error = { ...err };
        let message = "Unable to complete request";

        return rejectWithValue(
          error?.response.data.errors[0].errorMessages[0] || message
        );
      }
    }

    //if item is a package Item
    if (itemGroup === "Package") {
      try {
        let response1 = {};
        let response2 = {};
        let response3 = {};
        if (itemData.items) {
          const newData = itemData.items.map((item) => {
            return {
              itemId: item.itemId,
              price: item.price,
              quantity: item.quantity,
            };
          });
          response1 = await axiosApiInstance.patch(
            `${API_URL}/ItemPackages/${itemData.id}/AddItems`,
            newData
          );
        }
        if (itemData.parentItems) {
          const newData = itemData.parentItems.map((item) => {
            return {
              itemParentId: item.id,
              price: item.price,
              quantity: item.quantity,
            };
          });
          response2 = await axiosApiInstance.patch(
            `${API_URL}/ItemPackages/${itemData.id}/AddPackageParentItems`,
            newData
          );
        }

        response3 = await axiosApiInstance.patch(
          `${API_URL}/ItemPackages/Update?itemPackageId=${itemData.id}`,
          { ...itemData }
        );

        if (response1.data && response2.data && response3.data) {
          return fulfillWithValue({
            ...response1.data.data,
            ...response2.data.data,
            ...response3.data.data,
          });
        }
      } catch (err) {
        const error = { ...err };
        let message = "Unable to complete request";

        return rejectWithValue(
          error?.response.data.errors[0].errorMessages[0] || message
        );
      }
    }
  }
);
const initialState = {
  getItemsError: "",
  getItemsStatus: FETCH_STATUS.IDLE,
  itemsList: [],
  getItemClassesError: "",
  getItemClassesStatus: FETCH_STATUS.IDLE,
  itemClasses: [],
  getItemGroupsError: "",
  getItemGroupsStatus: FETCH_STATUS.IDLE,
  itemGroups: [],
  getVendorsError: "",
  getVendorsStatus: FETCH_STATUS.IDLE,
  vendorsList: [],
  createItemError: "",
  createItemStatus: FETCH_STATUS.IDLE,
  getItemError: "",
  getItemStatus: FETCH_STATUS.IDLE,
  item: {},
  editItemError: "",
  editItemStatus: FETCH_STATUS.IDLE,
  editedItem: {},
  unitOfMeasurementError: "",
  unitOfMeasurementStatus: FETCH_STATUS.IDLE,
  unitOfMeasurement: [],
  posItems: [],
  getPosItemsStatus: FETCH_STATUS.IDLE,
  getPosItemsError: null,
  kioskItems: [],
  getKioskItemsStatus: FETCH_STATUS.IDLE,
  getKioskItemsError: null,
};

export const itemsSlice = createSlice({
  name: "items",
  initialState,
  reducers: {
    clearItem(state, action) {
      state.item = {};
    },
    appendAddedItem: (state, action) => {
      //console.log(action);

      state.itemsList = addItemDirect(state, action, "itemsList");
    },
    appendEditedItem: (state, action) => {
      editItemDirect(state, action, "itemsList");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getItems.pending, (state) => {
        state.getItemsError = "";
        state.getItemsStatus = FETCH_STATUS.PENDING;
      })
      .addCase(getItems.fulfilled, (state, action) => {
        state.getItemsError = "";
        state.getItemsStatus = FETCH_STATUS.RESOLVED;
        state.itemsList = action.payload;
      })
      .addCase(getItems.rejected, (state, action) => {
        state.getItemsError = action.payload;
        state.getItemsStatus = FETCH_STATUS.REJECTED;
      })
      .addCase(getItemClasses.pending, (state) => {
        state.getItemClassesError = "";
        state.getItemClassesStatus = FETCH_STATUS.PENDING;
      })
      .addCase(getItemClasses.fulfilled, (state, action) => {
        state.getItemClassesError = "";
        state.getItemClassesStatus = FETCH_STATUS.RESOLVED;
        state.itemClasses = action.payload;
      })
      .addCase(getItemClasses.rejected, (state, action) => {
        state.getItemClassesError = action.payload;
        state.getItemClassesStatus = FETCH_STATUS.REJECTED;
      })
      .addCase(getItemGroups.pending, (state) => {
        state.getItemGroupsError = "";
        state.getItemGroupsStatus = FETCH_STATUS.PENDING;
      })
      .addCase(getItemGroups.fulfilled, (state, action) => {
        state.getItemGroupsError = "";
        state.getItemGroupsStatus = FETCH_STATUS.RESOLVED;
        state.itemGroups = action.payload;
      })
      .addCase(getItemGroups.rejected, (state, action) => {
        state.getItemGroupsError = action.payload;
        state.getItemGroupsStatus = FETCH_STATUS.REJECTED;
      })
      .addCase(getUnitOfMeasurements.pending, (state) => {
        state.unitOfMeasurementError = "";
        state.unitOfMeasurementStatus = FETCH_STATUS.PENDING;
      })
      .addCase(getUnitOfMeasurements.fulfilled, (state, action) => {
        state.unitOfMeasurementError = "";
        state.unitOfMeasurementStatus = FETCH_STATUS.RESOLVED;
        state.unitOfMeasurement = action.payload;
      })
      .addCase(getUnitOfMeasurements.rejected, (state, action) => {
        state.unitOfMeasurementError = action.payload;
        state.unitOfMeasurementStatus = FETCH_STATUS.REJECTED;
      })
      .addCase(getVendors.pending, (state) => {
        state.getVendorsError = "";
        state.getVendorsStatus = FETCH_STATUS.PENDING;
      })
      .addCase(getVendors.fulfilled, (state, action) => {
        state.getVendorsError = "";
        state.getVendorsStatus = FETCH_STATUS.RESOLVED;
        state.vendorsList = action.payload;
      })
      .addCase(getVendors.rejected, (state, action) => {
        state.getVendorsError = action.payload;
        state.getVendorsStatus = FETCH_STATUS.REJECTED;
      })
      .addCase(createItem.pending, (state) => {
        state.createItemError = "";
        state.createItemStatus = FETCH_STATUS.PENDING;
      })
      .addCase(createItem.fulfilled, (state, action) => {
        state.createItemError = "";
        state.createItemStatus = FETCH_STATUS.RESOLVED;
        // state.vendorsList = action.payload;
      })
      .addCase(createItem.rejected, (state, action) => {
        state.createItemError = action.payload;
        state.createItemStatus = FETCH_STATUS.REJECTED;
      })
      .addCase(getItem.pending, (state) => {
        state.getItemError = "";
        state.getItemStatus = FETCH_STATUS.PENDING;
      })
      .addCase(getItem.fulfilled, (state, action) => {
        state.getItemError = "";
        state.getItemStatus = FETCH_STATUS.RESOLVED;
        state.item = action.payload;
      })
      .addCase(getItem.rejected, (state, action) => {
        state.getItemError = action.payload;
        state.getItemStatus = FETCH_STATUS.REJECTED;
      })
      .addCase(editItem.pending, (state) => {
        state.editItemError = "";
        state.editItemStatus = FETCH_STATUS.PENDING;
      })
      .addCase(editItem.fulfilled, (state, action) => {
        state.editItemError = "";
        state.editItemStatus = FETCH_STATUS.RESOLVED;
        state.editedItem = action.payload;
      })
      .addCase(editItem.rejected, (state, action) => {
        state.editItemError = action.payload;
        state.editItemStatus = FETCH_STATUS.REJECTED;
      })

      .addCase(getPosItems.pending, (state) => {
        state.getPosItemsError = "";
        state.getPosItemsStatus = FETCH_STATUS.PENDING;
      })
      .addCase(getPosItems.fulfilled, (state, action) => {
        state.getPosItemsError = "";
        state.getPosItemsStatus = FETCH_STATUS.RESOLVED;
        state.posItems = action.payload;
      })
      .addCase(getPosItems.rejected, (state, action) => {
        state.getPosItemsError = action.payload;
        state.getPosItemsStatus = FETCH_STATUS.REJECTED;
      })

      .addCase(getKioskItems.pending, (state) => {
        state.getKioskItemsError = "";
        state.getKioskItemsStatus = FETCH_STATUS.PENDING;
      })
      .addCase(getKioskItems.fulfilled, (state, action) => {
        state.getKioskItemsError = "";
        state.getKioskItemsStatus = FETCH_STATUS.RESOLVED;
        state.kioskItems = action.payload;
      })
      .addCase(getKioskItems.rejected, (state, action) => {
        state.getKioskItemsStatus = FETCH_STATUS.REJECTED;
        state.getKioskItemsError = action.payload;
      });
  },
});

export const { clearItem, appendAddedItem, appendEditedItem } =
  itemsSlice.actions;

export const selectItems = (state) => state.items;

export const itemsReducer = itemsSlice.reducer;
