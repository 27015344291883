import { stockData } from "../../redux/slices";
import { useSelector } from "react-redux";
import { formatNumberAsCurrency } from "../../utils";
const StockTransferLayout = ({ type, data }) => {
  const { stockTransfer } = useSelector(stockData);
  return (
    <div>
      <p className="font-bold">Stock Transfer</p>
      <div className="flex justify-between text-[#8B8D97]">
        <div className=" mr-5">
          <p className="py-2">
            <span className="w-[180px] inline-block ">Stock Transfer Id: </span>
            <span>{stockTransfer?.id}</span>
          </p>
          <p className="py-2">
            <span className="w-[180px] inline-block">From: </span>
            <span>{stockTransfer?.originatingTransferRequest}</span>
          </p>
          <p className="py-2">
            <span className="w-[180px] inline-block">To: </span>
            <span>{stockTransfer?.destinationTransferRequest}</span>
          </p>
        </div>
        <div>
          <div>
            <p className="py-2">
              <span className="w-[180px] inline-block ">Status: </span>
              <span>{stockTransfer?.status}</span>
            </p>
            <p className="py-2">
              <span className="w-[180px] inline-block">Date Sent: </span>
              <span>
                {new Date(stockTransfer?.requestDate).toLocaleString()}
              </span>
            </p>
            <p className="py-2">
              <span className="w-[180px] inline-block">Scheduled date: </span>
              <span>
                {new Date(stockTransfer?.scheduledDate).toLocaleString()}
              </span>
            </p>
          </div>
        </div>
      </div>
      <table className="w-full mt-8 ">
        <thead>
          <tr className="border-y-2 ">
            <th className="py-3 text-left ">Item Name</th>
            <th className="text-left">Unit of Measure</th>{" "}
            <th className="text-center">Qty Desired</th>
            <th className="text-center">Qty Available</th>
          </tr>
        </thead>
        <tbody>
          {stockTransfer?.items?.map((item) => {
            return (
              <tr>
                <td className="py-2">{item?.item}</td>
                <td>{item?.unitOfMeasurement}</td>
                <td className="text-center">{item?.quantiityNeeded}</td>
                <td className="text-center">{item?.quantityAvailable}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export { StockTransferLayout };
