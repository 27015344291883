import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ActiveRequestSpinner, PrivateRoute } from "../../../../components";
import { DateTimeInput } from "../../../../components/Films/DateTimeInput";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import {
  stockData,
  getSimplifiedPO,
  getPurchaseOrder,
  createStockReceipt,
  updateFetchedStockReceipt,
} from "../../../../redux/slices";
import {
  stockReceiptSchema,
  FETCH_STATUS,
  formatNumberAsCurrency,
  storage,
} from "../../../../utils";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Spinner, FormError } from "../../../../components";
import POItemsTable from "../PurchaseOrder/POItemsTable";
import addDays from "date-fns/addDays";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const InlineError = ({ err }) => {
  if (err) {
    return (
      <p className={`italic  text-red-400 font-light text-[10px] `}>{err}</p>
    );
  }

  return null;
};
const StockReceiptForm = () => {
  const navigate = useNavigate();
  const [invoiceDate, setInvoiceDate] = useState(new Date().toISOString());
  const [items, setItems] = useState([]);
  const [itemsReceived, setItemsReceived] = useState(false);
  const [taxInclusive, setTaxInclusive] = useState(false);
  const [fileSelected, setFileSelected] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [_, setVendorReceipt] = useState("");

  const {
    simplifiedPo,
    purchaseOrder,
    simplifiedPoStatus,
    createStockReceiptErr,
    createStockReceiptStatus,
    stockReceipts,
  } = useSelector(stockData);
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(
    addDays(new Date(), -30).toISOString()
  );
  const [endDate, setEndDate] = useState(new Date().toISOString());

  const {
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(stockReceiptSchema),
    defaultValues: {
      invoiceDate: "",
      vendorReceipt: "",
    },
  });

  useEffect(() => {
    setValue("invoiceDate", invoiceDate);
  }, [invoiceDate, setValue]);

  const POFetchFunc = (e) => {
    e.preventDefault();
    
    dispatch(
      getSimplifiedPO({
        DateFrom: startDate,
        DateTo: endDate,
        force: true,
      })
    );
  };

  const imageChange = (e) => {
    setFileSelected(true);
    let file = e.target.files[0];
    const storageRef = ref(storage, `${file.name}`);
    uploadBytes(storageRef, file)
      .then((_) => {
        getDownloadURL(ref(storage, `${file.name}`)).then((url) => {
          if (url) {
            setValue("vendorReceipt", url, { shouldValidate: true });
            setFileUploaded(true);
            setVendorReceipt(url);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTotal = () => {
    let POTotal = 0;
    items.forEach((item) => {
      const subTotal = item.unitPrice * item.quantity;
      const total = subTotal + subTotal * (item.tax / 100);
      POTotal += total;
    });
    return POTotal;
  };

  const formatData = (data) => {
    return {
      ...data,
      purchaseOrderId: purchaseOrder.id,
      referecnce: "Stock",
      itemsReceived: itemsReceived,
      taxInclusive: taxInclusive,
      total: getTotal(),
      items: items.map((item) => {
        const subTotal = item.unitPrice * item.quantity;
        const total = subTotal + subTotal * (item.tax / 100);
        const requestedQuantity = purchaseOrder?.items.find((po) => {
          return po.id === item.id;
        }).quantity;

        return {
          tax: item.tax,
          unitPrice: item.unitPrice,
          subTotal,
          total,
          quantity: requestedQuantity,
          receivedQuantity: item.quantity,
          itemPurchaseRequestId: item.id,
        };
      }),
    };
  };

  const createReceiptFunction = () => {
    handleSubmit((data) => {
      const cleanedData = formatData(data);
      
      dispatch(createStockReceipt(cleanedData))
        .unwrap()
        .then((data) => {
          dispatch(
            updateFetchedStockReceipt([
              {
                ...data,
                requestedBy: `${data?.requestedBy?.firstName} ${data?.requestedBy?.lastName}`,
              },
              ...stockReceipts,
            ])
          );
          navigate("/dashboard/stock/stockReceipt");
        });
    })();
  };

  return (
    <PrivateRoute redirectTo="/dashboard/stock/stockReceipt">
      <>
        <div className="flex items-center justify-end p-4 px-8 rounded-t-lg bg-gradient-to-b from-app-purple-4 to-app-purple-3">
          <div className="flex buttongroup">
            <button
              className="p-3 px-5 rounded-md text-[#C96FCC] bg-white shadow-md mr-4 hover:font-bold"
              onClick={createReceiptFunction}
            >
              Submit
            </button>

            <button
              className="p-3 px-5 rounded-md text-[#C96FCC] bg-white shadow-md hover:font-bold"
              onClick={() => {
                navigate("/dashboard/stock/stockReceipt");
              }}
            >
              Back
            </button>
          </div>
        </div>
        <FormError className="mt-[20px]" err={createStockReceiptErr} />
        <p className="text-center text-[20px] p-3">
          Select Date Range to get Purchase Orders
        </p>
        <form onSubmit={POFetchFunc}>
          <div className="flex justify-center">
            <div className=" pb-3.5 ">
              <label
                htmlFor={`value`}
                className="mb-2 text-md font-bold text-[#666666] "
              >
                From
              </label>
              <DatePicker
                placeholderText="Expiry Date"
                selected={new Date(startDate)}
                onChange={(value) => setStartDate(value.toISOString())}
                customInput={
                  <DateTimeInput
                    className="bg-app-purple-8 w-[8rem] md:w-[10rem]"
                    dateFormat={"EEE, MMM dd, yyyy"}
                    value={startDate}
                  />
                }
              />
            </div>
            <div className=" pb-3.5 ">
              <label
                htmlFor={`value`}
                className="mb-2 text-md font-bold text-[#666666] "
              >
                To
              </label>
              <DatePicker
                placeholderText="Expiry Date"
                selected={new Date(endDate)}
                onChange={(value) => {
                  setEndDate(value.toISOString());
                }}
                customInput={
                  <DateTimeInput
                    className="bg-app-purple-8 w-[8rem] md:w-[10rem]"
                    dateFormat={"EEE, MMM dd, yyyy"}
                    value={endDate}
                  />
                }
              />
            </div>
          </div>
          <div className="flex justify-center">
            <button
              className=" mb-[30px] p-1 px-3 flex items-center justify-between rounded-md text-white bg-[#7E2080] shadow-sm hover:shadow-xl hover:text-white"
              type="submit"
            >
              Get Purchase Order
              <Spinner
                className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                status={simplifiedPoStatus}
              />
            </button>
          </div>
        </form>
        <div className="flex justify-center">
          <select
            className="rounded bg-[#d0a3d3] border-0 mb-3 w-[250px]"
            onChange={(e) => {
              dispatch(getPurchaseOrder({ force: true, id: e.target.value }))
                .unwrap()
                .then((data) => {
                  const newData = data?.items.map((item) => {
                    return item;
                  });
                  setItems([...newData]);
                });
            }}
          >
            <option value={""}>Select Purchase Order</option>
            {simplifiedPo.length > 0 &&
              simplifiedPo?.map((PO, index) => {
                return (
                  <option value={PO?.id} key={index}>
                    {PO?.id}
                  </option>
                );
              })}
          </select>
        </div>
        <form className="flex justify-center mb-5">
          <div className=" max-w-[800px]  grid grid-cols-2 gap-5 mt-[30px] ">
            <div>
              <label htmlFor={`invoiceDate`}>Invoice Date</label>
              <DatePicker
                id="invoiceDate"
                placeholderText="Invoice Date"
                selected={new Date(invoiceDate)}
                onChange={(value) => setInvoiceDate(value.toISOString())}
                customInput={
                  <DateTimeInput
                    className="rounded bg-[#d0a3d3] border-0 mb-3 w-[180px]"
                    dateFormat={"EEE, MMM dd, yyyy"}
                    value={invoiceDate}
                  />
                }
              />
              <InlineError err={errors?.invoiceDate?.message} />
            </div>
            <div>
              <label className="block"> Vendor Receipt</label>
              <input
                type="file"
                className="bg-[#d0a3d3] w-[16rem]"
                name={"vendorReceipt"}
                onChange={imageChange}
              />
              <div className="flex">
                {fileSelected && (
                  <>
                    {fileSelected && !fileUploaded && (
                      <p>Upload In-Progress...</p>
                    )}
                    {getValues("vendorReceipt")?.length > 0 && fileUploaded && (
                      <p>Upload Successful</p>
                    )}

                    {getValues("vendorReceipt")?.length < 1 && fileUploaded && (
                      <p> Oops, Upload Failed</p>
                    )}
                  </>
                )}
              </div>
              <InlineError err={errors?.vendorReceipt?.message} />
            </div>
            <div>
              <label htmlFor={`itemsReceived`} className="block">
                {" "}
                Items Received
              </label>
              <input
                className="accent-[#d0a3d3]  mb-3 text-[#C96FCC] !ring-transparent"
                type="checkbox"
                id="itemsReceived"
                onChange={(e) => {
                  setItemsReceived(e.target.checked);
                }}
              />
              <InlineError err={errors?.vendorId?.message} />
            </div>
            <div>
              <label htmlFor={`taxInclusive`} className="block">
                Tax Inclusive
              </label>
              <input
                className="accent-[#d0a3d3]  mb-3 text-[#C96FCC] !ring-transparent"
                type="checkbox"
                id="taxInclusive"
                onChange={(e) => {
                  setTaxInclusive(e.target.checked);
                }}
              />
              <InlineError err={errors?.invoiceDate?.message} />
            </div>
          </div>
        </form>
        <div className="overflow-x-scroll">
          <POItemsTable
            items={items}
            setItems={setItems}
            useCase={"stockReceipt"}
          />
        </div>
        <p className="text-right font-bold text-[20px] py-3 ">
          {" "}
          <span className="mr-5">{"Total (incd Taxes):"}</span>
          <span className="mr-[5rem]">
            {formatNumberAsCurrency(getTotal())}
          </span>
        </p>
      </>

      <ActiveRequestSpinner
        show={createStockReceiptStatus === FETCH_STATUS.PENDING && true}
      />
    </PrivateRoute>
  );
};

export { StockReceiptForm };
