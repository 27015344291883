import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  API_URL,
  FETCH_STATUS,
  addItemDirect,
  axiosApiInstance,
  axiosApiInstanceGet,
  editItemDirect,
} from "../../utils";
import { startOfWeek, endOfWeek } from "date-fns";

const startDate = startOfWeek(new Date()).toISOString();
const endDate = endOfWeek(new Date()).toISOString();

// export const createRoster = async (data) => {
//   try {
//     const response = await axiosApiInstance.post(
//       `${API_URL}/Rosters/Create`,
//       data
//     );

//     return response?.data?.data;
//   } catch (error) {}
// };

// export const getRosterById = async (id) => {
//   try {

//     const response = await axiosApiInstanceGet(`${API_URL}/Rosters/${id}`);
//     return response?.data?.data;
//   } catch (error) {
//    return error
//   }
// };

// export const editRoster = async (id, data) => {
//   try {

//     const response = await axiosApiInstance.patch(
//       `${API_URL}/Rosters/${id}/Update`,
//       data
//     );
//     return response?.data?.data;
//   } catch (error) {
//     return error
//   }
// };

// export const deleteRoster = async (id) => {
//   try {
//     const response = await axiosApiInstance.delete(`${API_URL}/Rosters/${id}`);
//     return response?.data?.data;
//   } catch (error) {
//     return error
//   }
// };

export const getTasks = createAsyncThunk(
  "getTasks",
  async ({circuitId}, { fulfillWithValue, rejectWithValue }) => {
    let force = true;
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/RosterTasks/ListAll?page=1&perPage=1000&circuitId=${circuitId}`,
        force
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data || error?.message);
    }
  }
);

export const getShifts = createAsyncThunk(
  "getShifts",
  async ({circuitId}, { fulfillWithValue, rejectWithValue }) => {
    let force = true;
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Shifts/ListAll?page=1&perPage=1000&circuitId=${circuitId}`,
        force
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data || error?.message);
    }
  }
);
export const getShiftById = createAsyncThunk(
  "getShiftById",
  async (shiftId, { fulfillWithValue, rejectWithValue }) => {
    let force = true;
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Shifts/${shiftId}`,
        force
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      console.error({ error });
      return rejectWithValue(error?.response?.data || error?.message);
    }
  }
);
export const getTaskById = createAsyncThunk(
  "getTaskById",
  async (taskId, { fulfillWithValue, rejectWithValue }) => {
    let force = true;
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/RosterTasks/${taskId}`,
        force
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data || error?.message);
    }
  }
);

export const getAllRoster = createAsyncThunk(
  "getAllRoster",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Rosters/ListAll?page=1&perPage=1000`,
        true
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data || error?.message);
    }
  }
);

export const getScheduledRoster = createAsyncThunk(
  "getScheduledRoster",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    const body = {
      dateFrom: data?.start || startDate,
      dateTo: data?.end || endDate,
      includeAllStaf: true,
    };
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Rosters/GetScheduledRoster?page=1&perPage=1000`,
        data || body
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data || error?.message);
    }
  }
);

const initialState = {
  tasks: [],
  getTasksStatus: FETCH_STATUS.IDLE,
  getTasksErr: null,
  shifts: [],
  getShiftsStatus: FETCH_STATUS.IDLE,
  getShiftsErr: null,
  singleTask: {},
  getTaskByIdStatus: FETCH_STATUS.IDLE,
  getTaskByIdErr: null,
  singleShift: {},
  getShiftByIdStatus: FETCH_STATUS.IDLE,
  getShiftByIdErr: null,
  scheduledRoster: [],
  getScheduledRosterStatus: FETCH_STATUS.IDLE,
  getScheduledRosterErr: null,
  allRoster: [],
  getAllRosterStatus: FETCH_STATUS.IDLE,
  getAllRosterErr: null,
  activeRosterDate: new Date().toISOString(),
};

export const rosterSlice = createSlice({
  name: "roster",
  initialState,
  reducers: {
    addTask: (state, action) => {
      // [...state.tasks].push(action.payload);
      state.tasks = addItemDirect(state, action, "tasks");
    },
    editTask: (state, action) => {
      editItemDirect(state, action, "tasks");
    },
    addShift: (state, action) => {
      state.shifts = addItemDirect(state, action, "shifts");
    },
    editShift: (state, action) => {
      editItemDirect(state, action, "shifts");
    },
    setActiveRosterDate: (state, action) => {
      state.activeRosterDate = new Date(action.payload).toISOString();
    },
    resetRosterErr: (state) => {
      state.getAllRosterStatus = FETCH_STATUS.IDLE;
      state.getScheduledRosterStatus = FETCH_STATUS.IDLE;
      state.getShiftByIdStatus = FETCH_STATUS.IDLE;
      state.getShiftsStatus = FETCH_STATUS.IDLE;
      state.getTaskByIdStatus = FETCH_STATUS.IDLE;
      state.getTasksStatus = FETCH_STATUS.IDLE;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getTasks.pending, (state) => {
        state.getTasksStatus = FETCH_STATUS.PENDING;
        state.getTasksErr = "";
      })
      .addCase(getTasks.fulfilled, (state, action) => {
        state.getTasksStatus = FETCH_STATUS.RESOLVED;
        state.tasks = action.payload;
        state.getTasksErr = "";
      })
      .addCase(getTasks.rejected, (state, action) => {
        state.getTasksStatus = FETCH_STATUS.REJECTED;
        state.getTasksErr = action.payload || "unable to get tasks";
      })
      .addCase(getShifts.pending, (state) => {
        state.getShiftsStatus = FETCH_STATUS.PENDING;
        state.getShiftsErr = "";
      })
      .addCase(getShifts.fulfilled, (state, action) => {
        state.getShiftsStatus = FETCH_STATUS.RESOLVED;
        state.shifts = action.payload;
        state.getShiftsErr = "";
      })
      .addCase(getShifts.rejected, (state, action) => {
        state.getShiftsStatus = FETCH_STATUS.REJECTED;
        state.getShiftsErr = action.payload || "unable to get shifts";
      })

      .addCase(getShiftById.pending, (state) => {
        state.getShiftByIdStatus = FETCH_STATUS.PENDING;
        state.getShiftByIdErr = "";
      })
      .addCase(getShiftById.fulfilled, (state, action) => {
        state.getShiftByIdStatus = FETCH_STATUS.RESOLVED;
        state.singleShift = action.payload;
        state.getShiftByIdErr = "";
      })
      .addCase(getShiftById.rejected, (state, action) => {
        
        state.getShiftByIdStatus = FETCH_STATUS.REJECTED;
        state.getShiftByIdErr = action.payload || "unable to get shift";
      })

      .addCase(getTaskById.pending, (state) => {
        state.getTaskByIdStatus = FETCH_STATUS.PENDING;
        state.getTaskByIdErr = "";
      })
      .addCase(getTaskById.fulfilled, (state, action) => {
        state.getTaskByIdStatus = FETCH_STATUS.RESOLVED;
        state.singleTask = action.payload;
        state.getTaskByIdErr = "";
      })
      .addCase(getTaskById.rejected, (state, action) => {
        state.getTaskByIdStatus = FETCH_STATUS.REJECTED;
        state.getTaskByIdErr = action.payload || "unable to get task";
      })

      .addCase(getScheduledRoster.pending, (state) => {
        state.getScheduledRosterStatus = FETCH_STATUS.PENDING;
        state.getScheduledRosterErr = "";
      })
      .addCase(getScheduledRoster.fulfilled, (state, action) => {
        state.getScheduledRosterStatus = FETCH_STATUS.RESOLVED;
        state.scheduledRoster = action.payload;
        state.getScheduledRosterErr = "";
      })
      .addCase(getScheduledRoster.rejected, (state, action) => {
        state.getScheduledRosterStatus = FETCH_STATUS.REJECTED;
        state.getScheduledRosterErr = action.payload || "unable to get roster";
      })

      .addCase(getAllRoster.pending, (state) => {
        state.getAllRosterStatus = FETCH_STATUS.PENDING;
        state.getAllRosterErr = "";
      })
      .addCase(getAllRoster.fulfilled, (state, action) => {
        state.getAllRosterStatus = FETCH_STATUS.RESOLVED;
        state.allRoster = action.payload;
        state.getAllRosterErr = "";
      })
      .addCase(getAllRoster.rejected, (state, action) => {
        state.getAllRosterStatus = FETCH_STATUS.REJECTED;
        state.getAllRosterErr = action.payload || "unable to get rosters";
      });
  },
});

export const selectRoster = (state) => state.roster;
export const {
  addTask,
  editTask,
  addShift,
  setActiveRosterDate,
  editShift,
  resetRosterErr,
} = rosterSlice.actions;
