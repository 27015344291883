import React from "react";
import DatePicker from "react-datepicker";
import { Calendar } from "../Icons";
import { Controller } from "react-hook-form";
import { InlineErr, MultiSearchSelect } from "../UI";
import { addHours } from "date-fns";
import { getSelectedItem } from "../../utils";

const CreateRosterRow = ({
  register,
  tasks,
  control,
  errors,
  shifts,
  staffs,
  remove,
  index,
}) => {
  const sessionErr =
    errors?.rosterSessions && errors?.rosterSessions[index]
      ? errors?.rosterSessions[index]
      : null;
  return (
    <>
      <div className=" relative grid grid-cols-2 pt-8 gap-4">
        <div className="relative w-full">
          <label
            htmlFor="scheduledDate"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Date
          </label>

          <Controller
            name={`rosterSessions.${index}.scheduledDate`}
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <>
                  <DatePicker
                    selected={value ? new Date(value) : new Date()}
                    onChange={(value) => onChange(value.toISOString())}
                    dateFormat="yyyy-MM-dd HH:mm"
                    showTimeInput
                    timeInputLabel="Time:"
                    customInput={
                      <input
                        id="scheduledDate"
                        value={value}
                        onChange={onChange}
                        className="placeholder:text-[#ABAFB1] px-[25px] py-[10px] border-none outline-none focus:ring-0 rounded-lg bg-[#AA7DAA26] w-full"
                      />
                    }
                  />

                  <Calendar className="aspect-square absolute top-[50%] !w-4 text-sec-alt-200 ml-2" />
                </>
              );
            }}
          />
          <InlineErr err={sessionErr?.scheduledDate?.message} />
        </div>
        <div className="w-full relative">
          <label
            htmlFor="taskId"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Set Task
          </label>
          <select
            className="w-full px-[16px] py-[10px] border-none outline-none focus:ring-0 rounded-lg bg-[#AA7DAA26]"
            id="taskId"
            placeholder=""
            {...register(`rosterSessions.${index}.rosterTaskId`)}
          >
            <option value="" disabled selected hidden>
              Select Task
            </option>
            {tasks.map((task) => {
              return (
                <option key={task?.id} value={task?.id}>
                  {task?.name}
                </option>
              );
            })}
          </select>
          <InlineErr err={sessionErr?.rosterTaskId?.message} />
        </div>
        <div className="w-full relative">
          <label
            htmlFor="shiftId"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Set Shift
          </label>
          <select
            className="w-full px-[16px] py-[10px] border-none outline-none focus:ring-0 rounded-lg bg-[#AA7DAA26]"
            id="shiftId"
            placeholder=""
            {...register(`rosterSessions.${index}.shiftId`)}
          >
            <option value="" disabled selected hidden>
              Select Shift
            </option>
            {shifts.map((shift) => {
              return (
                <option key={shift?.id} value={shift?.id}>
                  {shift?.name}
                </option>
              );
            })}
          </select>
          <InlineErr err={sessionErr?.shiftId?.message} />
        </div>
        <div className="w-full relative">
          <label
            htmlFor="userId"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Set Staff
          </label>
          {/* <select
            className="w-full px-[16px] py-[10px] border-none outline-none focus:ring-0 rounded-lg bg-[#AA7DAA26]"
            id="userId"
            placeholder=""
            {...register(`rosterSessions.${index}.userId`)}
          >
            <option value="" disabled selected hidden>
              Select Staff
            </option>
            {staffs.map((staff) => {
              return (
                <option
                  key={staff?.id}
                  value={staff?.id}
                >{`${staff?.firstName} ${staff?.lastName}`}</option>
              );
            })}
          </select> */}
          <Controller
            control={control}
            name={`rosterSessions.${index}.userId`}
            render={({ field: { value, onChange } }) => {
              return (
                <MultiSearchSelect
                  items={staffs}
                  inputClassName="w-full px-[16px] py-[10px] border-none outline-none focus:ring-0 rounded-lg bg-[#AA7DAA26]"
                  id={"userId"}
                  value={getSelectedItem(staffs, value, "id")}
                  onChange={(value) => onChange(value.map((v) => v.id))}
                  displayValue={(value) =>
                    value.map((v) => v.firstName).join(", ")
                  }
                  placeholder={`Staff`}
                  searchOptions={{
                    keys: ["name"],
                  }}
                />
              );
            }}
          />
          <InlineErr err={sessionErr?.userId?.message} />
        </div>

        <button
          onClick={() => remove(index)}
          type="button"
          className="bg-rose-500 text-white rounded-lg text-sm p-1.5 lex items-center absolute top-0 right-0 "
        >
          <svg
            className="w-4 h-4"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>

      <hr className="col-span-3 my-2 border-dashed" />
    </>
  );
};

export default CreateRosterRow;
