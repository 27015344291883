import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  reportData,
  getOrdersByUser,
  getOrderById,
  rollbackRedeem,
  getOnlineOrders,
} from "../../redux/slices";
import { FETCH_STATUS, formatDateTimeForApi } from "../../utils";
import { formatNumberAsCurrency } from "../../utils";

import { Spinner } from "..";

const OnlineOrderDetailsModal = ({
  onClose = () => {},
  show,
  orderData,
  funcProps,
}) => {
  const dispatch = useDispatch();
  const { singleOrder, singleOrderStatus } = useSelector(reportData);

  useEffect(() => {
    dispatch(getOrderById({ force: true, orderId: orderData.id }));
  }, [orderData.id]);

  let singleItems = [];
  let tickets = [];

  if (singleOrder?.itemSales?.length > 0) {
    singleItems = singleOrder?.itemSales?.map((item) => {
      return {
        name: item.item,
        amount: item.amount,
        quantity: item.quantity,
        unitPrice: item.price,
      };
    });
  }
  if (singleOrder?.ticketSales?.length > 0) {
    tickets = singleOrder?.ticketSales?.map((ticket) => {
      return {
        name: ticket.film,
        amount: ticket.amount,
        quantity: ticket.quantity,
        unitPrice: ticket.ticketPrice,
        startTime: ticket.startTime,
        endTime:ticket.endTime

      };
    });
  }
  let orderDetails = [...singleItems, ...tickets];

  const rollbackRedeemFunc = () => {
    dispatch(rollbackRedeem({ bookingId: show.id, force: true })).then(() => {
      dispatch(getOnlineOrders(funcProps));
      onClose();
      orderDetails = [];
    });
  };

  return (
    <>
      <Transition show={!!show} appear as={Fragment}>
        <Dialog as="div" className="relative z-[10000]" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl  max-h-[90vh] text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-white bg-[#a86aaa] rounded-t-2xl px-2 py-2 text-center"
                  >
                    Order Details
                  </Dialog.Title>
                  {singleOrderStatus === FETCH_STATUS.PENDING && (
                    <>
                      <div className="flex justify-center my-[30px]">
                        <Spinner
                          className="!w-[20px] !h-[20px] mx-1 m-auto text-white fill-pink-400"
                          status={singleOrderStatus}
                        />
                      </div>
                    </>
                  )}

                  {singleOrderStatus === FETCH_STATUS.RESOLVED && (
                    <div className=" py-2">
                      {/* <form onSubmit={handleSubmit(submitHandler)}> */}
                      <div className="max-h-[60vh] overflow-y-auto border-t-[1px] border-[#7E208080]">
                        <div className="grid grid-cols-6 gap-4 p-2 ">
                          <span className=" text-[#C96FCC] font-bold">
                            Name
                          </span>{" "}
                          <span className=" text-[#C96FCC] font-bold">Qty</span>
                          <span className=" text-[#C96FCC] font-bold">
                            Unit Price
                          </span>
                          <span className=" text-[#C96FCC] font-bold">
                            Sub-total
                          </span>
                          <span className=" text-[#C96FCC] font-bold">
                            Start Time
                          </span>
                          <span className=" text-[#C96FCC] font-bold">
                            End Time
                          </span>
                          <div className="col-span-6 h-[1px] bg-[#7E208080]" />
                          {orderDetails?.map((orderChild, index) => {
                            return (
                              <Fragment
                                // className="flex items-center justify-between px-2 py-1 border-b-[1px] border-[#7E208080]"
                                key={index}
                              >
                                <span className="text-[#C96FCC] ">
                                  {orderChild?.name}
                                </span>
                                <span className="text-[#C96FCC]  ">
                                  {orderChild?.quantity}
                                </span>
                                <span className="text-[#C96FCC]  ">
                                  {formatNumberAsCurrency(orderChild?.unitPrice)}
                                </span>
                                <span className="text-[#C96FCC]  ">
                                  {formatNumberAsCurrency(orderChild?.amount)}
                                </span>
                                <span className="text-[#C96FCC]  ">
                                  {orderChild?.startTime?new Date(
                                    orderChild?.startTime
                                  ).toLocaleString():"N/A"}
                                </span>
                                <span className="text-[#C96FCC]  ">
                                  {orderChild?.endTime?new Date(
                                    orderChild?.endTime
                                  ).toLocaleString():"N/A"}
                                </span>
                                <div className="col-span-6 h-[1px] bg-[#7E208080]" />
                              </Fragment>
                            );
                          })}
                        </div>
                      </div>
                      <div className="flex justify-center gap-3 buttongroup mt-2 ">
                        <button
                          className="p-1 px-3 rounded-md text-[#C96FCC] bg-[#ffe583] shadow-md hover:shadow-sm"
                          type="button"
                          onClick={() => {
                            onClose();
                            orderDetails = [];
                          }}
                        >
                          Cancel
                        </button>
                        {show.status === "Redeemed" && (
                          <button
                            className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm"
                            type="onClick"
                            onClick={rollbackRedeemFunc}
                          >
                            Undo Redeem
                          </button>
                        )}
                      </div>
                      {/* </form> */}
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export { OnlineOrderDetailsModal };
