import { Dialog, Transition } from "@headlessui/react";
import DatePicker from "react-datepicker";
import React from "react";
import { Fragment } from "react";
import { Calendar } from "../../Icons";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FETCH_STATUS,
  getAPIErrMessage,
  rosterInit,
  rosterSchema,
} from "../../../utils";
import { ErrorModal, Spinner, SuccessModal } from "../../UI";
import CreateRosterRow from "../CreateRosterRow";
import { useMemo } from "react";
import { addHours } from "date-fns";

const CreateRosterModal = ({
  close,
  createStatus,
  resetErr,
  createErr,
  onCreate,
  show,
  tasks,
  cinemaId,
  shifts,
  staffs,
}) => {
  const {
    control,
    handleSubmit,
    watch,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(rosterSchema),
    defaultValues: rosterInit,
  });

  const memoizedData = useMemo(
    () => ({
      tasks,
      shifts,
      staffs,
    }),

    [shifts, staffs, tasks]
  );

  const rosterSessions = watch("rosterSessions");

  const { fields, append, remove } = useFieldArray({
    control,
    name: "rosterSessions",
  });

  const handleCreateRoster = async (values) => {
    const updatedRoster = values.rosterSessions.map((sess) => ({
      ...sess,
      scheduledDate: addHours(new Date(sess.scheduledDate), 1).toISOString(),
      cinemaId:cinemaId
    }));
    onCreate(updatedRoster);
  };

  return (
    <>
      <Transition show={show} as={Fragment}>
        <Dialog as="div" className="relative z-[1000000]" onClose={close}>
          {/* Overlay */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          {/* Content */}

          <div className="fixed inset-0 p-4 overflow-y-auto">
            <div className="flex items-center justify-center min-h-[400px] p-2 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-100"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full min-h-[400px] max-w-2xl p-6  text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    className="relative flex justify-between pb-4 border-b"
                    as="div"
                  >
                    <p className="text-lg font-medium leading-6 text-gray-900">
                      Create Roster Session
                    </p>
                    <button
                      type="button"
                      onClick={close}
                      className="absolute right-0 top-2/4 -translate-y-2/4 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    >
                      <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </Dialog.Title>

                  <form
                    onSubmit={handleSubmit(handleCreateRoster)}
                    className="my-6"
                  >
                    <h4 className="mt-5 text-base">Add sessions</h4>
                    <hr className="my-2" />

                    <div className="relative">
                      {!rosterSessions.length ? (
                        <small className="block my-1 font-medium text-center text-rose-500">
                          Please create at least one session
                        </small>
                      ) : null}
                      {fields?.map((field, index) => (
                        <CreateRosterRow
                          key={field.id}
                          errors={errors}
                          register={register}
                          index={index}
                          control={control}
                          remove={remove}
                          {...memoizedData}
                        />
                      ))}
                    </div>
                    <button
                      onClick={() =>
                        append({
                          ...rosterSessions[rosterSessions.length - 1],
                        })
                      }
                      type="button"
                      className="p-2 mt-2 text-sm text-white bg-green-400 rounded-lg"
                    >
                      Add Roster session
                    </button>

                    <div className="flex justify-center mt-10">
                      <button
                        type="submit"
                        disabled={createStatus === FETCH_STATUS.PENDING}
                        className="px-10 py-2 rounded-md text-white bg-app-purple-4 inline-flex items-center justify-center space-x-2"
                      >
                        <span>Save</span>
                        <Spinner
                          className="!w-3 !h-3 text-white fill-app-yellow"
                          status={createStatus}
                        />
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <ErrorModal
        show={createStatus === FETCH_STATUS.REJECTED}
        message={getAPIErrMessage(createErr, "Unable to create roster")}
        onClose={resetErr}
        onClick={resetErr}
      />
    </>
  );
};

export default CreateRosterModal;
