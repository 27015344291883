import React, { forwardRef, useState } from "react";
import { InlineErr } from "./InlineErr";

const Input = forwardRef(
  (
    {
      label,
      errorMsg,
      inputType,
      inputClass = "",
      labelClass = "",
      id,
      wrapperClass = "",
      errClass = "",
      isPwd,
      ...props
    },
    ref
  ) => {
    const [show, setShow] = useState(false);

    const Inp = inputType || "input";

    return (
      <div
        className={`grid items-center gap-10 grid-cols-[12%_minmax(0,1fr)] ${wrapperClass}`}
      >
        <label className={`text-right ${labelClass}`} htmlFor={id}>
          {label}
        </label>

        <div className="relative">
          <Inp
            
            className={`w-full p-2 rounded outline-none bg-app-purple-10 ${inputClass}`}
            id={id}
            name={id}
            ref={ref}
            {...props}
            type={isPwd ? (show ? "text" : "password") : props.type}
          />

          {isPwd ? (
            <button
              
              onClick={() => setShow((s) => !s)}
              className="absolute -translate-y-1/2 top-1/2 right-2"
              type="button"
            >
              {show ? (
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                    />
                  </svg>
                </span>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </svg>
              )}
            </button>
          ) : null}

          <InlineErr className={errClass} err={errorMsg} />
        </div>
      </div>
    );
  }
);

export { Input };
