import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { FETCH_STATUS, API_URL,axiosApiInstance, getAPIErr } from "../../utils";


export const getCinemaSettings = createAsyncThunk(
  "getCinemaSettings",
  async ({cinemaId,circuitId}, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/CinemaSettings/GetCinemaSetting?cinemaId=${cinemaId}&circuitId=${circuitId}`
      );
      

      return fulfillWithValue(response?.data?.data);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(getAPIErr(err||message));
    }
  }
);

const initialState = {
    cinemaSettings: {},
    cinemaSettingsErr: null,
    cinemaSettingsStatus: FETCH_STATUS.IDLE,
}

export const cinemaSettingsSlice = createSlice({
  name: "cinemaSettings",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCinemaSettings.pending, (state) => {
        state.cinemaSettingsStatus = FETCH_STATUS.PENDING;
        state.cinemaSettingsErr = "";
      })
      .addCase(getCinemaSettings.fulfilled, (state, action) => {
        state.cinemaSettingsStatus = FETCH_STATUS.RESOLVED;
        state.cinemaSettings = action.payload;
        state.cinemaSettingsErr = "";
      })
      .addCase(getCinemaSettings.rejected, (state, action) => {
        state.cinemaSettingsStatus = FETCH_STATUS.REJECTED;
        state.cinemaSettingsErr = action.payload || "Unable to get cinema settings";
      });
  },
});

export const selectCinemaSettings = (state) => state.cinemaSettings;