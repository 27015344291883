import { useMemo } from 'react';
import { ClientPaginatedTable, DefaultColumnFilter } from '../UI';

const StockItemTable = ({
  products,
  onNewQuantity,
  editable,
}) => {
  const columns = useMemo(() => {
    return [
      {
        Header: 'Product',
        accessor: 'name',
        Filter: DefaultColumnFilter,
      },
      {
        Header: 'Unit',
        accessor: 'unitOfMeasure',
      },
      {
        Header: 'Expected Quantity',
        accessor: 'quantityOnHand',
      },
      {
        Header: 'Actual Quantity',
        accessor: 'stockTakeQuantity',
        Cell: (props) => {
          return (
            <input
              value={props.value}
              className="bg-[#CD9BCD] py-2 px-3 w-20"
              type="number"
              min={0}
              onChange={(evt) => {
                onNewQuantity(
                  props.row.original.itemStoreId,
                  +evt.target.value
                );
              }}
              readOnly={!editable}
            />
          );
        },
      },
      {
        Header: 'Variance',
        accessor: 'variance',
        Cell: (props) => {
          return (
            <p className={props.value >= 0 ? 'text-green-700' : 'text-red-700'}>
              {`${props.value > 0 ? '+' : ''}${props.value}`}
            </p>
          );
        },
      },
    ];
  }, []);
  return (
    <ClientPaginatedTable
      columns={columns}
      tableData={products}
      filterColumnsKeys={['name']}
      getHeaderProps={(column) => ({
        className:
          'px-3 pt-8 pb-2 text-base font-medium border border-[#00000033] uppercase text-app-purple-4 underline',
        ...(column.id === 'name' ? column?.getSortByToggleProps() : {}),
      })}
      getCellProps={(cellInfo) => ({
        style: {},
        className: `${
          cellInfo?.column?.id === 'name' ? 'text-app-purple-9' : ''
        }`,
      })}
      getColumnProps={() => ({ style: {} })}
    />
  );
};

export default StockItemTable;
