import { stockData } from "../../redux/slices";
import { useSelector } from "react-redux";
import { formatNumberAsCurrency } from "../../utils";
import ReachLogo from "../../assets/images/logo.png";
const StockReceiptLayout = () => {
  const { stockReceipt } = useSelector(stockData);
  return (
    <div>
      <div
        key={ReachLogo}
        className=" ml-[-5px] w-[5rem] h-[3rem] bg-no-repeat bg-contain bg-center hidden print-source print:block"
        style={{
          backgroundImage: `url("${ReachLogo}")`,
        }}
      ></div>
      <p className="font-bold"> Stock Receipt</p>
      <div className="flex justify-between text-[#8B8D97]">
        <div className="flex mr-5">
          <div className="w-[180px]">
            <p className="py-2">Cinema:</p>
            <p className="py-2">Stock Receipt Id:</p>
            <p className="py-2">Purchase Order Id:</p>
            <p className="py-2">Vendor:</p>
            <p className="py-2">Status:</p>
          </div>
          <div>
            <p className="py-2">{stockReceipt?.cinema}</p>
            <p className="py-2">{stockReceipt?.id}</p>
            <p className="py-2">{stockReceipt?.purchaseOrderId}</p>
            <p className="py-2">
              {stockReceipt?.vendor ? stockReceipt?.vendor : "No data"}
            </p>
            <p className="py-2">
              {stockReceipt?.status ? stockReceipt?.status : "No data"}
            </p>
          </div>
        </div>
        <div>
          <div className="flex ">
            <div className="w-[180px]">
              <p className="py-2">Invoice Date:</p>
              <p className="py-2">Tax Inclusive:</p>
              <p className="py-2">Items Received:</p>
              <p className="py-2">Received By:</p>
              <p className="py-2">Approved By:</p>
            </div>
            <div>
              <p className="py-2">
                {new Date(stockReceipt?.invoiceDate).toLocaleString()}
              </p>
              <p className="py-2">
                {stockReceipt?.taxInclusive ? "Yes" : "No"}
              </p>
              <p className="py-2">
                {stockReceipt?.itemsReceived ? "Yes" : "No"}
              </p>
              <p className="py-2">
                {stockReceipt?.receivedBy
                  ? `${stockReceipt?.receivedBy.firstName} ${stockReceipt?.receivedBy.lastName}`
                  : "No Data"}
              </p>
              <p className="py-2">
                {stockReceipt?.approvedBy
                  ? `${stockReceipt?.approvedBy.firstName} ${stockReceipt?.approvedBy.lastName}`
                  : "No Data"}
              </p>
            </div>
          </div>
        </div>
      </div>
      <table className="w-full mt-8 ">
        <thead>
          <tr className="border-y-2 ">
            <th className="py-3 text-left ">Item Name</th>
            <th className="text-left">Description</th>{" "}
            <th className="text-left">Unit of Measure</th>{" "}
            <th className="text-left">QUoM</th>{" "}
            <th className="text-center">Qty Desired</th>
            <th className="text-center">Qty Received</th>
            <th className="text-center">Unit Price</th>
            <th className="text-center">Tax</th>
            <th className="text-center">Sub total</th>
            <th className="text-center">Total</th>
          </tr>
        </thead>
        <tbody>
          {stockReceipt?.items?.map((item) => {
            return (
              <tr>
                <td className="py-2">{item?.item}</td>
                <td>{item?.description}</td>
                <td>{item?.unitOfMeasurement}</td>
                <td className="text-center">{item?.quantityUOM}</td>
                <td className="text-center">{item?.quantity}</td>
                <td className="text-center">{item?.receivedQuantity}</td>
                <td className="text-center">{item?.unitPrice}</td>
                <td className="text-center">{item?.tax}</td>
                <td className="text-center">
                  {formatNumberAsCurrency(item?.subTotal)}
                </td>
                <td className="text-center">
                  {formatNumberAsCurrency(item?.total)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <p className="font-bold text-[20px] text-right">
        Total: {formatNumberAsCurrency(stockReceipt?.total)}
      </p>
    </div>
  );
};
export { StockReceiptLayout };
