import React from "react";
import { useDispatch } from "react-redux";
import {
  PrivateRoute,
  ScreenForm,
  PageHeader,
  SuccessModal,
} from "../../../components";
import { useRequest } from "../../../hooks";
import { addScreen } from "../../../redux/slices";
import { API_URL, axiosApiInstance, FETCH_STATUS } from "../../../utils";

const CreateScreen = () => {
  const { err, status, resetErr, setResolved, setPending, setRejected } =
    useRequest();

  const dispatch = useDispatch();

  const onSave = async (data) => {
    try {
      setPending();



      const res = await axiosApiInstance.post(
        `${API_URL}/Screens/Create`,
        data
      );

      setResolved();

      dispatch(addScreen(res.data.data));
    } catch (error) {
      setRejected(error || "Unable to create screen");
    }
  };

  return (
    <PrivateRoute redirectTo="/dashboard/screens/create">
      <PageHeader>
        <h1 className="text-2xl font-semibold text-white ">Create Screen</h1>
      </PageHeader>
      <ScreenForm
        onReset={resetErr}
        status={status}
        error={err}
        onSave={onSave}
      />
      <SuccessModal
        message="Created a new screen successfully."
        show={status === FETCH_STATUS.RESOLVED}
        backLink={"/dashboard/screens"}
      />
    </PrivateRoute>
  );
};

export { CreateScreen };
