import { startOfWeek } from "date-fns";
import { differenceInMilliseconds, endOfWeek } from "date-fns";
import { orderBy } from "lodash";
import React from "react";
import { useMemo } from "react";
import { useLayoutEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import RosterWeekRowRender from "./RosterWeekRowRender";

const RosterWeekRow = ({
  renderEvent,
  rowEvents = [],
  resource,
  resourceIdentifier,
  renderResource,
  useContextTrigger = () => {},
}) => {
  const [rowNode, setRowNode] = useState(null);
  const [width, setWidth] = useState(0);
  const [heights, setRowHeights] = useState([0]);

  const rowRef = useCallback((node) => {
    if (node !== null) {
      setRowNode(node);
    }
  }, []);

  const events = useMemo(() => rowEvents, [rowEvents]);
  const days = useMemo(
    () => orderBy(Object.keys(events), (value) => +value),
    [events]
  );

  const getTranslate = useCallback(
    (dayStartMilliseconds) => {
      if (rowNode) {
        const rowWidth = width;

        const dayDiff = differenceInMilliseconds(
          endOfWeek(new Date(dayStartMilliseconds)),
          startOfWeek(new Date(dayStartMilliseconds))
        );

        const itemDiff = differenceInMilliseconds(
          new Date(dayStartMilliseconds),
          startOfWeek(new Date(dayStartMilliseconds))
        );

        const dayRatio = itemDiff / dayDiff;

        const scaledOffset = dayRatio * rowWidth;

        return scaledOffset;
      }

      return 0;
    },
    [rowNode, width]
  );

  useLayoutEffect(() => {
    if (rowNode) {
      const measure = () => {
        setWidth(rowNode.getBoundingClientRect().width);
      };

      measure();
      window.addEventListener("resize", measure);

      return () => {
        window.removeEventListener("resize", measure);
      };
    }
  }, [rowNode]);
  return (
    <>
      <div
        style={{
          height: `${Math.max(...heights)}px`, //height of row depends on the height/content of the day with the most movies
        }}
        className="min-h-[114px] grid grid-cols-[8%_minmax(0,1fr)] odd:bg-app-purple-7 even:bg-app-purple-5"
      >
        <div className="flex items-center justify-center">
          {renderResource(resource[resourceIdentifier])}
        </div>
        <div ref={rowRef} className="relative mode-week-grid">
          {days.map((d, i) => (
            <RosterWeekRowRender
              horizontalOffset={getTranslate(+d)}
              renderEvent={renderEvent}
              dayEvents={events[d]}
              useContextTrigger={useContextTrigger}
              key={i}
              index={i}
              setRowHeights={setRowHeights}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default RosterWeekRow;
