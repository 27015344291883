import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { Input } from "../UI";

const RegenerateLayoutForm = ({ show, onClose, generateLayout, register }) => {
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-[10000000]" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-75"
          leave="ease-in duration-75"
          leaveFrom="opacity-75"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex justify-center items-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full pb-6 max-w-md max-h-max overflow-hidden text-left transition-all transform bg-app-purple-2 shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-center text-white bg-gradient-to-b from-app-purple-4 to-app-purple-3 p-3.5 "
                >
                  PREVIEW NEW LAYOUT
                </Dialog.Title>
                <section className="">
                  <div className="flex items-center justify-end p-4 bg-app-purple-5">
                    <div className="inline-flex items-center space-x-7">
                      <button
                        className="p-2 text-center rounded min-w-[92px] bg-app-yellow text-app-purple-6"
                        onClick={onClose}
                      >
                        Cancel
                      </button>

                      <button
                        type="button"
                        onClick={generateLayout}
                        className="p-2 rounded min-w-[92px] text-app-yellow bg-app-purple-4 disabled:opacity-30 inline-flex items-center justify-center space-x-2"
                      >
                        <span>Preview</span>
                      </button>
                    </div>
                  </div>
                  <div className="flex justify-center mt-8 min-h-[200px] overflow-auto">
                    <div className="space-y-4 ">
                      <Input
                        label="Number of rows:"
                        placeholder="number of rows"
                        labelClass="not-sr-only"
                      
                        id="numOfRows"
                        wrapperClass="!flex !flex-col !gap-4"
                        // errorMsg={errors?.houseSeats?.message}
                        type="number"
                        min={0}
                        {...register("numberOfRows")}
                      />
                      <Input
                        label="Number of columns:"
                        placeholder="number of columns"
                        labelClass="not-sr-only"
                        id="numberOfColumns"
                        wrapperClass="!flex !flex-col !gap-4"
                        // errorMsg={errors?.houseSeats?.message}
                        type="number"
                        min={0}
                        {...register("numberOfColumns")}
                      />
                    </div>
                  </div>
                </section>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default RegenerateLayoutForm;
