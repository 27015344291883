import {useEffect, useRef, useState} from "react";
import {
    BoxOfficePerformanceTable,
    ExportExcelBtn,
    InputErr,
    PdfButton,
    Pdiv,
    Pdiv2,
    PrivateRoute,
    Spinner,
} from "../../../components";
import {useNavigate} from "react-router-dom";
import DatePicker from "react-datepicker";
import addDays from "date-fns/addDays";
import {setHours, setMinutes} from "date-fns";
import {DateTimeInput} from "../../../components/Films/DateTimeInput";
import {useLoggedInuser} from "../../../hooks";
import {getGBOAdmitsPerformance, getGBOPerformance, reportData,} from "../../../redux/slices";
import {formatDateTimeForApi, gboPerformanceSchema} from "../../../utils";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Element, scroller} from "react-scroll";
import {useDispatch, useSelector} from "react-redux";

const BoxOfficePerformance = () => {
  const navigate = useNavigate();
  const [startDate, setStart] = useState(addDays(new Date(), -2).toISOString());
  const [endDate, setEnd] = useState(new Date().toISOString());
  const { gboPerformance, gboPerformanceStatus } = useSelector(reportData);
  const { profile } = useLoggedInuser();
  const dispatch = useDispatch();
  const reportRef = useRef(null);

  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(gboPerformanceSchema),
    defaultValues: {
      DateFrom: startDate,
      DateTo: endDate,
      CinemaId: profile.cinemaId,
    },
  });

  useEffect(() => {
    setValue("DateFrom", startDate);
    setValue("DateTo", endDate);
  }, [startDate, endDate, setValue]);

  const onSubmit = (data) => {
    dispatch(
      getGBOPerformance({
        ...data,
        force: true,
        DateFrom: formatDateTimeForApi(new Date(data.DateFrom)),
        DateTo: formatDateTimeForApi(new Date(data.DateTo)),
      })
    );
    dispatch(
      getGBOAdmitsPerformance({
        ...data,
        force: true,
        DateFrom: formatDateTimeForApi(new Date(data.DateFrom)),
        DateTo: formatDateTimeForApi(new Date(data.DateTo)),
      })
    );
    scroller.scrollTo("reportContainer", {
      duration: 1500,
      delay: 0,
      smooth: true,
      offset: -100, // Scrolls to element + 50 pixels down the page
    });
  };

  // an array of id's for the number of tables to converted to excel
  const xlsId = ["boxOff-xlsx1", "boxOff-xlsx2"];
  return (
    <PrivateRoute redirectTo={"/dashboard/reports/box-office-performance"}>
      <div className="w-[95%] mx-auto mt-6">
        <p className="font-semi-bold px-3 pb-5">
          GBO Report by User - Set Report Parameters
        </p>
        <div className="flex items-center justify-between p-4 px-8 h-[50px] rounded-t-lg bg-[#C96FCC]">
          <div className="flex gap-4">
            <span className="text-xl font-semibold text-white"></span>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex items-center justify-end py-3 px-4 bg-white ">
            <div className="flex gap-3 buttongroup ">
              <button
                className="p-1 px-3 rounded-md text-[#C96FCC] bg-[#ffe583] shadow-md hover:shadow-sm"
                type="button"
                onClick={() => {
                  navigate("/dashboard/reports");
                }}
              >
                Cancel
              </button>
              <button
                className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm flex items-center"
                type="submit"
              >
                <span> Run Report </span>
                <Spinner
                  className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                  status={gboPerformanceStatus}
                />
              </button>
            </div>
          </div>
          <div className="bg-app-purple-2 p-3 pt-[30px]">
            <Pdiv label={"Date Range"}>
              <div className="flex items-center">
                <DatePicker
                  placeholderText="Start Date"
                  selected={new Date(startDate)}
                  onChange={(value) => setStart(value.toISOString())}
                  dateFormat="Pp"
                  showTimeSelect
                  customInput={
                    <DateTimeInput
                      className="bg-app-purple-8  w-[12rem]"
                      // dateFormat={"EEE, MMM dd, yyyy"}
                    />
                  }
                />
                <p className="w-[6rem] text-center"> To:</p>
                <DatePicker
                  placeholderText="End Date"
                  selected={new Date(endDate)}
                  onChange={(value) => setEnd(value.toISOString())}
                  dateFormat="Pp"
                  showTimeSelect
                  injectTimes={[setHours(setMinutes(new Date(), 59), 23)]}
                  customInput={
                    <DateTimeInput
                      className="bg-app-purple-8"
                      // dateFormat={"EEE, MMM dd, yyyy"}
                    />
                  }
                />
              </div>
            </Pdiv>
            <InputErr err={errors?.DateTo?.message} />
            <InputErr err={errors?.DateFrom?.message} />
            <Pdiv2 label={"Site"} name={"CinemaId"} {...register("CinemaId")}>
              <option value={""}>Select Cinema</option>
              <option value={profile.cinemaId}>{profile.cinema}</option>
            </Pdiv2>
            <InputErr err={errors?.CinemaId?.message} />
          </div>
        </form>
        <Element name="reportContainer">
          {gboPerformance?.paramFilter && (
            <>
              <div className="flex justify-center mt-5 space-x-4">
                <PdfButton
                  refValue={reportRef}
                  docTitle={"box-office-report"}
                />
                <ExportExcelBtn id={xlsId} name={"boxOffice.xlsx"} />
              </div>
              <div  className="hidden">
                <BoxOfficePerformanceTable
                  id1={"boxOff-xlsx1"}
                  id2={"boxOff-xlsx2"}
                />
              </div>
              <div ref={reportRef}>
                <BoxOfficePerformanceTable
               
                />
              </div>
            </>
          )}
        </Element>
      </div>
    </PrivateRoute>
  );
};
export { BoxOfficePerformance };
