import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Alert, Input, Spinner, StatusToggler } from "../UI";
import { useDispatch, useSelector } from "react-redux";
import {
  createSeatClass,
  getSeatClass,
  selectScreens,
  updateSeatClass,
} from "../../redux/slices";
import { FETCH_STATUS, getAPIErrMessage, trueFalse } from "../../utils";
import { useRef } from "react";

const wrapperClass = "grid-cols-[40%_minmax(0,1fr)] gap-4 items-center";

const SeatClassModalForm = ({
  show,
  onClose = () => {},
  isEdit,
  seatClassId,
  isEditData,
  singleSeatClassStatus,
}) => {
  const {
    createdSeatClassStatus,
    createdSeatClassErr,
    updatedSeatClassStatus,
    updatedSeatClassErr,
  } = useSelector(selectScreens);

  const dispatch = useDispatch();
  const force = true;

  const updateErrRef = useRef(null);
  const updateSuccessRef = useRef(null);
  const createErrRef = useRef(null);
  const createSuccessRef = useRef(null);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      isActive: "true",
    },
  });

  useEffect(() => {
    if (
      isEdit &&
      singleSeatClassStatus === FETCH_STATUS.RESOLVED &&
      isEditData
    ) {
      reset({
        isActive: isEditData?.isActive.toString(),
        name: isEditData?.name,
      });
    }
  }, [
    isEdit,
    isEditData?.isActive,
    isEditData?.name,
    reset,
    isEditData,
    singleSeatClassStatus,
  ]);

  const handleSubmitSeatClass = async (values) => {
    const createErrModal = createErrRef.current;
    const createSuccessModal = createSuccessRef.current;
    const updateErrModal = updateErrRef.current;
    const updateSuccessModal = updateSuccessRef.current;
    if (!isEdit) {
      try {
        createErrModal?.close();
        await dispatch(
          createSeatClass({
            ...values,

            isActive: trueFalse[values.isActive],
          })
        )
          .unwrap()
          .then(() => {
            dispatch(getSeatClass(force));
            createSuccessModal?.open();
            onClose()
          });
      } catch (error) {
        createSuccessModal?.close();
        createErrModal?.open();
      }
    }
    if (isEdit) {
      updateErrModal?.close();
      try {
        await dispatch(
          updateSeatClass({
            ...values,
            seatClassId: seatClassId,
            isActive: trueFalse[values.isActive],
          })
        )
          .unwrap()
          .then(() => {
            dispatch(getSeatClass(force));
            updateSuccessModal?.open();
            onClose()
          });
        
      } catch (error) {
        updateSuccessModal?.close();
        updateErrModal?.open();
      }
    }
  };
  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog as="div" className="relative z-[10000000]" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-75"
            leave="ease-in duration-75"
            leaveFrom="opacity-75"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full pb-6 max-w-2xl max-h-max overflow-hidden text-left transition-all transform bg-app-purple-2 shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-center text-white bg-gradient-to-b from-app-purple-4 to-app-purple-3 p-3.5 "
                  >
                    SEAT CLASS FORM
                  </Dialog.Title>
                  <>
                    <form onSubmit={handleSubmit(handleSubmitSeatClass)}>
                      <div className="flex items-center justify-end p-2 bg-app-purple-5">
                        <div className="inline-flex items-center space-x-7">
                          <button
                            type="submit"
                            className="p-2 rounded min-w-[92px] text-app-yellow bg-app-purple-4 disabled:opacity-30 inline-flex items-center justify-center space-x-2"
                            // onClick={onClose}
                          >
                            {isEdit ? "Update" : "Create"}
                            <Spinner
                              className="!w-[15px] !h-[15px] mx-1 m-auto text-white fill-pink-400"
                              status={
                                isEdit
                                  ? updatedSeatClassStatus
                                  : createdSeatClassStatus
                              }
                            />
                          </button>
                          <button
                            className="p-2 text-center rounded min-w-[92px] bg-app-yellow text-app-purple-6"
                            onClick={onClose}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>

                      {singleSeatClassStatus === FETCH_STATUS.PENDING && (
                        <div className="flex justify-center mt-2">
                          <Spinner
                            className="!w-[25px] !h-[25px] mx-1 m-auto text-white fill-pink-400"
                            status={singleSeatClassStatus}
                          />
                        </div>
                      )}

                      <div className="flex flex-col items-center space-y-5 mt-5">
                        <Alert ref={createErrRef} className="my-2">
                          <span className="flex justify-center">
                            {getAPIErrMessage(
                              createdSeatClassErr,
                              "Unable to create seat class"
                            )}
                          </span>
                        </Alert>

                        <Alert
                          ref={createSuccessRef}
                          className="my-2"
                          type="success"
                          dismissable={true}
                        >
                          <span>Seat class created successfully</span>
                        </Alert>

                        <Alert
                          ref={updateSuccessRef}
                          className="my-2"
                          type="success"
                          dismissable={true}
                        >
                          <span>Seat class updated successfully</span>
                        </Alert>

                        <Alert ref={updateErrRef} className="my-2">
                          <span className="flex justify-center">
                            {getAPIErrMessage(
                              updatedSeatClassErr,
                              "Unable to update seat class"
                            )}
                          </span>
                        </Alert>

                        <Input
                          label="Seat Class Name:"
                          id="name"
                          wrapperClass={wrapperClass}
                          errorMsg={
                            errors?.name
                              ? "Please enter a seat class name"
                              : null
                          }
                          type="text"
                          {...register("name", { required: true })}
                        />

                        <div className={`grid ${wrapperClass}`}>
                          <p className="">Set isActive:</p>

                          <div className="">
                            <StatusToggler
                              options={[
                                { key: "Active", value: "true" },
                                { key: "Inactive", value: "false" },
                              ]}
                              register={register}
                              statusKey="isActive"
                              errMsg={
                                errors?.isActive
                                  ? "select an active status"
                                  : null
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export { SeatClassModalForm };
