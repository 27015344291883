import React, { useCallback, useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useRequest, useToggle } from "../../../hooks";
import {
  ErrorModal,
  PrivateRoute,
  ShiftForm,
  SuccessModal,
} from "../../../components";
import BreadCrumb from "../../../components/BreadCrumbs/BreadCrumbs";
import {
  API_URL,
  FETCH_STATUS,
  axiosApiInstance,
  getAPIErrMessage,
  onReject,
} from "../../../utils";

import { editShift, getShiftById, resetRosterErr, selectRoster } from "../../../redux/slices";

import { Spinner2 } from "../../../components/UI/ItemsComponents/Spinner2";

const EditShift = () => {
  let params = useParams();
  const navigate = useNavigate();
  const { singleShift,getShiftByIdStatus,getShiftByIdErr } = useSelector(selectRoster);
  const dispatch = useDispatch();

  const {
    err,
    status,
    data,
    reset,
    resetErr,
    setResolved,
    setPending,
    setRejected,
  } = useRequest();
  
 
  
  const getInfo = useCallback(() => {
    if (params?.id) {
      dispatch(getShiftById(params?.id)).unwrap();
    }
  }, [dispatch, params?.id]);

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  const onSave = async (values) => {
    const body = {
      ...values,
      isActive: !!values.status,
    };
    try {
      setPending();
      const res = await axiosApiInstance.patch(
        `${API_URL}/Shifts/${params?.id}/Update`,
        body
      );
      setResolved();
      // dispatch(editShift(res.data.data));
    } catch (error) {
      onReject(setRejected, error, `unable to edit shift`);
    }
  };
  return (
    <>
      <PrivateRoute redirectTo={`/dashboard/users/shifts/${params?.id}`}>
        <div className="flex items-center flex-col md:flex-row gap-5 justify-between mb-[25px]">
          <h3 className="text-xl font-[550]">Shift</h3>
          <BreadCrumb />
        </div>
        <div className="bg-[#E6C4E7] rounded-[20px] border overflow-hidden border-[#AA7DAA]">
          <div className="px-5 py-5 flex justify-between items-center gap-4 bg-[#7E208080]">
            <h4 className="text-white text-lg font-sans">Edit Shift</h4>
            <button
              type="button"
              onClick={() => navigate("/dashboard/users/shifts")}
            >
              <Icon
                icon="ion:close-circle-sharp"
                fontSize={26}
                color="#E5E5E5"
              />
            </button>
          </div>
          <div className="p-[19px] pb-[30px] bg-[#DDC4DD] min-h-[699px]">
            <div className="px-6 md:px-0 mx-auto md:w-[590px] lg:w-[700px] xl:w-[900px]">
              {getShiftByIdStatus === FETCH_STATUS.PENDING && (
                <div className="flex items-center justify-center p-3">
                  {" "}
                  <Spinner2 className="w-20 h-20" />
                </div>
              )}
              {getShiftByIdStatus === FETCH_STATUS.RESOLVED && (
                <ShiftForm
                  onReset={resetErr}
                  status={status}
                  isEdit
                  info={singleShift}
                  error={err}
                  onSave={onSave}
                />
              )}
            </div>
          </div>
        </div>
        <ErrorModal
          show={getShiftByIdStatus === FETCH_STATUS.REJECTED}
          message={getAPIErrMessage(getShiftByIdErr, "Unable to fetch shift")}
          onClose={() => dispatch(resetRosterErr())}
          onClick={() => dispatch(resetRosterErr())}
        />
        <SuccessModal
          message="Shift edited successfully"
          show={status === FETCH_STATUS.RESOLVED}
          backLink={`/dashboard/users/shifts`}
        />
      </PrivateRoute>
    </>
  );
};

export { EditShift };
