import React, { useEffect, useCallback, useState, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";

import {
  cleanNumberString,
  FETCH_STATUS,
  formatNumberAsCurrency,
  ticketInit,
  ticketSchema,
  trueFalse,
  ITEM_GROUP,
  getSelectedItem,
  getSelectedItem2,
  loggedInCinemaName,
} from "../../utils";

import {
  selectTickets,
  getChannels,
  getClasses,
  getAllSources,
  reportData,
  getItems,
  selectScreens,
  getSeatClass,
} from "../../redux/slices";

import {
  Error,
  FormError,
  InlineErr,
  CancelSaveToolbar,
  MultiSearchSelect,
  SearchSelect,
  StatusToggler,
} from "../UI";
import { useTable } from "react-table";
import { ItemsModal } from "../UI";
import TicketsModal from "./TicketsModal";
import { ItemFormErrorModal } from "../UI";
import { isArray } from "lodash";
const TicketForm = ({ onSave, isEdit, info, error, status, onDismiss }) => {
  const {
    classes,
    classesStatus,
    classesErr,
    channels,
    channelsStatus,
    channelsErr,
  } = useSelector(selectTickets);
  const { seatClasses, seatClassStatus } = useSelector(selectScreens);
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [showItemsModal, setShowItemsModal] = useState(false);
  const [showTicketsModal, setShowTicketsModal] = useState(false);
  const [formError, setFormError] = useState(false);
  const { sources, sourcesStatus } = useSelector(reportData);
  const { itemsList, getItemsStatus } = useSelector((state) => state.items);
   const cinemaName = localStorage.getItem(loggedInCinemaName);
   const isCanal = cinemaName?.toLowerCase().includes("canal");
  const getInfo = useCallback(
    (force) => {
      if (channelsStatus === FETCH_STATUS.REJECTED || force) {
        dispatch(getChannels(true));
      }
      if (classesStatus === FETCH_STATUS.REJECTED || force) {
        dispatch(getClasses(true));
      }
      if (sourcesStatus === FETCH_STATUS.REJECTED || force) {
        dispatch(getAllSources(true));
      }
    },
    [dispatch, channelsStatus, classesStatus, sourcesStatus]
  );

  const {
    register,
    control,
    reset,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(ticketSchema),
    defaultValues: ticketInit,
  });

  // const watchedChannels = watch("channels");
  const isComp = watch("isComplementary");
  const ticketGroup = watch("class");
  const seatClas = watch("seatClassId")

  const onSubmit = async (values) => {
    const body = {
      ...values,
      isActive: trueFalse[values.isActive],
      isLoyalty: trueFalse[values.isLoyalty],
      openTicket: trueFalse[values.openTicket],
      price: values.isComp ? 0 : values.price,
      items: selectedItems
        ?.filter((singleItem) => {
          return (
            singleItem.itemGroupId === ITEM_GROUP.SINGLE ||
            singleItem.itemGroupId === ITEM_GROUP.RECIPE
          );
        })
        .map((item) => {
          return {
            quantity: item.quantity,
            price: item.price,
            itemId: item.id,
          };
        }),
      itemParents: selectedItems
        ?.filter((singleItem) => {
          return singleItem.itemGroupId === ITEM_GROUP.PARENT;
        })
        .map((item) => {
          return {
            quantity: item.quantity,
            price: item.price,
            itemParentId: item.id,
          };
        }),
      tickets: selectedTickets?.map((x) => {
        return { quantity: x.unit, ticketId: x.id, price: x.price };
      }),
      seatClassId: values?.seatClassId === "" ? null : values?.seatClassId,
    };

    //package total calculation for package ticket
    let packageTotal = 0;
    const itemsArray = [...body?.items, ...body?.itemParents];

    if (
      itemsArray &&
      body?.tickets &&
      isArray(itemsArray) &&
      isArray(body?.tickets)
    ) {
      itemsArray?.forEach((item) => {
        packageTotal += +[item.price * +item.quantity];
      });
      body?.tickets?.forEach((ticket) => {
        packageTotal += +[ticket.price * +ticket.quantity];
      });
    }

    if (ticketGroup !== "tgroup-9e210a") {
      body.tickets = null;
      body.itemParents = null;
      body.items = null;
    }

    //additional checks for package ticket before submitting
    if (ticketGroup === "tgroup-9e210a") {
      //check to ensure one item is selected
      if (body?.items?.length < 1 && body?.itemParents?.length < 1) {
        setFormError("Select at least one Item");
        return;
      }
      //check to ensure one ticket is selected
      if (body?.tickets?.length < 1) {
        setFormError("Select at least one Ticket");
        return;
      }

      if (+body?.price !== packageTotal) {
        setFormError(
          `The price for the package should be ${formatNumberAsCurrency(
            packageTotal
          )} not ${formatNumberAsCurrency(
            body.price
          )}. Package selling price should be equal to the sum of all its children items `
        );

        return;
      }
    }

    onSave(body);
  };

  useEffect(() => {
    if (sourcesStatus === FETCH_STATUS.IDLE) {
      dispatch(getAllSources(true));
    }
  }, [dispatch, sourcesStatus]);

  useEffect(() => {
    if (getItemsStatus === FETCH_STATUS.IDLE) {
      dispatch(getItems());
    }
    if (seatClassStatus === FETCH_STATUS.IDLE) {
      dispatch(getSeatClass());
    }
  }, [dispatch, getItemsStatus, seatClassStatus]);

  useEffect(() => {
    if (channelsStatus === FETCH_STATUS.IDLE) {
      dispatch(getChannels());
    }

    if (classesStatus === FETCH_STATUS.IDLE) {
      dispatch(getClasses());
    }
  }, [dispatch, channelsStatus, classesStatus]);

  useEffect(() => {
    if (isEdit && classes.length) {
      reset({
        ...ticketInit,
        ...info,
        isActive: info?.isActive?.toString(),
        isLoyalty: info?.isLoyalty?.toString(),
        openTicket: info?.openTicket?.toString(),
        // active: info?.status?.toString(),
        // class:
        //   classes.find((c) => c.name === info?.class)?.id?.toString() || "",
        class: info.ticketGroupId,
        seatClassId:info?.seatClassId?info?.seatClassId:"",
        sourceIds: info.ticketSources.map((item) => item.sourceId),
      });
      //formatting he data to prepopulate with the tickets and items for a package

      let formatedTickets = [];
      let formatedItems = [];
      let formatedItemParents = [];
      if (info?.ticketPackage?.tickets) {
        formatedTickets = info?.ticketPackage?.tickets?.map((data) => {
          return {
            id: data.ticketId,
            unit: data.quantity,
            name: data.ticketName,
            price: data.price,
          };
        });
        setSelectedTickets(formatedTickets);
      }

      //formatting the data to prepopulate with the items for a package
      if (info?.ticketPackage?.items) {
        formatedItems = info?.ticketPackage?.items?.map((data) => {
          return {
            id: data.itemId,
            quantity: data.quantity,
            name: data.name,
            price: data.price,
            itemGroupId: data.itemGroupId,
          };
        });
      }

      if (info?.ticketPackage?.itemParents) {
        formatedItemParents = info?.ticketPackage?.itemParents?.map((data) => {
          return {
            id: data.id,
            quantity: data.quantity,
            name: data.name,
            price: data.price,
            itemGroupId: data.itemGroupId,
          };
        });
      }
      setSelectedItems([...formatedItems, ...formatedItemParents]);
    }
  }, [info, reset, classes, isEdit]);

  //Table related stuff to add Tickets and Items

  //Table stuff for items
  const columns = useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Price",
        accessor: "price",
        //the check below helps to prevent the price of a parent item from being editable because even if its edited all its children would still have the same price and total would not balance when a user buy's a package
        Cell: (props) => {
          if (props.row.original.itemGroupId !== ITEM_GROUP.PARENT) {
            return (
              <input
                defaultValue={props.value}
                className="px-2 py-1 w-[100px]"
                onChange={(e) => {
                  selectedItems[props.row.id].price = +e.target.value;
                }}
              />
            );
          } else {
            return <p>{props.value}</p>;
          }
        },
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        Cell: (props) => {
          return (
            <input
              defaultValue={props.value}
              min={1}
              type="number"
              className="px-2 py-1 w-[100px]"
              onChange={(e) => {
                selectedItems[props.row.id].quantity = +e.target.value;
              }}
            />
          );
        },
      },
      {
        Header: "Action",
        Cell: (props) => {
          return (
            <button
              className="bg-white py-1 px-2 rounded-md text-[#a86aaa]"
              onClick={() => {
                setSelectedItems(
                  selectedItems.filter((item, index) => {
                    return index !== +props.row.id;
                  })
                );
              }}
            >
              Remove
              <span className="bg-[#a86aaa] text-white px-1 rounded-full ">
                X
              </span>
            </button>
          );
        },
      },
    ];
  }, [selectedItems]);

  const data = useMemo(() => {
    return selectedItems;
  }, [selectedItems]);

  const tableInstance = useTable({
    columns,
    data,
  });

  const { headerGroups, getTableProps, getTableBodyProps, rows, prepareRow } =
    tableInstance;
  // Table stuff for ticketsTable
  const columns2 = useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Price",
        accessor: "price",
        //the check below helps to prevent the price of a parent item from being editable because even if its edited all its children would still have the same price and total would not balance when a user buy's a package
        Cell: (props) => {
          if (props.row.original.itemGroupId !== ITEM_GROUP.PARENT) {
            return (
              <input
                defaultValue={props.value}
                className="px-2 py-1 w-[100px]"
                onChange={(e) => {
                  selectedTickets[props.row.id].price = +e.target.value;
                }}
              />
            );
          } else {
            return <p>{props.value}</p>;
          }
        },
      },
      {
        Header: "Quantity",
        accessor: "unit",
        Cell: (props) => {
          return (
            <input
              defaultValue={props.value}
              min={1}
              type="number"
              className="px-2 py-1 w-[100px]"
              onChange={(e) => {
                selectedTickets[props.row.id].unit = +e.target.value;
              }}
            />
          );
        },
      },
      {
        Header: "Action",
        Cell: (props) => {
          return (
            <button
              className="bg-white py-1 px-2 rounded-md text-[#a86aaa]"
              onClick={() => {
                setSelectedTickets(
                  selectedTickets?.filter((item, index) => {
                    return index !== +props.row.id;
                  })
                );
              }}
            >
              Remove
              <span className="bg-[#a86aaa] text-white px-1 rounded-full ">
                X
              </span>
            </button>
          );
        },
      },
    ];
  }, [selectedTickets]);

  const data2 = useMemo(() => {
    return selectedTickets;
  }, [selectedTickets]);

  const tableInstance2 = useTable({
    columns: columns2,
    data: data2,
  });

  const {
    headerGroups: headerGroups2,
    getTableProps: getTableProps2,
    getTableBodyProps: getTableBodyProps2,
    rows: rows2,
    prepareRow: prepareRow2,
  } = tableInstance2;

  return (
    <>
      <form className="relative" onSubmit={handleSubmit(onSubmit)}>
        <FormError
          className="absolute top-0 z-20 transform left-2/4 -translate-x-2/4"
          err={error}
          onDismiss={onDismiss}
        />

        <CancelSaveToolbar backPath={"/dashboard/tickets"} status={status} />

        <section className="w-full px-8 py-8 overflow-auto bg-app-purple-2">
          <h3 className="text-lg font-medium">Ticket details</h3>

          <div className="max-w-[1500px] mb-5 grid items-start gap-6 md:grid-cols-[minmax(0,1fr)_35%] mt-11">
            <div className="space-y-6">
              <div className="grid items-center gap-6 grid-cols-[35%_minmax(0,1fr)]">
                <label className="text-right" htmlFor="name">
                  Ticket Type Name :
                </label>

                <div className="relative">
                  <input
                    className="w-full p-2 px-4 py-2 border-none rounded-3xl bg-app-purple-10"
                    id="name"
                    name="name"
                    type="text"
                    {...register("name")}
                  />
                  <InlineErr err={errors?.name?.message} />
                </div>
              </div>

              <div className="grid items-center gap-6 grid-cols-[35%_minmax(0,1fr)]">
                <label className="text-right" htmlFor="type">
                  Short Name :
                </label>

                <div className="relative">
                  <input
                    className="w-full p-2 px-4 py-2 border-none rounded-3xl bg-app-purple-10"
                    id="type"
                    name="type"
                    type="text"
                    {...register("type")}
                  />
                  <InlineErr err={errors?.type?.message} />
                </div>
              </div>

              <div className="grid items-center gap-6 grid-cols-[35%_minmax(0,1fr)]">
                <label className="text-right" htmlFor="class">
                  Ticket Class :
                </label>

                <div className="relative">
                  <select
                    className="w-full p-2 px-4 py-2 border-none rounded-3xl bg-app-purple-10"
                    id="class"
                    name="class"
                    type="text"
                    {...register("class")}
                  >
                    <option value="">Select...</option>
                    {classes?.map((c) => (
                      <option value={c.id} key={c.id}>
                        {c.name}
                      </option>
                    ))}
                  </select>
                  <InlineErr err={errors?.class?.message} />
                </div>
              </div>

              {ticketGroup === "tgroup-581749" && (
                <div className="grid items-center gap-6 grid-cols-[35%_minmax(0,1fr)]">
                  <label className="text-right" htmlFor="isComplementary">
                    Is complimentary :
                  </label>

                  <label className={`relative`} htmlFor="isComplementary">
                    <input
                      name="isComplementary"
                      {...register("isComplementary")}
                      type="checkbox"
                    />
                  </label>
                </div>
              )}
              {ticketGroup === "tgroup-2292bd" && (
                <div className="grid items-center gap-6 grid-cols-[35%_minmax(0,1fr)]">
                  <label
                    className="text-right"
                    htmlFor="isVoucherComplementary"
                  >
                    Is complimentary voucher :
                  </label>

                  <label
                    className={`relative`}
                    htmlFor="isVoucherComplementary"
                  >
                    <input
                      name="isVoucherComplementary"
                      {...register("isVoucherComplementary")}
                      type="checkbox"
                    />
                  </label>
                </div>
              )}

              {!isComp ? (
                <div className="grid items-center gap-6 grid-cols-[35%_minmax(0,1fr)]">
                  <label className="text-right" htmlFor="price">
                    Price :
                  </label>

                  <Controller
                    control={control}
                    name="price"
                    render={({ field: { onChange, value } }) => (
                      <div className="relative">
                        <input
                          className="w-full p-2 px-4 py-2 border-none rounded-3xl bg-app-purple-10"
                          id="price"
                          name="price"
                          type="text"
                          onChange={(e) => {
                            const value = e.target.value;
                            onChange(cleanNumberString(value));
                          }}
                          value={formatNumberAsCurrency(value, true)}
                        />
                        <InlineErr err={errors?.price?.message} />
                      </div>
                    )}
                  />
                </div>
              ) : null}

              <div className="grid items-center gap-6 grid-cols-[35%_minmax(0,1fr)]">
                <label className="text-right" htmlFor="tax">
                  Sales tax (%)
                </label>

                <Controller
                  control={control}
                  name="tax"
                  render={({ field: { onChange, value } }) => (
                    <div className="relative">
                      <input
                        className="w-full p-2 px-4 py-2 border-none rounded-3xl bg-app-purple-10"
                        id="tax"
                        name="tax"
                        type="text"
                        onChange={(e) => {
                          const value = e.target.value;
                          onChange(cleanNumberString(value));
                        }}
                        value={formatNumberAsCurrency(value, true)}
                      />
                      <InlineErr err={errors?.tax?.message} />
                    </div>
                  )}
                />
              </div>

              <div className="grid items-center gap-6 grid-cols-[35%_minmax(0,1fr)]">
                <label className="text-right" htmlFor="tax">
                  Sales source
                </label>
                <div className="relative">
                  <Controller
                    control={control}
                    name="sourceIds"
                    render={({ field: { value, onChange } }) => {
                      return (
                        <>
                          <MultiSearchSelect
                            inputClassName="!w-full p-2 px-4 py-2 border-none rounded-3xl !bg-app-purple-10"
                            listClassName="!py-2"
                            items={sources}
                            id={"sourceIds"}
                            value={getSelectedItem(sources, value, "id")}
                            onChange={(value) =>
                              onChange(value.map((v) => v.id))
                            }
                            displayValue={(value) =>
                              value.map((v) => v.name).join(", ")
                            }
                            placeholder={`Select Sources`}
                            searchOptions={{
                              keys: ["name"],
                            }}
                          />
                        </>
                      );
                    }}
                  />
                  <InlineErr err={errors?.sourceIds?.message} />
                </div>
              </div>
              <div className="grid items-center gap-6 grid-cols-[35%_minmax(0,1fr)]">
                <label className="text-right" htmlFor="tax">
                  Seat Class:
                </label>
                <div className="relative">
                  <Controller
                    control={control}
                    name="seatClassId"
                    render={({ field: { value, onChange } }) => {
                      return (
                        <>
                          <SearchSelect
                            items={[
                              ...seatClasses,
                              { name: "None", id: "" },
                            ]}
                            dropDownNameKey="name"
                            inputClassName="!w-full p-2 px-4 py-2 border-none !bg-app-purple-10 rounded-3xl"
                            listClassName="!py-2"
                            id={`seatClassId`}
                            value={getSelectedItem2(
                              [...seatClasses, { name: "None", id: "" }],
                              value,
                              "id"
                            )}
                            onChange={(value) => onChange(value?.id)}
                            displayValue={(value) => {
                              return value?.name;
                            }}
                            placeholder={`Select Class`}
                            searchOptions={{
                              keys: ["name"],
                            }}
                          />
                        </>
                      );
                    }}
                  />
                  <InlineErr err={errors?.sourceIds?.message} />
                </div>
              </div>
            </div>
            <div className="space-y-6">
              <div className="relative flex md:justify-end">
                <StatusToggler
                  options={[
                    { key: "Active", value: "true" },
                    { key: "Inactive", value: "false" },
                  ]}
                  register={register}
                  statusKey="isActive"
                  radioLabel="Status"
                  errMsg={errors?.isActive?.message}
                />
              </div>
              <div className="relative flex md:justify-end">
                <StatusToggler
                  options={[
                    { key: "Yes", value: "true" },
                    { key: "No", value: "false" },
                  ]}
                  register={register}
                  statusKey="isLoyalty"
                  // className="grid items-center gap-6 grid-cols-[40%_minmax(0,1fr)]"
                  radioLabel="Loyalty Ticket"
                  errMsg={errors?.isLoyalty?.message}
                />
              </div>

              {ticketGroup === "tgroup-581749" && isCanal && (
                <div className="relative flex md:justify-end">
                  <StatusToggler
                    options={[
                      { key: "Yes", value: "true" },
                      { key: "No", value: "false" },
                    ]}
                    register={register}
                    statusKey="openTicket"
                    // className="grid items-center gap-6 grid-cols-[40%_minmax(0,1fr)]"
                    radioLabel="Open Ticket"
                    errMsg={errors?.openTicket?.message}
                  />
                </div>
              )}
            </div>
          </div>
        </section>

        <Error
          retry={getInfo}
          error="Error loading classes/channels"
          show={!!(channelsErr || classesErr)}
        />
      </form>
      {/* Tables for items and tickets */}

      {/* Tables for items */}
      {/* to dynamically display the tables for just package tickets */}
      {ticketGroup === "tgroup-9e210a" && (
        <div>
          <div className="flex items-center justify-between px-2 py-3 bg-[#a86aaa] ">
            <p className="text-white">Items</p>
            <button
              className="rounded border-0 bg-white p-1 text-[#C96FCC]"
              onClick={() => {
                setShowItemsModal(true);
              }}
              type="button"
            >
              Add Item
            </button>
          </div>
          <table {...getTableProps()} className="w-full">
            <thead>
              {headerGroups?.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup?.headers?.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      className="shadow-lg p-4 border border-[#7E208080] bg-white text-left"
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows?.map((row) => {
                prepareRow(row);

                return (
                  <tr
                    {...row.getRowProps()}
                    className="transition duration-300 bg-app-purple-1 hover:bg-gray-100"
                  >
                    {row?.cells?.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        className="p-4 border border-[#7E208080] bg-"
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {showItemsModal && (
            <ItemsModal
              show={showItemsModal}
              onClose={() => {
                setShowItemsModal(false);
              }}
              setSelection={setSelectedItems}
              selection={selectedItems}
              itemGroupType={"03571310-71a2-4425-93ce-1c65d18b4341"}
              initialItemsArray={itemsList?.map((item) => {
                return { ...item, quantity: 1 };
              })}
            />
          )}

          {/* Tables for tickets */}
          <div className="flex items-center justify-between px-2 py-3 bg-[#a86aaa] ">
            <p className="text-white">Ticket</p>
            <button
              className="rounded border-0 bg-white p-1 text-[#C96FCC]"
              onClick={() => {
                setShowTicketsModal(true);
              }}
              type="button"
            >
              Add Ticket
            </button>
          </div>
          <table {...getTableProps2()} className="w-full">
            <thead>
              {headerGroups2?.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup?.headers?.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      className="shadow-lg p-4 border border-[#7E208080] bg-white text-left"
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps2()}>
              {rows2?.map((row) => {
                prepareRow2(row);

                return (
                  <tr
                    {...row.getRowProps()}
                    className="transition duration-300 bg-app-purple-1 hover:bg-gray-100"
                  >
                    {row?.cells?.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        className="p-4 border border-[#7E208080] bg-"
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {showTicketsModal && (
            <TicketsModal
              show={showTicketsModal}
              onClose={() => {
                setShowTicketsModal(false);
              }}
              setSelection={setSelectedTickets}
              selection={selectedTickets}
            />
          )}
        </div>
      )}
      {formError && (
        <ItemFormErrorModal
          onClose={() => {
            setFormError(false);
          }}
          show={formError}
          message={formError}
          onClick={() => {
            setFormError(false);
          }}
        />
      )}
    </>
  );
};

export { TicketForm };
