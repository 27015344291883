export const preventScrollBug = () => {
  // prevent scroll bug
  // https://github.com/tailwindlabs/headlessui/issues/1698
  const HTMLDOM = document.querySelector("html");

  // console.log('html dom', HTMLDOM)

  if (HTMLDOM) {
    // https://stackoverflow.com/a/67384208/6236633
    // HTMLDOM.style = ''
    HTMLDOM.setAttribute("style", "");
  }
};
