import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import logo from "../../assets/images/logo.png";
import { Spinner } from '../UI';
import EyeOpen from '../Icons/EyeOpen';
import EyeClosed from '../Icons/EyeClosed';
import { changePasswordInit, changePasswordSchema } from '../../utils';

const ConfirmPassword = () => {
    const [show, setShow] = useState(false);
     const {
       register,
       formState: { errors },
       handleSubmit,
     } = useForm({
       resolver: yupResolver(changePasswordSchema),
       defaultValues: changePasswordInit,
     });
     const handleConfirmPassword = async (values) => {};
    return (
      <>
     <div>
          <img className="w-auto h-32 mx-auto" src={logo} alt="Reach Logo" />
          <h2 className="my-10 text-xl font-medium text-center text-gray-900">
            Reset Admin Password
          </h2>
        </div>
        <form
          onSubmit={handleSubmit(handleConfirmPassword)}
          autoComplete="off"
          className="mt-8 space-y-6"
        >
          <div className="space-y-4 rounded-md">
            <div className="relative pb-5">
              <label htmlFor="oldPassword" className="sr-only">
                Old Password
              </label>
              <input
                autoComplete="off"
                id="oldPassword"
                type="password"
                {...register("oldPassword")}
                className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-app-purple-4 focus:border-app-purple-4 focus:z-10 sm:text-sm"
                placeholder="Old Password"
              />

              <span className="absolute bottom-0 left-0 flex items-center mt-1 ml-1 text-xs italic font-medium tracking-wide text-red-500">
                {errors?.userName?.message}
              </span>
            </div>
            <div className="relative pb-5">
              <label htmlFor="newPassword" className="sr-only">
                New Password
              </label>
              <input
                id="password"
                autoComplete="off"
                type={show ? "text" : "password"}
                {...register("newPassword")}
                className="relative block w-full px-3 py-2 pr-12 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-app-purple-4 focus:border-app-purple-4 focus:z-10 sm:text-sm"
                placeholder="New Password"
              />
              <button
                className="absolute top-0 z-10 flex items-center justify-center w-5 h-5 mt-5 transform right-3 -translate-y-2/4 "
                type="button"
                onClick={() => setShow((s) => !s)}
              >
                {show ? <EyeOpen /> : <EyeClosed />}
              </button>

              <span className="absolute bottom-0 left-0 flex items-center mt-1 ml-1 text-xs italic font-medium tracking-wide text-red-500">
                {errors?.newPassword?.message}
              </span>
            </div>
            <div className="relative pb-5">
              <label htmlFor="confirmPassword" className="sr-only">
                Confirm Password
              </label>
              <input
                id="confirmPassword"
                autoComplete="off"
                type={show ? "text" : "password"}
                {...register("confirmPassword")}
                className="relative block w-full px-3 py-2 pr-12 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-app-purple-4 focus:border-app-purple-4 focus:z-10 sm:text-sm"
                placeholder="Confirm Password"
              />
              <button
                className="absolute top-0 z-10 flex items-center justify-center w-5 h-5 mt-5 transform right-3 -translate-y-2/4 "
                type="button"
                onClick={() => setShow((s) => !s)}
              >
                {show ? <EyeOpen /> : <EyeClosed />}
              </button>

              <span className="absolute bottom-0 left-0 flex items-center mt-1 ml-1 text-xs italic font-medium tracking-wide text-red-500">
                {errors?.confirmPassword?.message}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <button
              type="submit"
              className="relative min-w-[150px] flex justify-center items-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-app-purple-4"
            >
              Submit
              <Spinner
                className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                // status={status}
              />
            </button>
          </div>
        </form>
      </>
  )
}

export default ConfirmPassword
