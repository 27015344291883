import React, { useMemo, useCallback } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectStaffSessions, setEnd, setStart } from "../../redux/slices";

import {
  formatDateTime,
  formatNumberAsCurrency,
  getTableProps,
} from "../../utils";
import { DateTimeInput } from "../Films/DateTimeInput";

import { ClientPaginatedTable, DefaultColumnFilter } from "../UI";

const DatePickerForm = ({ getSessions }) => {
  const dispatch = useDispatch();

  const { startDate, endDate } = useSelector(selectStaffSessions);

  const onSubmit = (e) => {
    e.preventDefault();
    getSessions(true, startDate, endDate);
  };

  return (
    <form
      onSubmit={onSubmit}
      className="flex flex-wrap items-center px-2 -mx-2 space-x-2"
    >
      <div className="my-2 ">
        <DatePicker
          placeholderText="Start Date"
          selected={new Date(startDate)}
          onChange={(value) => dispatch(setStart(value.toISOString()))}
          customInput={
            <DateTimeInput
              className="bg-app-purple-8"
              dateFormat={"EEE, MMM dd, yyyy"}
            />
          }
        />
      </div>

      <div className="my-2 ">
        <DatePicker
          placeholderText="End Date"
          selected={new Date(endDate)}
          onChange={(value) => dispatch(setEnd(value.toISOString()))}
          customInput={
            <DateTimeInput
              className="bg-app-purple-8"
              dateFormat={"EEE, MMM dd, yyyy"}
            />
          }
        />
      </div>

      <button
        className="px-4 py-2 my-2 rounded-lg text-app-yellow bg-app-purple-3"
        type="submit"
      >
        Fetch Sessions
      </button>
    </form>
  );
};

const SessionsTable = ({ info = [], setItem, getSessions }) => {
  const navigate = useNavigate();
  const openModal = useCallback(
    (session) => {
 
      setItem(session);
    },
    [setItem]
  );
  const columns = useMemo(
    () => [
      {
        Header: "Status ",
        id: "edit",
        Cell: ({ row: { original } }) => {
          return original.isActive ? (
            <button
              className={`px-4 py-2 rounded-lg ${
                original.status === "Draft"
                  ? "text-app-yellow bg-app-purple-3"
                  : "text-[#007F00] bg-[#CDFFCD]"
              }`}
              onClick={() => openModal(original)}
              type="button"
            >
              {original.status === "Draft" ? "Draft" : "Open"}
            </button>
          ) : (
            <span className="px-4 py-2 rounded-lg cursor-not-allowed text-app-purple-3 bg-app-yellow">
              Closed
            </span>
          );
        },
      },
      {
        Header: "Staff Name",
        accessor: "name",
        Filter: DefaultColumnFilter,
        Cell: ({ value, row }) => (
          <button
            onClick={() => {
              navigate(
                `/dashboard/reports/cashier-session-reconcilation/${row?.original?.dateCreated}/${row?.original?.userId}`
              );
            }}
          >
            {value}
          </button>
        ),
      },
      {
        Header: "Actual Card",
        accessor: "actualCard",
        Cell: ({ value }) => formatNumberAsCurrency(value),
      },
      {
        Header: "Actual Cash",
        accessor: "actualCash",
        Cell: ({ value }) => formatNumberAsCurrency(value),
      },
      {
        Header: "Actual Transfer",
        accessor: "actualTransfer",
        Cell: ({ value }) => formatNumberAsCurrency(value),
      },
      {
        Header: "Actual Ext. Voucher",
        accessor: "actualExternalVoucher",
        Cell: ({ value }) => formatNumberAsCurrency(value),
      },
      {
        Header: "Actual Custom Channel",
        accessor: "actualOtherChannel",
        Cell: ({ value }) => formatNumberAsCurrency(value),
      },
      {
        Header: "Expected Card",
        accessor: "expectedCard",
        Cell: ({ value }) => formatNumberAsCurrency(value),
      },
      {
        Header: "Expected Cash",
        accessor: "expectedCash",
        Cell: ({ value }) => formatNumberAsCurrency(value),
      },
      {
        Header: "Expected Transfer",
        accessor: "expectedTransfer",
        Cell: ({ value }) => formatNumberAsCurrency(value),
      },
      {
        Header: "Expected Ext. Voucher",
        accessor: "expectedExternalVoucher",
        Cell: ({ value }) => formatNumberAsCurrency(value),
      },
      {
        Header: "Expected Custom Channel",
        accessor: "expectedOtherChannel",
        Cell: ({ value }) => formatNumberAsCurrency(value),
      },
      {
        Header: "Total Expected",
        accessor: "totalExpected",
        Cell: ({ value }) => formatNumberAsCurrency(value),
      },
      {
        Header: "Total Actual",
        accessor: "totalActual",
        Cell: ({ value }) => formatNumberAsCurrency(value),
      },
      {
        Header: "Card Variance (%)",
        accessor: "cardVariance",
        Cell: ({ value }) => (
          <p className={value !== 0 && "text-red-500"}>
            {formatNumberAsCurrency(value)}
          </p>
        ),
      },
      {
        Header: "Cash Variance (%)",
        accessor: "cashVariance",
        Cell: ({ value }) => (
          <p className={value !== 0 && "text-red-500"}>
            {formatNumberAsCurrency(value)}
          </p>
        ),
      },
      {
        Header: "Transfer Variance (%)",
        accessor: "transferVariance",
        Cell: ({ value }) => (
          <p className={value !== 0 && "text-red-500"}>
            {formatNumberAsCurrency(value)}
          </p>
        ),
      },
      {
        Header: "Ext. Voucher Variance (%)",
        accessor: "externalVoucherVariance",
        Cell: ({ value }) => (
          <p className={value !== 0 && "text-red-500"}>
            {formatNumberAsCurrency(value)}
          </p>
        ),
      },
      {
        Header: "Custom Channel Variance (%)",
        accessor: "otherChannelVariance",
        Cell: ({ value }) => (
          <p className={value !== 0 && "text-red-500"}>
            {formatNumberAsCurrency(value)}
          </p>
        ),
      },

      {
        Header: "Opened on",
        accessor: "dateCreated",
        Cell: ({ value }) =>
          formatDateTime(value, "EEEE, do LLLL yyyy @ HH:mm"),
      },
      {
        Header: "Closed By",
        accessor: "closedBy",
      },
      {
        Header: "Closed On",
        accessor: "closedOn",
        Cell: ({ value }) =>
          formatDateTime(value, "EEEE, do LLLL yyyy @ HH:mm"),
      },
    ],
    [openModal]
  );

  return (
    <div className="w-full overflow-x-scroll">
      <ClientPaginatedTable
        tableClass="!min-w-max"
        columns={columns}
        tableData={info}
        filterColumnsKeys={["name"]}
        {...getTableProps((cellInfo) => ({
          className: `border-[#00000033] border ${
            cellInfo.column.id === "edit" ? "!py-1" : ""
          }`,
        }))}
        rowClass="hover:!bg-transparent !cursor-default"
        toolbarChildren={<DatePickerForm getSessions={getSessions} />}
        toolBarWrapperClass="!flex !flex-wrap items-center justify-between !bg-transparent"
      />
    </div>
  );
};

export { SessionsTable };
