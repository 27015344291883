import { useSelector } from "react-redux";
import { reportData } from "../../redux/slices";
import { useLoggedInuser } from "../../hooks";
import ReachLogo from "../../assets/images/logo.png";
import {
  dateAndTime,
  formatDateTime,
  formatNumberAsCurrency,
} from "../../utils";
import { Th } from "./styles";
const FilmByScreenTable = ({ id }) => {
  const { filmByScreen } = useSelector(reportData);
  const { profile } = useLoggedInuser();
  return (
    <div className="px-3">
      <div className="flex items-center justify-between">
        <div
          key={ReachLogo}
          className=" w-[5rem] h-[5rem]  bg-no-repeat bg-contain bg-center "
          style={{
            backgroundImage: `url("${ReachLogo}")`,
          }}
        ></div>
        <p className="font-bold text-[20px]">Film By Screen Report</p>
        <p>{profile.cinema}</p>
      </div>
      <div className=" flex p-2 justify-between">
        <div>
          <p>
            {" "}
            <span className="font-bold">User Name:</span>
            {` ${profile?.firstName} ${profile?.lastName}`}
          </p>
          <p>
            <span className="font-bold">Date Time Generated: </span>
            {formatDateTime(
              filmByScreen?.paramFilter?.dateTimeGenerated,
              dateAndTime
            )}
          </p>
        </div>
        <div>
          <p className="font-bold">Data Range</p>
          <p>
            {new Date(filmByScreen?.paramFilter?.dateFrom).toDateString()}
          </p>{" "}
          <p>{new Date(filmByScreen?.paramFilter?.dateTo).toDateString()}</p>
        </div>
      </div>
      <table
        className="table-auto w-full mx-auto mt-8 overflow-hidden text-left border rounded-md border-app-purple-4"
        id={id}
      >
        <thead className="shadow-2xl bg-app-purple-8">
          <tr>
            <Th>Screen</Th>
            <Th>Film</Th> <Th>Distributor</Th> <Th>Sales</Th> <Th>Refunds</Th>{" "}
            <Th>Admits</Th> <Th>Net Total</Th>
            <Th>Tax Total</Th> <Th>Gross Total</Th>
          </tr>
        </thead>
        <tbody>
          {filmByScreen?.result?.report?.length > 0
            ? filmByScreen?.result?.report?.map((data, index) => {
                return (
                  <tr
                    className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30"
                    key={data?.id}
                  >
                    <td className=" px-2">{data?.screen}</td>
                    <td className=" px-2">{data?.film}</td>
                    <td className="px-2">{data?.distributor}</td>
                    <td className=" px-2">{data?.sales}</td>
                    <td className=" px-2">{data?.refunds}</td>
                    <td className=" px-2 text-center">{data?.admits}</td>
                    <td className=" px-2">
                      {id
                        ? data?.netTotal
                        : formatNumberAsCurrency(data?.netTotal?.toFixed(2))}
                    </td>
                    <td className=" px-2">{data?.taxTotal}</td>
                    <td className=" px-2">
                      {id
                        ? data?.grossTotal
                        : formatNumberAsCurrency(data?.grossTotal)}
                    </td>
                  </tr>
                );
              })
            : null}
          <tr className="transition duration-300 border-y-2  bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[13px] font-bold">
            <td className=" py-4 pl-2 text-[16px]" colSpan="3">
              {" "}
              Total
            </td>

            <td className=" px-2">{filmByScreen?.result?.total?.sales}</td>
            <td className=" px-2">{filmByScreen?.result?.total?.refunds}</td>
            <td className=" px-2 text-center">
              {filmByScreen?.result?.total?.admits}
            </td>
            <td className=" px-2">
              {id
                ? filmByScreen?.result?.total?.netTotal
                : formatNumberAsCurrency(filmByScreen?.result?.total?.netTotal?.toFixed(2))}
            </td>
            <td className=" px-2">{filmByScreen?.result?.total?.taxTotal}</td>
            <td className=" px-2">
              {id
                ? filmByScreen?.result?.total?.grossTotal
                : formatNumberAsCurrency(filmByScreen?.result?.total?.grossTotal)}
            </td>
          </tr>
        </tbody>
      </table>
      {filmByScreen?.result.report.length < 1 && (
        <p className="text-center py-3"> No Data Found</p>
      )}
    </div>
  );
};
export { FilmByScreenTable };
