import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectFinance } from "../redux/slices";
import isBefore from "date-fns/isBefore";
import isEqual from "date-fns/isEqual";
import {
  refreshTokenKey,
  tokenKey,
  expiresKey,
  axiosApiInstance,
  API_URL,
  setTokenService,
  refreshInterval,
} from "../utils";

import { store } from "../redux/store";

const setTokens = (refreshToken, token, userId) => {
  return {
    type: "auth/setTokens",
    payload: {
      refreshToken,
      token,
      userId,
    },
  };
};

const upDateAccessToken = async () => {
  let resp = {
    refreshToken: "",
    token: "",
    expiresAt: "",
    userId: "",
  };

  const currentToken = await store?.getState()?.auth?.token;
  const currentRefreshToken = await store?.getState()?.auth?.refreshToken;

  try {
    const response = await axiosApiInstance.post(
      `${API_URL}/Authentication/GenerateRefreshToken`,
      {
        currentJWT: currentToken,
        refreshToken: currentRefreshToken,
      }
    );

    const { token, refreshToken, userId, expiresAt } = {
      ...resp,
      ...response?.data,
    };

    setTokenService(refreshToken, token, expiresAt);
    store?.dispatch(setTokens(token, refreshToken, userId));
  } catch (error) {
    console.error(error);
  }
};

const useAuth = (redirectTo, shouldNavigate = true) => {
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { subscriptionStatus } = useSelector(selectFinance);

  useEffect(() => {
    const path = !redirectTo ? `/login` : `/login?redirectTo=${redirectTo}`;
    const token = localStorage.getItem(tokenKey);
    const refreshToken = localStorage.getItem(refreshTokenKey);

    const expiresAt = new Date(localStorage.getItem(expiresKey));
    const now = new Date();

    if (token && refreshToken && expiresAt) {
      const hasExpired = isBefore(expiresAt, now) || isEqual(expiresAt, now);
      setLoading(false);

      if (hasExpired) {
        setValid(false);
        shouldNavigate && navigate(path);
      } else {
        if (subscriptionStatus?.status === "Disconnected") {
          shouldNavigate && navigate("/dashboard/finance/invoice");
          setValid(true);
        } else {
          setValid(true);
        }
      }
    } else {
      setLoading(false);

      setValid(false);
      shouldNavigate && navigate(path);
    }
  }, [navigate, redirectTo, shouldNavigate, subscriptionStatus]);

  useEffect(() => {
    let intervalId;
    if (shouldNavigate) {
      intervalId = setInterval(async () => {
        await upDateAccessToken();
      }, refreshInterval);
    }

    return () => (intervalId ? clearInterval(intervalId) : null);
  }, [shouldNavigate]);

  return { loading, valid };
};

export { useAuth };
