import React from "react";

const Staff = ({ fill = process.env.REACT_APP_COLOR_10 }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_303_1074)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 14.0002C10.9116 14.0002 8.4 11.4886 8.4 8.40019C8.4 5.31179 10.9116 2.80019 14 2.80019C17.0884 2.80019 19.6 5.31179 19.6 8.40019C19.6 11.4886 17.0884 14.0002 14 14.0002ZM19.2612 14.9424C20.2414 14.157 21.0325 13.1613 21.5758 12.0289C22.1191 10.8964 22.4008 9.65623 22.4 8.40019C22.401 6.97996 22.0419 5.58273 21.3563 4.33896C20.6706 3.09519 19.6808 2.04544 18.4794 1.28808C17.2779 0.530728 15.9041 0.0904348 14.4862 0.00822506C13.0684 -0.0739847 11.6528 0.20469 10.3719 0.818113C9.09098 1.43154 7.98651 2.35973 7.16171 3.51592C6.33692 4.67211 5.81871 6.01848 5.65558 7.42932C5.49246 8.84015 5.68971 10.2693 6.22891 11.5832C6.7681 12.8971 7.63166 14.0528 8.73881 14.9424C3.61621 16.8674 0 21.6232 0 28.0002H2.8C2.8 21.0002 7.8246 16.8002 14 16.8002C20.1754 16.8002 25.2 21.0002 25.2 28.0002H28C28 21.6232 24.3838 16.8674 19.2612 14.9424Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_303_1074">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { Staff };
