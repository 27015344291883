import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { getOrdersByUser, getOrderById } from "../../../redux/slices";
import {
  partialRefundSchema,
  preventScrollBug,
  API_URL,
  axiosApiInstance,
  formatNumberAsCurrency,
} from "../../../utils";
import { Spinner } from "../../UI";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRequest } from "../../../hooks";

const PartialRefundModal = ({
  onClose = () => {},
  show,
  orderData,
  funcProps,
}) => {
  const dispatch = useDispatch();
  const [formError, setFormError] = useState(false);
  const { setPending, setResolved, setRejected, err, status } = useRequest();

  const { register, control, watch, reset, handleSubmit } = useForm({
    resolver: yupResolver(partialRefundSchema),
    defaultValues: {},
  });

  const { fields } = useFieldArray({
    control,
    name: "refundTickets",
  });

  const { fields: fields2 } = useFieldArray({
    control,
    name: "refundItems",
  });

  const { fields: fields3 } = useFieldArray({
    control,
    name: "refundPaymentChannels",
  });

  useEffect(() => {
    dispatch(getOrderById({ force: true, orderId: orderData.id }))
      .unwrap()
      .then((data) => {
        const items = data?.itemSales?.map((item) => {
          return {
            itemSaleId: item?.id,
            quantity: 0,
            name: item?.item,
            max: item?.quantity,
            price: item?.price,
          };
        });
        const tickets = data?.ticketSales?.map((ticket) => {
          return {
            ticketSaleId: ticket?.id,
            quantity: 0,
            name: ticket?.film,
            max: ticket?.quantity,
            price: ticket?.ticketPrice,
          };
        });

        const channels = data?.paymentChannels?.map((channel) => {
          return {
            channelId: channel?.channelId,
            amount: 0,
            channel: channel?.channel,
            maxAmount: channel?.amount,
          };
        });

        const applicableChannels = data.paymentChannels
          .filter((data) => {
            return (
              data?.channel === "Card" ||
              data?.channel === "Cash" ||
              data?.channel === "Transfer"
            );
          })
          .map((x) => {
            return x?.amount;
          });

        const applicableChannelsTotal = applicableChannels.reduce(
          (a, b) => a + b,
          0
        );

        reset({
          refundItems: items,
          refundTickets: tickets,
          amountInSession: applicableChannelsTotal,
          refundPaymentChannels: channels,
        });
      });
  }, [orderData.id]);

  const formData = watch();

  const amountToBeRefunded = useMemo(() => {
    const amountInSession = formData.amountInSession;

    let ticketRefundAmount = 0;
    let itemRefundAmount = 0;

    if (formData?.refundItems?.length > 0) {
      itemRefundAmount = formData?.refundItems
        ?.map((x) => {
          return x.quantity * x.price;
        })
        .reduce((a, b) => a + b, 0);
    }

    if (formData?.refundTickets?.length > 0) {
      ticketRefundAmount = formData?.refundTickets
        ?.map((x) => {
          return x.quantity * x.price;
        })
        .reduce((a, b) => a + b, 0);
    }

    let refundAmount = ticketRefundAmount + itemRefundAmount;

    if (amountInSession >= refundAmount) {
      return refundAmount;
    }

    if (amountInSession < refundAmount) {
      return amountInSession;
    }

    return 0;
  }, [formData]);

  //change to commit

  const partialRefundFunc = async (data) => {
    const body = {
      reason: data.reason || null,
      supplimentaryOrderId: data.supplimentaryOrderId || null,
      refundItems: data?.refundItems?.find((x) => {
        return x?.quantity > 0;
      })
        ? data?.refundItems
            ?.filter((x) => {
              return x?.quantity > 0;
            })
            ?.map((x) => {
              return { quantity: x?.quantity, itemSaleId: x?.itemSaleId };
            })
        : [],
      refundTickets: data?.refundTickets?.find((x) => {
        return x?.quantity > 0;
      })
        ? data?.refundTickets
            ?.filter((x) => {
              return x?.quantity > 0;
            })
            ?.map((x) => {
              return { quantity: x?.quantity, ticketSaleId: x?.ticketSaleId };
            })
        : [],
      refundPaymentChannels: data?.refundPaymentChannels?.filter((x) => {
        return x?.amount > 0;
      })
        ? data?.refundPaymentChannels
            ?.filter((x) => {
              return x?.amount > 0;
            })
            ?.map((x) => {
              return { channelId: x?.channelId, amount: x?.amount };
            })
        : [],
      refundVouchers: [],
    };

    if (body.refundItems.length < 1 && body.refundTickets.length < 1) {
      setFormError(
        "Ensure you enter the quantity of the item or ticket you want to refund"
      );
      return;
    }

    if (
      amountToBeRefunded &&
      body.refundPaymentChannels
        ?.map((x) => {
          return x?.amount;
        })
        .reduce((a, b) => a + b, 0) !== +amountToBeRefunded
    ) {
      setFormError(
        "Ensure the Total entered for all channels is equal to the amount to be reverted"
      );
      return;
    }

    if (data.supplimentaryOrderId) {
      dispatch(
        getOrderById({ force: true, orderId: data.supplimentaryOrderId })
      )
        .unwrap()
        .then(async (data) => {
          setFormError(false);

          try {
            setPending();

            await axiosApiInstance
              .patch(
                `${API_URL}/Orders/PartialRefundOrder?orderId=${orderData.id}`,
                body
              )

              .then(() => {
                setResolved();
                dispatch(getOrdersByUser(funcProps));
                onClose();
              });
          } catch (error) {
            setRejected(error);
          }
        })
        .catch((error) => {
          setFormError(
            "Kindly confirm the supplementary order id entered is correct. No order was found for it"
          );
          return;
        });
    } else {
      try {
        setPending();
        await axiosApiInstance
          .patch(
            `${API_URL}/Orders/PartialRefundOrder?orderId=${orderData.id}`,
            body
          )
          .then(() => {
            setResolved();
            dispatch(getOrdersByUser(funcProps));
            onClose();
          });
      } catch (error) {
        setRejected(error);
      }
    }
  };

  return (
    <>
      <Transition show={show} appear as={Fragment}>
        <Dialog as="div" className="relative z-[10000]" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => {
              setTimeout(() => {
                preventScrollBug();
              }, 500);
            }}
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-sm overflow-auto max-h-[90vh] text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-white bg-[#a86aaa] rounded-t-2xl px-2 py-2 text-center"
                  >
                    Partial Refund
                  </Dialog.Title>
                  <form
                    onSubmit={handleSubmit(partialRefundFunc)}
                    className="p-3"
                  >
                    <div className=" max-h-[60vh] overflow-y-auto border-t-[1px] border-[#7E208080]">
                      <div className="flex items-center justify-between px-2 py-1 border-b-[1px] border-[#7E208080]">
                        <span className="w-[50%] text-[#C96FCC] font-bold">
                          Name
                        </span>{" "}
                        <span className="w-[10%] text-[#C96FCC] font-bold">
                          Qty
                        </span>
                      </div>
                      {fields?.map((field, index) => {
                        return (
                          <div
                            className="flex items-center justify-between px-2 py-1 border-b-[1px] border-[#7E208080]"
                            key={index}
                          >
                            <p>{field.name}</p>
                            <input
                              type={"number"}
                              className="w-[50px] p-2 rounded bg-app-purple-10"
                              {...register(`refundTickets.${index}.quantity`)}
                              max={field?.max}
                              min={0}
                            />
                          </div>
                        );
                      })}
                      {fields2?.map((field, index) => {
                        return (
                          <div
                            className="flex items-center justify-between px-2 py-1 border-b-[1px] border-[#7E208080]"
                            key={index}
                          >
                            <p>{field.name}</p>
                            <input
                              type={"number"}
                              className="w-[50px] p-2 rounded bg-app-purple-10"
                              {...register(`refundItems.${index}.quantity`)}
                              max={field?.max}
                              min={0}
                            />
                          </div>
                        );
                      })}
                    </div>
                    <p className="py-3">
                      <span>Amount To be reverted In Session: </span>
                      <span className="text-[#C96FCC]">
                        {formatNumberAsCurrency(amountToBeRefunded)}
                      </span>
                    </p>

                    {formData.refundPaymentChannels && (
                      <div className=" max-h-[60vh] overflow-y-auto border-t-[1px] border-[#7E208080]">
                        <div className="flex items-center justify-between px-2 py-1 border-b-[1px] border-[#7E208080]">
                          <span className=" text-[#C96FCC] font-bold">
                            Channel
                          </span>{" "}
                          <span className=" text-[#C96FCC] font-bold">
                            Max Amount
                          </span>
                          <span className=" text-[#C96FCC] font-bold">
                            Amount
                          </span>
                        </div>
                        {fields3?.map((field, index) => {
                          return (
                            <div
                              className="flex items-center justify-between px-2 py-1 border-b-[1px] border-[#7E208080]"
                              key={index}
                            >
                              <p>{field.channel}</p>
                              <p>{formatNumberAsCurrency(field.maxAmount)}</p>
                              <input
                                type={"number"}
                                className="w-[80px] p-2 rounded bg-app-purple-10"
                                {...register(
                                  `refundPaymentChannels.${index}.amount`
                                )}
                                max={field?.maxAmount}
                                min={0}
                              />
                            </div>
                          );
                        })}
                      </div>
                    )}

                    <div className="mb-2">
                      <label htmlFor={"reason"} className="block mb-2">
                        {" "}
                        Reason
                      </label>
                      <textarea
                        id={"reason"}
                        className="w-full p-2 rounded bg-app-purple-10"
                        rows={4}
                        placeholder={"Reason for the Refund"}
                        {...register("reason")}
                      ></textarea>
                    </div>
                    <div>
                      <label
                        id={"supplimentaryOrderId"}
                        className=" block mb-2"
                      >
                        {" "}
                        Supplementary Order Id
                      </label>
                      <input
                        id={"supplimentaryOrderId"}
                        className=" w-full p-2 rounded bg-app-purple-10"
                        placeholder="Order Id of Supplementary order"
                        {...register("supplimentaryOrderId")}
                      />
                    </div>
                    {formError && (
                      <p className="text-red-500 italic text-center text-sm p-2">
                        {formError}
                      </p>
                    )}
                    <div className="mt-4 flex justify-around py-3">
                      <button
                        type="button"
                        className="inline-flex justify-center items-center rounded-md border border-transparent bg-red-700 px-4 py-2 text-sm font-medium text-white mr-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={() => {
                          onClose();
                          setFormError(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      >
                        <span>Refund </span>
                        <Spinner
                          className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                          status={status}
                        />
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export { PartialRefundModal };
