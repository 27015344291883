import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import CheckImage from "../../../assets/images/check.png";
import { useDispatch, useSelector } from "react-redux";
import {
  approveStockRequest,
  approvePurchaseOrder,
  stockData,
  approveStockReceipt,
  approveStockTransfer,
  updateFetchedStockRequest,
  updateFetchedPurchaseOrder,
  updateFetchedStockReceipt,
  approveTransferReceipt,
} from "../../../redux/slices";
import { Spinner } from "../../UI";
import { FETCH_STATUS, replaceInArray } from "../../../utils";

const AcceptanceConfirmation = ({
  onClose = () => {},
  show,
  data,
  type,
  onConfirm,
}) => {
  const dispatch = useDispatch();
  const {
    approveStockRequestStatus,
    approveStockRequestErr,
    approvePurchaseOrderStatus,
    approvePurchaseOrderErr,
    approveStockReceiptStatus,
    approveStockReceiptErr,
    stockRequests,
    purchaseOrders,
    stockReceipts,
    approveStockTransferErr,
    approveStockTransferStatus,
    approveTransferReceiptErr,
    approveTransferReceiptStatus,
  } = useSelector(stockData);
  const [showApiErr, setShowApiErr] = useState(false);
  const confirmationFunc = () => {
    if (type === "Stock Request") {
      dispatch(approveStockRequest(data))
        .unwrap()
        .then((data) => {
          const newData = replaceInArray(stockRequests, {
            ...data,
            requestedBy:
              data?.requestedBy?.firstName + data?.requestedBy?.lastName,
          });
          dispatch(updateFetchedStockRequest(newData));
          setShowApiErr(false);
          onConfirm();
        })
        .catch(() => {
          setShowApiErr(true);
        });
    }
    if (type === "Purchase Order") {
      dispatch(approvePurchaseOrder(data))
        .unwrap()
        .then((data) => {
          const newData = replaceInArray(purchaseOrders, {
            ...data,
            // requestedBy:
            //   data?.requestedBy?.firstName + data?.requestedBy?.lastName,
          });
          dispatch(updateFetchedPurchaseOrder(newData));
          setShowApiErr(false);
          onConfirm();
        })
        .catch(() => {
          setShowApiErr(true);
        });
    }
    if (type === "Stock Receipt") {
      dispatch(approveStockReceipt(data))
        .unwrap()
        .then((data) => {
          const newData = replaceInArray(stockReceipts, {
            ...data,
            requestedBy:
              data?.requestedBy?.firstName + data?.requestedBy?.lastName,
          });
          dispatch(updateFetchedStockReceipt(newData));
          setShowApiErr(false);
          onConfirm();
        })
        .catch(() => {
          setShowApiErr(true);
        });
    }
    if (type === "Stock Transfer") {
      dispatch(approveStockTransfer(data))
        .unwrap()
        .then((data) => {
          setShowApiErr(false);
          onConfirm();
        })
        .catch(() => {
          setShowApiErr(true);
        });
    }
    if (type === "Stock Transfer Receipt") {
      dispatch(approveTransferReceipt(data))
        .unwrap()
        .then((data) => {
          setShowApiErr(false);
          onConfirm();
        })
        .catch(() => {
          setShowApiErr(true);
        });
    }
  };

  return (
    <>
      <Transition show={show} appear as={Fragment}>
        <Dialog as="div" className="relative z-[10000]" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg px-5 py-2 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <div
                    className="flex justify-end "
                    onClick={() => {
                      onClose();
                      setShowApiErr(false);
                    }}
                  >
                    <div
                      className={
                        "rounded-xl text-[15px] font-bold bg-[#E5E5E5] px-3 py-1 cursor-pointer"
                      }
                    >
                      X
                    </div>
                  </div>
                  {showApiErr && (
                    <p className="italic text-center text-red-500">
                      {approveStockRequestErr ||
                        approvePurchaseOrderErr ||
                        approveStockReceiptErr ||
                        approveStockTransferErr ||
                        approveTransferReceiptErr}
                    </p>
                  )}
                  <div className="flex">
                    <div
                      className="bg-[#E5E5E5] rounded-md  bg-no-repeat bg-center p-6 mr-3"
                      style={{
                        backgroundImage: `url("${CheckImage}")`,
                      }}
                    ></div>

                    <div>
                      <p className={"font-medium "}>Sure you want to accept?</p>
                      <p
                        className={"text-[#54595E]"}
                      >{`Are you sure you want to accept this ${type}`}</p>
                    </div>
                  </div>
                  <div className="flex justify-center my-2">
                    <button
                      className="rounded bg-[#61C876] px-3 py-1 text-white hover:font-bold flex items-center justify-between"
                      onClick={confirmationFunc}
                    >
                      {" "}
                      Yes, Confirm
                      <Spinner
                        className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                        status={
                          approveStockRequestStatus === FETCH_STATUS.PENDING ||
                          approvePurchaseOrderStatus === FETCH_STATUS.PENDING ||
                          approveStockReceiptStatus === FETCH_STATUS.PENDING ||
                          approveStockTransferStatus === FETCH_STATUS.PENDING ||
                          approveTransferReceiptStatus === FETCH_STATUS.PENDING
                            ? FETCH_STATUS.PENDING
                            : FETCH_STATUS.IDLE
                        }
                      />
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export { AcceptanceConfirmation };
