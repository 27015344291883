import {useEffect, useRef, useState} from "react";
import {
  ExportExcelBtn,
  InputErr,
  PdfButton,
  Pdiv,
  Pdiv2,
  PrivateRoute,
  Spinner,
  TransactionsByUserTable,
  TransactionsByUserTable2,
} from "../../../components";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Element, scroller} from "react-scroll";
import {useDispatch, useSelector} from "react-redux";
import DatePicker from "react-datepicker";
import {DateTimeInput} from "../../../components/Films/DateTimeInput";
import {useLoggedInuser} from "../../../hooks";
import {getOrdersByUser, getStaff, reportData, selectStaff,} from "../../../redux/slices";
import {FETCH_STATUS, formatDateForApi, refundsReportSchema} from "../../../utils";
import addHours from "date-fns/addHours";

const TransactionsByUserReport = () => {
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { ordersByUser, ordersByUserStatus } = useSelector(reportData);
  const { orderedStaff } = useSelector(selectStaff);
  const { profile } = useLoggedInuser();
  const dispatch = useDispatch();
  const reportRef = useRef(null);

  const {
    register,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(refundsReportSchema),
    defaultValues: {
      userId: "",
      dateFilter: selectedDate,
      // CinemaId: profile.cinemaId,
    },
  });

  useEffect(() => {
    setValue("dateFilter", selectedDate);
    dispatch(getStaff({ force: true, cinemaId: profile?.cinemaId }));
  }, [selectedDate, setValue, dispatch, profile]);

  const formData = getValues();
  const onSubmit = (data) => {
    dispatch(
      getOrdersByUser({
        ...data,
        dateFilter: addHours(new Date(data.dateFilter), 1).toISOString(),
        force: true,
      })
    );
    scroller.scrollTo("reportContainer", {
      duration: 1500,
      delay: 0,
      smooth: true,
      offset: -100, // Scrolls to element + 50 pixels down the page
    });
  };

 
  return (
    <PrivateRoute redirectTo={"/dashboard/reports/transactions-by-user"}>
      <div className="w-[95%] mx-auto mt-6">
        <p className="font-semi-bold px-3 pb-5">Transactions by user</p>
        <div className="flex items-center justify-between p-4 px-8 h-[50px] rounded-t-lg bg-[#C96FCC]">
          <div className="flex gap-4">
            <span className="text-xl font-semibold text-white"></span>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex items-center justify-end py-3 px-4 bg-white ">
            <div className="flex gap-3 buttongroup ">
              <button
                className="p-1 px-3 rounded-md text-[#C96FCC] bg-[#ffe583] shadow-md hover:shadow-sm"
                type="button"
                onClick={() => {
                  navigate("/dashboard/reports");
                }}
              >
                Cancel
              </button>
              <button
                className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm flex items-center"
                type="submit"
              >
                <span> Run Report </span>
                <Spinner
                  className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                  status={ordersByUserStatus}
                />
              </button>
            </div>
          </div>
          <div className="bg-app-purple-2 p-3 pt-[30px]">
            <Pdiv label={"Select Date"}>
              <DatePicker
                placeholderText="Select Date"
                selected={new Date(selectedDate)}
                onChange={(value) => setSelectedDate(formatDateForApi(new Date(value)))}
                customInput={
                  <DateTimeInput
                    className="bg-app-purple-8"
                    dateFormat={"EEE, MMM dd, yyyy"}
                  />
                }
              />
            </Pdiv>
            <Pdiv2 label={"User"} name={"userId"} {...register("userId")}>
              <option value={""}>Select User</option>
              {orderedStaff?.map((singleStaff) => {
                return (
                  <option value={singleStaff.id}>
                    {singleStaff.firstName + " " + singleStaff.lastName}
                  </option>
                );
              })}
            </Pdiv2>
            <InputErr err={errors?.userId?.message} />
          </div>
        </form>

        <Element name="reportContainer">
          {ordersByUser?.pagedList?.length > 0 &&
            ordersByUserStatus !== FETCH_STATUS.REJECTED && (
              <>
                <div className="flex justify-center mt-5 space-x-4">
                  <PdfButton
                    refValue={reportRef}
                    docTitle={"transaction-by-user"}
                  />
                  <ExportExcelBtn
                    id={"t-byUser"}
                    name={"transactionsByUser.xlsx"}
                  />
                </div>
                <div className="hidden">
                  <div ref={reportRef}>
                    <TransactionsByUserTable2
                      id={"t-byUser"}
                      selectedDate={selectedDate}
                    />
                  </div>
                </div>
                <div>
                  <TransactionsByUserTable
                    funcProps={{
                      ...formData,
                      force: true,
                      dateFilter: addHours(
                        new Date(formData.dateFilter),
                        1
                      ).toISOString(),
                    }}
                  />
                </div>
              </>
            )}
          {ordersByUserStatus === FETCH_STATUS.REJECTED && (
            <p className="text-center text-red-500 py-3">
              Seems an error occured, Kindly refresh and try again
            </p>
          )}
          {ordersByUser?.pagedList?.length < 1 && (
            <p className="text-center py-3">No data found</p>
          )}
        </Element>
      </div>
    </PrivateRoute>
  );
};
export { TransactionsByUserReport };
