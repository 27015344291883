import React from "react";

import { ScrollToTop } from "./ScrollToTop";
import { useAuth } from "../hooks";

const PrivateRoute = ({ children, redirectTo = "/dashboard" }) => {
  const { valid, loading } = useAuth(redirectTo);

  if (valid && !loading) {
    return <ScrollToTop>{children}</ScrollToTop>;
  }

  return <></>;
};

export { PrivateRoute };
