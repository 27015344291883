import React, {useCallback, useEffect, useRef} from "react";
import {Element, scroller} from "react-scroll";
import {useNavigate} from "react-router-dom";
import {
    ErrorWithWrapper,
    ExportExcelBtn,
    FormGroup,
    InlineErr,
    Label,
    PdfButton,
    Pdiv,
    PrivateRoute,
    SearchSelect,
    Spinner,
    Success,
} from "../../../components";
import DatePicker from "react-datepicker";
import {DateTimeInput} from "../../../components/Films/DateTimeInput";
import {Controller, useForm} from "react-hook-form";
import {useLoggedInuser} from "../../../hooks";
import {
    contractReportInit,
    contractReportSchema,
    FETCH_STATUS,
    formatDateForApi,
    getSelectedItem2,
} from "../../../utils";
import {yupResolver} from "@hookform/resolvers/yup";
import {getContractReports, getDistributors, reportData, selectContract,} from "../../../redux/slices";
import {useDispatch, useSelector} from "react-redux";
import ContractReportTable from "../../../components/FilmContract/contractReportTable";
import {useReactToPrint} from "react-to-print";
import ContractReportPrint from "../../../components/FilmContract/contractReportPrint";

const FilmHireReport = () => {
  const { contractReports, contractReportsStatus, contractReportsErr } =
    useSelector(selectContract);
  const { distributorsList } = useSelector(reportData);

  const reportRef = useRef(null);

  const { profile } = useLoggedInuser();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDistributors(true));
  }, []);

  const {
    watch,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    resolver: yupResolver(contractReportSchema),
    defaultValues: { ...contractReportInit, cinemaId: profile?.cinemaId },
  });

  const from = watch("dateFrom");
  const to = watch("dateTo");
  const distributor = watch("distributorId");

  const getInfo = useCallback(() => {
    dispatch(
      getContractReports({
        dateFrom: from,
        dateTo: to,
        distributorId: distributor,
        cinemaId: profile?.cinemaId,
      })
    );
  }, [dispatch, distributor, profile, from, to]);

  const handleReport = async (values) => {
    const body = {
      dateFrom: formatDateForApi(new Date(values.dateFrom)),
      dateTo: formatDateForApi(new Date(values.dateTo)),
      distributorId: values.distributorId,
      cinemaId: values.cinemaId,
    };
    try {
      await dispatch(getContractReports(body)).then(() => {
        scroller.scrollTo("reportContainer", {
          duration: 1500,
          delay: 0,
          smooth: true,
          offset: -100, // Scrolls to element + 50 pixels down the page
        });
      });
    } catch (error) {}
  };
  const handlePrint = useReactToPrint({
    content: () => reportRef.current,
    documentTitle: "contract-report",
  });
  return (
    <>
      <PrivateRoute redirectTo={"/dashboard/reports/filmhire-report"}>
        <div className="w-[95%] mx-auto mt-6" id={"parentContainer"}>
          <p className="font-semi-bold px-3 pb-5">
            Film Hire Report - Set Report Parameters
          </p>
          <div className="flex items-center justify-between p-4 px-8 h-[50px] rounded-t-lg bg-[#C96FCC]">
            <div className="flex gap-4">
              <span className="text-xl font-semibold text-white"></span>
            </div>
          </div>
          <form onSubmit={handleSubmit(handleReport)}>
            <div className="flex items-center justify-end py-3 px-4 bg-white ">
              <div className="flex gap-3 buttongroup ">
                <button
                  className="p-1 px-3 rounded-md text-[#C96FCC] bg-[#ffe583] shadow-md hover:shadow-sm"
                  type="button"
                  onClick={() => {
                    navigate("/dashboard/reports");
                  }}
                >
                  Cancel
                </button>
                <button
                  className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm flex items-center"
                  type="submit"
                >
                  <span> Run Report </span>
                  <Spinner
                    className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                    status={contractReportsStatus}
                  />
                </button>
              </div>
            </div>
            <div className="bg-app-purple-2 p-3 pt-[30px]">
              <Pdiv label={"Date Range"}>
                <div className="flex items-center">
                  <Controller
                    control={control}
                    name="dateFrom"
                    render={({ field: { value, onChange } }) => (
                      <div className="relative ">
                        <DatePicker
                          placeholderText="Start Date"
                          selected={new Date(value)}
                          onChange={(value) => onChange(new Date(value))}
                          customInput={
                            <DateTimeInput
                              className="bg-app-purple-8"
                              dateFormat={"EEE, MMM dd, yyyy"}
                            />
                          }
                        />
                      </div>
                    )}
                  />

                  <p className="w-[6rem] text-center"> To:</p>
                  <Controller
                    control={control}
                    name="dateTo"
                    render={({ field: { value, onChange } }) => (
                      <div className="relative ">
                        <DatePicker
                          placeholderText="Start Date"
                          selected={new Date(value)}
                          onChange={(value) => onChange(new Date(value))}
                          customInput={
                            <DateTimeInput
                              className="bg-app-purple-8"
                              dateFormat={"EEE, MMM dd, yyyy"}
                            />
                          }
                        />
                      </div>
                    )}
                  />
                </div>
              </Pdiv>
              <FormGroup className={"mb-[30px] relative"}>
                <Label className={"w-[23rem] mr-[30px]"}>
                  Select Distributor:
                </Label>

                <Controller
                  control={control}
                  name={"distributorId"}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <SearchSelect
                        isDefault={true}
                        defaultValue={"allDistributors"}
                        defaultName={"All Distributors"}
                        items={distributorsList}
                        dropDownNameKey="name"
                        inputClassName="!w-full p-2 px-4 py-2 border-none !bg-app-purple-10"
                        listClassName="!py-2"
                        id={`distributorId`}
                        value={getSelectedItem2(distributorsList, value, "id")}
                        onChange={(value) =>
                          onChange(value?.id ?? "allDistributors")
                        }
                        displayValue={(value) => {
                          return value?.name ?? "All Distributors";
                        }}
                        placeholder={`Select Distributor`}
                        searchOptions={{
                          keys: ["name"],
                        }}
                      />
                    );
                  }}
                />
                <InlineErr
                  err={errors?.distributorId?.message}
                  className={"left-[25rem]"}
                />
              </FormGroup>
            </div>
          </form>
        </div>

        <ErrorWithWrapper
          error={contractReportsErr}
          show={contractReportsStatus === FETCH_STATUS.REJECTED}
          retry={getInfo}
        />

        <Success show={contractReportsStatus === FETCH_STATUS.RESOLVED}>
          {contractReports?.result?.report?.length && (
            <div ref={reportRef} className="hidden print-source print:block">
              <ContractReportPrint
                data={contractReports?.result.report}
                total={contractReports?.result?.total}
                paramFilter={contractReports?.paramFilter}
              />
            </div>
          )}
        </Success>
        {/* <ExportExcelBtn id={"contract-xlsx"} name={"film-contract.xlsx"} /> */}
        <Success show={contractReportsStatus === FETCH_STATUS.RESOLVED}>
          <Element name="reportContainer">
            {contractReports?.result?.report?.length ? (
              <div>
                <div className="flex justify-center mt-5 space-x-4">
                  <PdfButton refValue={reportRef} docTitle={"film-contract"} />
                  <ExportExcelBtn
                    id={"contract-xlsx"}
                    name={"film-contract.xlsx"}
                  />
                </div>
                <ContractReportTable
                  data={contractReports?.result.report}
                  total={contractReports?.result?.total}
                  paramFilter={contractReports?.paramFilter}
                />
                <div className="hidden">
                  <ContractReportTable
                    id={"contract-xlsx"}
                    data={contractReports?.result.report}
                    total={contractReports?.result?.total}
                    paramFilter={contractReports?.paramFilter}
                  />
                </div>
              </div>
            ) : (
              <p className="text-red-700 flex justify-center mt-3">
                No contract report available
              </p>
            )}
          </Element>
        </Success>
      </PrivateRoute>
    </>
  );
};

export { FilmHireReport };
