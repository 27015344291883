import React from "react";

const Sessions = ({ fill = process.env.REACT_APP_COLOR_10 }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9998 7.99984C15.8247 7.99979 15.6513 8.03425 15.4895 8.10124C15.3278 8.16823 15.1808 8.26644 15.0569 8.39026C14.9331 8.51408 14.8349 8.66108 14.7679 8.82287C14.7009 8.98466 14.6665 9.15806 14.6665 9.33317V15.1782L11.4256 16.8091C11.2693 16.8877 11.1299 16.9964 11.0155 17.1289C10.9012 17.2614 10.814 17.4152 10.7591 17.5814C10.7041 17.7476 10.6824 17.9229 10.6953 18.0975C10.7081 18.2721 10.7552 18.4424 10.8338 18.5988C10.9125 18.7552 11.0212 18.8945 11.1537 19.0089C11.2862 19.1233 11.4399 19.2104 11.6061 19.2654C11.7723 19.3203 11.9477 19.342 12.1223 19.3292C12.2968 19.3163 12.4672 19.2693 12.6235 19.1906L16.5988 17.1906C16.8196 17.0798 17.0053 16.9097 17.135 16.6994C17.2647 16.4891 17.3333 16.2469 17.3332 15.9998V9.33317C17.3332 9.15806 17.2988 8.98466 17.2318 8.82287C17.1648 8.66108 17.0666 8.51408 16.9428 8.39026C16.8189 8.26644 16.6719 8.16823 16.5101 8.10124C16.3484 8.03425 16.175 7.99979 15.9998 7.99984ZM15.9998 2.6665C13.3628 2.6665 10.7849 3.44849 8.59224 4.91358C6.39958 6.37866 4.69062 8.46104 3.68145 10.8974C2.67228 13.3337 2.40824 16.0146 2.92271 18.601C3.43718 21.1875 4.70705 23.5632 6.57175 25.4279C8.43645 27.2926 10.8122 28.5625 13.3986 29.077C15.9851 29.5914 18.6659 29.3274 21.1023 28.3182C23.5386 27.3091 25.621 25.6001 27.0861 23.4074C28.5512 21.2148 29.3332 18.6369 29.3332 15.9998C29.3291 12.4649 27.9231 9.07582 25.4235 6.57621C22.9239 4.0766 19.5348 2.67055 15.9998 2.6665ZM15.9998 26.6665C13.8902 26.6665 11.8279 26.0409 10.0738 24.8688C8.31964 23.6968 6.95246 22.0309 6.14513 20.0818C5.33779 18.1327 5.12656 15.988 5.53813 13.9189C5.94971 11.8497 6.96561 9.94913 8.45737 8.45737C9.94913 6.96561 11.8497 5.9497 13.9189 5.53813C15.988 5.12655 18.1327 5.33779 20.0818 6.14512C22.0309 6.95246 23.6968 8.31963 24.8689 10.0738C26.0409 11.8279 26.6665 13.8902 26.6665 15.9998C26.6633 18.8278 25.5384 21.539 23.5387 23.5387C21.5391 25.5384 18.8278 26.6633 15.9998 26.6665Z"
        fill={fill}
      />
    </svg>
  );
};

export { Sessions };
