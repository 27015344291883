import { Dialog, Transition, Listbox, Combobox } from "@headlessui/react";
import { differenceInDays, parse } from "date-fns";
import { isEmpty } from "lodash";
import { useCallback } from "react";
import { forwardRef, Fragment, useEffect, useState } from "react";
import { formatDateTime } from "../../utils";
import { Spinner } from "../UI";
import CreateSessionErr from "./CreateSessionErr";
import { DuplicateAllForm } from "./DuplicateAllForm";

const DuplicateAllSessions = ({
  show,
  date,
  state,
  onClose = () => {},
  duplicateAllStatus,
  addingErr,
  getInfo,
  onDuplicateAll,
  sessionLoaded,
  error,
  setError,
  resetErrs,
  ticketTypes = [],
  priceCardsErr,
  priceCardError,
  allEvents,
  duplicatingAll,
}) => {
 
  // dayEvents is the sessions from the day we are duplicating from
  const [dayEvents, setEvents] = useState([]);
  const [ticketType, setTicketType] = useState("");

  const handleDuplicate = (data) => {
    const selectedDays = data;
    onDuplicateAll(dayEvents, selectedDays);
  };

 

  return (
    <Transition show={show} as={Fragment}>
      <Dialog as="div" className="relative z-[1000000]" onClose={() => {}}>
        {/* overlay */}

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        {/* content */}

        <div className="fixed inset-0 p-4 overflow-y-auto">
          <div className="flex items-center justify-center min-h-[400px] p-2 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full min-h-[400px] max-w-2xl p-6 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl ">
                <Dialog.Title className="relative pb-4 border-b" as="div">
                  <p className="text-lg font-medium leading-6 text-gray-900">
                    Duplicate All Sessions
                  </p>
                  <button
                    type="button"
                    onClick={onClose}
                    className="absolute right-0 top-2/4 -translate-y-2/4 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </Dialog.Title>
                {/* <form onSubmit={handleSubmit}> */}
                <h4 className="mt-5 text-base">
                  Select Duplication Dates and Price Card
                </h4>
                <p className="text-red-500 italic">
                  Pricecard selection on a day(s) you want to duplicate to is no
                  longer compulsory. With this, the showtimes on that day would
                  simply retain their existing pricecard .
                </p>
                <hr className="my-2" />
                <DuplicateAllForm
                  // setDateTo={onDateChangeTo}
                  duplicatingAll={duplicatingAll}
                  show={show}
                  // dateTo={dateTo}
                  // dateFrom={dateFrom}
                  // setDateFrom={onDateChangeFrom}
                  ticketTypes={ticketTypes}
                  priceCardsErr={priceCardsErr}
                  priceCardError={priceCardError}
                  setTicketType={setTicketType}
                  ticketType={ticketType}
                  duplicateAllStatus={duplicateAllStatus}
                  handleDuplicate={handleDuplicate}
                  getShowtimes={getInfo}
                  setEvents={setEvents}
                />
                <CreateSessionErr
                  onDismiss={() => setError([])}
                  errors={error}
                />
                <CreateSessionErr
                  onDismiss={resetErrs}
                  errors={
                    !isEmpty(addingErr)
                      ? [
                          {
                            id: 0,
                            message: priceCardError
                              ? "Price card is required"
                              : addingErr,
                          },
                        ]
                      : []
                  }
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export { DuplicateAllSessions };
