import classNames from "classnames";
import { useMemo } from "react";
import { useGlobalFilter, useTable } from "react-table";
import { dateAndTime, formatDateTime, formatNumberAsCurrency } from "../../utils";
import ReachLogo from "../../assets/images/logo.png"
import styles from "./contractReport.module.css";
import { useLoggedInuser } from "../../hooks";

const ContractReportPrint = ({ data, paramFilter, total }) => {

  const {profile} = useLoggedInuser()
  const columns = useMemo(() => {
    return [
      {
        Header: "Film",
        accessor: "film",
        Footer: `TOTAL`
      },
      {
        Header: "Week",
        accessor: "week",
      },
      {
        Header: "Week Start Date",
        accessor: "weekStartDate",
        Cell: ({ value }) =>
          formatDateTime(new Date(value), "EEE, MMM dd, yyyy"),
      },
      {
        Header: "Film Date Created",
        accessor: "filmDateCreated",
        Cell: ({ value }) =>
          formatDateTime(new Date(value), "EEE, MMM dd, yyyy"),
      },
      {
        Header: "No of Shows",
        accessor: "numOfShows",
      },
      {
        Header: "Admits",
        accessor: "admits",
      },
      {
        Header: "GBO",
        Footer: `${formatNumberAsCurrency(total.gboTotal)}`,
        accessor: "gbo",
        Cell: ({ value }) => {
          return formatNumberAsCurrency(value);
        },
      },
      {
        Header: "Tax %",
        accessor: "taxPerc",
        Cell: ({ value }) => {
          return value.toFixed(2);
        },
      },
      {
        Header: "Tax Box Office",
        accessor: "taxBoxOffice",
        Cell: ({ value }) => {
          return formatNumberAsCurrency(value.toFixed(2));
        },
      },
      {
        Header: "Net Box Office",
        accessor: "netBoxOffice",
        Cell: ({ value }) => {
          return formatNumberAsCurrency(value.toFixed(2));
        },
      },
      {
        Header: "Royalty Percentage %",
        accessor: "royaltyPerc",
        Cell: ({ value }) => {
          return value.toFixed(2);
        },
      },
      {
        Header: "Cinema Amount",
        Footer: `${formatNumberAsCurrency(total.cinemaTotal.toFixed(2))}`,
        accessor: "cinemaAmount",
        Cell: ({ value }) => {
          return formatNumberAsCurrency(value.toFixed(2));
        },
      },
      {
        Header: "Royalty Amount",
        accessor: "royaltyAmount",
        Cell: ({ value }) => {
          return formatNumberAsCurrency(value.toFixed(2));
        },
      },
      {
        Header: "WHT Percentage %",
        accessor: "whtPerc",
        Cell: ({ value }) => {
          return value.toFixed(2);
        },
      },
      {
        Header: "WHT Amount",
        accessor: "whtAmount",
        Cell: ({ value }) => {
          return formatNumberAsCurrency(value.toFixed(2));
        },
      },
      {
        Header: "Net Payable",
        Footer: `${formatNumberAsCurrency(total.netPayableTotal)}`,
        accessor: "netPayable",
        Cell: ({ value }) => {
          return formatNumberAsCurrency(value);
        },
      },
    ];
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );

  const { globalFilter } = state;

  return (
    <>
      <div className="flex items-center justify-between">
        <div
          key={ReachLogo}
          className=" w-[5rem] h-[5rem]  bg-no-repeat bg-contain bg-center "
          style={{
            backgroundImage: `url("${ReachLogo}")`,
          }}
        ></div>
        <p className="font-bold text-[20px] phone-mini:text-[1rem] phone-lg:text-[20px] mr-1  ">
          Film Hire Report
        </p>
        <p>{paramFilter.distributor}</p>
      </div>
      <div className=" flex p-2 justify-between">
        <div>
          <p>
            {" "}
            <span className="font-bold">User Name:</span>
            {` ${profile?.firstName} ${profile?.lastName}`}
          </p>
          <p>
            <span className="font-bold">Date Time Generated: </span>
            {formatDateTime(
              paramFilter?.dateTimeGenerated,
              dateAndTime
            )}
          </p>
        </div>
        <div>
          <p className="font-bold">Data Range</p>
          <p>
            {new Date(paramFilter?.dateFrom).toUTCString().substring(0, 16)}
          </p>{" "}
          <p>{new Date(paramFilter?.dateTo).toUTCString().substring(0, 16)}</p>
        </div>
      </div>
      <div className="relative shadow-md sm:rounded-sm">
        <table
          {...getTableProps()}
          className={classNames(
            "w-full mx-auto mt-8 overflow-hidden text-left border rounded-md border-app-purple-4"
            // styles.table
          )}
        >
          <thead className="shadow-2xl bg-app-purple-8">
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, i) => (
                  <th
                    {...column.getHeaderProps()}
                    className="px-3 py-3 font-normal tracking-wide uppercase border border-gray-300 text-md"
                    key={i}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="text-[#534C7E]">
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={index}
                  className="transition duration-300 border bg-app-purple-2 border-app-purple-4 border-opacity-30"
                >
                  {row.cells.map((cell, i) => {
                    return (
                      <td
                        data-label={cell.column.Header}
                        {...cell.getCellProps()}
                        className="py-3 min-w-min px-3"
                        key={i}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            {footerGroups.map((footerGroup, i) => (
              <tr
                key={i}
                {...footerGroup.getFooterGroupProps()}
                className="bg-app-purple-8 border-b"
              >
                {footerGroup.headers.map((column, i) => (
                  <td
                    key={i}
                    {...column.getFooterProps}
                    className="px-3 py-3 text-[#534C7E] min-w-min"
                  >
                    {column.render("Footer")}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>
    </>
  );
};

export default ContractReportPrint;
