import React from "react";

const KioskPageContent = ({
  selectedPage,
  fields,
  removePage,
  addItem,
  currentPage,
  allPages,
  currentPageIndex,
  setValue,
  getValues,
  renderChildren,
}) => {
  const pageData = allPages.find((instance, pageIndex) => {
    
    return selectedPage.currentPage === pageIndex;
  });

  const pageIndex = selectedPage.currentPage;

  if (!pageData) {
    return <></>;
  }

 
  return (
    <>
      {pageData.id === currentPage.id && (
        <>
          <div className=" border-4 border-[#C96FCC] my-4 p-4 w-full">
            <div className=" pb-3.5">
              <label
                htmlFor={`pageName`}
                className="block mb-2 text-md font-bold text-[#666666] "
              >
                Page Name
              </label>
              <input
                id={`pageName`}
                type="text"
                // value={page.pageName}
                value={getValues(`kioskLayout.${currentPageIndex}.pageName`)}
                onChange={(e) => {
                  setValue(
                    `kioskLayout.${currentPageIndex}.pageName`,
                    e.target.value
                  );
                }}
                className="block w-[7rem] md:w-[15rem] p-2 h-[40px] text-gray-900 bg-[#d0a3d3] border border-gray-300 rounded-lg sm:text-xs focus:ring-blue-500 focus:border-blue-500 "
              />
            </div>
            <button
              className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm"
              type="button"
              onClick={() => {
                addItem({ pageIndex });
              }}
            >
              Add Item
            </button>
            <div className=" border-2 border-[#C96FCC] my-4 p-4 grid grid-cols-3 mx-auto">
              {renderChildren(pageData, pageIndex)}
            </div>
          </div>
          <div className="flex justify-end">
            <button
              className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm"
              type="button"
              onClick={() => {
                removePage({ pageIndex });
              }}
            >
              Remove Page
            </button>
          </div>
        </>
      )}
    </>
  );
};

export { KioskPageContent };
