import { useMemo, useEffect, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { reportData, getOrdersByUser, refundOrder } from "../../redux/slices";
import format from "date-fns/format";
import {
  PrivateRoute,
  ClientPaginatedTable,
  ErrorWithWrapper,
  Loading,
  Success,
  DefaultColumnFilter,
} from "..";

import { FETCH_STATUS, formatNumberAsCurrency } from "../../utils";
import { TransactionDetailsModal } from "./TransactionDetailsModal";
import { FullRefundModal } from "./RefundModals/FullRefundModal";
import { PartialRefundModal } from "./RefundModals/PartialRefundModal";
const TransactionsByUserTable = ({ funcProps, id }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [showFullRefundModal, setShowFullRefundModal] = useState(false);
  const [showPartialRefundModal, setShowPartialRefundModal] = useState(false);
  const [orderData, setOrderData] = useState({});

  const { ordersByUser, ordersByUserStatus, ordersByUserErr } =
    useSelector(reportData);

  const getInfo = useCallback(
    (force) => {
      dispatch(getOrdersByUser(funcProps));
    },
    [dispatch, funcProps]
  );

  const columns = useMemo(() => {
    return [
      {
        Header: "Transaction Id",
        accessor: "id",
        // accessor: "transactionId",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Offline Sale Id",
        accessor: "offlineId",
        Cell: ({ value }) => <>{value ? value : ""}</>,
      },
      {
        Header: "Staff Name",
        accessor: "user",
      },
      {
        Header: "Total",
        accessor: "amount",
        Cell: ({ value }) => formatNumberAsCurrency(value),
      },
      {
        Header: "Time",
        accessor: "dateCreated",
        Cell: ({ value }) => format(new Date(value), "HH:mm:ss"),
      },
      {
        Header: "Has Refunds",
        accessor: "hasBeenRefunded",
        Cell: ({ value, row }) => {
          return value ? (
            <p className="text-center">{"Yes"}</p>
          ) : (
            <p className="text-center">{"No"}</p>
          );
        },
      },
      {
        Header: "Offline Sale",

        Cell: ({ value, row }) => {
          return row?.original?.offlineId ? (
            <p className="text-center">{"Yes"}</p>
          ) : (
            <p className="text-center">{"No"}</p>
          );
        },
      },
      {
        Header: "Payment Channels",
        accessor: "paymentChannels",
        Cell: ({ value }) => {
          let channels = "";
          value?.map((channel) => {
            return (channels =
              channels +
              `${channel.channel}: ${formatNumberAsCurrency(
                channel.amount
              )}  `);
          });

          return channels;
        },
      },
      {
        Header: "Actions",
        Cell: ({ row }) => {
          return (
            <div className="flex justify-around py-3">
              <button
                className="p-1 px-2 mx-1 rounded-md text-[#ffe583] bg-app-purple-4 shadow-md hover:shadow-sm"
                type="button"
                onClick={() => {
                  setOrderData({ ...row?.original, showsRefund: false });
                  setShowModal(true);
                }}
              >
                View Order
              </button>
              {row?.original?.hasBeenRefunded && (
                <button
                  className="p-1 px-2 mx-1 rounded-md text-[#ffe583] bg-app-purple-4 shadow-md hover:shadow-sm"
                  type="button"
                  onClick={() => {
                    setOrderData({
                      id: row?.original?.id,
                      hasBeenRefunded: false,
                      showsRefund: true,
                    });
                    setShowModal(true);
                  }}
                >
                  View Refund
                </button>
              )}
            </div>
          );
        },
      },
    ];
  }, []);

  return (
    <>
      <div className="mt-6">
        <ErrorWithWrapper
          error={ordersByUserErr}
          show={ordersByUserStatus === FETCH_STATUS.REJECTED}
          retry={getInfo}
        />

        <Loading
          wrapperClassName="relative min-h-[50vh]"
          show={ordersByUserStatus === FETCH_STATUS.PENDING}
        />

        <Success show={ordersByUserStatus === FETCH_STATUS.RESOLVED}>
          {/* Main Table */}
          <div className={"overflow-x-auto"}>
            <ClientPaginatedTable
              filterColumnsKeys={["id"]}
              onRowClick={() => {}}
              rowClass={"hover:bg-app-purple-2"}
              tableData={ordersByUser.pagedList}
              columns={columns}
              getHeaderProps={(column) => ({
                className:
                  "px-3 pt-8 pb-2 text-base font-medium border border-[#00000033] ",
                ...(column.id === "name" ? column?.getSortByToggleProps() : {}),
              })}
              getCellProps={(cellInfo) => ({
                style: {},
                className: `${
                  cellInfo?.column?.id === "" ? "text-app-purple-9" : ""
                } ${cellInfo?.column?.id === "days" ? "break-all" : ""} `,
              })}
              getColumnProps={() => ({ style: {} })}
            />
          </div>
        </Success>
        {showModal && (
          <TransactionDetailsModal
            funcProps={funcProps}
            orderData={orderData}
            show={showModal}
            onClose={() => {
              setShowModal(false);
              setOrderData({});
            }}
            showFullRefund={() => {
              setShowFullRefundModal(true);
            }}
            showPartialRefund={() => {
              setShowPartialRefundModal(true);
            }}
          />
        )}
      </div>
    </>
  );
};

export { TransactionsByUserTable };
