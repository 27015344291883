import React from "react";

const WithAuth = ({ children }) => {
  return (
    <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 mode-week-grid">
      <div className="max-w-md w-full space-y-8">{children}</div>
    </div>
  );
};

export { WithAuth };
