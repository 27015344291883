import { useMemo, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { reportData } from "../../redux/slices";
import {
  PrivateRoute,
  ClientPaginatedTable,
  ErrorWithWrapper,
  Loading,
  Success,
  DefaultColumnFilter,
} from "..";

import { FETCH_STATUS, formatNumberAsCurrency } from "../../utils";

const DistributorByFilmTable = ({ getInfo }) => {
  const {
    distributorByFilmReport,
    distributorByFilmReportStatus,
    distributorByFilmReportErr,
  } = useSelector(reportData);

  // const getInfo = useCallback(
  //   (force) => {
  //     dispatch(getItems(force));
  //   },
  //   [dispatch]
  // );

  // useEffect(() => {
  //   if (distributorByFilmReportStatus === FETCH_STATUS.IDLE) {
  //     dispatch(getItems());
  //   }
  // }, [dispatch, distributorByFilmReportStatus]);

  const filteredData = useMemo(() => {
    if (distributorByFilmReport.result) {
      return distributorByFilmReport.result;
    } else {
      return [];
    }
  }, [distributorByFilmReport]);

  const columns = useMemo(() => {
    return [
      {
        Header: "Film",
        accessor: "film",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Sales",
        accessor: "quantity",
      },
      {
        Header: "Admits",
        accessor: "admits",
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }) => formatNumberAsCurrency(value),
      },
      {
        Header: "Tax",
        accessor: "tax",
        Cell: ({ value }) => formatNumberAsCurrency(value.toFixed(2)),
      },
      {
        Header: "Net Total",
        accessor: "netTotal",
        Cell: ({ value }) => formatNumberAsCurrency(value.toFixed(2)),
      },
      {
        Header: "Gross Total",
        accessor: "grossTotal",
        Cell: ({ value }) => formatNumberAsCurrency(value.toFixed(2)),
      },

      // {
      //   Header: "Default Price",
      //   accessor: "price",
      //   Cell: ({ value }) => formatNumberAsCurrency(value),
      // },
    ];
  }, []);

  return (
    <PrivateRoute redirectTo="/dashboard/reports/distributor-by-film">
      <div className="w-[95%] mx-auto mt-6">
        {/* Table Header */}
        <div className="flex items-center justify-between p-4 px-8 rounded-t-lg bg-gradient-to-b from-app-purple-4 to-app-purple-3">
          <div className="flex gap-4">
            <span className="text-xl font-semibold text-white">
              Distributor By Film Report
            </span>
          </div>
        </div>

        <ErrorWithWrapper
          error={distributorByFilmReportErr}
          show={distributorByFilmReportStatus === FETCH_STATUS.REJECTED}
          retry={getInfo}
        />

        <Loading
          wrapperClassName="relative min-h-[50vh]"
          show={distributorByFilmReportStatus === FETCH_STATUS.PENDING}
        />

        <Success show={distributorByFilmReportStatus === FETCH_STATUS.RESOLVED}>
          {/* Main Table */}
          <ClientPaginatedTable
            filterColumnsKeys={["film"]}
            toolbarChildren={
              <div className="flex justify-between p-2">
                <div>
                  <p>Distributor</p>
                  <p>{distributorByFilmReport?.paramFilter?.distributor}</p>
                </div>{" "}
                <div>
                  <p>Film</p>{" "}
                  <p>{distributorByFilmReport?.paramFilter?.film}</p>
                </div>
                <div>
                  <p className="font-bold">Data Range</p>
                  <p>
                    {new Date(distributorByFilmReport?.paramFilter?.dateFrom)
                      .toUTCString()
                      .substring(0, 16)}
                  </p>{" "}
                  <p>
                    {new Date(distributorByFilmReport?.paramFilter?.dateTo)

                      .toUTCString()
                      .substring(0, 16)}
                  </p>
                </div>
              </div>
            }
            // onRowClick={onRowClick}
            tableData={filteredData}
            columns={columns}
            getHeaderProps={(column) => ({
              className:
                "px-3 pt-8 pb-2 text-base font-medium border border-[#00000033] ",
              ...(column.id === "film" ? column?.getSortByToggleProps() : {}),
            })}
            getCellProps={(cellInfo) => ({
              style: {},
              className: `${
                cellInfo?.column?.id === "film" ? "text-app-purple-9" : ""
              } ${cellInfo?.column?.id === "days" ? "break-all" : ""} `,
            })}
            getColumnProps={() => ({ style: {} })}
          />
        </Success>
      </div>
    </PrivateRoute>
  );
};

export { DistributorByFilmTable };
