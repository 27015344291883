import React, { useMemo } from "react";
import { useTable } from "react-table";
import { formatNumberAsCurrency } from "../../utils";

const ConcessionsList = ({ items = [] }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Items",
        accessor: "item",
      },
      {
        Header: "Item Sales",
        accessor: "itemSales",
        Cell: ({ cell }) => {
          return formatNumberAsCurrency(cell?.value);
        },
      },
      { Header: "Qty", accessor: "itemSold" },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: items,
    });

  if (!items?.length) {
    return (
      <h4 className="m-4 text-base font-semibold text-center text-app-purple-4">
        No data
      </h4>
    );
  }

  return (
    <div className="overflow-x-hidden overflow-y-auto max-h-[500px] pr-3">
      <table
        className="w-full text-sm text-left text-gray-500 table-fixed"
        {...getTableProps()}
      >
        <thead className="text-lg text-gray-700 uppercase bg-app-purple-8">
          {headerGroups?.map((headerGroup) => (
            <tr {...headerGroup?.getHeaderGroupProps()}>
              {headerGroup?.headers?.map((column) => (
                <th className="px-6 py-3" {...column?.getHeaderProps()}>
                  {column?.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows?.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                className="text-base bg-white border-b odd:bg-white even:bg-app-purple-2"
                {...row?.getRowProps()}
              >
                {row?.cells?.map((cell) => {
                  return (
                    <td className="px-6 py-4" {...cell?.getCellProps()}>
                      {cell?.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export { ConcessionsList };
