import React, { useEffect,useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePicker from "react-datepicker";
import {
  ExportExcelBtn,
  FormGroup,
  Label,
  MultiSearchSelect,
  PdfButton,
  Pdiv,
  PrivateRoute,
  ReportWrapper,
  Success,
} from "../../../components";
import { useLoggedInuser } from "../../../hooks";
import {
  FETCH_STATUS,
  bookingByGenreInit,
  bookingByGenreSchema,
  getSelectedItem,
} from "../../../utils";
import { DateTimeInput } from "../../../components/Films/DateTimeInput";
import {
  filmsSlice,
  getBookingsByGenre,
  getGenre,
  reportData,
  selectFilms,
} from "../../../redux/slices";
import { Element, scroller } from "react-scroll";
import { useReactToPrint } from "react-to-print";
import { BookingsByGenreTable } from "../../../components/Reports/BookingsByGenreTable";

const BookingsByGenre = () => {
  const { profile } = useLoggedInuser();
  const dispatch = useDispatch();
  const { bookingsByGenre, bookingsByGenreStatus, bookingsByGenreErr } =
    useSelector(reportData);
  const { genres, getGenreStatus, getGenreErr } = useSelector(selectFilms);
  const reportRef = useRef(null);
  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(bookingByGenreSchema),
    defaultValues: { ...bookingByGenreInit, cinemaId: profile?.cinemaId },
  });

  

  useEffect(() => {
    if (getGenreStatus === FETCH_STATUS.IDLE) {
      
      dispatch(getGenre());
    }
  }, [dispatch, getGenreStatus]);

  const handleBookingByGenre = async (values) => {
    
    try {
      await dispatch(getBookingsByGenre(values)).then(() => {
        scroller.scrollTo("reportContainer", {
          duration: 1500,
          delay: 0,
          smooth: true,
          offset: -100, // Scrolls to element + 50 pixels down the page
        });
      });
    } catch (error) {}
  };

  return (
    <>
      <PrivateRoute redirectTo={"/dashboard/reports/bookings-by-genre"}>
        <div className="w-[95%] mx-auto mt-6">
          <ReportWrapper
            reportName="Bookings By Genre"
            goBackRoute={"/dashboard/reports"}
            submitFor={"bookingByGenre"}
            reportStatus={bookingsByGenreStatus}
          >
            <form
              onSubmit={handleSubmit(handleBookingByGenre)}
              id={"bookingByGenre"}
            >
              <Pdiv label={"Date Range"}>
                <div className="flex items-center">
                  <Controller
                    control={control}
                    name="dateFrom"
                    render={({ field: { value, onChange } }) => (
                      <div className="relative ">
                        <DatePicker
                          placeholderText="Start Date"
                          selected={new Date(value)}
                          onChange={(value) =>
                            onChange(new Date(value).toISOString())
                          }
                          customInput={
                            <DateTimeInput
                              className="bg-app-purple-8"
                              dateFormat={"EEE, MMM dd, yyyy"}
                            />
                          }
                        />
                      </div>
                    )}
                  />

                  <p className="w-[6rem] text-center"> To:</p>
                  <Controller
                    control={control}
                    name="dateTo"
                    render={({ field: { value, onChange } }) => (
                      <div className="relative ">
                        <DatePicker
                          placeholderText="End Date"
                          selected={new Date(value)}
                          onChange={(value) =>
                            onChange(new Date(value).toISOString())
                          }
                          customInput={
                            <DateTimeInput
                              className="bg-app-purple-8"
                              dateFormat={"EEE, MMM dd, yyyy"}
                            />
                          }
                        />
                      </div>
                    )}
                  />
                </div>
              </Pdiv>
              <FormGroup className={"mb-[20px] relative"}>
                <Label className={"w-[23rem] mr-[30px]"}>
                  Select Distributor:
                </Label>
                <Controller
                  control={control}
                  name="genreIds"
                  render={({ field: { value, onChange } }) => {
                    return (
                      <>
                        <MultiSearchSelect
                          items={genres}
                          id={"genreIds"}
                          value={getSelectedItem(genres, value, "id")}
                          inputClassName="mb-3 h-[35px] pl-2 w-[20rem]"
                          onChange={(value) => onChange(value.map((v) => v.id))}
                          displayValue={(value) =>
                            value.map((v) => v.name).join(", ")
                          }
                          placeholder={`Select Genre`}
                          searchOptions={{
                            keys: ["name"],
                          }}
                        />
                      </>
                    );
                  }}
                />
              </FormGroup>
            </form>
          </ReportWrapper>
        </div>
        <Success show={bookingsByGenreStatus === FETCH_STATUS.RESOLVED}>
          <Element name="reportContainer">
            {bookingsByGenre?.length > 0 ? (
              <>
                <div className="flex justify-center mt-5 space-x-4">
                  <PdfButton
                    refValue={reportRef}
                    docTitle={"booking-by-genre"}
                  />
                  <ExportExcelBtn
                    id={"bookingGenre-xlsx"}
                    name={"bookingsByGenre.xlsx"}
                  />
                </div>
                <div  className="m-2 hidden">
                  <BookingsByGenreTable
                    data={bookingsByGenre}
                    id={"bookingGenre-xlsx"}
                  />
                </div>
                <div ref={reportRef} className="m-2">
                  <BookingsByGenreTable data={bookingsByGenre} paramFilter={bookingsByGenre.paramFilter} />
                </div>
              </>
            ) : null}
          </Element>
          {!bookingsByGenre?.length && (
            <p className="text-red-700 flex justify-center mt-3">
              No Data Found
            </p>
          )}
        </Success>
        {bookingsByGenreStatus === FETCH_STATUS.REJECTED && (
          <p className="text-red-700 flex justify-center mt-3">
            Sorry an error occured...
          </p>
        )}
      </PrivateRoute>
    </>
  );
};

export { BookingsByGenre };
