import React, { useMemo } from "react";
import { useTable, useFilters, useSortBy, usePagination } from "react-table";
import { PAGINATION } from "../../../utils";
import PaginatePills from "./PaginatePills";
import { SearchAndStatusFilter } from "./SearchAndStatusFilter";

const ClientPaginatedTable = ({
  id,
  columns = [],
  filterColumnsKeys = ["name", "active"],
  tableData = [],
  perPage = PAGINATION.PAGE_SIZE,
  rowClass = "",
  onRowClick = () => {},
  toolbarChildren = null,
  getColumnProps = () => {},
  getHeaderProps = () => {},
  getRowProps = () => {},
  getCellProps = () => {},
  tableClass = "",
  toolBarWrapperClass = "",
  filtersWrapperClass = "",
  toolbarChildrenWrapperClass = "",
}) => {
  const data = useMemo(() => tableData, [tableData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      initialState: { pageSize: perPage, pageIndex: 0 },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  let filterColumns = useMemo(
    () =>
      data.length && filterColumnsKeys.length
        ? headerGroups[0].headers.filter((col) =>
            filterColumnsKeys.includes(col.id)
          )
        : [],
    [headerGroups, filterColumnsKeys, data.length]
  );

  if (!data.length) {
    return (
      <div>
        {toolbarChildren}
        <br />
        <h4 className="m-4 text-base font-semibold text-center text-app-purple-4">
          No data
        </h4>
      </div>
    );
  }

  return (
    <div>
      <SearchAndStatusFilter
        wrapperClass={toolBarWrapperClass}
        filtersWrapperClass={filtersWrapperClass}
        childrenWrapperClass={toolbarChildrenWrapperClass}
        columns={filterColumns}
      >
        {toolbarChildren}
      </SearchAndStatusFilter>

      <table
        className={`w-full text-sm   text-left border-collapse bg-app-purple-2 ${tableClass} `}
        {...getTableProps()}
        id={id}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr className="" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
                      className: column?.className,
                      style: column?.style,
                    },
                    getColumnProps(column),
                    getHeaderProps(column),
                  ])}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page?.map((row) => {
            prepareRow(row);
            return (
              <tr
                className={`text-base hover:bg-app-purple-7 transition-colors cursor-pointer border-b border-[#7E20804D] ${rowClass}`}
                onClick={onRowClick(row)}
                {...row.getRowProps(getRowProps(row))}
              >
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps([
                      {
                        className: `px-3 font-medium  pt-8 pb-2  ${cell?.column?.className}`,
                        style: cell?.column?.style,
                      },
                      getColumnProps(cell?.column),
                      getCellProps(cell),
                    ])}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <PaginatePills
        currentPage={pageIndex}
        noOfPages={pageCount}
        previousPage={previousPage}
        nextPage={nextPage}
        goToPage={gotoPage}
      />
    </div>
  );
};

export { ClientPaginatedTable };
