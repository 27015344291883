import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import isEmpty from "lodash/isEmpty";

import { formatDateTime } from "../../utils";
import { Spinner } from "../UI";
import CreateSessionErr from "./CreateSessionErr";

const DeleteSession = ({
  show,
  onClose,
  screens,
  onDelete,
  event,
  resetDelErrs,
  delErr,
  delStatus,
}) => {
  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog as="div" className="relative z-[1000000000]" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="max-w-2xl p-6 m-2 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Delete session
                  </Dialog.Title>
                  <div className="mt-4">
                    <h3 className="my-1 text-lg font-medium">
                      You are about to delete a session for{" "}
                      <i className="text-app-purple-3">
                        {event?.title || event?.id}
                      </i>{" "}
                      with details:
                    </h3>

                    <div className="relative grid items-center grid-cols-3 gap-2 py-2">
                      <div>
                        <label
                          htmlFor={`screenId`}
                          className="block mb-1 text-sm font-medium text-gray-900 "
                        >
                          Screen Id
                        </label>
                        <select
                          id={`screenId`}
                          className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                          disabled
                          value={event?.screenId}
                        >
                          <option value="">Select screen...</option>
                          {screens.map((screen) => (
                            <option value={screen.id} key={screen.id}>
                              {screen.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div>
                        <label
                          htmlFor={`startTime`}
                          className="block mb-1 text-sm font-medium text-gray-900 "
                        >
                          Start time
                        </label>

                        <input
                          disabled
                          className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                          value={formatDateTime(
                            new Date(event?.startTime),
                            "yyyy-LL-dd @ HH:mm"
                          )}
                        />
                      </div>

                      <div>
                        <label
                          htmlFor={`startTime`}
                          className="block mb-1 text-sm font-medium text-gray-900 "
                        >
                          End time
                        </label>

                        <input
                          disabled
                          className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                          value={formatDateTime(
                            new Date(event?.endTime),
                            "yyyy-LL-dd @ HH:mm"
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center justify-center mt-6 mb-2 space-x-4">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md bg-rose-400 "
                      onClick={onClose}
                    >
                      Cancel
                    </button>

                    <button
                      onClick={() => onDelete(event.id)}
                      className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-green-400 border border-transparent rounded-md "
                    >
                      Continue{" "}
                      <Spinner
                        className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                        status={delStatus}
                      />
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>

          <CreateSessionErr
            onDismiss={resetDelErrs}
            errors={
              !isEmpty(delErr)
                ? [
                    {
                      id: 0,
                      message: delErr,
                    },
                  ]
                : []
            }
          />
        </Dialog>
      </Transition>
    </>
  );
};

export { DeleteSession };
