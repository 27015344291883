export const getTableProps = (
  cellProps = () => ({}),
  colProps = () => ({}),
  headerProps = () => ({})
) => {
  return {
    getCellProps: (cellInfo) => ({
      style: {},
      className: "border-b-[#00000033] ",
      ...cellProps(cellInfo),
    }),
    getHeaderProps: (column) => ({
      className:
        "px-3 pt-8 pb-2 text-base font-medium border border-[#00000033] ",
      ...headerProps(column),
    }),
    getColumnProps: () => ({ style: {}, ...colProps() }),
  };
};
