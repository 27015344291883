import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { PrivateRoute } from "../../../components";
//import movies from "../../../utils/mockData/movies.json";
import { useSelector } from "react-redux";

const FilmDetails = () => {
  const { filmId } = useParams();
  const [movie, setMovie] = useState({});
  const { films } = useSelector((state) => state.films);
  const navigate = useNavigate();

  useEffect(() => {
    setMovie(
      films.pagedList.find((film) => {
        return film.id === filmId;
      })
    );
  }, [films, filmId]);

  return (
    <PrivateRoute redirectTo={`/dashboard/films/details/${filmId}`}>
      <main className="p-8">
        {/* top section */}
        <div className="flex justify-center mb-5">
          <button
            className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm"
            onClick={() => {
              navigate(`/dashboard/films/`);
            }}
          >
            Back to All Films
          </button>
        </div>
        <div className="flex items-center justify-between">
          <h1 className="text-xl font-semibold tracking-wide uppercase">
            {movie.name}
          </h1>
        </div>

        {/* form section */}

        <form className="w-3/4 p-8 mx-auto mt-4">
          <div className="grid gap-4 mb-6 grid-cols-[100px,_1fr]">
            <label className="mt-2" htmlFor="#movieTitle">
              Title
            </label>
            <input
              type="text"
              disabled
              id="movieTitle"
              value={movie["name"]}
              className="w-full max-w-3xl p-4 border rounded-md outline-none h-14 bg-app-gray-1 border-app-purple-8"
            />
          </div>

          <div className="grid gap-4 mb-6 grid-cols-[100px,_1fr]">
            <label className="mt-2" htmlFor="#shortMovieTitle">
              Short Title
            </label>
            <input
              type="text"
              disabled
              id="shortMovieTitle"
              value={movie["shortName"]}
              className="w-full max-w-3xl p-4 border rounded-md outline-none h-14 bg-app-gray-1 border-app-purple-8"
            />
          </div>

          <div className="grid gap-4 mb-6 grid-cols-[100px,_1fr]">
            <label className="mt-2" htmlFor="#synopsis">
              Synopsis
            </label>
            <textarea
              disabled
              id="synopsis"
              value={movie["synopsis"]}
              className="w-full h-48 max-w-3xl p-4 border rounded-md outline-none bg-app-gray-1 border-app-purple-8"
            />
          </div>

          <div className="grid gap-4 mb-6 grid-cols-[100px,_1fr]">
            <label className="mt-2" htmlFor="#genre">
              Genre
            </label>
            <input
              type="text"
              disabled
              id="genre"
              value={movie["genre"]}
              className="w-full max-w-3xl p-4 border rounded-md outline-none h-14 bg-app-gray-1 border-app-purple-8"
            />
          </div>

          <div className="grid gap-4 mb-6 grid-cols-[100px,_1fr]">
            <label className="mt-2" htmlFor="#shortMovieTitle">
              Distributor
            </label>
            <input
              type="text"
              disabled
              id="distrubutor"
              value={movie["distributor"]}
              className="w-full max-w-3xl p-4 border rounded-md outline-none h-14 bg-app-gray-1 border-app-purple-8"
            />
          </div>
        </form>
      </main>
    </PrivateRoute>
  );
};

export { FilmDetails };
