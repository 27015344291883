import React, { useMemo, useState, useEffect } from "react";
import { useTable, useGlobalFilter } from "react-table";
import differenceWith from "lodash/differenceWith";

import { FETCH_STATUS } from "../../utils";
import { Delete } from "../Icons";
import { Error } from "../UI";
import TicketsTableToolbar from "./TicketsTableToolbar";

const EditableCell = ({
  value: initialValue,
  row: { index, original },
  column: { id },
  updateTicketData,
}) => {
  const [value, setValue] = useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    updateTicketData(index, id, value);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (original.ticketGroup === "Standard") {
    return (
      <div className="flex items-center justify-center mx-2">
        <input
          className="p-1 w-full rounded border text-center  bg-[#EEEEEE] border-[#0000004D]"
          type="number"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      </div>
    );
  } else {
    return <p> {value}</p>;
  }
};

const TicketsTable = ({
  tickets = [],
  includedTickets = [],
  updateTicketData = () => {},
  err,
  retry,
  ticketsStatus,
  removeItem = () => {},
  onAddNewtickets = () => {},
}) => {
  const columns = useMemo(
    () => [
      {
        Header: "Ticket Name",
        accessor: "name",
      },
      {
        Header: "Ticket Class",
        accessor: "class",
      },
      {
        Header: "Ticket Type",
        accessor: "type",
      },

      {
        Header: "Price",
        accessor: "price",
        Cell: EditableCell,
      },
      {
        Header: "Action(s)",
        Cell: ({ row: { id } }) => {
          return (
            <button onClick={() => removeItem(id)} type="button">
              <Delete />
            </button>
          );
        },
      },
    ],
    [removeItem]
  );

  const notIncludedTickets = useMemo(() => {
    return differenceWith(
      tickets,
      includedTickets,
      (e1, e2) => e1.id === e2.id
    );
  }, [tickets, includedTickets]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: includedTickets,
      updateTicketData,
    },
    useGlobalFilter
  );

  if (ticketsStatus === FETCH_STATUS.REJECTED) {
    return <Error show={true} retry={retry} error={err} />;
  }

  return (
    <div>
      <TicketsTableToolbar
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
        tickets={notIncludedTickets}
        addTickets={onAddNewtickets}
      />

      <table
        className="w-full  text-sm border border-[#00000033] text-left border-collapse table-fixed bg-app-purple-2 "
        {...getTableProps()}
      >
        <thead className="">
          {headerGroups.map((headerGroup) => (
            <tr className="" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className="px-3 pt-8 pb-2 text-base font-medium border border-[#00000033] "
                  {...column.getHeaderProps()}
                >
                  <div className="inline-flex items-center justify-between space-x-4 underline ">
                    <span> {column.render("Header")}</span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                className="text-base transition-colors border-b border-[#7E20804D]"
                {...row.getRowProps()}
              >
                {row.cells.map((cell, j) => {
                  return (
                    <td
                      className={`px-3 font-medium last:text-center pt-8 pb-2`}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TicketsTable;
