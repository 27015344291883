import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useRequest } from "../../../../hooks";
import {
  API_URL,
  axiosApiInstance,
  axiosApiInstanceGet,
  FETCH_STATUS,
} from "../../../../utils";
import { editLocation } from "../../../../redux/slices";
import {
  ErrorWithWrapper,
  ItemFormErrorModal,
  Loading,
  PageHeader,
  PrivateRoute,
  Success,
  SuccessModal,
} from "../../../../components";
import LocationForm from "../../../../components/locations/LocationForm";

const UpdateLocation = () => {
  let params = useParams();

  const dispatch = useDispatch();

  const { data, err, status, setResolved, setPending, setRejected } =
    useRequest({});

  const {
    err: editErr,
    status: editStatus,
    setPending: editSetPending,
    setRejected: editSetRejected,
    setResolved: editSetResolved,
    resetErr,
  } = useRequest({});

  const onSave = async (values) => {
    try {
      editSetPending();

      const res = await axiosApiInstance.patch(
        `${API_URL}/StoreLocations/Update?storeLocationId=${params.id}`,
        values
      );
      dispatch(editLocation(res));
      editSetResolved("success");
    } catch (e) {
      editSetRejected(e || "Unable to update location");
    }
  };

  const getInfo = useCallback(async () => {
    if (params?.id) {
      try {
        setPending();
        const res = await axiosApiInstanceGet(
          `${API_URL}/StoreLocations/GetById?storeLocationId=${params.id}`,
          true
        );

        setResolved(res.data.data);
      } catch (e) {}
    }
  }, [params?.id, setRejected, setResolved, setPending]);

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  return (
    <PrivateRoute redirectTo={`/dashboard/stock/locations/${params?.id}`}>
      <PageHeader>
        <h1 className="text-2xl font-semibold text-white ">Edit Location</h1>
      </PageHeader>
      <Loading
        wrapperClassName="relative min-h-[50vh]"
        show={status === FETCH_STATUS.PENDING}
      />

      <ErrorWithWrapper
        error={err}
        show={status === FETCH_STATUS.REJECTED}
        retry={getInfo}
      />

      <ItemFormErrorModal
        message={editErr}
        onClick={resetErr}
        onClose={resetErr}
      />

      <Success show={status === FETCH_STATUS.RESOLVED}>
        <LocationForm
          isLoading={editStatus === FETCH_STATUS.PENDING}
          onSubmit={onSave}
          location={data}
        />
      </Success>

      <SuccessModal
        message="Updated Location."
        show={editStatus === FETCH_STATUS.RESOLVED}
        backLink="/dashboard/stock/locations"
      />
    </PrivateRoute>
  );
};

export { UpdateLocation };
