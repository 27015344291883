import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  API_URL,
  axiosApiInstance,
  axiosApiInstanceGet,
  editItemDirect,
  FETCH_STATUS,
  getAPIErr,
  getErrMsg,
} from "../../utils";

export const getKioskLayout = createAsyncThunk(
  "getKioskLayout",
  async (force, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/KioskLayouts/GetByCinema`,
        force
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(
        getAPIErr(
          error ||
            error?.message ||
            "unable to get kiosk layout"
        )
      );
    }
  }
);

export const getPosLayout = createAsyncThunk(
  "getPosLayout",
  async (force, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/PosLayouts/GetByCinema`,
        force
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(
        getAPIErr(
          error || error?.message || "unable to get Pos layout"
        )
      );
    }
  }
);

export const updatePosLayout = createAsyncThunk(
  "updatePosLayout",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.patch(
        `${API_URL}/PosLayouts/Update`,
        data
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(
        getAPIErr(
          error || error?.message || "unable to get Pos layout"
        )
      );
    }
  }
);

export const updateKioskLayout = createAsyncThunk(
  "updateKioskLayout",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.patch(
        `${API_URL}/KioskLayouts/Update`,
        data
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(
        getAPIErr(
          error || error?.message || "unable to get Pos layout"
        )
      );
    }
  }
);

const initialState = {
  allKioskLayout: [],
  getKioskLayoutStatus: FETCH_STATUS.IDLE,
  getKioskLayoutErr: null,
  allPosLayout: [],
  getPosLayoutStatus: FETCH_STATUS.IDLE,
  getPosLayoutErr: null,
  updatePosLayoutStatus: FETCH_STATUS.IDLE,
  updatePosLayoutErr: null,
  updateKioskLayoutStatus: FETCH_STATUS.IDLE,
  updateKioskLayoutErr: null,
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    resetUpdateKioskLayout: (state) => {
      state.updateKioskLayoutStatus = FETCH_STATUS.IDLE;
      state.updateKioskLayoutErr = null;
    },
    resetGetKioskLayout: (state) => {
      state.getKioskLayoutStatus = FETCH_STATUS.IDLE;
      state.getKioskLayoutErr = null;
    },
    resetUpdatePosLayout: (state) => {
      state.updatePosLayoutStatus = FETCH_STATUS.IDLE;
      state.updatePosLayoutErr = null;
    },
    resetGetPosLayout: (state) => {
      state.getPosLayoutStatus = FETCH_STATUS.IDLE;
      state.getPosLayoutErr = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getKioskLayout.pending, (state, action) => {
        state.getKioskLayoutStatus = FETCH_STATUS.PENDING;
        state.getKioskLayoutErr = "";
      })
      .addCase(getKioskLayout.fulfilled, (state, action) => {
        state.getKioskLayoutStatus = FETCH_STATUS.RESOLVED;
        state.allKioskLayout = action.payload;
        state.getKioskLayoutErr = "";
      })
      .addCase(getKioskLayout.rejected, (state, action) => {
        state.getKioskLayoutStatus = FETCH_STATUS.REJECTED;
        state.getKioskLayoutErr = action.payload;
      })

      .addCase(getPosLayout.pending, (state, action) => {
        state.getPosLayoutStatus = FETCH_STATUS.PENDING;
        state.getPosLayoutErr = "";
      })
      .addCase(getPosLayout.fulfilled, (state, action) => {
        state.getPosLayoutStatus = FETCH_STATUS.RESOLVED;
        state.allPosLayout = action.payload;
        state.getPosLayoutErr = "";
      })
      .addCase(getPosLayout.rejected, (state, action) => {
        state.getPosLayoutStatus = FETCH_STATUS.REJECTED;
        state.getPosLayoutErr = action.payload;
      })

      .addCase(updatePosLayout.pending, (state, action) => {
        state.updatePosLayoutStatus = FETCH_STATUS.PENDING;
        state.updatePosLayoutErr = "";
      })
      .addCase(updatePosLayout.fulfilled, (state, action) => {
        state.updatePosLayoutStatus = FETCH_STATUS.RESOLVED;
        state.updatePosLayoutErr = "";
      })
      .addCase(updatePosLayout.rejected, (state, action) => {
        state.updatePosLayoutStatus = FETCH_STATUS.REJECTED;
        state.updatePosLayoutErr = action.payload;
      })

      .addCase(updateKioskLayout.pending, (state, action) => {
        state.updateKioskLayoutStatus = FETCH_STATUS.PENDING;
        state.updateKioskLayoutErr = "";
      })
      .addCase(updateKioskLayout.fulfilled, (state, action) => {
        state.updateKioskLayoutStatus = FETCH_STATUS.RESOLVED;
        state.updateKioskLayoutErr = "";
      })
      .addCase(updateKioskLayout.rejected, (state, action) => {
        state.updateKioskLayoutStatus = FETCH_STATUS.REJECTED;
        state.updateKioskLayoutErr = action.payload;
      });
  },
});

export const selectLayout = (state) => state.layout;
export const {
  resetUpdateKioskLayout,
  resetGetKioskLayout,
  resetGetPosLayout,
  resetUpdatePosLayout,
} = layoutSlice.actions;
