import React, { useEffect } from "react";
import {
  Loading,
  PageHeader,
  PaymentChannelForm,
  PrivateRoute,
  Success,
  SuccessModal,
} from "../../../components";
import { FETCH_STATUS } from "../../../utils";
import {
  createPaymentChannel,
  editPaymentChannel,
  getPaymentChannel,
  resetEditChannel,
  selectFinance,
} from "../../../redux/slices";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const EditPaymentChannel = () => {
  const {
    editChannelStatus,
    editChannelErr,
    paymentChannel,
    paymentChannelStatus,
  } = useSelector(selectFinance);
  const dispatch = useDispatch();
  let params = useParams();

  const resetEdit = () => {
    dispatch(resetEditChannel());
  };

  useEffect(() => {
    if (params?.id) {
      dispatch(getPaymentChannel(params?.id));
    }
  }, [dispatch, params]);

  const onSave = (value) => {
  
    try {
     dispatch(editPaymentChannel({data:value,otherChannelId:params?.id})).unwrap()
    } catch (err) {}
  };
  return (
    <PrivateRoute
      redirectTo={`/dashboard/finance/payment-channel/edit/${params.id}`}
    >
      <PageHeader>
        <h1 className="text-2xl font-semibold text-white ">Edit Channel</h1>
      </PageHeader>
      <Loading
        wrapperClassName="relative min-h-[50vh]"
        show={paymentChannelStatus === FETCH_STATUS.PENDING}
      />
      <Success show={paymentChannelStatus === FETCH_STATUS.RESOLVED}>
        <PaymentChannelForm
          status={editChannelStatus}
          err={editChannelErr}
          onSave={onSave}
          resetErr={resetEdit}
          channel={paymentChannel}
        />
      </Success>
      <SuccessModal
        onClick={resetEdit}
        message="Edited a channel successfully."
        show={editChannelStatus === FETCH_STATUS.RESOLVED}
        backLink={"/dashboard/finance/payment-channel"}
      />
    </PrivateRoute>
  );
};

export { EditPaymentChannel };
