import React from "react";
import {
 
  PageHeader,
  PaymentChannelForm,
  PrivateRoute,

  SuccessModal,
} from "../../../components";

import {
  FETCH_STATUS,
 
} from "../../../utils";

import { useDispatch, useSelector } from "react-redux";
import {
  createPaymentChannel,
  resetCreateChannel,
  selectFinance,
} from "../../../redux/slices";
import { useLoggedInuser } from "../../../hooks";

const CreatePaymentChannel = () => {
  const { createChannelStatus, createChannelErr } = useSelector(selectFinance);
  const dispatch = useDispatch();
  const { profile } = useLoggedInuser();

  const resetCreate = () => {
    dispatch(resetCreateChannel());
  };

  const onSave = (value) => {
    try {
      dispatch(createPaymentChannel({...value,cinemaId:profile?.cinemaId})).unwrap()
    } catch (err) {}
  };

  return (
    <PrivateRoute redirectTo="/dashboard/finance/payment-channel/create">
      <PageHeader>
        <h1 className="text-2xl font-semibold text-white ">Create Channel</h1>
      </PageHeader>
      <PaymentChannelForm
        status={createChannelStatus}
        err={createChannelErr}
        onSave={onSave}
        resetErr={resetCreate}
      />
      <SuccessModal
        onClick={resetCreate}
        message="Created a channel successfully."
        show={createChannelStatus === FETCH_STATUS.RESOLVED}
        backLink={"/dashboard/finance/payment-channel"}
      />
    </PrivateRoute>
  );
};

export { CreatePaymentChannel };
