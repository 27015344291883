import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Input, SearchSelect } from "../UI";
import { getSelectedItem2, seatStatus } from "../../utils";
import { InfoIcon } from "../Icons";
const wrapperClass = "grid items-center gap-10 grid-cols-[15%_minmax(0,1fr)]";
const ScreenPreviewForm = ({
  show,
  onClose,
  seatClasses,
  formDetails,
  seatLayouts,
  isEditRow,
  layoutValues,
  isUpdate,
  setIsUpdate,
  update,
}) => {
  const {
    watch,
    control,
    setValue,
    register,
    reset,

    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      numOfSeats: formDetails.layout.numOfSeats,
      layoutIndex: formDetails.layoutIndex,
      rowName: formDetails.layout.name,
      seatNumber: formDetails.seatNumber,
      columnNumber: formDetails.columnNumber,
      status: formDetails.seatStatus || 0,
      seatClassId: formDetails.seatClassId || seatClasses[0]?.id,
    },
  });

  const setFieldUpdate = useCallback(() => {
    setIsUpdate(true);
  }, [setIsUpdate]);
  const handlePreviewForm = useCallback(
    (values) => {
      update(formDetails?.layoutIndex, {
        name: values?.rowName,
        numOfSeats: values?.numOfSeats,
        rowNumber: layoutValues[formDetails?.layoutIndex]?.rowNumber,
        definitions: layoutValues[formDetails?.layoutIndex]?.definitions.map(
          (def, i) => {
            if (def.columnNumber === values.columnNumber) {
              return {
                ...def,
                seatClassId: values?.seatClassId,
                seatNumber:
                  Number(values?.status) === 3 ? 0 : +values?.seatNumber,
                status: values?.status,
                columnNumber: values?.columnNumber,
              };
            }
            return def;
          }
        ),
      });

      setFieldUpdate();

      onClose();
    },
    [formDetails?.layoutIndex, layoutValues, onClose, setFieldUpdate, update]
  );

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-[10000000]" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-75"
          leave="ease-in duration-75"
          leaveFrom="opacity-75"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex justify-center items-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full pb-6 max-w-md max-h-max overflow-hidden text-left transition-all transform bg-app-purple-2 shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-center text-white bg-gradient-to-b from-app-purple-4 to-app-purple-3 p-3.5 "
                >
                  SEAT {formDetails.seatName}
                </Dialog.Title>
                <section className="">
                  <form onSubmit={handleSubmit(handlePreviewForm)}>
                    <div className="flex items-center justify-end p-4 bg-app-purple-5">
                      <div className="inline-flex items-center space-x-7">
                        <button
                          className="p-2 text-center rounded min-w-[92px] bg-app-yellow text-app-purple-6"
                          onClick={onClose}
                        >
                          Cancel
                        </button>

                        <button
                          type="submit"
                          className="p-2 rounded min-w-[92px] text-app-yellow bg-app-purple-4 disabled:opacity-30 inline-flex items-center justify-center space-x-2"
                        >
                          <span>Save</span>
                        </button>
                      </div>
                    </div>
                    <div className="flex justify-center mt-8 min-h-[240px] overflow-auto">
                      <div className="space-y-4 ">
                      <div className={`${wrapperClass}`}>
                        <p className="text-right">Seat Class:</p>

                        <Controller
                          control={control}
                          name={"seatClassId"}
                          render={({ field: { value, onChange } }) => {
                            return (
                              <SearchSelect
                                items={seatClasses}
                                dropDownNameKey="name"
                                inputClassName="!w-full p-2 px-4 py-2 border-none !bg-app-purple-10"
                                listClassName="!py-2"
                                id={`seatClassId`}
                                value={getSelectedItem2(
                                  seatClasses,
                                  value,
                                  "id"
                                )}
                                onChange={(value) => onChange(value?.id)}
                                displayValue={(value) => {
                                  return value?.name;
                                }}
                                placeholder={`Select Class`}
                                searchOptions={{
                                  keys: ["name"],
                                }}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className={`${wrapperClass}`}>
                        <p className="text-right">Status:</p>

                        <Controller
                          control={control}
                          name={"status"}
                          render={({ field: { value, onChange } }) => {
                            return (
                              <SearchSelect
                                items={seatStatus}
                                dropDownNameKey="name"
                                inputClassName="!w-full p-2 px-4 py-2 border-none !bg-app-purple-10"
                                listClassName="!py-2"
                                id={`seatStatus`}
                                value={getSelectedItem2(
                                  seatStatus,
                                  value,
                                  "id"
                                )}
                                onChange={(value) => onChange(value?.id)}
                                displayValue={(value) => {
                                  return value?.name;
                                }}
                                placeholder={`Select Status`}
                                searchOptions={{
                                  keys: ["name"],
                                }}
                              />
                            );
                          }}
                        />
                      </div>
                        <Input
                          label="Number of seats:"
                          placeholder="number of seats"
                          labelClass="not-sr-only"
                          disabled
                          id="numOfSeats"
                          wrapperClass="!grid-cols-[15%_minmax(0,1fr)]"
                          // errorMsg={errors?.houseSeats?.message}
                          type="number"
                          min={0}
                          {...register("numOfSeats")}
                        />
                        <Input
                          label="Seat Number:"
                          placeholder="seat number"
                          labelClass="not-sr-only"
                          id="seatNumber"
                          wrapperClass="!grid-cols-[15%_minmax(0,1fr)]"
                          // errorMsg={errors?.houseSeats?.message}
                          type="number"
                          min={0}
                          {...register("seatNumber")}
                        />
                        <Input
                          label="Column Number:"
                          placeholder="column number"
                          labelClass="not-sr-only"
                          id="columnNumber"
                          disabled
                          wrapperClass="!grid-cols-[15%_minmax(0,1fr)]"
                          // errorMsg={errors?.houseSeats?.message}
                          type="number"
                          min={0}
                          {...register("columnNumber")}
                        />

                        <Input
                          label="Row Name:"
                          placeholder="Row Name"
                          labelClass="not-sr-only"
                          id="rowName"
                          wrapperClass="!grid-cols-[15%_minmax(0,1fr)]"
                          // errorMsg={errors?.houseSeats?.message}
                          type="text"
                          {...register("rowName")}
                        />
                      </div>
                    </div>
                  </form>
                  <div className="flex justify-center items-center">
                    <div className="grid text-xs grid-cols-[25px_minmax(0,1fr)] items-center ml-[2%] pt-8">
                      <span>
                        <InfoIcon />
                      </span>

                      <p>
                        Remember to save on the main form after setting up
                        screen layout
                      </p>
                    </div>
                  </div>
                </section>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export { ScreenPreviewForm };
