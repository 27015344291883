import { useState, useMemo, useEffect, useCallback } from "react";
import { useTable, useGlobalFilter } from "react-table";
//import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import {
  FilmXFilmsModal,
  PrivateRoute,
  TextInput,
  Loading,
  ErrorWithWrapper,
} from "../../../components";
import { FETCH_STATUS, formatDateTime } from "../../../utils";
import { getFilms, selectFilms } from "../../../redux/slices";

const AllFilms = () => {
  const [showFilmXModal, setShowFilmXmodal] = useState(false);
  //const navigate = useNavigate();
  const dispatch = useDispatch();

  const { films, getFilmsStatus, getFilmsError } = useSelector(selectFilms);

  useEffect(() => {
    if (getFilmsStatus === FETCH_STATUS.IDLE) {
      dispatch(getFilms());
    }
  }, [dispatch, getFilmsStatus]);

  const getInfo = useCallback(
    (force) => {
      dispatch(getFilms(force));
    },
    [dispatch]
  );

  const columns = useMemo(() => {
    return [
      {
        Header: "S/N",
        Cell: (row) => Number(row.row.id) + 1,
      },
      {
        Header: "Title",
        accessor: "name",
      },
      {
        Header: "Distributor",
        accessor: "distributor",
      },
      {
        Header: "Rating",
        accessor: "filmRating",
      },
      {
        Header: "Duration",
        accessor: "duration",
      },
      {
        Header: "Release Date",
        accessor: "releaseDate",
        Cell: ({ value }) =>
          formatDateTime(new Date(value), "EEE, MMM dd, yyyy"),
      },
      {
        Header: "Genre",
        accessor: "filmGenre",
        Cell: ({ value }) => {
          let genreList = "";
          value.map((genre) => {
            return (genreList = genreList + `${genre.genre} `);
          });

          return genreList;
        },
      },
      // {
      //   Header: "Trailer",
      //   accessor: "filmTrailerUrl",
      //   Cell: ({ value }) => (
      //     <a
      //       href={value}
      //       target="_blank"
      //       referrerPolicy="no-referrer"
      //       rel="noreferrer"
      //       className="text-app-purple-4"
      //     >
      //       {"Watch Trailer"}
      //     </a>
      //   ),
      // },
      {
        Header: "Status",
        accessor: "isActive",
        Cell: ({ value }) => (value ? "Active" : "Inactive"),
      },
    ];
  }, []);

  const tableInstance = useTable({ data: films, columns }, useGlobalFilter);

  const { setGlobalFilter } = tableInstance;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <PrivateRoute redirectTo="/dashboard/films">
      <main>
        {/* film page headers */}
        <div className="flex flex-wrap items-center justify-between gap-3 p-2">
          <h1 className="w-full text-xl font-semibold text-center md:text-left lg:w-auto">
            All Films
          </h1>

          <TextInput
            containerClassName="w-full md:w-2/3 lg:w-1/3"
            value={tableInstance?.state?.globalFilter}
            onChange={(text) => setGlobalFilter(text || undefined)}
            placeholder="Search for film..."
          />
          <div className="flex gap-4 buttonGroup">
            <button
              className="p-2 px-6 rounded-md bg-app-purple-3 text-app-yellow"
              onClick={() => {
                setShowFilmXmodal(true);
              }}
            >
              Import
            </button>
          </div>
        </div>

        <ErrorWithWrapper
          error={getFilmsError}
          show={getFilmsStatus === FETCH_STATUS.REJECTED}
          retry={getInfo}
        />

        <div className="relative">
          <Loading
            wrapperClassName="absolute top-0 left-0 right-0 min-h-[50vh]"
            show={getFilmsStatus === FETCH_STATUS.PENDING}
          />

          {films?.length ? (
            <>
              <table
                {...getTableProps()}
                className="w-full mx-auto mt-8 overflow-hidden text-left border rounded-md border-app-purple-4"
                style={{ border: "1px" }}
              >
                <thead className="shadow-2xl bg-app-purple-8">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps()}
                          className="p-4 font-normal tracking-wide uppercase border border-gray-300 text-md"
                        >
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30"
                        // onClick={() =>
                        //   navigate(
                        //     `/dashboard/films/details/${row.original.id}`
                        //   )
                        // }
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()} className="p-4">
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          ) : null}
          {showFilmXModal && (
            <FilmXFilmsModal
              show={showFilmXModal}
              onClose={() => {
                setShowFilmXmodal(false);
              }}
            />
          )}
        </div>
      </main>
    </PrivateRoute>
  );
};

export { AllFilms };
