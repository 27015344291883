import React from "react";
import { useDispatch } from "react-redux";
import {
  PageHeader,
  PriceCardForm,
  PrivateRoute,
  SuccessModal,
} from "../../../../components";
import { useRequest } from "../../../../hooks";
import { addCard } from "../../../../redux/slices";

import {
  API_URL,
  axiosApiInstance,
  FETCH_STATUS,
  PriceCard,
} from "../../../../utils";

const PriceCardCreate = () => {
  const { err, status, resetErr, setResolved, setPending, setRejected } =
    useRequest();

  const dispatch = useDispatch();

  const onSave = async (data) => {
    try {
      setPending();

      const res = await axiosApiInstance.post(
        `${API_URL}/PriceCards/Create`,
        PriceCard.toDTO(data)
      );

      const newCard = PriceCard.toUI(res.data.data);

      setResolved();

      dispatch(addCard(newCard));
    } catch (error) {
      setRejected(error || "Unable to create price card");
    }
  };

  return (
    <PrivateRoute redirectTo="/dashboard/price-cards/create">
      <PageHeader>
        <h1 className="text-2xl font-semibold text-white ">
          Create Price card
        </h1>
      </PageHeader>
      <PriceCardForm
        onReset={resetErr}
        status={status}
        error={err}
        onSave={onSave}
      />
      <SuccessModal
        message="Created price card."
        show={status === FETCH_STATUS.RESOLVED}
        backLink={"/dashboard/price-cards"}
      />
    </PrivateRoute>
  );
};

export { PriceCardCreate };
