import {useLoggedInuser} from "../../../hooks";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {cinemaSessionReportInit, cinemaSessionReportSchema, FETCH_STATUS, formatDateForApi} from "../../../utils";
import {useDispatch, useSelector} from "react-redux";
import {getCinemaSessionReport, reportData} from "../../../redux/slices";
import React, {useRef} from "react";
import {Element, scroller} from "react-scroll";
import {ExportExcelBtn, PdfButton, Pdiv, PrivateRoute, SessionReportTable, Spinner} from "../../../components";
import DatePicker from "react-datepicker";
import { DateTimeInput } from "../../../components/Films/DateTimeInput";
import { useNavigate } from "react-router-dom";

const SessionReconciliation = () => {
  const { profile } = useLoggedInuser();

  const navigate = useNavigate();

  const { cinemaSessionReport, cinemaSessionReportStatus } =
    useSelector(reportData);

  const dispatch = useDispatch();

  const reportRef = useRef(null);

  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(cinemaSessionReportSchema),
    defaultValues: { ...cinemaSessionReportInit, cinemaId: profile?.cinemaId },
  });

 

  const xlsId = ["cash1", "cash2", "cash3"];
 
    const onSubmit = (data) => {
        dispatch(
            getCinemaSessionReport({
                ...data,
                dateFrom: formatDateForApi(new Date(data.dateFrom)),
                dateTo: formatDateForApi(new Date(data.dateTo)),
                force: true,
            })
        );
        scroller.scrollTo("reportContainer", {
            duration: 1500,
            delay: 0,
            smooth: true,
            offset: -100, // Scrolls to element + 50 pixels down the page
        });
    };

  return (
    <PrivateRoute redirectTo={"/dashboard/reports/session-reconciliation"}>
      <div className="w-[95%] mx-auto mt-6">
        <p className="font-semi-bold px-3 pb-5">Session Reconcilation</p>
        <div className="flex items-center justify-between p-4 px-8 h-[50px] rounded-t-lg bg-[#C96FCC]">
          <div className="flex gap-4">
            <span className="text-xl font-semibold text-white"></span>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex items-center justify-end py-3 px-4 bg-white ">
            <div className="flex gap-3 buttongroup ">
              <button
                className="p-1 px-3 rounded-md text-[#C96FCC] bg-[#ffe583] shadow-md hover:shadow-sm"
                type="button"
                onClick={() => {
                  navigate("/dashboard/reports");
                }}
              >
                Cancel
              </button>
              <button
                className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm flex items-center"
                type="button"
                onClick={() => {
                  navigate("/dashboard/sessions");
                }}
              >
                <span> View Sessions </span>
              </button>
              <button
                className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm flex items-center"
                type="submit"
              >
                <span> Run Report </span>
                <Spinner
                  className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                  status={cinemaSessionReportStatus}
                />
              </button>
            </div>
          </div>
          <div className="bg-app-purple-2 p-3 pt-[30px]">
            <Pdiv label={"Date Range"}>
              <div className="flex items-center">
                <Controller
                  control={control}
                  name="dateFrom"
                  render={({ field: { value, onChange } }) => (
                    <div className="relative ">
                      <DatePicker
                        placeholderText="Start Date"
                        selected={new Date(value)}
                        onChange={(value) =>
                          onChange(new Date(value).toISOString())
                        }
                        customInput={
                          <DateTimeInput
                            className="bg-app-purple-8"
                            dateFormat={"EEE, MMM dd, yyyy"}
                          />
                        }
                      />
                    </div>
                  )}
                />

                <p className="w-[6rem] text-center"> To:</p>
                <Controller
                  control={control}
                  name="dateTo"
                  render={({ field: { value, onChange } }) => (
                    <div className="relative ">
                      <DatePicker
                        placeholderText="End Date"
                        selected={new Date(value)}
                        onChange={(value) =>
                          onChange(new Date(value).toISOString())
                        }
                        customInput={
                          <DateTimeInput
                            className="bg-app-purple-8"
                            dateFormat={"EEE, MMM dd, yyyy"}
                          />
                        }
                      />
                    </div>
                  )}
                />
              </div>
            </Pdiv>
          </div>
        </form>
        <Element name="reportContainer">
          {Object.keys(cinemaSessionReport).length > 0 &&
            cinemaSessionReport !== FETCH_STATUS.REJECTED && (
              <>
                <div className="flex justify-center mt-5 space-x-4">
                  <PdfButton refValue={reportRef} docTitle={"csession"} />
                  <ExportExcelBtn id={xlsId} name={"cashier-session.xlsx"} />
                </div>
                <div className="hidden">
                  <SessionReportTable
                    sessionReport={cinemaSessionReport}
                    id1={"cash1"}
                    id2={"cash2"}
                    id3={"cash3"}
                  />
                </div>
                <div ref={reportRef}>
                  <SessionReportTable sessionReport={cinemaSessionReport} />
                </div>
              </>
            )}
          {cinemaSessionReportStatus === FETCH_STATUS.REJECTED && (
            <p className="text-center py-3">No data Found</p>
          )}
        </Element>
      </div>
    </PrivateRoute>
  );
};

export { SessionReconciliation };
