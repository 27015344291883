import { stockData } from "../../redux/slices";
import { useSelector } from "react-redux";
const TransferReceiptLayout = ({ type, data }) => {
  const { transferReceipt } = useSelector(stockData);
  return (
    <div>
      <p className="font-bold">Stock Transfer</p>
      <div className="flex justify-between text-[#8B8D97]">
        <div className=" mr-5">
          <p className="py-2">
            <span className="w-[180px] inline-block ">
              Transfer Receipt Id:{" "}
            </span>
            <span>{transferReceipt?.id}</span>
          </p>
          <p className="py-2">
            <span className="w-[180px] inline-block">Received by: </span>
            <span>
              {transferReceipt?.receivedBy
                ? transferReceipt?.receivedBy.firstName +
                  " " +
                  transferReceipt?.receivedBy.lastName
                : ""}
            </span>
          </p>
          <p className="py-2">
            <span className="w-[180px] inline-block">Approved By: </span>
            <span>
              {transferReceipt?.approvedBy
                ? transferReceipt?.approvedBy.firstName +
                  " " +
                  transferReceipt?.approvedBy.lastName
                : ""}
            </span>
          </p>
        </div>
        <div>
          <div>
            <p className="py-2">
              <span className="w-[180px] inline-block ">Items Received: </span>
              <span>{transferReceipt?.itemsReceived ? "Yes" : "No"}</span>
            </p>
            <p className="py-2">
              <span className="w-[180px] inline-block">Date Received: </span>
              <span>
                {new Date(transferReceipt?.dateCreated).toLocaleString()}
              </span>
            </p>
            <p className="py-2">
              <span className="w-[180px] inline-block">Date Completed: </span>
              <span>
                {transferReceipt?.dateCompleted &&
                  new Date(transferReceipt?.dateCompleted).toLocaleString()}
              </span>
            </p>
          </div>
        </div>
      </div>
      <table className="w-full mt-8 ">
        <thead>
          <tr className="border-y-2 ">
            <th className="py-3 text-left ">Item Name</th>
            <th className="text-left">Received Quantity</th>
            {/* <th className="text-left">Unit of Measure</th>{" "}
            <th className="text-center">Qty Desired</th>
            <th className="text-center">Qty Available</th> */}
          </tr>
        </thead>
        <tbody>
          {transferReceipt?.items?.map((item) => {
            return (
              <tr>
                <td className="py-2">{item?.item}</td>
                <td>{item?.receivedQuantity}</td>
                {/* <td>{item?.unitOfMeasurement}</td>
                <td className="text-center">{item?.quantiityNeeded}</td>
                <td className="text-center">{item?.quantityAvailable}</td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export { TransferReceiptLayout };
