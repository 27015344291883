import { useState } from "react";
import { SPACE_BUCKET, SPACE_URL, s3Client } from "../utils";
import { PutObjectCommand } from "@aws-sdk/client-s3";

export const useFileUpload = ({ fieldName, setValue, imageDefault }) => {
  const [singleImage, setSingleImage] = useState(imageDefault || "");
  const [multiImage, setMultiImage] = useState([]);
  const [imageUploaded, setImageUploaded] = useState(false);
  const [imageSelected, setImageSelected] = useState(false);
  
  const handleFileUpload = async (e) => {
    if (e.target.multiple) {
      const files = e.target.files;
     
      try {
        await Promise.all(
          Object.values(files).map(async (file) => {
            setImageSelected(true);
            setImageUploaded(false);
            const fileName = `${file.name}${crypto.randomUUID()}`;
            const bucketParams = {
              Bucket: SPACE_BUCKET,
              Key: fileName,
              Body: file,
              ContentType: file.type,
              ACL: "public-read",
            };
            await s3Client
              .send(new PutObjectCommand(bucketParams))
              .then((data) => {
                if (data) {
                  const imageUrl = SPACE_URL + "/" + fileName;
                 
                  if (fieldName.length>0) {
                   
                    setMultiImage((prevImg) => {
                      return [...new Set([...prevImg, imageUrl, ...fieldName])];
                    });
                  } else if (fieldName.length < 1) {
                    setMultiImage((prevImg) => {
                      return [...new Set([...prevImg, imageUrl])];
                    });
                  }
                  setImageUploaded(true);
                  
                }
              });
          })
        );
      } catch (error) {}
    } else {
      setImageSelected(true);
      const file = e.target.files[0];
      let fileName = `${file.name}${crypto.randomUUID()}`;
      const bucketParams = {
        Bucket: SPACE_BUCKET,
        Key: `${fileName}`,
        Body: file,
        ContentType: file.type,
        ACL: "public-read",
      };
      try {
        await s3Client.send(new PutObjectCommand(bucketParams)).then((data) => {
          if (data) {
            const imageUrl = SPACE_URL + "/" + fileName;
            setValue(fieldName, imageUrl, { shouldValidate: true });
            setImageUploaded(true);
            setSingleImage(imageUrl);
          }
       
        });
      } catch (err) {
        console.log({ err });
      }
    }
  };

  return {
    imageUploaded,
    singleImage,
    setSingleImage,
    setMultiImage,
    multiImage,
    imageSelected,
    handleFileUpload,
  };
};
