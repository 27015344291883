import React, { useMemo, useState, useCallback } from "react";
import { formatDateTime, getTableProps } from "../../utils";
import { ClientPaginatedTable } from "../UI";
import { format } from "../Films/DateTimeInput";
import OrderModal from "../RefundReport/OrderModal";
import { useDispatch, useSelector } from "react-redux";
import { getOrderById, reportData } from "../../redux/slices";
import RefundModal from "../RefundReport/RefundModal";
import { useRequest } from "../../hooks";
import SupplementaryOrderModal from "../RefundReport/SupplementaryOrderModal";

const RefundReportTable = ({ refundReport = [] }) => {
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [openSupplementaryOrderModal, setOpenSupplementaryOrderModal] =
    useState(false);
  const [openRefundModal, setOpenRefundModal] = useState(false);
  const dispatch = useDispatch();
  const { singleOrder, singleOrderStatus } = useSelector(reportData);
  const { resetErr, setPending, setResolved, setRejected, err } = useRequest();

  const handleViewOrder = useCallback(
    async (orderId) => {
      setOpenOrderModal(true);
      try {
        setPending();
        dispatch(getOrderById({ orderId: orderId }));
        setResolved();
      } catch (error) {
        setRejected(error || "Unable to view order");
      }
    },
    [dispatch, setPending, setRejected, setResolved]
  );

  const handleViewRefund = useCallback(
    async (newOrderId) => {
      setPending();
      setOpenRefundModal(true);
      try {
        dispatch(getOrderById({ orderId: newOrderId }));
        setResolved();
      } catch (error) {
        setRejected(error || "unable to view refund");
      }
    },
    [dispatch, setPending, setRejected, setResolved]
  );
  const handleViewSupplementaryOrder = useCallback(
    async (supplimentaryOrderId) => {
      setOpenSupplementaryOrderModal(true);
      try {
        setPending();
        dispatch(getOrderById({ orderId: supplimentaryOrderId }));
        setResolved();
      } catch (error) {
        setRejected(error || "unable to view supplementary order");
      }
    },
    [dispatch, setPending, setRejected, setResolved]
  );
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "refundedByUser",
        Cell: ({ value }) => `${value?.firstName} ${value?.lastName}`,
      },
      {
        Header: "Date",
        accessor: "dateCreated",
        Cell: ({ value }) => formatDateTime(new Date(value), format),
      },
      {
        Header: "Reason",
        accessor: "reason",
      },
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => {
          return (
            <div className="flex flex-col gap-2 md:flex-row md:gap-0 justify-evenly items-center">
              <button
                className="bg-app-purple-4 p-2 rounded-md text-white"
                onClick={() => handleViewOrder(original.orderId)}
              >
                <span>View Order</span>
              </button>
              {original?.supplimentaryOrderId && (
                <button
                  className="bg-app-purple-4 p-2 rounded-md text-white"
                  onClick={() =>
                    handleViewSupplementaryOrder(original.supplimentaryOrderId)
                  }
                >
                  View Sup Order
                </button>
              )}
              {original?.newOrderId && (
                <button
                  className="bg-app-purple-4 p-2 rounded-md text-white"
                  onClick={() => handleViewRefund(original.newOrderId)}
                >
                  View Refund
                </button>
              )}
            </div>
          );
        },
      },
    ],
    [handleViewOrder, handleViewRefund, handleViewSupplementaryOrder]
  );
  return (
    <>
      <ClientPaginatedTable
        columns={columns}
        filterColumnsKeys={["name", "isActive"]}
        tableData={refundReport}
        {...getTableProps()}
        rowClass="hover:!bg-transparent !cursor-default"
      />
      {openOrderModal && (
        <OrderModal
          show={openOrderModal}
          data={singleOrder ?? []}
          onClose={() => setOpenOrderModal(false)}
          status={singleOrderStatus}
          error={err}
          resetErr={resetErr}
        />
      )}
      {openRefundModal && (
        <RefundModal
          show={openRefundModal}
          data={singleOrder ?? []}
          onClose={() => setOpenRefundModal(false)}
          status={singleOrderStatus}
          error={err}
          resetErr={resetErr}
        />
      )}
      {openSupplementaryOrderModal && (
        <SupplementaryOrderModal
          show={openSupplementaryOrderModal}
          data={singleOrder ?? []}
          onClose={() => setOpenSupplementaryOrderModal(false)}
          status={singleOrderStatus}
          error={err}
          resetErr={resetErr}
        />
      )}
    </>
  );
};

export { RefundReportTable };
