import React, { useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { getTableProps } from "../../utils";

import { ClientPaginatedTable, DefaultColumnFilter, StatusFilter } from "../UI";
import { ClaimsModal } from "./ClaimsModal";

const ClaimsTable = ({ claims = [], getInfo }) => {
  const details = {
    name: "",
    userId: "",
    claims: [],
    userClaim:{}
  };
  const [modalDetails, setModalDetails] = useState(details);
  const [showModal, setShowModal] = useState(false);

  const columns = useMemo(
    () => [
      {
        id: "name",
        Header: "Name",
        accessor: (row) => `${row?.lastName} ${row?.firstName}`,
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Role",
        accessor: "roleName",
      },
      {
        Header: "Claims",
        accessor: "claims",
        // Filter: StatusFilter,
        Cell: ({ value }) => {
          return value.map((v, i) => <p key={i}>{v}</p>);
        },
      },
    ],
    []
  );
  const onRowClick = (row) => () => {
    setModalDetails({
      ...details,
      name: `${row.original.lastName} ${row.original.firstName}`,
      userId: row.original.id,
      claims: row.original.claims,
      userClaim:row.original
    });
    setShowModal(true);
    
  };
  
  return (
    <>
      <ClientPaginatedTable
        onRowClick={onRowClick}
        columns={columns}
        filterColumnsKeys={["name"]}
        tableData={claims}
        {...getTableProps()}
        rowClass="hover:!bg-transparent !cursor-default"
      />
      {showModal && (
        <ClaimsModal
          show={showModal}
          onClose={() => {
            setShowModal(false);
          }}
          userClaim={modalDetails}
        />
      )}
    </>
  );
};

export { ClaimsTable };
