import React from "react";

export const InputErr = ({ err }) => {
  if (err) {
    return (
      <p
        className={`italic  text-red-400 font-light text-[10px] ml-[398px] mb-[1rem] mt-[-2rem] pb-2`}
      >
        {err}
      </p>
    );
  }

  return null;
};

export const ReportLi = (props) => {
  return (
    <li className="flex justify-between p-3 items-center bg-app-purple-2 border-b border-[#00000033]">
      {props.children}
    </li>
  );
};
export const ReportTitle = (props) => {
  return <span className=" font-bold">{props.children}</span>;
};
export const GenerateBtn = (props) => {
  return (
    <button
      onClick={props.onclick}
      className="bg-[#0fd853] text-white p-2 rounded"
    >
      Generate
    </button>
  );
};

export const Pdiv = (props) => {
  return (
    <div className="flex items-center mb-[30px]">
      <div className="w-[23rem] flex justify-end mr-[30px]">{props.label}:</div>{" "}
      <div> {props.children}</div>
    </div>
  );
};

export const Pdiv2 = React.forwardRef(
  ({ label, children, ...otherProps }, ref) => (
    <>
      <div className="flex items-center mb-[30px]">
        <div className="w-[23rem] flex justify-end mr-[30px]">{label}:</div>
        <select
          {...otherProps}
          ref={ref}
          className="rounded bg-[#d0a3d3] border-0 mb-3 w-[30rem]"
        >
          {children}
        </select>
      </div>
    </>
  )
);

export const Th = ({colSpan, rowSpan,children}) => {
  return (
    <th className="p-4 font-normal tracking-wide uppercase border border-gray-300 text-md "

    colSpan = {colSpan}
    rowSpan = {rowSpan}
    >
      {children}
    </th>
  );
};
