import React, { useEffect } from "react";
import { Transition } from "@headlessui/react";
import { useCountdown, useLogout } from "../../hooks";

const twoMins = 1000 * 60 * 2;

const IdleWarning = ({ show }) => {
  const { time, done } = useCountdown(new Date().getTime() + twoMins);
  const logout = useLogout();

  useEffect(() => {
    if (done) {
      logout();
    }
  }, [done, logout]);

  return (
    <Transition
      show={show}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-75"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className="fixed flex  items-center w-full max-w-screen-phone-xs phone-sm:max-w-screen-phone-xs phone-lg:max-w-screen-phone-md  p-4 text-gray-500 bg-white rounded-lg shadow pointer-events-auto top-2 right-2"
        role="alert"
      >
        <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-500 bg-orange-100 rounded-lg ">
          <svg
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>
        <div className="ml-3 text-sm font-normal">
          <div>
            <p className="inline">
              You've been idle for a while, automatic logout in{" "}
              <strong>{time}</strong>
            </p>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export { IdleWarning };
