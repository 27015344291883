import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { FETCH_STATUS } from "../../../utils";
import {
  Loading,
  PageHeader,
  PrivateRoute,
  ErrorWithWrapper,
} from "../../../components";

const IssuesList = () => {
  const dispatch = useDispatch();

  //    const getInfo = useCallback(
  //      (force) => {
  //        //config = {force:false,page}
  //        dispatch(getIssues(force));
  //      },
  //      [dispatch]
  //    );

  //   useEffect(() => {
  //     if (ticketsStatus === FETCH_STATUS.IDLE) {
  //       dispatch(getTickets());
  //     }
  //   }, [dispatch, ticketsStatus]);

  return (
    <PrivateRoute redirectTo="/dashboard/issues">
      <PageHeader>
        <h1 className="items-center text-2xl font-semibold text-white ">
          Issues
        </h1>

        <NavLink
          to="/dashboard/issues/create"
          type="button"
          className="px-4 py-3 text-base font-medium bg-white rounded-md text-app-purple-9"
        >
          Create Issue
        </NavLink>
      </PageHeader>
    </PrivateRoute>
  );
};

export { IssuesList };
