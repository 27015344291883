import React from "react";
import { useRequest } from "../../../../hooks";
import { useDispatch } from "react-redux";
import { API_URL, axiosApiInstance, FETCH_STATUS } from "../../../../utils";
import { addLocation } from "../../../../redux/slices";
import {
  ItemFormErrorModal,
  PageHeader,
  PrivateRoute,
  SuccessModal,
} from "../../../../components";
import LocationForm from "../../../../components/locations/LocationForm";

const CreateLocation = (props) => {
  const { err, status, setResolved, setPending, setRejected, resetErr } =
    useRequest();

  const dispatch = useDispatch();

  const onSave = async (data) => {
    try {
      setPending();

      const res = await axiosApiInstance.post(
        `${API_URL}/StoreLocations/Create`,
        data
      );

      const location = res.data.data;
      dispatch(addLocation(location));

      setResolved("Success");
    } catch (e) {
      setRejected(e || "Unable to create location");
    }
  };

  return (
    <PrivateRoute redirectTo="/dashboard/stock/locations/create">
      <PageHeader>
        <h1 className="text-2xl font-semibold text-white ">
          Create Store Location
        </h1>
      </PageHeader>
      <LocationForm
        isLoading={status === FETCH_STATUS.PENDING}
        onSubmit={onSave}
      />
      <SuccessModal
        message="Created Location."
        show={status === FETCH_STATUS.RESOLVED}
        backLink="/dashboard/stock/locations"
      />
      <ItemFormErrorModal error={err} show={!!err} />
    </PrivateRoute>
  );
};

export { CreateLocation };
