import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getSubscriptionStatus,
  selectFinance,
} from "../../redux/slices/financeSlice";
import { FETCH_STATUS } from "../../utils";
import { selectUser } from "../../redux/slices";
import { formatDistanceToNow } from "date-fns";

const PaymentReminder = () => {
  const dispatch = useDispatch();
  const { subscriptionStatus, status, err } = useSelector(selectFinance);
  const { profile } = useSelector(selectUser);
  useEffect(() => {
    if (status === FETCH_STATUS.IDLE) {
      dispatch(
        getSubscriptionStatus({ force: true, circuitId: profile.circuitId })
      );
    }
  }, []);

  if (
    subscriptionStatus.status === "OverDue" ||
    subscriptionStatus.status === "Disconnected"
  ) {
    return (
      <div className="flex justify-center mb-2">
        <p className="w-[700px] text-center border-2 rounded-xl text-red-500 bg-white p-3">
          {subscriptionStatus.status === "OverDue" && (
            <span>
              Seems Your Cinema has overdue payments and would be
              <span className="font-bold animate-pulse">
                {" "}
                {`Disconnected ${formatDistanceToNow(
                  new Date(subscriptionStatus?.deactivationDate),
                  { addSuffix: true, includeSeconds: true }
                )}`}{" "}
                .{" "}
              </span>
            </span>
          )}
          {subscriptionStatus.status === "Disconnected" && (
            <span> Your cinema has been disconnected </span>
          )}
          Kindly notify your Cinema Manager or Finance officer to{" "}
          <span className="underline text-blue-500">
            <Link to="finance/invoice">Clear all overdue payments</Link>
          </span>
          <p className="mb-2">
            You can also watch this{" "}
            <a
              href="https://rebrand.ly/mt7h147"
              className="underline text-blue-500"
              target="_blank"
            >
              Demo video
            </a>{" "}
            to understand how payment works.
          </p>
        </p>
      </div>
    );
  } else {
    return <></>;
  }
};
export { PaymentReminder };
