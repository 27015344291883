import { useForm, useFieldArray, useWatch, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  API_URL,
  FETCH_STATUS,
  POSLayoutInit,
  POSLayoutSchema,
  axiosApiInstance,
  axiosApiInstanceGet,
  fetchArea,
  getAPIErr,
  getAPIErrMessage,
  getSelectedItem,
  getSelectedItem2,
} from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import {
  getItems,
  getPosItems,
  getPosLayout,
  resetGetPosLayout,
  resetUpdatePosLayout,
  selectItems,
  selectLayout,
  updatePosLayout,
} from "../../redux/slices";
import {
  ErrorModal,
  Loading,
  SearchSelect,
  Spinner,
  SuccessModal,
} from "../UI";
import { useState, useMemo, useEffect } from "react";
import POSLayoutPreview from "./POSLayoutPreview";

import { Delete } from "../Icons";
import {
  useDataMutateWithKey,
  useLoggedInuser,
  usePosLayout,
  useRequest,
  useToggle,
} from "../../hooks";
import { useCallback } from "react";

const serviceAreas = [
  { id: 0, serviceArea: "Cinema" },
  { id: 1, serviceArea: "FastFood" },
  { id: 2, serviceArea: "Arcade" },
  { id: 3, serviceArea: "Cafe" },
];

const POSLayoutForm = () => {
  const { err, setPending, setResolved, status, resetErr, setRejected } =
    useRequest();

  const { profile } = useLoggedInuser();
  const [preview, setPreview] = useToggle(false);

  const [selection, setSelection] = useState([
    { serviceArea: 0, currentPage: 0 },
    { serviceArea: 1, currentPage: 0 },
    { serviceArea: 2, currentPage: 0 },
    { serviceArea: 3, currentPage: 0 },
  ]);

  const dispatch = useDispatch();

  const { posItems } = useSelector(selectItems);
  const {
    getPosLayoutStatus,
    getPosLayoutErr,
    updatePosLayoutStatus,
    updatePosLayoutErr,
  } = useSelector(selectLayout);
  const { mutatedData: itemsMutateWithId } = useDataMutateWithKey(
    posItems,
    "id"
  );

  const getItemDropdown = useCallback(() => {
    dispatch(getPosItems(profile.cinemId));
  }, [dispatch, profile.cinemId]);

  useEffect(() => {
    getItemDropdown();
  }, [getItemDropdown]);

  const {
    register,
    control,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(POSLayoutSchema),
    defaultValues: POSLayoutInit,
  });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "posLayout",
  });

  const posLayout = useWatch({ control, name: "posLayout" });
  const { addItem, addPage, removeItem, removePage, itemsWithPosSource } =
    usePosLayout(update, posLayout, posItems);
  const formValues = getValues();

  const getLayout = useCallback(async () => {
    try {
      await dispatch(getPosLayout(true))
        .unwrap()
        .then((layout) => {
          //  if(layout)
          reset({
            posLayout: layout?.serviceAreas.map((area) => ({
              ...area,
              serviceArea: fetchArea[area.serviceArea],
              pages: area.pages.map((page) => ({
                ...page,
                pageItems: page.pageItems.map((itemId) => ({ itemId })),
              })),
            })),
          });
        });
    } catch (error) {}
  }, [dispatch, reset]);

  useEffect(() => {
    getLayout();
  }, [getLayout]);

  const onSubmit = async (data) => {
    const body = data.posLayout.map((layout) => ({
      ...layout,
      pages: layout.pages.map((page) => ({
        ...page,
        pageItems: page.pageItems.map((item) => item.itemId),
      })),
    }));

    try {
      body.forEach((layout) => {
        if (layout.pages.length > 10) {
          throw new Error(
            "Numbers of pages or items exceeded(should be 10 and 15 repectively)"
          );
        } else {
          layout.pages.forEach((page) => {
            if (page.pageItems.length > 15) {
              throw new Error("Number of items exceeded(should be 15 max)");
            }
          });
        }
      });
      setPending();
      await dispatch(
        updatePosLayout({
          serviceAreas: body,
        })
      ).unwrap();
      setResolved();

      getLayout();
    } catch (err) {
      setRejected(err.message || err);
    }
  };

  const onError = (error) => {
    console.log(error);
  };

  return (
    <div className="w-[95%] mx-auto mt-6">
      <div className="flex items-center justify-between p-4 px-8 rounded-t-lg bg-gradient-to-b from-app-purple-4 to-app-purple-3">
        <div className="flex gap-4">
          <span className="text-xl font-semibold text-white">POS Layout</span>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="flex items-center justify-end px-4 py-3 bg-white ">
          <div className="flex gap-3 buttongroup ">
            <button
              disabled={!isValid}
              className="p-1 px-3 disabled:cursor-not-allowed rounded-md text-[#C96FCC] bg-[#ffe583] shadow-md hover:shadow-sm"
              type="button"
              onClick={setPreview}
            >
              Preview
            </button>
            <p className="font-extrabold text-xl">
              {errors?.posLayout?.message}
            </p>
            <button
              className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm inline-flex items-center justify-center space-x-2"
              type="submit"
            >
              <span>Save</span>
              <Spinner
                className="!w-3 !h-3 text-white fill-app-yellow"
                status={status}
              />
            </button>
          </div>
        </div>
        <div className="relative p-3 bg-app-purple-2">
          <Loading
            wrapperClassName="absolute top-0 left-0 right-0 min-h-[50vh]"
            show={
              getPosLayoutStatus === FETCH_STATUS.PENDING ||
              updatePosLayoutStatus === FETCH_STATUS.PENDING
            }
          />
          {posLayout?.length < 4 && (
            <button
              className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm"
              type="button"
              onClick={() => {
                append({
                  serviceArea: "",
                  pages: [{ pageName: "", pageItems: [{ itemId: "" }] }],
                });
              }}
            >
              Add Service Area
            </button>
          )}
          {fields?.map((field, layoutIndex) => {
            return (
              <div key={field.id}>
                <div className=" border-4 border-app-purple-4 my-4 p-4">
                  <div className=" pb-3.5 ">
                    <label
                      htmlFor={`status`}
                      className="block mb-2 text-md font-bold text-[#666666] "
                    >
                      Service Area
                    </label>
                    <select
                      value={getValues(`posLayout.${layoutIndex}.serviceArea`)}
                      onChange={(e) => {
                        update(layoutIndex, {
                          serviceArea: +e.target.value,
                          pages: posLayout[layoutIndex].pages,
                          // pages: [],
                        });
                      }}
                      className="block w-[7rem] md:w-[15rem] p-2 h-[40px] text-gray-900 bg-[#d0a3d3] border border-gray-300 rounded-lg sm:text-xs focus:ring-blue-500 focus:border-blue-500 "
                    >
                      <option value={""} disabled>
                        Select Service Area
                      </option>
                      {serviceAreas.map((x) => {
                        return (
                          <option key={x.id} value={x.id}>
                            {x.serviceArea}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <button
                    className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm"
                    type="button"
                    onClick={() => {
                      addPage(layoutIndex);
                    }}
                  >
                    Add Page
                  </button>

                  <div className="mt-2">
                    {field?.pages?.map((page, pageIndex) => {
                      return (
                        <button
                          key={pageIndex}
                          className={`p-1 px-3 rounded-md  shadow-md hover:shadow-sm ml-2 ${
                            selection.find((x) => {
                              return x.serviceArea === field.serviceArea;
                            })?.currentPage === pageIndex
                              ? "bg-[#C96FCC] text-[#ffe583] "
                              : "bg-transparent border border-[#C96FCC] text-[#666666]"
                          }`}
                          type="button"
                          onClick={() => {
                            setSelection((prev) => {
                              return [
                                ...prev.filter((x) => {
                                  return x.serviceArea !== field.serviceArea;
                                }),
                                {
                                  serviceArea: field.serviceArea,
                                  currentPage: pageIndex,
                                },
                              ];
                            });
                          }}
                        >
                          {`Page ${pageIndex + 1}`}
                        </button>
                      );
                    })}
                  </div>
                  {fields
                    ?.find((instance) => {
                      return instance.serviceArea === field.serviceArea;
                    })
                    ?.pages.filter((pageInstance, index) => {
                      return (
                        index ===
                        selection?.find((x) => {
                          return x.serviceArea === field.serviceArea;
                        })?.currentPage
                      );
                    })
                    .map((page, i) => {
                      const pageIndex = selection?.find((x) => {
                        return x.serviceArea === field.serviceArea;
                      })?.currentPage;
                      return (
                        <div
                          key={`posLayout.${layoutIndex}.pages.${pageIndex}.pageName`}
                        >
                          <div className=" border-4 border-[#C96FCC] my-4 p-4 ">
                            <div className=" pb-3.5 ">
                              <label
                                htmlFor={`pageName`}
                                className="block mb-2 text-md font-bold text-[#666666] "
                              >
                                Page Name
                              </label>
                              <input
                                id={`pageName`}
                                type={"text"}
                                value={getValues(
                                  `posLayout.${layoutIndex}.pages.${pageIndex}.pageName`
                                )}
                                onChange={(e) => {
                                  setValue(
                                    `posLayout.${layoutIndex}.pages.${pageIndex}.pageName`,
                                    e.target.value
                                  );
                                }}
                                className="block w-[7rem] md:w-[15rem] p-2 h-[40px] text-gray-900 bg-[#d0a3d3] border border-gray-300 rounded-lg sm:text-xs focus:ring-blue-500 focus:border-blue-500 "
                              />
                            </div>

                            <button
                              className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm"
                              type="button"
                              onClick={() => {
                                addItem({ layoutIndex, pageIndex });
                              }}
                            >
                              Add Item
                            </button>

                            <div className=" border-2 border-[#C96FCC] my-4 p-4 grid grid-cols-3 mx-auto">
                              {page?.pageItems?.map((field3, itemIndex) => {
                                return (
                                  <div className=" pb-3.5 " key={field3.itemId}>
                                    <label className="block mb-2 text-md font-bold text-[#666666] ">
                                      {`Item ${itemIndex + 1}`}
                                    </label>
                                    <div className="flex gap-2">
                                      <Controller
                                        control={control}
                                        name={`posLayout.${layoutIndex}.pages.${pageIndex}.pageItems.${itemIndex}.itemId`}
                                        render={({
                                          field: { value, onChange },
                                        }) => {
                                          return (
                                            <>
                                              <SearchSelect
                                                items={itemsWithPosSource}
                                                dropDownNameKey="name"
                                                inputClassName="!w-full p-2 px-4 py-2 border-none !bg-app-purple-10"
                                                listClassName="!py-2"
                                                id={`itemId`}
                                                value={getSelectedItem2(
                                                  itemsWithPosSource,
                                                  value,
                                                  "id"
                                                )}
                                                onChange={(value) =>
                                                  onChange(value?.id)
                                                }
                                                displayValue={(value) => {
                                                  return value?.name;
                                                }}
                                                placeholder={`Select Items`}
                                                searchOptions={{
                                                  keys: ["name"],
                                                }}
                                              />
                                            </>
                                          );
                                        }}
                                      />

                                      <button
                                        type="button"
                                        onClick={() => {
                                          removeItem({
                                            layoutIndex,
                                            pageIndex,
                                            itemIndex,
                                          });
                                        }}
                                      >
                                        <Delete />
                                      </button>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          <div className="flex justify-end">
                            <button
                              className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm"
                              type="button"
                              onClick={() => {
                                removePage({ layoutIndex, pageIndex });
                              }}
                            >
                              Remove Page
                            </button>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="flex justify-end">
                  <button
                    className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm"
                    type="button"
                    onClick={() => {
                      remove(layoutIndex);
                    }}
                  >
                    Remove Service Area
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </form>
      {preview && (
        <POSLayoutPreview
          itemsById={itemsMutateWithId}
          show={preview}
          onClose={setPreview}
          formValues={formValues}
        />
      )}
      <ErrorModal
        show={!!err || !!updatePosLayoutErr}
        message={
          err?.message
            ? err?.message
            : getAPIErr(err, "Unable to update pos layout")
        }
        onClose={resetErr}
        onClick={resetErr}
      />
      <ErrorModal
        show={!!getPosLayoutErr}
        message={getPosLayoutErr}
        onClose={() => dispatch(resetGetPosLayout())}
        onClick={() => dispatch(resetGetPosLayout())}
      />
      <SuccessModal
        onClick={() => dispatch(resetUpdatePosLayout())}
        message="PosLayout Updated successfully"
        show={updatePosLayoutStatus === FETCH_STATUS.RESOLVED}
      />
    </div>
  );
};

export { POSLayoutForm };
