import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addItemDirect,
  API_URL,
  axiosApiInstanceGet,
  editItemDirect,
  FETCH_STATUS,
  onReturnReject,
} from "../../utils";

export const getLocations = createAsyncThunk(
  "getLocations",
  async (force, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/StoreLocations/ListAll`,
        force
      );
      return fulfillWithValue(response?.data?.data.pagedList);
    } catch (error) {
      return rejectWithValue(onReturnReject(error));
    }
  }
);

const initialState = {
  locations: [],
  locationsStatus: FETCH_STATUS.IDLE,
  locationsErr: null,
};
export const locationSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    addLocation: (state, action) => {
      state.locations = addItemDirect(state, action, "locations");
    },
    editLocation: (state, action) => {
      editItemDirect(state, action, "locations");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLocations.pending, (state) => {
        state.locationsStatus = FETCH_STATUS.PENDING;
        state.locationsErr = "";
      })
      .addCase(getLocations.fulfilled, (state, action) => {
        state.locationsStatus = FETCH_STATUS.RESOLVED;
        state.locationsErr = "";
        state.locations = action.payload;
      })
      .addCase(getLocations.rejected, (state, action) => {
        state.locationsStatus = FETCH_STATUS.REJECTED;
        state.locationsErr = action.payload || "Unable to retrieve locations";
        state.locations = action.payload;
      });
  },
});

export const selectLocations = (state) => state.locations;

export const { addLocation, editLocation } = locationSlice.actions;
