import React from "react";

const PageHeader = ({ children, className = "" }) => {
  return (
    <div
      className={`flex items-center justify-between bg-gradient-to-b from-app-purple-4 to-app-purple-3 p-4 px-8 rounded-t-lg ${className}`}
    >
      {children}
    </div>
  );
};

export { PageHeader };
