import React, { useMemo, useState } from "react";
import RosterToolbar from "./RosterToolbar";
import RosterWeekView from "./view/RosterWeekView";

const Roster = React.memo(
  ({
    date,
    getRosterInfo,
    renderEvent,
    renderResource,
    events,
    eventGroupByKey,
    toggleCreate,
    resources,
    resourceIdentifier,
    renderContextMenu = () => {},
  }) => {
    const [activeDate, setActiveDate] = useState(
      date ? new Date(date) : new Date()
    );

    const props = useMemo(
      () => ({
        activeDate,
        renderEvent,
        renderResource,
        events /*roster sessions */,
        eventGroupByKey /*shiftId */,
        resources /*shifts */,
        resourceIdentifier,
        renderContextMenu,
      }),
      [
        activeDate,
        eventGroupByKey,
        events,
        renderContextMenu,
        renderEvent,
        renderResource,
        resourceIdentifier,
        resources,
      ]
    );

    const onDateChange = (date) => {
      setActiveDate(date);
      getRosterInfo(date);
    };

    return (
      <>
        <div className="bg-white rounded-lg px-2.5 py-1">
          <RosterToolbar
            setActiveDate={onDateChange}
            activeDate={activeDate}
            toggleCreate={toggleCreate}
          />
          <RosterWeekView {...props} />
        </div>
      </>
    );
  }
);

export { Roster };
