import { Fragment, useState } from "react";
import { Dialog, Transition, Listbox, Combobox } from "@headlessui/react";
import add from "date-fns/add";
import isEmpty from "lodash/isEmpty";

import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  sessionSchema,
  sessionInit,
  miniSession,
  validateSessions,
  FETCH_STATUS,
} from "../../utils";
import CreateSessionRow from "./CreateSessionRow";
import CreateSessionErr from "./CreateSessionErr";
import { Caret } from "../Icons";
import { InlineErr, Spinner } from "../UI";

const CreateSession = ({
  events,
  formats = [],
  show,
  onClose,
  priceCardsErr,
  onCreate = () => {},
  films = [],
  screens = [],
  ticketTypes = [],
  showTimesStatusList = [],
  getStatusOptList,
  showTimesStatusListErr,
  showTimesStatusListStatus,
  resetErrs,
  addingStatus,
  addingErr,
}) => {
  const {
    control,
    handleSubmit,
    watch,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(sessionSchema),
    defaultValues: {
      ...sessionInit,
      sessions: [
        {
          ...miniSession,
          startTime: new Date(),
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "sessions",
  });
  const sessions = watch("sessions");

  const [filmQ, setFilmQ] = useState("");
  const [validErrs, setValidErrs] = useState([]);

  const close = () => {
    onClose();
  };

  const filteredFilms =
    filmQ?.trim() === ""
      ? films
      : films.filter((film) => {
          return film.title.toLowerCase().includes(filmQ.toLowerCase());
        });

  const [activeFilm] = watch(["film"]);

  const onSubmit = (data) => {
   
    data.sessions = data.sessions.map((d) => ({
      ...d,
      status:
        showTimesStatusList.findIndex((s) => s === d.status) > -1
          ? showTimesStatusList.findIndex((s) => s === d.status)
          : 0,
      endTime: add(new Date(d.startTime), {
        minutes: activeFilm?.duration + +d.trailerDuration,
        // trailer: d.trailerDuration
      }),
    }));

    const errors = validateSessions(data.sessions, events);

    if (errors.length) {
    
      setValidErrs(errors);
    } else {
      onCreate(data);
    }
  };

  return (
    <Transition show={show} as={Fragment}>
      <Dialog as="div" className="relative z-[1000000]" onClose={close}>
        {/* Overlay */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        {/* Content */}

        <div className="fixed inset-0 p-4 overflow-y-auto">
          <div className="flex items-center justify-center min-h-[400px] p-2 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full min-h-[400px] max-w-2xl p-6  text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title className="relative pb-4 border-b" as="div">
                  <p className="text-lg font-medium leading-6 text-gray-900">
                    Create Film Session<span className="text-sm">(</span>s
                    <span className="text-sm">)</span>
                  </p>
                  <button
                    type="button"
                    onClick={close}
                    className="absolute right-0 top-2/4 -translate-y-2/4 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </Dialog.Title>

                <form className="my-6" onSubmit={handleSubmit(onSubmit)}>
                  <div className="grid grid-cols-2 gap-2">
                    <div className="relative">
                      <Controller
                        control={control}
                        name="film"
                        render={({ field: { onChange, value } }) => (
                          <Combobox onChange={onChange} value={value}>
                            <div className="relative w-full text-left">
                              <Combobox.Label className="block text-sm">
                                Select Film
                              </Combobox.Label>
                              <div className="relative">
                                <Combobox.Input
                                  className="w-full py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 border-none rounded-lg shadow-md outline-none"
                                  displayValue={(film) => film?.title}
                                  onChange={(event) =>
                                    setFilmQ(event.target.value)
                                  }
                                />
                                <Combobox.Button className="absolute right-0 flex items-center pr-2 top-2/4 -translate-y-2/4">
                                  <Caret />
                                </Combobox.Button>

                                <InlineErr
                                  err={
                                    errors?.film
                                      ? "Please select a valid film"
                                      : ""
                                  }
                                />
                              </div>
                            </div>

                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                              afterLeave={() => setFilmQ("")}
                            >
                              <Combobox.Options className="max-h-[200px] overflow-y-auto rounded-md bg-white shadow-lg">
                                {filteredFilms.length === 0 && filmQ !== "" ? (
                                  <div className="relative px-4 py-2 text-gray-700 cursor-default select-none">
                                    Nothing found.
                                  </div>
                                ) : (
                                  filteredFilms.map((film) => (
                                    <Combobox.Option
                                      key={film.id}
                                      className={({ active }) =>
                                        `relative cursor-default select-none p-2 ${
                                          active
                                            ? "bg-app-purple-2 text-white"
                                            : "text-gray-900"
                                        } ${
                                          activeFilm?.id === film.id
                                            ? "!bg-app-purple-4 !text-white"
                                            : ""
                                        }
                                        
                                        `
                                      }
                                      value={film}
                                    >
                                      <p>
                                        {film.title}
                                        <small> ({film.duration} mins)</small>
                                      </p>
                                    </Combobox.Option>
                                  ))
                                )}
                              </Combobox.Options>
                            </Transition>
                          </Combobox>
                        )}
                      />
                    </div>
                    <div className="relative">
                      <InlineErr
                        retry={getStatusOptList}
                        err={showTimesStatusListErr}
                        status={showTimesStatusListStatus}
                      />

                      <InlineErr err={errors?.status?.message} />

                      <Controller
                        name="status"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Listbox value={value} onChange={onChange}>
                            <Listbox.Label className={"text-sm block"}>
                              Select status
                            </Listbox.Label>
                            <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow-md cursor-default sm:text-sm">
                              <span className="block truncate">{value}</span>
                              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <Caret />
                              </span>
                            </Listbox.Button>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options
                                className={`absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 z-20 focus:outline-none sm:text-sm`}
                              >
                                {showTimesStatusList.map((s) => (
                                  <Listbox.Option
                                    key={s}
                                    className={({ active, selected }) =>
                                      `relative cursor-default select-none p-2 ${
                                        active
                                          ? "bg-app-purple-2 text-white"
                                          : "text-gray-900"
                                      } ${
                                        selected
                                          ? "!bg-app-purple-4 !text-white"
                                          : ""
                                      }
                                        
                                        `
                                    }
                                    value={s}
                                  >
                                    {s}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </Listbox>
                        )}
                      />
                    </div>
                  </div>

                  <h4 className="mt-5 text-base">Add sessions</h4>
                  <hr className="my-2" />

                  <div className="relative">
                    {!sessions.length ? (
                      <small className="block my-1 font-medium text-center text-rose-500">
                        Please create at least one session
                      </small>
                    ) : null}
                    {fields?.map((field, index) => (
                      <CreateSessionRow
                        key={index}
                        register={register}
                        ticketTypes={ticketTypes}
                        activeFilm={activeFilm}
                        index={index}
                        control={control}
                        sessions={sessions}
                        remove={remove}
                        formats={formats}
                        screens={screens}
                        errors={errors}
                        priceCardsErr={priceCardsErr}
                      />
                    ))}
                  </div>

                  <button
                    onClick={() =>
                      append({
                        ...sessions[sessions.length - 1],
                      })
                    }
                    type="button"
                    className="p-2 mt-2 text-sm text-white bg-green-400 rounded-lg"
                  >
                    Add session
                  </button>

                  <div className="flex items-center justify-center mt-4">
                    <button
                      type="submit"
                      disabled={addingStatus === FETCH_STATUS.PENDING}
                      className="inline-flex justify-center items-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md bg-app-purple-6 "
                    >
                      Submit{" "}
                      <Spinner
                        className="!w-3 !h-3 mx-1 text-white fill-pink-400 items-center"
                        status={addingStatus}
                      />
                    </button>
                  </div>
                </form>

                <CreateSessionErr
                  onDismiss={() => setValidErrs([])}
                  errors={validErrs}
                />

                <CreateSessionErr
                  onDismiss={resetErrs}
                  errors={
                    !isEmpty(addingErr)
                      ? [
                          {
                            id: 0,
                            message: addingErr,
                          },
                        ]
                      : []
                  }
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export { CreateSession };
