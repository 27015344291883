import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
  API_URL,
  API_URLV2,
  axiosApiInstance,
  axiosApiInstanceGet,
  BIGNUMBER,
  FETCH_STATUS,
  getErrMsg,
} from "../../utils";

export const getFilms = createAsyncThunk(
  "get_Films",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/films/ListAll?page=1&perPage=${BIGNUMBER}`
      );

      const films = response.data.data;

      return fulfillWithValue(films);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(error?.response?.data?.toString() || message);
    }
  }
);

export const getFilmFormat = createAsyncThunk(
  "getFilmFormat",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/FilmFormats/ListAll`
      );

      const formats = response.data.data;

      return fulfillWithValue(formats);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(error?.response?.data?.toString() || message);
    }
  }
);

export const getFilmsByCinema = createAsyncThunk(
  "getFilmsByCinema",
  async ({ force, cinemaId }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const res = await axiosApiInstanceGet(
        `${API_URL}/Films/ByCinema?cinemaId=${cinemaId}&page=1&perPage=2000`,
        force
      );
      return fulfillWithValue(res?.data?.data);
    } catch (error) {
      return rejectWithValue(getErrMsg(error));
    }
  }
);
export const getFilmsByDistributor = createAsyncThunk(
  "getFilmsByDistributor",
  async ({ force, distributorId }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const res = await axiosApiInstanceGet(
        `${API_URL}/Films/ByDistributor?distributorId=${distributorId}&page=1&perPage=2000`,
        force
      );
      return fulfillWithValue(res?.data?.data);
    } catch (error) {
      return rejectWithValue(getErrMsg(error));
    }
  }
);

export const getFilmXFilms = createAsyncThunk(
  "get_FIlmx_Films",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URLV2}/FilmXFilms/ListAll?perPage=${BIGNUMBER}`
      );

      const films = response.data.data.pagedList;

      return fulfillWithValue(films);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(error?.response?.data?.toString() || message);
    }
  }
);

export const importFilms = createAsyncThunk(
  "import_Films",
  async (selectedFilms, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Films/ImportFilm`,
        selectedFilms
      );

      if (response.data) {
        return fulfillWithValue(true);
      }
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(error?.response?.data?.toString() || message);
    }
  }
);

export const getGenre = createAsyncThunk(
  "getGenre",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    // const force = true;
    try {
      const response = await axiosApiInstanceGet(`${API_URL}/Genres/ListAll`);
      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

const initialState = {
  getFilmsError: "",
  getFilmsStatus: FETCH_STATUS.IDLE,
  films: [],
  getFilmXFilmsError: "",
  getFilmXFilmsStatus: FETCH_STATUS.IDLE,
  filmXFilms: [],
  importFilmsError: "",
  importFilmsStatus: FETCH_STATUS.IDLE,
  getFilmsByCinemaErr: null,
  getFilmsByCinemaStatus: FETCH_STATUS.IDLE,
  filmsByCinema: [],
  getFilmsByDistributorErr: null,
  getFilmsByDistributorStatus: FETCH_STATUS.IDLE,
  filmsByDistributor: [],
  filmFormat: [],
  getFilmFormatStatus: FETCH_STATUS.IDLE,
  getFilmFormatErr: null,
  genres: [],
  getGenreStatus: FETCH_STATUS.IDLE,
  getGenreErr: null,
};

export const filmsSlice = createSlice({
  name: "films",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFilms.pending, (state) => {
        state.getFilmsError = "";
        state.getFilmsStatus = FETCH_STATUS.PENDING;
      })
      .addCase(getFilms.fulfilled, (state, action) => {
        state.getFilmsError = "";
        state.getFilmsStatus = FETCH_STATUS.RESOLVED;
        state.films = action.payload;
      })
      .addCase(getFilms.rejected, (state, action) => {
        state.getFilmsError = action.payload;
        state.getFilmsStatus = FETCH_STATUS.REJECTED;
      })
      .addCase(getFilmXFilms.pending, (state) => {
        state.getFilmXFilmsError = "";
        state.getFilmXFilmsStatus = FETCH_STATUS.PENDING;
      })
      .addCase(getFilmXFilms.fulfilled, (state, action) => {
        state.getFilmXFilmsError = "";
        state.getFilmXFilmsStatus = FETCH_STATUS.RESOLVED;
        state.filmXFilms = action.payload;
      })
      .addCase(getFilmXFilms.rejected, (state, action) => {
        state.getFilmXFilmsError = action.payload;
        state.getFilmXFilmsStatus = FETCH_STATUS.REJECTED;
      })
      .addCase(importFilms.pending, (state) => {
        state.importFilmsError = "";
        state.importFilmsStatus = FETCH_STATUS.PENDING;
      })
      .addCase(importFilms.fulfilled, (state, action) => {
        state.importFilmsError = "";
        state.importFilmsStatus = FETCH_STATUS.RESOLVED;
      })
      .addCase(importFilms.rejected, (state, action) => {
        state.importFilmsError = action.payload;
        state.importFilmsStatus = FETCH_STATUS.REJECTED;
      })

      .addCase(getFilmsByCinema.pending, (state) => {
        state.getFilmsByCinemaErr = "";
        state.getFilmsByCinemaStatus = FETCH_STATUS.PENDING;
      })
      .addCase(getFilmsByCinema.fulfilled, (state, action) => {
        state.getFilmsByCinemaErr = null;
        state.getFilmsByCinemaStatus = FETCH_STATUS.RESOLVED;
        state.filmsByCinema = action.payload;
      })
      .addCase(getFilmsByCinema.rejected, (state, action) => {
        state.getFilmsByCinemaErr =
          action.payload || "unable to get films by cinema";
        state.getFilmsByCinemaStatus = FETCH_STATUS.REJECTED;
      })

      .addCase(getFilmsByDistributor.pending, (state) => {
        state.getFilmsByDistributorErr = "";
        state.getFilmsByDistributorStatus = FETCH_STATUS.PENDING;
      })
      .addCase(getFilmsByDistributor.fulfilled, (state, action) => {
        state.getFilmsByDistributorErr = null;
        state.getFilmsByDistributorStatus = FETCH_STATUS.RESOLVED;
        state.filmsByDistributor = action.payload;
      })
      .addCase(getFilmsByDistributor.rejected, (state, action) => {
        state.getFilmsByDistributorErr =
          action.payload || "unable to get films by distributor";
        state.getFilmsByDistributorStatus = FETCH_STATUS.REJECTED;
      })

      .addCase(getFilmFormat.pending, (state) => {
        state.getFilmFormatErr = "";
        state.getFilmFormatStatus = FETCH_STATUS.PENDING;
      })
      .addCase(getFilmFormat.fulfilled, (state, action) => {
        state.getFilmFormatErr = null;
        state.getFilmFormatStatus = FETCH_STATUS.RESOLVED;
        state.filmFormat = action.payload;
      })
      .addCase(getFilmFormat.rejected, (state, action) => {
        state.getFilmFormatStatus = FETCH_STATUS.REJECTED;
        state.getFilmFormatErr = action.payload || "unable to get film format";
      })
      .addCase(getGenre.pending, (state) => {
        state.getGenreErr = "";
        state.getGenreStatus = FETCH_STATUS.PENDING;
      })
      .addCase(getGenre.fulfilled, (state, action) => {
        state.getGenreErr = null;
        state.getGenreStatus = FETCH_STATUS.RESOLVED;
        state.genres = action.payload;
      })
      .addCase(getGenre.rejected, (state, action) => {
        state.getGenreErr = action.payload || "unable to get genre";
        state.getGenreStatus = FETCH_STATUS.REJECTED;
      });
  },
});

export const selectFilms = (state) => ({
  films: state.films.films.pagedList ?? [],
  getFilmsStatus: state.films.getFilmsStatus,
  getFilmsError: state.films.getFilmsError,
  filmsByCinema: state.films.filmsByCinema.pagedList,
  getFilmsByCinemaStatus: state.films.getFilmsByCinemaStatus,
  getFilmsByCinemaErr: state.films.getFilmsByCinemaErr,
  filmsByDistributor: state.films.filmsByDistributor.pagedList,
  getFilmsByDistributorStatus: state.films.getFilmsByDistributorStatus,
  getFilmsByDistributorErr: state.films.getFilmsByDistributorErr,
  filmFormat: state.films.filmFormat ?? [],
  getFilmFormatStatus: state.films.getFilmFormatStatus,
  getFilmFormatErr: state.films.getFilmFormatErr,
  genres: state.films.genres ?? [],
  getGenreStatus: state.films.getGenreStatus,
  getGenreErr: state.films.getGenreErr,
});
// export const selectFilms = (state) => state.films

export const filmsReducer = filmsSlice.reducer;

// filmXFilms: state.filmXfilms.films.pagedList ?? [],
