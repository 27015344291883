import { useState, useEffect } from "react";
import { useRequest } from "../../../hooks";
import { FETCH_STATUS, axiosApiInstanceGet, API_URL } from "../../../utils";
import {
  FormError,
  KioskTerminalModal,
  PrivateRoute,
  Spinner,
} from "../../../components";
import { Edit, Delete } from "../../../components/Icons";

const KioskTerminals = () => {
  const [openModal, setOpenModal] = useState(false);
  const [terminalData, setTerminalData] = useState({});
  const { err, status, setResolved, setPending, data, setRejected, resetErr } =
    useRequest();

  useEffect(() => {
    loadList();
  }, []);
  const loadList = async () => {
    const force = true;
    try {
      setPending();
      const res = await axiosApiInstanceGet(
        `${API_URL}/KioskTerminals/ListAll`,
        force
      );
      setResolved(res?.data?.data?.pagedList);
    } catch (error) {
      setRejected(error.message || error);
    }
  };

  return (
    <PrivateRoute redirectTo={`/dashboard/finance/kiosk-terminals`}>
      <div className=" w-[60%] mx-auto mt-6">
        <div className="flex items-center justify-between p-4 px-8 rounded-t-lg bg-gradient-to-b from-app-purple-4 to-app-purple-3">
          <div className="flex gap-4">
            <span className="text-xl font-semibold text-white">
              Kiosk Terminals
            </span>
          </div>
          <button
            className="p-1 px-3 rounded-md text-[#C96FCC] bg-white shadow-md hover:font-bold"
            type="button"
            onClick={() => {
              setOpenModal(true);
              setTerminalData({});
            }}
          >
            Create
          </button>
        </div>

        <div className="flex justify-center mb-2">
          <Spinner
            className="!w-10 !h-10"
            status={status}
            foreFill="fill-app-purple-4"
            bgFill="text-gray-300"
          />
        </div>
        <FormError
          className="absolute top-0 z-20 transform left-2/4 -translate-x-2/4"
          err={err}
          onDismiss={resetErr}
        />

        <ul className=" bg-app-purple-2 rounded-b-lg pb-3">
          {data?.length < 1 && status === FETCH_STATUS.RESOLVED && (
            <p className="text-center py-2"> No Terminals have been created</p>
          )}
          {data?.map((terminal, i) => {
            return (
              <li
                key={i}
                className="flex justify-between p-3 items-center bg-app-purple-2 border-b border-[#00000033]"
              >
                <div>{terminal?.terminalName}</div>
                <div className="flex">
                  <button
                    className="mr-[30px]"
                    onClick={() => {
                      setOpenModal(true);
                      setTerminalData(terminal);
                    }}
                  >
                    <Edit />
                  </button>
                  <button className="mr-[30px]">
                    <Delete />
                  </button>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      <KioskTerminalModal
        openStatus={openModal}
        closeFunc={() => {
          setOpenModal(false);
        }}
        loadList={loadList}
        data={terminalData}
      />
    </PrivateRoute>
  );
};
export { KioskTerminals };
