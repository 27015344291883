import React, { useMemo } from "react";
import {
  dateAndTime,
  formatDateTime,
  formatNumberAsCurrency,
} from "../../utils";
import { format } from "../Films/DateTimeInput";
import { DefaultListTable } from "../UI";
import ReachLogo from "../../assets/images/logo.png";
import { useLoggedInuser } from "../../hooks";
import { addHours } from "date-fns";

const BookingsByGenreTable = ({ data, id, paramFilter }) => {
  const { profile } = useLoggedInuser();
  const columns = useMemo(() => {
    return [
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }) => (id ? value : formatNumberAsCurrency(value)),
      },
      {
        Header: "Date Created",
        accessor: "dateCreated",

        Cell: ({ value }) => formatDateTime(value, format),
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Film Details",
        accessor: "filmDetail",
        Cell: ({ value }) => {
          return value?.map((film) => {
            return (
              <>
                <p>{`film: ${film.film}`}</p>
                <p>{`genre: ${film.genres.map((genre) => genre + " ")}`}</p>
              </>
            );
          });
        },
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Phone Number",
        accessor: "phoneNumber",
      },
    ];
  }, [id]);
  return (
    <>
      <div className="flex justify-between">
        <div
          key={ReachLogo}
          className=" ml-[-5px] w-[5rem] h-[3rem] bg-no-repeat bg-contain bg-center"
          style={{
            backgroundImage: `url("${ReachLogo}")`,
          }}
        ></div>
        <div>
          <p>
            {" "}
            <span className="font-bold">User Name:</span>
            {` ${profile.firstName} ${profile.lastName}`}
          </p>
          <p>
            <span className="font-bold">Date Time Generated: </span>
            {formatDateTime(
              addHours(new Date(data[0]?.dateTimeGenerated), 1),
              dateAndTime
            )}
          </p>
        </div>
      </div>
      <DefaultListTable columns={columns} data={data} id={id} />
    </>
  );
};

export { BookingsByGenreTable };
