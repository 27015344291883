import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  Loading,
  PageHeader,
  PriceCardTable,
  PrivateRoute,
  ErrorWithWrapper,
} from "../../../../components";
import { selectPiceCards, getPriceCards } from "../../../../redux/slices";
import { FETCH_STATUS } from "../../../../utils";

const PriceCards = () => {
  const { priceCards, priceCardsStatus, priceCardsErr } =
    useSelector(selectPiceCards);

  const dispatch = useDispatch();

  const getInfo = useCallback(
    (force) => {
      dispatch(getPriceCards(force));
    },
    [dispatch]
  );

  useEffect(() => {
    if (priceCardsStatus === FETCH_STATUS.IDLE) {
      dispatch(getPriceCards());
    }
  }, [dispatch, priceCardsStatus]);

  return (
    <PrivateRoute redirectTo="/dashboard/price-cards">
      <PageHeader>
        <h1 className=" text-white font-semibold text-2xl items-center gap-3.5 grid grid-cols-[34px_minmax(0,1fr)]">
          <span>
            <svg
              width="34"
              height="32"
              viewBox="0 0 34 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M28.9634 10.8304V2.89154C28.9646 2.51197 28.8907 2.13591 28.7459 1.78493C28.6012 1.43396 28.3884 1.11498 28.1198 0.846316C27.8512 0.577651 27.5322 0.364586 27.1809 0.219352C26.8296 0.074118 26.4531 -0.000424997 26.0729 1.82258e-06H18.1254C17.6042 0.00107225 17.1046 0.207247 16.7347 0.573792L0.846178 16.4314C0.304286 16.9742 0 17.7092 0 18.4755C0 19.2418 0.304286 19.9769 0.846178 20.5196L8.41031 28.0712C8.95374 28.6123 9.68995 28.9162 10.4575 28.9162C11.225 28.9162 11.9612 28.6123 12.5046 28.0712L28.3887 12.2187C28.7559 11.8496 28.9625 11.3507 28.9634 10.8304ZM22.7569 8.26154C22.3478 8.26154 21.9478 8.14041 21.6076 7.91346C21.2673 7.68652 21.0022 7.36395 20.8456 6.98655C20.689 6.60915 20.648 6.19387 20.7279 5.79322C20.8077 5.39258 21.0047 5.02456 21.2941 4.73571C21.5834 4.44686 21.952 4.25015 22.3533 4.17046C22.7546 4.09077 23.1706 4.13167 23.5486 4.28799C23.9267 4.44432 24.2498 4.70904 24.4771 5.04869C24.7044 5.38834 24.8258 5.78766 24.8258 6.19616C24.8258 6.74393 24.6078 7.26927 24.2198 7.65661C23.8318 8.04394 23.3056 8.26154 22.7569 8.26154Z"
                fill="currentColor"
              />
              <path
                d="M32.0666 3.09961C31.7923 3.09961 31.5292 3.20841 31.3352 3.40208C31.1412 3.59575 31.0322 3.85841 31.0322 4.1323V12.3532L14.1383 29.2196C14.0382 29.3146 13.9582 29.4286 13.9029 29.5549C13.8476 29.6812 13.8183 29.8173 13.8165 29.9551C13.8147 30.0929 13.8406 30.2297 13.8926 30.3574C13.9447 30.4851 14.0217 30.6011 14.1194 30.6985C14.217 30.796 14.3332 30.873 14.4611 30.9249C14.589 30.9768 14.726 31.0027 14.8641 31.0009C15.0021 30.9992 15.1384 30.9698 15.2649 30.9146C15.3915 30.8594 15.5056 30.7795 15.6007 30.6796L32.5392 13.7693C32.7194 13.5885 32.8618 13.3739 32.9583 13.1378C33.0547 12.9017 33.1033 12.6488 33.101 12.3938V4.1323C33.101 3.85841 32.9921 3.59575 32.7981 3.40208C32.6041 3.20841 32.341 3.09961 32.0666 3.09961Z"
                fill="currentColor"
              />
            </svg>
          </span>
          <span>Price cards</span>
        </h1>

        <NavLink
          to="/dashboard/price-cards/create"
          type="button"
          className="px-4 py-3 text-base font-medium bg-white rounded-md text-app-purple-9"
        >
          Create Card
        </NavLink>
      </PageHeader>

      <ErrorWithWrapper
        error={priceCardsErr}
        show={priceCardsStatus === FETCH_STATUS.REJECTED}
        retry={getInfo}
      />
      <div className="relative">
        <Loading
          wrapperClassName="absolute top-0 left-0 right-0 min-h-[50vh]"
          show={priceCardsStatus === FETCH_STATUS.PENDING}
        />

        {priceCards?.length ? <PriceCardTable priceCards={priceCards} /> : null}
      </div>

      {/* <Success show={priceCardsStatus === FETCH_STATUS.RESOLVED}>
        <PriceCardTable
          pageCount={noOfPages}
          initPage={activePage}
          activePageIndexInData={activePageIndexInData}
          priceCards={data}
          onPageChange={(page) => getInfo({ page })}
         
        />
      </Success> */}
    </PrivateRoute>
  );
};

export { PriceCards };
