import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  PageHeader,
  PrivateRoute,
  SuccessModal,
  TicketForm,
  Success,
  ErrorWithWrapper,
  Loading,
} from "../../../../components";

import { useRequest } from "../../../../hooks";
import { editTicket } from "../../../../redux/slices";
import {
  API_URL,
  axiosApiInstance,
  axiosApiInstanceGet,
  didUpdateFields,
  FETCH_STATUS,
  Ticket,
  trueFalse,
} from "../../../../utils";

const EditTicket = () => {
  let params = useParams();

  const dispatch = useDispatch();

  const { data, err, status, setResolved, setPending, setRejected } =
    useRequest({});

  const {
    err: editErr,
    status: editStatus,
    setPending: editSetPending,
    setRejected: editSetRejected,
    setResolved: editSetResolved,
    resetErr,
  } = useRequest({});

  const onSave = async (values) => {
    try {
      editSetPending();

      const promises = [];

      const info = Ticket.toDTO(values);

      const oldData = {
        ...data,
        // active: null,
        // isActive: null,
        class: data.ticketGroupId,
      };

      const newData = {
        ...info,
        // active: null, 
        // isActive: null
      };

      if (didUpdateFields(oldData, newData)) {
     
        promises.push(
          await axiosApiInstance.patch(
            `${API_URL}/Tickets/${params.id}/Update`,
            info
          )
        );
      }

      // if (trueFalse[info.active] !== data.isActive) {
      //   promises.push(
      //     await axiosApiInstance.patch(`${API_URL}/Tickets/${params.id}/Toggle`)
      //   );
      // }

      const res = await Promise.all(promises);

      if (res.length) {
        let ticket = res[promises.length - 1].data.data;

        dispatch(editTicket(Ticket.toUI(ticket)));
      }
      editSetResolved();
    } catch (error) {
      editSetRejected(error || "Unable to update ticket");
    }
  };

  const getInfo = useCallback(async () => {
    if (params?.id) {
      try {
        setPending();
        const res = await axiosApiInstanceGet(
          `${API_URL}/Tickets/${params.id}`,
          true
        );

        const info = Ticket.toUI(res.data.data);

        setResolved(info);
      } catch (error) {
        setRejected(error);
      }
    }
  }, [params?.id, setRejected, setResolved, setPending]);

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  return (
    <PrivateRoute redirectTo={`/dashboard/tickets/${params?.id}`}>
      <PageHeader>
        <h1 className="text-2xl font-semibold text-white ">Edit Ticket</h1>
      </PageHeader>
      <Loading
        wrapperClassName="relative min-h-[50vh]"
        show={status === FETCH_STATUS.PENDING}
      />

      <ErrorWithWrapper
        error={err}
        show={status === FETCH_STATUS.REJECTED}
        retry={getInfo}
      />

      <Success show={status === FETCH_STATUS.RESOLVED}>
        <TicketForm
          info={data}
          isEdit
          status={editStatus}
          error={editErr}
          onSave={onSave}
          onDismiss={resetErr}
        />
      </Success>

      <SuccessModal
        message={`Edited ticket with id ${params?.id}`}
        show={editStatus === FETCH_STATUS.RESOLVED}
        backLink="/dashboard/tickets"
      />
    </PrivateRoute>
  );
};

export { EditTicket };
