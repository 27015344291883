import React from "react";

const UpArrowIcon = ({className}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
          viewBox="0 0 24 24"
          className={className}
    >
      <path fill="currentColor" d="M15 20H9v-8H4.16L12 4.16L19.84 12H15z" />
    </svg>
  );
};

export default UpArrowIcon;
