import React from "react";

const Reports = ({ fill = process.env.REACT_APP_COLOR_10 }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.6047 7.09843L18.9492 0.656244C18.5445 0.262494 17.9922 0.0382751 17.4234 0.0382751L4.88906 0.00546265C3.36875 0.00546265 2.13281 1.23046 2.13281 2.73984V25.2601C2.13281 26.7422 3.40703 28 4.91641 28H23.4555C24.9375 28 26.1406 26.7695 26.1406 25.2547V8.40546C26.1406 7.89687 25.9492 7.43202 25.6047 7.09843ZM18.9219 3.67499L22.3617 6.99999H19.518C19.2008 6.99999 18.9219 6.7539 18.9219 6.47499V3.67499ZM23.4555 25.8125H4.91641C4.61016 25.8125 4.32031 25.5445 4.32031 25.2547V2.73984C4.32031 2.44999 4.58828 2.19296 4.88906 2.19296L16.7344 2.22577V6.47499C16.7344 7.96796 17.9812 9.18749 19.518 9.18749H23.9531V25.2547C23.9531 25.5281 23.7672 25.8125 23.4555 25.8125Z"
        fill={fill}
      />
      <path
        d="M9.78906 8.36719C9.1875 8.36719 8.69531 8.85938 8.69531 9.46094V21.2734C8.69531 21.875 9.1875 22.3672 9.78906 22.3672C10.3906 22.3672 10.8828 21.875 10.8828 21.2734V9.46094C10.8828 8.85938 10.3906 8.36719 9.78906 8.36719ZM14.1641 11.2109C13.5625 11.2109 13.0703 11.7031 13.0703 12.3047V21.2734C13.0703 21.875 13.5625 22.3672 14.1641 22.3672C14.7656 22.3672 15.2578 21.875 15.2578 21.2734V12.3047C15.2578 11.7031 14.7656 11.2109 14.1641 11.2109ZM18.5391 14.0547C17.9375 14.0547 17.4453 14.5469 17.4453 15.1484V21.2734C17.4453 21.875 17.9375 22.3672 18.5391 22.3672C19.1406 22.3672 19.6328 21.875 19.6328 21.2734V15.1484C19.6328 14.5469 19.1406 14.0547 18.5391 14.0547Z"
        fill={fill}
      />
    </svg>
  );
};

export { Reports };
