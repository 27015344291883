import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    API_URL,
    axiosApiInstance,
    axiosApiInstanceGet,
    editItemDirect,
    FETCH_STATUS,
    getAPIErr,
    getErrMsg
} from "../../utils";

export const getStockItems = createAsyncThunk(
  "getStockItems",
  async ({ storeLocationId }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/ItemStocks/ListAll?storeLocationId=${storeLocationId}`,
        false
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);

export const getStockRequests = createAsyncThunk(
  "getStockRequests",
  async ({ force, ...data }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/StockRequests/ListAll?cinemaId=${data.cinemaId}&DateFrom=${data.DateFrom}&DateTo=${data.DateTo}&page=1&perPage=1000`,
        force
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);

export const getSimplifiedST = createAsyncThunk(
  "getSimplifiedST",
  async ({ startDate, endDate }, { fulfillWithValue, rejectWithValue }) => {
    const force = true;
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/StockTransfers/SimpleListAll?DateFrom=${startDate}&DateTo=${endDate}&status=InTransit&fetchType=Origin&page=1&perPage=1000`,
        force
      );

      return fulfillWithValue(response?.data?.data.pagedList);
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);

export const createTransferReceipt = createAsyncThunk(
  "createTransferReceipt",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/StockTransferReceipts/Create`,
        { ...data }
      );

      return fulfillWithValue(response?.data?.data);
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);

export const getTransferReceipts = createAsyncThunk(
  "getTransferReceipts",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    const force = true;
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/StockTransferReceipts/ListAll?DateFrom=${data.DateFrom}&DateTo=${data.DateTo}&page=1&perPage=1000`,
        force
      );

      return fulfillWithValue(response?.data?.data?.pagedList || []);
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);

export const getTransferReceipt = createAsyncThunk(
  "getTransferReceipt",
  async (id, { fulfillWithValue, rejectWithValue }) => {
    const force = true;
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/StockTransferReceipts/GetById?stockTransferReceiptId=${id}`,
        force
      );

      return fulfillWithValue(response?.data?.data || {});
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);

export const getItemsStock = createAsyncThunk(
  "getItemStock",
  async (
    { itemGroup, storeLocationId, cinemaId },
    { fulfillWithValue, rejectWithValue }
  ) => {
    const force = true;
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/ItemStocks/ListAll?itemGroupId=${itemGroup}${
          storeLocationId !== "allStores"
            ? `&storeLocationid=${storeLocationId}`
            : ""
        }&cinemaId=${cinemaId}`,
        force
      );
      console.log({ stock: response?.data?.data });
      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);

export const getRequisitionTypes = createAsyncThunk(
  "getRequisitionTypes",
  async (force, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/StockRequests/RequisitionTypes`,
        force
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);

export const getStoreLocations = createAsyncThunk(
  "getStoreLocations",
  async (force, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/StoreLocations/ListAll?page=1&perPage=1000`,
        force
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);

export const createStockRequest = createAsyncThunk(
  "createStockRequest",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/StockRequests/Create`,
        { ...data }
      );

      return fulfillWithValue(response?.data?.data);
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);

export const updateStockRequest = createAsyncThunk(
  "updateStockRequest",
  async ({ id, data }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.patch(
        `${API_URL}/StockRequests/SaveDraft?stockRequestId=${id}`,
        { ...data }
      );

      return fulfillWithValue(response?.data?.data);
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);

export const approveStockRequest = createAsyncThunk(
  "approveStockRequest",
  async (id, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.patch(
        `${API_URL}/StockRequests/Approve?stockRequestId=${id}`
      );

      return fulfillWithValue(response?.data?.data);
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);

export const rejectStockRequest = createAsyncThunk(
  "rejectStockRequest",
  async ({ id, data }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.patch(
        `${API_URL}/StockRequests/Reject?stockRequestId=${id}`,
        { ...data }
      );

      return fulfillWithValue(response?.data?.data);
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);

export const submitStockRequest = createAsyncThunk(
  "submitStockRequest",
  async (id, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.patch(
        `${API_URL}/StockRequests/UpdatePending?stockRequestId=${id}`
      );

      return fulfillWithValue(response?.data?.data);
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);

export const getStockRequest = createAsyncThunk(
  "getStockRequest",
  async ({ force, id }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/StockRequests/GetById?stockRequestId=${id}`,
        force
      );

      return fulfillWithValue(response?.data?.data || {});
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);

export const getPurchaseOrders = createAsyncThunk(
  "getPurchaseOrders",
  async ({ force, ...data }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/PurchaseOrders/ListAll?cinemaId=${data.cinemaId}&DateFrom=${data.DateFrom}&DateTo=${data.DateTo}&page=1&perPage=1000`,
        force
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);

export const getSimplifiedPO = createAsyncThunk(
  "getSimplifiedPO",
  async ({ force, ...data }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/PurchaseOrders/SimpleListAll?DateFrom=${data.DateFrom}&DateTo=${data.DateTo}&status=Completed&page=1&perPage=1000`,
        force
      );
      console.log({ PO: response?.data?.data?.pagedList });
      return fulfillWithValue(response?.data?.data?.pagedList || []);
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);

export const getPurchaseOrder = createAsyncThunk(
  "getPurchaseOrder",
  async ({ force, id }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/PurchaseOrders/GetById?purchaseOrderId=${id}`,
        force
      );

      return fulfillWithValue(response?.data?.data || {});
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);

export const approvePurchaseOrder = createAsyncThunk(
  "approvePurchaseOrder",
  async (id, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.patch(
        `${API_URL}/PurchaseOrders/Approve?purchaseOrderId=${id}`
      );

      return fulfillWithValue(response?.data?.data);
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);

export const getCurrencyList = createAsyncThunk(
  "getCurrencyList",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    const force = true;
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Currencies/ListAll`,
        force
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);

export const updatePurchaseOrder = createAsyncThunk(
  "updatePurchaseOrder",
  async ({ id, data }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.patch(
        `${API_URL}/PurchaseOrders/Update?purchaseOrderId=${id}`,
        { ...data }
      );

      return fulfillWithValue(response?.data?.data);
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);

export const getStockReceipts = createAsyncThunk(
  "getStockReceipts",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    const force = true;
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/PurchaseReceipts/ListAll?cinemaId=${data.cinemaId}&DateFrom=${data.DateFrom}&DateTo=${data.DateTo}&page=1&perPage=1000`,
        force
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);

export const getStockReceipt = createAsyncThunk(
  "getStockReceipt",
  async (id, { fulfillWithValue, rejectWithValue }) => {
    const force = true;
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/PurchaseReceipts/GetById?purchaseReceiptId=${id}`,
        force
      );

      return fulfillWithValue(response?.data?.data || {});
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);

export const createStockReceipt = createAsyncThunk(
  "createStockReceipt",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/PurchaseReceipts/Create`,
        { ...data }
      );

      return fulfillWithValue(response?.data?.data);
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);

export const approveStockReceipt = createAsyncThunk(
  "approveStockReceipt",
  async (id, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.patch(
        `${API_URL}/PurchaseReceipts/Approve?purchaseReceiptId=${id}`
      );

      return fulfillWithValue(response?.data?.data);
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);

export const rejectStockReceipt = createAsyncThunk(
  "rejectStockReceipt",
  async ({ id, data }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.patch(
        `${API_URL}/PurchaseReceipts/Reject?purchaseReceiptId=${id}`,
        { ...data }
      );

      return fulfillWithValue(response?.data?.data);
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);

export const getStockTransfer = createAsyncThunk(
  "getStockTransfer",
  async (id, { fulfillWithValue, rejectWithValue }) => {
    const force = true;
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/StockTransfers/GetById?stockTransferId=${id}`,
        force
      );

      return fulfillWithValue(response?.data?.data || {});
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);

export const getStockTransfers = createAsyncThunk(
  "getStockTransfers",
  async (
    { startDate, endDate, status, fetchType, cinemaId },
    { fulfillWithValue, rejectWithValue }
  ) => {
    const force = true;
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/StockTransfers/ListAll?cinemaId=${cinemaId}&DateFrom=${startDate}&DateTo=${endDate}&status=${status}&fetchType=${fetchType}&page=1&perPage=1000`,
        force
      );

      return fulfillWithValue(response?.data?.data.pagedList);
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);

export const approveStockTransfer = createAsyncThunk(
  "approveStockTransfer",
  async (id, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.patch(
        `${API_URL}/StockTransfers/Approve?stockTransferId=${id}`
      );

      return fulfillWithValue(response?.data?.data);
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);

export const createStockTake = createAsyncThunk(
  "createStockTake",
  async (payload, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/StocksTake/Create`,
        payload
      );
      return fulfillWithValue(response?.data?.data);
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);

export const getStockTakeList = createAsyncThunk(
  "getStockTakeList",
  async (cinemaId, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/StocksTake/ListAll?cinemaId=${cinemaId}`,
        true
      );
      return fulfillWithValue(response?.data?.data);
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);

export const getStockTake = createAsyncThunk(
  "getStockTake",
  async (id, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/StocksTake/GetById?stockTakeId=${id}`,
        true
      );
      return fulfillWithValue(response?.data?.data);
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);
export const approveTransferReceipt = createAsyncThunk(
  "approveTransferReceipt",
  async (id, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.patch(
        `${API_URL}/StockTransferReceipts/Approve?stockTransferReceiptId=${id}`
      );

      return fulfillWithValue(response?.data?.data);
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);

export const rejectTransferReceipt = createAsyncThunk(
  "rejectTransferReceipt",
  async ({ id, data }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.patch(
        `${API_URL}/StockTransferReceipts/Reject?stockTransferReceiptId=${id}`,
        { ...data }
      );

      return fulfillWithValue(response?.data?.data);
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(getErrMsg(err));
    }
  }
);

export const updateItemStore = createAsyncThunk(
  "updateItemStore",
  async ({ id, data },{fulfillWithValue,rejectWithValue}) => {
    try {
      const response = await axiosApiInstance.patch(`${API_URL}/StocksTake/UpdateItemStore?itemStoreId=${id}`,data);
    return fulfillWithValue(response?.data?.data)
    } catch (err) {
      let message = "Unable to complete request"
return rejectWithValue(getAPIErr(err||message))
    }
  }
);

const initialState = {
  stockItemsStatus: FETCH_STATUS.IDLE,
  stockItemsErr: null,
  stockRequests: [],
  stockRequestsStatus: FETCH_STATUS.IDLE,
  stockRequestsErr: null,
  stockTakes: [],
  stockTakesStatus: FETCH_STATUS.IDLE,
  stockTakesErr: null,
  requisitionTypes: [],
  requisitionTypesStatus: FETCH_STATUS.IDLE,
  requisitionTypesErr: null,
  storeLocations: [],
  storeLocationsStatus: FETCH_STATUS.IDLE,
  storeLocationsErr: null,
  createStockRequestStatus: FETCH_STATUS.IDLE,
  createStockRequestErr: null,
  createStockTakeStatus: FETCH_STATUS.IDLE,
  createStockTakeErr: null,
  updateStockRequestStatus: FETCH_STATUS.IDLE,
  updateStockRequestErr: null,
  approveStockRequestStatus: FETCH_STATUS.IDLE,
  approveStockRequestErr: null,
  rejectStockRequestStatus: FETCH_STATUS.IDLE,
  rejectStockRequestErr: null,
  submitStockRequestStatus: FETCH_STATUS.IDLE,
  submitStockRequestErr: null,
  stockRequest: {},
  stockRequestStatus: FETCH_STATUS.IDLE,
  stockRequestErr: null,
  stockTake: {},
  stockTakeStatus: FETCH_STATUS.IDLE,
  stockTakeErr: null,
  purchaseOrders: [],
  purchaseOrdersStatus: FETCH_STATUS.IDLE,
  purchaseOrdersErr: null,
  simplifiedPo: [],
  simplifiedPoStatus: FETCH_STATUS.IDLE,
  simplifiedPoErr: null,
  purchaseOrder: {},
  purchaseOrderStatus: FETCH_STATUS.IDLE,
  purchaseOrderErr: null,
  approvePurchaseOrderStatus: FETCH_STATUS.IDLE,
  approvePurchaseOrderErr: null,
  currencyList: [],
  currencyListStatus: FETCH_STATUS.IDLE,
  currencyListErr: null,
  updatePurchaseOrderStatus: FETCH_STATUS.IDLE,
  updatePurchaseOrderErr: null,
  stockReceipts: [],
  stockReceiptsStatus: FETCH_STATUS.IDLE,
  stockReceiptsErr: null,
  stockReceipt: {},
  stockReceiptStatus: FETCH_STATUS.IDLE,
  stockReceiptErr: null,
  createStockReceipt: {},
  createStockReceiptStatus: FETCH_STATUS.IDLE,
  createStockReceiptErr: null,
  approveStockReceiptStatus: FETCH_STATUS.IDLE,
  approveStockReceiptErr: null,
  rejectStockReceiptStatus: FETCH_STATUS.IDLE,
  rejectStockReceiptErr: null,
  stockTransfer: {},
  stockTransferStatus: FETCH_STATUS.IDLE,
  stockTransferErr: null,
  approveStockTransferStatus: FETCH_STATUS.IDLE,
  approveStockTransferErr: null,
  stockTransfers: [],
  stockTransfersStatus: FETCH_STATUS.IDLE,
  stockTransfersErr: null,
  itemsStock: [],
  itemsStockStatus: FETCH_STATUS.IDLE,
  itemsStockErr: null,
  transferReceipts: [],
  transferReceiptsStatus: FETCH_STATUS.IDLE,
  transferReceiptsErr: null,
  simplifiedST: [],
  simplifiedSTStatus: FETCH_STATUS.IDLE,
  simplifiedSTErr: null,
  createTransferReceipt: {},
  createTransferReceiptStatus: FETCH_STATUS.IDLE,
  createTransferReceiptErr: null,
  transferReceipt: {},
  transferReceiptStatus: FETCH_STATUS.IDLE,
  transferReceiptErr: null,

  approveTransferReceiptStatus: FETCH_STATUS.IDLE,
  approveTransferReceiptErr: null,
  rejectTransferReceiptStatus: FETCH_STATUS.IDLE,
  rejectTransferReceiptErr: null,
  updateItemStoreStatus: FETCH_STATUS.IDLE,
  updateItemStoreErr:null,
};

export const stockSlice = createSlice({
  name: "stock",
  initialState,
  reducers: {
    updateFetchedStockRequest(state, action) {
      state.stockRequests = action.payload;
    },
    updateFetchedPurchaseOrder(state, action) {
      state.purchaseOrders = action.payload;
    },
    updateFetchedStockReceipt(state, action) {
      state.stockReceipts = action.payload;
    },
    updateFetchedStockTake(state, action) {
      state.stockTake = action.payload;
    },
    resetCreateStockTakeRequest(state) {
      state.createStockTakeErr = "";
      state.createStockTakeStatus = FETCH_STATUS.IDLE;
    },
    updateItemStock: (state, action) => {
      editItemDirect(state,action,"itemsStock")
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStockRequests.pending, (state) => {
        state.stockRequestsStatus = FETCH_STATUS.PENDING;
        state.stockRequestsErr = "";
      })
      .addCase(getStockRequests.fulfilled, (state, action) => {
        state.stockRequestsStatus = FETCH_STATUS.RESOLVED;
        state.stockRequestsErr = null;
        state.stockRequests = action.payload.pagedList;
      })
      .addCase(getStockRequests.rejected, (state, action) => {
        state.stockRequestsStatus = FETCH_STATUS.REJECTED;
        state.stockRequestsErr =
          action.payload || "Unable to retrieve stock Requests";
      })
      .addCase(getStockTakeList.pending, (state) => {
        state.stockTakesStatus = FETCH_STATUS.PENDING;
        state.stockTakesErr = null;
      })
      .addCase(getStockTakeList.fulfilled, (state, action) => {
        state.stockTakesStatus = FETCH_STATUS.RESOLVED;
        state.stockTakesErr = null;
        state.stockTakes = action.payload.pagedList;
      })
      .addCase(getStockTakeList.rejected, (state, action) => {
        state.stockTakesStatus = FETCH_STATUS.REJECTED;
        state.stockTakesErr =
          action.payload || "Unable to retrieve stock takes";
      })
      .addCase(getRequisitionTypes.pending, (state) => {
        state.requisitionTypesStatus = FETCH_STATUS.PENDING;
        state.requisitionTypesErr = "";
      })
      .addCase(getRequisitionTypes.fulfilled, (state, action) => {
        state.requisitionTypesStatus = FETCH_STATUS.RESOLVED;
        state.requisitionTypesErr = null;
        state.requisitionTypes = action.payload;
      })
      .addCase(getRequisitionTypes.rejected, (state, action) => {
        state.requisitionTypesStatus = FETCH_STATUS.REJECTED;
        state.requisitionTypesErr =
          action.payload || "Unable to retrieve requisition Types ";
      })
      .addCase(getStoreLocations.pending, (state) => {
        state.storeLocationsStatus = FETCH_STATUS.PENDING;
        state.storeLocationsErr = "";
      })
      .addCase(getStoreLocations.fulfilled, (state, action) => {
        state.storeLocationsStatus = FETCH_STATUS.RESOLVED;
        state.storeLocationsErr = null;
        state.storeLocations = action.payload.pagedList;
      })
      .addCase(getStoreLocations.rejected, (state, action) => {
        state.storeLocationsStatus = FETCH_STATUS.REJECTED;
        state.storeLocationsErr =
          action.payload || "Unable to retrieve store locations";
      })
      .addCase(createStockRequest.pending, (state) => {
        state.createStockRequestStatus = FETCH_STATUS.PENDING;
        state.createStockRequestErr = "";
      })
      .addCase(createStockRequest.fulfilled, (state, action) => {
        state.createStockRequestStatus = FETCH_STATUS.RESOLVED;
        state.createStockRequestErr = null;
      })
      .addCase(createStockRequest.rejected, (state, action) => {
        state.createStockRequestStatus = FETCH_STATUS.REJECTED;
        state.createStockRequestErr =
          action.payload || "Unable to create stock request";
      })
      .addCase(createStockTake.pending, (state) => {
        state.createStockTakeStatus = FETCH_STATUS.PENDING;
        state.createStockTakeErr = "";
      })
      .addCase(createStockTake.fulfilled, (state, action) => {
        state.createStockTakeStatus = FETCH_STATUS.RESOLVED;
        state.createStockTakeErr = null;
      })
      .addCase(createStockTake.rejected, (state, action) => {
        state.createStockTakeStatus = FETCH_STATUS.REJECTED;
        state.createStockTakeErr =
          action.payload || "Unable to create stock take";
      })
      .addCase(updateStockRequest.pending, (state) => {
        state.updateStockRequestStatus = FETCH_STATUS.PENDING;
        state.updateStockRequestErr = "";
      })
      .addCase(updateStockRequest.fulfilled, (state, action) => {
        state.updateStockRequestStatus = FETCH_STATUS.RESOLVED;
        state.updateStockRequestErr = null;
      })
      .addCase(updateStockRequest.rejected, (state, action) => {
        state.updateStockRequestStatus = FETCH_STATUS.REJECTED;
        state.updateStockRequestErr =
          action.payload || "Unable to update stock request";
      })
      .addCase(approveStockRequest.pending, (state) => {
        state.approveStockRequestStatus = FETCH_STATUS.PENDING;
        state.approveStockRequestErr = "";
      })
      .addCase(approveStockRequest.fulfilled, (state, action) => {
        state.approveStockRequestStatus = FETCH_STATUS.RESOLVED;
        state.approveStockRequestErr = null;
      })
      .addCase(approveStockRequest.rejected, (state, action) => {
        state.approveStockRequestStatus = FETCH_STATUS.REJECTED;
        state.approveStockRequestErr =
          action.payload || "Unable to approve stock request";
      })
      .addCase(rejectStockRequest.pending, (state) => {
        state.rejectStockRequestStatus = FETCH_STATUS.PENDING;
        state.rejectStockRequestErr = "";
      })
      .addCase(rejectStockRequest.fulfilled, (state, action) => {
        state.rejectStockRequestStatus = FETCH_STATUS.RESOLVED;
        state.rejectStockRequestErr = null;
      })
      .addCase(rejectStockRequest.rejected, (state, action) => {
        state.rejectStockRequestStatus = FETCH_STATUS.REJECTED;
        state.rejectStockRequestErr =
          action.payload || "Unable to reject stock request";
      })
      .addCase(submitStockRequest.pending, (state) => {
        state.submitStockRequestStatus = FETCH_STATUS.PENDING;
        state.submitStockRequestErr = "";
      })
      .addCase(submitStockRequest.fulfilled, (state, action) => {
        state.submitStockRequestStatus = FETCH_STATUS.RESOLVED;
        state.submitStockRequestErr = null;
      })
      .addCase(submitStockRequest.rejected, (state, action) => {
        state.submitStockRequestStatus = FETCH_STATUS.REJECTED;
        state.submitStockRequestErr =
          action.payload || "Unable to submit stock request";
      })
      .addCase(getStockRequest.pending, (state) => {
        state.stockRequestStatus = FETCH_STATUS.PENDING;
        state.stockRequestErr = "";
      })
      .addCase(getStockRequest.fulfilled, (state, action) => {
        state.stockRequestStatus = FETCH_STATUS.RESOLVED;
        state.stockRequestErr = null;
        state.stockRequest = action.payload;
      })
      .addCase(getStockRequest.rejected, (state, action) => {
        state.stockRequestStatus = FETCH_STATUS.REJECTED;
        state.stockRequestErr = action.payload || "Unable to get stock request";
      })
      .addCase(getStockTake.pending, (state) => {
        state.stockTakeStatus = FETCH_STATUS.PENDING;
        state.stockTakeErr = "";
      })
      .addCase(getStockTake.fulfilled, (state, action) => {
        state.stockTakeStatus = FETCH_STATUS.RESOLVED;
        state.stockTakeErr = null;
        state.stockTake = action.payload;
      })
      .addCase(getStockTake.rejected, (state, action) => {
        state.stockTakeStatus = FETCH_STATUS.REJECTED;
        state.stockTakeErr = action.payload || "Unable to get stock take";
      })
      .addCase(getPurchaseOrders.pending, (state) => {
        state.purchaseOrdersStatus = FETCH_STATUS.PENDING;
        state.purchaseOrdersErr = "";
      })
      .addCase(getPurchaseOrders.fulfilled, (state, action) => {
        state.purchaseOrdersStatus = FETCH_STATUS.RESOLVED;
        state.purchaseOrdersErr = null;
        state.purchaseOrders = action.payload.pagedList;
      })
      .addCase(getPurchaseOrders.rejected, (state, action) => {
        state.purchaseOrdersStatus = FETCH_STATUS.REJECTED;
        state.purchaseOrdersErr =
          action.payload || "Unable to retrieve purchase orders";
      })
      .addCase(getPurchaseOrder.pending, (state) => {
        state.purchaseOrderStatus = FETCH_STATUS.PENDING;
        state.purchaseOrderErr = "";
      })
      .addCase(getPurchaseOrder.fulfilled, (state, action) => {
        state.purchaseOrderStatus = FETCH_STATUS.RESOLVED;
        state.purchaseOrderErr = null;
        state.purchaseOrder = action.payload;
      })
      .addCase(getPurchaseOrder.rejected, (state, action) => {
        state.purchaseOrderStatus = FETCH_STATUS.REJECTED;
        state.purchaseOrderErr =
          action.payload || "Unable to get purchase order";
      })
      .addCase(approvePurchaseOrder.pending, (state) => {
        state.approvePurchaseOrderStatus = FETCH_STATUS.PENDING;
        state.approvePurchaseOrderErr = "";
      })
      .addCase(approvePurchaseOrder.fulfilled, (state, action) => {
        state.approvePurchaseOrderStatus = FETCH_STATUS.RESOLVED;
        state.approvePurchaseOrderErr = null;
      })
      .addCase(approvePurchaseOrder.rejected, (state, action) => {
        state.approvePurchaseOrderStatus = FETCH_STATUS.REJECTED;
        state.approvePurchaseOrderErr =
          action.payload || "Unable to approve purchase order";
      })
      .addCase(getCurrencyList.pending, (state) => {
        state.currencyListStatus = FETCH_STATUS.PENDING;
        state.currencyListErr = "";
      })
      .addCase(getCurrencyList.fulfilled, (state, action) => {
        state.currencyListStatus = FETCH_STATUS.RESOLVED;
        state.currencyListErr = null;
        state.currencyList = action.payload;
      })
      .addCase(getCurrencyList.rejected, (state, action) => {
        state.currencyListStatus = FETCH_STATUS.REJECTED;
        state.currencyListErr = action.payload || "Unable to get currency list";
      })
      .addCase(updatePurchaseOrder.pending, (state) => {
        state.updatePurchaseOrderStatus = FETCH_STATUS.PENDING;
        state.updatePurchaseOrderErr = "";
      })
      .addCase(updatePurchaseOrder.fulfilled, (state, action) => {
        state.updatePurchaseOrderStatus = FETCH_STATUS.RESOLVED;
        state.updatePurchaseOrderErr = null;
      })
      .addCase(updatePurchaseOrder.rejected, (state, action) => {
        state.updatePurchaseOrderStatus = FETCH_STATUS.REJECTED;
        state.updatePurchaseOrderErr =
          action.payload || "Unable to update purchase order";
      })
      .addCase(getStockReceipts.pending, (state) => {
        state.stockReceiptsStatus = FETCH_STATUS.PENDING;
        state.stockReceiptsErr = "";
      })
      .addCase(getStockReceipts.fulfilled, (state, action) => {
        state.stockReceiptsStatus = FETCH_STATUS.RESOLVED;
        state.stockReceiptsErr = null;
        state.stockReceipts = action.payload.pagedList;
      })
      .addCase(getStockReceipts.rejected, (state, action) => {
        state.stockReceiptsStatus = FETCH_STATUS.REJECTED;
        state.stockReceiptsErr =
          action.payload || "Unable to get stock receipts";
      })
      .addCase(getStockReceipt.pending, (state) => {
        state.stockReceiptStatus = FETCH_STATUS.PENDING;
        state.stockReceiptErr = "";
      })
      .addCase(getStockReceipt.fulfilled, (state, action) => {
        state.stockReceiptStatus = FETCH_STATUS.RESOLVED;
        state.stockReceiptErr = null;
        state.stockReceipt = action.payload;
      })
      .addCase(getStockReceipt.rejected, (state, action) => {
        state.stockReceiptStatus = FETCH_STATUS.REJECTED;
        state.stockReceiptErr = action.payload || "Unable to get stock receipt";
      })
      .addCase(createStockReceipt.pending, (state) => {
        state.createStockReceiptStatus = FETCH_STATUS.PENDING;
        state.createStockReceiptErr = "";
      })
      .addCase(createStockReceipt.fulfilled, (state, action) => {
        state.createStockReceiptStatus = FETCH_STATUS.RESOLVED;
        state.createStockReceiptErr = null;
        state.createStockReceipt = action.payload;
      })
      .addCase(createStockReceipt.rejected, (state, action) => {
        state.createStockReceiptStatus = FETCH_STATUS.REJECTED;
        state.createStockReceiptErr =
          action.payload || "Unable to create stock receipt";
      })
      .addCase(approveStockReceipt.pending, (state) => {
        state.approveStockReceiptStatus = FETCH_STATUS.PENDING;
        state.approveStockReceiptErr = "";
      })
      .addCase(approveStockReceipt.fulfilled, (state, action) => {
        state.approveStockReceiptStatus = FETCH_STATUS.RESOLVED;
        state.approveStockReceiptErr = null;
      })
      .addCase(approveStockReceipt.rejected, (state, action) => {
        state.approveStockReceiptStatus = FETCH_STATUS.REJECTED;
        state.approveStockReceiptErr =
          action.payload || "Unable to approve stock receipt";
      })
      .addCase(rejectStockReceipt.pending, (state) => {
        state.rejectStockReceiptStatus = FETCH_STATUS.PENDING;
        state.rejectStockReceiptErr = "";
      })
      .addCase(rejectStockReceipt.fulfilled, (state, action) => {
        state.rejectStockReceiptStatus = FETCH_STATUS.RESOLVED;
        state.rejectStockReceiptErr = null;
      })
      .addCase(rejectStockReceipt.rejected, (state, action) => {
        state.rejectStockReceiptStatus = FETCH_STATUS.REJECTED;
        state.rejectStockReceiptErr =
          action.payload || "Unable to reject stock receipt";
      })
      .addCase(getStockTransfer.pending, (state) => {
        state.stockTransferStatus = FETCH_STATUS.PENDING;
        state.stockTransferErr = "";
      })
      .addCase(getStockTransfer.fulfilled, (state, action) => {
        state.stockTransferStatus = FETCH_STATUS.RESOLVED;
        state.stockTransferErr = "";
        state.stockTransfer = action.payload;
      })
      .addCase(getStockTransfer.rejected, (state, action) => {
        state.stockTransferStatus = FETCH_STATUS.REJECTED;
        state.stockTransferErr =
          action.payload || "Unable to get stock transfer";
      })
      .addCase(getStockTransfers.pending, (state) => {
        state.stockTransfersStatus = FETCH_STATUS.PENDING;
        state.stockTransfersErr = "";
      })
      .addCase(getStockTransfers.fulfilled, (state, action) => {
        state.stockTransfersStatus = FETCH_STATUS.RESOLVED;
        state.stockTransfersErr = "";
        state.stockTransfers = action.payload;
      })
      .addCase(getStockTransfers.rejected, (state, action) => {
        state.stockTransfersStatus = FETCH_STATUS.REJECTED;
        state.stockTransfersErr =
          action.payload || "Unable to get stock transfers";
      })
      .addCase(approveStockTransfer.pending, (state) => {
        state.approveStockTransferStatus = FETCH_STATUS.PENDING;
        state.approveStockTransferErr = "";
      })
      .addCase(approveStockTransfer.fulfilled, (state, action) => {
        state.approveStockTransferStatus = FETCH_STATUS.RESOLVED;
        state.approveStockTransferErr = null;
      })
      .addCase(approveStockTransfer.rejected, (state, action) => {
        state.approveStockTransferStatus = FETCH_STATUS.REJECTED;
        state.approveStockTransferErr =
          action.payload || "Unable to approve stock transfer";
      })
      .addCase(getItemsStock.pending, (state, action) => {
        state.itemsStockStatus = FETCH_STATUS.PENDING;
        state.itemsStockErr = "";
      })
      .addCase(getItemsStock.fulfilled, (state, action) => {
        state.itemsStockStatus = FETCH_STATUS.RESOLVED;
        state.itemsStockErr = null;
        state.itemsStock = action.payload;
      })
      .addCase(getItemsStock.rejected, (state, action) => {
        state.itemsStockStatus = FETCH_STATUS.REJECTED;
        state.itemsStockErr = action.payload || "unable to get items stock";
      })
      .addCase(getTransferReceipts.pending, (state, action) => {
        state.transferReceiptsStatus = FETCH_STATUS.PENDING;
        state.transferReceiptsErr = "";
      })
      .addCase(getTransferReceipts.fulfilled, (state, action) => {
        state.transferReceiptsStatus = FETCH_STATUS.RESOLVED;
        state.transferReceiptsErr = null;
        state.transferReceipts = action.payload;
      })
      .addCase(getTransferReceipts.rejected, (state, action) => {
        state.transferReceiptsStatus = FETCH_STATUS.REJECTED;
        state.transferReceiptsErr =
          action.payload || "unable to get items stock";
      })
      .addCase(getSimplifiedPO.pending, (state, action) => {
        state.simplifiedPoStatus = FETCH_STATUS.PENDING;
        state.simplifiedPoErr = "";
      })
      .addCase(getSimplifiedPO.fulfilled, (state, action) => {
        state.simplifiedPoStatus = FETCH_STATUS.RESOLVED;
        state.simplifiedPoErr = null;
        state.simplifiedPo = action.payload;
      })
      .addCase(getSimplifiedPO.rejected, (state, action) => {
        state.simplifiedPoStatus = FETCH_STATUS.REJECTED;
        state.simplifiedPoErr =
          action.payload || "unable to get purchase order";
      })
      .addCase(getSimplifiedST.pending, (state, action) => {
        state.simplifiedSTStatus = FETCH_STATUS.PENDING;
        state.simplifiedSTErr = "";
      })
      .addCase(getSimplifiedST.fulfilled, (state, action) => {
        state.simplifiedSTStatus = FETCH_STATUS.RESOLVED;
        state.simplifiedSTErr = null;
        state.simplifiedST = action.payload;
      })
      .addCase(getSimplifiedST.rejected, (state, action) => {
        state.simplifiedSTStatus = FETCH_STATUS.REJECTED;
        state.simplifiedSTErr =
          action.payload || "unable to get purchase order";
      })
      .addCase(updateItemStore.pending, (state, action) => {
        state.updateItemStoreStatus = FETCH_STATUS.PENDING;
        state.updateItemStoreErr = null;
    })
      .addCase(updateItemStore.rejected, (state, action) => {
        state.updateItemStoreStatus = FETCH_STATUS.REJECTED;
        state.updateItemStoreErr = action.payload||"Unable to update item store"

    })
      .addCase(updateItemStore.fulfilled, (state, action) => {
        state.updateItemStoreStatus = FETCH_STATUS.RESOLVED;
        state.updateItemStoreErr = null

    })
    
  },
});

export const {
  updateFetchedPurchaseOrder,
  updateFetchedStockRequest,
  updateFetchedStockReceipt,
  updateItemStock
} = stockSlice.actions;

export const stockData = (state) => state.stock;
