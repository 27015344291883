import React, { useMemo } from "react";
import { dateAndTime, formatDateTime, formatNumberAsCurrency } from "../../utils";
import ReachLogo from "../../assets/images/logo.png";
import { DefaultListTable } from "../UI";
import { useLoggedInuser } from "../../hooks";

const GBOFilmReportsTable = ({ id, data, paramFilter }) => {
  const {profile} = useLoggedInuser()
  const columns = useMemo(() => {
    return [
      {
        Header: "Film",
        accessor: "film",
        Footer: `TOTAL`,
      },
      {
        Header: "POS",
        accessor: "pos",
        Cell: ({ value }) => {
          return (
            <>
              <p>{`gbo: ${
                id ? value?.gbo : formatNumberAsCurrency(value?.gbo)
              }`}</p>
              <p>{`admit(s): ${value?.admits}`}</p>
            </>
          );
        },
      },
      {
        Header: "Web",
        accessor: "web",
        Cell: ({ value }) => {
          return (
            <>
              <p>{`gbo: ${
                id ? value?.gbo : formatNumberAsCurrency(value?.gbo)
              }`}</p>
              <p>{`admit(s): ${value?.admits}`}</p>
            </>
          );
        },
      },
      {
        Header: "Connect",
        accessor: "connect",
        Cell: ({ value }) => {
          return (
            <>
              <p>{`gbo: ${
                id ? value?.gbo : formatNumberAsCurrency(value?.gbo)
              }`}</p>
              <p>{`admit(s): ${value?.admits}`}</p>
            </>
          );
        },
      },
      {
        Header: "Mobile",
        accessor: "mobileApp",
        Cell: ({ value }) => {
          return (
            <>
              <p>{`gbo: ${
                id ? value?.gbo : formatNumberAsCurrency(value?.gbo)
              }`}</p>
              <p>{`admit(s): ${value?.admits}`}</p>
            </>
          );
        },
      },

      {
        Header: "GBO",
        accessor: "gbo",
        Footer: (info) => {
          const total = useMemo(() => {
            return info?.rows?.reduce(
              (sum, row) => parseFloat(row.values.gbo) + parseFloat(sum),
              0
            );
          }, [info?.rows]);
          return `${id ? total : formatNumberAsCurrency(total)}`;
        },
        Cell: ({ value }) => (id ? value : formatNumberAsCurrency(value)),
      },

      {
        Header: "Admits",
        accessor: "admits",
        Footer: (info) => {
          const total = useMemo(() => {
            return info?.rows?.reduce((sum, row) => {
              return parseFloat(row.values.admits) + parseFloat(sum);
            }, 0);
          }, [info?.rows]);

          return total;
        },
      },
    ];
  }, [id]);
  return (
    <div className="overflow-auto px-3">
      <div className="flex items-center justify-between">
        <div
          key={ReachLogo}
          className=" w-[5rem] h-[5rem]  bg-no-repeat bg-contain bg-center "
          style={{
            backgroundImage: `url("${ReachLogo}")`,
          }}
        ></div>
        <p className="font-bold text-[20px]">GBO By Films Report</p>
        <p>
          {paramFilter?.cinema === "All" ? "All Cinemas" : paramFilter?.cinema}
        </p>
      </div>
      <div className=" flex p-2 justify-between">
        <div>
          <p>
            {" "}
            <span className="font-bold">User Name:</span>
            {` ${profile.firstName} ${profile.lastName}`}
          </p>
          <p>
            <span className="font-bold">Date Time Generated: </span>
            {formatDateTime(
              paramFilter?.dateTimeGenerated,
              dateAndTime
            )}
          </p>
        </div>
        <div>
          <p className="font-bold">Data Range</p>
          <p>{new Date(paramFilter?.startDate).toDateString()}</p>{" "}
          <p>{new Date(paramFilter?.endDate).toDateString()}</p>
        </div>
      </div>
      <DefaultListTable columns={columns} data={data} id={id} isFooter={true} />
    </div>
  );
};

export { GBOFilmReportsTable };
