import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addItemDirect,
  API_URL,
  axiosApiInstanceGet,
  editItemDirect,
  FETCH_STATUS,
  onReturnReject,
} from "../../utils";

export const getVendorList = createAsyncThunk(
  "getVendorList",
  async (force, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Vendors/ListAll`,
        force
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(onReturnReject(error));
    }
  }
);

const initialState = {
  vendorList: [],
  vendorsStatus: FETCH_STATUS.IDLE,
  vendorsErr: null,
};
export const vendorSlice = createSlice({
  name: "vendorList",
  initialState,
  reducers: {
    addVendors: (state, action) => {
      state.vendorList = addItemDirect(state, action, "vendorList");
    },
    editVendors: (state, action) => {
      editItemDirect(state, action, "vendorList");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVendorList.pending, (state) => {
        state.vendorsStatus = FETCH_STATUS.PENDING;
        state.vendorsErr = "";
      })
      .addCase(getVendorList.fulfilled, (state, action) => {
        state.vendorsStatus = FETCH_STATUS.RESOLVED;
        state.vendorsErr = "";
        state.vendorList = action.payload;
      })
      .addCase(getVendorList.rejected, (state, action) => {
        state.vendorsStatus = FETCH_STATUS.REJECTED;
        state.vendorsErr = action.payload || "Unable to retrieve vendors";
        state.vendorList = action.payload;
      });
  },
});

export const selectVendors = (state) => state.vendorList;

export const { addVendors, editVendors } = vendorSlice.actions;
