import React, { useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { dayMap } from "../../utils";
import { Arrow } from "../Icons";
import { ClientPaginatedTable, DefaultColumnFilter, StatusFilter } from "../UI";

const PriceCardTable = ({ priceCards = [] }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const columns = useMemo(
    () => [
      {
        Header: (props) => {
          const { column } = props;
          return (
            <div className="inline-flex items-center justify-between space-x-4">
              <span>Description</span>
              <span
                className={`${
                  column?.isSortedDesc ? "rotate-180" : "rotate-0"
                }`}
              >
                <Arrow />
              </span>
            </div>
          );
        },
        accessor: "name",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Day(s)",
        accessor: "days",
        Cell: ({ value }) => {
          return value?.map((v) => dayMap[v]).join(",");
        },
      },
      {
        Header: "Status",
        accessor: "active",
        Filter: StatusFilter,
        Cell: ({ value }) => {
          return value ? "Active" : "Inactive";
        },
      },
    ],
    []
  );

  const onRowClick = (row) => () => {
    navigate(`${pathname}/${row.original.id}`);
  };

  return (
    <ClientPaginatedTable
      columns={columns}
      tableData={priceCards}
      onRowClick={onRowClick}
      getHeaderProps={(column) => ({
        className:
          "px-3 pt-8 pb-2 text-base font-medium border border-[#00000033] ",
        ...(column.id === "name" ? column?.getSortByToggleProps() : {}),
      })}
      getCellProps={(cellInfo) => ({
        style: {},
        className: `${
          cellInfo?.column?.id === "name" ? "text-app-purple-9" : ""
        } ${cellInfo?.column?.id === "days" ? "break-all" : ""} `,
      })}
      getColumnProps={() => ({ style: {} })}
    />
  );
};

export { PriceCardTable };
