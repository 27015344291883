import { Dialog, Listbox, Transition } from "@headlessui/react";
import { Fragment, useState, useMemo } from "react";

const TicketsModal = ({ show, addTickets, onClose, tickets = [] }) => {
  const [addedTickets, setAddedTickets] = useState([]);

  const ids = useMemo(() => addedTickets.map((t) => t.name), [addedTickets]);

  const onSubmit = () => {
    addTickets(addedTickets);
    setAddedTickets([]);
    onClose();
  };

  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog as="div" className="relative z-[10000000]" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-75"
            leave="ease-in duration-75"
            leaveFrom="opacity-75"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-center text-white bg-gradient-to-b from-app-purple-4 to-app-purple-3 p-3.5 "
                  >
                    Tickets
                  </Dialog.Title>

                  <Listbox
                    value={addedTickets}
                    onChange={setAddedTickets}
                    multiple
                    className="overflow-y-auto max-h-[70vh]"
                  >
                    {({ props }) => (
                      <Listbox.Options className="" static>
                        {tickets.map((ticket, i) => (
                          <Listbox.Option
                            className="flex items-center justify-between px-2 pt-4 pb-2 border-b text-app-purple-3 border-b-purple-3"
                            key={ticket.id}
                            value={ticket}
                          >
                            <span>{ticket.name}</span>
                            <span>
                              <input
                                checked={ids.includes(ticket.name)}
                                readOnly
                                type="checkbox"
                                className="border rounded  outline-none !ring-transparent border-app-purple-3 checked:text-app-purple-3"
                              />
                            </span>
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    )}
                  </Listbox>

                  <div className="flex items-center justify-center my-4">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white rounded-md bg-app-purple-4"
                      onClick={onSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default TicketsModal;
