import { Dialog, Transition } from "@headlessui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { Fragment, useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLoggedInuser, useRequest } from "../../hooks";
import {
  getClaims,
  getStaffClaims,
  selectClaims,
  updateUserClaims,
} from "../../redux/slices";
import {
  API_URL,
  axiosApiInstance,
  axiosApiInstanceGet,
  didUpdateFields,
  editClaimInit,
  editClaimsSchema,
  FETCH_STATUS,
  getClaimItem,
  getScreenFormat,
  getSelectedItem,
  onReject,
} from "../../utils";
import {
  ErrorWithWrapper,
  InlineErr,
  Input,
  MultiSearchSelect,
  Spinner,
  SuccessModal,
} from "../UI";

const wrapperClass = "grid grid-cols-[30%_minmax(0,1fr)] gap-2";

const ClaimsModal = ({ show, onClose = () => {}, userClaim }) => {
  const {
    err,
    status: updateStatus,
    resetErr,
    setResolved,
    setPending,
    setRejected,
  } = useRequest();
  const { claims, claimsStatus, claimsErr } = useSelector(selectClaims);

  // const { profile } = useLoggedInuser();
  const dispatch = useDispatch();

  const {
    control,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(editClaimsSchema),
    defaultValues: {
      ...editClaimInit,
      userId: userClaim.userId,
    },
  });

  useEffect(() => {
    if (userClaim) {
      reset({
        claims: userClaim.claims,
        userId: userClaim.userId,
      });
    }
  }, [reset, userClaim]);

  useEffect(() => {
    if (claimsStatus === FETCH_STATUS.IDLE) {
      dispatch(getClaims());
    }
  }, [dispatch, claimsStatus]);

  const handleClaimSubmit = async (values) => {
    const body = {
      ...userClaim.userClaim,
      claims: values.claims,
      userId: userClaim.userId,
    };

    try {
      setPending();

      await axiosApiInstance
        .post(`${API_URL}/Claims/UpdateUserClaims`, {
          claims: body.claims,
          userId: body.id,
        })
        .then((data) => {
          dispatch(updateUserClaims(body));
        });

      setResolved();
    } catch (error) {
      setRejected(error || "unable to update user claims");
    }
  };

  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog as="div" className="relative z-[10000000]" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-75"
            leave="ease-in duration-75"
            leaveFrom="opacity-75"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl max-h-max overflow-hidden text-left transition-all transform bg-app-purple-2 shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-center text-white bg-gradient-to-b from-app-purple-4 to-app-purple-3 p-3.5 "
                  >
                    Edit Staff Claim
                  </Dialog.Title>
                  <>
                    <ErrorWithWrapper
                      error={err || claimsErr}
                      show={updateStatus === FETCH_STATUS.REJECTED}
                      retry={resetErr}
                      className="!max-w-sm !mx-auto"
                    />
                    <form
                      onSubmit={handleSubmit(handleClaimSubmit)}
                      className="relative"
                    >
                      <div className="flex items-center justify-end p-4 bg-app-purple-5">
                        <div className="inline-flex items-center space-x-7">
                          <button
                            className="p-2 text-center rounded min-w-[92px] bg-app-yellow text-app-purple-6"
                            onClick={onClose}
                          >
                            Cancel
                          </button>

                          <button
                            type="submit"
                            disabled={updateStatus === FETCH_STATUS.PENDING}
                            className="p-2 rounded min-w-[92px] text-app-yellow bg-app-purple-4 disabled:opacity-30 inline-flex items-center justify-center space-x-2"
                          >
                            <span>Save</span>
                            <Spinner
                              className="!w-3 !h-3 text-white fill-app-yellow"
                              status={updateStatus}
                            />
                          </button>
                        </div>
                      </div>
                      <section className="relative px-6 md:px-0 min-h-[200px] py-4 overflow-auto">
                        <div className=" mx-auto md:mx-10">
                          <Input
                            inputClass="!bg-transparent"
                            readOnly
                            disabled
                            label="Staff Name:"
                            value={userClaim?.name}
                            wrapperClass={wrapperClass}
                          />

                          <div className={`items-center ${wrapperClass}`}>
                            <label
                              className={`text-right`}
                              htmlFor="filmFormats"
                            >
                              Staff Claims:
                            </label>

                            <div className="relative">
                              <Controller
                                control={control}
                                name="claims"
                                render={({ field: { value, onChange } }) => {
                                  return (
                                    <>
                                      <MultiSearchSelect
                                        inputClassName=""
                                        listClassName="!py-2"
                                        items={claims}
                                        id={"claims"}
                                        value={getSelectedItem(claims, value)}
                                        onChange={(value) => onChange(value)}
                                        displayValue={(value) => {
                                          return value.map((v) => v).join(", ");
                                        }}
                                        placeholder={`Select Claims`}
                                      />
                                    </>
                                  );
                                }}
                              />

                              <InlineErr err={errors?.claims?.message} />
                            </div>
                          </div>
                        </div>
                      </section>
                    </form>
                  </>
                  <SuccessModal
                    onClick={onClose}
                    message="Updated user claim(s) successfully."
                    show={updateStatus === FETCH_STATUS.RESOLVED}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export { ClaimsModal };
