import React, { Fragment } from "react";
import { ModalSkeleton } from "../../Shared";

const ReceiptImage = ({ show, image, onClose }) => {
  return (
    <ModalSkeleton openStatus={show} dialogPanelStyles="flex max-w-[800px] max-h-[800px] min-h-[300px] min-w-[300px] justify-center" closeFunc={onClose}>
      <img
        alt="stock-receipt"
              src={image}
        className=" bg-no-repeat bg-contain bg-center"
      />
    </ModalSkeleton>
  );
};

export { ReceiptImage };
