import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ActiveRequestSpinner, PrivateRoute } from "../../../../components";
import { DateTimeInput } from "../../../../components/Films/DateTimeInput";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import {
  stockData,
  getSimplifiedST,
  getStockTransfer,
  createTransferReceipt,
  updateFetchedStockReceipt,
} from "../../../../redux/slices";
import { FETCH_STATUS } from "../../../../utils";

import { Spinner, FormError } from "../../../../components";

import addDays from "date-fns/addDays";

import TRItemsTable from "./TRItemsTable";

const InlineError = ({ err }) => {
  if (err) {
    return (
      <p className={`italic  text-red-400 font-light text-[10px] `}>{err}</p>
    );
  }

  return null;
};
const TransferReceiptForm = () => {
  const navigate = useNavigate();

  const [items, setItems] = useState([]);
  const [itemsReceived, setItemsReceived] = useState(false);

  const {
    simplifiedST,
    simplifiedSTStatus,
    createTransferReceiptErr,
    createTransferReceiptStatus,
    stockTransfer,
  } = useSelector(stockData);
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(
    addDays(new Date(), -30).toISOString()
  );
  const [endDate, setEndDate] = useState(new Date().toISOString());

  const TRFetchFunc = (e) => {
    e.preventDefault();
    dispatch(
      getSimplifiedST({
        startDate,
        endDate,
        force: true,
      })
    );
  };

  const formatData = () => {
    return {
      stockTransferId: stockTransfer.id,
      storeLocationId: stockTransfer.originatingTransferRequestId,
      itemsReceived: itemsReceived,
      items: items.map((item) => {
        return {
          itemStockTransferId: item?.id,
          receivedQuantity: item?.quantityReceived,
        };
      }),
    };
  };

  const createReceiptFunction = () => {
    const cleanedData = formatData();
    dispatch(createTransferReceipt(cleanedData))
      .unwrap()
      .then((data) => {
        // dispatch(
        //   updateFetchedStockReceipt([
        //     {
        //       ...data,
        //       requestedBy: `${data?.requestedBy?.firstName} ${data?.requestedBy?.lastName}`,
        //     },
        //     ...stockReceipts,
        //   ])
        // );
        navigate("/dashboard/stock/stockTransferReceipt");
      });
  };

  return (
    <PrivateRoute redirectTo="/dashboard/stock/stockReceipt">
      <>
        <div className="flex items-center justify-end p-4 px-8 rounded-t-lg bg-gradient-to-b from-app-purple-4 to-app-purple-3">
          <div className="flex buttongroup">
            <button
              className="p-3 px-5 rounded-md text-[#C96FCC] bg-white shadow-md mr-4 hover:font-bold"
              onClick={createReceiptFunction}
            >
              Submit
            </button>

            <button
              className="p-3 px-5 rounded-md text-[#C96FCC] bg-white shadow-md hover:font-bold"
              onClick={() => {
                navigate("/dashboard/stock/stockReceipt");
              }}
            >
              Back
            </button>
          </div>
        </div>
        <FormError className="mt-[20px]" err={createTransferReceiptErr} />
        <p className="text-center text-[20px] p-3">
          Select Date Range to get Stock Transfers
        </p>
        <form onSubmit={TRFetchFunc}>
          <div className="flex justify-center">
            <div className=" pb-3.5 ">
              <label
                htmlFor={`value`}
                className="mb-2 text-md font-bold text-[#666666] "
              >
                From
              </label>
              <DatePicker
                placeholderText="Expiry Date"
                selected={new Date(startDate)}
                onChange={(value) => setStartDate(value.toISOString())}
                customInput={
                  <DateTimeInput
                    className="bg-app-purple-8 w-[8rem] md:w-[10rem]"
                    dateFormat={"EEE, MMM dd, yyyy"}
                    value={startDate}
                  />
                }
              />
            </div>
            <div className=" pb-3.5 ">
              <label
                htmlFor={`value`}
                className="mb-2 text-md font-bold text-[#666666] "
              >
                To
              </label>
              <DatePicker
                placeholderText="Expiry Date"
                selected={new Date(endDate)}
                onChange={(value) => {
                  setEndDate(value.toISOString());
                }}
                customInput={
                  <DateTimeInput
                    className="bg-app-purple-8 w-[8rem] md:w-[10rem]"
                    dateFormat={"EEE, MMM dd, yyyy"}
                    value={endDate}
                  />
                }
              />
            </div>
          </div>
          <div className="flex justify-center">
            <button
              className=" mb-[30px] p-1 px-3 flex items-center justify-between rounded-md text-white bg-[#7E2080] shadow-sm hover:shadow-xl hover:text-white"
              type="submit"
            >
              Get Transfer Request
              <Spinner
                className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                status={simplifiedSTStatus}
              />
            </button>
          </div>
        </form>
        <div className="flex justify-center">
          <select
            className="rounded bg-[#d0a3d3] border-0 mb-3 w-[250px] mr-2"
            onChange={(e) => {
              dispatch(getStockTransfer(e.target.value))
                .unwrap()
                .then((data) => {
                  const newData = data?.items.map((item) => {
                    return item;
                  });
                  setItems([...newData]);
                });
            }}
          >
            <option value={""}>Select Transfer Request</option>
            {simplifiedST?.length > 0 &&
              simplifiedST?.map((ST, index) => {
                return (
                  <option value={ST.id} key={index}>
                    {ST.id}
                  </option>
                );
              })}
          </select>
          <div className="ml-2">
            <label htmlFor={`itemsReceived`} className="block">
              {" "}
              Items Received
            </label>
            <input
              className="accent-[#d0a3d3]  mb-3 text-[#C96FCC] !ring-transparent"
              type="checkbox"
              id="itemsReceived"
              onChange={(e) => {
                setItemsReceived(e.target.checked);
              }}
            />
          </div>
        </div>

        <div className="overflow-x-scroll">
          <TRItemsTable items={items} setItems={setItems} />
        </div>
      </>

      <ActiveRequestSpinner
        show={createTransferReceiptStatus === FETCH_STATUS.PENDING && true}
      />
    </PrivateRoute>
  );
};

export { TransferReceiptForm };
