import React, { useCallback, useState, useLayoutEffect } from "react";

import endOfDay from "date-fns/endOfDay";
import startOfDay from "date-fns/startOfDay";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";

import { useDrag, useDrop } from "react-dnd";
import { VIEW_MODES } from "../../../utils";

const EventCard = ({
  offsetHorizontal = 0,
  dragItemType,
  width = 10,
  renderEvent,
  event,
  useContextTrigger = () => {},
}) => {
  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: dragItemType,
    item: event,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [bindTrigger] = useContextTrigger({
    collect: () => event,
  });

  return (
    <div
      ref={dragRef}
      style={{
        transform: `translate(${offsetHorizontal}px,-50%)`,
        width: `${width}px`,
      }}
      className={`absolute z-[1] left-0 top-2/4 truncate max-h-[85%] cursor-pointer ${
        isDragging ? "opacity-40" : "opacity-100"
      }`}
    >
      <div className="relative" {...bindTrigger}>
        {renderEvent(event, VIEW_MODES.DAY)}
      </div>
    </div>
  );
};

const DropDetector = ({
  events,
  resourceId,
  onDrop,
  activeDate,
  rowWidth,
  node,
  dragItemType,
}) => {
  const [{ isOver }, dropRef] = useDrop(
    () => ({
      accept: dragItemType,
      drop: (item, monitor) => {
        if (node) {
          const dropPos = monitor.getClientOffset();
          const dims = node.getBoundingClientRect();

          onDrop({
            event: item,
            resourceId,
            newPosX: dropPos.x - dims.x,
            newRowWidth: rowWidth,
            activeDate,
          });
        }
      },
      collect: (monitor) => {
        return {
          isOver: !!monitor.isOver(),
        };
      },
    }),
    [events, node, rowWidth]
  );

  return (
    <div
      ref={dropRef}
      className={`z-[0] absolute left-0 right-0 top-0 bottom-0 w-full h-full ${
        isOver ? "dashed" : ""
      }`}
    />
  );
};

const DayRow = ({
  renderEvent,
  events,
  renderResource,
  resourceIdentifier,
  resource,
  activeDate,
  dragItemType,
  onDrop,
  useContextTrigger = () => {},
}) => {
  const [node, setNode] = useState(null);
  const [width, setWidth] = useState(0);

  const rowRef = useCallback((node) => {
    if (node !== null) {
      setNode(node);
    }
  }, []);

 

  const getWidth = useCallback(
    (item) => {
      if (node) {
        const rowWidth = width;

        const dayDiff = differenceInMilliseconds(
          endOfDay(new Date(item.startTime)),
          startOfDay(new Date(item.startTime))
        );

        const duration = differenceInMilliseconds(
          new Date(item.endTime),
          new Date(item.startTime)
        );

        const ratio = duration / dayDiff;

        const scaledWidth = ratio * rowWidth;

        return scaledWidth;
      }

      return 0;
    },
    [node, width]
  );

  const getTranslate = useCallback(
    (item) => {
      if (node) {
        const rowWidth = width;

        const dayDiff = differenceInMilliseconds(
          endOfDay(new Date(item.startTime)),
          startOfDay(new Date(item.startTime))
        );

        const itemDiff = differenceInMilliseconds(
          new Date(item.startTime),
          startOfDay(new Date(activeDate))
        );

        const dayRatio = itemDiff / dayDiff;

        const scaledOffset = dayRatio * rowWidth;

        return scaledOffset;
      }

      return 0;
    },
    [node, width, activeDate]
  );

  useLayoutEffect(() => {
    if (node) {
      const measure = () => {
        setWidth(node.getBoundingClientRect().width);
      };

      measure();
      window.addEventListener("resize", measure);

      return () => {
        window.removeEventListener("resize", measure);
      };
    }
  }, [node]);

  return (
    <div className=" min-h-[114px] grid grid-cols-[8%_minmax(0,1fr)] odd:bg-app-purple-7 even:bg-app-purple-5 ">
      <div className="flex items-center justify-center">
        {renderResource(resource[resourceIdentifier])}
      </div>

      <div className="relative overflow-hidden mode-day-grid" ref={rowRef}>
        <DropDetector
          events={events}
          onDrop={onDrop}
          resourceId={resource[resourceIdentifier]} // eg resource.id
          dragItemType={dragItemType}
          activeDate={activeDate}
          rowWidth={width}
          node={node}
        />

        {events.map((ev, i) => (
          <EventCard
            offsetHorizontal={getTranslate(ev)}
            width={getWidth(ev)}
            renderEvent={renderEvent}
            event={ev}
            key={i}
            dragItemType={dragItemType}
            //pass trigger
            useContextTrigger={useContextTrigger}
          />
        ))}
      </div>
    </div>
  );
};

export default DayRow;
