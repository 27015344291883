import { useMemo, useEffect, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { reportData, getOnlineOrders } from "../../redux/slices";

import format from "date-fns/format";
import {
  PrivateRoute,
  ClientPaginatedTable,
  ErrorWithWrapper,
  Loading,
  Success,
  DefaultColumnFilter,
  ExportTable,
} from "..";

import { FETCH_STATUS, formatNumberAsCurrency } from "../../utils";
import { TransactionDetailsModal } from "./TransactionDetailsModal";
import { OnlineOrderDetailsModal } from "./OnlineOrderDetailsModal";

const OnlineOrdersTable = ({ funcProps, reportRef, id }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [orderData, setOrderData] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [enteredValue, setEnteredValue] = useState("");

  const { onlineOrders, onlineOrdersStatus, onlineOrdersErr, ticketSold } =
    useSelector(reportData);

  useEffect(() => {
    if (onlineOrders?.pagedList?.length > 0) {
      setFilteredData(onlineOrders?.pagedList);
    }
  }, [onlineOrders]);

  useEffect(() => {
    if (enteredValue.length === 0) {
      setFilteredData(onlineOrders?.pagedList);
    }
  }, [enteredValue, onlineOrders]);

  const onChangeHandler = (e) => {
    setEnteredValue(e.target.value);
    const result = filteredData.filter((data) => {
      return (
        data.name.toLowerCase().includes(enteredValue.toLowerCase(), 0) ||
        data?.id.includes(enteredValue, 0)
      );
    });

    setFilteredData(result);
  };

  const getInfo = useCallback(
    (force) => {
      dispatch(getOnlineOrders(funcProps));
    },
    [dispatch]
  );

  const columns = useMemo(() => {
    return [
      {
        Header: "Booking Id",
        accessor: "id",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Contact",
        Cell: ({ cell }) => {
          return (
            <>
              {" "}
              <p>{cell?.row?.original?.email}</p>{" "}
              <p>{cell?.row?.original?.phoneNumber}</p>
            </>
          );
        },
      },
      {
        Header: "Source",
        Cell: ({ cell }) => {
          return cell?.row?.original?.order?.source;
        },
      },
      {
        Header: "Booked At",
        accessor: "dateCreated",
        Cell: ({ value }) => {
          return new Date(value).toLocaleString();
        },
      },
      {
        Header: "Redeemed At",
        accessor: "dateCompleted",
        Cell: ({ value }) => {
          return value ? new Date(value).toLocaleString() : "";
        },
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Redeemed By",
        accessor: "reedemedBy",
        Cell: ({ value, row }) => {
          if (row?.original?.status === "Completed") {
            if (row?.original?.isSelfService) {
              return "Self Service";
            } else {
              return ` ${value?.firstName}  ${value?.lastName}`;
            }
          } else {
            return "";
          }
        },
      },
    ];
  }, []);

  const exportColumn = useMemo(() => {
    return [
      {
        Header: "Booking Id",
        accessor: "id",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Contact",
        accessor: "phoneNumber",
      },
      {
        Header: "E-mail",
        accessor: "email",
      },
      {
        Header: "Booked At",
        accessor: "dateCreated",
        Cell: ({ value }) => {
          return new Date(value).toLocaleString();
        },
      },
      {
        Header: "Redeemed At",
        accessor: "dateCompleted",
        Cell: ({ value }) => {
          return value ? new Date(value).toLocaleString() : "";
        },
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Redeemed By",
        accessor: "reedemedBy",
        Cell: ({ value, row }) => {
          if (row?.original?.status === "Completed") {
            if (row?.original?.isSelfService) {
              return "Self Service";
            } else {
              return ` ${value?.firstName}  ${value?.lastName}`;
            }
          } else {
            return "";
          }
        },
      },
    ];
  }, []);

  const onRowClick = (row) => () => {
    setOrderData(row.original.order);
    setShowModal({ status: row.original.status, id: row.original.id });
  };

  return (
    <div className=" mt-6">
      <ErrorWithWrapper
        error={onlineOrdersErr}
        show={onlineOrdersStatus === FETCH_STATUS.REJECTED}
        retry={getInfo}
      />

      <Loading
        wrapperClassName="relative min-h-[50vh]"
        show={onlineOrdersStatus === FETCH_STATUS.PENDING}
      />

      <Success show={onlineOrdersStatus === FETCH_STATUS.RESOLVED}>
        {/* Main Table */}
        <div className="flex justify-center my-5">
          <div className={`relative `}>
            <div
              className={`absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none`}
            >
              <svg
                className="w-5 h-5 text-[#CEBCCE] "
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className={`block min-w-[280px] p-3 text-sm pl-10 rounded-xl bg-white outline-none `}
              value={enteredValue}
              onChange={onChangeHandler}
              placeholder={`Search by Booking Id or Name`}
            />
          </div>
        </div>
        <section ref={reportRef}>
          <div className="flex justify-center">
            <p className="text-center py-3 mr-2">
              Total Tickets sold:
              <span className="font-bold ml-2">
                {`${ticketSold.ticketsSoldOnline}`}
              </span>
            </p>
            <p className="text-center py-3 ml-2">
              Total Online Ticket Sales:
              <span className="font-bold ml-2">
                {`${formatNumberAsCurrency(ticketSold.totalOnlineSales)}`}
              </span>
            </p>
          </div>
          <div className="flex justify-center">
            <p className="text-center py-3 mr-2">
              Total Online Concession Sales:{" "}
              <span className="font-bold ml-2">{` ${formatNumberAsCurrency(
                ticketSold.totalConcessionOnlineSales
              )}`}</span>
            </p>
            <p className="text-center py-3 ml-2">
              Online Sales Total:{" "}
              <span className="font-bold ml-2">
                {" "}
                {`  ${formatNumberAsCurrency(
                  ticketSold.totalOnlineSales +
                    ticketSold.totalConcessionOnlineSales
                )}`}
              </span>
            </p>{" "}
          </div>
          <ClientPaginatedTable
            filterColumnsKeys={[]}
            onRowClick={onRowClick}
            tableData={filteredData}
            columns={columns}
            getHeaderProps={(column) => ({
              className:
                "px-3 pt-8 pb-2 text-base font-medium border border-[#00000033] ",
              ...(column.id === "name" ? column?.getSortByToggleProps() : {}),
            })}
            getCellProps={(cellInfo) => ({
              style: {},
              className: `${
                cellInfo?.column?.id === "" ? "text-app-purple-9" : ""
              } ${cellInfo?.column?.id === "days" ? "break-all" : ""} `,
            })}
            getColumnProps={() => ({ style: {} })}
          />
        </section>
        <div className="hidden">
          <ExportTable
            columns={exportColumn}
            filteredData={filteredData}
            id={id}
          />
        </div>
      </Success>

      {showModal && (
        <OnlineOrderDetailsModal
          funcProps={funcProps}
          orderData={orderData}
          show={showModal}
          onClose={() => {
            setShowModal(false);
            setOrderData({});
          }}
        />
      )}
    </div>
  );
};

export { OnlineOrdersTable };
