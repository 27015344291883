import React from "react";
import { forwardRef } from "react";
import DatePicker from "react-datepicker";
import { formatDateTime } from "../../utils";
import { Calendar } from "../Icons";

const CustomInput = forwardRef((props, ref) => {
  const { value, onChange, onClick } = props;

  return (
    <input
      value={formatDateTime(new Date(value), "EEEE, MMM dd, yyyy")}
      onChange={onChange}
      onClick={onClick} //to trigger calenda open
      className="block w-full text-center bg-transparent  px-5 mr-1 py-[18px]"
      ref={ref}
    />
  );
});

const RosterToolbar = ({ activeDate, setActiveDate, toggleCreate }) => {
  return (
    <>
      <div className="flex items-center justify-between">
        <div
          className="rounded-t-md"
          // role="group"
        >
          <button
            type="button"
            //   onClick={handleClick(VIEW_MODES.WEEK)}
            className={`py-4 px-[25px] transition-colors text-base font-medium bg-app-purple-5 text-app-purple-6`}
          >
            Week
          </button>
        </div>
        <div className="relative bg-app-purple-5 rounded-[10px] my-0.5 z-50 px-2">
          <span
            role="presentation"
            className="absolute left-0 scale-75 translate-x-1 top-2/4 -translate-y-2/4"
          >
            <Calendar />
          </span>

          <span
            role="presentation"
            className="absolute right-0 -translate-x-2 top-2/4 -translate-y-2/4 "
          >
            <svg
              width="9"
              height="5"
              viewBox="0 0 9 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.232562 0.256018C0.55332 -0.0721195 0.999622 -0.0978287 1.39159 0.256018L4.26534 3.01112L7.13902 0.256018C7.53107 -0.0978287 7.97818 -0.0721195 8.29626 0.256018C8.44596 0.417516 8.52913 0.629597 8.52913 0.849804C8.52913 1.07001 8.44596 1.28209 8.29626 1.44359C7.99756 1.75105 4.84392 4.75392 4.84392 4.75392C4.76889 4.83183 4.67891 4.8938 4.57937 4.93613C4.47983 4.97846 4.37278 5.00028 4.26461 5.00028C4.15645 5.00028 4.04939 4.97846 3.94985 4.93613C3.85032 4.8938 3.76034 4.83183 3.6853 4.75392C3.6853 4.75392 0.533125 1.75105 0.233049 1.44359C0.0832454 1.28215 0 1.07004 0 0.849804C0 0.629564 0.0832454 0.417463 0.233049 0.256018H0.232562Z"
                fill="#222222"
              />
            </svg>
          </span>

          <DatePicker
            selected={activeDate}
            onChange={(date) => setActiveDate(date)}
            customInput={<CustomInput />}
          />
        </div>
        <button
          className="relative bg-app-purple-8 rounded-[10px] text-app-yellow px-4 py-[13px]"
          type="button"
          onClick={toggleCreate}
        >
          Create Roster
        </button>
      </div>
    </>
  );
};

export default RosterToolbar;
