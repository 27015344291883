
import React from "react";


const ContractDatesRow = ({
  index,
  register,
  remove,
}) => {
    
  return (
    <>
      <div className="grid grid-cols-4 border border-[#A86AAA] border-x-0 border-t-0 bg-app-purple-13">
        <input
          type="number"
          name="weeNumber"
          disabled
          value={index + 1}
          id={`weekNumber`}
          {...register(`contractDates.${index}.weekNumber`)}
          className="bg-transparent border-none"
        />

        <input
          type="number"
          name="percentage"
          min="0"
          step="0.01"
          id={`percentage`}
          {...register(`contractDates.${index}.percentage`)}
          className="w-[6rem] text-black text-sm h-[1.5rem] focus:outline-none border-none text-end mt-2 border-b"
        />
        <input
          type="number"
          name="minGuarantee"
          min="0"
          step="0.01"
          id={`minGuarantee`}
          {...register(`contractDates.${index}.minGuarantee`)}
          className="w-[6rem] text-black text-sm h-[1.5rem] focus:outline-none border-none text-end mt-2"
        />
        <div className="flex justify-center">
          <button
            type="button"
            className="bg-gradient-[180deg] to-[#764877] from-[#A86AAA] px-2 py-1 rounded-md text-[12px] text-white m-2 w-[4rem]"
            onClick={() => {
              remove(index);
            }}
          >
            Remove
          </button>
        </div>
      </div>
      
    </>
  );
};

export { ContractDatesRow };
