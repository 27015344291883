import * as React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { locationInit, locationSchema } from "../../utils";
import { InlineErr, Spinner } from "../UI";

const LocationForm = ({ location, isLoading, onSubmit }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(locationSchema),
    defaultValues: location || locationInit,
  });

  return (
    <form
      className="w-full h-full bg-white rounded-[12px] py-[28px] px-[30px] flex flex-col gap-5"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex flex-col md:grid grid-cols-1 md:grid-cols-2 gap-y-7 gap-x-5">
        <div className="contents relative w-full">
          <input
            className="w-full placeholder:text-[#ABAFB1] px-[16px] py-[14px] border-none rounded-lg bg-[#AA7DAA26]"
            id="name"
            type="text"
            placeholder="Name"
            {...register("name")}
          />
          <InlineErr err={errors?.name?.message} />
        </div>
        <div className="contents relative w-full">
          <input
            className="w-full placeholder:text-[#ABAFB1] px-[16px] py-[14px] border-none rounded-lg bg-[#AA7DAA26]"
            id="description"
            type="text"
            placeholder="Description"
            {...register("description")}
          />
          <InlineErr err={errors?.description?.message} />
        </div>
        <div className="contents relative w-full">
          <input
            className="w-full placeholder:text-[#ABAFB1] px-[16px] py-[14px] border-none rounded-lg bg-[#AA7DAA26]"
            id="phoneNumber"
            type="tel"
            placeholder="Phone Number"
            {...register("phone")}
          />
          <InlineErr err={errors?.phone?.message} />
        </div>
        <div className="contents relative w-full">
          <input
            className="w-full placeholder:text-[#ABAFB1] px-[16px] py-[14px] border-none rounded-lg bg-[#AA7DAA26]"
            id="email"
            type="email"
            placeholder="Email"
            {...register("email")}
          />
          <InlineErr err={errors?.email?.message} />
        </div>
        <div className="contents relative w-full">
          <input
            className="w-full placeholder:text-[#ABAFB1] px-[16px] py-[14px] border-none rounded-lg bg-[#AA7DAA26]"
            id="location"
            type="text"
            placeholder="Location"
            {...register("location")}
          />
          <InlineErr err={errors?.location?.message} />
        </div>
        <div className="contents relative w-full">
          <input
            className="w-full placeholder:text-[#ABAFB1] px-[16px] py-[14px] border-none rounded-lg bg-[#AA7DAA26]"
            id="address"
            type="text"
            placeholder="Address"
            {...register("address")}
          />
          <InlineErr err={errors?.address?.message} />
        </div>

        <div className="flex justify-end col-span-2">
          <button
            type="submit"
            className="py-[12px] px-[36px] rounded-[14px] bg-gradient-to-b from-[#764877] to-[#A86AAA] text-[#fff] w-auto"
            disabled={isLoading}
          >
            Save & Publish
            <Spinner
              className="!w-3 !h-3 text-white fill-app-yellow"
              status={isLoading}
            />
          </button>
        </div>
      </div>
    </form>
  );
};

export default LocationForm;
