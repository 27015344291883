import { useRequest } from "./useRequest";

const useShowtimesStatuses = () => {
  const { resetErr, setPending, setResolved, setRejected, err, status } =
    useRequest();

  const {
    resetErr: editResetErr,
    setPending: editSetPending,
    setResolved: editSetResolved,
    setRejected: editSetRejected,
    err: editErr,
    status: editStatus,
  } = useRequest();

  const {
    resetErr: deleteResetErr,
    setPending: deleteSetPending,
    setResolved: deleteSetResolved,
    setRejected: deleteSetRejected,
    err: deleteErr,
    status: deleteStatus,
  } = useRequest();

  return {
    resetErr,
    setPending,
    setResolved,
    setRejected,
    err,
    status,
    editResetErr,
    editSetPending,
    editSetResolved,
    editSetRejected,
    editErr,
    editStatus,
    deleteResetErr,
    deleteSetPending,
    deleteSetResolved,
    deleteSetRejected,
    deleteErr,
    deleteStatus,
  };
};

export { useShowtimesStatuses };
