import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  addItemDirect,
  editItemDirect,
  API_URL,
  axiosApiInstanceGet,
  FETCH_STATUS,
} from "../../utils";

export const getUserById = async (id) => {
  const res = await axiosApiInstanceGet(
    `${API_URL}/Users/GetById?userId=${id}`,
    true
  );

  return res;
};

export const getLoggedInUserById = createAsyncThunk(
  "getLoggedInUserById",
  async (id, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await getUserById(id);
    

      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getGenders = createAsyncThunk(
  "getGenders",
  async (force, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Genders/ListAll`,
        force
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getRoles = createAsyncThunk(
  "getRoles",
  async (force, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Roles/ViewAll`,
        force
      );

      return fulfillWithValue(response?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getStaff = createAsyncThunk(
  "getStaff",
  async (props, { fulfillWithValue, rejectWithValue }) => {
    const { force, cinemaId } = {
      force: false,
      cinemaId: "",
      ...props,
    };
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Users/GetByCinema?cinemaId=${cinemaId}`,
        force
      );

      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      console.log({ ...error });

      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getCinemasByCircuitId = createAsyncThunk(
  "getCinemasByCircuit",
  async (circuitId, { fulfillWithValue, rejectWithValue }) => {
    const force = true;
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Cinemas/ListAllByCircuit?circuitId=${circuitId}`,
        force
      );

      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const initialState = {
  profile: {
    id: "",
    firstName: "",
    lastName: "",
    cinemaId: "",
    cinema: "",
    userName: "",
    userNumber: "",
    email: "",
    roleName: "",
    circuitId: "",
  },
  userStatus: FETCH_STATUS.IDLE,
  userErr: null,

  staff: [],
  staffStatus: FETCH_STATUS.IDLE,
  staffErr: null,

  roles: [],
  rolesStatus: FETCH_STATUS.IDLE,
  rolesErr: null,

  genders: [],
  gendersStatus: FETCH_STATUS.IDLE,
  gendersErr: null,

  cinemasInCircuit: [],
  cinemasInCircuitStatus: FETCH_STATUS.IDLE,
  cinemasInCircuitErr: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addStaff: (state, action) => {
      state.staff = addItemDirect(state, action, "staff");
    },
    editStaff: (state, action) => {
      editItemDirect(state, action, "staff");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLoggedInUserById.pending, (state) => {
        state.userStatus = FETCH_STATUS.PENDING;
        state.userErr = "";
      })
      .addCase(getLoggedInUserById.fulfilled, (state, action) => {
        
        state.userStatus = FETCH_STATUS.RESOLVED;
        state.profile = action.payload;
        state.userErr = "";
      })
      .addCase(getLoggedInUserById.rejected, (state, action) => {
        state.userStatus = FETCH_STATUS.REJECTED;
        state.userErr = action.payload || "Unable to get user";
      })
      .addCase(getStaff.pending, (state) => {
        state.staffStatus = FETCH_STATUS.PENDING;
        state.staffErr = "";
      })
      .addCase(getStaff.fulfilled, (state, action) => {
        state.staffStatus = FETCH_STATUS.RESOLVED;
        state.staff = action.payload;
        state.staffErr = "";
      })
      .addCase(getStaff.rejected, (state, action) => {
        state.staffStatus = FETCH_STATUS.REJECTED;
        state.staffErr = action.payload || "Unable to get staff list";
      })

      .addCase(getRoles.pending, (state) => {
        state.rolesStatus = FETCH_STATUS.PENDING;
        state.rolesErr = "";
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        state.rolesStatus = FETCH_STATUS.RESOLVED;
        state.roles = action.payload;
        state.rolesErr = "";
      })
      .addCase(getRoles.rejected, (state, action) => {
        state.rolesStatus = FETCH_STATUS.REJECTED;
        state.rolesErr = action.payload || "Unable to get available roles";
      })
      .addCase(getGenders.pending, (state) => {
        state.gendersStatus = FETCH_STATUS.PENDING;
        state.gendersErr = "";
      })
      .addCase(getGenders.fulfilled, (state, action) => {
        state.gendersStatus = FETCH_STATUS.RESOLVED;
        state.genders = action.payload;
        state.gendersErr = "";
      })
      .addCase(getGenders.rejected, (state, action) => {
        state.gendersStatus = FETCH_STATUS.REJECTED;
        state.gendersErr = action.payload || "Unable to get available genders";
      })
      .addCase(getCinemasByCircuitId.pending, (state) => {
        state.cinemasInCircuitStatus = FETCH_STATUS.PENDING;
        state.cinemasInCircuitErr = "";
      })
      .addCase(getCinemasByCircuitId.fulfilled, (state, action) => {
        state.cinemasInCircuitStatus = FETCH_STATUS.RESOLVED;
        state.cinemasInCircuit = action.payload;
        state.cinemasInCircuitErr = "";
      })
      .addCase(getCinemasByCircuitId.rejected, (state, action) => {
        state.cinemasInCircuitStatus = FETCH_STATUS.REJECTED;
        state.cinemasInCircuitErr =
          action.payload || "Unable to get cinemas in circuit";
      });
  },
});

export const userReducer = userSlice.reducer;
export const selectUser = (state) => state.user;

export const selectStaff = (state) => {
  const { staff, staffStatus, staffErr } = state.user;

  let orderedStaff = [];
  let staffList = staff.map((x) => {
    return x;
  });

  if (staff?.length > 0) {
    orderedStaff = staffList?.sort((a, b) =>
      a.firstName.localeCompare(b.firstName)
    );
  }

  return { staff, orderedStaff, staffStatus, staffErr };
};

export const selectRoles = (state) => {
  const { roles, rolesStatus, rolesErr } = state.user;

  return { roles, rolesStatus, rolesErr };
};

export const selectGenders = (state) => {
  const { genders, gendersStatus, gendersErr } = state.user;

  return { genders, gendersStatus, gendersErr };
};

export const selectCinemas = (state) => {
  const { cinemasInCircuit, cinemasInCircuitStatus, cinemasInCircuitErr } =
    state.user;

  return { cinemasInCircuit, cinemasInCircuitStatus, cinemasInCircuitErr };
};

export const { addStaff, editStaff } = userSlice.actions;
