import {yupResolver} from "@hookform/resolvers/yup";
import DatePicker from "react-datepicker";
import React, {useEffect, useMemo, useRef,} from "react";
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    FormGroup,
    GBOByScreenFormatTable,
    InlineErr,
    Label,
    PdfButton,
    Pdiv,
    PrivateRoute,
    SearchSelect,
    Spinner,
    Success,
} from "../../../components";
import {useLoggedInuser} from "../../../hooks";
import {getFilmFormat, getGBOByScreenFormat, reportData, selectFilms,} from "../../../redux/slices";
import {
    FETCH_STATUS,
    formatDateForApi,
    GBOByScreenFormatInit,
    GBOByScreenFormatSchema,
    getSelectedItem2,
} from "../../../utils";
import {DateTimeInput} from "../../../components/Films/DateTimeInput";
import {Element, scroller} from "react-scroll";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";

const GBOByScreenFormat = () => {
  const { profile } = useLoggedInuser();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    distributorsList,
    distributorsListStatus,
    distributorFilms,
    GBOByScreenFormat,
    GBOByScreenFormatStatus,
  } = useSelector(reportData);
  const { filmFormat, getFilmFormatErr, getFilmFormatStatus } =
    useSelector(selectFilms);

  const formattedFilmFormat = useMemo(() => {
    return [{ name: "All Formats", id: "allFormats" }, ...filmFormat];
  }, [filmFormat]);

  const reportRef = useRef(null);

  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(GBOByScreenFormatSchema),
    defaultValues: { ...GBOByScreenFormatInit },
  });

  useEffect(() => {
    if (
      getFilmFormatStatus === FETCH_STATUS.IDLE ||
      getFilmFormatStatus === FETCH_STATUS.REJECTED
    ) {
      dispatch(getFilmFormat());
    }
  }, [dispatch, getFilmFormatStatus]);

  const onSubmit = async (values) => {
    try {
      await dispatch(
        getGBOByScreenFormat({
          ...values,
          cinemaId: profile?.cinemaId,
          dateFrom: formatDateForApi(startOfDay(new Date(values.dateFrom))),
          dateTo: formatDateForApi(endOfDay(new Date(values.dateTo))),
        })
      ).then(() => {
        scroller.scrollTo("reportContainer", {
          duration: 1500,
          delay: 0,
          smooth: true,
          offset: -100, // Scrolls to element + 50 pixels down the page
        });
      });
    } catch (error) {}
  };

  return (
    <>
      <PrivateRoute redirectTo={"/dashboard/reports/gbo-by-screenformat"}>
        <div className="w-[95%] mx-auto mt-6">
          <p className="font-semi-bold px-3 pb-5">
            GBO By Screen Format - Set Report Parameters
          </p>
          <div className="flex items-center justify-between p-4 px-8 h-[50px] rounded-t-lg bg-[#C96FCC]">
            <div className="flex gap-4">
              <span className="text-xl font-semibold text-white"></span>
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex items-center justify-end py-3 px-4 bg-white ">
              <div className="flex gap-3 buttongroup ">
                <button
                  className="p-1 px-3 rounded-md text-[#C96FCC] bg-[#ffe583] shadow-md hover:shadow-sm"
                  type="button"
                  onClick={() => {
                    navigate("/dashboard/reports");
                  }}
                >
                  Cancel
                </button>
                <button
                  className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm flex items-center"
                  type="submit"
                >
                  <span> Run Report </span>
                  <Spinner
                    className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                    status={GBOByScreenFormatStatus}
                  />
                </button>
              </div>
            </div>
            <div className="bg-app-purple-2 p-3 pt-[30px]">
              <Pdiv label={"Date Range"}>
                <div className="flex items-center">
                  <Controller
                    control={control}
                    name="dateFrom"
                    render={({ field: { value, onChange } }) => (
                      <div className="relative ">
                        <DatePicker
                          placeholderText="Start Date"
                          selected={new Date(value)}
                          onChange={(value) =>
                            onChange(new Date(value).toISOString())
                          }
                          customInput={
                            <DateTimeInput
                              className="bg-app-purple-8"
                              dateFormat={"EEE, MMM dd, yyyy"}
                            />
                          }
                        />
                      </div>
                    )}
                  />

                  <p className="w-[6rem] text-center"> To:</p>
                  <Controller
                    control={control}
                    name="dateTo"
                    render={({ field: { value, onChange } }) => (
                      <div className="relative ">
                        <DatePicker
                          placeholderText="End Date"
                          selected={new Date(value)}
                          onChange={(value) =>
                            onChange(new Date(value).toISOString())
                          }
                          customInput={
                            <DateTimeInput
                              className="bg-app-purple-8"
                              dateFormat={"EEE, MMM dd, yyyy"}
                            />
                          }
                        />
                      </div>
                    )}
                  />
                </div>
              </Pdiv>

              <FormGroup className={"mb-[20px] relative"}>
                <Label className={"w-[23rem] mr-[30px]"}>Screen Format:</Label>

                <Controller
                  control={control}
                  name={"screenFormatId"}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <SearchSelect
                        items={formattedFilmFormat}
                        inputClassName="!w-full p-2 px-4 py-2 border-none !bg-app-purple-10"
                        listClassName="!py-2"
                        id={`screenFormatId`}
                        value={getSelectedItem2(
                          formattedFilmFormat,
                          value,
                          "id"
                        )}
                        onChange={(value) => onChange(value?.id)}
                        displayValue={(value) => {
                          return value?.name;
                        }}
                        placeholder={`Select Screen Format`}
                        searchOptions={{
                          keys: ["name"],
                        }}
                      />
                    );
                  }}
                />
                <InlineErr
                  err={errors?.screenFormatId?.message}
                  className={"left-[25rem]"}
                />
              </FormGroup>
            </div>
          </form>
        </div>
        <Success show={GBOByScreenFormatStatus === FETCH_STATUS.RESOLVED}>
          <Element name="reportContainer">
            {GBOByScreenFormat?.result?.length ? (
              <div>
                <div className="flex justify-center mt-5 space-x-4">
                  <PdfButton
                    refValue={reportRef}
                    docTitle={"gbo-by-screenFormat"}
                  />
                  {/* <ExportExcelBtn
                    id={"GBOTicket-xlsx"}
                    name={"GBOByTicket.xlsx"}
                  /> */}
                </div>

                <GBOByScreenFormatTable
                  ref={reportRef}
                  data={GBOByScreenFormat?.result}
                  total={GBOByScreenFormat?.total}
                  paramFilter={{
                    ...GBOByScreenFormat?.paramFilter,
                    firstName: profile.firstName,
                    lastName: profile.lastName,
                  }}
                />
                {/* <div className="hidden">
                  <GBOByTicketTable
                    id={"GBOTicket-xlsx"}
                    data={GBOByScreenFormat?.result}
                    total={GBOByScreenFormat?.total}
                    paramFilter={GBOByScreenFormat?.paramFilter}
                  />
                </div> */}
              </div>
            ) : (
              <p className="text-red-700 flex justify-center mt-3">
                No Data Found
              </p>
            )}
          </Element>
        </Success>
      </PrivateRoute>
    </>
  );
};

export { GBOByScreenFormat };
