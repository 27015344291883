import React from "react";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import classNames from "classnames";
import styles from "./contractTable.module.css";
import PaginatePills from "../UI/Tables/PaginatePills";
import { useMemo } from "react";
import { PAGINATION } from "../../utils";
import { SearchAndStatusFilter } from "../UI";

const ContractTable = ({
  id,
  columns = [],
  filterColumnsKeys = ["name", "active"],
  tableData = [],
  perPage = PAGINATION.PAGE_SIZE,
  rowClass = "",
  onRowClick = () => {},
  toolbarChildren = null,
  getColumnProps = () => {},
  getHeaderProps = () => {},
  getRowProps = () => {},
  getCellProps = () => {},
  tableClass = "",
  toolBarWrapperClass = "",
  filtersWrapperClass = "",
  toolbarChildrenWrapperClass = "",
}) => {
  const data = useMemo(() => tableData, [tableData]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      initialState: { pageSize: perPage, pageIndex: 0 },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  let filterColumns = useMemo(
    () =>
      data.length && filterColumnsKeys.length
        ? headerGroups[0].headers.filter((col) =>
            filterColumnsKeys.includes(col.id)
          )
        : [],
    [headerGroups, filterColumnsKeys, data.length]
  );

  if (!data.length) {
    return (
      <div>
        {toolbarChildren}
        <br />
        <h4 className="m-4 text-base font-semibold text-center text-app-purple-4">
          No data
        </h4>
      </div>
    );
  }

  return (
    <>
      <div className="overflow-auto">
        <SearchAndStatusFilter
          wrapperClass={toolBarWrapperClass}
          filtersWrapperClass={filtersWrapperClass}
          childrenWrapperClass={toolbarChildrenWrapperClass}
          columns={filterColumns}
        >
          {toolbarChildren}
        </SearchAndStatusFilter>
        <table
          {...getTableProps()}
          className={classNames(
            "w-full m-auto mt-6 text-sm text-left",
            styles.table
          )}
        >
          <thead className="bg-app-purple-14 text-white">
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, i) => (
                  <th
                    {...column.getHeaderProps()}
                    className="py-3 px-8"
                    key={i}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="text-[#534C7E]">
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={index}
                  className="text-left bg-[#E6C4E7]"
                >
                  {row.cells.map((cell, i) => {
                    return (
                      <td
                        data-label={cell.column.Header}
                        {...cell.getCellProps()}
                        className="py-4 min-w-min px-8"
                        key={i}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <PaginatePills
          currentPage={pageIndex}
          noOfPages={pageCount}
          previousPage={previousPage}
          nextPage={nextPage}
          goToPage={gotoPage}
        />
      </div>
    </>
  );
};

export default ContractTable;
