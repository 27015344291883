import React from 'react'

const ScreenArcSvg = ({className}) => {
  return (
    <div>
      <svg
        className={className}
        viewBox="0 0 1192 162"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_1150_2200)">
          <path
            d="M18 141C18 141 309.881 13 593.166 13C876.451 13 1203.86 150.383 1172.97 138.319"
            stroke="#EEEEEE"
            strokeWidth="8"
            strokeLinecap="round"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_1150_2200"
            x="0.998047"
            y="0"
            width="1190.54"
            height="162"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="6.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.933333 0 0 0 0 0.933333 0 0 0 0 0.933333 0 0 0 0.82 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1150_2200"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1150_2200"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
}

export {ScreenArcSvg}
