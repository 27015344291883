import { useMemo, useEffect, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { reportData, getOrdersBySourceAndChannel } from "../../redux/slices";
import format from "date-fns/format";
import {
  PrivateRoute,
  ClientPaginatedTable,
  ErrorWithWrapper,
  Loading,
  Success,
  DefaultColumnFilter,
  ExportTable,
} from "..";

import { FETCH_STATUS, formatNumberAsCurrency } from "../../utils";
import { OrdersBySAndCModal } from "./OrdersBySAndCModal";
const OrdersBySourceAndChannelTable = ({ funcProps, id }) => {
  // const navigate = useNavigate();

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [orderData, setOrderData] = useState({});

  const {
    ordersBySourceAndChannel,
    ordersBySourceAndChannelStatus,
    ordersBySourceAndChannelErr,
    ticketSold,
  } = useSelector(reportData);

  const getInfo = useCallback(
    (force) => {
      dispatch(getOrdersBySourceAndChannel(funcProps));
    },
    [dispatch, funcProps]
  );

  const columns = useMemo(() => {
    return [
      {
        Header: "Transaction Id",
        accessor: "id",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Source",
        accessor: "source",
      },
      {
        Header: "Total",
        accessor: "amount",
        Cell: ({ value }) => formatNumberAsCurrency(value),
      },
      {
        Header: "Time",
        accessor: "dateCreated",
        Cell: ({ value }) => format(new Date(value), "HH:mm:ss"),
      },
      {
        Header: "Has Refunds",
        accessor: "hasRefunds",
        Cell: ({ value, row }) => {
          return value ? (
            <p className="text-center">{"Yes"}</p>
          ) : (
            <p className="text-center">{"No"}</p>
          );
        },
      },
      {
        Header: "Payment Channels",
        accessor: "paymentChannels",
        Cell: ({ value }) => {
          let channels = "";
          value?.map((channel) => {
            return (channels =
              channels +
              `${channel.channel}: ${formatNumberAsCurrency(
                channel.amount
              )}  `);
          });

          return channels;
        },
      },
    ];
  }, []);
  const exportColumn = useMemo(() => {
    return [
      {
        Header: "Transaction Id",
        accessor: "id",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Source",
        accessor: "source",
      },
      {
        Header: "Total",
        accessor: "amount",
        Cell: ({ value }) => value,
      },
      {
        Header: "Time",
        accessor: "dateCreated",
        Cell: ({ value }) => format(new Date(value), "HH:mm:ss"),
      },
      {
        Header: "Has Refunds",
        accessor: "hasRefunds",
        Cell: ({ value, row }) => {
          return value ? (
            <p className="text-center">{"Yes"}</p>
          ) : (
            <p className="text-center">{"No"}</p>
          );
        },
      },
      {
        Header: "Payment Channels",
        accessor: "paymentChannels",
        Cell: ({ value }) => {
          let channels = "";
          value?.map((channel) => {
            return (channels =
              channels + `${channel.channel}: ${channel.amount}  `);
          });

          return channels;
        },
      },
    ];
  }, []);

  const onRowClick = (row) => () => {
    setOrderData(row.original);
    setShowModal(true);
  };

  return (
    <>
      <div className=" mt-6">
        <ErrorWithWrapper
          error={ordersBySourceAndChannelErr}
          show={ordersBySourceAndChannelStatus === FETCH_STATUS.REJECTED}
          retry={getInfo}
        />

        <Loading
          wrapperClassName="relative min-h-[50vh]"
          show={ordersBySourceAndChannelStatus === FETCH_STATUS.PENDING}
        />

        <Success
          show={ordersBySourceAndChannelStatus === FETCH_STATUS.RESOLVED}
        >
          {/* totals summary */}
          <div className="flex justify-center">
            <p className="text-center py-3 mr-2">
              Total Web Tickets sold:
              <span className="font-bold ml-2">
                {`${ticketSold?.ticketsSoldOnline}`}
              </span>
            </p>
            <p className="text-center py-3 ml-2">
              Total Web Ticket Sales:
              <span className="font-bold ml-2">
                {`${formatNumberAsCurrency(ticketSold?.totalOnlineSales)}`}
              </span>
            </p>
          </div>
          <div className="flex justify-center">
            <p className="text-center py-3 mr-2">
              Total Connect Tickets Sold:{" "}
              <span className="font-bold ml-2">{` ${ticketSold?.ticketsSoldConnect}`}</span>
            </p>
            <p className="text-center py-3 mr-2">
              Total Connect Ticket Sales:{" "}
              <span className="font-bold ml-2">{` ${formatNumberAsCurrency(
                ticketSold?.totalConnectSales
              )}`}</span>
            </p>
          </div>
          <div className="flex justify-center">
            <p className="text-center py-3 mr-2">
              Total Kiosk Tickets Sold:{" "}
              <span className="font-bold ml-2">{` ${ticketSold?.ticketsSoldKiosk}`}</span>
            </p>
            <p className="text-center py-3 mr-2">
              Total Kiosk Ticket Sales:{" "}
              <span className="font-bold ml-2">{` ${formatNumberAsCurrency(
                ticketSold?.totalKioskSales
              )}`}</span>
            </p>
          </div>
          <div className="flex justify-center">
            <p className="text-center py-3 mr-2">
              Total POS Ticket Sold:{" "}
              <span className="font-bold ml-2">{` ${ticketSold?.ticketsSoldPOS}`}</span>
            </p>
            <p className="text-center py-3 mr-2">
              Total POS Ticket Sales:{" "}
              <span className="font-bold ml-2">{` ${formatNumberAsCurrency(
                ticketSold?.totalPOSSales
              )}`}</span>
            </p>
          </div>
          <div className="flex justify-center">
            <p className="text-center py-3 mr-2">
              Total Concession Sold:{" "}
              <span className="font-bold ml-2">{` ${ticketSold?.totalConcessionSales}`}</span>
            </p>
            <p className="text-center py-3 mr-2">
              Total Concession Online Sales:{" "}
              <span className="font-bold ml-2">{` ${formatNumberAsCurrency(
                ticketSold?.totalConcessionOnlineSales
              )}`}</span>
            </p>
          </div>
          {/* Main Table */}
          <ClientPaginatedTable
            filterColumnsKeys={["id"]}
            onRowClick={onRowClick}
            tableData={ordersBySourceAndChannel?.result}
            columns={columns}
            getHeaderProps={(column) => ({
              className:
                "px-3 pt-8 pb-2 text-base font-medium border border-[#00000033]",
              ...(column.id === "name" ? column?.getSortByToggleProps() : {}),
            })}
            getCellProps={(cellInfo) => ({
              style: {},
              className: `${
                cellInfo?.column?.id === "" ? "text-app-purple-9" : ""
              } ${cellInfo?.column?.id === "days" ? "break-all" : ""} `,
            })}
            getColumnProps={() => ({ style: {} })}
          />
          <div className="hidden">
            <ExportTable
              columns={exportColumn}
              filteredData={ordersBySourceAndChannel?.result}
              id={id}
            />
          </div>
        </Success>
        {showModal && (
          <OrdersBySAndCModal
            funcProps={funcProps}
            orderData={orderData}
            show={showModal}
            onClose={() => {
              setShowModal(false);
              setOrderData({});
            }}
          />
        )}
      </div>
    </>
  );
};

export { OrdersBySourceAndChannelTable };
