import format from "date-fns/format";
import formatDuration from "date-fns/formatDuration";
import dayjs from "dayjs";

const dateAndTime = "dd-MM-yyyy, HH:mm a";

export const formatDateForApi = (date) => format(date, "yyyy-MM-dd");
export const formatDateTimeForApi = (date) =>
  dayjs(date).format("YYYY-MM-DDTHH:mm:ss");

const formatDateTime = (date, dateFormat = "yyyy/LL/dd") => {
  if (!date) {
    return "N/A";
  }

  let formmated;


  try {
    formmated = format(new Date(date), dateFormat);
  } catch {
    formmated = format(new Date(), dateFormat);
  }

  return formmated;
};

const formatAMPM = (date) => {
  let hours = new Date(date).getHours();
  let minutes = new Date(date).getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

const formatToISOWithTZ = (date) => {
  if (!date) {
    throw new Error("Please pass in a valid date object");
  }

  const isoDateTime = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  ).toISOString();

  return isoDateTime;
};

export {
  formatDateTime,
  dateAndTime,
  formatDuration,
  formatToISOWithTZ,
  formatAMPM,
};
