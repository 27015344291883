import React, { forwardRef } from "react";

const FileUploadInput = forwardRef(
  ({ id, children, isMultiple, uploadName, headerName, ...props }, ref) => {
    return (
      <>
        <div className="bg-[#d0a3d3]  rounded p-2">
          <p className="flex justify-center">{headerName}</p>
          <input
            ref={ref}
            type="file"
            multiple={isMultiple}
            id={id}
            className={`w-0 hidden`}
            //   onChange={onChange}
            // {...register("")}
            // ${className}`}
            {...props}
          />
          {children}
          <label htmlFor={id}>
            <div className="text-white bg-gradient-[180deg] to-[#764877] from-[#A86AAA] px-4 py-2 rounded-lg text-sm text-center flex justify-center">
              {uploadName}
            </div>
          </label>
        </div>
      </>
    );
  }
);

// const FileUploadInput = ({id, children,onChange,register,uploadName,headerName,isMultiple,...props }) => {
//     return (
//       <>
//           <div className="bg-[#d0a3d3]  rounded p-2">
//               <p className='flex justify-center'>{ headerName}</p>
//         <input
//         //   ref={ref}
//           type="file"
//           multiple={isMultiple}
//           id={id}
//           className={`w-0 hidden`}
//           //   onChange={onChange}
//           // {...register("")}
//           // ${className}`}
//           {...props}
//           />
//           {children}
//               <label
//            htmlFor={id}

//               >

//                   <div className="text-white bg-gradient-[180deg] to-[#764877] from-[#A86AAA] px-4 py-2 rounded-lg text-sm text-center flex justify-center">{ uploadName}</div>

//       </label>
//       </div>
//     </>
//   )
// }

export { FileUploadInput };
