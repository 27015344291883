import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  FETCH_STATUS,
  API_URL,
  axiosApiInstanceGet,
  transformErr,
} from "../../utils";
import addHours from "date-fns/addHours";

export const getAllSales = createAsyncThunk(
  "getAllSales",
  async ({ force, cinemaId }, { fulfillWithValue, rejectWithValue }) => {
    let currentDate = addHours(new Date(), 1).toISOString();

    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/AdminDashboard/GetDashboardSalesChart?todayDate=${currentDate}&cinemaId=${cinemaId}`,
        force
      );

      return fulfillWithValue(response?.data?.data);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] ||
          transformErr(err) ||
          message
      );
    }
  }
);

export const getTicketSales = createAsyncThunk(
  "getTicketSales",
  async ({ force, cinemaId }, { fulfillWithValue, rejectWithValue }) => {
    let currentDate = addHours(new Date(), 1).toISOString();

    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/AdminDashboard/GetDashboardTicketSalesChart?todayDate=${currentDate}&cinemaId=${cinemaId}`,
        force
      );

      return fulfillWithValue(response?.data?.data);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] ||
          transformErr(err) ||
          message
      );
    }
  }
);

export const getItemSales = createAsyncThunk(
  "getItemSales",
  async ({ force, cinemaId }, { fulfillWithValue, rejectWithValue }) => {
    let currentDate = addHours(new Date(), 1).toISOString();

    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/AdminDashboard/GetDashboardItemSalesChart?todayDate=${currentDate}&cinemaId=${cinemaId}`,
        force
      );

      return fulfillWithValue(response?.data?.data);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] ||
          transformErr(err) ||
          message
      );
    }
  }
);

export const getGBO = createAsyncThunk(
  "getGbo",
  async ({ force, cinemaId }, { fulfillWithValue, rejectWithValue }) => {
    let currentDate = addHours(new Date(), 1).toISOString();

    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/AdminDashboard/GetDashboardGBO?dateFilter=${currentDate}&cinemaId=${cinemaId}`,
        force
      );

      return fulfillWithValue(response?.data?.data);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] ||
          transformErr(err) ||
          message
      );
    }
  }
);
export const getItemSalesToday = createAsyncThunk(
  "getItemSalesToday",
  async ({ force, cinemaId }, { fulfillWithValue, rejectWithValue }) => {
    let currentDate = addHours(new Date(), 1).toISOString();

    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/AdminDashboard/GetDashboardItemSales?dateFilter=${currentDate}&cinemaId=${cinemaId}`,
        force
      );

      return fulfillWithValue(response?.data?.data);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] ||
          transformErr(err) ||
          message
      );
    }
  }
);

export const getRPH = createAsyncThunk(
  "getRph",
  async ({ force, cinemaId }, { fulfillWithValue, rejectWithValue }) => {
    let currentDate = addHours(new Date(), 1).toISOString();

    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/AdminDashboard/GetDashboardRPH?dateFilter=${currentDate}&cinemaId=${cinemaId}`,
        force
      );

      return fulfillWithValue(response?.data?.data);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] ||
          transformErr(err) ||
          message
      );
    }
  }
);

export const getGuestsAdmitted = createAsyncThunk(
  "getGuestsAdmitted",
  async ({ force, cinemaId }, { fulfillWithValue, rejectWithValue }) => {
    let currentDate = addHours(new Date(), 1).toISOString();

    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/AdminDashboard/GetDashboardGuestsAdmitsOverview?dateFilter=${currentDate}&cinemaId=${cinemaId}`,
        force
      );

      return fulfillWithValue(response?.data?.data);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] ||
          transformErr(err) ||
          message
      );
    }
  }
);

const initialState = {
  allSales: {},
  allSalesStatus: FETCH_STATUS.IDLE,
  allSalesErr: null,
  ticketSales: {},
  ticketSalesStatus: FETCH_STATUS.IDLE,
  ticketSalesErr: null,
  itemSales: {},
  itemSalesStatus: FETCH_STATUS.IDLE,
  itemSalesErr: null,
  dashboardRph: {},
  dashboardRphStatus: FETCH_STATUS.IDLE,
  dashboardRphErr: null,
  dashboardGbo: [],
  dashboardGboStatus: FETCH_STATUS.IDLE,
  dashboardGboErr: null,
  guestsAdmitted: {},
  guestsAdmittedStatus: FETCH_STATUS.IDLE,
  guestsAdmittedErr: null,
  itemSalesToday: [],
  itemSalesTodayStatus: FETCH_STATUS.IDLE,
  itemSalesTodayErr: null,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllSales.pending, (state) => {
        state.allSalesStatus = FETCH_STATUS.PENDING;
        state.allSalesErr = "";
      })
      .addCase(getAllSales.fulfilled, (state, action) => {
        state.allSalesStatus = FETCH_STATUS.RESOLVED;
        state.allSalesErr = null;
        state.allSales = action.payload;
      })
      .addCase(getAllSales.rejected, (state, action) => {
        state.allSalesStatus = FETCH_STATUS.REJECTED;
        state.allSalesErr =
          action.payload || "Unable to retrieve distributor By Film Report";
      })
      .addCase(getTicketSales.pending, (state) => {
        state.ticketSalesStatus = FETCH_STATUS.PENDING;
        state.ticketSalesErr = "";
      })
      .addCase(getTicketSales.fulfilled, (state, action) => {
        state.ticketSalesStatus = FETCH_STATUS.RESOLVED;
        state.ticketSalesErr = null;
        state.ticketSales = action.payload;
      })
      .addCase(getTicketSales.rejected, (state, action) => {
        state.ticketSalesStatus = FETCH_STATUS.REJECTED;
        state.ticketSalesErr =
          action.payload || "Unable to retrieve distributor By Film Report";
      })
      .addCase(getItemSales.pending, (state) => {
        state.itemSalesStatus = FETCH_STATUS.PENDING;
        state.itemSalesErr = "";
      })
      .addCase(getItemSales.fulfilled, (state, action) => {
        state.itemSalesStatus = FETCH_STATUS.RESOLVED;
        state.itemSalesErr = null;
        state.itemSales = action.payload;
      })
      .addCase(getItemSales.rejected, (state, action) => {
        state.itemSalesStatus = FETCH_STATUS.REJECTED;
        state.itemSalesErr =
          action.payload || "Unable to retrieve distributor By Film Report";
      })
      .addCase(getGBO.pending, (state) => {
        state.dashboardGboStatus = FETCH_STATUS.PENDING;
        state.dashboardGboErr = "";
      })
      .addCase(getGBO.fulfilled, (state, action) => {
        state.dashboardGboStatus = FETCH_STATUS.RESOLVED;
        state.dashboardGboErr = null;
        state.dashboardGbo = action.payload;
      })
      .addCase(getGBO.rejected, (state, action) => {
        state.dashboardGboStatus = FETCH_STATUS.REJECTED;
        state.dashboardGboErr =
          action.payload || "Unable to retrieve distributor By Film Report";
      })
      .addCase(getRPH.pending, (state) => {
        state.dashboardRphStatus = FETCH_STATUS.PENDING;
        state.dashboardRphErr = "";
      })
      .addCase(getRPH.fulfilled, (state, action) => {
        state.dashboardRphStatus = FETCH_STATUS.RESOLVED;
        state.dashboardRphErr = null;
        state.dashboardRph = action.payload;
      })
      .addCase(getRPH.rejected, (state, action) => {
        state.dashboardRphStatus = FETCH_STATUS.REJECTED;
        state.dashboardRphErr =
          action.payload || "Unable to retrieve distributor By Film Report";
      })
      .addCase(getGuestsAdmitted.pending, (state) => {
        state.guestsAdmittedStatus = FETCH_STATUS.PENDING;
        state.guestsAdmittedErr = "";
      })
      .addCase(getGuestsAdmitted.fulfilled, (state, action) => {
        state.guestsAdmittedStatus = FETCH_STATUS.RESOLVED;
        state.guestsAdmittedErr = null;
        state.guestsAdmitted = action.payload;
      })
      .addCase(getGuestsAdmitted.rejected, (state, action) => {
        state.guestsAdmittedStatus = FETCH_STATUS.REJECTED;
        state.guestsAdmittedErr =
          action.payload || "Unable to retrieve distributor By Film Report";
      })

      .addCase(getItemSalesToday.pending, (state) => {
        state.itemSalesTodayStatus = FETCH_STATUS.PENDING;
        state.itemSalesTodayErr = "";
      })
      .addCase(getItemSalesToday.fulfilled, (state, action) => {
        state.itemSalesTodayStatus = FETCH_STATUS.RESOLVED;
        state.itemSalesTodayErr = null;
        state.itemSalesToday = action.payload;
      })
      .addCase(getItemSalesToday.rejected, (state, action) => {
        state.itemSalesTodayStatus = FETCH_STATUS.REJECTED;
        state.itemSalesTodayErr =
          action.payload || "Unable to get Items sold today";
      });
  },
});

export const dashboardData = (state) => state.dashboard;
