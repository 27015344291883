import React from "react";

const Footer = () => {
  return (
    <footer className="w-screen px-4 py-2 mt-4 font-medium">
      <small>
        Built by{" "}
        <a
          className="text-app-purple-4"
          href="https://fusionintel.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Fusion Intelligence
        </a>{" "}
        © 2022
      </small>
    </footer>
  );
};

export { Footer };
