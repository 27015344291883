import {useEffect, useRef, useState} from "react";
import {
    ExportExcelBtn,
    InputErr,
    ItemDetailSalesTable,
    PdfButton,
    Pdiv,
    Pdiv2,
    PrivateRoute,
    SearchSelect,
    Spinner,
} from "../../../components";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import DatePicker from "react-datepicker";
import addDays from "date-fns/addDays";
import {DateTimeInput} from "../../../components/Films/DateTimeInput";
import {useLoggedInuser} from "../../../hooks";
import {getItems, getItemSalesDetailReport, getStaff, reportData, selectStaff,} from "../../../redux/slices";
import {formatDateForApi, getSelectedItem2, itemDetailSalesSchema} from "../../../utils";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Element, scroller} from "react-scroll";
import {FormGroup, Label} from "../../../components/FilmContract/FormStyle";

import addHours from "date-fns/addHours";

const ItemDetailSalesReport = () => {
  const navigate = useNavigate();
  const [startDate, setStart] = useState(addDays(new Date(), -2));
  const [endDate, setEnd] = useState(new Date());
  const { itemSalesDetailReport, itemSalesDetailReportStatus } =
    useSelector(reportData);
  const { profile } = useLoggedInuser();
  const dispatch = useDispatch();
  const { orderedStaff } = useSelector(selectStaff);
  const { itemsList } = useSelector((state) => state.items);

  const reportRef = useRef(null);

  const {
    control,
    watch,
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(itemDetailSalesSchema),
    defaultValues: {
      DateFrom: startDate,
      DateTo: endDate,
      CinemaId: profile.cinemaId,
      userId: "allUsers",
      itemId: "allItems",
    },
  });

  const items = itemsList.filter(
    (item) => item.itemGroup === "Single" || item.itemGroup === "Recipe"
  );

  useEffect(() => {
    dispatch(getStaff({ force: true, cinemaId: profile?.cinemaId }));
    setValue("DateFrom", startDate);
    setValue("DateTo", endDate);
    dispatch(getItems(true));
  }, [startDate, endDate, setValue, dispatch, profile.cinemaId]);

  const onSubmit = (data) => {
    const body = {
      CinemaId: data.CinemaId,
      DateFrom: addHours(new Date(data.DateFrom), 1).toISOString(),
      DateTo: addHours(new Date(data.DateTo), 1).toISOString(),
      userId: data.userId,
      itemId: data.itemId,
    };
    dispatch(getItemSalesDetailReport({ ...body, force: true }));
    scroller.scrollTo("reportContainer", {
      duration: 1500,
      delay: 0,
      smooth: true,
      offset: -100, // Scrolls to element + 50 pixels down the page
    });
  };



  return (
    <PrivateRoute redirectTo={"/dashboard/reports/item-detail-sales-report"}>
      <div className="w-[95%] mx-auto mt-6">
        <p className="font-semi-bold px-3 pb-5">
          Item Detail Sales Report - Set Report Parameters
        </p>
        <div className="flex items-center justify-between p-4 px-8 h-[50px] rounded-t-lg bg-[#C96FCC]">
          <div className="flex gap-4">
            <span className="text-xl font-semibold text-white"></span>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex items-center justify-end py-3 px-4 bg-white ">
            <div className="flex gap-3 buttongroup ">
              <button
                className="p-1 px-3 rounded-md text-[#C96FCC] bg-[#ffe583] shadow-md hover:shadow-sm"
                type="button"
                onClick={() => {
                  navigate("/dashboard/reports");
                }}
              >
                Cancel
              </button>
              <button
                className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm flex items-center"
                type="submit"
              >
                <span> Run Report </span>
                <Spinner
                  className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                  status={itemSalesDetailReportStatus}
                />
              </button>
            </div>
          </div>
          <div className="bg-app-purple-2 p-3 pt-[30px]">
            <Pdiv label={"Date Range"}>
              <div className="flex items-center">
                <DatePicker
                  placeholderText="Start Date"
                  selected={new Date(startDate)}
                  onChange={(value) =>  setStart(formatDateForApi(new Date(value)))}
                  customInput={
                    <DateTimeInput
                      className="bg-app-purple-8  w-[12rem]"
                      dateFormat={"EEE, MMM dd, yyyy"}
                    />
                  }
                />
                <p className="w-[6rem] text-center"> To:</p>
                <DatePicker
                  placeholderText="End Date"
                  selected={new Date(endDate)}
                  onChange={(value) => setEnd(formatDateForApi(new Date(value)))}
                  customInput={
                    <DateTimeInput
                      className="bg-app-purple-8"
                      dateFormat={"EEE, MMM dd, yyyy"}
                    />
                  }
                />
              </div>
            </Pdiv>
            <InputErr err={errors?.DateTo?.message} />
            <InputErr err={errors?.DateFrom?.message} />
            <Pdiv2 label={"Site"} name={"CinemaId"} {...register("CinemaId")}>
              <option value={""}>Select Cinema</option>

              <option value={profile.cinemaId}>{profile.cinema}</option>
            </Pdiv2>
            <InputErr err={errors?.CinemaId?.message} />
            <Pdiv2 label={"User"} name={"userId"} {...register("userId")}>
              <option value={""}>Select User</option>
              <option value={"allUsers"}>All Users</option>
              {orderedStaff?.map((singleStaff) => {
                return (
                  <option value={singleStaff.id}>
                    {singleStaff.firstName + " " + singleStaff.lastName}
                  </option>
                );
              })}
            </Pdiv2>
            <InputErr err={errors?.userId?.message} />
            <FormGroup className={"mb-[30px]"}>
              <Label className={"w-[23rem] mr-[30px]"}>Select Item:</Label>
              <Controller
                control={control}
                name={"itemId"}
                render={({ field: { value, onChange } }) => {
                  return (
                    <SearchSelect
                      isDefault={true}
                      defaultValue={"allItems"}
                      defaultName={"All Items"}
                      items={items}
                      dropDownNameKey="name"
                      inputClassName="!w-full p-2 px-4 py-2 border-none !bg-app-purple-10"
                      listClassName="!py-2"
                      id={`itemId`}
                      value={getSelectedItem2(items, value, "id")}
                      onChange={(value) => onChange(value?.id ?? "allItems")}
                      displayValue={(value) => {
                        return value?.name ?? "All Items";
                      }}
                      placeholder={`Select Item`}
                      searchOptions={{
                        keys: ["name"],
                      }}
                    />
                  );
                }}
              />
            </FormGroup>
            <InputErr err={errors?.itemId?.message} />
          </div>
        </form>
        <Element name="reportContainer">
          {itemSalesDetailReport?.paramFilter && (
            <>
              <div className="flex justify-center mt-5 space-x-4">
                <PdfButton
                  refValue={reportRef}
                  docTitle={"item-details-sales"}
                />
                <ExportExcelBtn id={"itemdets"} name={"item-details.xlsx"} />
              </div>
              <div ref={reportRef}>
                <ItemDetailSalesTable />
              </div>
              <div className="hidden">
                <ItemDetailSalesTable id={"itemdets"} />
              </div>
            </>
          )}
        </Element>
      </div>
    </PrivateRoute>
  );
};
export { ItemDetailSalesReport };
