import {useMemo, useState} from "react";
import {useTable} from "react-table";
import debounce from "lodash/debounce";
import {formatNumberAsCurrency} from "../../../../utils";

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateData,
  useCase,
}) => {
  const [value, setValue] = useState(+initialValue === 0 ? "" : initialValue);

  const onChange = (e) => {
    setValue(+e.target.value);
  };

  const onKeyUp = debounce(() => {
    updateData((prevState) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        [id]: value,
      };

      return newState;
    });
  }, 700);

  if (
    (useCase === "purchaseOrder" && (id === "unitPrice" || id === "tax")) ||
    (useCase === "stockReceipt" && id === "quantity")
  ) {
    return (
      <input
        className="px-2 py-1 w-[100px]"
        value={value}
        type="number"
        min="0.1"
        onChange={onChange}
        onKeyUp={onKeyUp}
      />
    );
  }
  return <p>{initialValue}</p>;
};

const POItemsTable = ({ items = [], setItems, useCase = "purchaseOrder" }) => {
  const columns = useMemo(() => {
    return [
      {
        Header: "Item",
        accessor: "item",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "UoM",
        accessor: "unitOfMeasurement",
      },
      {
        Header: "QUoM",
        accessor: "quantityUOM",
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        Cell: EditableCell,
      },
      {
        Header: "Unit Price",
        accessor: "unitPrice",
        Cell: EditableCell,
      },
      {
        Header: "Total",
        accessor: "total",
        Cell: ({ value, row: { original } }) => {
          const sub = original.unitPrice * original.quantity;
          return formatNumberAsCurrency(sub);
        },
      },
    ];
  }, []);

  const tableInstance = useTable({
    columns,
    data: items,
    updateData: setItems,
    useCase: useCase,
  });

  const { headerGroups, getTableProps, getTableBodyProps, rows, prepareRow } =
    tableInstance;

  return (
    <table {...getTableProps()} className="w-full overflow-x-scroll">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                className="shadow-lg p-4 border border-[#7E208080] bg-white text-left"
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);

          return (
            <tr
              {...row.getRowProps()}
              className="transition duration-300 bg-app-purple-1 hover:bg-gray-100"
            >
              {row.cells.map((cell) => (
                <td
                  {...cell.getCellProps()}
                  className="p-4 border border-[#7E208080] bg-"
                >
                  {cell.render("Cell")}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
export default POItemsTable;
