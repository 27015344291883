import { API_URL, axiosApiInstance, FETCH_STATUS } from "../../utils";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getAllDistributor = createAsyncThunk(
  "getAllDistributor",
  async (force, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Distributor/ListAll`,
        force
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const initialState = {
  distributors: [],
  distributorStatus: FETCH_STATUS.IDLE,
  distributorErr: null,
};

export const distributorSlice = createSlice({
  name: "distributor",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllDistributor.pending, (state) => {
        state.distributorStatus = FETCH_STATUS.PENDING;
        state.distributorErr = "";
      })
      .addCase(getAllDistributor.fulfilled, (state, action) => {
        state.distributorStatus = FETCH_STATUS.RESOLVED;
        state.distributors = action.payload;
        state.dsitributorErr = "";
      })
      .addCase(getAllDistributor.rejected, (state, action) => {
        state.distributorStatus = FETCH_STATUS.REJECTED;
        state.distributorErr = action.payload || "Unable to get distributors";
      });
  },
});

export const distributorReducer = distributorSlice.reducer;
export const selectDistributor = (state) => state.contract;
