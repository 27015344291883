import React, {useState} from "react";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import axios from "axios";

import {yupResolver} from "@hookform/resolvers/yup";

import {FormError, ScrollToTop, Spinner, WithAuth} from "../../components";
import {
    API_URL,
    currencyKey,
    loggedInCinemaName,
    loginInit,
    loginSchema,
    setTokenService,
    timezoneKey,
} from "../../utils";

import {useAuth, useRequest} from "../../hooks";

import {useDispatch} from "react-redux";
import {getLoggedInUserById, getSubscriptionStatus, setTokens,} from "../../redux/slices";

import logo from "../../assets/images/logo.png";

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const { setPending, setRejected, err, status } = useRequest();

  const { valid, loading } = useAuth("/dashboard", false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(loginSchema),
    defaultValues: loginInit,
  });

  const onSubmit = async (values) => {
    setPending();

    try {
      const response = await axios.post(
        `${API_URL}/Authentication/LoginWithPassword`,
        values
      );

      const { token, refreshToken, userId, expiresAt, currency, cinema, circuitId, timeZone } =
        response?.data;
      const path = location?.search
        ? location?.search.replace("?redirectTo=", "")
        : "/dashboard";
      localStorage.setItem(currencyKey, currency);
      localStorage.setItem(timezoneKey, timeZone);
      localStorage.setItem(loggedInCinemaName,cinema)
      setTokenService(refreshToken, token, expiresAt);
      dispatch(setTokens({ refreshToken, token, userId }));
      dispatch(getLoggedInUserById(userId));
      dispatch(getSubscriptionStatus({ force: true, circuitId }));

      navigate(path, {
        replace: true,
      });
    } catch (error) {
      let errMsgs = "Unable to login";
      const err = error?.response?.data;

      if (typeof err === "string") {
        errMsgs = err;
      }

      if (err?.errors?.length && typeof err !== "string") {
        errMsgs = err?.errors[0]?.errorMessages.join(" ");
      }

      setRejected(errMsgs);
    }
  };

  if (valid) {
    return <Navigate replace to="/dashboard" />;
  }

  if (loading) {
    return <></>;
  }

  return (
    <>
      <FormError
        className="fixed z-20 transform top-2 left-2/4 -translate-x-2/4"
        err={err}
      />

      <WithAuth>
        <ScrollToTop>
          <div>
            <img className="w-auto h-32 mx-auto" src={logo} alt="Reach Logo" />
            <h2 className="my-10 text-xl font-medium text-center text-gray-900">
              Login to Admin Office
            </h2>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="mt-8 space-y-6">
            <div className="space-y-4 rounded-md">
              <div className="relative pb-5">
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  type="email"
                  {...register("userName")}
                  className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-app-purple-4 focus:border-app-purple-4 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                />

                <span className="absolute bottom-0 left-0 flex items-center mt-1 ml-1 text-xs italic font-medium tracking-wide text-red-500">
                  {errors?.userName?.message}
                </span>
              </div>

              <div className="relative pb-5">
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  type={show ? "text" : "password"}
                  {...register("password")}
                  className="relative block w-full px-3 py-2 pr-12 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-app-purple-4 focus:border-app-purple-4 focus:z-10 sm:text-sm"
                  placeholder="Password"
                />
                <button
                  className="absolute top-0 z-10 flex items-center justify-center w-5 h-5 mt-5 transform right-3 -translate-y-2/4 "
                  type="button"
                  onClick={() => setShow((s) => !s)}
                >
                  {show ? (
                    <svg
                      className="text-app-purple-4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                      width="100%"
                      height="100%"
                    >
                      <path
                        fill="currentColor"
                        d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
                      ></path>
                    </svg>
                  ) : (
                    <svg
                      className="text-app-purple-4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                      width="100%"
                      height="100%"
                    >
                      <path
                        fill="currentColor"
                        d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"
                      ></path>
                    </svg>
                  )}
                </button>

                <span className="absolute bottom-0 left-0 flex items-center mt-1 ml-1 text-xs italic font-medium tracking-wide text-red-500">
                  {errors?.password?.message}
                </span>
              </div>
              {/* <Link to={`/reset-password`} className="text-app-purple-4">
                forgot password?
              </Link> */}
            </div>

            <div className="flex items-center justify-center">
              <button
                type="submit"
                className="relative min-w-[150px] flex justify-center items-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-app-purple-4"
              >
                Sign in
                <Spinner
                  className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                  status={status}
                />
              </button>
            </div>
          </form>
        </ScrollToTop>
      </WithAuth>
    </>
  );
};

export { Login };
