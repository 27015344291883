import {ExportExcelBtn, InputErr, OnlineOrdersTable, PdfButton, PrivateRoute, Spinner,} from "../../../components";
import {useNavigate} from "react-router-dom";
import DatePicker from "react-datepicker";
import {DateTimeInput} from "../../../components/Films/DateTimeInput";
import {getOnlineOrders, getTicketSoldData, reportData,} from "../../../redux/slices";
import {FETCH_STATUS, formatDateForApi, onlineOrdersSchema} from "../../../utils";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Element, scroller} from "react-scroll";
import {useDispatch, useSelector} from "react-redux";
import {useLoggedInuser} from "../../../hooks";
import {FormGroup, Label} from "../../../components/FilmContract/FormStyle";
import { useRef } from "react";

const OnlineOrders = () => {
  const navigate = useNavigate();
  const { onlineOrders, onlineOrdersStatus } = useSelector(reportData);
  const dispatch = useDispatch();
  const { profile } = useLoggedInuser();
   const reportRef = useRef(null);

  const {
    control,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(onlineOrdersSchema),
    defaultValues: {
      dateFrom: new Date().toISOString(),
      dateTo: new Date().toISOString(),
    },
  });

  const formData = getValues();
  const onSubmit = (data) => {
    dispatch(
      getTicketSoldData({
        force: true,
        dateFrom: formatDateForApi(new Date(data.dateFrom)),
        dateTo: formatDateForApi(new Date(data.dateTo)),
        cinemaId: profile.cinemaId,
      })
    );
    dispatch(
      getOnlineOrders({
        dateFrom: formatDateForApi(new Date(data.dateFrom)),
        dateTo: formatDateForApi(new Date(data.dateTo)),
        cinemaId: profile.cinemaId,
        force: true,
      })
    ).then(() => {
      scroller.scrollTo("reportContainer", {
        duration: 1500,
        delay: 0,
        smooth: true,
        offset: -100, // Scrolls to element + 50 pixels down the page
      });
    });
  };

  return (
    <PrivateRoute redirectTo={"/dashboard/reports/online-orders"}>
      <div className="w-[95%] mx-auto mt-6">
        <p className="font-semi-bold px-3 pb-5">Online Orders</p>
        <div className="flex items-center justify-between p-4 px-8 h-[50px] rounded-t-lg bg-[#C96FCC]">
          <div className="flex gap-4">
            <span className="text-xl font-semibold text-white"></span>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex items-center justify-end py-3 px-4 bg-white ">
            <div className="flex gap-3 buttongroup ">
              <button
                className="p-1 px-3 rounded-md text-[#C96FCC] bg-[#ffe583] shadow-md hover:shadow-sm"
                type="button"
                onClick={() => {
                  navigate("/dashboard/reports");
                }}
              >
                Cancel
              </button>
              <button
                className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm flex items-center"
                type="submit"
              >
                <span> Run Report </span>
                <Spinner
                  className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                  status={onlineOrdersStatus}
                />
              </button>
            </div>
          </div>
          <div className="bg-app-purple-2 p-3 pt-[30px]">
            <div className="flex justify-center">
              <FormGroup>
                <Label className="">Start Date:</Label>
                <Controller
                  control={control}
                  name="dateFrom"
                  render={({ field: { value, onChange } }) => (
                    <div className="relative ">
                      <DatePicker
                        placeholderText="Start Date"
                        selected={new Date(value)}
                        onChange={(value) => onChange(value.toISOString())}
                        customInput={
                          <DateTimeInput
                            className="bg-app-purple-8"
                            dateFormat={"EEE, MMM dd, yyyy"}
                          />
                        }
                      />
                    </div>
                  )}
                />
                <InputErr err={errors?.dateFrom?.message} />
              </FormGroup>
              <FormGroup>
                <Label className="">End Date:</Label>
                <Controller
                  control={control}
                  name="dateTo"
                  render={({ field: { value, onChange } }) => (
                    <div className="relative ">
                      <DatePicker
                        placeholderText="End Date"
                        selected={new Date(value)}
                        onChange={(value) => onChange(value.toISOString())}
                        customInput={
                          <DateTimeInput
                            className="bg-app-purple-8 w-"
                            dateFormat={"EEE, MMM dd, yyyy"}
                          />
                        }
                      />
                    </div>
                  )}
                />
                <InputErr err={errors?.dateTo?.message} />
              </FormGroup>
            </div>
          </div>
        </form>

        <Element name="reportContainer">
          {onlineOrders?.pagedList?.length > 0 &&
            onlineOrdersStatus !== FETCH_STATUS.REJECTED && (
              <>
                <div className="flex justify-center gap-4 mt-5">
                  <PdfButton
                    refValue={reportRef}
                    docTitle={"online-orders-report"}
                  />
                  <ExportExcelBtn
                    id={"online-orders"}
                    name={"online-orders.xlsx"}
                  />
                </div>
                {/* <div className="hidden">
                  <div id={"fullReport"}>
                    <TransactionsByUserTable2 selectedDate={selectedDate} />
                  </div>
                </div> */}
                <div className="overflow-x-auto">
                  <OnlineOrdersTable
                    funcProps={{
                      ...formData,
                      force: true,
                      cinemaId: profile.cinemaId,
                    }}
                    id={"online-orders"}
                    reportRef={reportRef}
                  />
                </div>
              </>
            )}
          {onlineOrdersStatus === FETCH_STATUS.REJECTED && (
            <p className="text-center text-red-500 py-3">
              Seems an error occured, Kindly refresh and try again
            </p>
          )}
          {onlineOrdersStatus === FETCH_STATUS.RESOLVED &&
            Object.keys(onlineOrders).length < 1 && (
              <p className="text-center py-3">No data found</p>
            )}
        </Element>
      </div>
    </PrivateRoute>
  );
};
export { OnlineOrders };
