import axios from "axios";
import { appCache } from "./cache";
import { API_URL } from "./constants";
import { setTokenService } from "./tokenService";

const axiosApiInstance = axios.create();

const AxiosInstance = {
  setup: (store) => {
    const refreshAccessToken = async () => {
      let resp = {
        refreshToken: "",
        token: "",
        expiresAt: "",
        userId: "",
      };

      const currentToken = await store?.getState()?.auth?.token;
      const currentRefreshToken = await store?.getState()?.auth?.refreshToken;

      try {
        const response = await axiosApiInstance.post(
          `${API_URL}/Authentication/GenerateRefreshToken`,
          {
            currentJWT: currentToken,
            refreshToken: currentRefreshToken,
          }
        );

        resp = { ...resp, ...response?.data };
      } catch (error) {
        console.error(error);
      }

      return resp;
    };

    const setTokens = (refreshToken, token, userId) => {
      return {
        type: "auth/setTokens",
        payload: {
          refreshToken,
          token,
          userId,
        },
      };
    };

    //send token with request
    //return error if available
    axiosApiInstance.interceptors.request.use(
      async (config) => {
        const token = await store?.getState()?.auth?.token;
        config.headers = {
          Authorization: `Bearer ${token}`,
        };
        //console.log("00-1");

        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    //if invalid token, try to get a refresh and send again
    //also set in cache on success for get requests
    //return error if failed
    axiosApiInstance.interceptors.response.use(
      (response) => {
        if (response.config.method.toLowerCase() === "get") {
          appCache.addItem([response.config.url], response.data);
        }

        return response;
      },
      async function (error) {
        const originalRequest = error.config;

        if (
          (error.response.status === 403 || error.response.status === 401) &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true;
          const { token, refreshToken, userId, expiresAt } =
            await refreshAccessToken();

          setTokenService(refreshToken, token, expiresAt);

          store?.dispatch(setTokens(refreshToken, token, userId));

          axios.defaults.headers.common["Authorization"] = "Bearer " + token;

          return axiosApiInstance(originalRequest);
        }

        return Promise.reject(error);
      }
    );
  },
};

const axiosApiInstanceGet = async (url, force) => {
  if (force || !appCache.getItem(url)) {
    return await axiosApiInstance.get(url);
  }

  return { data: appCache.getItem(url) };
};

export { AxiosInstance, axiosApiInstance, axiosApiInstanceGet };
