import React from "react";

const Items = ({ fill = process.env.REACT_APP_COLOR_10 }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3335 3.3335H6.00016C4.5274 3.3335 3.3335 4.5274 3.3335 6.00016V11.3335C3.3335 12.8063 4.5274 14.0002 6.00016 14.0002H11.3335C12.8063 14.0002 14.0002 12.8063 14.0002 11.3335V6.00016C14.0002 4.5274 12.8063 3.3335 11.3335 3.3335Z"
        stroke={fill}
        strokeWidth="3"
      />
      <path
        d="M26 3.3335H20.6667C19.1939 3.3335 18 4.5274 18 6.00016V11.3335C18 12.8063 19.1939 14.0002 20.6667 14.0002H26C27.4728 14.0002 28.6667 12.8063 28.6667 11.3335V6.00016C28.6667 4.5274 27.4728 3.3335 26 3.3335Z"
        stroke={fill}
        strokeWidth="3"
      />
      <path
        d="M11.3335 18H6.00016C4.5274 18 3.3335 19.1939 3.3335 20.6667V26C3.3335 27.4728 4.5274 28.6667 6.00016 28.6667H11.3335C12.8063 28.6667 14.0002 27.4728 14.0002 26V20.6667C14.0002 19.1939 12.8063 18 11.3335 18Z"
        stroke={fill}
        strokeWidth="3"
      />
      <path
        d="M26 18H20.6667C19.1939 18 18 19.1939 18 20.6667V26C18 27.4728 19.1939 28.6667 20.6667 28.6667H26C27.4728 28.6667 28.6667 27.4728 28.6667 26V20.6667C28.6667 19.1939 27.4728 18 26 18Z"
        stroke={fill}
        strokeWidth="3"
      />
    </svg>
  );
};

export { Items };
