import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import ConcessionComponent from "./ConcessionComponent";
import { useMemo } from "react";

const areas = {
  0: "Cinema",
  1: "Fast Food",
  2: "Cafe",
  3: "Arcade",
};
const areaColor = {
  0: "bg-red-500",
  1: "bg-green-500",
  2: "bg-blue-500",
  3: "bg-yellow-500",
};

const POSLayoutPreview = ({ show, onClose, itemsById, formValues }) => {
  const [serviceArea, setServiceArea] = useState(0);

  const pageLayout = useMemo(() => {
    return formValues.posLayout.reduce((acc, curr) => {
      acc[Number(curr.serviceArea)] = curr.pages;
      return acc;
    }, {});
  }, [formValues.posLayout]);

  
  return (
    <div>
      <Transition appear show={show} as={Fragment}>
        <Dialog as="div" className="relative z-[10000000]" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-75"
            leave="ease-in duration-75"
            leaveFrom="opacity-75"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-5xl max-h-max overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="div"
                    className="text-lg font-medium flex justify-between relative bg-gradient-to-b from-app-purple-4 to-app-purple-3 p-3.5 "
                  >
                    <p className="text-lg font-medium leading-6 text-white">
                      P.O.S Layout Preview
                    </p>
                    <button
                      type="button"
                      onClick={onClose}
                      className="absolute right-2 top-2/4 -translate-y-2/4 text-white bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    >
                      <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </Dialog.Title>
                  <>
                    <div className="p-2">
                      <ConcessionComponent
                        pages={pageLayout[serviceArea]}
                        pageLayout={pageLayout}
                        serviceArea={serviceArea}
                        itemsById={itemsById}
                      />
                      <div className="grid grid-cols-4 gap-2">
                        {formValues.posLayout.map((val) => {
                          return (
                            <>
                              <button
                                onClick={() =>
                                  setServiceArea(Number(val.serviceArea))
                                }
                                key={val.serviceArea}
                                className={`px-3 py-2 ${
                                  serviceArea === Number(val.serviceArea)
                                    ? "border-2 border-black"
                                    : ""
                                } ${areaColor[Number(val.serviceArea)]}`}
                              >
                                {areas[Number(val.serviceArea)]}
                              </button>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default POSLayoutPreview;
