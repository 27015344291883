import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import {
  createStockTakeSchema,
  stockTakeInit,
  FETCH_STATUS,
  API_URL,
  axiosApiInstanceGet,
} from '../../utils';
import { useSelector, useDispatch } from 'react-redux';
import { getLocations, selectLocations } from '../../redux/slices';
import { InlineErr, Spinner } from '../UI';
import DatePicker from 'react-datepicker';
import { DateTimeInput } from '../Films/DateTimeInput';
import StockItemTable from './StockItemTable';
import { useRequest } from '../../hooks';

const StockTakeForm = ({ stockTake, onSubmit }) => {
  const {
    register,
    reset,
    setValue,
    watch,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm({
    resolver: yupResolver(createStockTakeSchema),
    defaultValues: stockTakeInit,
  });

  const stockItemFormValues = watch('items');

  const { err, status, setResolved, setPending, setRejected, resetErr } =
    useRequest();

  const { locations, locationsStatus, locationsErr } =
    useSelector(selectLocations);

  const dispatch = useDispatch();

  const onFormSubmit = (values) => {};

  const handleSelectLocation = async (locationId) => {
    setValue('storeLocationId', locationId, { shouldValidate: true });
    try {
      setPending();

      const res = await axiosApiInstanceGet(
        `${API_URL}/ItemStocks/ListAll?storeLocationId=${locationId}`
      );

      if (res.data?.data) {
        setValue(
          'items',
          res.data.data.map((stock) => {
            const found = stockTake?.items.find(
              (item) => item.itemStoreId === stock.id
            );
            if (found) {
              return {
                itemStoreId: stock.id,
                unitOfMeasurementId: stock.unitOfMeasurementId,
                unitOfMeasure: stock.unitOfMeasurement,
                quantityOnHand: stock.availableQuantity,
                stockTakeQuantity: found.stockTakeQuantity,
                variance: found.variance,
                name: stock.item,
              };
            }
            return {
              itemStoreId: stock.id,
              unitOfMeasurementId: stock.unitOfMeasurementId,
              unitOfMeasure: stock.unitOfMeasurement,
              quantityOnHand: stock.availableQuantity,
              stockTakeQuantity: 0,
              variance: 0,
              name: stock.item,
            };
          })
        );
      }

      setResolved('Success');
    } catch (e) {
      setRejected(e || 'Unable to create location');
    }
  };

  const updateItemCount = (id, quantity) => {
    const oldItems = getValues().items;
    const newItems = oldItems.map((item) => {
      if (item.itemStoreId === id) {
        return {
          ...item,
          stockTakeQuantity: quantity,
          variance: quantity - Number(item.quantityOnHand),
        };
      } else {
        return item;
      }
    });
    setValue('items', newItems);
  };

  useEffect(() => {
    if (stockTake) {
      reset({
        reference: stockTake.reference,
        expenseAccount: stockTake.expenseAccount,
        cinemaId: stockTake.cinemaId,
        effectiveDate: stockTake.effectiveDate,
      });
      handleSelectLocation(stockTake.storeLocationId);
    }
  }, [stockTake]);

  useEffect(() => {
    dispatch(getLocations());
  }, []);

  return (
    <>
      <div className="my-4 mx-2.5 rounded-xl bg-white">
        <form className="px-4 py-5" onSubmit={handleSubmit(onSubmit)}>
          <section className="mb-20 grid grid-cols-1 md:gris-cols-2 lg:grid-cols-3 xl:grid-cols-4 items-center gap-5">
            <div className="flex flex-col gap-1">
              <label className="text-[#767579] text-sm">Expense Account</label>
              <div className="relative">
                <input
                  className="w-full p-2 px-4 py-2 border-none rounded-md bg-[#AA7DAA26]"
                  id="expenseAccount"
                  type="text"
                  {...register('expenseAccount')}
                  readOnly={stockTake && stockTake.status !== 'Pending'}
                />
                <InlineErr err={errors?.expenseAccount?.message} />
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-[#767579] text-sm">Effective Date</label>
              <div className="relative">
                <DatePicker
                  placeholderText="Effective Date"
                  selected={
                    getValues().effectiveDate
                      ? new Date(getValues().effectiveDate)
                      : null
                  }
                  disabled={stockTake && stockTake.status !== 'Pending'}
                  onChange={(value) => {
                    value &&
                      setValue('effectiveDate', value.toISOString(), {
                        shouldValidate: true,
                      });
                  }}
                  customInput={
                    <DateTimeInput
                      className="bg-app-purple-8"
                      dateFormat={'EEE, MMM dd, yyyy'}
                      value={getValues().effectiveDate}
                    />
                  }
                />
                <InlineErr err={errors?.effectiveDate?.message} />
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-[#767579] text-sm">Select Location</label>
              <div className="relative">
                <select
                  className="rounded bg-[#d0a3d3] border-0 text-white w-full"
                  onChange={(event) => handleSelectLocation(event.target.value)}
                  disabled={
                    locationsStatus === FETCH_STATUS.PENDING ||
                    (stockTake && stockTake.status !== 'Pending')
                  }
                  value={stockTake?.storeLocationId || watch('storeLocationId')}
                >
                  <option>
                    {locationsStatus === FETCH_STATUS.PENDING
                      ? 'Fetching locations...'
                      : 'Select a Location'}
                  </option>
                  {locations?.map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.name}
                    </option>
                  ))}
                </select>
                <InlineErr err={errors?.storeLocationId?.message} />
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-[#767579] text-sm" id="reference">
                Reference
              </label>
              <div className="relative">
                <input
                  className="w-full p-2 px-4 py-2 border-none rounded-md bg-[#AA7DAA26]"
                  type="text"
                  {...register('reference')}
                  readOnly={stockTake && stockTake.status !== 'Pending'}
                />
                <InlineErr err={errors?.reference?.message} />
              </div>
            </div>
          </section>

          {stockItemFormValues?.length ? (
            <StockItemTable
              products={stockItemFormValues}
              onNewQuantity={updateItemCount}
              editable={!stockTake || stockTake?.status === 'Pending'}
            />
          ) : null}
          {stockTake && stockTake.status !== 'Pending' ? null : (
            <div className="flex justify-end col-span-2">
              <button
                type="submit"
                className="flex justify-center py-[12px] px-[36px] rounded-[14px] bg-gradient-to-b from-[#764877] to-[#A86AAA] text-[#fff] w-auto"
              >
                <span>Submit</span>
              </button>
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export default StockTakeForm;
