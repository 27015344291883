import React, { useCallback, useEffect } from "react";
import {
  ErrorWithWrapper,
  Loading,
  PageHeader,
  PrivateRoute,
  ShiftTable,
} from "../../../components";
import BreadCrumb from "../../../components/BreadCrumbs/BreadCrumbs";
import { NavLink } from "react-router-dom";
import shifts from "../../../utils/mockData/shift.json";
import { getShifts, selectRoster } from "../../../redux/slices";
import { useDispatch, useSelector } from "react-redux";
import { FETCH_STATUS } from "../../../utils";
import { useLoggedInuser } from "../../../hooks";

const StaffShifts = () => {
  const dispatch = useDispatch();
  const {profile} = useLoggedInuser()
  const { shifts, getShiftsStatus, getShiftsErr } = useSelector(selectRoster);
  
  
  const getInfo = useCallback(() => {
    dispatch(getShifts({circuitId:profile?.circuitId}));
  }, [dispatch, profile?.circuitId]);

  useEffect(() => {
    // if (getShiftsStatus === FETCH_STATUS.IDLE ) {
    //   dispatch(getShifts(force));
    // }
    getInfo();
  }, [getInfo]);

  return (
    <>
      <PrivateRoute redirectTo="/dashboard/users/shifts">
        <div className="flex justify-end mb-4">
          <BreadCrumb />
        </div>
        <PageHeader>
          <h1 className=" text-white font-semibold text-2xl">Shifts</h1>
          <div className="flex justify-end space-x-4">
            <NavLink
              to="/dashboard/users/create-shift"
              type="button"
              className="px-4 py-3 text-base font-medium bg-white rounded-md 
          text-app-purple-9"
            >
              Create Shift
            </NavLink>
          </div>
        </PageHeader>
        <ErrorWithWrapper
          error={getShiftsErr}
          show={getShiftsStatus === FETCH_STATUS.REJECTED}
          retry={getInfo}
        />

        <div className="relative p-[19px] pb-[30px] bg-[#DDC4DD] min-h-[699px] rounded-b-lg">
          <Loading
            wrapperClassName="absolute top-0 left-0 right-0 min-h-[50vh]"
            show={getShiftsStatus === FETCH_STATUS.PENDING}
          />

          {getShiftsStatus === FETCH_STATUS.RESOLVED ?
          <div className="w-full h-full bg-white rounded-[12px] py-[28px] px-[30px]">
            <ShiftTable shifts={shifts?.pagedList??[]} getInfo={getInfo} />
          </div>:null
          }
        </div>
      </PrivateRoute>
    </>
  );
};

export { StaffShifts };
