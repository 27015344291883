import React, {useRef} from "react";
import DatePicker from "react-datepicker";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import {DateTimeInput} from "../../../components/Films/DateTimeInput";
import {Pdiv, PrivateRoute, RefundReportTable, Spinner, Success,} from "../../../components";
import {getRefundReport, selectRefundReports,} from "../../../redux/slices";
import {Element, scroller} from "react-scroll";
import {FETCH_STATUS, formatDateForApi} from "../../../utils";

const RefundReports = () => {
  const { refundReportsStatus, refundReports } =
        useSelector(selectRefundReports);
    
   

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reportRef = useRef(null);
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      dateFrom: new Date().toISOString(),
      dateTo: new Date().toISOString(),
    },
  });

  const handleRefundReport = async (values) => {
    try {
      await dispatch(getRefundReport(values)).then((data) => {
        scroller.scrollTo("reportContainer", {
          duration: 1500,
          delay: 0,
          smooth: true,
          offset: -100, // Scrolls to element + 50 pixels down the page
        });
      });
    } catch (error) {}
  };
 
  return (
    <>
      <PrivateRoute redirectTo={"/dashboard/reports/refund-report"}>
        <div className="w-[95%] mx-auto mt-6" id={"parentContainer"}>
          <p className="font-semi-bold px-3 pb-5">
            Refunds Report - Set Report Parameters
          </p>
          <div className="flex items-center justify-between p-4 px-8 h-[50px] rounded-t-lg bg-[#C96FCC]">
            <div className="flex gap-4">
              <span className="text-xl font-semibold text-white"></span>
            </div>
          </div>
          <form onSubmit={handleSubmit(handleRefundReport)}>
            <div className="flex items-center justify-end py-3 px-4 bg-white ">
              <div className="flex gap-3 buttongroup ">
                <button
                  className="p-1 px-3 rounded-md text-[#C96FCC] bg-[#ffe583] shadow-md hover:shadow-sm"
                  type="button"
                  onClick={() => {
                    navigate("/dashboard/reports");
                  }}
                >
                  Cancel
                </button>
                <button
                  className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm flex items-center"
                  type="submit"
                >
                  <span> Run Report </span>
                  <Spinner
                    className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                    status={refundReportsStatus}
                  />
                </button>
              </div>
            </div>
            <div className="bg-app-purple-2 p-3 pt-[30px]">
              <Pdiv label={"Date Range"}>
                <div className="flex items-center">
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="dateFrom"
                    render={({ field: { value, onChange } }) => (
                      <div className="relative ">
                        <DatePicker
                          placeholderText="Start Date"
                          selected={new Date(value)}
                          onChange={(value) =>
                              onChange(formatDateForApi(new Date(value)))
                          }
                          customInput={
                            <DateTimeInput
                              className="bg-app-purple-8"
                              dateFormat={"EEE, MMM dd, yyyy"}
                            />
                          }
                        />
                      </div>
                    )}
                  />

                  <p className="w-[6rem] text-center"> To:</p>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="dateTo"
                    render={({ field: { value, onChange } }) => (
                      <div className="relative ">
                        <DatePicker
                          placeholderText="End Date"
                          selected={new Date(value)}
                          onChange={(value) =>
                              onChange(formatDateForApi(new Date(value)))
                          }
                          customInput={
                            <DateTimeInput
                              className="bg-app-purple-8"
                              dateFormat={"EEE, MMM dd, yyyy"}
                            />
                          }
                        />
                      </div>
                    )}
                  />
                </div>
              </Pdiv>
            </div>
          </form>
        </div>
        <Success show={refundReportsStatus === FETCH_STATUS.RESOLVED}>
          <Element name="reportContainer">
            {refundReports?.pagedList?.length? (
              <div className="mt-10">
              
                <div ref={reportRef}>
                  <RefundReportTable
                    id={"refundReports"}
                    refundReport={refundReports?.pagedList}
                    
                  />
                </div>
              </div>
            ):null}
            {refundReports?.pagedList?.length < 1 && (
              <p className="text-red-700 flex justify-center mt-3">
                No Data Found
              </p>
            )}
          </Element>
        </Success>
      </PrivateRoute>
    </>
  );
};

export { RefundReports };
