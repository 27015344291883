import { yupResolver } from "@hookform/resolvers/yup";
import React, { useCallback, useEffect, useMemo } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  FETCH_STATUS,
  getAPIErr,
  getSelectedItem2,
  kioskLayoutInit,
  kioskLayoutSchema,
} from "../../utils";
import { useState } from "react";
import { KioskPageContent } from "./KioskPageContent";
import {
  useDataMutateWithKey,
  useKioskLayout,
  useLoggedInuser,
  useRequest,
  useToggle,
} from "../../hooks";
import {
  ErrorModal,
  Loading,
  SearchSelect,
  Spinner,
  SuccessModal,
} from "../UI";
import { Delete } from "../Icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getItems,
  getKioskItems,
  getKioskLayout,
  resetGetKioskLayout,
  resetUpdateKioskLayout,
  resetUpdateLayout,
  selectItems,
  selectLayout,
  updateKioskLayout,
} from "../../redux/slices";
import { KioskLayoutPreview } from "./KioskLayoutPreview";

const KioskLayoutForm = () => {
  const [selectedPage, setSelectedPage] = useState({ currentPage: 0 });
  const { err, setRejected, setPending, setResolved, resetErr } = useRequest();

  const [preview, setPreview] = useToggle(false);
  const dispatch = useDispatch();
  const { kioskItems, getKioskItemsStatus } = useSelector(selectItems);

  const {
    allKioskLayout,
    getKioskLayoutStatus,
    getKioskLayoutErr,
    updateKioskLayoutStatus,
    updateKioskLayoutErr,
  } = useSelector(selectLayout);

  const { profile } = useLoggedInuser();
  const { mutatedData: itemsMutateWithId } = useDataMutateWithKey(
    kioskItems,
    "id"
  );
 
  const {
    register,
    control,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(kioskLayoutSchema),
    defaultValues: kioskLayoutInit,
  });

  const getItemDropdown = useCallback(() => {
    dispatch(getKioskItems(profile?.cinemaId));
  }, [dispatch, profile?.cinemaId]);

  const getLayout = useCallback(async () => {
    try {
      
      dispatch(getKioskLayout(true))
        .unwrap()
        .then((layout) => {
          reset({
            kioskLayout: layout.kioskPages,
          });
        });
      
    } catch (error) {
    
    }
  }, [dispatch, reset]);

  useEffect(() => {
    getLayout();
  }, [getLayout]);

  useEffect(() => {
    getItemDropdown();
  }, [getItemDropdown]);

  const kioskFormValues = getValues();
  const kioskLayout = watch("kioskLayout");
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "kioskLayout",
  });
  const { removePage, addItem, removeItem, itemsWithKioskSource } =
    useKioskLayout(update, kioskLayout, remove, kioskItems);

  const handleKioskLayoutSubmit = async (values) => {
    try {
      if (values.kioskLayout.length > 10) {
        throw new Error(
          "Numbers of pages or items exceeded(should be 10 and 15 repectively)"
        );
      } else {
        values.kioskLayout.forEach((layout) => {
          if (layout.pageItems.length > 15) {
            throw new Error("Number of items exceeded(should be 15 max)");
          }
        });
      }
      setPending();
      await dispatch(
        updateKioskLayout({ kioskPages: values.kioskLayout })
      ).unwrap();
      setResolved();
      getLayout();
    } catch (error) {
      setRejected(error.message || error);
    }
  };

  return (
    <>
      <div className="w-[95%] mx-auto mt-6">
        <div className="flex items-center justify-between p-4 px-8 rounded-t-lg bg-gradient-to-b from-app-purple-4 to-app-purple-3">
          <div className="flex gap-4">
            <span className="text-xl font-semibold text-white">
              Kiosk Layout
            </span>
          </div>
        </div>
        <form onSubmit={handleSubmit(handleKioskLayoutSubmit)}>
          <div className="flex items-center justify-end px-4 py-3 bg-white ">
            <div className="flex gap-3 buttongroup ">
              <button
                disabled={!isValid}
                className="p-1 px-3 disabled:cursor-not-allowed rounded-md text-[#C96FCC] bg-[#ffe583] shadow-md hover:shadow-sm"
                type="button"
                onClick={setPreview}
              >
                Preview
              </button>
              <button
                className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm inline-flex items-center justify-center space-x-2"
                type="submit"
              >
                <span>Save</span>
                <Spinner
                  className="!w-3 !h-3 text-white fill-app-yellow"
                  status={updateKioskLayoutStatus}
                />
              </button>
            </div>
          </div>
          <div className="relative p-3 bg-app-purple-2">
            <Loading
              wrapperClassName="absolute top-0 left-0 right-0 min-h-[50vh]"
              show={
                updateKioskLayoutStatus === FETCH_STATUS.PENDING ||
                getKioskLayoutStatus === FETCH_STATUS.PENDING
              }
            />
            <button
              className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm"
              type="button"
              onClick={() => {
                append({
                  pageName: "",
                  pageItems: [{ itemId: "" }],
                });
              }}
            >
              Add Page
            </button>
            <div className="mt-2">
              {fields.map((field, pageIndex) => {
                return (
                  <>
                    {/* <div key={field.id}>
                      <div className="flex items-center space-x-2"> */}
                    <button
                      key={field.id}
                      className={`p-1 px-3 rounded-md mb-2 shadow-md hover:shadow-sm ml-2 ${
                        selectedPage?.currentPage === pageIndex
                          ? "bg-[#C96FCC] text-[#ffe583]"
                          : "bg-transparent border border-[#C96FCC] text-[#666666]"
                      } `}
                      type="button"
                      onClick={() => {
                        setSelectedPage({ currentPage: pageIndex });
                      }}
                    >
                      {`Page ${pageIndex + 1}`}
                    </button>
                  </>
                );
              })}
            </div>
            {/* test div for content */}
            <div className="mt-2">
              {fields.map((field, pageIndex) => {
                return (
                  <>
                    <KioskPageContent
                      key={field.id}
                      selectedPage={selectedPage}
                      currentPage={field}
                      removePage={removePage}
                      allPages={fields}
                      currentPageIndex={pageIndex}
                      setValue={setValue}
                      getValues={getValues}
                      addItem={addItem}
                      renderChildren={(pageData, pageDataIndex) => {
                        return (
                          <>
                            {pageData.pageItems.map((pageItem, itemIndex) => {
                              
                              return (
                                <div
                                  className=" pb-3.5 "
                                  key={`kioskLayout.${pageIndex}.pageItems.${itemIndex}.itemId`}
                                  // key={pageItem.itemId}
                                >
                                  <label className="block mb-2 text-md font-bold text-[#666666] ">
                                    {`Item ${itemIndex + 1}`}
                                  </label>
                                  <div className="flex gap-2">
                                    <Controller
                                      control={control}
                                      name={`kioskLayout.${pageIndex}.pageItems.${itemIndex}.itemId`}
                                      render={({
                                        field: { value, onChange },
                                      }) => {
                                        return (
                                          <>
                                            <SearchSelect
                                              items={itemsWithKioskSource}
                                              dropDownNameKey="name"
                                              inputClassName="!w-full p-2 px-4 py-2 border-none !bg-app-purple-10"
                                              listClassName="!py-2"
                                              id={`itemId`}
                                              value={getSelectedItem2(
                                                itemsWithKioskSource,

                                                value,

                                                "id"
                                              )}
                                              onChange={(value) =>
                                                onChange(value?.id)
                                              }
                                              displayValue={(value) => {
                                                return value?.name;
                                              }}
                                              placeholder={`Select Items`}
                                              searchOptions={{
                                                keys: ["name"],
                                              }}
                                            />
                                          </>
                                        );
                                      }}
                                    />

                                    <button
                                      type="button"
                                      onClick={() => {
                                        removeItem({
                                          pageIndex,
                                          itemIndex,
                                        });
                                      }}
                                    >
                                      <Delete />
                                    </button>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        );
                      }}
                    />
                  </>
                );
              })}
            </div>
          </div>
        </form>
        {preview && (
          <KioskLayoutPreview
            itemsMutateWithId={itemsMutateWithId}
            show={preview}
            onClose={setPreview}
            formValues={kioskFormValues}
          />
        )}
        <ErrorModal
          show={!!err || !!updateKioskLayoutErr}
          message={
            err?.message
              ? err?.message
              : getAPIErr(err, "Unable to update kiosk layout")
          }
          onClose={resetErr}
          onClick={resetErr}
        />
        <ErrorModal
          show={!!getKioskLayoutErr}
          message={getKioskLayoutErr}
          onClose={() => dispatch(resetGetKioskLayout())}
          onClick={() => dispatch(resetGetKioskLayout())}
        />
        <SuccessModal
          onClick={() => dispatch(resetUpdateKioskLayout())}
          message="KioskLayout Updated successfully"
          show={updateKioskLayoutStatus === FETCH_STATUS.RESOLVED}
        />
      </div>
    </>
  );
};

export default KioskLayoutForm;
