import { yupResolver } from "@hookform/resolvers/yup";
import DatePicker from "react-datepicker";
import { DateTimeInput } from "../../../components/Films/DateTimeInput";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  FETCH_STATUS,
  formatDateForApi,
  GBOFilmReportsInit,
  GBOFilmReportsSchema,
  getSelectedItem2,
} from "../../../utils";
import { useLoggedInuser } from "../../../hooks";
import {
  ExportExcelBtn,
  FormGroup,
  GBOFilmReportsTable,
  InlineErr,
  Label,
  PdfButton,
  Pdiv,
  Pdiv2,
  PrivateRoute,
  SearchSelect,
  Spinner,
  Success,
} from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAllSources,
  getDistributorFilms,
  getDistributors,
  getFilmsByCinema,
  getGBOFilmReports,
  reportData,
  selectFilms,
} from "../../../redux/slices";
import { Element, scroller } from "react-scroll";

const GBOFilmReports = () => {
  const { profile } = useLoggedInuser();
  const {
    GBOFilmReportsErr,
    distributorsList,
    distributorsListStatus,
    distributorFilms,
    sources,
    sourcesStatus,
    GBOFilmReports,
    GBOFilmReportsStatus,
  } = useSelector(reportData);
  const { filmsByCinema, getFilmsByCinemaStatus } = useSelector(selectFilms);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reportRef = useRef(null);

  const {
    control,
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(GBOFilmReportsSchema),
    defaultValues: { ...GBOFilmReportsInit, cinemaId: profile?.cinemaId },
  });

  const selectedDistributor = watch("distributorId");
  const formattedDistributorList = useMemo(() => {
    if (distributorsList?.length > 0) {
      return [
        { name: "All Distributors", id: "allDistributors" },
        ...distributorsList,
      ];
    }
  }, [distributorsList]);
  const formattedFilms = useMemo(() => {
    if (
      distributorFilms?.pagedList?.length > 0 &&
      selectedDistributor !== "allDistributors"
    ) {
      return [
        { name: "All Films", id: "allFilms" },
        ...distributorFilms?.pagedList,
      ];
    }
    if (
      selectedDistributor === "allDistributors" &&
      filmsByCinema?.length > 0
    ) {
      return [
        { name: "All Films", id: "allFilms" },
        ...filmsByCinema,
      ];
    }
  }, [distributorFilms?.pagedList, filmsByCinema, selectedDistributor]);

  const getAllData = useCallback(() => {
    if (sourcesStatus === FETCH_STATUS.IDLE) {
      dispatch(getAllSources());
    }

    if (distributorsListStatus === FETCH_STATUS.IDLE) {
      dispatch(getDistributors());
    }

    if (
      selectedDistributor !== "allDistributors" &&
      selectedDistributor !== ""
    ) {
      dispatch(
        getDistributorFilms({
          id: selectedDistributor,
          force: true,
        })
      );
    } else {
      dispatch(getFilmsByCinema({ force: true, cinemaId: profile?.cinemaId }));
    }
  }, [
    distributorsListStatus,
    profile?.cinemaId,
    selectedDistributor,
    sourcesStatus,
  ]);

  useEffect(() => {
    getAllData();
  }, [getAllData]);

  const onSubmit = async (values) => {
    try {
      await dispatch(getGBOFilmReports(values)).then((data) => {
        scroller.scrollTo("reportContainer", {
          duration: 1500,
          delay: 0,
          smooth: true,
          offset: -100, // Scrolls to element + 50 pixels down the page
        });
      });
    } catch (error) {}
  };

  return (
    <>
      <PrivateRoute redirectTo={"/dashboard/reports/GBOFilmReports"}>
        <div className="w-[95%] mx-auto mt-6">
          <p className="font-semi-bold px-3 pb-5">
            GBO By Films Reports - Set Report Parameters
          </p>
          <div className="flex items-center justify-between p-4 px-8 h-[50px] rounded-t-lg bg-[#C96FCC]">
            <div className="flex gap-4">
              <span className="text-xl font-semibold text-white"></span>
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex items-center justify-end py-3 px-4 bg-white ">
              <div className="flex gap-3 buttongroup ">
                <button
                  className="p-1 px-3 rounded-md text-[#C96FCC] bg-[#ffe583] shadow-md hover:shadow-sm"
                  type="button"
                  onClick={() => {
                    navigate("/dashboard/reports");
                  }}
                >
                  Cancel
                </button>
                <button
                  className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm flex items-center"
                  type="submit"
                >
                  <span> Run Report </span>
                  <Spinner
                    className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                    status={GBOFilmReportsStatus}
                  />
                </button>
              </div>
            </div>
            <div className="bg-app-purple-2 p-3 pt-[30px]">
              <Pdiv label={"Date Range"}>
                <div className="flex items-center">
                  <Controller
                    control={control}
                    name="startDate"
                    render={({ field: { value, onChange } }) => (
                      <div className="relative ">
                        <DatePicker
                          placeholderText="Start Date"
                          selected={new Date(value)}
                          onChange={(value) =>
                            onChange(formatDateForApi(new Date(value)))
                          }
                          customInput={
                            <DateTimeInput
                              className="bg-app-purple-8"
                              dateFormat={"EEE, MMM dd, yyyy"}
                            />
                          }
                        />
                      </div>
                    )}
                  />

                  <p className="w-[6rem] text-center"> To:</p>
                  <Controller
                    control={control}
                    name="endDate"
                    render={({ field: { value, onChange } }) => (
                      <div className="relative ">
                        <DatePicker
                          placeholderText="End Date"
                          selected={new Date(value)}
                          onChange={(value) =>
                            onChange(formatDateForApi(new Date(value)))
                          }
                          customInput={
                            <DateTimeInput
                              className="bg-app-purple-8"
                              dateFormat={"EEE, MMM dd, yyyy"}
                            />
                          }
                        />
                      </div>
                    )}
                  />
                </div>
              </Pdiv>
              <Pdiv2
                label={"Source"}
                name={"sourceId"}
                {...register("sourceId")}
              >
                <option value={""}>Select Source</option>
                <option value={"allSources"}>All Sources</option>
                {sources?.map((source, i) => {
                  return (
                    <option value={source.id} key={i}>
                      {source.name}{" "}
                    </option>
                  );
                })}
              </Pdiv2>
              <Pdiv label={"Include Comp"}>
                <Controller
                  name="includeComp"
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <input
                        checked={value}
                        id="includeComp"
                        type="checkbox"
                        onChange={onChange}
                        className="border border-gray-300 rounded-sm focus:!border-none text-[#C96FCC] !ring-transparent mr-2 items-center w-8 h-8"
                      />
                    );
                  }}
                />
              </Pdiv>
              <FormGroup className={"mb-[20px] relative"}>
                <Label className={"w-[23rem] mr-[30px]"}>
                  Select Distributor:
                </Label>

                <Controller
                  control={control}
                  name={"distributorId"}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <SearchSelect
                        items={formattedDistributorList}
                        inputClassName="!w-full p-2 px-4 py-2 border-none !bg-app-purple-10"
                        listClassName="!py-2"
                        id={`distributorId`}
                        value={getSelectedItem2(
                          formattedDistributorList,
                          value,
                          "id"
                        )}
                        onChange={(value) => onChange(value?.id)}
                        displayValue={(value) => {
                          return value?.name;
                        }}
                        placeholder={`Select Distributor`}
                        searchOptions={{
                          keys: ["name"],
                        }}
                      />
                    );
                  }}
                />
                <InlineErr
                  err={errors?.distributorId?.message}
                  className={"left-[25rem]"}
                />
              </FormGroup>

              <FormGroup className={"mb-[20px] relative "}>
                <Label className={"w-[23rem] mr-[30px]"}>Select Film:</Label>
                <Controller
                  control={control}
                  name={"filmId"}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <SearchSelect
                        items={formattedFilms}
                        dropDownNameKey="name"
                        inputClassName="!w-full p-2 px-4 py-2 border-none !bg-app-purple-10"
                        listClassName="!py-2"
                        id={`filmId`}
                        value={getSelectedItem2(formattedFilms, value)}
                        onChange={(value) => onChange(value?.id)}
                        displayValue={(value) => {
                          return value?.name;
                        }}
                        placeholder={`Select Film`}
                        searchOptions={{
                          keys: ["name"],
                        }}
                      />
                    );
                  }}
                />
                <InlineErr
                  err={errors?.filmId?.message}
                  className={"left-[25rem]"}
                />
              </FormGroup>
            </div>
          </form>
        </div>
        <Success show={GBOFilmReportsStatus === FETCH_STATUS.RESOLVED}>
          <Element name="reportContainer">
            {GBOFilmReports?.result?.reports?.length ? (
              <div>
                <div className="flex justify-center mt-5 space-x-4">
                  <PdfButton
                    refValue={reportRef}
                    docTitle={"gbo-film-report"}
                  />
                  <ExportExcelBtn
                    id={"GBOFilmReports"}
                    name={"GBOFilmReports.xlsx"}
                  />
                </div>
                <div ref={reportRef} className="hidden">
                  <GBOFilmReportsTable
                    id={"GBOFilmReports"}
                    data={GBOFilmReports?.result?.reports}
                    paramFilter={GBOFilmReports?.paramFilter}
                  />
                </div>
                <div ref={reportRef}>
                  <GBOFilmReportsTable
                    // id={"GBOFilmReports"}
                    data={GBOFilmReports?.result?.reports}
                    paramFilter={GBOFilmReports?.paramFilter}
                  />
                </div>
              </div>
            ) : (
              <p className="text-red-700 flex justify-center mt-3">
                No Data Found
              </p>
            )}
          </Element>
        </Success>
        {GBOFilmReportsStatus === FETCH_STATUS.REJECTED && (
          <p className="text-red-700 flex justify-center mt-3">
            Sorry an error occured. Could not fetch report
          </p>
        )}
      </PrivateRoute>
    </>
  );
};

export { GBOFilmReports };
