import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  adjustStock,
  approveStockTake,
  getStockTake,
  rejectStockTake,
  stockData,
  updateStockTake,
} from '../../../../redux/slices';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { FETCH_STATUS, axiosApiInstance, API_URL } from '../../../../utils';
import { Icon } from '@iconify/react';
import {
  PageHeader,
  PrivateRoute,
  ErrorWithWrapper,
  SuccessModal,
  ConfirmModal,
} from '../../../../components';
import { useRequest } from '../../../../hooks';
import StockTakeForm from '../../../../components/Stock/StockTakeForm';

const UpdateStockTake = () => {
  const { stockTake, stockTakeStatus, stockTakeErr } = useSelector(stockData);

  const { err, status, resetErr, setResolved, setPending, setRejected, reset } =
    useRequest();

  const dispatch = useDispatch();

  let params = useParams();

  const [confirmAction, setConfirmAction] = useState();

  const updateStockTake = async (data) => {
    try {
      resetErr();
      setPending();

      const res = await axiosApiInstance.patch(
        `${API_URL}/StocksTake/Update?stockTakeId=${params.id}`,
        data
      );

      setResolved(res);
    } catch (error) {
      setRejected(error || 'Unable to update stock take');
    }
  };

  const approveStockTake = async (data) => {
    try {
      resetErr();
      setPending();

      const res = await axiosApiInstance.patch(
        `${API_URL}/StocksTake/Accept?stockTakeId=${params.id}`,
        data
      );

      setResolved(res);
    } catch (error) {
      setRejected(error || 'Unable to approve stock take');
    }
  };

  const rejectStockTake = async (data) => {
    try {
      resetErr();
      setPending();

      const res = await axiosApiInstance.patch(
        `${API_URL}/StocksTake/Void?stockTakeId=${params.id}`,
        data
      );

      setResolved(res);
    } catch (error) {
      setRejected(error || 'Unable to void stock take');
    }
  };

  const adjustStock = async (data) => {
    try {
      resetErr();
      setPending();

      const res = await axiosApiInstance.patch(
        `${API_URL}/StocksTake/StockAdjustment?stockTakeId=${params.id}`,
        data
      );

      setResolved(res);
    } catch (error) {
      setRejected(error || 'Unable to adjust stock');
    }
  };

  const handleOnConfirmClick = () => {
    switch (confirmAction?.action) {
      case 'update':
        updateStockTake(confirmAction?.payload);
        break;
      case 'approve':
        approveStockTake();
        break;
      case 'void':
        rejectStockTake();
        break;
      case 'adjust':
        adjustStock();
        break;
      case 'print':
        break;
      default:
        break;
    }
  };

  const refetch = () => {
    dispatch(getStockTake(params.id));
    reset();
  };

  useEffect(() => {
    if (status === FETCH_STATUS.PENDING) {
      setConfirmAction(undefined);
    }
  }, [status]);

  useEffect(() => {
    if (params.id) {
      dispatch(getStockTake(params.id));
      resetErr();
    }
  }, []);

  return (
    <PrivateRoute redirectTo="/dashboard/stock/stockTake/create">
      <div className="rounded-xl border border-[#A86AAA] bg-[#DDC4DD] h-fit">
        <div className="px-5 py-3 bg-[#7E208080] flex justify-between items-center">
          <h3 className="text-white flex items-center gap-4">
            <span className="py-2 px-3 bg-white text-[#878787] rounded-md">
              {stockTake?.status}
            </span>
            <span>Edit Stocktake / {stockTake?.id}</span>
          </h3>

          <div className="flex gap-2">
            {stockTake?.status === 'Pending' ? (
              <>
                <button
                  onClick={() =>
                    setConfirmAction({ action: 'approve', payload: null })
                  }
                  className="bg-green-700 rounded-[5px] text-sm px-4 py-2.5 flex gap-2 items-center text-white"
                >
                  <Icon icon="ic:twotone-check" /> Approve
                </button>
                <button
                  onClick={() =>
                    setConfirmAction({ action: 'void', payload: null })
                  }
                  className="bg-gradient-to-r from-[#B91414] to-[#DA4D4D] rounded-[5px] text-sm px-4 py-2.5 flex gap-2 items-center text-white"
                >
                  <Icon icon="clarity:remove-line" /> Void
                </button>
              </>
            ) : null}
            {stockTake?.status === 'Completed' &&
            !stockTake?.isStockAdjusted ? (
              <>
                <button
                  onClick={() =>
                    setConfirmAction({ action: 'adjust', payload: null })
                  }
                  className="bg-green-700 rounded-[5px] text-sm px-4 py-2.5 flex gap-2 items-center text-white"
                >
                  <Icon icon="ic:twotone-check" /> Adjust
                </button>
              </>
            ) : null}
            <button
              onClick={() =>
                setConfirmAction({ action: 'print', payload: null })
              }
              className="bg-white rounded-[5px] font-semibold text-sm px-4 py-2.5 flex gap-2 items-center text-[#8E5890]"
            >
              <Icon icon="bi:printer" /> Print
            </button>
          </div>
        </div>
      </div>

      <ErrorWithWrapper
        error={err}
        show={
          status === FETCH_STATUS.REJECTED ||
          stockTakeStatus === FETCH_STATUS.REJECTED
        }
      />
      <StockTakeForm
        onSubmit={(values) =>
          setConfirmAction({ action: 'update', payload: values })
        }
        stockTake={stockTake}
      />
      <ConfirmModal
        show={!!confirmAction}
        onClick={handleOnConfirmClick}
        text="Are you sure?"
        onClose={() => setConfirmAction(undefined)}
        status={status}
      />
      <SuccessModal
        message="Operation Successful."
        show={status === FETCH_STATUS.RESOLVED}
        onClick={refetch}
      />
    </PrivateRoute>
  );
};

export { UpdateStockTake };
