import { useEffect } from "react";
import { useCallback, useState } from "react";

const useSeatLayout = (update, seatLayouts, seatNum, rowNum) => {
  const [letters, setLetters] = useState(["A"]);
  // const [seatStatusCount, setSeatStatusCount] = useState({
  //   0: 0,
  //   1: 0,
  //   2: 0,
  //   3: 0,
  // });

  // useEffect(() => {
  //   // Calculate status counts
  //   const counts = seatLayouts?.reduce(
  //     (acc, layout) => {
  //       layout.definitions.forEach((definition) => {
  //         acc[definition.status]++;
  //       });

  //       return acc;
  //     },
  //     { 0: 0, 1: 0, 2: 0, 3: 0 }
  //   );
  //   setSeatStatusCount(counts);
  // }, [seatLayouts]);

  const addDefinition = useCallback(
    (layoutIndex) => {
      update(layoutIndex, {
        name: seatLayouts[layoutIndex]?.name,
        numOfSeats: seatLayouts[layoutIndex]?.numOfSeats,
        rowNumber: seatLayouts[layoutIndex]?.rowNumber,
        definitions: [
          ...seatLayouts[layoutIndex].definitions,
          {
            seatClassId: "",
            seatNumber: "",
            seatName: "",
            // rowNumber: "",
            status: "1",
          },
        ],
      });
    },
    [seatLayouts, update]
  );
  const removeDefinition = useCallback(
    (layoutIndex, definitionIndex) => {
      update(layoutIndex, {
        name: seatLayouts[layoutIndex]?.name,
        numOfSeats: seatLayouts[layoutIndex]?.numOfSeats,
        rowNumber: seatLayouts[layoutIndex]?.rowNumber,
        definitions: seatLayouts[layoutIndex]?.definitions
          ?.flat()
          .filter((def, i) => {
            return i !== definitionIndex;
          }),
      });
    },
    [seatLayouts, update]
  );
  const generateLetters = useCallback(
    (num) => {
     
      const startCharCode = letters.join("").charCodeAt(letters.length - 1);
      const generatedLetters = [];

      for (let i = 0; i < num; i++) {
        const charCode = startCharCode + (i % 26);
        const quotient = Math.floor(i / 26);
        let letter = String.fromCharCode(charCode);

        if (quotient > 0) {
          letter = "A".repeat(quotient) + letter;
        }

        generatedLetters.push(letter);
      }

      return generatedLetters;
    },
    [letters]
  );

  const getSeatLength = useCallback(
    (seat) => {
      seat.length = seatNum;
      return seat;
    },
    [seatNum]
  );
  const getRowLength = useCallback(
    (row) => {
      row.length = rowNum;
      return row;
    },
    [rowNum]
  );


  return {
    addDefinition,
    removeDefinition,
    getSeatLength,
    getRowLength,
    generateLetters,
    setLetters,
    letters,
    // seatStatusCount,
    // modifyInitialLayout,
  };
};

export { useSeatLayout };



//  const reGenerateLayout = useCallback(() => {
//     if (!isGenerated || viewedCurrent) {
//       reset({
//         ...screenInit,
//         ...info,
//         filmFormats: !info?.filmFormats?.length
//           ? [screenFormat.standard_2d]
//           : info?.filmFormats?.map((f) => f.id),
//         isActive: info.isActive.toString(),
//         seatLayouts: [],
//       });
//     }
//     setRegenerate(true);
//   }, [isGenerated, reset, viewedCurrent]);

//   const viewCurrentLayout = useCallback(() => {
//     reset({
//       ...screenInit,
//       ...info,
//       filmFormats: !info?.filmFormats?.length
//         ? [screenFormat.standard_2d]
//         : info?.filmFormats?.map((f) => f.id),
//       isActive: info.isActive.toString(),
//       seatLayouts: info?.seatLayouts?.map((layout) => ({
//         ...layout,
//         definitions: layout?.definitions?.map((def) => ({
//           ...def,
//           status: CHECK_SEAT_STATUS[def.status],
//         })),
//       })),
//     });
//     setViewedCurrent(true);
//     setPreviewModal(true);
//   }, [reset]);