import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useCallback, useEffect, useState } from "react";

import { Delete, InfoIcon, ScreenArcSvg } from "../Icons";
import SeatIcon from "../Icons/SeatIcon";
import { ScreenPreviewForm } from "./ScreenPreviewForm";
import { remove } from "lodash";
import { SeatLegend } from "./SeatLegend";
import { getDefinitionValues, getSelectedSeat } from "../../utils";
import UpArrowIcon from "../Icons/UpArrowIcon";
import DownArrowIcon from "../Icons/DownArrowIcon";

const detailInit = {
  seatName: "",
  seatNumber: "",
  columnNumber: "",
  layoutIndex: "",
  layout: {},
  seatStatus: 0,
  seatClassId: "",
};

const ScreenPreviewModal = ({
  show,
  seatClasses,
  onClose,
  layoutValues,
  screenName,
  seatLayouts,
  remove,
  update,
  // seatStatusCount,
}) => {
  const [reverse, setReverse] = useState(false);

  // const [leftToRight, setleftToRight] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [isMultiple, setIsMultiple] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [showPreviewForm, setShowPreviewForm] = useState(false);
  const [verticalReverse, setVerticalReverse] = useState(false);
  const [formDetails, setFormDetails] = useState(detailInit);

  const handleOpen = (
    {
      seatName,
      seatNumber,
      columnNumber,
      layoutIndex,
      layout,
      seatStatus,
      seatClassId,
    },
    event
  ) => {
    // if (event.ctrlKey || event.metaKey) {
    //   setIsSelected(true);
    //   setIsUpdate(true);

    //   const hasSeat = selectedSeats.some(
    //     (seat) =>
    //       seat.rowNumber === layout.rowNumber &&
    //       seat.columnNumber === columnNumber
    //   );
    //   if (hasSeat) {
    //     setSelectedSeats(
    //       selectedSeats.filter(
    //         (seat) =>
    //           !(
    //             seat.rowNumber === layout.rowNumber &&
    //             seat.columnNumber === columnNumber
    //           )
    //       )
    //     );
    //   } else {
    //     setSelectedSeats((prev) => [
    //       ...prev,
    //       { rowNumber: layout.rowNumber, columnNumber: columnNumber },
    //     ]);
    //   }
    // } else {
    //   setIsSelected(false)
    //   setSelectedSeats([]);
    // }
    setFormDetails({
      ...detailInit,
      seatName: seatName,
      seatNumber: seatNumber,
      columnNumber: columnNumber,
      layoutIndex: layoutIndex,
      layout: layout,
      seatStatus: seatStatus,
      seatClassId: seatClassId,
    });
    setShowPreviewForm(true);
  };

  const handleRowReverse = useCallback(() => {
    layoutValues.forEach((layout) => layout?.definitions.reverse());
    setReverse((prev) => !prev);
  }, [layoutValues]);
  const updateAll = useCallback(() => {
    // this if statement if for multiselection of seats. it would be worked on later
    // if (isSelected) {
    //   layoutValues[formDetails?.layoutIndex]?.definitions?.forEach((def) => {
    //     if (
    //       def.columnNumber === formDetails.columnNumber &&
    //       layoutValues[formDetails?.layoutIndex]?.rowNumber ===
    //       formDetails?.layout?.rowNumber
    //       ) {

    //       console.log({ isSelected, def });
    //       def.isSelected = !def.isSelected;
    //         // return { ...def, isSelected: true};
    //       } else {
    //         return def;
    //       }
    //   });
    // }
    let num = 0;

    layoutValues[formDetails?.layoutIndex]?.definitions?.forEach((def) => {
      if (def?.status !== 3) {
        num++;
      } else {
        return;
      }
      def.seatNumber = num;
      if (num > layoutValues[formDetails.layoutIndex]?.definitions?.length) {
        num = 0;
      }
    });
  }, [formDetails.layoutIndex, layoutValues]);

  const handleAddSeat = ({ layoutIndex, layout }) => {
    setFormDetails({ ...detailInit, layoutIndex: layoutIndex });
    update(layoutIndex, {
      name: layout?.name,
      numOfSeats: layout?.numOfSeats + 1,
      rowNumber: layout?.rowNumber,
      definitions: [
        ...layout?.definitions,
        {
          seatClassId: seatClasses[1]?.id,
          seatNumber: layout?.numOfSeats + 1,
          status: 0,
          columnNumber: layout?.numOfSeats + 1,
        },
      ],
    });
    setIsUpdate(true);
  };
  const handleRemoveSeat = ({ layoutIndex, layout }) => {
    setFormDetails({ ...detailInit, layoutIndex: layoutIndex });
    update(layoutIndex, {
      name: layout?.name,
      numOfSeats: layout?.numOfSeats - 1,
      rowNumber: layout?.rowNumber,
      definitions: [
        ...layout?.definitions,
        {
          seatClassId: seatClasses[1]?.id,
          seatNumber: layout?.numOfSeats - 1,
          status: 0,
          columnNumber: layout?.numOfSeats - 1,
        },
      ],
    });
    setIsUpdate(true);
  };

  useEffect(() => {
    if (isUpdate) {
      updateAll();
    }
  }, [isUpdate, updateAll]);


  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-[10000000]" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-75"
          leave="ease-in duration-75"
          leaveFrom="opacity-75"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full pb-6 max-w-6xl max-h-max overflow-hidden text-left transition-all transform bg-app-purple-8 shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-center text-white bg-gradient-to-b from-app-purple-4 to-app-purple-3 p-3.5 "
                >
                  SEAT LAYOUT
                </Dialog.Title>
                <div className="flex items-center justify-end p-2 bg-app-purple-5">
                  <div className="inline-flex items-center space-x-7">
                    <button
                      className="p-2 text-center rounded min-w-[92px] bg-app-yellow text-app-purple-6"
                      onClick={() => {
                        setVerticalReverse(false);
                        onClose();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                <section>
                  <h2 className="text-xl font-black text-center mb-4 mt-4">
                    Select Seats to Edit
                  </h2>
                  <p className="w-fit mx-auto text-lg leading-10 font-black flex-col gap-1 uppercase mb-8">
                    <span className="block h-[2px] bg-white w-full"></span>
                    <span className="text-center">{screenName}</span>
                    <span className="block w-[70%] mx-auto h-[2px] bg-white"></span>
                  </p>
                  <ScreenArcSvg className="w-[90%] mx-auto h-[60px]" />
                  <p className="font-medium uppercase tracking-[1.2px] leading-10 -mt-10 text-center">
                    Screen
                  </p>
                  {/* <div className="flex justify-center items-center gap-2">
                    <label htmlFor="isMultiple">Edit Multiple Seat</label>
                    <input
                      id="isMultiple"
                      type="checkbox"
                      value={isMultiple}
                      checked={isMultiple}
                      onChange={(e) => setIsMultiple(e.target.checked)}
                    />
                  </div> */}

                  <div className="px-10 mt-4 w-full">
                    {layoutValues?.map((layout, layoutIndex) => {
                      return (
                        <div
                          key={layout.id}
                          className="flex justify-center items-center gap-10"
                        >
                          <div className="flex items-center gap-4">
                            <button
                              onClick={() => remove(layoutIndex)}
                              title={`delete row ${layout.name}`}
                            >
                              <Delete className="!h-4 !w-4" />
                            </button>
                            {reverse ? null : (
                              <p className="text-xs md:text-sm lg:text-base">
                                {layout?.name}
                              </p>
                            )}
                            <button
                              title={`addseat to ${layout.name}`}
                              onClick={() =>
                                handleAddSeat({
                                  layout: layout,
                                  layoutIndex: layoutIndex,
                                })
                              }
                            >
                              <UpArrowIcon />
                            </button>
                            <button
                              title={`remove seat from ${layout.name}`}
                              onClick={() =>
                                handleRemoveSeat({
                                  layout: layout,
                                  layoutIndex: layoutIndex,
                                })
                              }
                            >
                              <DownArrowIcon />
                            </button>
                          </div>

                          <div className="flex items-center gap-0.5 md:gap-1.5 lg:gap-2 overflow-auto">
                            {Array.from(
                              { length: layout?.numOfSeats },
                              (seat, i) => (
                                <div key={`${layout?.name}${i + 1}`}>
                                  {/* <p className="text-[14px]">{i + 1}</p> */}
                                  <SeatIcon
                                    status={Number(
                                      layout?.definitions
                                        ?.flat()
                                        .find((def, j) => {
                                          return (
                                            Number(def.columnNumber) ===
                                            Number(i + 1)
                                          );
                                        })?.status
                                    )}
                                    isSelected={getDefinitionValues({
                                      layout: layout,
                                      value: "isSelected",
                                      index: i,
                                    })}
                                    seatName={`${
                                      layout?.name
                                    }${getDefinitionValues({
                                      layout: layout,
                                      value: "seatNumber",
                                      index: i,
                                    })}`}
                                    onClick={(event) =>
                                      handleOpen(
                                        {
                                          seatName: layout.name,
                                          columnNumber: getDefinitionValues({
                                            layout: layout,
                                            value: "columnNumber",
                                            index: i,
                                          }),
                                          layout: layout,
                                          layoutIndex: layoutIndex,
                                          seatNumber: getDefinitionValues({
                                            layout: layout,
                                            value: "seatNumber",
                                            index: i,
                                          }),
                                          seatClassId: getDefinitionValues({
                                            layout: layout,
                                            value: "seatClassId",
                                            index: i,
                                          }),
                                          seatStatus: getDefinitionValues({
                                            layout: layout,
                                            value: "status",
                                            index: i,
                                          }),
                                        },
                                        event
                                      )
                                    }
                                    className="w-[11px] h-[12px] md:w-[14px] md:h-[15px] lg:w-[17px] lg:h-[18px]"
                                  />
                                </div>
                              )
                            )}
                          </div>
                          {reverse ? (
                            <p className="text-xs md:text-sm lg:text-base">
                              {layout.name}
                            </p>
                          ) : null}
                        </div>
                      );
                    })}
                  </div>
                  {/* <div className="flex justify-center gap-4 mt-4">
                    <button
                      onClick={(ev) => {
                        handleRowReverse();
                        // ev.stopPropagation()
                      }}
                      type="button"
                      className="text-black bg-gradient-[180deg] to-[#b4aeb4] from-[#f5eef5] px-4 py-3 rounded-lg text-sm"
                    >
                      {reverse ? (
                        <span>Reverse L-R</span>
                      ) : (
                        <span>Reverse R-L</span>
                      )}
                    </button>
                    <button
                      onClick={(ev) => {
                        // handleVerticalReverse();
                        // ev.stopPropagation()
                      }}
                      type="button"
                      className="text-black bg-gradient-[180deg] to-[#b4aeb4] from-[#f5eef5] px-4 py-3 rounded-lg text-sm"
                    >
                      {verticalReverse ? (
                        <span>Reverse T-B</span>
                      ) : (
                        <span>Reverse B-T</span>
                      )}
                    </button>
                  </div> */}
                  <SeatLegend className="mt-10" />

                  <div className="flex justify-center items-center">
                    <div className="grid text-xs grid-cols-[25px_minmax(0,1fr)] items-center ml-[2%]">
                      <span>
                        <InfoIcon />
                      </span>

                      <p>
                        Remember to save on the main form after setting up
                        screen layout
                      </p>
                    </div>
                  </div>
                </section>
                {showPreviewForm && (
                  <ScreenPreviewForm
                    seatLayouts={seatLayouts}
                    seatClasses={seatClasses}
                    formDetails={formDetails}
                    update={update}
                    setIsUpdate={setIsUpdate}
                    isUpdate={isUpdate}
                    layoutValues={layoutValues}
                    show={showPreviewForm}
                    onClose={() => setShowPreviewForm(false)}
                  />
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export { ScreenPreviewModal };

//  `${layout.name}${reverse ? Number(layout?.numOfSeats) - i : i + 1}`,
//    reverse ? Number(layout?.numOfSeats) - i : i + 1,
//    reverse ? Number(layout?.numOfSeats) - i : i + 1,
//    index,
//    layout,
//    Number(
//      layout?.definitions?.find((def, j) => {
//        return (
//          Number(def.columnNumber) ===
//          Number(reverse ? Number(layout?.numOfSeats) - i : i + 1)
//        );
//      })?.status
//    ),
//    layout?.definitions?.find((def, j) => {
//      return (
//        Number(def.columnNumber) ===
//        Number(reverse ? Number(layout?.numOfSeats) - i : i + 1)
//      );
//    })?.seatClassId;
