import React, { useMemo } from "react";
import { useTable } from "react-table";
import { formatNumberAsCurrency } from "../../utils";

const MovieList = ({ movies = [] }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "film",
      },
      {
        Header: "Price",
        accessor: "ticketSales",
        Cell: ({ cell }) => {
          return formatNumberAsCurrency(cell?.value);
        },
      },
      { Header: "Admits", accessor: "ticketSold" },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: movies,
    });

  if (!movies.length) {
    return (
      <h4 className="m-4 text-base font-semibold text-center text-app-purple-4">
        No data
      </h4>
    );
  }

  return (
    <div className="overflow-x-hidden overflow-y-auto max-h-[500px] pr-3">
      <table
        className="w-full text-sm text-left text-gray-500 table-fixed"
        {...getTableProps()}
      >
        <thead className="text-lg text-gray-700 uppercase bg-app-purple-8">
          {headerGroups?.map((headerGroup) => (
            <tr {...headerGroup?.getHeaderGroupProps()}>
              {headerGroup?.headers?.map((column) => (
                <th className="px-6 py-3" {...column?.getHeaderProps()}>
                  {column?.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows?.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                className="text-base bg-white border-b odd:bg-white even:bg-app-purple-2"
                {...row?.getRowProps()}
              >
                {row?.cells?.map((cell) => {
                  return (
                    <td className="px-6 py-4" {...cell?.getCellProps()}>
                      {cell?.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* {movies.map((movie) => (
        <li key={movie.id}>
          <NavLink
            to="/dashboard"
            className="grid grid-cols-[45%_minmax(0,1fr)]  md:grid-cols-[35%_minmax(0,1fr)] items-center space-x-4 bg-white rounded-lg border py-4 px-3.5 "
          >
            <div
              style={{ backgroundColor: getBgColor(movie.title) }}
              className="w-full rounded-md h-52 md:h-40"
            />
            <div className="space-y-4 break-words md:h-full">
              <h5 className="text-base font-normal text-[#222222] ">
                {movie.title}
              </h5>
              <p className="text-xl font-semibold text-[#AA7DAA]">
                {formatNumberAsCurrency(movie.price)}
              </p>
            </div>
          </NavLink>
        </li>
      ))} */}
    </div>
  );
};

export { MovieList };
