import React from "react";

const GoBack = () => {
  return (
    <div>
      <svg
        width="18"
        height="12"
        viewBox="0 0 18 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.1249 4.81248H4.73681L7.46806 2.0931C7.69167 1.86949 7.8173 1.56621 7.8173 1.24998C7.8173 0.933742 7.69167 0.630461 7.46806 0.406851C7.24445 0.18324 6.94117 0.0576172 6.62494 0.0576172C6.3087 0.0576172 6.00542 0.18324 5.78181 0.406851L1.03181 5.15685C0.923702 5.26979 0.838956 5.40296 0.782438 5.54873C0.663667 5.83784 0.663667 6.16211 0.782438 6.45122C0.838956 6.59699 0.923702 6.73016 1.03181 6.8431L5.78181 11.5931C5.89221 11.7044 6.02355 11.7927 6.16825 11.853C6.31296 11.9133 6.46817 11.9444 6.62494 11.9444C6.7817 11.9444 6.93692 11.9133 7.08162 11.853C7.22633 11.7927 7.35767 11.7044 7.46806 11.5931C7.57937 11.4827 7.66771 11.3514 7.728 11.2067C7.78828 11.062 7.81932 10.9067 7.81932 10.75C7.81932 10.5932 7.78828 10.438 7.728 10.2933C7.66771 10.1486 7.57937 10.0172 7.46806 9.90685L4.73681 7.18748H16.1249C16.4399 7.18748 16.7419 7.06237 16.9646 6.83967C17.1873 6.61697 17.3124 6.31492 17.3124 5.99998C17.3124 5.68503 17.1873 5.38299 16.9646 5.16029C16.7419 4.93759 16.4399 4.81248 16.1249 4.81248Z"
          fill="#4F4F4F"
        />
      </svg>
    </div>
  );
};

export { GoBack };
