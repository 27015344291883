import React, { useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";

// const getInitialState = (pages) => {
//   return pages && pages.length > 0 ? pages[0].pageName : "";
// };

const ConcessionComponent = ({ pages, pageLayout, serviceArea, itemsById }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [areaPage, setAreaPage] = useState(pages[0].pageName);

  const servicePage = useMemo(() => {
    return pages.reduce((acc, curr) => {
      acc[curr.pageName] = curr.pageItems;
      return acc;
    }, {});
  }, [pages]);

  useEffect(() => {
    // if (pages.length) {
    //   setAreaPage(getInitialState(pages));
    // }
    if (pages.length) {
      setAreaPage(pages[0].pageName);
    }
    
  }, [pages]);

  const handleClickNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleClickPrevious = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const startIdx = (currentPage - 1) * 5;
  const endIdx = startIdx + 5;
  const displayedPages = pages.slice(startIdx, endIdx);
  
  return (
    <>
      <div className="grid grid-cols-5 gap-3 bg-purple-700 p-2">
        {servicePage[areaPage]?.map((item) => {
          return !item.itemId || servicePage[areaPage]?.length < 1 ? (
            <p className="text-white p-4 grid items-center">
              No item available
            </p>
          ) : (
            <div className="p3 bg-white flex flex-col items-center">
              <p> {itemsById[item.itemId]?.name}</p>
              <p> {itemsById[item.itemId]?.price}</p>
            </div>
          );
        })}
        {servicePage[areaPage]?.length < 1 && (
          <p className="text-white p-4 grid items-center">No item available</p>
        )}
      </div>
      <div className="grid grid-cols-7 gap-2 my-3">
        <button
          className="bg-gray-300 px-3 py-2 disabled:cursor-not-allowed"
          onClick={handleClickPrevious}
          disabled={currentPage === 1}
        >
          Prev
        </button>
        {displayedPages?.map((page) => {
          return (
            <button
              key={page.PageName}
              onClick={() => setAreaPage(page.pageName)}
              className={`bg-purple-300 px-3 py-2 rounded-md ${
                page.pageName === areaPage ? "border-2 border-black" : ""
              }`}
            >
              {page.pageName}
            </button>
          );
        })}
        <button
          className="bg-gray-300 px-3 py-2 disabled:cursor-not-allowed"
          onClick={handleClickNext}
          disabled={endIdx >= pages.length}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default ConcessionComponent;
