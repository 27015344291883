import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Loading,
  PageHeader,
  PriceCardForm,
  PrivateRoute,
  Success,
  ErrorWithWrapper,
  SuccessModal,
} from "../../../../components";
import { useRequest } from "../../../../hooks";
import { editCard } from "../../../../redux/slices";
import {
  API_URL,
  axiosApiInstance,
  axiosApiInstanceGet,
  FETCH_STATUS,
  PriceCard,
  Ticket,
  onReject,
  trueFalse,
  didUpdateFields,
  getAddedAndRemovedTickets,
} from "../../../../utils";

const PriceCardDetails = () => {
  let params = useParams();

  const dispatch = useDispatch();
  const { err, status, data, setResolved, setPending, setRejected } =
    useRequest();

  const {
    err: editErr,
    status: editStatus,
    setPending: editSetPending,
    setRejected: editSetRejected,
    setResolved: editSetResolved,
    resetErr,
  } = useRequest({});

  const onSave = async (values) => {
    try {
      editSetPending();
      const promises = [];

      const info = PriceCard.toDTO(values);

      const oldData = { ...data };
      oldData.tickets = null;
      oldData.isActive = null;
      oldData.active = null;

      const newData = { ...info };
      newData.tickets = null;
      newData.isActive = null;
      newData.active = null;

      if (didUpdateFields(oldData, newData)) {
        promises.push(
          await axiosApiInstance.patch(
            `${API_URL}/PriceCards/${params.id}/Update`,
            info
          )
        );
      }

      const { added, removed, modified } = getAddedAndRemovedTickets(
        data,
        values
      );

      if (added.length) {
        added.forEach(async (t) =>
          promises.push(
            await axiosApiInstance.patch(`${API_URL}/PriceCards/AddTicket`, {
              priceCardId: params.id,
              ticketId: t.id,
              price: t.price,
            })
          )
        );
      }

      if (removed.length) {
        removed.forEach(async (t) =>
          promises.push(
            await axiosApiInstance.patch(
              `${API_URL}/PriceCards/RemoveTicket?priceCardId=${params.id}&ticketId=${t.id}`
            )
          )
        );
      }

      if (modified.length) {
        modified.forEach(async (t) =>
          promises.push(
            await axiosApiInstance.patch(`${API_URL}/PriceCards/UpdateTicket`, {
              priceCardId: params.id,
              ticketId: t.id,
              price: t.price,
            })
          )
        );
      }

      if (trueFalse[values.active] !== data.isActive) {
        promises.push(
          await axiosApiInstance.patch(
            `${API_URL}/PriceCards/${params.id}/Toggle`
          )
        );
      }

      const res = await Promise.all(promises);

      if (res.length) {
        const card = res[promises.length - 1].data.data;

        dispatch(editCard(PriceCard.toUI(card)));
      }
      editSetResolved();
    } catch (error) {
      onReject(editSetRejected, error, "Unable to update price card");
    }
  };

  const getInfo = useCallback(async () => {
    if (params?.id) {
      try {
        setPending();
        const res = await axiosApiInstanceGet(
          `${API_URL}/PriceCards/${params.id}`,
          true
        );

        const info = PriceCard.toUI(res.data.data);

        info.tickets = info.tickets.map((t) => Ticket.toUI(t));

        setResolved(info);
      } catch (error) {
        onReject(
          setRejected,
          error,
          `Unable to retrieve price card with id ${params?.id}`
        );
      }
    }
  }, [params?.id, setRejected, setResolved, setPending]);

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  return (
    <PrivateRoute redirectTo={`/dashboard/price-cards/${params?.id}`}>
      <PageHeader>
        <h1 className="text-2xl font-semibold text-white ">Edit Price card</h1>
      </PageHeader>

      <Loading
        wrapperClassName="relative min-h-[50vh]"
        show={status === FETCH_STATUS.PENDING}
      />

      <ErrorWithWrapper
        error={err}
        show={status === FETCH_STATUS.REJECTED}
        retry={getInfo}
        className="!max-w-md !mx-auto"
      />

      <Success show={status === FETCH_STATUS.RESOLVED}>
        <PriceCardForm
          error={editErr}
          status={editStatus}
          onSave={onSave}
          cardInfo={data}
          isEdit
          onReset={resetErr}
        />

        <SuccessModal
          message="Successfully edited price card."
          show={editStatus === FETCH_STATUS.RESOLVED}
          backLink="/dashboard/price-cards"
        />
      </Success>
    </PrivateRoute>
  );
};

export { PriceCardDetails };
