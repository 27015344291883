import React, { useCallback, useEffect, useMemo } from "react";
import {
  ErrorWithWrapper,
  Loading,
  PageHeader,
  PrivateRoute,
} from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { getAllPaymentChannel, selectFinance } from "../../../redux/slices";
import { FETCH_STATUS } from "../../../utils";
import { NavLink } from "react-router-dom";
import { ChannelsTable } from "../../../components/Finance/ChannelsTable";
import { useLoggedInuser } from "../../../hooks";

const PaymentChannel = () => {
  const dispatch = useDispatch();
  const {
    profile: { cinemaId },
  } = useLoggedInuser();
  const { allPaymentChannels, allPaymentChannelStatus, paymentChannelErr } =
    useSelector(selectFinance);
  const getInfo = useCallback(() => {
    if (cinemaId) {
      dispatch(getAllPaymentChannel(cinemaId));
    }
  }, [dispatch, cinemaId]);
  useEffect(() => {
    if (
      allPaymentChannelStatus === FETCH_STATUS.IDLE ||
      allPaymentChannelStatus === FETCH_STATUS.REJECTED
    ) {
      dispatch(getAllPaymentChannel(cinemaId));
    }
  }, [allPaymentChannelStatus, dispatch, cinemaId]);
  const filteredChannels = useMemo(() => {
    return allPaymentChannels.filter((ch) => ch?.isActive);
  }, [allPaymentChannels]);

  return (
    <PrivateRoute redirectTo="/dashboard/finance/payment-channel">
      <PageHeader>
        <h1 className=" text-white font-semibold text-2xl">Payment Channel</h1>
        <NavLink
          to="/dashboard/finance/payment-channel/create"
          type="button"
          className="px-4 py-3 text-base font-medium bg-white rounded-md 
          text-app-purple-9"
        >
          Add Channel
        </NavLink>
      </PageHeader>
      <ErrorWithWrapper
        error={paymentChannelErr}
        show={allPaymentChannelStatus === FETCH_STATUS.REJECTED}
        retry={getInfo}
      />
      <div className="relative ">
        <Loading
          wrapperClassName="absolute top-0 left-0 right-0 min-h-[50vh]"
          show={allPaymentChannelStatus === FETCH_STATUS.PENDING}
        />

        <ChannelsTable
          channels={
            filteredChannels?.length > 0
              ? filteredChannels.filter((ch) => ch?.isActive)
              : []
          }
        />
      </div>
    </PrivateRoute>
  );
};

export { PaymentChannel };
