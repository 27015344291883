import React from "react";

const DownArrowIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      className={className}
    >
      <path fill="currentColor" d="M9 4h6v8h4.84L12 19.84L4.16 12H9z" />
    </svg>
  );
};

export default DownArrowIcon;
