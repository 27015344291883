import { useSelector } from "react-redux";
import { reportData } from "../../redux/slices";
import { useLoggedInuser } from "../../hooks";
import ReachLogo from "../../assets/images/logo.png";
import {
  dateAndTime,
  formatDateTime,
  formatNumberAsCurrency,
} from "../../utils";
import { Th } from "./styles";
const ItemSalesTable = ({ id }) => {
  const { itemSalesReport } = useSelector(reportData);
  const { profile } = useLoggedInuser();

  const calcSubTotal = (x) => {
    let marginTotal = 0;
    x.forEach((item) => {
      let itemMargin = item.amount - [item.cost * item.quantity];
      marginTotal = marginTotal + itemMargin;
    });

    return marginTotal;
  };

  const calcTotal = () => {
    let total = 0;
    if (itemSalesReport?.result) {
      itemSalesReport?.result.itemClass.forEach((data) => {
        let itemClassTotal = calcSubTotal(data?.items);
        total = total + itemClassTotal;
      });
      let itemPackagesMargin = calcSubTotal(
        itemSalesReport?.result.itemPackges
      );
      total = total + itemPackagesMargin;
    }

    return total;
  };
  return (
    <div className="px-3">
      <div className="flex items-center justify-between">
        <div
          key={ReachLogo}
          className=" w-[5rem] h-[5rem]  bg-no-repeat bg-contain bg-center "
          style={{
            backgroundImage: `url("${ReachLogo}")`,
          }}
        ></div>
        <p className="font-bold text-[20px]">Item Sales Report</p>
        <p>{profile.cinema}</p>
      </div>
      <div className=" flex p-2 justify-between">
        <div>
          <p>
            {" "}
            <span className="font-bold">User Name:</span>
            {` ${profile?.firstName} ${profile?.lastName}`}
          </p>
          <p>
            <span className="font-bold">Date Time Generated: </span>
            {formatDateTime(
              itemSalesReport?.paramFilter?.dateTimeGenerated,
              dateAndTime
            )}
          </p>
          <p>
            <span className="font-bold">Service Area: </span>
            {itemSalesReport?.paramFilter?.serviceArea
              ? itemSalesReport?.paramFilter?.serviceArea
              : "All Service Areas"}
          </p>
        </div>
        <div>
          <p className="font-bold">Data Range</p>
          <p>
            {new Date(itemSalesReport?.paramFilter?.dateFrom).toDateString()}
          </p>{" "}
          <p>{new Date(itemSalesReport?.paramFilter?.dateTo).toDateString()}</p>
        </div>
      </div>
      <table
        className="table-auto w-full mx-auto mt-8 overflow-hidden text-left border rounded-md border-app-purple-4"
        id={id}
      >
        <thead className="shadow-2xl bg-app-purple-8">
          <tr>
            {" "}
            <Th>Item</Th>
            <Th>Unit Of Measure</Th> <Th>Quantity</Th>
            <Th>Net</Th> <Th>Tax</Th> <Th>Sales</Th>
            <Th>Cost</Th>
            <Th>Margin</Th>
          </tr>
        </thead>
        <tbody>
          {itemSalesReport?.result
            ? itemSalesReport?.result?.itemClass?.map((data, index) => {
                return (
                  <>
                    <tr
                      className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30"
                      key={data?.id}
                    >
                      <td colSpan={8} className="font-bold text-[20px] p-3">
                        {data?.itemClass}
                      </td>
                    </tr>
                    {data?.items?.map((data2, index) => {
                      return (
                        <tr
                          className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30 "
                          key={index}
                        >
                          <td className="text-right py-2 ">{data2?.name}</td>
                          <td className="text-right">{data2?.unitOfMeasure}</td>
                          <td className="text-center">{data2?.quantity}</td>
                          <td className="text-right">
                            {id
                              ? data2?.net
                              : formatNumberAsCurrency(data2?.net?.toFixed(2))}
                          </td>
                          <td className="text-center">
                            {data2?.tax?.toFixed(2)}
                          </td>
                          <td className="text-right">
                            {id
                              ? data2?.amount
                              : formatNumberAsCurrency(data2?.amount)}
                          </td>
                          <td className="text-right">
                            {id
                              ? data2?.cost
                              : formatNumberAsCurrency(data2?.cost)}
                          </td>
                          <td className="text-right pr-2">
                            {id
                              ? data2?.amount - [data2?.cost * data2?.quantity]
                              : formatNumberAsCurrency(
                                  data2?.amount -
                                    [data2?.cost * data2?.quantity]
                                )}
                          </td>
                        </tr>
                      );
                    })}
                    <tr className="transition duration-300 border-y-2  bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[13px] font-bold">
                      <td
                        className="text-left pt-8 pb-3 text-[16px] pl-2"
                        colSpan={2}
                      >
                        Sub Total
                      </td>
                      <td className="text-center">{data?.total?.quantity}</td>
                      <td className="text-right">
                        {id
                          ? data?.total?.net
                          : formatNumberAsCurrency(data.total.net?.toFixed(2))}
                      </td>
                      <td className="text-center">
                        {data?.total?.tax?.toFixed(2)}
                      </td>
                      <td className="text-right">
                        {id
                          ? data?.total?.amount
                          : formatNumberAsCurrency(data?.total?.amount)}
                      </td>
                      <td className="text-right">
                        {id
                          ? data?.total?.cost
                          : formatNumberAsCurrency(data?.total?.cost)}
                      </td>
                      <td className="text-right pr-2">
                        {id
                          ? calcSubTotal(data?.items)
                          : formatNumberAsCurrency(calcSubTotal(data?.items))}
                      </td>
                    </tr>
                  </>
                );
              })
            : null}

          {itemSalesReport?.result?.itemPackges?.length > 0 && (
            <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30">
              <td colSpan={8} className="font-bold text-[20px] text-center p-3">
                Item Packages
              </td>
            </tr>
          )}
          {itemSalesReport?.result?.itemPackges?.map((packageItem, index) => {
            return (
              <>
                <tr
                  className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30 "
                  key={index}
                >
                  <td className="text-right py-2 ">{packageItem?.name}</td>
                  <td className="text-right">{packageItem?.unitOfMeasure}</td>
                  <td className="text-center">{packageItem?.quantity}</td>
                  <td className="text-right">
                    {id
                      ? packageItem?.net
                      : formatNumberAsCurrency(packageItem?.net?.toFixed(2))}
                  </td>
                  <td className="text-center">
                    {packageItem?.tax?.toFixed(2)}
                  </td>
                  <td className="text-right">
                    {id
                      ? packageItem?.amount
                      : formatNumberAsCurrency(packageItem?.amount)}
                  </td>
                  <td className="text-right">
                    {id
                      ? packageItem?.cost
                      : formatNumberAsCurrency(packageItem?.cost)}
                  </td>
                  <td className="text-right pr-2">
                    {id
                      ? packageItem?.amount -
                        [packageItem?.cost * packageItem?.quantity]
                      : formatNumberAsCurrency(
                          packageItem?.amount -
                            [packageItem?.cost * packageItem?.quantity]
                        )}
                  </td>
                </tr>
              </>
            );
          })}

          {/* ticket package */}

          {itemSalesReport?.result?.ticketItems?.length > 0 && (
            <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30">
              <td colSpan={8} className="font-bold text-[20px] text-center p-3">
                Package Tickets
              </td>
            </tr>
          )}

          {itemSalesReport?.result?.ticketItems?.map((item) => {
            return (
              <>
                <tr className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30">
                  <td colSpan={8} className=" py-4 pl-6 font-bold">
                    {" "}
                    {item?.packageTicket}
                  </td>
                </tr>
                {item?.items?.map((item2, i) => {
                  return (
                    <tr
                      key={i}
                      className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30 "
                    >
                      <td className="pl-6 ">{item2?.name}</td>
                      <td className="pl-6 ">{item2?.unitOfMeasure}</td>
                      <td className="text-center"> {item2?.quantity}</td>
                      <td className="text-right">
                        {" "}
                        {id
                          ? item2?.net
                          : formatNumberAsCurrency(item2?.net?.toFixed(2))}
                      </td>
                      <td className="text-center"> {item2?.tax?.toFixed(2)}</td>
                      <td className="text-right">
                        {" "}
                        {id
                          ? item2?.amount
                          : formatNumberAsCurrency(item2?.amount)}
                      </td>
                      <td className="  text-right">
                        {" "}
                        {id ? item2?.cost : formatNumberAsCurrency(item2?.cost)}
                      </td>
                      <td className="text-right pr-2">
                        {id
                          ? item2?.amount - [item2?.cost * item2?.quantity]
                          : formatNumberAsCurrency(
                              item2?.amount - [item2?.cost * item2?.quantity]
                            )}
                      </td>
                    </tr>
                  );
                })}
                <tr className="transition duration-300 border-y-2  bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[13px] font-bold">
                  <td
                    className="text-left pt-8 pb-3 font-semibold text-[16px] pl-2"
                    colSpan={2}
                  >
                    Sub Total
                  </td>
                  <td className="text-center">{item?.total?.quantity}</td>
                  <td className="text-right font-semibold">
                    {id
                      ? item?.total?.net
                      : formatNumberAsCurrency(item?.total?.net?.toFixed(2))}
                  </td>
                  <td className="text-center font-semibold">
                    {item?.total?.tax?.toFixed(2)}
                  </td>
                  <td className="text-right font-semibold">
                    {id
                      ? item?.total?.amount
                      : formatNumberAsCurrency(item?.total?.amount)}
                  </td>
                  <td className="text-right font-semibold">
                    {formatNumberAsCurrency(item?.total?.cost)}
                  </td>
                  <td className="text-right pr-2 font-semibold">
                    {id
                      ? calcSubTotal(item?.items)
                      : formatNumberAsCurrency(calcSubTotal(item?.items))}
                  </td>
                </tr>
              </>
            );
          })}

          <tr className="transition duration-300 border-y-2   bg-app-purple-1 border-app-purple-4 border-opacity-30 pt-6 text-[16px] text-app-purple-4 font-bold ">
            <td className="text-left pt-8 pb-3 text-[16px] pl-2" colSpan={2}>
              Item Sales Report Total
            </td>
            <td className="text-center">
              {itemSalesReport?.result?.total?.quantity}
            </td>
            <td className="text-right">
              {id
                ? itemSalesReport?.result?.total?.net
                : formatNumberAsCurrency(
                    itemSalesReport?.result?.total?.net?.toFixed(2)
                  )}
            </td>
            <td className="text-center">
              {itemSalesReport?.result?.total?.tax?.toFixed(2)}
            </td>
            <td className="text-right">
              {id
                ? itemSalesReport?.result?.total?.amount
                : formatNumberAsCurrency(itemSalesReport?.result?.total?.amount)}
            </td>
            <td className="text-right">
              {id
                ? itemSalesReport?.result?.total?.cost
                : formatNumberAsCurrency(itemSalesReport?.result?.total?.cost)}
            </td>
            <td className="text-right pr-2">
              {id ? calcTotal() : formatNumberAsCurrency(calcTotal())}
            </td>
          </tr>
        </tbody>
      </table>
      {!itemSalesReport?.result && (
        <p className="text-center py-3"> No Data Found</p>
      )}
    </div>
  );
};
export { ItemSalesTable };
