import { yupResolver } from "@hookform/resolvers/yup";
import React, { useCallback, useEffect, useState, Fragment } from "react";
import DatePicker from "react-datepicker";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { GoBack } from "../../../../components/Icons";
import {
  API_URL,
  axiosApiInstance,
  FETCH_STATUS,
  filmContractInit,
  filmContractSchema,
  getSelectedItem2,
  minicontractDates,
} from "../../../../utils";
import { DateTimeInput } from "../../../../components/Films/DateTimeInput";
import {
  FormGroup,
  Label,
  Select,
} from "../../../../components/FilmContract/FormStyle";
import {
  getFilmsByCinema,
  selectFilms,
} from "../../../../redux/slices/filmsSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../../redux/slices/userSlice";
import { Dialog, Transition } from "@headlessui/react";
import { ContractDatesRow } from "../../../../components/FilmContract";
import { useRequest } from "../../../../hooks";
import {
  ErrorWithWrapper,
  InlineErr,
  SearchSelect,
  Spinner,
} from "../../../../components/UI";
import { getContract } from "../../../../redux/slices";
import { SuccessToast } from "../../../../components";

const ContractForm = () => {
  const { filmsByCinema } = useSelector(selectFilms);

  const { profile } = useSelector(selectUser);
  const { err, status, resetErr, setResolved, setPending, setRejected } =
    useRequest();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showEditSuccess, setShowEditSuccess] = useState(false);
  const [activeDate, setActiveDate] = useState(new Date().toISOString());

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(filmContractSchema),
    defaultValues: {
      ...filmContractInit,
      contractDates: [
        {
          ...minicontractDates,
          weekNumber: 1,
          percentage: 0,
          minGuarantee: 0,
        },
      ],
      cinemaIds: [profile?.cinemaId],
    },
  });

  const contractDates = useWatch({ control, name: "contractDates" });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "contractDates",
  });

  //callback to dispatch getfilmsbycinea
  const getFilms = useCallback(
    (force) => {
      profile?.cinemaId &&
        dispatch(getFilmsByCinema({ force, cinemaId: profile?.cinemaId }));
    },

    [dispatch, profile]
  );

  //useeffect to get films by cinema
  useEffect(() => {
    getFilms();
  }, [getFilms]);

  // sets value of contract date
  useEffect(() => {
    setValue("startDate", activeDate);
  }, [activeDate, setValue]);

  //to control edit and create form values
  useEffect(() => {
    if (id) {
      dispatch(getContract({ id }))
        .unwrap()
        .then((contract) => {
          reset({
            ...contract,

            cinemaIds: [contract?.cinemaId],
            contractType: contract?.type === "Weekly" ? 0 : "",
            contractDates: contract?.contractDates,
            film: contract?.filmId,
          });
          setActiveDate(contract?.startDate ? contract?.startDate : new Date());
        });
    } else {
      reset({
        ...filmContractInit,
        cinemaIds: [profile?.cinemaId],
        contractDates: [
          {
            ...minicontractDates,
            weekNumber: 1,
            percentage: 0,
            minGuarantee: 0,
          },
        ],
      });
      setActiveDate(new Date().toISOString());
    }
  }, [dispatch, id, reset, profile, filmsByCinema]);

  const handleClose = () => {
    setShowSaveModal(false);
  };

  const handleCloseToast = () => {
    setShowSaveModal(false);
    setShowSuccess(false);
  };

  const handleEditToast = () => {
    setShowSaveModal(false);
    setShowEditSuccess(false);
  };

  // console.log(
  //   filmsByCinema.find((x) => {
  //     return x.name === null;
  //   })
  // );

  const onSubmit = async (data) => {
    if (!id) {
      const body = {
        cinemaIds: data.cinemaIds,
        filmId: data.film,
        type: data.contractType,
        startDate: data.startDate,
        contractDates: data.contractDates,
      };
      try {
        setPending();
        await axiosApiInstance.post(`${API_URL}/MovieContracts/Create`, body);

        setResolved();
        setShowSuccess(true);
      } catch (err) {
        let errMsgs = "Unable to create Contract";

        if (err?.errors?.length) {
          errMsgs = err?.errors[0]?.errorMessages.join(" ");
        }

        setRejected(errMsgs);
      }
    }

    if (id) {
      const body = {
        cinemaIds: data.cinemaId,
        filmId: data.film,
        type: data.contractType,
        startDate: data.startDate,
        contractDates: data.contractDates,
      };
      try {
        setPending();
        await axiosApiInstance.patch(
          `${API_URL}/MovieContracts/Update?contractId=${id}`,
          body
        );
        setResolved();
        setShowEditSuccess(true);
        reset();
      } catch (err) {
        let errMsgs = "Unable to edit Contract";

        if (err?.errors?.length) {
          errMsgs = err?.errors[0]?.errorMessages.join(" ");
        }

        setRejected(errMsgs);
      }
    }
  };

  

  return (
    <>
      <div className="mx-auto w-[90%]">
        <div className="text-white border-2 border-[#A86AAA] rounded-t-xl bg-[#C080C5] p-2">
          <div className="flex justify-between">
            <button
              className="bg-[#E5E5E5] p-2 rounded-md mr"
              onClick={() => navigate("/dashboard/film-contracts")}
            >
              <GoBack />
            </button>
            <p className="font-bold text-xl text-white">Create Film Contract</p>
            <button
              type="button"
              className="text-white bg-gradient-[180deg] to-[#764877] from-[#A86AAA] px-4 py-2 rounded-lg text-sm"
              onClick={() => setShowSaveModal(true)}
            >
              Save
            </button>
          </div>
        </div>
        <ErrorWithWrapper
          error={err}
          show={status === FETCH_STATUS.REJECTED}
          retry={resetErr}
        />

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-[#E6C4E7] rounded-b-xl p-3 pt-[30px] border-2 border-t-0 border-[#A86AAA]">
            <div className="flex justify-center mb-4">
              <div>
                <FormGroup>
                  <Label>Contract Type:</Label>

                  <Select name={"contractType"} {...register("contractType")}>
                    <option value={0}>Weekly</option>
                  </Select>
                </FormGroup>

                <FormGroup>
                  <Label>Contract Start Date:</Label>
                  <div className="relative bg-app-purple-5 rounded-[10px] my-0.5 z-50 mb-3">
                    <span
                      role="presentation"
                      className="absolute right- scale-75 translate-x-1 top-2/4 -translate-y-2/4"
                    >
                      {/* <CalendarToolbar /> */}
                    </span>
                    <DatePicker
                      placeholderText="Start Date"
                      selected={new Date(activeDate)}
                      onChange={(value) => {
                        setActiveDate(value.toISOString());
                      }}
                      customInput={
                        <DateTimeInput
                          className="bg-[#d0a3d3] w-[20rem]"
                          dateFormat={"EEE, MMM dd, yyyy"}
                        />
                      }
                    />
                  </div>
                </FormGroup>

                <FormGroup>
                  <Label>Film:</Label>
                  <Controller
                    control={control}
                    name={"film"}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <SearchSelect
                          items={filmsByCinema}
                          dropDownNameKey = "name"
                          inputClassName="!w-full p-2 px-4 py-2 border-none !bg-app-purple-10"
                          listClassName="!py-2"
                          id={`film`}
                          value={getSelectedItem2(filmsByCinema, value, "id")}
                          onChange={(value) => onChange(value?.id)}
                          displayValue={(value) => {
                            return value?.name;
                          }}
                          placeholder={`Select Film`}
                          searchOptions={{
                            keys: ["name"],
                          }}
                        />
                      );
                    }}
                  />
                </FormGroup>
              </div>
            </div>

            {/* FILM RENTAL SECTION */}
            <div className="mx-auto w-[80%] border-2 border-[#AA7DAA] rounded-xl pb-2">
              <div className="text-white  bg-[#a480a4] p-2 rounded-t-lg">
                <div className="flex justify-start">
                  <button
                    type="button"
                    onClick={() => {
                      append({
                        // ...contractDates,
                        weekNumber: fields?.length + 1,
                        percentage: 0,
                        minGuarantee: 0,
                      });
                    }}
                    className="text-black bg-gradient-[180deg] to-[#b4aeb4] from-[#f5eef5] px-4 py-2 rounded-lg text-sm"
                  >
                    Add Week
                  </button>
                </div>
              </div>

              <div className="bg-[#e6dde6] grid grid-cols-4 text-black border-2 border-[#A86AAA] border-x-0 py-2">
                <p className="pl-2">Week#</p>
                <p>Fixed Percentage</p>
                <p>Minimum Guarantee</p>
              </div>

              {!contractDates?.length ? (
                <small className="block my-1 font-medium text-center text-rose-500">
                  Please create at least one week
                </small>
              ) : null}
              {fields?.map((field, index) => {
                return (
                  <ContractDatesRow
                    key={field.id}
                    register={register}
                    index={index}
                    remove={remove}
                  />
                );
              })}
            </div>
          </div>

          <SuccessToast
            show={showSuccess}
            handleClose={handleCloseToast}
            message={"Contract created successfully"}
          />
          <SuccessToast
            show={showEditSuccess}
            handleClose={handleEditToast}
            message={"Contract edited successfully"}
          />

          {/* SAVE MODAL SECTION */}
          <Transition show={showSaveModal} appear as={Fragment}>
            <Dialog
              as="div"
              className="relative z-[10000]"
              onClose={handleClose}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-50" />
              </Transition.Child>
              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex items-center justify-center min-h-full p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-sm max-h-[90vh] p-2 text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
                      {/* <div> */}
                      <div className="flex justify-end items-center mb-6">
                        <button
                          className="border border-gray-400 text-[9px] bg-gray-300 rounded-full px-[0.3rem]"
                          onClick={handleClose}
                        >
                          x
                        </button>
                      </div>
                      {/* </div> */}
                      <div className="flex justify-center items-center mb-3">
                        <p className="text-gray-600 font-semibold">
                          Sure you want to accept?
                        </p>
                      </div>
                      <div className="flex justify-center items-center mb-3">
                        <p className="text-gray-300 text-sm">
                          Are you sure you want to accept this?
                        </p>
                      </div>
                      <div className=" flex justify-around mb-3">
                        <button
                          type="button"
                          className="bg-transparent w-[12rem] mx-2 py-2 border border-gray-500 rounded-md text-sm text-black"
                          onClick={handleClose}
                        >
                          No, cancel
                        </button>
                        <button
                          // ref={confirmRef}
                          type="submit"
                          className="bg-gradient-[180deg] to-[#764877] from-[#A86AAA] w-[12rem] relative py-2 mx-2 rounded-md text-sm text-white"
                          onClick={handleSubmit(onSubmit)}
                        >
                          Yes, Confirm
                          <Spinner
                            className="!w-3 !h-3 text-white mx-1 !absolute top-[0.8rem] right-4"
                            status={status}
                          />
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </form>
      </div>
    </>
  );
};

export { ContractForm };
