import { InvoiceTable, PrivateRoute } from "../../../components";

const InvoicePage = () => {
  return (
    <PrivateRoute redirectTo="/dashboard/finance/invoice">
      <InvoiceTable />
    </PrivateRoute>
  );
};
export { InvoicePage };
