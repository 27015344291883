import classNames from "classnames";
import { useMemo } from "react";
import { useGlobalFilter, useTable } from "react-table";
import { formatDateTime, formatNumberAsCurrency } from "../../utils";
import styles from "./contractReport.module.css";

const ContractReportTable = ({ data, paramFilter, total, id }) => {
  const columns = useMemo(() => {
    return [
      {
        Header: "Film",
        accessor: "film",
        Footer: `TOTAL`,
      },
      {
        Header: "Week",
        accessor: "week",
      },
      {
        Header: "Week Start Date",
        accessor: "weekStartDate",
        Cell: ({ value }) =>
          formatDateTime(new Date(value), "EEE, MMM dd, yyyy"),
      },
      {
        Header: "Film Date Created",
        accessor: "filmDateCreated",
        Cell: ({ value }) =>
          formatDateTime(new Date(value), "EEE, MMM dd, yyyy"),
      },
      {
        Header: "No of Shows",
        accessor: "numOfShows",
      },
      {
        Header: "Admits",
        accessor: "admits",
      },
      {
        Header: "GBO",
        Footer: `${
          id ? total.gboTotal : formatNumberAsCurrency(total.gboTotal)
        }`,
        accessor: "gbo",
        Cell: ({ value }) => {
          return id ? value : formatNumberAsCurrency(value);
        },
      },
      {
        Header: "Tax %",
        accessor: "taxPerc",
        Cell: ({ value }) => {
          return value.toFixed(2);
        },
      },
      {
        Header: "Tax Box Office",
        accessor: "taxBoxOffice",
        Cell: ({ value }) => {
          return id
            ? value.toFixed(2)
            : formatNumberAsCurrency(value.toFixed(2));
        },
      },
      {
        Header: "Net Box Office",
        accessor: "netBoxOffice",
        Cell: ({ value }) => {
          return id
            ? value.toFixed(2)
            : formatNumberAsCurrency(value.toFixed(2));
        },
      },
      {
        Header: "Royalty Percentage %",
        accessor: "royaltyPerc",
        Cell: ({ value }) => {
          return value.toFixed(2);
        },
      },
      {
        Header: "Cinema Amount",
        Footer: `${
          id
            ? total.cinemaTotal.toFixed(2)
            : formatNumberAsCurrency(total.cinemaTotal.toFixed(2))
        }`,
        accessor: "cinemaAmount",
        Cell: ({ value }) => {
          return id
            ? value.toFixed(2)
            : formatNumberAsCurrency(value.toFixed(2));
        },
      },
      {
        Header: "Royalty Amount",
        accessor: "royaltyAmount",
        Cell: ({ value }) => {
          return id
            ? value.toFixed(2)
            : formatNumberAsCurrency(value.toFixed(2));
        },
      },
      {
        Header: "WHT Percentage %",
        accessor: "whtPerc",
        Cell: ({ value }) => {
          return value.toFixed(2);
        },
      },
      {
        Header: "WHT Amount",
        accessor: "whtAmount",
        Cell: ({ value }) => {
          return id
            ? value.toFixed(2)
            : formatNumberAsCurrency(value.toFixed(2));
        },
      },
      {
        Header: "Net Payable",
        Footer: `${
          id
            ? total.netPayableTotal
            : formatNumberAsCurrency(total.netPayableTotal)
        }`,
        accessor: "netPayable",
        Cell: ({ value }) => {
          return id ? value : formatNumberAsCurrency(value.toFixed(2));
        },
      },
    ];
  }, [id, total.cinemaTotal, total.gboTotal, total.netPayableTotal]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );

  const { globalFilter } = state;

  return (
    <>
      <div className="hidden relative md:inline-block w-1/2 h-3/4 m-auto mt-20 mb-4">
        <div className="absolute mt-2 left-0 top-2 pl-2 pointer-events-none">
          <svg
            className="w-5 h-5"
            fill="#5e0e65"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>
        <input
          value={globalFilter || ""}
          onChange={(e) => setGlobalFilter(e.target.value)}
          type="text"
          name="searchBar"
          id="search-navbar"
          className="inline-block m-auto h-3/4 p-2 pl-10 w-[50%] text-black text-lg bg-white border-transparent outline-none focus:border-transparent focus:ring-0 rounded-lg"
          placeholder="Look up report here..."
        />
      </div>
      <div className="flex justify-between items-baseline mb-4">
        <div className="flex items-baseline space-x-6">
          <h2 className="font-bold text-xl">{paramFilter?.distributor}</h2>
          <p className="font-semibold">
            From: {formatDateTime(paramFilter?.dateFrom ?? "", "dd MMMM yyyy")}
          </p>
          <p className="font-semibold">
            To: {formatDateTime(paramFilter?.dateTo ?? "", "dd MMMM yyyy")}
          </p>
        </div>
        <p className="font-semibold">{paramFilter?.cinema}</p>
      </div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-sm">
        <table
          {...getTableProps()}
          className={classNames(
            "w-full mx-auto mt-8 overflow-hidden text-left border rounded-md border-app-purple-4"
            // styles.table
          )}
          id={id}
        >
          <thead className="shadow-2xl bg-app-purple-8">
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, i) => (
                  <th
                    {...column.getHeaderProps()}
                    className="px-3 py-3 font-normal tracking-wide uppercase border border-gray-300 text-md"
                    key={i}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="text-[#534C7E]">
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={index}
                  className="transition duration-300 border bg-app-purple-2 border-app-purple-4 border-opacity-30"
                >
                  {row.cells.map((cell, i) => {
                    return (
                      <td
                        data-label={cell.column.Header}
                        {...cell.getCellProps()}
                        className="py-3 min-w-min px-3"
                        key={i}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            {footerGroups.map((footerGroup, i) => (
              <tr
                key={i}
                {...footerGroup.getFooterGroupProps()}
                className="bg-app-purple-8 border-b"
              >
                {footerGroup.headers.map((column, i) => (
                  <td
                    key={i}
                    {...column.getFooterProps}
                    className="px-3 py-3 text-[#534C7E] min-w-min"
                  >
                    {column.render("Footer")}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>
    </>
  );
};

export default ContractReportTable;
