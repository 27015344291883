import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const useGoBack = (defaultPath = "/") => {
  const navigate = useNavigate();

  const goBack = useCallback(() => {
    const prevPath = window?.sessionStorage?.getItem("prevPath");

    const pathname = window?.location?.pathname;

    if (prevPath === pathname) {
      navigate(defaultPath);
    } else {
      navigate(-1);
    }
  }, [navigate, defaultPath]);

  const monitorPath = useCallback(() => {
    const storage = window?.sessionStorage;
    if (!storage) return;

    const prevPath = storage.getItem("currentPath");

    storage.setItem("prevPath", prevPath);

    const pathname = window?.location?.pathname;

    storage.setItem("currentPath", pathname);
  }, []);

  return { goBack, monitorPath };
};

export { useGoBack };
