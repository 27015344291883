import React from 'react'
import SeatIcon from '../Icons/SeatIcon';
import { SEATSTATUS } from '../../utils';
import classNames from 'classnames';

const SeatLegend = ({className}) => {
  return (
    <div className={classNames("flex gap-5 justify-center", className)}>
      <div className="py-4 flex gap-1 items-center font-nunito font-semibold capitalize text-xs md:text-sm lg:text-base">
        <SeatIcon
          status={SEATSTATUS.AVAILABLE}
          seatName=""
          className="w-[12px] h-[12px] md:w-[20px] md:h-[20px] lg:w-[25px] lg:h-[25px]"
        />
        <p>available</p>
      </div>
      <div className="py-4 flex gap-1 items-center font-nunito font-semibold capitalize text-xs md:text-sm lg:text-base">
        <SeatIcon
          status={SEATSTATUS.RESERVED}
          seatName=""
          className="w-[12px] h-[12px] md:w-[20px] md:h-[20px] lg:w-[25px] lg:h-[25px]"
        />
        <p>reserved</p>
      </div>
      <div className="py-4 flex gap-1 items-center font-nunito font-semibold capitalize text-xs md:text-sm lg:text-base">
        <SeatIcon
          status={SEATSTATUS.UNAVAILABLE}
          seatName=""
          className="w-[12px] h-[12px] md:w-[20px] md:h-[20px] lg:w-[25px] lg:h-[25px]"
        />
        <p>unavailable</p>
      </div>
    </div>
  );
}

export {SeatLegend}
