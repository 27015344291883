import {useEffect, useRef, useState} from "react";
import {
    ExportExcelBtn,
    FilmByScreenTable,
    InputErr,
    PdfButton,
    Pdiv,
    Pdiv2,
    PrivateRoute,
    ReportWrapper,
} from "../../../components";
import {useNavigate} from "react-router-dom";
import DatePicker from "react-datepicker";
import addDays from "date-fns/addDays";
import {DateTimeInput} from "../../../components/Films/DateTimeInput";
import {useLoggedInuser} from "../../../hooks";
import {getFilmByScreen, reportData} from "../../../redux/slices";
import {formatDateForApi, itemSalesSchema} from "../../../utils";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Element, scroller} from "react-scroll";
import {useDispatch, useSelector} from "react-redux";

const FilmsByScreen = () => {
  const navigate = useNavigate();
  const [startDate, setStart] = useState(addDays(new Date(), -2));
  const [endDate, setEnd] = useState(new Date());
  const { filmByScreen, filmByScreenStatus } = useSelector(reportData);
  const { profile } = useLoggedInuser();
  const dispatch = useDispatch();
  const reportRef = useRef(null);

  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(itemSalesSchema),
    defaultValues: {
      DateFrom: startDate,
      DateTo: endDate,
      CinemaId: profile.cinemaId,
    },
  });

  useEffect(() => {
    setValue("DateFrom", startDate);
    setValue("DateTo", endDate);
  }, [startDate, endDate, setValue]);

  const onSubmit = (data) => {
    dispatch(
      getFilmByScreen({
        ...data,
        DateFrom: formatDateForApi(new Date(data.DateFrom)),
        DateTo: formatDateForApi(new Date(data.DateTo)),
        force: true,
      })
    );
    scroller.scrollTo("reportContainer", {
      duration: 1500,
      delay: 0,
      smooth: true,
      offset: -100, // Scrolls to element + 50 pixels down the page
    });
  };


  return (
    <PrivateRoute redirectTo={"/dashboard/reports/films-by-screen"}>
      <div className="w-[95%] mx-auto mt-6">
        <ReportWrapper
          reportName={"Film By Screen"}
          submitFor={"filmByScreen"}
          goBackRoute={"/dashboard/reports"}
          reportStatus={filmByScreenStatus}
        >
          <form onSubmit={handleSubmit(onSubmit)} id="filmByScreen">
            <Pdiv label={"Date Range"}>
              <div className="flex items-center">
                <DatePicker
                  placeholderText="Start Date"
                  selected={new Date(startDate)}
                  onChange={(value) => setStart(new Date(value))}
                  customInput={
                    <DateTimeInput
                      className="bg-app-purple-8  w-[12rem]"
                      dateFormat={"EEE, MMM dd, yyyy"}
                    />
                  }
                />
                <p className="w-[6rem] text-center"> To:</p>
                <DatePicker
                  placeholderText="End Date"
                  selected={new Date(endDate)}
                  onChange={(value) => setEnd(new Date(value))}
                  customInput={
                    <DateTimeInput
                      className="bg-app-purple-8"
                      dateFormat={"EEE, MMM dd, yyyy"}
                    />
                  }
                />
              </div>
            </Pdiv>
            <InputErr err={errors?.DateTo?.message} />
            <InputErr err={errors?.DateFrom?.message} />
            <Pdiv2 label={"Site"} name={"CinemaId"} {...register("CinemaId")}>
              <option value={""}>Select Cinema</option>
              <option value={profile.cinemaId}>{profile.cinema}</option>
            </Pdiv2>
            <InputErr err={errors?.CinemaId?.message} />
          </form>
        </ReportWrapper>
        <Element name="reportContainer">
          {filmByScreen?.paramFilter && (
            <>
              <div className="flex justify-center mt-5 space-x-4">
                <PdfButton refValue={reportRef} docTitle={"film-by-screen"} />
                <ExportExcelBtn id={"f-byScreen"} name={"filmByScreen.xlsx"} />
              </div>
              <div ref={reportRef}>
                <FilmByScreenTable />
              </div>
              <div  className="hidden">
                <FilmByScreenTable id={"f-byScreen"} />
              </div>
            </>
          )}
        </Element>
      </div>
    </PrivateRoute>
  );
};
export { FilmsByScreen };
