import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  reportData,
  getOrdersByUser,
  refundOrder,
  getOrderById,
} from "../../../redux/slices";
import { FETCH_STATUS, preventScrollBug } from "../../../utils";
import { Spinner } from "../../UI";

const FullRefundModal = ({
  onClose = () => {},
  show,
  orderData,
  funcProps,
}) => {
  const dispatch = useDispatch();
  const [reason, setReason] = useState("");
  const [supplimentaryOrderId, setSupplementaryOrderId] = useState("");
  const [supOrderError, setSupOrderError] = useState(false);
  const { singleOrderStatus, refundOrderStatus } = useSelector(reportData);

  const refundOrderFunc = (e) => {
    e.preventDefault();
    if (supplimentaryOrderId) {
      dispatch(getOrderById({ force: true, orderId: supplimentaryOrderId }))
        .unwrap()
        .then((data) => {
          setSupOrderError(false);
          dispatch(
            refundOrder({
              orderId: orderData.id,
              force: true,
              body: {
                reason: reason || null,
                supplimentaryOrderId: supplimentaryOrderId || null,
              },
            })
          ).then(() => {
            dispatch(getOrdersByUser(funcProps));
            onClose();
          });
        })
        .catch((error) => {
          setSupOrderError(true);
          return;
        });
    } else {
      dispatch(
        refundOrder({
          orderId: orderData.id,
          force: true,
          body: {
            reason: reason || null,
            supplimentaryOrderId: supplimentaryOrderId || null,
          },
        })
      ).then(() => {
        dispatch(getOrdersByUser(funcProps));
        onClose();
      });
    }
  };

  return (
    <>
      <Transition show={show} appear as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[10000]"
          onClose={() => {
            setTimeout(() => {
              preventScrollBug();
            }, 500);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => {
              setTimeout(() => {
                preventScrollBug();
              }, 500);
            }}
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-sm max-h-[90vh] text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-white bg-[#a86aaa] rounded-t-2xl px-2 py-2 text-center"
                  >
                    Full Refund
                  </Dialog.Title>
                  <form onSubmit={refundOrderFunc} className="p-3">
                    <div className="mb-2">
                      <label htmlFor={"reason"} className="block mb-2">
                        {" "}
                        Reason
                      </label>
                      <textarea
                        id={"reason"}
                        className="w-full p-2 rounded bg-app-purple-10"
                        rows={4}
                        value={reason}
                        placeholder={"Reason for the Refund"}
                        onChange={(e) => {
                          setReason(e.target.value);
                        }}
                      ></textarea>
                    </div>
                    <div>
                      <label
                        id={"supplimentaryOrderId"}
                        className=" block mb-2"
                      >
                        {" "}
                        Supplementary Order Id
                      </label>
                      <input
                        id={"supplimentaryOrderId"}
                        className=" w-full p-2 rounded bg-app-purple-10"
                        value={supplimentaryOrderId}
                        placeholder="Order Id of Supplementary order"
                        onChange={(e) => {
                          setSupplementaryOrderId(e.target.value);
                        }}
                      />
                    </div>
                    {supOrderError && (
                      <p className="text-red-500 italic text-center text-sm p-2">
                        Kindly confirm the supplementary order id entered is
                        correct as no order was found for it
                      </p>
                    )}
                    <div className="mt-4 flex justify-around py-3">
                      <button
                        type="button"
                        className="inline-flex justify-center items-center rounded-md border border-transparent bg-red-700 px-4 py-2 text-sm font-medium text-white mr-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={() => {
                          onClose();
                          setSupOrderError(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      >
                        <span>Refund </span>
                        <Spinner
                          className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                          status={
                            singleOrderStatus === FETCH_STATUS.PENDING ||
                            refundOrderStatus === FETCH_STATUS.PENDING
                              ? FETCH_STATUS.PENDING
                              : ""
                          }
                        />
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export { FullRefundModal };
