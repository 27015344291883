import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {API_URL, API_URLV2, axiosApiInstance, axiosApiInstanceGet, FETCH_STATUS,} from "../../utils";

export const getDistributorByFilmReport = createAsyncThunk(
  "getDistributorByFilmReport",
  async ({ force, ...data }, { fulfillWithValue, rejectWithValue }) => {
    let urlFilter = "";
    if (data.FilmId === "allfilms") {
      urlFilter = `CinemaId=${data.CinemaId}&DistributorId=${data.DistributorId}&Page=1&PerPage=100&DateFrom=${data.DateFrom}&DateTo=${data.DateTo}`;
    } else {
      urlFilter = `CinemaId=${data.CinemaId}&DistributorId=${data.DistributorId}&FilmId=${data.FilmId}&Page=1&PerPage=100&DateFrom=${data.DateFrom}&DateTo=${data.DateTo}`;
    }
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Reports/GetTicketSalesReport?${urlFilter}`,
        force
      );

      return fulfillWithValue(response?.data?.data);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getDistributors = createAsyncThunk(
  "getDistributors",
  async (force, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Distributors/ListAll`,
        force
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getDistributorFilms = createAsyncThunk(
  "getDistributorFilms",
  async ({ force, id }, { fulfillWithValue, rejectWithValue }) => {
    let params = "";
    if (id === "allDistributors") {
      params = `page=1&perPage=1000`;
    } else {
      params = `distributorId=${id}&page=1&perPage=1000`;
    }
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Films/ByDistributor?${params}`,
        force
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getItemSalesDetailReport = createAsyncThunk(
  "getItemSalesDetailReport",
  async (
    { force, CinemaId, DateFrom, DateTo, userId, itemId },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Reports/GetItemDetailSalesReport?CinemaId=${CinemaId}&DateFrom=${DateFrom}&DateTo=${DateTo}${
          userId !== "allUsers" ? "&userId=" + userId : ""
        }${itemId !== "allItems" ? "&itemId=" + itemId : ""}`,
        force
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getItemSalesReport = createAsyncThunk(
  "getItemSalesReport",
  async (
    { force, CinemaId, ServiceArea, DateFrom, DateTo },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URLV2}/Reports/GetItemSalesReport?${
          ServiceArea !== "allServiceAreas"
            ? "ServiceArea=" + ServiceArea + "&"
            : ""
        }CinemaId=${CinemaId}&DateFrom=${DateFrom}&DateTo=${DateTo}`,
        force
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getSessionReport = createAsyncThunk(
  "getSessionReport",
  async (
    { force, userId, dateFilter },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URLV2}/Reports/GetSessionReport?userId=${userId}&dateFilter=${dateFilter}`,
        force
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getCinemaSessionReport = createAsyncThunk(
  "getCinemaSessionReport",
  async (
    { force, cinemaId, dateFrom, dateTo },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URLV2}/Reports/GetSessionReportForMultiUsers?cinemaId=${cinemaId}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
        force
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getOrdersByUser = createAsyncThunk(
  "getOrdersbyUser",
  async (
    { force, userId, dateFilter },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Orders/ListAllByUser?userId=${userId}&todayDate=${dateFilter}&page=${1}&perPage=${1000}`,
        force
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const refundOrder = createAsyncThunk(
  "refundOrder",
  async ({ force, orderId, body }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.patch(
        `${API_URL}/Orders/RefundOrder?orderId=${orderId}`,
        { ...body }
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getOrderById = createAsyncThunk(
  "getOrderById",
  async ({ force, orderId }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Orders/${orderId}`,
        force
      );

      return fulfillWithValue(response?.data?.data || {});
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getRefundByOrderId = createAsyncThunk(
  "getRefundByOrderId",
  async (orderId, { fulfillWithValue, rejectWithValue }) => {
    const force = true;
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/RefundReports/GetByOrderId?orderId=${orderId}`,
        force
      );

      return fulfillWithValue(response?.data?.data || {});
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getGBOPerformance = createAsyncThunk(
  "getGBOPerformance",
  async (
    { force, CinemaId, DateFrom, DateTo },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Reports/GetGBOPerformance?CinemaId=${CinemaId}&DateFrom=${DateFrom}&DateTo=${DateTo}`,
        force
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getGBOAdmitsPerformance = createAsyncThunk(
  "getGBOAdmitsPerformance",
  async (
    { force, CinemaId, DateFrom, DateTo },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Reports/GetGBOAdmitsPerformance?CinemaId=${CinemaId}&DateFrom=${DateFrom}&DateTo=${DateTo}`,
        force
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getFilmByScreen = createAsyncThunk(
  "getDistributorByScreen",
  async (
    { force, CinemaId, DateFrom, DateTo },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Reports/GetDistributorByScreenReport?CinemaId=${CinemaId}&DateFrom=${DateFrom}&DateTo=${DateTo}`,
        force
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getPerformanceReport = createAsyncThunk(
  "getPerformanceReport",
  async (
    { force, CinemaId, DateFrom, DateTo },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Reports/GetPerformanceReport?CinemaId=${CinemaId}&DateFrom=${DateFrom}&DateTo=${DateTo}`,
        force
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getMarketingReport = createAsyncThunk(
  "getMarketingReport",
  async (
    { force, CinemaId, DateFrom, DateTo },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Reports/GetMarketingReport?CinemaId=${CinemaId}&DateFrom=${DateFrom}&DateTo=${DateTo}`,
        force
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getDailyCashupReport = createAsyncThunk(
  "getDailyCashupReport",
  async (
    { force, CinemaId, todayDate },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Reports/DailyCashUp?todayDate=${todayDate}&CinemaId=${CinemaId}`,
        force
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getDailyOperationsReport = createAsyncThunk(
  "getDailyOperationsReport",
  async (
    { force, CinemaId, todayDate },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Reports/DailyOperationsReport?todayDate=${todayDate}&CinemaId=${CinemaId}`,
        force
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);
export const getDailyOperationsRevReport = createAsyncThunk(
  "getDailyOperationsRevReport",
  async (
    { force, CinemaId, todayDate },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Reports/DailyOperationsRevenueReport?todayDate=${todayDate}&CinemaId=${CinemaId}`,
        force
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getDailyOperationsStatReport = createAsyncThunk(
  "getDailyOperationsStatReport",
  async (
    { force, CinemaId, todayDate },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Reports/DailyOperationsStatisticsReport?todayDate=${todayDate}&CinemaId=${CinemaId}`,
        force
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getAdminActionsByUser = createAsyncThunk(
  "getAdminActionsByUser",
  async (
    { force, userId, todayDate },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/AdminActions/ByUser?userId=${userId}&todayDate=${todayDate}`,
        force
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getAllSources = createAsyncThunk(
  "getAllSources",
  async (force, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Sources/ListAll`,
        force
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getAllChannels = createAsyncThunk(
  "getAllChannels",
  async (force, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Channels/ListAll`,
        force
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getOrdersBySourceAndChannel = createAsyncThunk(
  "getOrdersBySourceAndChannel",
  async (
    { force, dateFrom, dateTo, cinemaId, sourceId, channelId },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Reports/OrdersBySourceChannel?DateFrom=${dateFrom}&DateTo=${dateTo}${
          sourceId === "allSources" ? "" : "&SourceId=" + sourceId
        }${
          channelId === "allChannels" ? "" : "&ChannelId=" + channelId
        }&CinemaId=${cinemaId}`,
        force
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getOnlineOrders = createAsyncThunk(
  "getOnlineOrders",
  async (
    { force, dateFrom, dateTo, cinemaId },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URLV2}/Bookings/ListAll?DateFrom=${dateFrom}&DateTo=${dateTo}&cinemaId=${cinemaId}&page=1&perPage=2000`,
        force
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getTicketSoldData = createAsyncThunk(
  "getTicketSoldData",
  async (
    { force, dateFrom, dateTo, cinemaId },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URLV2}/Reports/GetTicketSalesReportFilter?DateFrom=${dateFrom}&DateTo=${dateTo}&cinemaId=${cinemaId}`,
        force
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const rollbackRedeem = createAsyncThunk(
  "rollbackRedeem",
  async ({ force, bookingId }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.patch(
        `${API_URL}/Bookings/RollbackRedeem?bookingId=${bookingId}`,
        force
      );

      return fulfillWithValue(response?.data?.data || "");
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getScheduledReports = createAsyncThunk(
  "getScheduledReports",
  async (cinemaId, { fulfillWithValue, rejectWithValue }) => {
    const force = true;
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/scheduledReports/ListAll?cinemaId=${cinemaId}&page=1&perPage=1000`,
        force
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getScheduledReportById = createAsyncThunk(
  "getScheduledReportById",
  async (scheduledReportId, { fulfillWithValue, rejectWithValue }) => {
    const force = true;
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/scheduledReports/GetById?scheduledReportId=${scheduledReportId}`,
        force
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getReportTypes = createAsyncThunk(
  "getReportType",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/scheduledReports/ReportTypes`
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getReportFrequencyType = createAsyncThunk(
  "getReportFrequencyType",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/scheduledReports/ReportFrequencyType`
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getReportFileFormats = createAsyncThunk(
  "getReportFileFormats",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/scheduledReports/ReportFileFormats`
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const createScheduledReport = createAsyncThunk(
  "createScheduledReport",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/scheduledReports/create`,
        { ...data }
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const updateScheduledReport = createAsyncThunk(
  "updateScheduledReport",
  async ({ id, data }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.patch(
        `${API_URL}/scheduledReports/update?scheduledReportId=${id}`,
        { ...data }
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getGBOByTicketsReport = createAsyncThunk(
  "getGBOByTicketsReport",
  async (
    { cinemaId, filmId, distributorId, dateFrom, dateTo },
    { fulfillWithValue, rejectWithValue }
  ) => {
    
    let params = "";
    if (filmId === "allFilms" && distributorId === "allDistributors") {
      params = `CinemaId=${cinemaId}&DateFrom=${dateFrom}&DateTo=${dateTo}`;
    }
    if (filmId === "allFilms"&&distributorId!=="allDistributors") {
      params = `CinemaId=${cinemaId}&DistributorId=${distributorId}&DateFrom=${dateFrom}&DateTo=${dateTo}`;
    }
    if (distributorId === "allDistributors"&&filmId!=="allFilms") {
      params = `CinemaId=${cinemaId}&FilmId=${filmId}&DateFrom=${dateFrom}&DateTo=${dateTo}`;
    }
    if (distributorId !== "allDistributors" && filmId !== "allFilms") {
      
      params = `CinemaId=${cinemaId}&FilmId=${filmId}&DistributorId=${distributorId}&DateFrom=${dateFrom}&DateTo=${dateTo}`;
    }
    try {
      const res = await axiosApiInstanceGet(
        `${API_URL}/Reports/GBOByTicketsReport?${params}`
      );
      return fulfillWithValue(res?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getGBOByScreenFormat = createAsyncThunk(
  "getGBOByScreenFormat",
  async (
    { screenFormatId, dateFrom, dateTo, cinemaId },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const res = await axiosApiInstance.get(
        `${API_URL}/Reports/GBOByFilmFormatReport?CinemaId=${cinemaId}&DateFrom=${dateFrom}&DateTo=${dateTo}${
          screenFormatId !== "allFormats"
            ? `&filmFormatId=${screenFormatId}`
            : ""
        }`
      );
      return fulfillWithValue(res?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getShowtimeScheduleReport = createAsyncThunk(
  "getShowtimeScheduleReport",
  async ({ cinemaId, todayDate }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const res = await axiosApiInstanceGet(
        `${API_URL}/Reports/GetShowtimeSchedule?todayDate=${todayDate}&cinemaId=${cinemaId}`
      );
      return fulfillWithValue(res?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getGBOFilmReports = createAsyncThunk(
  "getGBOFilmReports",
  async (
    { cinemaId, startDate, endDate, sourceId,filmId,distributorId, includeComp },
    { fulfillWithValue, rejectWithValue }
  ) => {
    
    try {
      const res = await axiosApiInstanceGet(
        `${API_URL}/Reports/GetGBOFilmReports?CinemaId=${cinemaId}&StartDate=${startDate}&EndDate=${endDate}${
          sourceId === "allSources" ? "" : "&SourceId=" + sourceId
        }&IncludeComp=${includeComp}${filmId==="allFilms"?"":"&FilmId="+filmId}${distributorId==="allDistributors"?"":"&DistributorId="+distributorId}`
      );
      return fulfillWithValue(res?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getBookingsByGenre = createAsyncThunk(
  "getBookingsByGenre",
  async (
    { cinemaId, dateFrom, dateTo, genreIds },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await axiosApiInstance.patch(
        `${API_URL}/Bookings/ListAllByGenre?cinemaId=${cinemaId}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
        { genreIds }
      );
      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getPerformanceByShowtime = createAsyncThunk(
  "getPerformanceByShowtime",
  async ({ todayDate, cinemaId }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Reports/PerformanceByShowtime?todayDate=${todayDate}&cinemaId=${cinemaId}`
      );
      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);
export const getPerformanceByFilm = createAsyncThunk(
  "getPerformanceByFilm",
  async (
    { dateFrom, dateTo, cinemaId },
    { fulfillWithValue, rejectWithValue }
  ) => {
    const force = true;
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Reports/PerformanceByFilm?DateFrom=${dateFrom}&DateTo=${dateTo}&cinemaId=${cinemaId}`,
        force
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getStaffPerformance = createAsyncThunk(
  "getStaffPerformance",
  async (
    { cinemaId, dateFrom, dateTo },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const force = true;
      const response = await axiosApiInstanceGet(
        `${API_URL}/Reports/GetStaffPerformanceReport?DateFrom=${dateFrom}&DateTo=${dateTo}&cinemaId=${cinemaId}`,
        force
      );
      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

const initialState = {
  distributorByFilmReport: {},
  distributorByFilmReportStatus: FETCH_STATUS.IDLE,
  distributorByFilmReportErr: null,
  distributorsList: [],
  distributorsListStatus: FETCH_STATUS.IDLE,
  distributorsListErr: null,
  distributorFilms: [],
  distributorFilmsStatus: FETCH_STATUS.IDLE,
  distributorFilmsErr: null,
  itemSalesDetailReport: {},
  itemSalesDetailReportStatus: FETCH_STATUS.IDLE,
  itemSalesDetailReportErr: null,
  itemSalesReport: {},
  itemSalesReportStatus: FETCH_STATUS.IDLE,
  itemSalesReportErr: null,
  sessionReport: {},
  sessionReportStatus: FETCH_STATUS.IDLE,
  sessionReportErr: null,
  cinemaSessionReport: {},
  cinemaSessionReportStatus: FETCH_STATUS.IDLE,
  cinemaSessionReportErr: null,
  gboPerformance: {},
  gboPerformanceStatus: FETCH_STATUS.IDLE,
  gboPerformanceErr: null,
  gboAdmitsPerformance: {},
  gboAdmitsPerformanceStatus: FETCH_STATUS.IDLE,
  gboAdmitsPerformanceErr: null,
  filmByScreen: {},
  filmByScreenStatus: FETCH_STATUS.IDLE,
  filmByScreenErr: null,
  performanceReport: {},
  performanceReportStatus: FETCH_STATUS.IDLE,
  performanceReportErr: null,
  marketingReport: {},
  marketingReportStatus: FETCH_STATUS.IDLE,
  marketingReportErr: null,
  ordersByUser: [],
  ordersByUserErr: null,
  ordersByUserStatus: FETCH_STATUS.IDLE,
  refundedOrder: "",
  refundOrderErr: null,
  refundOrderStatus: FETCH_STATUS.IDLE,
  singleOrder: {},
  singleOrderErr: null,
  singleOrderStatus: FETCH_STATUS.IDLE,
  dailyCashupReport: {},
  dailyCashupReportErr: null,
  dailyCashupReportStatus: FETCH_STATUS.IDLE,
  dailyOperationsReport: {},
  dailyOperationsReportErr: null,
  dailyOperationsReportStatus: FETCH_STATUS.IDLE,
  dailyOperationsRevReport: {},
  dailyOperationsRevReportErr: null,
  dailyOperationsRevReportStatus: FETCH_STATUS.IDLE,
  dailyOperationsStatReport: {},
  dailyOperationsStatReportErr: null,
  dailyOperationsStatReportStatus: FETCH_STATUS.IDLE,
  adminActionsByUser: {},
  adminActionsByUserErr: null,
  adminActionsByUserStatus: FETCH_STATUS.IDLE,
  channels: [],
  channelsErr: null,
  channelsStatus: FETCH_STATUS.IDLE,
  sources: [],
  sourcesErr: null,
  sourcesStatus: FETCH_STATUS.IDLE,
  ordersBySourceAndChannel: {},
  ordersBySourceAndChannelErr: null,
  ordersBySourceAndChannelStatus: FETCH_STATUS.IDLE,
  onlineOrders: {},
  onlineOrdersErr: null,
  onlineOrdersStatus: FETCH_STATUS.IDLE,
  ticketSold: {},
  ticketSoldErr: null,
  ticketSoldStatus: FETCH_STATUS.IDLE,
  rollbackRedeemResp: "",
  rollbackRedeemErr: null,
  rollbackRedeemStatus: FETCH_STATUS.IDLE,
  scheduledReportsList: [],
  scheduledReportsListErr: null,
  scheduledReportsListStatus: FETCH_STATUS.IDLE,
  scheduledReport: {},
  scheduledReportErr: null,
  scheduledReportStatus: FETCH_STATUS.IDLE,
  reportTypes: [],
  reportTypesErr: null,
  reportTypesStatus: FETCH_STATUS.IDLE,
  reportFileFormats: [],
  reportFileFormatsErr: null,
  reportFileFormatsStatus: FETCH_STATUS.IDLE,
  reportFrequencyTypes: [],
  reportFrequencyTypesErr: null,
  reportFrequencyTypesStatus: FETCH_STATUS.IDLE,
  createScheduleErr: null,
  createScheduleStatus: FETCH_STATUS.IDLE,
  updateScheduleErr: null,
  updateScheduleStatus: FETCH_STATUS.IDLE,
  GBOByTickets: {},
  GBOByTicketsStatus: FETCH_STATUS.IDLE,
  GBOByTicketsErr: null,
  showtimesReport: [],
  showtimesReportStatus: FETCH_STATUS.IDLE,
  showtimesReportErr: null,
  GBOFilmReports: [],
  GBOFilmReportsErr: null,
  GBOFilmReportsStatus: FETCH_STATUS.IDLE,
  bookingsByGenre: [],
  bookingsByGenreStatus: FETCH_STATUS.IDLE,
  bookingsByGenreErr: null,
  performanceByFilm: [],
  performanceByFilmStatus: FETCH_STATUS.IDLE,
  performanceByFilmErr: null,
  performanceByShowtime: [],
  performanceByShowtimeStatus: FETCH_STATUS.IDLE,
  performanceByShowtimeErr: null,
  staffPerformance: [],
  getStaffPerformanceStatus: FETCH_STATUS.IDLE,
  getStaffPerformanceErr: null,
  GBOByScreenFormat: [],
  GBOByScreenFormatErr: null,
  GBOByScreenFormatStatus: FETCH_STATUS.IDLE,
};

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    // addScreen: (state, action) => {
    //   state.distributorByFilmReport = addItemDirect(state, action, "distributorByFilmReport");
    // },
    // editScreen: (state, action) => {
    //   editItemDirect(state, action, "distributorByFilmReport");
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDistributorByFilmReport.pending, (state) => {
        state.distributorByFilmReportStatus = FETCH_STATUS.PENDING;
        state.distributorByFilmReportErr = "";
      })
      .addCase(getDistributorByFilmReport.fulfilled, (state, action) => {
        state.distributorByFilmReportStatus = FETCH_STATUS.RESOLVED;
        state.distributorByFilmReportErr = null;
        state.distributorByFilmReport = action.payload;
      })
      .addCase(getDistributorByFilmReport.rejected, (state, action) => {
        state.distributorByFilmReportStatus = FETCH_STATUS.REJECTED;
        state.distributorByFilmReportErr =
          action.payload || "Unable to retrieve distributor By Film Report";
      })
      .addCase(getDistributors.pending, (state) => {
        state.distributorsListStatus = FETCH_STATUS.PENDING;
        state.distributorsListErr = "";
      })
      .addCase(getDistributors.fulfilled, (state, action) => {
        state.distributorsListStatus = FETCH_STATUS.RESOLVED;
        state.distributorsListErr = null;
        state.distributorsList = action.payload;
      })
      .addCase(getDistributors.rejected, (state, action) => {
        state.distributorsListStatus = FETCH_STATUS.REJECTED;
        state.distributorsListErr =
          action.payload || "Unable to retrieve distributors";
      })
      .addCase(getDistributorFilms.pending, (state) => {
        state.distributorFilmsStatus = FETCH_STATUS.PENDING;
        state.distributorFilmsErr = "";
      })
      .addCase(getDistributorFilms.fulfilled, (state, action) => {
        state.distributorFilmsStatus = FETCH_STATUS.RESOLVED;
        state.distributorFilmsErr = null;
        state.distributorFilms = action.payload;
      })
      .addCase(getDistributorFilms.rejected, (state, action) => {
        state.distributorFilmsStatus = FETCH_STATUS.REJECTED;
        state.distributorFilmsErr =
          action.payload || "Unable to retrieve distributor films";
      })
      .addCase(getItemSalesDetailReport.pending, (state) => {
        state.itemSalesDetailReportStatus = FETCH_STATUS.PENDING;
        state.itemSalesDetailReportErr = "";
      })
      .addCase(getItemSalesDetailReport.fulfilled, (state, action) => {
        state.itemSalesDetailReportStatus = FETCH_STATUS.RESOLVED;
        state.itemSalesDetailReportErr = null;
        state.itemSalesDetailReport = action.payload;
      })
      .addCase(getItemSalesDetailReport.rejected, (state, action) => {
        state.itemSalesDetailReportStatus = FETCH_STATUS.REJECTED;
        state.itemSalesDetailReportErr =
          action.payload || "Unable to retrieve item sales detail report";
      })
      .addCase(getItemSalesReport.pending, (state) => {
        state.itemSalesReportStatus = FETCH_STATUS.PENDING;
        state.itemSalesReportErr = "";
      })
      .addCase(getItemSalesReport.fulfilled, (state, action) => {
        state.itemSalesReportStatus = FETCH_STATUS.RESOLVED;
        state.itemSalesReportErr = null;
        state.itemSalesReport = action.payload;
      })
      .addCase(getItemSalesReport.rejected, (state, action) => {
        state.itemSalesReportStatus = FETCH_STATUS.REJECTED;
        state.itemSalesReportErr =
          action.payload || "Unable to retrieve item sales report";
      })
      .addCase(getSessionReport.pending, (state) => {
        state.sessionReportStatus = FETCH_STATUS.PENDING;
        state.sessionReportErr = "";
      })
      .addCase(getSessionReport.fulfilled, (state, action) => {
        state.sessionReportStatus = FETCH_STATUS.RESOLVED;
        state.sessionReportErr = null;
        state.sessionReport = action.payload;
      })
      .addCase(getSessionReport.rejected, (state, action) => {
        state.sessionReportStatus = FETCH_STATUS.REJECTED;
        state.sessionReportErr =
          action.payload || "Unable to retrieve session report";
      })
      .addCase(getCinemaSessionReport.pending, (state) => {
        state.cinemaSessionReportStatus = FETCH_STATUS.PENDING;
        state.cinemaSessionReportErr = "";
      })
      .addCase(getCinemaSessionReport.fulfilled, (state, action) => {
        state.cinemaSessionReportStatus = FETCH_STATUS.RESOLVED;
        state.cinemaSessionReportErr = null;
        state.cinemaSessionReport = action.payload;
      })
      .addCase(getCinemaSessionReport.rejected, (state, action) => {
        state.cinemaSessionReportStatus = FETCH_STATUS.REJECTED;
        state.cinemaSessionReportErr =
          action.payload || "Unable to retrieve cinema session report";
      })
      .addCase(getGBOPerformance.pending, (state) => {
        state.gboPerformanceStatus = FETCH_STATUS.PENDING;
        state.gboPerformanceErr = "";
      })
      .addCase(getGBOPerformance.fulfilled, (state, action) => {
        state.gboPerformanceStatus = FETCH_STATUS.RESOLVED;
        state.gboPerformanceErr = null;
        state.gboPerformance = action.payload;
      })
      .addCase(getGBOPerformance.rejected, (state, action) => {
        state.gboPerformanceStatus = FETCH_STATUS.REJECTED;
        state.gboPerformanceErr =
          action.payload || "Unable to retrieve GBO Performance report";
      })
      .addCase(getGBOAdmitsPerformance.pending, (state) => {
        state.gboAdmitsPerformanceStatus = FETCH_STATUS.PENDING;
        state.gboAdmitsPerformanceErr = "";
      })
      .addCase(getGBOAdmitsPerformance.fulfilled, (state, action) => {
        state.gboAdmitsPerformanceStatus = FETCH_STATUS.RESOLVED;
        state.gboAdmitsPerformanceErr = null;
        state.gboAdmitsPerformance = action.payload;
      })
      .addCase(getGBOAdmitsPerformance.rejected, (state, action) => {
        state.gboAdmitsPerformanceStatus = FETCH_STATUS.REJECTED;
        state.gboAdmitsPerformanceErr =
          action.payload || "Unable to retrieve GBO Performance report";
      })
      .addCase(getFilmByScreen.pending, (state) => {
        state.filmByScreenStatus = FETCH_STATUS.PENDING;
        state.filmByScreenErr = "";
      })
      .addCase(getFilmByScreen.fulfilled, (state, action) => {
        state.filmByScreenStatus = FETCH_STATUS.RESOLVED;
        state.filmByScreenErr = null;
        state.filmByScreen = action.payload;
      })
      .addCase(getFilmByScreen.rejected, (state, action) => {
        state.filmByScreenStatus = FETCH_STATUS.REJECTED;
        state.filmByScreenErr =
          action.payload || "Unable to retrieve Film by screen report";
      })
      .addCase(getPerformanceReport.pending, (state) => {
        state.performanceReportStatus = FETCH_STATUS.PENDING;
        state.performanceReportErr = "";
      })
      .addCase(getPerformanceReport.fulfilled, (state, action) => {
        state.performanceReportStatus = FETCH_STATUS.RESOLVED;
        state.performanceReportErr = null;
        state.performanceReport = action.payload;
      })
      .addCase(getPerformanceReport.rejected, (state, action) => {
        state.performanceReportStatus = FETCH_STATUS.REJECTED;
        state.performanceReportErr =
          action.payload || "Unable to retrieve Performance Report";
      })
      .addCase(getMarketingReport.pending, (state) => {
        state.marketingReportStatus = FETCH_STATUS.PENDING;
        state.marketingReportErr = "";
      })
      .addCase(getMarketingReport.fulfilled, (state, action) => {
        state.marketingReportStatus = FETCH_STATUS.RESOLVED;
        state.marketingReportErr = null;
        state.marketingReport = action.payload;
      })
      .addCase(getMarketingReport.rejected, (state, action) => {
        state.marketingReportStatus = FETCH_STATUS.REJECTED;
        state.marketingReportErr =
          action.payload || "Unable to retrieve marketing Report";
      })
      .addCase(getOrdersByUser.pending, (state) => {
        state.ordersByUserStatus = FETCH_STATUS.PENDING;
        state.ordersByUserErr = "";
      })
      .addCase(getOrdersByUser.fulfilled, (state, action) => {
        state.ordersByUserStatus = FETCH_STATUS.RESOLVED;
        state.ordersByUserErr = null;
        state.ordersByUser = action.payload;
      })
      .addCase(getOrdersByUser.rejected, (state, action) => {
        state.ordersByUserStatus = FETCH_STATUS.REJECTED;
        state.ordersByUserErr =
          action.payload || "Unable to retrieve Orders by users";
      })
      .addCase(refundOrder.pending, (state) => {
        state.refundOrderStatus = FETCH_STATUS.PENDING;
        state.refundOrderErr = "";
      })
      .addCase(refundOrder.fulfilled, (state, action) => {
        state.refundOrderStatus = FETCH_STATUS.RESOLVED;
        state.refundOrderErr = null;
        state.refundedOrder = action.payload;
      })
      .addCase(refundOrder.rejected, (state, action) => {
        state.refundOrderStatus = FETCH_STATUS.REJECTED;
        state.refundOrderErr = action.payload || "Unable to Refund Order";
      })
      .addCase(getOrderById.pending, (state) => {
        state.singleOrderStatus = FETCH_STATUS.PENDING;
        state.singleOrderErr = "";
      })
      .addCase(getOrderById.fulfilled, (state, action) => {
        state.singleOrderStatus = FETCH_STATUS.RESOLVED;
        state.singleOrderErr = null;
        state.singleOrder = action.payload;
      })
      .addCase(getOrderById.rejected, (state, action) => {
        state.singleOrderStatus = FETCH_STATUS.REJECTED;
        state.singleOrderErr = action.payload || "Unable to get Order";
      })
      .addCase(getRefundByOrderId.pending, (state) => {
        state.singleOrderStatus = FETCH_STATUS.PENDING;
        state.singleOrderErr = "";
      })
      .addCase(getRefundByOrderId.fulfilled, (state, action) => {
        state.singleOrderStatus = FETCH_STATUS.RESOLVED;
        state.singleOrderErr = null;
        state.singleOrder = action.payload;
      })
      .addCase(getRefundByOrderId.rejected, (state, action) => {
        state.singleOrderStatus = FETCH_STATUS.REJECTED;
        state.singleOrderErr = action.payload || "Unable to Refund Order";
      })
      .addCase(getDailyCashupReport.pending, (state) => {
        state.dailyCashupReportStatus = FETCH_STATUS.PENDING;
        state.dailyCashupReportErr = "";
      })
      .addCase(getDailyCashupReport.fulfilled, (state, action) => {
        state.dailyCashupReportStatus = FETCH_STATUS.RESOLVED;
        state.dailyCashupReportErr = null;
        state.dailyCashupReport = action.payload;
      })
      .addCase(getDailyCashupReport.rejected, (state, action) => {
        state.dailyCashupReportStatus = FETCH_STATUS.REJECTED;
        state.dailyCashupReportErr =
          action.payload || "Unable to fetch Daily Cashup Report";
      })
      .addCase(getDailyOperationsReport.pending, (state) => {
        state.dailyOperationsReportStatus = FETCH_STATUS.PENDING;
        state.dailyOperationsReportErr = "";
      })
      .addCase(getDailyOperationsReport.fulfilled, (state, action) => {
        state.dailyOperationsReportStatus = FETCH_STATUS.RESOLVED;
        state.dailyOperationsReportErr = null;
        state.dailyOperationsReport = action.payload;
      })
      .addCase(getDailyOperationsReport.rejected, (state, action) => {
        state.dailyOperationsReportStatus = FETCH_STATUS.REJECTED;
        state.dailyOperationsReportErr =
          action.payload || "Unable to fetch Daily Operations Report";
      })
      .addCase(getDailyOperationsRevReport.pending, (state) => {
        state.dailyOperationsRevReportStatus = FETCH_STATUS.PENDING;
        state.dailyOperationsRevReportErr = "";
      })
      .addCase(getDailyOperationsRevReport.fulfilled, (state, action) => {
        state.dailyOperationsRevReportStatus = FETCH_STATUS.RESOLVED;
        state.dailyOperationsRevReportErr = null;
        state.dailyOperationsRevReport = action.payload;
      })
      .addCase(getDailyOperationsRevReport.rejected, (state, action) => {
        state.dailyOperationsRevReportStatus = FETCH_STATUS.REJECTED;
        state.dailyOperationsRevReportErr =
          action.payload || "Unable to fetch Daily Operations Report Revenue";
      })
      .addCase(getDailyOperationsStatReport.pending, (state) => {
        state.dailyOperationsStatReportStatus = FETCH_STATUS.PENDING;
        state.dailyOperationsStatReportErr = "";
      })
      .addCase(getDailyOperationsStatReport.fulfilled, (state, action) => {
        state.dailyOperationsStatReportStatus = FETCH_STATUS.RESOLVED;
        state.dailyOperationsStatReportErr = null;
        state.dailyOperationsStatReport = action.payload;
      })
      .addCase(getDailyOperationsStatReport.rejected, (state, action) => {
        state.dailyOperationsStatReportStatus = FETCH_STATUS.REJECTED;
        state.dailyOperationsStatReportErr =
          action.payload ||
          "Unable to fetch Daily Operations Statistics Revenue";
      })
      .addCase(getAdminActionsByUser.pending, (state) => {
        state.adminActionsByUserStatus = FETCH_STATUS.PENDING;
        state.adminActionsByUserErr = "";
      })
      .addCase(getAdminActionsByUser.fulfilled, (state, action) => {
        state.adminActionsByUserStatus = FETCH_STATUS.RESOLVED;
        state.adminActionsByUserErr = null;
        state.adminActionsByUser = action.payload;
      })
      .addCase(getAdminActionsByUser.rejected, (state, action) => {
        state.adminActionsByUserStatus = FETCH_STATUS.REJECTED;
        state.adminActionsByUserErr =
          action.payload || "Unable to fetch admin actions by user";
      })
      .addCase(getAllChannels.pending, (state) => {
        state.channelsStatus = FETCH_STATUS.PENDING;
        state.channelsErr = "";
      })
      .addCase(getAllChannels.fulfilled, (state, action) => {
        state.channelsStatus = FETCH_STATUS.RESOLVED;
        state.channelsErr = null;
        state.channels = action.payload;
      })
      .addCase(getAllChannels.rejected, (state, action) => {
        state.channelsStatus = FETCH_STATUS.REJECTED;
        state.channelsErr = action.payload || "Unable to fetch channels";
      })
      .addCase(getAllSources.pending, (state) => {
        state.sourcesStatus = FETCH_STATUS.PENDING;
        state.sourcesErr = "";
      })
      .addCase(getAllSources.fulfilled, (state, action) => {
        state.sourcesStatus = FETCH_STATUS.RESOLVED;
        state.sourcesErr = null;
        state.sources = action.payload;
      })
      .addCase(getAllSources.rejected, (state, action) => {
        state.sourcesStatus = FETCH_STATUS.REJECTED;
        state.sourcesErr = action.payload || "Unable to fetch sources";
      })
      .addCase(getOrdersBySourceAndChannel.pending, (state) => {
        state.ordersBySourceAndChannelStatus = FETCH_STATUS.PENDING;
        state.ordersBySourceAndChannelErr = "";
      })
      .addCase(getOrdersBySourceAndChannel.fulfilled, (state, action) => {
        state.ordersBySourceAndChannelStatus = FETCH_STATUS.RESOLVED;
        state.ordersBySourceAndChannelErr = null;
        state.ordersBySourceAndChannel = action.payload;
      })
      .addCase(getOrdersBySourceAndChannel.rejected, (state, action) => {
        state.ordersBySourceAndChannelStatus = FETCH_STATUS.REJECTED;
        state.ordersBySourceAndChannelErr =
          action.payload || "Unable to fetch orders by source and channel";
      })
      .addCase(getOnlineOrders.pending, (state) => {
        state.onlineOrdersStatus = FETCH_STATUS.PENDING;
        state.onlineOrdersErr = "";
      })
      .addCase(getOnlineOrders.fulfilled, (state, action) => {
        state.onlineOrdersStatus = FETCH_STATUS.RESOLVED;
        state.onlineOrdersErr = null;
        state.onlineOrders = action.payload;
      })
      .addCase(getOnlineOrders.rejected, (state, action) => {
        state.onlineOrdersStatus = FETCH_STATUS.REJECTED;
        state.onlineOrdersErr =
          action.payload || "Unable to fetch online orders";
      })
      .addCase(getTicketSoldData.pending, (state) => {
        state.ticketSoldStatus = FETCH_STATUS.PENDING;
        state.ticketSoldErr = "";
      })
      .addCase(getTicketSoldData.fulfilled, (state, action) => {
        state.ticketSoldStatus = FETCH_STATUS.RESOLVED;
        state.ticketSoldErr = null;
        state.ticketSold = action.payload;
      })
      .addCase(getTicketSoldData.rejected, (state, action) => {
        state.ticketSoldStatus = FETCH_STATUS.REJECTED;
        state.ticketSoldErr = action.payload || "Unable to fetch tickets sold";
      })
      .addCase(rollbackRedeem.pending, (state) => {
        state.rollbackRedeemStatus = FETCH_STATUS.PENDING;
        state.rollbackRedeemErr = "";
      })
      .addCase(rollbackRedeem.fulfilled, (state, action) => {
        state.rollbackRedeemStatus = FETCH_STATUS.RESOLVED;
        state.rollbackRedeemErr = null;
        state.rollbackRedeemResp = action.payload;
      })
      .addCase(rollbackRedeem.rejected, (state, action) => {
        state.rollbackRedeemStatus = FETCH_STATUS.REJECTED;
        state.rollbackRedeemErr =
          action.payload || "Unable to fetch online orders";
      })
      .addCase(getScheduledReports.pending, (state) => {
        state.scheduledReportsListStatus = FETCH_STATUS.PENDING;
        state.scheduledReportsListErr = "";
      })
      .addCase(getScheduledReports.fulfilled, (state, action) => {
        state.scheduledReportsListStatus = FETCH_STATUS.RESOLVED;
        state.scheduledReportsListErr = null;
        state.scheduledReportsList = action.payload.pagedList;
      })
      .addCase(getScheduledReports.rejected, (state, action) => {
        state.scheduledReportsListStatus = FETCH_STATUS.REJECTED;
        state.scheduledReportsListErr =
          action.payload || "Unable to fetch scheduled reports";
      })
      .addCase(getScheduledReportById.pending, (state) => {
        state.scheduledReportStatus = FETCH_STATUS.PENDING;
        state.scheduledReportErr = "";
      })
      .addCase(getScheduledReportById.fulfilled, (state, action) => {
        state.scheduledReportStatus = FETCH_STATUS.RESOLVED;
        state.scheduledReportErr = null;
        state.scheduledReport = action.payload;
      })
      .addCase(getScheduledReportById.rejected, (state, action) => {
        state.scheduledReportStatus = FETCH_STATUS.REJECTED;
        state.scheduledReportErr =
          action.payload || "Unable to fetch scheduled Report";
      })
      .addCase(getReportTypes.pending, (state) => {
        state.reportTypesStatus = FETCH_STATUS.PENDING;
        state.reportTypesErr = "";
      })
      .addCase(getReportTypes.fulfilled, (state, action) => {
        state.reportTypesStatus = FETCH_STATUS.RESOLVED;
        state.reportTypesErr = null;
        state.reportTypes = action.payload;
      })
      .addCase(getReportTypes.rejected, (state, action) => {
        state.reportTypesStatus = FETCH_STATUS.REJECTED;
        state.reportTypesErr =
          action.payload || "Unable to fetch scheduled Report";
      })
      .addCase(getReportFrequencyType.pending, (state) => {
        state.reportFrequencyTypesStatus = FETCH_STATUS.PENDING;
        state.reportFrequencyTypesErr = "";
      })
      .addCase(getReportFrequencyType.fulfilled, (state, action) => {
        state.reportFrequencyTypesStatus = FETCH_STATUS.RESOLVED;
        state.reportFrequencyTypesErr = null;
        state.reportFrequencyTypes = action.payload;
      })
      .addCase(getReportFrequencyType.rejected, (state, action) => {
        state.reportFrequencyTypesStatus = FETCH_STATUS.REJECTED;
        state.reportFrequencyTypesErr =
          action.payload || "Unable to fetch report frequency types";
      })
      .addCase(getReportFileFormats.pending, (state) => {
        state.reportFileFormatsStatus = FETCH_STATUS.PENDING;
        state.reportFileFormatsErr = "";
      })
      .addCase(getReportFileFormats.fulfilled, (state, action) => {
        state.reportFileFormatsStatus = FETCH_STATUS.RESOLVED;
        state.reportFileFormatsErr = null;
        state.reportFileFormats = action.payload;
      })
      .addCase(getReportFileFormats.rejected, (state, action) => {
        state.reportFileFormatsStatus = FETCH_STATUS.REJECTED;
        state.reportFileFormatsErr =
          action.payload || "Unable to fetch report file formats";
      })
      .addCase(createScheduledReport.pending, (state) => {
        state.createScheduleStatus = FETCH_STATUS.PENDING;
        state.createScheduleErr = "";
      })
      .addCase(createScheduledReport.fulfilled, (state, action) => {
        state.createScheduleStatus = FETCH_STATUS.RESOLVED;
        state.createScheduleErr = null;
      })
      .addCase(createScheduledReport.rejected, (state, action) => {
        state.createScheduleStatus = FETCH_STATUS.REJECTED;
        state.createScheduleErr =
          action.payload || "Unable to create schedule form";
      })
      .addCase(updateScheduledReport.pending, (state) => {
        state.updateScheduleStatus = FETCH_STATUS.PENDING;
        state.updateScheduleErr = "";
      })
      .addCase(updateScheduledReport.fulfilled, (state, action) => {
        state.updateScheduleStatus = FETCH_STATUS.RESOLVED;
        state.updateScheduleErr = null;
      })
      .addCase(updateScheduledReport.rejected, (state, action) => {
        state.updateScheduleStatus = FETCH_STATUS.REJECTED;
        state.updateScheduleErr =
          action.payload || "Unable to update schedule form";
      })

      .addCase(getGBOByTicketsReport.pending, (state) => {
        state.GBOByTicketsStatus = FETCH_STATUS.PENDING;
        state.GBOByTicketsErr = "";
      })
      .addCase(getGBOByTicketsReport.fulfilled, (state, action) => {
        state.GBOByTicketsStatus = FETCH_STATUS.RESOLVED;
        state.GBOByTickets = action.payload;
        state.GBOByTicketsErr = null;
      })
      .addCase(getGBOByTicketsReport.rejected, (state, action) => {
        state.GBOByTicketsStatus = FETCH_STATUS.REJECTED;
        state.GBOByTicketsErr =
          action.payload || "Unable to update schedule form";
      })

      .addCase(getShowtimeScheduleReport.pending, (state) => {
        state.showtimesReportStatus = FETCH_STATUS.PENDING;
        state.showtimesReportErr = "";
      })
      .addCase(getShowtimeScheduleReport.fulfilled, (state, action) => {
        state.showtimesReportStatus = FETCH_STATUS.RESOLVED;
        state.showtimesReport = action.payload;
        state.showtimesReportErr = null;
      })
      .addCase(getShowtimeScheduleReport.rejected, (state, action) => {
        state.showtimesReportStatus = FETCH_STATUS.REJECTED;
        state.showtimesReportErr =
          action.payload || "Unable to fetch showtime schedule report";
      })

      .addCase(getGBOFilmReports.pending, (state) => {
        state.GBOFilmReportsStatus = FETCH_STATUS.PENDING;
        state.showtimesReportErr = "";
      })
      .addCase(getGBOFilmReports.fulfilled, (state, action) => {
        state.GBOFilmReportsStatus = FETCH_STATUS.RESOLVED;
        state.GBOFilmReports = action.payload;
        state.GBOFilmReportsErr = null;
      })
      .addCase(getGBOFilmReports.rejected, (state, action) => {
        state.GBOFilmReportsStatus = FETCH_STATUS.REJECTED;
        state.GBOFilmReportsErr =
          action.payload || "Unable to fetch GBO Films report";
      })
      .addCase(getBookingsByGenre.pending, (state) => {
        state.bookingsByGenreStatus = FETCH_STATUS.PENDING;
        state.bookingsByGenreErr = "";
      })
      .addCase(getBookingsByGenre.fulfilled, (state, action) => {
        state.bookingsByGenreStatus = FETCH_STATUS.RESOLVED;
        state.bookingsByGenre = action.payload;
        state.bookingsByGenreErr = null;
      })
      .addCase(getBookingsByGenre.rejected, (state, action) => {
        state.bookingsByGenreStatus = FETCH_STATUS.REJECTED;
        state.bookingsByGenreErr =
          action.payload || "Unable to fetch bookings by genre";
      })
      .addCase(getPerformanceByFilm.pending, (state) => {
        state.performanceByFilmStatus = FETCH_STATUS.PENDING;
        state.performanceByFilmErr = "";
      })
      .addCase(getPerformanceByFilm.fulfilled, (state, action) => {
        state.performanceByFilmStatus = FETCH_STATUS.RESOLVED;
        state.performanceByFilm = action.payload;
        state.performanceByFilmErr = null;
      })
      .addCase(getPerformanceByFilm.rejected, (state, action) => {
        state.performanceByFilmStatus = FETCH_STATUS.REJECTED;
        state.performanceByFilmErr =
          action.payload || "Unable to get performance by film";
      })

      .addCase(getPerformanceByShowtime.pending, (state, action) => {
        state.performanceByShowtimeStatus = FETCH_STATUS.PENDING;
        state.performanceByShowtimeErr = "";
      })
      .addCase(getPerformanceByShowtime.fulfilled, (state, action) => {
        state.performanceByShowtimeStatus = FETCH_STATUS.RESOLVED;
        state.performanceByShowtime = action.payload;
        state.performanceByShowtimeErr = null;
      })
      .addCase(getPerformanceByShowtime.rejected, (state, action) => {
        state.performanceByShowtimeStatus = FETCH_STATUS.REJECTED;
        state.performanceByShowtimeErr =
          action.payload || "Unable to get performance by showtime";
      })

      .addCase(getStaffPerformance.pending, (state, action) => {
        state.getStaffPerformanceStatus = FETCH_STATUS.PENDING;
        state.getStaffPerformanceErr = "";
      })
      .addCase(getStaffPerformance.fulfilled, (state, action) => {
        state.getStaffPerformanceStatus = FETCH_STATUS.RESOLVED;
        state.staffPerformance = action.payload;
        state.getStaffPerformanceErr = null;
      })
      .addCase(getStaffPerformance.rejected, (state, action) => {
        state.getStaffPerformanceStatus = FETCH_STATUS.REJECTED;
        state.getStaffPerformanceErr =
          action.payload || "Unable to get staff performance report";
      })
      .addCase(getGBOByScreenFormat.pending, (state, action) => {
        state.GBOByScreenFormatStatus = FETCH_STATUS.PENDING;
        state.GBOByScreenFormatErr = "";
      })
      .addCase(getGBOByScreenFormat.fulfilled, (state, action) => {
        state.GBOByScreenFormatStatus = FETCH_STATUS.RESOLVED;
        state.GBOByScreenFormat = action.payload;
        state.GBOByScreenFormatErr = null;
      })
      .addCase(getGBOByScreenFormat.rejected, (state, action) => {
        state.GBOByScreenFormatStatus = FETCH_STATUS.REJECTED;
        state.GBOByScreenFormatErr =
          action.payload || "Unable to get GBO by Screen Format";
      });
  },
});

export const reportData = (state) => state.reports;
// export const { addScreen, editScreen } = distributorByFilmReportSlice.actions;
