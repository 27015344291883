export const replaceInArray = (initialArray, arrayItem) => {
  const newArray = initialArray.map((x) => {
    if (x.id !== arrayItem.id) {
      return x;
    } else {
      return arrayItem;
    }
  });

  return newArray;
};
