import {useEffect, useRef, useState} from "react";
import {useReactToPrint} from "react-to-print";
import {
    ExportExcelBtn,
    FormGroup,
    InputErr,
    ItemSalesTable,
    Label,
    PdfButton,
    Pdiv,
    Pdiv2,
    PrivateRoute,
    SearchSelect,
    Spinner,
} from "../../../components";
import {useNavigate} from "react-router-dom";
import DatePicker from "react-datepicker";
import addDays from "date-fns/addDays";
import {DateTimeInput} from "../../../components/Films/DateTimeInput";
import {useLoggedInuser} from "../../../hooks";
import {getItemSalesReport, reportData,} from "../../../redux/slices";
import {formatDateForApi, getSelectedItem2, itemSalesSchema,} from "../../../utils";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Element, scroller} from "react-scroll";
import {useDispatch, useSelector} from "react-redux";

const serviceAreas = [
  { id: 0, serviceArea: "Cinema" },
  { id: 1, serviceArea: "FastFood" },
  { id: 2, serviceArea: "Cafe" },
  { id: 3, serviceArea: "Arcade" },
];

const ItemSalesReport = () => {
  const navigate = useNavigate();
  const [startDate, setStart] = useState(addDays(new Date(), -2));
  const [endDate, setEnd] = useState(new Date());
  const { itemSalesReport, itemSalesReportStatus } = useSelector(reportData);
  const { profile } = useLoggedInuser();
  const dispatch = useDispatch();
  const reportRef = useRef(null);

  const {
    register,
    control,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(itemSalesSchema),
    defaultValues: {
      DateFrom: startDate,
      DateTo: endDate,
      CinemaId: profile.cinemaId,
      ServiceArea: "allServiceAreas",
    },
  });

  useEffect(() => {
    setValue("DateFrom", startDate);
    setValue("DateTo", endDate);
  }, [startDate, endDate, setValue]);

  const onSubmit = (data) => {
    dispatch(
      getItemSalesReport({
        ...data,
        DateFrom: formatDateForApi(new Date(data.DateFrom)),
        DateTo: formatDateForApi(new Date(data.DateTo)),
        force: true,
      })
    );
    scroller.scrollTo("reportContainer", {
      duration: 1500,
      delay: 0,
      smooth: true,
      offset: -100, // Scrolls to element + 50 pixels down the page
    });
  };

  const handlePrint = useReactToPrint({
    content: () => reportRef.current,
    documentTitle: "item-sales-report",
  });
  return (
    <PrivateRoute redirectTo={"/dashboard/reports/item-sales-report"}>
      <div className="w-[95%] mx-auto mt-6">
        <p className="font-semi-bold px-3 pb-5">
          Item Sales Report - Set Report Parameters
        </p>
        <div className="flex items-center justify-between p-4 px-8 h-[50px] rounded-t-lg bg-[#C96FCC]">
          <div className="flex gap-4">
            <span className="text-xl font-semibold text-white"></span>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex items-center justify-end py-3 px-4 bg-white ">
            <div className="flex gap-3 buttongroup ">
              <button
                className="p-1 px-3 rounded-md text-[#C96FCC] bg-[#ffe583] shadow-md hover:shadow-sm"
                type="button"
                onClick={() => {
                  navigate("/dashboard/reports");
                }}
              >
                Cancel
              </button>
              <button
                className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm flex items-center"
                type="submit"
              >
                <span> Run Report </span>
                <Spinner
                  className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                  status={itemSalesReportStatus}
                />
              </button>
            </div>
          </div>
          <div className="bg-app-purple-2 p-3 pt-[30px]">
            <Pdiv label={"Date Range"}>
              <div className="flex items-center">
                <DatePicker
                  placeholderText="Start Date"
                  selected={new Date(startDate)}
                  onChange={(value) => setStart(new Date(value))}
                  customInput={
                    <DateTimeInput
                      className="bg-app-purple-8  w-[12rem]"
                      dateFormat={"EEE, MMM dd, yyyy"}
                    />
                  }
                />
                <p className="w-[6rem] text-center"> To:</p>
                <DatePicker
                  placeholderText="End Date"
                  selected={new Date(endDate)}
                  onChange={(value) => setEnd(new Date(value))}
                  customInput={
                    <DateTimeInput
                      className="bg-app-purple-8"
                      dateFormat={"EEE, MMM dd, yyyy"}
                    />
                  }
                />
              </div>
            </Pdiv>
            <InputErr err={errors?.DateTo?.message} />
            <InputErr err={errors?.DateFrom?.message} />
            <Pdiv2 label={"Site"} name={"CinemaId"} {...register("CinemaId")}>
              <option value={""}>Select Cinema</option>
              <option value={profile.cinemaId}>{profile.cinema}</option>
            </Pdiv2>
            <InputErr err={errors?.CinemaId?.message} />
            <FormGroup className={"mb-[30px]"}>
              <Label className={"w-[23rem] mr-[30px]"}>Service Area:</Label>
              <Controller
                control={control}
                name={"ServiceArea"}
                render={({ field: { value, onChange } }) => {
                  return (
                    <SearchSelect
                      isDefault={true}
                      defaultValue={"allServiceAreas"}
                      defaultName={"All Service Areas"}
                      items={serviceAreas}
                      dropDownNameKey="serviceArea"
                      inputClassName="!w-full p-2 px-4 py-2 border-none !bg-app-purple-10"
                      listClassName="!py-2"
                      id={`serviceArea`}
                      value={getSelectedItem2(serviceAreas, value, "id")}
                      onChange={(value) =>
                        onChange(value?.id ?? "allServiceAreas")
                      }
                      displayValue={(value) => {
                        return value?.serviceArea ?? "All Service Areas";
                      }}
                      placeholder={`Select Service Area`}
                      searchOptions={{
                        keys: ["serviceArea"],
                      }}
                    />
                  );
                }}
              />
            </FormGroup>
          </div>
        </form>
        <Element name="reportContainer">
          {itemSalesReport?.paramFilter && (
            <>
              <div className="flex justify-center mt-5 space-x-4">
                <PdfButton refValue={reportRef} docTitle={"item-sales"} />
                <ExportExcelBtn id={"itemsales"} name={"item-sales.xlsx"} />
              </div>
              <div ref={reportRef}>
                <ItemSalesTable />
              </div>
              <div className="hidden">
                <ItemSalesTable id={"itemsales"} />
              </div>
            </>
          )}
        </Element>
      </div>
    </PrivateRoute>
  );
};
export { ItemSalesReport };
