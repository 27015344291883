import * as React from "react";
import { Link} from "react-router-dom";




const Crumb = ({ text, href, last = false }) => {
  if (last) {
    return (
      <small className="text-[#C397C3] text-[18px] leading-[27px] capitalize">
        {text}
      </small>
    );
  }
  return (
    <Link className="text-[#C397C3] text-[18px] leading-[27px] capitalize" to={href}>
      {/* <a className="text-[#C397C3] text-[18px] leading-[27px] capitalize"> */}
        {text}
      {/* </a> */}
    </Link>
  );
};

export default Crumb;
