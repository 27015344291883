import { S3 } from "@aws-sdk/client-s3";
import { SPACE_ACCESS_KEY, SPACE_ENDPOINT, SPACE_SECRET_KEY } from "./constants";

const s3Client = new S3({
  forcePathStyle: false, // Configures to use subdomain/virtual calling format.
  endpoint: SPACE_ENDPOINT,
  // endpoint: spacesEndpoint,
  region: "nyc3",
  credentials: {
    accessKeyId: SPACE_ACCESS_KEY,
    secretAccessKey: SPACE_SECRET_KEY,
    // accessKeyId: process.env.REACT_APP_SPACES_KEY_LIVE,
    // secretAccessKey: process.env.REACT_APP_SPACES_SECRET_LIVE,
    // accessKeyId: "DO00EE8PGZLQCMD2HRFF",
    // secretAccessKey: "HXVdV/65nWWM9/VtViUi614Qej+xV87Co6UFDlP8dtw",
  },
});

export { s3Client };
