import React, { useMemo } from "react";
import { DefaultListTable } from "../UI";
import { formatDateTime, formatNumberAsCurrency } from "../../utils";
import { format } from "../Films/DateTimeInput";
import ReachLogo from "../../assets/images/logo.png";

const PerformanceByShowtimeTable = ({ data, id }) => {
  const columns = useMemo(() => {
    return [
      {
        Header: "Start time",
        accessor: "startTime",
        Cell: ({ value }) => formatDateTime(value, format),
      },
      {
        Header: "End time",
        accessor: "endTime",
        Cell: ({ value }) => formatDateTime(value, format),
      },
      {
        Header: "Film",
        accessor: "film",
      },

      {
        Header: "Screen",
        accessor: "screen",
      },
      {
        Header: "Screen Capacity",
        accessor: "screenCapacity",
      },
      {
        Header: "Admits",
        accessor: "admits",
      },
      {
        Header: "Occupancy",
        accessor: "occupancy",
        Cell: ({ value, row }) => {
          return `${Math.round(
            (row.original.admits / row.original.screenCapacity) * 100
          )}%`;
        },
      },
      {
        Header: "Total GBO",
        accessor: "totalGBO",
        Cell: ({ value }) => (id ? value : formatNumberAsCurrency(value)),
      },
      {
        Header: "Estimated concession sales",
        accessor: "estimatedConcessionSales",

        Cell: ({ value }) => (id ? value : formatNumberAsCurrency(value)),
      },
      {
        Header: "Estimated total revenue",
        accessor: "estimatedTotalRevenue",

        Cell: ({ value }) => (id ? value : formatNumberAsCurrency(value)),
      },
    ];
  }, [id]);
  return (
    <>
      <div>
        <div
          key={ReachLogo}
          className=" ml-[-5px] w-[5rem] h-[3rem] bg-no-repeat bg-contain bg-center"
          style={{
            backgroundImage: `url("${ReachLogo}")`,
          }}
        ></div>
        {/* <div>
        <p>
          {" "}
          <span className="font-bold">User Name:</span>
          {` ${profile.firstName} ${profile.lastName}`}
        </p>
        <p>
          <span className="font-bold">Date Time Generated: </span>
          {formatDateTime(paramFilter?.dateTimeGenerated, dateAndTime)}
        </p>
      </div> */}
      </div>
      <DefaultListTable columns={columns} data={data} id={id} />
    </>
  );
};

export { PerformanceByShowtimeTable };
