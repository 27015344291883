import React, { useMemo } from "react";
import startOfWeek from "date-fns/startOfWeek";
import addDays from "date-fns/addDays";
import groupBy from "lodash/groupBy";
import isWithinInterval from "date-fns/isWithinInterval";
import startOfDay from "date-fns/startOfDay";
import useContextMenu from "react-use-context-menu";

import { formatDateTime, isCanal, loggedInCinemaName } from "../../../utils";
import WeekRow from "./WeekRow";
import endOfDay from "date-fns/endOfDay";

const getWeekDays = (date) => {
  const days = [];
  const cinemaName = localStorage.getItem(loggedInCinemaName);
  const isCanal = cinemaName?.toLowerCase().includes("canal");
  const sunday = isCanal
    ? startOfWeek(date, { weekStartsOn: 1 })
    : startOfWeek(date, { weekStartsOn: 0 });

  for (let day = 0; day < 7; day++) {
    const newDay = addDays(sunday, day);
    days.push(newDay);
  }

  return days;
};

const filterWeekEvents = (events = [], activeDate = new Date()) => {
  const days = getWeekDays(activeDate);

  return events.filter((ev) => {
    const startValid = isWithinInterval(new Date(ev.startTime), {
      start: days[0],
      end: endOfDay(new Date(days[days.length - 1])),
    });

    const endValid = isWithinInterval(new Date(ev.endTime), {
      start: days[0],
      end: endOfDay(new Date(days[days.length - 1])),
    });

    return startValid || endValid;
  });
};

const WeekView = ({
  resources = [],
  events = [],
  renderEvent,
  renderResource,
  resourceIdentifier,
  activeDate,
  eventGroupByKey,
  renderContextMenu = () => {},
}) => {
  const days = useMemo(() => getWeekDays(activeDate), [activeDate]);
  const [
    bindMenu,
    bindMenuItem,
    useContextTrigger,
    { data, coords, setVisible },
  ] = useContextMenu();

  const hideMenu = () => setVisible(false);

  const eventsByResource = useMemo(
    () => groupBy(filterWeekEvents(events, activeDate), eventGroupByKey),
    [events, eventGroupByKey, activeDate]
  );

  const rowProps = useMemo(
    () => ({
      renderEvent,
      renderResource,
      resourceIdentifier,
      useContextTrigger,
    }),
    [renderEvent, renderResource, resourceIdentifier, useContextTrigger]
  );

  return (
    <div>
      <div className="relative grid grid-cols-[8%_minmax(0,1fr)] bg-app-purple-5">
        <div />

        <div className="mode-week-grid grid grid-cols-[repeat(7,minmax(0,1fr))]">
          {days.map((day) => (
            <p
              key={formatDateTime(day, "EEE do")}
              className="relative px-2 py-5 !font-mono text-base font-semibold text-center break-words text-app-black"
            >
              {formatDateTime(day, "EEE do")}
            </p>
          ))}
        </div>
      </div>

      <div>
        {resources.map((res, i) => {
          const groupedEventsByDayForResource = groupBy(
            eventsByResource[res[resourceIdentifier]],
            (value) =>
              formatDateTime(startOfDay(new Date(value.startTime)), "T")
          );

          return (
            <WeekRow
              resource={res}
              key={i}
              rowEvents={groupedEventsByDayForResource}
              {...rowProps}
            />
          );
        })}

        {/* Custom menu via renderContextMenu, it will take props and arent handles individual actions */}

        {renderContextMenu({
          bindMenu,
          data,
          bindMenuItem,
          coords,
          hideMenu,
        })}
      </div>
    </div>
  );
};

export default React.memo(WeekView);
