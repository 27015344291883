import React from "react";
import { useTable } from "react-table";

const ExportTable = ({ filteredData, columns, id, isFooter = false }) => {
  const tableInstance = useTable({ data: filteredData, columns });

  const {
    getTableProps,
    getTableBodyProps,
    footerGroups,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;
  return (
    <>
      <table
        id={id}
        {...getTableProps()}
        className="w-full mx-auto mt-8 overflow-hidden text-left border rounded-md border-app-purple-4"
        style={{ border: "1px" }}
      >
        <thead className="shadow-2xl bg-app-purple-8">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className="p-6 font-normal tracking-wide uppercase border border-gray-300 text-md"
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className="transition duration-300 border bg-app-purple-1 border-app-purple-4 border-opacity-30"
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} className="p-4">
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        {isFooter && (
          <tfoot>
            {footerGroups.map((footerGroup, i) => (
              <tr
                key={i}
                {...footerGroup.getFooterGroupProps()}
                className="bg-[#E6C4E7] border-b"
              >
                {footerGroup.headers.map((column, i) => (
                  <td
                    key={i}
                    {...column.getFooterProps}
                    className="px-6 py-4 text-[#534C7E] text-center min-w-min"
                  >
                    {column.render("Footer")}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </table>
    </>
  );
};

export { ExportTable };
