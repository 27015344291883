import React from "react";
import * as XLSX from "xlsx";

const ExportExcelBtn = ({ data, id, sheet = "sheet1", name }) => {
  const handleExcel = () => {
    if (typeof id === "object") {
      id.forEach((i) => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.table_to_sheet(document.getElementById(i));

        XLSX.utils.book_append_sheet(wb, ws, sheet);
        XLSX.writeFile(wb, name);
      });
    } else {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.table_to_sheet(document.getElementById(id));

      XLSX.utils.book_append_sheet(wb, ws, sheet);
      XLSX.writeFile(wb, name);
    }
  };
  return (
    <>
      <div>
        <button
          type={"button"}
          className="p-1 px-3 rounded-md text-[#C96FCC] bg-[#ffe583] shadow-md hover:shadow-sm"
          onClick={handleExcel}
        >
          Generate XLS
        </button>
      </div>
    </>
  );
};
// const ExportExcelBtn = ({ data, id = "", sheet = "sheet1", name }) => {
//   const handleExcel = () => {
//     const wb = XLSX.utils.book_new();
//     const ws = XLSX.utils.table_to_sheet(document.getElementById(id));

//     XLSX.utils.book_append_sheet(wb, ws, sheet);
//     XLSX.writeFile(wb, name);
//   };
//   return (
//     <>
//       {/* <div className="flex justify-center space-x-4"> */}
//       <div>
//         <button
//           type={"button"}
//           className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm"
//           onClick={handleExcel}
//         >
//           Generate XLS
//         </button>
//       </div>
//       {/* </div> */}
//     </>
//   );
// };

export { ExportExcelBtn };
