import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  ActiveRequestSpinner,
  FormError,
  PrivateRoute,
  SuccessModal,
} from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  stockData,
  getStoreLocations,
  getStockTransfer,
} from "../../../../redux/slices";
import { FETCH_STATUS, axiosApiInstance, API_URL } from "../../../../utils";
import { ItemFormErrorModal as FormErrorModal } from "../../../../components";
import STItemsTable from "./STItemsTable";
import { useRequest } from "../../../../hooks";

const StockTransReqForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formError, setFormError] = useState(false);
  const [items, setItems] = useState([]);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [location, setLocation] = useState("");
  const { stockTransfer, stockTransferStatus, storeLocations } =
    useSelector(stockData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStoreLocations(true));
  }, []);

  useEffect(() => {
    dispatch(getStockTransfer(id))
      .unwrap()
      .then((data) => {
        setItems(data?.items);
        setLocation(data?.destinationTransferRequestId);
      });
  }, [id]);
  const {
    setResolved,
    setPending,
    setRejected,
    status: updateStockTransferStatus,
    err: updateStockTransferError,
  } = useRequest();

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!location) {
      setFormError("Ensure you select a location");
      return;
    }
    const formattedData = {
      originatingTransferRequestId: stockTransfer?.originatingTransferRequestId,
      destinationTransferRequestId:
        stockTransfer?.type === "Internal"
          ? stockTransfer?.destinationTransferRequestId
          : location,
      requestDate: stockTransfer?.requestDate,
      scheduledDate: stockTransfer.scheduledDate,
      items: items?.map((item) => {
        return {
          itemId: item.itemId,
          unitOfMeasurementId: item.unitOfMeasurementId,
          quantityNeeded: item?.quantiityNeeded,
          quantityAvailable: item?.quantityAvailable,
        };
      }),
    };

    try {
      setPending();

      const res = await axiosApiInstance.patch(
        `${API_URL}/StockTransfers/Update?stockTransferId=${stockTransfer?.id}`,
        { ...formattedData }
      );
      setResolved(res.data.data);
    } catch (error) {
      setRejected(error);
    }
  };

  return (
    <PrivateRoute redirectTo="/dashboard/stock/stockTransferRequest">
      <>
        <form onSubmit={submitHandler}>
          <div className="flex items-center justify-end p-4 px-8 rounded-t-lg bg-gradient-to-b from-app-purple-4 to-app-purple-3">
            <div className="flex buttongroup">
              <button
                className="p-3 px-5 rounded-md text-[#C96FCC] bg-white shadow-md hover:font-bold"
                onClick={() => {
                  navigate("/dashboard/stock/stockTransferRequest");
                }}
              >
                Back
              </button>
              <button
                className="p-3 px-5 rounded-md text-[#C96FCC] bg-white shadow-md hover:font-bold ml-4"
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>

          <FormError className="mt-[20px]" err={updateStockTransferError} />

          <div className="flex justify-center mb-5">
            <div className=" max-w-[800px]  grid grid-cols-2 gap-5 mt-[30px] ">
              <div className="">
                <label className="block">Stock Transfer Id</label>
                <p>{stockTransfer?.id}</p>
              </div>
              <div>
                <label className="block">Request Date</label>
                <p> {new Date(stockTransfer?.requestDate).toDateString()}</p>
              </div>
              <div>
                <label className="block">From</label>
                <p>{stockTransfer?.originatingTransferRequest}</p>
              </div>
              {stockTransfer?.type === "Internal" && (
                <div>
                  <label className="block">To</label>
                  <p>{stockTransfer?.destinationTransferRequest}</p>
                </div>
              )}
              {stockTransfer?.type === "External" && (
                <div>
                  <label className="block">Sender Location</label>
                  <select
                    className="rounded bg-[#d0a3d3] border-0 mb-3 w-[180px]"
                    value={location}
                    onChange={(e) => {
                      setLocation(e.target.value);
                    }}
                  >
                    <option value={""}>Select Location</option>
                    {storeLocations.length > 0 &&
                      storeLocations?.map((x, index) => {
                        return (
                          <option value={x.id} key={index}>
                            {x.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              )}
            </div>
          </div>
        </form>
        <div className="overflow-x-scroll">
          <STItemsTable items={items} setItems={setItems} />
        </div>
      </>

      <ActiveRequestSpinner
        show={
          updateStockTransferStatus === FETCH_STATUS.PENDING ||
          stockTransferStatus === FETCH_STATUS.PENDING
            ? true
            : false
        }
      />
      <FormErrorModal
        onClose={() => {
          setFormError(false);
        }}
        show={formError}
        message={formError}
        onClick={() => {
          setFormError(false);
        }}
      />
      <SuccessModal
        onClose={() => {
          setUpdateSuccess(false);
          navigate("/dashboard/stock/stockTransferRequest");
        }}
        show={updateSuccess}
        message={"Stock Transfer Request updated successfully"}
        onClick={() => {
          setUpdateSuccess(false);
          navigate("/dashboard/stock/stockTransferRequest");
        }}
      />
    </PrivateRoute>
  );
};

export { StockTransReqForm };
