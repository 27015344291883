import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  addItemDirect,
  BIGNUMBER,
  editItemDirect,
  API_URL,
  axiosApiInstanceGet,
  FETCH_STATUS,
  Ticket,
  onReturnReject,
} from "../../utils";

export const getTickets = createAsyncThunk(
  "getTickets",
  async (force, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Tickets/ListAll?perPage=${BIGNUMBER}`,
        force
      );

      const data =
        response?.data?.data?.pagedList?.map((d) => Ticket.toUI(d)) || [];

      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(onReturnReject(error));
    }
  }
);
export const getChannels = createAsyncThunk(
  "getChannels",
  async (force, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Channels/ListAll`,
        force
      );

      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(onReturnReject(error));
    }
  }
);

export const getClasses = createAsyncThunk(
  "getClasses",
  async (force, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/TicketGroup/ListAll`,
        force
      );

      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(onReturnReject(error));
    }
  }
);

const initialState = {
  tickets: [],
  ticketsStatus: FETCH_STATUS.IDLE,
  ticketsErr: null,

  classes: [],
  classesStatus: FETCH_STATUS.IDLE,
  classesErr: null,

  channels: [],
  channelsStatus: FETCH_STATUS.IDLE,
  channelsErr: null,
};

export const ticketsSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    addTicket: (state, action) => {
      state.tickets = addItemDirect(state, action, "tickets");
    },
    editTicket: (state, action) => {
      editItemDirect(state, action, "tickets");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTickets.pending, (state) => {
        state.ticketsStatus = FETCH_STATUS.PENDING;
        state.ticketsErr = "";
      })
      .addCase(getTickets.fulfilled, (state, action) => {
        state.ticketsStatus = FETCH_STATUS.RESOLVED;
        state.tickets = action.payload;
        state.ticketsErr = "";
      })
      .addCase(getTickets.rejected, (state, action) => {
        state.ticketsStatus = FETCH_STATUS.REJECTED;
        state.ticketsErr = action.payload || "Unable to get tickets";
      })
      .addCase(getChannels.pending, (state) => {
        state.channelsStatus = FETCH_STATUS.PENDING;
        state.channelsErr = "";
      })
      .addCase(getChannels.fulfilled, (state, action) => {
        state.channelsStatus = FETCH_STATUS.RESOLVED;
        state.channelsErr = null;
        state.channels = action.payload;
      })
      .addCase(getChannels.rejected, (state, action) => {
        state.channelsStatus = FETCH_STATUS.REJECTED;
        state.channelsErr = action.payload || "Unable to retrieve channels";
      })
      .addCase(getClasses.pending, (state) => {
        state.classesStatus = FETCH_STATUS.PENDING;
        state.classesErr = "";
      })
      .addCase(getClasses.fulfilled, (state, action) => {
        state.classesStatus = FETCH_STATUS.RESOLVED;
        state.classesErr = null;
        state.classes = action.payload;
      })
      .addCase(getClasses.rejected, (state, action) => {
        state.classesStatus = FETCH_STATUS.REJECTED;
        state.classesErr = action.payload || "Unable to retrieve classes";
      });
  },
});

export const selectTickets = (state) => state.tickets;
export const { addTicket, editTicket } = ticketsSlice.actions;
