import React, { useState } from 'react'
import logo from "../../assets/images/logo.png";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Spinner, WithAuth } from '../../components';
import { loginInit, loginSchema } from '../../utils';
import EyeOpen from '../../components/Icons/EyeOpen';
import EyeClosed from '../../components/Icons/EyeClosed';
import ConfirmPassword from '../../components/Auth/ConfirmPassword';
const ResetPassword = () => {
      const[open,setOpen] = useState(false)
     const {
       register,
       formState: { errors },
       handleSubmit,
     } = useForm({
      
       defaultValues: {emailAddress:""},
     });
    const onSubmit = async (values) => {
     setOpen(true)   
    }
  return (
    <>
      <WithAuth>
        <div>
          <img className="w-auto h-32 mx-auto" src={logo} alt="Reach Logo" />
          <h2 className="my-10 text-xl font-medium text-center text-gray-900">
            Get Default Password
          </h2>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          className="mt-8 space-y-6"
        >
          <div className="space-y-4 rounded-md">
            <div className="relative pb-5">
              <label htmlFor="emailAddress" className="sr-only">
                Email Address
              </label>
              <input
                autoComplete="off"
                id="emailAddress"
                type="email"
                {...register("emailAddress")}
                className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-app-purple-4 focus:border-app-purple-4 focus:z-10 sm:text-sm"
                placeholder="Email Address"
              />

              <span className="absolute bottom-0 left-0 flex items-center mt-1 ml-1 text-xs italic font-medium tracking-wide text-red-500">
                {errors?.emailAddress?.message}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <button
              type="submit"
              className="relative min-w-[150px] flex justify-center items-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-app-purple-4"
            >
              Submit
              <Spinner
                className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                // status={status}
              />
            </button>
          </div>
        </form>
        {open && <ConfirmPassword />}
      </WithAuth>
    </>
  );
}

export default ResetPassword
