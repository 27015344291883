import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  FETCH_STATUS,
  API_URL,
  axiosApiInstanceGet,
  addItemDirect,
  editItemDirect,
  transformErr,
  axiosApiInstance,
} from "../../utils";

export const getScreens = createAsyncThunk(
  "getScreens",
  async (force, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Screens/ListAll`,
        force
      );

      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      console.log({ ...err });

      return rejectWithValue(transformErr(err));
    }
  }
);

export const getSeatClass = createAsyncThunk(
  "getSeatClass",
  async (force, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/SeatClasses/ListAll?page=1&perPage=1000`,
        force
      );
      // console.log({ response });
      return fulfillWithValue(response?.data?.data?.pagedList || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      //  console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getSeatClassById = createAsyncThunk(
  "getSeatClassById",
  async ({ seatClassId, force }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/SeatClasses/${seatClassId}`,
        force
      );
      return fulfillWithValue(response?.data?.data || []);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      //  console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const createSeatClass = createAsyncThunk(
  "createSeatClass",
  async ({ name, isActive }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SeatClasses/Create`,
        { name, isActive }
      );
      return fulfillWithValue(response?.data?.data || {});
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      //  console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);
export const updateSeatClass = createAsyncThunk(
  "updateSeatClass",
  async (
    { name, seatClassId, isActive },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await axiosApiInstance.patch(
        `${API_URL}/SeatClasses/${seatClassId}/Update`,
        { name, isActive }
      );
      return fulfillWithValue(response?.data?.data || {});
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      //  console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);
export const deleteSeatClass = createAsyncThunk(
  "deleteSeatClass",
  async (seatClassId, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.delete(
        `${API_URL}/SeatClasses/${seatClassId}/Delete`
      );
      return fulfillWithValue(response?.data?.data || {});
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      //  console.log(error?.response.data.errors[0].errorMessages[0]);

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

const initialState = {
  screens: [],
  screensStatus: FETCH_STATUS.IDLE,
  screensErr: null,
  seatClasses: [],
  seatClassStatus: FETCH_STATUS.IDLE,
  seatClassErr: null,
  singleSeatClass: {},
  singleSeatClassStatus: FETCH_STATUS.IDLE,
  singleSeatClassErr: null,
  createdSeatClass: {},
  createdSeatClassStatus: FETCH_STATUS.IDLE,
  createdSeatClassErr: null,
  updatedSeatClass: {},
  updatedSeatClassStatus: FETCH_STATUS.IDLE,
  updatedSeatClassErr: null,
  deletedSeatClass: {},
  deletedSeatClassStatus: FETCH_STATUS.IDLE,
  deletedSeatClassErr: null,
};

export const screensSlice = createSlice({
  name: "screens",
  initialState,
  reducers: {
    addScreen: (state, action) => {
      state.screens = addItemDirect(state, action, "screens");
    },
    editScreen: (state, action) => {
      editItemDirect(state, action, "screens");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getScreens.pending, (state) => {
        state.screensStatus = FETCH_STATUS.PENDING;
        state.screensErr = "";
      })
      .addCase(getScreens.fulfilled, (state, action) => {
        state.screensStatus = FETCH_STATUS.RESOLVED;
        state.screensErr = null;
        state.screens = action.payload;
      })
      .addCase(getScreens.rejected, (state, action) => {
        state.screensStatus = FETCH_STATUS.REJECTED;
        state.screensErr = action.payload || "Unable to retrieve screens";
      })
      .addCase(getSeatClass.pending, (state, action) => {
        state.seatClassStatus = FETCH_STATUS.PENDING;
        state.seatClassErr = "";
      })
      .addCase(getSeatClass.fulfilled, (state, action) => {
        state.seatClassStatus = FETCH_STATUS.RESOLVED;
        state.seatClasses = action.payload;
        state.seatClassErr = null;
      })
      .addCase(getSeatClass.rejected, (state, action) => {
        state.seatClassStatus = FETCH_STATUS.REJECTED;
        state.seatClassErr = action.payload || "Unable to get seat classes";
      })

      .addCase(getSeatClassById.pending, (state) => {
        state.singleSeatClassStatus = FETCH_STATUS.PENDING;
        state.singleSeatClassErr = "";
      })
      .addCase(getSeatClassById.fulfilled, (state, action) => {
        state.singleSeatClassStatus = FETCH_STATUS.RESOLVED;
        state.singleSeatClass = action.payload;
        state.singleSeatClassErr = null;
      })
      .addCase(getSeatClassById.rejected, (state, action) => {
        state.singleSeatClassStatus = FETCH_STATUS.REJECTED;
        state.singleSeatClassErr = action.payload || "Unable to get seat class";
      })

      .addCase(createSeatClass.pending, (state) => {
        state.createdSeatClassStatus = FETCH_STATUS.PENDING;
        state.createdSeatClassErr = "";
      })
      .addCase(createSeatClass.fulfilled, (state, action) => {
        state.createdSeatClassStatus = FETCH_STATUS.RESOLVED;
        state.createdSeatClass = action.payload;
        state.createdSeatClassErr = null;
      })
      .addCase(createSeatClass.rejected, (state, action) => {
        state.createdSeatClassStatus = FETCH_STATUS.REJECTED;
        state.createdSeatClassErr =
          action.payload || "Unable to create seat class";
      })
      .addCase(updateSeatClass.pending, (state) => {
        state.updatedSeatClassStatus = FETCH_STATUS.PENDING;
        state.updatedSeatClassErr = "";
      })
      .addCase(updateSeatClass.fulfilled, (state, action) => {
        state.updatedSeatClassStatus = FETCH_STATUS.RESOLVED;
        state.updatedSeatClass = action.payload;
        state.updatedSeatClassErr = null;
      })
      .addCase(updateSeatClass.rejected, (state, action) => {
        state.updatedSeatClassStatus = FETCH_STATUS.REJECTED;
        state.updatedSeatClassErr =
          action.payload || "Unable to update seat class";
      })
      .addCase(deleteSeatClass.pending, (state) => {
        state.deletedSeatClassStatus = FETCH_STATUS.PENDING;
        state.deletedSeatClassErr = "";
      })
      .addCase(deleteSeatClass.fulfilled, (state, action) => {
        state.deletedSeatClassStatus = FETCH_STATUS.RESOLVED;
        state.deletedSeatClass = action.payload;
        state.deletedSeatClassErr = null;
      })
      .addCase(deleteSeatClass.rejected, (state, action) => {
        state.deletedSeatClassStatus = FETCH_STATUS.REJECTED;
        state.deletedSeatClassErr =
          action.payload || "Unable to delete seat class";
      });
  },
});

export const selectScreens = (state) => state.screens;
export const { addScreen, editScreen } = screensSlice.actions;
