import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import {
  Loading,
  PageHeader,
  StaffTable,
  PrivateRoute,
  ErrorWithWrapper,
} from "../../../components";
import { useLoggedInuser } from "../../../hooks";
import { getStaff, selectStaff } from "../../../redux/slices";
import { FETCH_STATUS } from "../../../utils";

const AllStaff = () => {
  const dispatch = useDispatch();
  const { staff, staffStatus, staffErr } = useSelector(selectStaff);
  const { profile } = useLoggedInuser();

  const getInfo = useCallback(
    (force) => {
      dispatch(getStaff({ force, cinemaId: profile?.cinemaId }));
    },
    [dispatch, profile?.cinemaId]
  );

  useEffect(() => {
    if (staffStatus === FETCH_STATUS.IDLE && profile?.cinemaId) {
      dispatch(getStaff({ cinemaId: profile?.cinemaId }));
    }
  }, [dispatch, staffStatus, profile?.cinemaId]);

  return (
    <PrivateRoute redirectTo="/dashboard/users">
      <PageHeader>
        <h1 className="text-2xl font-semibold text-white ">Staff</h1>

        <NavLink
          to="/dashboard/users/create"
          type="button"
          className="px-4 py-3 text-base font-medium bg-white rounded-md text-app-purple-9"
        >
          Add Staff
        </NavLink>
      </PageHeader>

      <ErrorWithWrapper
        error={staffErr}
        show={staffStatus === FETCH_STATUS.REJECTED}
        retry={getInfo}
      />
      <div className="relative">
        <Loading
          wrapperClassName="absolute top-0 left-0 right-0 min-h-[50vh]"
          show={staffStatus === FETCH_STATUS.PENDING}
        />

        {staff?.length ? <StaffTable staff={staff} /> : null}
      </div>
    </PrivateRoute>
  );
};

export { AllStaff };
