import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  reportData,
  getOrderById,
  getRefundByOrderId,
} from "../../redux/slices";
import {
  FETCH_STATUS,
  preventScrollBug,
  formatNumberAsCurrency,
  API_URL,
  axiosApiInstance,
} from "../../utils";
import { Spinner } from "../../components";
import { FullRefundModal } from "./RefundModals/FullRefundModal";
import { PartialRefundModal } from "./RefundModals/PartialRefundModal";
import { useRequest } from "../../hooks";

const TransactionDetailsModal = ({
  onClose = () => {},
  funcProps,
  show,
  orderData,
}) => {
  const dispatch = useDispatch();
  const { singleOrder, singleOrderStatus } = useSelector(reportData);
  const [showFullRefundModal, setShowFullRefundModal] = useState(false);
  const [showPartialRefundModal, setShowPartialRefundModal] = useState(false);

  const { setPending, setResolved, setRejected, data } = useRequest();

  useEffect(() => {
    if (!orderData?.showsRefund) {
      dispatch(getOrderById({ force: true, orderId: orderData.id }));
    } else {
      dispatch(getRefundByOrderId(orderData.id));
    }
  }, [orderData.id]);

  let singleItems = [];
  let tickets = [];

  if (singleOrder?.itemSales?.length > 0) {
    singleItems = singleOrder?.itemSales?.map((item) => {
      return {
        name: item.item,
        amount: item.amount,
        quantity: Math.abs(item.quantity),
        unitPrice: item.price,
      };
    });
  }
  if (singleOrder?.ticketSales?.length > 0) {
    tickets = singleOrder?.ticketSales?.map((ticket) => {
      return {
        name: ticket.film,
        amount: ticket.amount,
        quantity: Math.abs(ticket.quantity),
        unitPrice: ticket.ticketPrice,
      };
    });
  }

  let orderDetails = [...singleItems, ...tickets];

  let showRefundButtons = false;

  if (!orderData?.hasBeenRefunded) {
    showRefundButtons = true;
    if (orderData?.showsRefund) {
      showRefundButtons = false;
    }
  }

  //comment to redo commit

  return (
    <>
      <Transition show={show} appear as={Fragment}>
        <Dialog as="div" className="relative z-[10000]" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => {
              setTimeout(() => {
                preventScrollBug();
              }, 500);
            }}
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-sm max-h-[90vh] text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-white bg-[#a86aaa] rounded-t-2xl px-2 py-2 text-center"
                  >
                    {!orderData.showsRefund
                      ? "Order Details"
                      : "Refund Details"}
                  </Dialog.Title>
                  {singleOrderStatus === FETCH_STATUS.PENDING && (
                    <>
                      <div className="flex justify-center my-[30px]">
                        <Spinner
                          className="!w-[20px] !h-[20px] mx-1 m-auto text-white fill-pink-400"
                          status={singleOrderStatus}
                        />
                      </div>
                    </>
                  )}
                  {singleOrderStatus === FETCH_STATUS.RESOLVED && (
                    <div className=" py-2">
                      {/* <form onSubmit={handleSubmit(submitHandler)}> */}
                      <div className=" max-h-[60vh] overflow-y-auto border-t-[1px] border-[#7E208080]">
                        <div className="flex items-center justify-between px-2 py-1 border-b-[1px] border-[#7E208080]">
                          <span className="w-[50%] text-[#C96FCC] font-bold">
                            Name
                          </span>{" "}
                          <span className="w-[10%] text-[#C96FCC] font-bold">
                            Qty
                          </span>
                          <span className="w-[20%] text-[#C96FCC] font-bold">
                            Unit Price
                          </span>
                          <span className="w-[20%] text-[#C96FCC] font-bold">
                            Sub-total
                          </span>
                        </div>
                        {orderDetails.map((orderChild, index) => {
                          return (
                            <div
                              className="flex items-center justify-between px-2 py-1 border-b-[1px] border-[#7E208080]"
                              key={index}
                            >
                              <span className="text-[#C96FCC] w-[50%]">
                                {orderChild.name}
                              </span>
                              <span className="text-[#C96FCC]  w-[10%]">
                                {orderChild.quantity}
                              </span>
                              <span className="text-[#C96FCC]  w-[20%]">
                                {formatNumberAsCurrency(orderChild.unitPrice)}
                              </span>
                              <span className="text-[#C96FCC]  w-[20%]">
                                {formatNumberAsCurrency(orderChild.amount)}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                      <div className="flex justify-center gap-3 buttongroup mt-2 ">
                        <button
                          className="p-1 px-3 rounded-md text-[#C96FCC] bg-[#ffe583] shadow-md hover:shadow-sm"
                          type="button"
                          onClick={() => {
                            onClose();
                            orderDetails = [];
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                      {showRefundButtons && (
                        <div className="flex justify-around py-3">
                          <button
                            className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm"
                            type="button"
                            onClick={() => {
                              setShowFullRefundModal(true);
                            }}
                          >
                            Full Refund
                          </button>
                          <button
                            className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm"
                            type="button"
                            onClick={() => {
                              setShowPartialRefundModal(true);
                            }}
                          >
                            Partial Refund
                          </button>
                        </div>
                      )}

                      {/* </form> */}
                    </div>
                  )}
                  {showFullRefundModal && (
                    <FullRefundModal
                      funcProps={funcProps}
                      orderData={orderData}
                      show={showFullRefundModal}
                      onClose={() => {
                        setShowFullRefundModal(false);
                        onClose();
                      }}
                    />
                  )}
                  {showPartialRefundModal && (
                    <PartialRefundModal
                      funcProps={funcProps}
                      orderData={orderData}
                      show={showPartialRefundModal}
                      onClose={() => {
                        setShowPartialRefundModal(false);
                        onClose();
                      }}
                    />
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export { TransactionDetailsModal };
