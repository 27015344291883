import React from "react";
import { useDispatch } from "react-redux";
import {
  PrivateRoute,
  StaffForm,
  PageHeader,
  SuccessModal,
} from "../../../components";
import { useRequest } from "../../../hooks";
import { API_URL, axiosApiInstance, FETCH_STATUS } from "../../../utils";

import { addStaff } from "../../../redux/slices";

const CreateStaff = () => {
  const { err, data, status, resetErr, setResolved, setPending, setRejected } =
    useRequest();

  const dispatch = useDispatch();

  const onSave = async (data) => {
    try {
      setPending();

      const res = await axiosApiInstance.post(`${API_URL}/Users/Create`, data);
      setResolved(res.data.data);

      dispatch(addStaff(res.data.data));
    } catch (error) {
      setRejected(error || "Unable to create staff");
    }
  };

  return (
    <PrivateRoute redirectTo="/dashboard/users/create">
      <PageHeader>
        <h1 className="text-2xl font-semibold text-white ">Create Staff</h1>
      </PageHeader>
      <StaffForm
        onReset={resetErr}
        status={status}
        error={err}
        onSave={onSave}
      />
      <SuccessModal
        message={
          <div className="space-y-2">
            <p className="text-sm text-gray-500">
              Created a new user successfully.
            </p>

            <p className="text-sm">
              Click the button below to create pin for this user
            </p>
          </div>
        }
        show={status === FETCH_STATUS.RESOLVED}
        backLink={`/dashboard/users/create-pin/${data?.id}`}
        buttonLinkText="Create Pin"
      />
    </PrivateRoute>
  );
};

export { CreateStaff };
