import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  API_URL,
  axiosApiInstanceGet,
  FETCH_STATUS,
  StaffSession,
  editItemDirect,
  transformErr,
} from "../../utils";

const startDate = new Date().toISOString();
const endDate = new Date().toISOString();

export const getStaffSessions = createAsyncThunk(
  "getStaffSessions",
  async (config, { fulfillWithValue, rejectWithValue }) => {
    const { force, start, end } = {
      force: config?.force || false,
      start: config?.start || startDate,
      end: config?.end || endDate,
    };
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL}/Sessions/ListAll?DateFrom=${encodeURIComponent(
          start
        )}&DateTo=${encodeURIComponent(end)}`,
        force
      );

      const sessions =
        response?.data?.data?.map((s) => StaffSession.toUI(s)) || [];

      return fulfillWithValue(sessions);
    } catch (err) {
      return rejectWithValue(transformErr(err));
    }
  }
);

const initialState = {
  staffSessions: [],
  staffSessionsStatus: FETCH_STATUS.IDLE,
  staffSessionsErr: null,
  startDate,
  endDate,
};

export const staffSessionsSlice = createSlice({
  name: "staffSessions",
  initialState,
  reducers: {
    setStart: (state, action) => {
      state.startDate = new Date(action.payload).toISOString();
    },
    setEnd: (state, action) => {
      state.endDate = new Date(action.payload).toISOString();
    },
    updateSession: (state, action) => {
      editItemDirect(state, action, "staffSessions");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStaffSessions.pending, (state) => {
        state.staffSessionsStatus = FETCH_STATUS.PENDING;
        state.staffSessionsErr = "";
      })
      .addCase(getStaffSessions.fulfilled, (state, action) => {
        state.staffSessionsStatus = FETCH_STATUS.RESOLVED;
        state.staffSessionsErr = "";
        state.staffSessions = action.payload;
      })
      .addCase(getStaffSessions.rejected, (state, action) => {
        state.staffSessionsStatus = FETCH_STATUS.REJECTED;
        state.staffSessionsErr = action.payload;
      });
  },
});

export const selectStaffSessions = (state) => state.staffSessions;


export const { setStart, setEnd, updateSession } = staffSessionsSlice.actions;
