import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import { Calendar } from "../Icons";
import { formatDateTime } from "../../utils";
import { FormError, InlineErr, Spinner, StatusToggler } from "../UI";
import { Controller, useForm } from "react-hook-form";
import TimePicker from "react-time-picker";
import TimeRangePicker from "@wojtekmaj/react-timerange-picker";
import { useTimeToDateTime } from "../../hooks";
import { addHours } from "date-fns";

const ShiftForm = ({ isEdit, info, status, onReset, error, onSave }) => {
  const [convertTime] = useTimeToDateTime();
  const {
    reset,
    control,
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      timeRange: [new Date(), new Date()],
      status: "true",
    },
  });

  useEffect(() => {
    if (isEdit) {
      reset({
        name: info?.name,
        status: !!info?.status ? "true" : "false",
        timeRange: [new Date(info?.dateFrom), new Date(info?.dateTo)],
      });
    }
  }, [info, isEdit, reset]);

  const handlesShiftSubmit = async (values) => {
    const body = {
      dateFrom:
        typeof values.timeRange[0] === "string"
          ? convertTime(values.timeRange[0]).toISOString()
          : new Date(values.timeRange[0]).toISOString(),
      dateTo:
        typeof values.timeRange[1] === "string"
          ? convertTime(values.timeRange[1]).toISOString()
          : new Date(values.timeRange[1]).toISOString(),
      // dateTo: new Date(values.timeRange[1]).toISOString(),
      name: values.name,
      status: values.status,
    };

    onSave({
      ...body,
      status: "true" ? 1 : 0,
      dateFrom: addHours(new Date(body.dateFrom), 1),
      dateTo: addHours(new Date(body.dateTo), 1),
    });
  };
  return (
    <>
      <form
        onSubmit={handleSubmit(handlesShiftSubmit)}
        className="w-full relative h-full bg-white rounded-[12px] py-[28px] px-[30px]"
      >
        <FormError
          className="absolute top-0 z-20 transform left-2/4 -translate-x-2/4"
          err={error}
          onDismiss={onReset}
        />
        <div className="flex flex-col gap-y-7 justify-center items-center">
          <div className="relative w-full md:w-1/2">
            <label
              htmlFor="shiftName"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Shift Name
            </label>
            <input
              {...register("name", { required: true })}
              id="shiftName"
              type="text"
              className="w-full placeholder:text-[#ABAFB1] px-[16px] py-[14px] border-none focus:ring-0 rounded-lg bg-[#AA7DAA26]"
              placeholder="Enter Shift Name"
            />
            <InlineErr err={errors?.name ? "Shift name is required" : null} />
          </div>
          <div className="relative w-full md:w-1/2">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Time Range
            </label>

            {/* <DatePicker
              selected={new Date()}
              //   onChange={(value) => onChange(value?.toISOString())}
              dateFormat="yyyy-MM-dd HH:mm"
              showTimeInput
              timeInputLabel="Time:"
              customInput={
                <input
                  //   id={id}
                  // value={formatDateTime(new Date(value), dateTimeFormat)}
                  //   onChange={onChange}
                  className="placeholder:text-[#ABAFB1] px-[30px] py-[14px] border-none outline-none focus:ring-0 rounded-lg bg-[#AA7DAA26] w-full"
                />
              }
            />

           
            <Calendar className="aspect-square absolute top-[50%] !w-4 text-sec-alt-200 ml-2" /> */}
            <Controller
              control={control}
              name="timeRange"
              render={({ field: { onChange, value } }) => {
                return (
                  <TimeRangePicker
                    className=""
                    onChange={(value) => {
                      return onChange(value);
                    }}
                    value={value}
                  />
                );
              }}
            />
          </div>
          {/* <div className="relative w-full md:w-1/2">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Date To
            </label>

            <DatePicker
              selected={new Date()}
              //   onChange={(value) => onChange(value?.toISOString())}
              dateFormat="yyyy-MM-dd HH:mm"
              showTimeInput
              timeInputLabel="Time:"
              customInput={
                <input
                  //   id={id}
                  // value={formatDateTime(new Date(value), dateTimeFormat)}
                  //   onChange={onChange}
                  className="placeholder:text-[#ABAFB1] px-[30px] py-[14px] border-none outline-none focus:ring-0 rounded-lg bg-[#AA7DAA26] w-full"
                />
              }
            />

          
            <Calendar className="aspect-square absolute top-[50%] !w-4 text-sec-alt-200 ml-2" />
          
          </div> */}
          <div className="relative w-full md:w-1/2">
            <label
              htmlFor="name"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Status
            </label>
            <StatusToggler
              options={[
                { key: "Active", value: "true" },
                { key: "Inactive", value: "false" },
              ]}
              register={register}
              statusKey="status"
              className="w-1/2 !justify-start"
              errMsg=""
            />
          </div>
        </div>
        <div className="flex justify-center mt-10">
          <button
            type="submit"
            className="px-10 py-2 rounded-md text-white bg-app-purple-4 inline-flex items-center justify-center space-x-2"
          >
            <span>Save</span>
            <Spinner
              className="!w-3 !h-3 text-white fill-app-yellow"
              status={status}
            />
          </button>
        </div>
      </form>
    </>
  );
};

export { ShiftForm };
