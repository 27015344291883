import { useReducer, useCallback } from "react";

import { FETCH_STATUS } from "../utils";

import {
  fakeGetFormats,
  fakeGetCinemas,
  fakeGetTickets,
  fakeFetch,
} from "../utils/mockData/sessions";

const init = {
  formats: [],
  formatsStatus: FETCH_STATUS.IDLE,
  formatsErr: null,

  cinemas: [],
  cinemasStatus: FETCH_STATUS.IDLE,
  cinemasErr: null,

  tickets: [],
  ticketsStatus: FETCH_STATUS.IDLE,
  ticketsErr: null,

  editPriceCardStatus: FETCH_STATUS.IDLE,
  editPriceCardErr: null,

  createPriceCardStatus: FETCH_STATUS.IDLE,
  createPriceCardErr: null,
};

const actions = {
  getFormatsPending: "getFormatsPending",
  getFormatsResolved: "getFormatsResolved",
  getFormatsRejected: "getFormatsRejected",

  getPriceCardsPending: "getPriceCardsPending",
  getPriceCardsResolved: "getPriceCardsResolved",
  getPriceCardsRejected: "getPriceCardsRejected",

  createPriceCardPending: "createPriceCardPending",
  createPriceCardResolved: "createPriceCardResolved",
  createPriceCardRejected: "createPriceCardRejected",

  editPriceCardPending: "editPriceCardPending",
  editPriceCardResolved: "editPriceCardResolved",
  editPriceCardRejected: "editPriceCardRejected",

  getPriceCards2Pending: "getPriceCards2Pending",
  getPriceCards2Resolved: "getPriceCards2Resolved",
  getPriceCards2Rejected: "getPriceCards2Rejected",

  getCinemasPending: "getCinemasPending",
  getCinemasResolved: "getCinemasResolved",
  getCinemasRejected: "getCinemasRejected",

  getTicketsPending: "getTicketsPending",
  getTicketsResolved: "getTicketsResolved",
  getTicketsRejected: "getTicketsRejected",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.getFormatsPending:
      return {
        ...state,
        formatsStatus: FETCH_STATUS.PENDING,
        formatsErr: null,
      };

    case actions.getPriceCardsPending:
      return {
        ...state,
        priceCardsStatus: FETCH_STATUS.PENDING,
        priceCardsErr: null,
      };

    case actions.getPriceCards2Pending:
      return {
        ...state,
        priceCards2Status: FETCH_STATUS.PENDING,
        priceCards2Err: null,
      };

    case actions.getFormatsResolved:
      return {
        ...state,
        formats: action.payload,
        formatsStatus: FETCH_STATUS.RESOLVED,
        formatsErr: null,
      };

    case actions.getPriceCardsResolved:
      return {
        ...state,
        priceCardsStatus: FETCH_STATUS.RESOLVED,
        priceCardsErr: null,
        priceCards: action.payload,
      };

    case actions.getPriceCards2Resolved:
      return {
        ...state,
        priceCards2Status: FETCH_STATUS.RESOLVED,
        priceCards2Err: null,
        priceCards2: action.payload,
      };

    case actions.getFormatsRejected:
      return {
        ...state,
        formatsStatus: FETCH_STATUS.REJECTED,
        formatsErr: action.payload,
      };

    case actions.getPriceCardsRejected:
      return {
        ...state,
        priceCardsStatus: FETCH_STATUS.REJECTED,
        priceCardsErr: action.payload,
      };

    case actions.getPriceCards2Rejected:
      return {
        ...state,
        priceCards2Status: FETCH_STATUS.REJECTED,
        priceCards2Err: action.payload,
      };

    case actions.getCinemasPending:
      return {
        ...state,
        cinemasStatus: FETCH_STATUS.PENDING,
        cinemasErr: "",
      };

    case actions.getCinemasRejected:
      return {
        ...state,
        cinemasStatus: FETCH_STATUS.REJECTED,
        cinemasErr: action.payload,
      };

    case actions.getCinemasResolved:
      return {
        ...state,
        cinemas: action.payload,
        cinemasStatus: FETCH_STATUS.RESOLVED,
      };

    case actions.getTicketsPending:
      return {
        ...state,
        ticketsStatus: FETCH_STATUS.PENDING,
        ticketsErr: "",
      };

    case actions.getTicketsRejected:
      return {
        ...state,
        ticketsStatus: FETCH_STATUS.REJECTED,
        ticketsErr: action.payload,
      };

    case actions.getTicketsResolved:
      return {
        ...state,
        tickets: action.payload,
        ticketsStatus: FETCH_STATUS.RESOLVED,
      };

    case actions.editPriceCardRejected:
      return {
        ...state,
        editPriceCardStatus: FETCH_STATUS.REJECTED,
        editPriceCardErr: action.payload,
      };

    case actions.editPriceCardPending:
      return {
        ...state,
        editPriceCardStatus: FETCH_STATUS.PENDING,
        editPriceCardErr: null,
      };

    case actions.editPriceCardResolved:
      const cards = [...state.priceCards2];

      const cardIndex = cards.findIndex((c) => c.id === action.payload.id);

      if (cardIndex > -1) {
        cards[cardIndex] = action.payload;
      }

      return {
        ...state,
        editPriceCardStatus: FETCH_STATUS.RESOLVED,
        editPriceCardErr: null,
        priceCards2: cards,
      };

    case actions.createPriceCardRejected:
      return {
        ...state,
        createPriceCardStatus: FETCH_STATUS.REJECTED,
        createPriceCardErr: action.payload,
      };

    case actions.createPriceCardPending:
      return {
        ...state,
        createPriceCardStatus: FETCH_STATUS.PENDING,
        createPriceCardErr: null,
      };

    case actions.createPriceCardResolved:
      const cards2 = [...state.priceCards2, action.payload];

      return {
        ...state,
        createPriceCardStatus: FETCH_STATUS.RESOLVED,
        createPriceCardErr: null,
        priceCards2: cards2,
      };

    default:
      return state;
  }
};

const useResources = () => {
  const [state, dispatch] = useReducer(reducer, init);

  const getFilmFormats = useCallback(() => {
    const fn = async () => {
      dispatch({
        type: actions.getFormatsPending,
      });

      const resp = await fakeGetFormats();

      dispatch({
        type: actions.getFormatsResolved,
        payload: resp.data,
      });
    };

    fn();
  }, []);

  const getPriceCards = useCallback(() => {
    const fn = async () => {
      dispatch({
        type: actions.getPriceCardsPending,
      });

      const resp = await fakeGetTickets();

      dispatch({
        type: actions.getPriceCardsResolved,
        payload: resp.data,
      });
    };

    fn();
  }, []);

  const getCinemas = useCallback(() => {
    const fn = async () => {
      dispatch({
        type: actions.getCinemasPending,
      });

      const resp = await fakeGetCinemas();
      dispatch({
        type: actions.getCinemasResolved,
        payload: resp.data,
      });
    };

    fn();
  }, []);

  const getTickets = useCallback(() => {
    const fn = async () => {
      dispatch({
        type: actions.getTicketsPending,
      });

      const resp = await fakeGetTickets();
      dispatch({
        type: actions.getTicketsResolved,
        payload: resp.data,
      });
    };

    fn();
  }, []);

  const createPriceCard = useCallback((card) => {
    const fn = async () => {
      dispatch({
        type: actions.createPriceCardPending,
      });

      try {
        await fakeFetch(() => {
          dispatch({
            type: actions.createPriceCardResolved,
            payload: card,
          });
        });
      } catch (error) {
        dispatch({
          type: actions.createPriceCardRejected,
          payload: error || "Unable to create card",
        });
      }
    };

    fn();
  }, []);

  const editPriceCard = useCallback((card) => {
    const fn = async () => {
      dispatch({
        type: actions.editPriceCardPending,
      });

      try {
        await fakeFetch(() => {
          dispatch({
            type: actions.editPriceCardResolved,
            payload: card,
          });
        });
      } catch (error) {
        dispatch({
          type: actions.editPriceCardRejected,
          payload: error || "Unable to edit card",
        });
      }
    };

    fn();
  }, []);

  return {
    getFilmFormats,
    getPriceCards,
    getCinemas,
    getTickets,
    editPriceCard,
    createPriceCard,
    state,
  };
};

export { useResources };
