import add from "date-fns/add";
import differenceInMinutes from "date-fns/differenceInMinutes";
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import {timezoneKey} from "../constants";

dayjs.extend(utc);
dayjs.extend(timezone)

class Session {
  /**
   * Create a new session
   *
   * @param {Object} sessionConfig Information about the new session
   * @param {String} sessionConfig.filmId The id of the film to show in the session.
   * @param {String} sessionConfig.title The name of the film to show in the session.
   * @param {String} sessionConfig.screenId Screen id of the session.
   * @param {Date} sessionConfig.startTime The start of the session .
   * @param {Date} sessionConfig.endTime The end of the session.
   * @param {Number} sessionConfig.trailerTime The trailer duration (in minutes) the session.
   * @param {String} sessionConfig.priceCard The email of the film to show in the session.
   * @returns A new film session
   */
  static create({
    filmId,
    title,
    screenId,
    startTime,
    endTime,
    trailerTime,
    priceCard,
    id,
  }) {
    return {
      url: "https://api-reach.fusionintel.io",
      id: id || new Date().getTime(),
      filmId,
      filmPackageId: null,
      title,
      screenId,
      seating: "Open",
      areComplimentariesAllowed: false,
      ticketsSoldOut: false,
      fewTicketsLeft: false,
      showType: "Public",
      salesVia: ["WWW", "POS", "KIOSK", "MX", "RSP"],
      status: "Open",
      seatsAvailable: 104,
      seatsHeld: 0,
      seatsHouse: 0,
      seatsSold: 0,
      priceCardName: priceCard,
      attributes: [],
      audioLanguage: null,
      filmFormatId: "2D Digital",
      trailerTime: add(new Date(startTime), {
        minutes: -trailerTime,
      }).toISOString(),
      startTime: new Date(startTime).toISOString(),
      endTime: new Date(endTime).toISOString(),
      cleanupTime: new Date(endTime).toISOString(),
    };
  }

  static toDTO(body) {
    return {
      // screenId: "string",
      // priceCardId: "string",
      // trailerTime: 0,
      // cleanUpTime: 0,
      // startTime: "2022-06-29T20:35:40.383Z",
      // endTime: "2022-06-29T20:35:40.383Z",
      // duration: 0,
      // status: 0, //ask?
    };
  }

  static toUI(body) {
    const tz = localStorage.getItem(timezoneKey)
    const newData = {
      ...body,
      title: body?.title || body?.film,
      priceCardName: body.priceCard?.name || "",
      seatsAvailable: body.totalSeats,
      duration: Math.abs(
        differenceInMinutes(new Date(body.startTime), new Date(body.endTime))
      ),
    };

    delete newData.priceCard;

    return newData;
  }
}

export { Session };
