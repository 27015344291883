import React, { useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";

import {
  dayMap,
  FETCH_STATUS,
  priceCardInit,
  priceCardSchema,
  trueFalse,
} from "../../utils";
import { FormError, InlineErr, CancelSaveToolbar } from "../UI";
import TicketsTable from "./TicketsTable";
import { selectTickets, getTickets } from "../../redux/slices";
import { useLoggedInuser } from "../../hooks";

const days = Object.values(dayMap).map((d) => d.toLowerCase().substring(0, 3));

const PriceCardForm = ({
  onSave,
  isEdit,
  cardInfo,
  error,
  status,
  onReset,
}) => {
  const { tickets, ticketsStatus, ticketsErr } = useSelector(selectTickets);

  const { profile, userErr } = useLoggedInuser();

  const dispatch = useDispatch();

  const {
    register,
    reset,
    watch,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    resolver: yupResolver(priceCardSchema),
    defaultValues: priceCardInit,
  });

  const watchDays = watch("days");
  const watchedTickets = watch("tickets");

  const updateTicketData = (rowIndex, columnId, value) => {
    const newTicketValues = [...watchedTickets];

    newTicketValues[rowIndex][columnId] = value;

    

    setValue("tickets", newTicketValues);
  };

  const onAddNewtickets = (tickets = []) => {
    const newTicketValues = [
      ...tickets.map((t) => ({ ...t })),
      ...watchedTickets,
    ];

    setValue("tickets", newTicketValues);
  };

  const removeItem = (rowIndex) => {
    const newTicketValues = [...watchedTickets].filter(
      (_, i) => i !== +rowIndex
    );

    setValue("tickets", newTicketValues);
  };

  const getTicketList = useCallback(
    (force) => {
      if (force || ticketsStatus === FETCH_STATUS.REJECTED) {
        dispatch(getTickets(force));
      }
    },
    [dispatch, ticketsStatus]
  );

  useEffect(() => {
    if (ticketsStatus === FETCH_STATUS.IDLE) {
      dispatch(getTickets());
    }
  }, [dispatch, ticketsStatus]);

  useEffect(() => {
    if (isEdit) {
      const info = {
        ...cardInfo,
        days: cardInfo?.days?.map((v) => v.toString()) || [],
        active: cardInfo?.active?.toString(),
      };
      reset({ ...priceCardInit, ...info });
    }
  }, [cardInfo, reset, isEdit]);

  const onSubmit = async (values) => {
    const body = {
      ...values,
      active: trueFalse[values.active],
      days: values.days.map((v) => +v),
      cinemaId: values.cinemaId || profile.cinemaId,
    };

    onSave(body);
  };

  return (
    <form className="relative pb-32" onSubmit={handleSubmit(onSubmit)}>
      <FormError
        className="absolute top-0 z-20 transform left-2/4 -translate-x-2/4"
        err={error}
        onDismiss={onReset}
      />

      <FormError
        className="absolute top-0 z-20 transform left-2/4 -translate-x-2/4"
        err={userErr}
      />

      <CancelSaveToolbar backPath={"/dashboard/price-cards"} status={status} />

      <section className="w-full px-3 py-8 bg-app-purple-2">
        <h3 className="text-lg font-medium">Name & Validity</h3>
        <p className="text-base font-normal text-app-gray-2">
          Enter the name of your price card
        </p>

        <div className="max-w-4xl mb-5 space-y-5 mt-11">
          <div className="grid items-center gap-10 grid-cols-[12%_minmax(0,1fr)]">
            <label className="text-right" htmlFor="name">
              Name:
            </label>

            <div className="relative">
              <input
                className="w-full p-2 rounded bg-app-purple-10"
                id="name"
                name="name"
                type="text"
                {...register("name")}
              />
              <InlineErr err={errors?.name?.message} />
            </div>
          </div>

          <div className="grid items-center gap-10 grid-cols-[12%_minmax(0,1fr)]">
            <label className="text-right" htmlFor="description">
              Description:
            </label>

            <div className="relative">
              <textarea
                className="w-full p-2 rounded bg-app-purple-10"
                id="description"
                name="description"
                rows={6}
                {...register("description")}
              />
              <InlineErr err={errors?.description?.message} />
            </div>
          </div>

          <div className="grid items-center gap-10 grid-cols-[12%_minmax(0,1fr)]">
            <label className="text-right" htmlFor="days">
              Day(s):
            </label>

            <div className="relative flex flex-wrap items-center ">
              {days.map((d, i) => (
                <label
                  className={`text-center transition-colors shadow rounded p-2.5 m-2 first:ml-0 text-xs md:text-base font-bold md:py-2.5 md:px-5 uppercase ${
                    watchDays.includes(i.toString())
                      ? "bg-app-green text-app-black"
                      : "bg-app-blue text-white"
                  } `}
                  id="days"
                  key={d}
                >
                  <input
                    className="sr-only"
                    name="days"
                    {...register("days")}
                    value={i}
                    type="checkbox"
                  />
                  {d}
                </label>
              ))}

              <InlineErr err={errors?.days?.message} />
            </div>
          </div>

          <div className="grid items-center gap-10 grid-cols-[12%_minmax(0,1fr)] ">
            <label className="text-right" htmlFor="active">
              Status:
            </label>

            <div className="relative">
              <div className="inline-flex p-2.5 rounded-xl bg-app-purple-10">
                <div className="flex items-center mr-4">
                  <input
                    id="active"
                    type="radio"
                    value="true"
                    name="active"
                    className="w-4 h-4 bg-white focus:!border-none text-app-black !ring-transparent "
                    {...register("active")}
                  />
                  <label
                    htmlFor="active"
                    className="ml-2 text-sm font-medium text-app-black "
                  >
                    Active
                  </label>
                </div>
                <div className="flex items-center mr-4">
                  <input
                    id="inactive"
                    type="radio"
                    value="false"
                    name="active"
                    className="w-4 h-4 bg-white focus:!border-none text-app-black !ring-transparent  "
                    {...register("active")}
                  />
                  <label
                    htmlFor="inactive"
                    className="ml-2 text-sm font-medium text-app-black "
                  >
                    Inactive
                  </label>
                </div>
              </div>
              <InlineErr err={errors?.active?.message} />
            </div>
          </div>
        </div>

        <hr className="mt-10 -mx-3 border-app-purple-8" />

        <h3 className="mx-3 my-2 text-lg font-medium">Included Tickets</h3>
        <p className="mx-3 my-2 text-base font-normal text-app-gray-2">
          Click the Add ticket button to include a ticket in your price card
        </p>

        <TicketsTable
          tickets={tickets}
          includedTickets={watchedTickets}
          updateTicketData={updateTicketData}
          retry={getTicketList}
          err={ticketsErr}
          ticketsStatus={ticketsStatus}
          removeItem={removeItem}
          onAddNewtickets={onAddNewtickets}
        />
      </section>
    </form>
  );
};

export { PriceCardForm };
