import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormError, Input, Spinner } from "../UI";
import { StaffSession, changeArrShape, trueFalse } from "../../utils";

import { useRequest } from "../../hooks";
import {
  API_URL,
  axiosApiInstance,
  formatNumberAsCurrency,
  onReject,
  staffSessionInit,
  staffSessionSchema,
} from "../../utils";
import { selectFinance, updateSession } from "../../redux/slices";
import CloseSessionConfirmation from "./CloseSessionConfirmation";
import { OtherChannelModal } from "./OtherChannelModal";

const wrapperClass = "!block pb-4";
const labelClass = "my-1 block !text-left";
const inputClass = "w-full !bg-transparent";
const inputClass2 = "w-full !bg-transparent border-none";
const inputClass3 = "w-full !bg-transparent border-none text-red-500";

const UpdateSessionModal = ({
  activeSession,
  channels = [],
  onSuccess,
  onCancel,
}) => {
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openOtherChannel, setOpenOtherChannel] = useState(false);
  const [channelValues, setChannelValues] = useState({});
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const { err, status, setResolved, setPending, setRejected, resetErr } =
    useRequest();
  const {
    err: err2,
    status: status2,
    setResolved: setResolved2,
    setPending: setPending2,
    setRejected: setRejected2,
    resetErr: resetErr2,
  } = useRequest();

  const dispatch = useDispatch();

  const sessionChannels = useMemo(() => {
    if (Object.values(channelValues).length > 0) {
      const value = channels?.reduce((acc, curr) => {
        if (curr.id in channelValues) {
          return [
            ...acc,
            {
              otherChannelId: curr?.id,
              // otherChannel: curr?.name,
              actual: channelValues[curr.id]?.actual,
            },
          ];
        }
        return acc;
      }, []);
      return value;
    }

  }, [channelValues, channels]);

  const {
    register,
    reset,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(staffSessionSchema),
    defaultValues: staffSessionInit,
  });

  const defaultCustomValues = useMemo(() => {
    const newSessionChannel = changeArrShape(
      "otherChannelId",
      activeSession?.sessionChannels,
      {}
    );

    return channels?.reduce((acc, curr) => {
      acc[curr.id] = {
        actual: newSessionChannel[curr.id]?.actual || 0,
        expected: newSessionChannel[curr?.id]?.expected || 0,
      };
      return acc;
    }, {});

  }, [activeSession?.sessionChannels, channels]);

  const totalCustomValues = useMemo(() => {
    if (Object.values(channelValues).length > 0) {
      const sum = Object.values(channelValues).reduce((acc, curr) => {
        return acc + +curr?.actual;
      }, 0);
      return sum;
    }
  }, [channelValues]);

  const closeSession = () => {
    handleSubmit(async (values) => {
    
      setPending();

      try {
        const newSession = await axiosApiInstance.patch(
          `${API_URL}/Sessions/CloseSession`,
          values
        );

        setResolved();
        const formattedSession = StaffSession.toUI(newSession.data.data);
        dispatch(
          updateSession({
            ...formattedSession,
          })
        );

        onSuccess();
      } catch (error) {
        onReject(setRejected, error, "Unable to create new sessions");
      }
    })();
  };

  const saveDraft = () => {
    handleSubmit(async (values) => {
      setPending2();

      try {
        const newSession = await axiosApiInstance.patch(
          `${API_URL}/Sessions/SaveDraft`,
          values
        );

        setResolved2();
        const formattedSession = StaffSession.toUI(newSession.data.data);

        dispatch(
          updateSession({
            ...formattedSession,
          })
        );

        onSuccess();
      } catch (error) {
        onReject(setRejected2, error, "Unable to create new sessions");
      }
    })();
  };

  useEffect(() => {
    if (activeSession.id) {
      reset({
        ...staffSessionInit,
        sessionId: activeSession.sessionId || activeSession.id,
        actualCash: activeSession.actualCash,
        actualCard: activeSession.actualCard,
        actualTransfer: activeSession.actualTransfer,
        actualExternalVoucher: activeSession.actualExternalVoucher,
        actualOtherChannel: activeSession.actualOtherChannel,
      });
    }
  }, [reset, activeSession]);

  useEffect(() => {
    if (defaultCustomValues) {
      setChannelValues(defaultCustomValues);
    }
  }, [defaultCustomValues]);

  useEffect(() => {
    setValue("actualOtherChannel", totalCustomValues);
    setValue("sessionChannels", sessionChannels);
  }, [sessionChannels, setValue, totalCustomValues]);

  const actualCard = watch("actualCard");
  const actualCash = watch("actualCash");
  const actualTransfer = watch("actualTransfer");
  const actualExternalVoucher = watch("actualExternalVoucher");
  const actualOtherChannel = watch("actualOtherChannel");

  return (
    !!activeSession && (
      <Transition appear show={!!activeSession} as={Fragment}>
        <Dialog as="div" className="relative z-20" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center max-h-screen min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  as={"form"}
                  className="relative w-full max-w-xl p-6 overflow-auto max-h-screen text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
                >
                  <FormError
                    className="absolute top-0 z-20 transform left-2/4 -translate-x-2/4"
                    err={err || err2}
                    onDismiss={err ? resetErr : resetErr2}
                  />
                  <button
                    type="button"
                    onClick={onCancel}
                    className="flex absolute top-4 right-4 -mx-1.5 -my-1.5 bg-white text-gray-400 transition-colors p-1 hover:bg-slate-400 rounded-md hover:text-white"
                    aria-label="Close"
                  >
                    <span className="sr-only">Close</span>
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>

                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Session with ID - <i>{activeSession?.id}</i>
                  </Dialog.Title>
                  <hr className="my-3" />
                  <div className="mt-2 mb-4">
                    <p className="text-sm text-gray-500">
                      You are about to update a session for{" "}
                      {activeSession?.name}
                    </p>
                  </div>
                  <div className="flex items-center justify-center my-[20px]">
                    <button
                      type="button"
                      className="relative min-w-[150px] flex justify-center items-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-app-purple-4"
                      onClick={() => {
                        navigate(
                          `/dashboard/reports/cashier-session-reconcilation/${activeSession?.dateCreated}/${activeSession?.userId}`
                        );
                      }}
                    >
                      View Session Report
                    </button>
                  </div>
                  <div className="grid grid-cols-3 gap-5">
                    <Input
                      label="Expected Cash:"
                      type="text"
                      wrapperClass={wrapperClass}
                      labelClass={labelClass}
                      inputClass={inputClass2}
                      value={formatNumberAsCurrency(
                        activeSession?.expectedCash
                      )}
                      disabled
                    />
                    <Input
                      label="Actual Cash:"
                      id="actualCash"
                      type="number"
                      wrapperClass={wrapperClass}
                      labelClass={labelClass}
                      inputClass={inputClass}
                      errorMsg={errors?.actualCash?.message}
                      {...register("actualCash")}
                    />
                    <Input
                      label="Cash Variance:"
                      type="text"
                      wrapperClass={wrapperClass}
                      labelClass={labelClass}
                      inputClass={
                        actualCash - activeSession?.expectedCash !== 0
                          ? inputClass3
                          : inputClass2
                      }
                      disabled
                      value={formatNumberAsCurrency(
                        actualCash - activeSession?.expectedCash
                      )}
                    />
                    <Input
                      label="Expected Card:"
                      type="string"
                      wrapperClass={wrapperClass}
                      labelClass={labelClass}
                      inputClass={inputClass2}
                      value={formatNumberAsCurrency(
                        activeSession?.expectedCard
                      )}
                      disabled
                    />
                    <Input
                      label="Actual Card:"
                      id="actualCard"
                      type="number"
                      wrapperClass={wrapperClass}
                      labelClass={labelClass}
                      inputClass={inputClass}
                      errorMsg={errors?.actualCard?.message}
                      {...register("actualCard")}
                    />
                    <Input
                      label="Card Variance:"
                      type="string"
                      wrapperClass={wrapperClass}
                      labelClass={labelClass}
                      inputClass={
                        actualCard - activeSession?.expectedCard !== 0
                          ? inputClass3
                          : inputClass2
                      }
                      disabled
                      value={formatNumberAsCurrency(
                        actualCard - activeSession?.expectedCard
                      )}
                    />
                    <Input
                      label="Expected Transfer:"
                      type="string"
                      wrapperClass={wrapperClass}
                      labelClass={labelClass}
                      inputClass={inputClass2}
                      disabled
                      value={formatNumberAsCurrency(
                        activeSession?.expectedTransfer
                      )}
                    />
                    <Input
                      label="Actual Transfer:"
                      id="actualTransfer"
                      type="number"
                      wrapperClass={wrapperClass}
                      labelClass={labelClass}
                      inputClass={inputClass}
                      errorMsg={errors?.actualTransfer?.message}
                      {...register("actualTransfer")}
                    />
                    <Input
                      label="Transfer Variance:"
                      type="string"
                      wrapperClass={wrapperClass}
                      labelClass={labelClass}
                      inputClass={
                        actualTransfer - activeSession?.expectedTransfer !== 0
                          ? inputClass3
                          : inputClass2
                      }
                      disabled
                      value={formatNumberAsCurrency(
                        actualTransfer - activeSession.expectedTransfer
                      )}
                    />
                    <Input
                      label="Expected Ext.Voucher:"
                      type="string"
                      wrapperClass={wrapperClass}
                      labelClass={labelClass}
                      inputClass={inputClass2}
                      disabled
                      value={formatNumberAsCurrency(
                        activeSession?.expectedExternalVoucher
                      )}
                    />
                    <Input
                      label="Actual Ext.Voucher:"
                      id="actualExternalVoucher"
                      type="number"
                      wrapperClass={wrapperClass}
                      labelClass={labelClass}
                      inputClass={inputClass}
                      errorMsg={errors?.actualExternalVoucher?.message}
                      {...register("actualExternalVoucher")}
                    />
                    <Input
                      label="Ext.Voucher Variance:"
                      type="string"
                      wrapperClass={wrapperClass}
                      labelClass={labelClass}
                      inputClass={
                        actualExternalVoucher -
                          activeSession?.expectedExternalVoucher !==
                        0
                          ? inputClass3
                          : inputClass2
                      }
                      disabled
                      value={formatNumberAsCurrency(
                        actualExternalVoucher -
                          activeSession.expectedExternalVoucher
                      )}
                    />

                    <Input
                      label="Expected Other Channel:"
                      type="string"
                      wrapperClass={wrapperClass}
                      labelClass={labelClass}
                      inputClass={inputClass2}
                      disabled
                      value={formatNumberAsCurrency(
                        activeSession?.expectedOtherChannel
                      )}
                    />

                    <Input
                      label="Actual Other Channel:"
                      id="actualOtherChannel"
                      type="number"
                      onClick={() => setOpenOtherChannel(true)}
                      wrapperClass={wrapperClass}
                      labelClass={labelClass}
                      inputClass={inputClass}
                      readOnly
                      errorMsg={errors?.actualOtherChannel?.message}
                      value={totalCustomValues}
                    />

                    <Input
                      label="Other Channel Variance:"
                      type="string"
                      wrapperClass={wrapperClass}
                      labelClass={labelClass}
                      inputClass={
                        actualOtherChannel -
                          activeSession?.expectedOtherChannel !==
                        0
                          ? inputClass3
                          : inputClass2
                      }
                      disabled
                      value={formatNumberAsCurrency(
                        actualOtherChannel - activeSession.expectedOtherChannel
                      )}
                    />
                  </div>
                  <div className="flex items-center justify-around mt-4">
                    <button
                      type="button"
                      onClick={saveDraft}
                      className="relative min-w-[150px] flex justify-center items-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-app-purple-4"
                    >
                      Save Draft
                      <Spinner
                        className="!w-3 !h-3 mx-1 text-white fill-amber-300"
                        status={status2}
                      />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setOpenConfirmation(true);
                      }}
                      className="relative min-w-[150px] flex justify-center items-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-app-purple-4"
                    >
                      Close Session
                      <Spinner
                        className="!w-3 !h-3 mx-1 text-white fill-amber-300"
                        status={status}
                      />
                    </button>
                  </div>
                  <CloseSessionConfirmation
                    openStatus={openConfirmation}
                    closeFunc={() => {
                      setOpenConfirmation(false);
                    }}
                    closeSession={closeSession}
                  />

                  <OtherChannelModal
                    show={openOtherChannel}
                    channels={channels}
                    channelValues={channelValues}
                    activeSession={activeSession}
                    setChannelValues={setChannelValues}
                    onClose={() => setOpenOtherChannel(false)}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    )
  );
};

export { UpdateSessionModal };
