import React from "react";
import BreadCrumb from "../../../components/BreadCrumbs/BreadCrumbs";
import { ErrorModal, PrivateRoute, SuccessModal, TaskForm } from "../../../components";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { addStaff, addTask, selectRoster } from "../../../redux/slices";
import { useDispatch, useSelector } from "react-redux";
import { useRequest } from "../../../hooks";
import { API_URL, FETCH_STATUS, axiosApiInstance, getAPIErrMessage } from "../../../utils";

const CreateTask = () => {
  const navigate = useNavigate();

  const { err, data, status, resetErr, setResolved, setPending, setRejected } =
    useRequest();

  
  const onSave = async (data) => {
    
    try {
      setPending();

      const res = await axiosApiInstance.post(
        `${API_URL}/RosterTasks/Create`,
        data
      );
      setResolved(res?.data?.data);

      // dispatch(addTask(res?.data?.data));
    } catch (error) {
      setRejected(error || "Unable to create task");
    }
  };

  return (
    <>
      <PrivateRoute redirectTo="/dashboard/users/create-task">
        <div className="flex items-center flex-col md:flex-row gap-5 justify-between mb-[25px]">
          <h3 className="text-xl font-[550]">Task</h3>
          <BreadCrumb />
        </div>
        <div className="bg-[#E6C4E7] rounded-[20px] border overflow-hidden border-[#AA7DAA]">
          <div className="px-5 py-5 flex justify-between items-center gap-4 bg-[#7E208080]">
            <h4 className="text-white text-lg font-sans">Create New Task</h4>
            <button
              type="button"
              onClick={() => navigate("/dashboard/users/tasks")}
            >
              <Icon
                icon="ion:close-circle-sharp"
                fontSize={26}
                color="#E5E5E5"
              />
            </button>
          </div>
          <div className="p-[19px] pb-[30px] bg-[#DDC4DD] min-h-[699px]">
            <div className="px-6 md:px-0 mx-auto md:w-[590px] lg:w-[700px] xl:w-[900px]">
              <TaskForm
                onSave={onSave}
                onReset={resetErr}
                status={status}
                error={err}
              />
            </div>
          </div>
        </div>
            {/* <ErrorModal
        show={status === FETCH_STATUS.REJECTED}
        message={getAPIErrMessage(err, "Unable to create shift")}
        onClose={resetErr}
        onClick={resetErr}
      /> */}
        <SuccessModal
          message="Task created successfully"
          show={status === FETCH_STATUS.RESOLVED}
          backLink={`/dashboard/users/tasks`}
        />
      </PrivateRoute>
    </>
  );
};

export { CreateTask };
