import { API_URL, axiosApiInstance, FETCH_STATUS } from "../../utils";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const createContract = createAsyncThunk(
  "createContract",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MovieContracts/Create`,
        data
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getAllContracts = createAsyncThunk(
  "getAllContract",
  async (cinemaId, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/MovieContracts/ListAll?${cinemaId}page=1&perPage=100`,
        
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const deleteContract = createAsyncThunk(
  "deleteContract",
  async (cinemaId, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/MovieContracts/${cinemaId}/Delete`,
        
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const getContract = createAsyncThunk(
  "getContract",
  async ({ id }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/MovieContracts/${id}`
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getContractReports = createAsyncThunk(
  "getContractReports",
  async (
    { dateFrom, dateTo, cinemaId, distributorId },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Reports/CinemaDistributorContractReport?DateFrom=${dateFrom}&DateTo=${dateTo}&CinemaId=${cinemaId}${
          distributorId === "allDistributors"
            ? ""
            : "&distributorId=" + distributorId
        }`
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const initialState = {
  contract: [],
  contractStatus: FETCH_STATUS.IDLE,
  contractErr: null,
  allContracts: [],
  allContractsStatus: FETCH_STATUS.IDLE,
  allContractsErr: null,
  contractReports: {},
  contractReportsStatus: FETCH_STATUS.IDLE,
  contractReportsErr: null,
  singleContract: {},
  singleContractStatus: FETCH_STATUS.IDLE,
  singleContractErr: null,
};

export const filmContractSlice = createSlice({
  name: "contract",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(createContract.pending, (state) => {
        state.contractStatus = FETCH_STATUS.PENDING;
        state.contractErr = "";
      })
      .addCase(createContract.fulfilled, (state, action) => {
        state.contractStatus = FETCH_STATUS.RESOLVED;
        state.contract = action.payload;
        state.contractErr = "";
      })
      .addCase(createContract.rejected, (state, action) => {
        state.contractStatus = FETCH_STATUS.REJECTED;
        state.contractErr = action.payload || "Unable to create contract";
      })
      .addCase(getAllContracts.pending, (state) => {
        state.allContractsStatus = FETCH_STATUS.PENDING;
        state.allContractsErr = "";
      })
      .addCase(getAllContracts.fulfilled, (state, action) => {
        state.allContractsStatus = FETCH_STATUS.RESOLVED;
        state.allContracts = action.payload;
        state.allContractsErr = "";
      })
      .addCase(getAllContracts.rejected, (state, action) => {
        state.allContractsStatus = FETCH_STATUS.REJECTED;
        state.allContractsErr = action.payload || "Unable to get contracts";
      })

      .addCase(getContractReports.pending, (state) => {
        state.contractReportsStatus = FETCH_STATUS.PENDING;
        state.contractReportsErr = "";
      })

      .addCase(getContractReports.fulfilled, (state, action) => {
        state.contractReportsStatus = FETCH_STATUS.RESOLVED;
        state.contractReports = action.payload;
        state.contractReportsErr = "";
      })
      .addCase(getContractReports.rejected, (state, action) => {
        state.contractReportsStatus = FETCH_STATUS.REJECTED;
        state.contractReportsErr =
          action.payload || "Unable to get contract reports";
      })

      .addCase(getContract.pending, (state) => {
        state.singleContractStatus = FETCH_STATUS.PENDING;
        state.singleContractErr = "";
      })
      .addCase(getContract.fulfilled, (state, action) => {
        state.singleContractStatus = FETCH_STATUS.RESOLVED;
        state.singleContract = action.payload;
        state.singleContractErr = "";
      })
      .addCase(getContract.rejected, (state, action) => {
        state.singleContractStatus = FETCH_STATUS.REJECTED;
        state.singleContractErr = action.payload || "Unable to get contract ";
      });
  },
});
export const contractReducer = filmContractSlice.reducer;
export const selectContract = (state) => state.contract;
