import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import ReachLogo from "../../../assets/images/logo.png";
import { useSelector } from "react-redux";
import { stockData } from "../../../redux/slices";
import { TransferReceiptLayout } from "../TransferReceiptLayout";
import { Spinner } from "../..";

const TransferReceiptView = ({
  onClose = () => {},
  show,
  approveFunc,
  declineFunc,
}) => {
  const { transferReceiptStatus } = useSelector(stockData);

  return (
    <>
      <Transition show={show} appear as={Fragment}>
        <Dialog as="div" className="relative z-[10000]" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex  justify-center my-10 p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[800px] max-h-[700px] px-5 py-2 overflow-auto text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <div className="flex justify-between">
                    <div
                      key={ReachLogo}
                      className=" ml-[-5px] w-[5rem] h-[5rem]  bg-no-repeat bg-contain bg-center "
                      style={{
                        backgroundImage: `url("${ReachLogo}")`,
                      }}
                    ></div>
                    <div
                      className={
                        "rounded-xl text-[15px] h-[30px] font-bold bg-[#E5E5E5] px-3 py-1 cursor-pointer"
                      }
                      onClick={onClose}
                    >
                      X
                    </div>
                  </div>
                  <div className="flex justify-center mb-2">
                    <Spinner
                      className="!w-10 !h-10"
                      status={transferReceiptStatus}
                      foreFill="fill-app-purple-4"
                      bgFill="text-gray-300"
                    />
                  </div>
                  <TransferReceiptLayout />

                  <div className="flex justify-end my-2">
                    <button
                      className="rounded bg-[#A62828] px-3 py-1 text-white hover:font-bold"
                      onClick={declineFunc}
                    >
                      {" "}
                      Reject
                    </button>
                    <button
                      className="rounded bg-[#2669FF] px-3 py-1 text-white hover:font-bold ml-3"
                      onClick={approveFunc}
                    >
                      {" "}
                      Approve
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export { TransferReceiptView };
