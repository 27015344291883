import React, {useRef} from "react";
import {
    ExportExcelBtn,
    PdfButton,
    Pdiv,
    PerformanceByFilmTable,
    PrivateRoute,
    ReportWrapper,
    Success,
} from "../../../components";
import {Controller, useForm} from "react-hook-form";
import DatePicker from "react-datepicker";
import {DateTimeInput} from "../../../components/Films/DateTimeInput";
import {useLoggedInuser} from "../../../hooks";
import {useDispatch, useSelector} from "react-redux";
import {getPerformanceByFilm, reportData} from "../../../redux/slices";
import {Element, scroller} from "react-scroll";
import {FETCH_STATUS, formatDateForApi} from "../../../utils";

const PerformanceByFilm = () => {
  const { performanceByFilm, performanceByFilmStatus, performanceByFilmErr } =
    useSelector(reportData);
  const { profile } = useLoggedInuser();
  const dispatch = useDispatch();
  const reportRef = useRef(null);

  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({
    // resolver: yupResolver(bookingByGenreSchema),
    defaultValues: {
      dateFrom: new Date().toISOString(),
      dateTo: new Date().toISOString(),
      cinemaId: profile?.cinemaId,
    },
  });

  const handleSubmitPerformanceByFilm = async (values) => {
    try {
      await dispatch(getPerformanceByFilm(values)).then(() => {
        scroller.scrollTo("reportContainer", {
          duration: 1500,
          delay: 0,
          smooth: true,
          offset: -100, // Scrolls to element + 50 pixels down the page
        });
      });
    } catch (error) {}
  };
  return (
    <div>
      <PrivateRoute redirectTo={"/dashboard/reports/performance-by-film"}>
        <div className="w-[95%] mx-auto mt-6">
          <ReportWrapper
            reportName="Performance By Film"
            goBackRoute={"/dashboard/reports"}
            submitFor={"performanceByFilm"}
            reportStatus={performanceByFilmStatus}
          >
            <form
              onSubmit={handleSubmit(handleSubmitPerformanceByFilm)}
              id={"performanceByFilm"}
            >
              <Pdiv label={"Date Range"}>
                <div className="flex items-center">
                  <Controller
                    control={control}
                    name="dateFrom"
                    render={({ field: { value, onChange } }) => (
                      <div className="relative ">
                        <DatePicker
                          placeholderText="Start Date"
                          selected={new Date(value)}
                          onChange={(value) =>
                              onChange(formatDateForApi(new Date(value)))
                          }
                          customInput={
                            <DateTimeInput
                              className="bg-app-purple-8"
                              dateFormat={"EEE, MMM dd, yyyy"}
                            />
                          }
                        />
                      </div>
                    )}
                  />

                  <p className="w-[6rem] text-center"> To:</p>
                  <Controller
                    control={control}
                    name="dateTo"
                    render={({ field: { value, onChange } }) => (
                      <div className="relative ">
                        <DatePicker
                          placeholderText="End Date"
                          selected={new Date(value)}
                          onChange={(value) =>
                              onChange(formatDateForApi(new Date(value)))
                          }
                          customInput={
                            <DateTimeInput
                              className="bg-app-purple-8"
                              dateFormat={"EEE, MMM dd, yyyy"}
                            />
                          }
                        />
                      </div>
                    )}
                  />
                </div>
              </Pdiv>
            </form>
          </ReportWrapper>
        </div>
        <Success show={performanceByFilmStatus === FETCH_STATUS.RESOLVED}>
          <Element name="reportContainer">
            {performanceByFilm?.length && (
              <>
                <div className="flex justify-center mt-5 space-x-4">
                  <PdfButton
                    refValue={reportRef}
                    docTitle={"performance-by-film"}
                  />
                  <ExportExcelBtn
                    id={"performanceByFilm-xlsx"}
                    name={"performanceByFilm.xlsx"}
                  />
                </div>
                <div ref={reportRef} className="m-2">
                  <PerformanceByFilmTable
                    data={performanceByFilm ?? []}
                    
                  />
                </div>
                <div  className="m-2 hidden">
                  <PerformanceByFilmTable
                    data={performanceByFilm ?? []}
                    // paramFilter={performanceByFilm?.paramFilter}
                    id={"performanceByFilm-xlsx"}
                  />
                </div>
              </>
            )}
          </Element>
          {!performanceByFilm?.length && (
            <p className="text-red-700 flex justify-center mt-3">
              No Data Found
            </p>
          )}
        </Success>
        {performanceByFilmStatus === FETCH_STATUS.REJECTED && (
          <p className="text-red-700 flex justify-center mt-3">
            Sorry an error occured...
          </p>
        )}
      </PrivateRoute>
    </div>
  );
};

export { PerformanceByFilm };
