import React from "react";
import { useDispatch } from "react-redux";

import {
  PageHeader,
  PrivateRoute,
  SuccessModal,
  TicketForm,
} from "../../../../components";

import { useRequest } from "../../../../hooks";
import { addTicket } from "../../../../redux/slices";

import {
  FETCH_STATUS,
  Ticket,
  API_URL,
  axiosApiInstance,
} from "../../../../utils";

const CreateTicket = () => {
  const { err, status, setResolved, setPending, setRejected, resetErr } =
    useRequest();

  const dispatch = useDispatch();

  const onSave = async (data) => {
    try {
      setPending();

      const info = Ticket.toDTO(data);

      const res = await axiosApiInstance.post(
        `${API_URL}/Tickets/Create`,
        info
      );

      let ticket = res.data.data;
      dispatch(addTicket(Ticket.toUI(ticket)));

      setResolved("Success");
    } catch (error) {
      setRejected(error || "Unable to create ticket");
    }
  };

  return (
    <PrivateRoute redirectTo="/dashboard/tickets/create">
      <PageHeader>
        <h1 className="text-2xl font-semibold text-white ">Create Ticket</h1>
      </PageHeader>
      <TicketForm
        onDismiss={resetErr}
        status={status}
        error={err}
        onSave={onSave}
      />
      <SuccessModal
        message="Created ticket."
        show={status === FETCH_STATUS.RESOLVED}
        backLink="/dashboard/tickets"
      />
    </PrivateRoute>
  );
};

export { CreateTicket };
