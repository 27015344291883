import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { API_URL, axiosApiInstance } from "../../utils";

export const revokeToken = createAsyncThunk("revokeToken", async (userId) => {
  await axiosApiInstance.post(
    `${API_URL}/Authentication/RevokeUserRefreshToken?userId=${userId}`
  );
});

const initialState = {
  token: "",
  refreshToken: "",
  userId: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      return initialState;
    },
    setTokens: (state, action) => {
      const newState = {
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        userId: action.payload.userId || state.userId,
      };

      return newState;
    },
  },
});

export const { logout, setTokens } = authSlice.actions;
export const authReducer = authSlice.reducer;
export const selectAuth = (state) => state.auth;
