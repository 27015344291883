import React from "react";
import { SearchSelect } from "../UI";
import { Delete } from "../Icons";
import { Controller } from "react-hook-form";
import { getSelectedItem2 } from "../../utils";

const FilmRow = ({ index, items, remove, control }) => {
  return (
    <>
      <div className="flex">
        <Controller
          control={control}
          name={`filmIds.${index}.filmId`}
          render={({ field: { value, onChange } }) => {
            return (
              <SearchSelect
                items={items}
                dropDownNameKey="name"
                inputClassName="!w-full p-2 px-4 py-2 border-none !bg-app-purple-10"
                listClassName="!py-2"
                id={`filmId`}
                value={getSelectedItem2(items, value, "id")}
                onChange={(value) => onChange(value?.id)}
                displayValue={(value) => {
                  return value?.name;
                }}
                placeholder={`Select Film`}
                searchOptions={{
                  keys: ["name"],
                }}
              />
            );
          }}
        />

        <button
          className="pr-3"
          type="button"
          onClick={() => {
            remove(index);
          }}
        >
          <Delete />
        </button>
      </div>
    </>
  );
};

export default FilmRow;
