import React, { useMemo } from "react";
import { dateAndTime, formatDateTime, formatNumberAsCurrency } from "../../utils";
import ReachLogo from "../../assets/images/logo.png";
import { DefaultListTable } from "../UI";
import { useLoggedInuser } from "../../hooks";
const StaffPerformanceTable = ({ data, id, paramFilter }) => {
  const {profile} = useLoggedInuser()
  const columns = useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Total Transactions",
        accessor: "totalTransactions",
        Cell: ({ value }) => id?value:formatNumberAsCurrency(value),
      },
      {
        Header: "Number of Sessions",
        accessor: "numberOfSessions",
      },
      {
        Header: "Number of Orders",
        accessor: "numberOfOrders",
      },
    ];
  }, [id]);

  return (
    <>
      <div className="flex justify-between items-center">
        <div
          key={ReachLogo}
          className=" ml-[-5px] w-[5rem] h-[3rem] bg-no-repeat bg-contain bg-center"
          style={{
            backgroundImage: `url("${ReachLogo}")`,
          }}
        ></div>

        <h3 className="font-bold text-base">{paramFilter?.cinema}</h3>
        <div>
          <p>
            {" "}
            <span className="font-bold">User Name:</span>
            {` ${profile.firstName} ${profile.lastName}`}
          </p>
          <p>
            <span className="font-bold">Date Time Generated: </span>
            {formatDateTime(paramFilter?.dateTimeGenerated, dateAndTime)}
          </p>
        </div>
        <div>
          <p className="font-bold">Data Range</p>
          <p>{new Date(paramFilter?.startDate).toDateString()}</p>
          <p>{new Date(paramFilter?.endDate).toDateString()}</p>
        </div>
      </div>

      <DefaultListTable columns={columns} data={data} id={id} />
    </>
  );
};

export { StaffPerformanceTable };
