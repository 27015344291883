import React, {useCallback, useEffect, useMemo} from 'react';
import {ClientPaginatedTable, ErrorWithWrapper, Loading, PageHeader, PrivateRoute,} from '../../../../components';
import {useDispatch, useSelector} from 'react-redux';
import {getStockTakeList, selectUser, stockData} from '../../../../redux/slices';
import {FETCH_STATUS, formatDateTime} from '../../../../utils';
import {Icon} from '@iconify/react';
import {useNavigate} from "react-router-dom";

const getClassList = (status) => {
  switch (status) {
    case 'pending':
      return 'bg-[#FFECCC] text-[#965E00]';
    case 'void':
      return 'bg-[#FFE0E0] text-[#D30000]';
    case 'completed':
      return 'bg-[#CDFFCD] text-[#007F00]';
    default:
      return 'bg-[#FFECCC] text-[#965E00]';
  }
};

const AllStockTake = () => {
  const navigate = useNavigate();
  const columns = useMemo(() => {
    return [
      {
        Header: 'Number',
        accessor: 'id',
      },
      {
        Header: 'Location',
        accessor: 'storeLocation',
      },
      {
        Header: 'Type',
        accessor: 'isStockAdjusted',
        Cell: (props) => {
          return <p>{props.value ? 'Stock Adjustment' : 'Stock Take'}</p>;
        },
      },
      {
        Header: 'Date',
        accessor: 'dateCreated',
        Cell: (props) => {
          return <>{formatDateTime(props.value, 'dd/mm/yyyy')}</>;
        },
      },
      {
        Header: 'Created By',
        accessor: 'user',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (props) => {
          return (
            <span
              className={`py-[6px] px-2.5 rounded-[10px] flex gap-1 items-center w-fit ${getClassList(
                props.value.toLowerCase()
              )}`}
            >
              <Icon icon="icon-park-outline:dot" />
              <span>{props.value}</span>
            </span>
          );
        },
      },
      {
        Header: 'Reference',
        accessor: 'reference',
      },
    ];
  }, []);

  const { stockTakes, stockTakesStatus, stockTakesErr } =
    useSelector(stockData);

  const dispatch = useDispatch();
  const {profile} = useSelector(selectUser)

  const getInfo = useCallback(() => {
    dispatch(getStockTakeList(profile.cinemaId));
  }, [dispatch]);

  const onRowClick = (row) => () => {
     navigate(`/dashboard/stock/stockTake/${row.original.id}`);
  };

  const onCreateClick = () => {
    navigate('/dashboard/stock/stockTake/create')
  }

  useEffect(() => {
    dispatch(getStockTakeList());
  }, []);

  return (
    <PrivateRoute redirectTo="/dashboard/stock/stockTake">
      <PageHeader className="mb-7 flex justify-between">
        <h1 className=" text-white font-semibold text-2xl items-center gap-3.5 grid grid-cols-[34px_minmax(0,1fr)]">
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="24"
              fill="none"
              viewBox="0 0 30 24"
            >
              <path
                fill="#fff"
                d="M15.654 0c.488 0 .884.385.884.86v3.607c0 .578.493 1.057 1.088 1.057.609 0 1.088-.479 1.088-1.057V.86c0-.475.396-.86.885-.86h4.729C27.46 0 30 2.466 30 5.51v3.552c0 .282-.116.55-.32.747-.202.197-.478.31-.768.31-1.073 0-1.944.845-1.944 1.874 0 1.036.86 1.877 1.924 1.888.599.006 1.108.412 1.108.994v3.615c0 3.042-2.539 5.51-5.672 5.51h-4.73a.872.872 0 01-.884-.86v-3.044c0-.592-.479-1.057-1.088-1.057-.595 0-1.088.465-1.088 1.057v3.044c0 .475-.396.86-.884.86H5.672C2.553 24 0 21.534 0 18.49v-3.615c0-.582.51-.988 1.108-.994 1.065-.01 1.924-.852 1.924-1.888 0-1-.841-1.762-1.944-1.762-.29 0-.566-.112-.769-.31A1.04 1.04 0 010 9.174v-3.65C0 2.486 2.559 0 5.687 0h9.967zm1.972 8.554c-.595 0-1.088.48-1.088 1.057v5.637c0 .578.493 1.057 1.088 1.057.609 0 1.088-.479 1.088-1.057V9.611c0-.578-.479-1.057-1.088-1.057z"
              ></path>
            </svg>
          </span>
          <span>StockTake</span>
        </h1>
         <button
            className="py-[12px] px-[36px] rounded-[14px] bg-[#AA7DAA] text-[#fff]"
            onClick={onCreateClick}
          >
            Create
          </button>
      </PageHeader>

      <ErrorWithWrapper
        error={stockTakesErr}
        show={stockTakesStatus === FETCH_STATUS.REJECTED}
        retry={getInfo}
      />

      <Loading
        wrapperClassName="relative min-h-[50vh]"
        show={stockTakesStatus === FETCH_STATUS.PENDING}
      />
      <ClientPaginatedTable
        onRowClick={onRowClick}
        tableData={stockTakes || []}
        columns={columns}
        getHeaderProps={(column) => ({
          className:
            'px-3 pt-8 pb-2 text-base font-medium border border-[#00000033] ',
          ...(column.id === 'film' ? column?.getSortByToggleProps() : {}),
        })}
        getCellProps={(cellInfo) => ({
          style: {},
          className: ` `,
        })}
        getColumnProps={() => ({ className: 'bg-app-purple-2', style: {} })}
      />
    </PrivateRoute>
  );
};

export { AllStockTake };
