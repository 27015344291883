import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef } from "react";
import { useForm } from "react-hook-form";
import { Alert, Input, Spinner, StatusToggler } from "../../UI";
import { useDispatch, useSelector } from "react-redux";
import {
  stockData,
  updateItemStock,
  updateItemStore,
} from "../../../redux/slices";
import { getAPIErrMessage } from "../../../utils";

const EditStoreItemModal = ({ show, prevItemData, onClose }) => {

  const { updateItemStoreStatus, updateItemStoreErr } = useSelector(stockData);
  const dispatch = useDispatch();
  const updateErrRef = useRef(null);
  const updateSuccessRef = useRef(null);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      stockTakeQuantity: prevItemData?.stockTakeQuantity || 0,
      thresholdQuantity: prevItemData?.thresholdQuantity,
      reorderLevel: prevItemData?.reorderLevel,
      unitOfMeasurementId: prevItemData?.unitOfMeasurementId,
    },
  });
  const handleSubmitItem = async (values) => {
    const updateErrModal = updateErrRef.current;
    const updateSuccessModal = updateSuccessRef.current;
  
    try {
      await dispatch(updateItemStore({ id: prevItemData?.id, data: values }))
        .unwrap()
        .then(() => {
          updateSuccessModal?.open();
          dispatch(
            updateItemStock({
              ...prevItemData,
              thresholdQuantity: values?.thresholdQuantity,
              reorderLevel: values?.reorderLevel,
            })
          )
          onClose()
        });
    } catch {
      updateSuccessModal?.close();
      updateErrModal?.open();
    }
  };
  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog as="div" className="relative z-[10000000]" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-75"
            leave="ease-in duration-75"
            leaveFrom="opacity-75"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full pb-6 max-w-md max-h-max overflow-hidden text-left transition-all transform bg-app-purple-2 shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-center text-white bg-gradient-to-b from-app-purple-4 to-app-purple-3 p-3.5 "
                  >
                    UPDATE ITEM
                  </Dialog.Title>
                  <>
                    <form onSubmit={handleSubmit(handleSubmitItem)}>
                      <div className="flex items-center justify-end p-2 bg-app-purple-5">
                        <div className="inline-flex items-center space-x-7">
                          <button
                            type="submit"
                            className="p-2 rounded min-w-[92px] text-app-yellow bg-app-purple-4 disabled:opacity-30 inline-flex items-center justify-center space-x-2"
                            // onClick={onClose}
                          >
                            Update Item
                            <Spinner
                              className="!w-[15px] !h-[15px] mx-1 m-auto text-white fill-pink-400"
                              status={updateItemStoreStatus}
                            />
                          </button>
                          <button
                            className="p-2 text-center rounded min-w-[92px] bg-app-yellow text-app-purple-6"
                            onClick={onClose}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>

                      <div className="flex flex-col items-center space-y-5 mt-5">
                        <Alert
                          ref={updateSuccessRef}
                          className="my-2"
                          type="success"
                          dismissable={true}
                        >
                          <span>Item updated successfully</span>
                        </Alert>

                        <Alert ref={updateErrRef} className="my-2">
                          <span className="flex justify-center">
                            {getAPIErrMessage(
                              updateItemStoreErr,
                              "Unable to update item"
                            )}
                          </span>
                        </Alert>

                        <Input
                          label="Threshold Quantity:"
                          labelClass="!text-left"
                          id="thresholdQuantity"
                          wrapperClass="!flex flex-col !items-stretch !gap-2 items-"
                          errorMsg={
                            errors?.threshholdQuantity
                              ? "Please enter threshold Quantity"
                              : null
                          }
                          type="number"
                          {...register("thresholdQuantity", {
                            required: true,
                          })}
                        />
                        <Input
                          label="Reorder Level:"
                          id="reorderLevel"
                          labelClass="!text-left"
                          wrapperClass="!flex flex-col !items-stretch !gap-2"
                          errorMsg={
                            errors?.threshholdQuantity
                              ? "Please enter reorder level"
                              : null
                          }
                          type="number"
                          {...register("reorderLevel", { required: true })}
                        />

                        {/* <div className={`grid ${wrapperClass}`}>
                          <p className="">Set isActive:</p>

                          <div className="">
                            <StatusToggler
                              options={[
                                { key: "Active", value: "true" },
                                { key: "Inactive", value: "false" },
                              ]}
                              register={register}
                              statusKey="isActive"
                              errMsg={
                                errors?.isActive
                                  ? "select an active status"
                                  : null
                              }
                            />
                          </div>
                        </div> */}
                      </div>
                    </form>
                  </>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export { EditStoreItemModal };
