import React from "react";
import { Tab } from "@headlessui/react";

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import { formatNumberAsCurrency, currencyKey } from "../../utils";

const modes = ["day", "week", "month"];
const keys = ["sales", "tickets", "concessions"];
const activeFill = process.env.REACT_APP_COLOR_10;
const inactiveFill = "#C9C5C5";
const inactiveText = "text-[#C9C5C5]";
const activeText = "text-[#FFB233]";


const CustomTooltip = ({ active, payload, label }) => {
  //Tried logging payload here but its undefined
  // console.log("the payload" + payload[0]);

  if (active && payload && payload.length) {
    return (
      <div className="p-3 max-w-sm bg-yellow-400 rounded-lg border border-gray-200 shadow-md">
        <p className="font-medium">{`${formatNumberAsCurrency(
          payload[0].value
        )}`}</p>
      </div>
    );
  }

  return null;
};

const Chart = ({ data }) => {
  const currency = localStorage.getItem(currencyKey);
  return (
    <ResponsiveContainer height={300} width="100%">
      <AreaChart width="100%" data={data} margin={{ left: 20, right: 20 }}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor={process.env.REACT_APP_COLOR_10}
              stopOpacity={0.8}
            />
            <stop
              offset="95%"
              stopColor={process.env.REACT_APP_COLOR_10}
              stopOpacity={0}
            />
          </linearGradient>
        </defs>
        <XAxis dataKey="period" />
        <YAxis
          tickFormatter={(tick) => {
            if (tick.toString().length <= 6) {
              let formattedTick = tick / 1000;
              return currency + formattedTick + "K";
            }
            if (tick.toString().length >= 7) {
              let formattedTick = tick / 1000000;
              return currency + formattedTick + "M";
            }
          }}
          type={"number"}
        />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip content={<CustomTooltip />} />

        <Area
          type="monotone"
          dataKey="amount"
          stroke={process.env.REACT_APP_COLOR_10}
          fillOpacity={1}
          fill="url(#colorUv)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

const ChartTabs = ({ data }) => {
  return (
    <div className="w-full px-2 sm:px-0">
      <Tab.Group>
        <Tab.List className="flex p-1 space-x-5 ">
          {keys.map((key) => (
            <Tab
              key={key}
              className={({ selected }) =>
                `w-full rounded-lg p-4 border-2 transition-colors ${
                  selected
                    ? "bg-white border-app-purple-10 border-2"
                    : "bg-[#F3F3F3] border-[transparent]"
                }`
              }
            >
              {({ selected }) => (
                <div className="font-sans text-base font-medium capitalize">
                  <p className="flex items-center justify-start">
                    {data[key].icon(selected ? activeFill : inactiveFill)}
                    <span
                      className={`ml-2 ${selected ? activeText : inactiveText}`}
                    >
                      {key}
                    </span>
                  </p>
                  <p
                    className={`text-left mt-2 font-semibold  ${inactiveText}`}
                  >
                    {formatNumberAsCurrency(data[key]?.revenue)}
                  </p>

                  {/* <p
                    className={`text-left mt-1 font-semibold text-[13px]  ${inactiveText}`}
                  >
                    {"Today: " +
                      formatNumberAsCurrency(data[key]?.revenue?.dailyRevenue)}
                  </p>

                  <p
                    className={`text-left mt-1 font-semibold text-[13px]  ${inactiveText}`}
                  >
                    {"This Week: " +
                      formatNumberAsCurrency(data[key]?.revenue?.weeklyRevenue)}
                  </p>
                  <p
                    className={`text-left mt-1 font-semibold text-[13px]  ${inactiveText}`}
                  >
                    {"This Month: " +
                      formatNumberAsCurrency(
                        data[key]?.revenue?.monthlyRevenue
                      )}
                  </p>
                  <p
                    className={`text-left mt-1 font-semibold text-[13px]  ${inactiveText}`}
                  >
                    {"This Year: " +
                      formatNumberAsCurrency(data[key]?.revenue?.yearlyRevenue)}
                  </p> */}
                </div>
              )}
            </Tab>
          ))}
        </Tab.List>

        <Tab.Panels className="mt-2">
          {keys.map((key) => (
            <Tab.Panel key={key} className={"rounded-xl bg-white p-3"}>
              <h2 className="capitalize my-4 font-semibold text-2xl ">
                {" "}
                {key}
              </h2>

              <Tab.Group>
                <Tab.List className="flex my-4 p-1 space-x-5 ">
                  {modes.map((mode) => (
                    <Tab
                      key={mode}
                      className={({ selected }) =>
                        `rounded-full min-w-[80px] font-medium p-2 text-base transition-colors ${
                          !selected ? "bg-transparent" : "bg-app-purple-10"
                        }`
                      }
                    >
                      {({ selected }) => (
                        <p
                          className={`capitalize ${
                            selected ? "text-white" : "text-[#5C5C5C]"
                          }`}
                        >
                          {mode}
                        </p>
                      )}
                    </Tab>
                  ))}
                </Tab.List>

                <Tab.Panels>
                  {modes.map((mode) => (
                    <Tab.Panel key={mode}>
                      <Chart data={data[key][mode]} />
                    </Tab.Panel>
                  ))}
                </Tab.Panels>
              </Tab.Group>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export { ChartTabs };
