import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useMemo } from "react";
import { DefaultListTable, ErrorModal, Spinner } from "../UI";
import {
  FETCH_STATUS,
  formatDateTime,
  formatNumberAsCurrency,
  getAPIErrMessage,
} from "../../utils";
import { format } from "../Films/DateTimeInput";

const SupplementaryOrderModal = ({
  show,
  onClose = () => {},
  data,
  status,
  error,
  resetErr,
}) => {
  const columns = useMemo(() => {
    return [
      {
        Header: "Order Item Details",
        columns: [
          {
            Header: "Item Name",
            // accessor:"itemSales"
            Cell: ({ row: { original } }) => {
              const allItems = [...original.itemSales, ...original.ticketSales];
              return allItems.map((sale, i) => <p key={i}>{sale.item || sale.film}</p>);
            },
          },
          {
            Header: "Quantity",
            Cell: ({ row: { original } }) => {
              const allItems = [...original.itemSales, ...original.ticketSales];
              return allItems.map((sale, i) => <p key={i}>{Math.abs(sale.quantity)}</p>);
            },
          },
          {
            Header: "Unit price",
            Cell: ({ row: { original } }) => {
              const allItems = [...original.itemSales, ...original.ticketSales];
              return allItems.map((sale, i) => (
                <p key={i}>{formatNumberAsCurrency(sale.price || sale.ticketPrice)}</p>
              ));
            },
          },
          {
            Header: "Sub Total",
            Cell: ({ row: { original } }) => {
              const allItems = [...original.itemSales, ...original.ticketSales];
              return allItems.map((sale, i) => (
                <p key={i}>
                  {formatNumberAsCurrency(sale.amount)}
                </p>
              ));
            },
          },
        ],
      },
    ];
  }, []);
  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog as="div" className="relative z-[10000000]" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-75"
            leave="ease-in duration-75"
            leaveFrom="opacity-75"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full pb-6 max-w-2xl max-h-max overflow-hidden text-left transition-all transform bg-app-purple-2 shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-center text-white bg-gradient-to-b from-app-purple-4 to-app-purple-3 p-3.5 "
                  >
                    ORDER DETAILS
                  </Dialog.Title>
                  <>
                    {status === FETCH_STATUS.PENDING && (
                      <>
                        <div className="flex justify-center my-[30px]">
                          <Spinner
                            className="!w-[40px] !h-[40px] mx-1 m-auto text-white fill-pink-400"
                            status={status}
                          />
                        </div>
                      </>
                    )}
                    {status === FETCH_STATUS.RESOLVED && (
                      <>
                        <div className="flex justify-between m-2">
                          <div>
                            <p className="font-medium">
                              User:{" "}
                              <span className="font-bold">{data?.user}</span>
                            </p>
                            <p className="font-medium">
                              Amount:{" "}
                              <span className="font-bold">
                                {formatNumberAsCurrency(data?.amount)}
                              </span>
                            </p>
                          </div>
                          <div>
                            <p className="font-medium">
                              Date Created:{" "}
                              <span className="font-bold">
                                {formatDateTime(
                                  new Date(data?.dateCreated),
                                  format
                                )}
                              </span>
                            </p>
                            <p></p>
                          </div>
                        </div>
                        <DefaultListTable
                          data={[data]}
                          columns={columns}
                          headerClass={"!text-sm !font-bold"}
                        />
                      </>
                    )}
                    <div className="flex items-center justify-center p-2">
                      <div className="inline-flex items-center space-x-7">
                        <button
                          className="p-2 text-center rounded min-w-[92px] bg-app-yellow text-app-purple-6"
                          onClick={onClose}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                    <ErrorModal
                      show={status === FETCH_STATUS.REJECTED}
                      message={getAPIErrMessage(
                        error,
                        " Unable to view refund"
                      )}
                      // onClose={onClose}
                      onClick={onClose}
                    />
                  </>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default SupplementaryOrderModal;
