import React, { forwardRef } from "react";
import { useReactToPrint } from "react-to-print";

const PdfButton = forwardRef(({ refValue, docTitle }, ref) => {
  const handlePrint = useReactToPrint({
     pageStyle: `@media print {
      @page {
        size: 400mm 400mm;
        margin: 0;
      }
    }`,
    content: () => refValue.current,
    documentTitle: docTitle,
  });

  return (
    <>
      <button
        ref={ref}
        type={"button"}
        className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm"
        onClick={handlePrint}
      >
        Generate PDF
      </button>
    </>
  );
});
export { PdfButton };
