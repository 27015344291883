import React, { Fragment } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormError, Input, Spinner } from "../UI";

import { useRequest } from "../../hooks";
import {
  API_URL,
  axiosApiInstance,
  FETCH_STATUS,
  pwdInit,
  pwdSchema,
} from "../../utils";
import { SuccessToast } from "../Films";

const wrapperClass = "!block pb-4";
const labelClass = "my-1 block !text-left";
const inputClass = "w-full !bg-transparent";

const UpdatePwdModal = ({ show, handleClose, name, id }) => {
  const {
    err,
    status,
    setResolved,
    setPending,
    setRejected,
    data,
    resetErr,
    reset: setInit,
  } = useRequest({ message: "" });

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(pwdSchema),
    defaultValues: pwdInit,
  });

  const onClose = () => {
    reset({ ...pwdInit });
    setInit();
    handleClose();
  };

  const onSubmit = async (values) => {
    setPending();

    try {
      await axiosApiInstance.patch(
        `${API_URL}/Users/${id}/ChangePassword`,
        values
      );

      setResolved({ message: "Password Changed Successfully" });
    } catch {
      setRejected("Unable to update password");
    }
  };

  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog as="div" className="relative z-[100000]" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  as={"form"}
                  className="relative w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <FormError
                    className="absolute top-0 z-20 transform left-2/4 -translate-x-2/4"
                    err={err}
                    onDismiss={resetErr}
                  />
                  <button
                    type="button"
                    onClick={onClose}
                    className="flex absolute top-4 right-4 -mx-1.5 -my-1.5 bg-white text-gray-400 transition-colors p-1 hover:bg-slate-400 rounded-md hover:text-white"
                    aria-label="Close"
                  >
                    <span className="sr-only">Close</span>
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>

                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Update user password for <i>{name}</i>
                  </Dialog.Title>
                  <hr className="my-3" />

                  <Input
                    label="Old Password"
                    id="oldPassword"
                    type="password"
                    isPwd
                    autoComplete="new-password"
                    wrapperClass={wrapperClass}
                    labelClass={labelClass}
                    inputClass={inputClass}
                    errorMsg={errors?.oldPassword?.message}
                    {...register("oldPassword")}
                  />

                  <Input
                    label="New Password"
                    id="newPassword"
                    type="password"
                    isPwd
                    autoComplete="new-password"
                    wrapperClass={wrapperClass}
                    labelClass={labelClass}
                    inputClass={inputClass}
                    errorMsg={errors?.newPassword?.message}
                    {...register("newPassword")}
                  />

                  <div className="flex items-center justify-center mt-4">
                    <button
                      type="submit"
                      className="relative min-w-[150px] flex justify-center items-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-app-purple-4"
                    >
                      Update
                      <Spinner
                        className="!w-3 !h-3 mx-1 text-white fill-amber-300"
                        status={status}
                      />
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <SuccessToast
        show={status === FETCH_STATUS.RESOLVED}
        message={data?.message}
        handleClose={onClose}
      />
    </>
  );
};

export default UpdatePwdModal;
