import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {
  PrivateRoute,
  StaffForm,
  PageHeader,
  SuccessModal,
  Loading,
  Success,
  ErrorWithWrapper,
} from "../../../components";
import { useRequest } from "../../../hooks";
import { getUserById, editStaff } from "../../../redux/slices";
import {
  FETCH_STATUS,
  onReject,
  API_URL,
  axiosApiInstance,
  didUpdateFields,
} from "../../../utils";

const EditStaff = () => {
  let params = useParams();

  const dispatch = useDispatch();
  const { err, status, data, setResolved, setPending, setRejected } =
    useRequest();

  const {
    err: editErr,
    status: editStatus,
    setPending: editSetPending,
    setRejected: editSetRejected,
    setResolved: editSetResolved,
    resetErr,
  } = useRequest({});

  const getInfo = useCallback(async () => {
    if (params?.id) {
      try {
        setPending();
        const res = await getUserById(params.id);
        const user = {
          ...res.data.data,
          password: "Test123$OP2", //fake default password so schema doesn;t throw error
        };

        setResolved(user);
      } catch (error) {
        onReject(
          setRejected,
          error,
          `Unable to retrieve user with id ${params?.id}`
        );
      }
    }
  }, [params?.id, setRejected, setResolved, setPending]);

  const onSave = async (values) => {
    try {
      editSetPending();

      const promises = [];

      const newRole = values.roleName;
      const oldRole = data.roleName;

      const newEmail = values.email;
      const oldEmail = data.email;

      delete data.password;
      delete values.password;
      delete values.roleName;
      delete data.roleName;

      if (didUpdateFields({ ...data }, { ...values })) {
        promises.push(
          await axiosApiInstance.patch(
            `${API_URL}/Users/${params?.id}/Update`,
            values
          )
        );
      }

      if (oldRole !== newRole) {
        await axiosApiInstance.patch(
          `${API_URL}/Users/${params?.id}/ChangeRole`,
          { roleName: newRole }
        );
      }

      if (oldEmail !== newEmail) {
        await axiosApiInstance.post(`${API_URL}/Authentication/ResetEmail`, {
          userId: params?.id,
          oldEmail,
          newEmail,
        });
      }

      const res = await Promise.all(promises);

      if (res.length) {
        const info = {
          ...res[promises.length - 1].data.data,
          roleName: newRole, //not needed but just in case
        };

        dispatch(editStaff(info));
      }

      editSetResolved();
    } catch (error) {
      editSetRejected(error || "Unable to edit staff");
    }
  };

  const toggleStatus = async () => {
    try {
      editSetPending();

      const res = await axiosApiInstance.patch(
        `${API_URL}/Users/${params?.id}/Toggle`
      );

      editSetResolved();

      dispatch(editStaff(res.data.data));
    } catch (error) {
      editSetRejected(error || "Unable to edit staff status");
    }
  };

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  return (
    <PrivateRoute redirectTo={`/dashboard/users/${params?.id}`}>
      <PageHeader>
        <h1 className="text-2xl font-semibold text-white ">Edit Staff</h1>
      </PageHeader>

      <Loading
        wrapperClassName="relative min-h-[50vh]"
        show={status === FETCH_STATUS.PENDING}
      />

      <ErrorWithWrapper
        error={err}
        show={status === FETCH_STATUS.REJECTED}
        retry={getInfo}
        className="!max-w-md !mx-auto"
      />
      <Success show={status === FETCH_STATUS.RESOLVED}>
        <StaffForm
          onReset={resetErr}
          status={editStatus}
          error={editErr}
          onSave={onSave}
          isEdit
          info={data}
          toggleStatus={toggleStatus}
        />
      </Success>

      <SuccessModal
        message="Edited a user successfully."
        show={editStatus === FETCH_STATUS.RESOLVED}
        backLink={"/dashboard/users"}
      />
    </PrivateRoute>
  );
};

export { EditStaff };
