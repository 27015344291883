import React, { useEffect, useState } from "react";
import {
  FormError,
  PageHeader,
  PrivateRoute,
  Spinner,
  StatusToggler,
  SuccessModal,
} from "../../../../components";
import { useForm } from "react-hook-form";
import {
  getCinemaSettings,
  selectCinemaSettings,
} from "../../../../redux/slices/cinemaSettingSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLoggedInuser, useRequest } from "../../../../hooks";
import {
  API_URL,
  FETCH_STATUS,
  axiosApiInstance,
  trueFalse,
} from "../../../../utils";
import { Spinner2 } from "../../../../components/UI/ItemsComponents/Spinner2";

const CinemaSetting = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const { cinemaSettingsErr, cinemaSettingsStatus } =
    useSelector(selectCinemaSettings);
  const { err, setPending, setResolved, status, resetErr, setRejected } =
    useRequest();
  const { profile } = useLoggedInuser();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      includeMerchantCopy: "true",
      stockControl: "true",
      seatAllocation: "false",
      stockOversell: "true",
      cinemaSettingId: "",
      posLayout: "true",
      cinemaLogo: "false",
      includeChannelsOnTicket: "false",
      customerReceipts: "0",
      includeTax: "false",
      automaticOfflineUpload: "false",
      orderCreation: "0",
    },
  });

  useEffect(() => {
    dispatch(
      getCinemaSettings({
        cinemaId: profile?.cinemaId,
        circuitId: profile?.circuitId,
      })
    )
      .unwrap()
      .then((cinemaSetting) => {
        if (cinemaSetting) {
          reset({
            includeMerchantCopy: cinemaSetting?.includeMerchantCopy?.toString(),
            stockControl: cinemaSetting?.stockControl?.toString(),
            seatAllocation: cinemaSetting?.seatAllocation?.toString(),
            stockOversell: cinemaSetting?.stockOversell?.toString(),
            posLayout: cinemaSetting?.posLayout?.toString(),
            cinemaLogo: cinemaSetting?.cinemaLogo?.toString(),
            includeChannelsOnTicket:
              cinemaSetting?.includeChannelsOnTicket?.toString(),
            cinemaSettingId: cinemaSetting?.id,
            customerReceipts: cinemaSetting?.customerReceipts.toString(),
            includeTax: cinemaSetting?.includeTax?.toString(),
            automaticOfflineUpload:
              cinemaSetting?.automaticOfflineUpload?.toString(),
            orderCreation: cinemaSetting?.orderCreation?.toString(),
          });
        } else {
          reset({
            includeMerchantCopy: "true",
            stockControl: "true",
            seatAllocation: "false",
            stockOversell: "true",
            cinemaSettingId: "",
            posLayout: "true",
            cinemaLogo: "false",
            includeChannelsOnTicket: "false",
            customerReceipts: "0",
            includeTax: "false",
            automaticOfflineUpload: "false",
            orderCreation: "0",
          });
        }
      });
  }, [dispatch, profile?.cinemaId, profile?.circuitId, reset]);

  const onSubmit = async (data) => {
    const body = {
      includeMerchantCopy: trueFalse[data.includeMerchantCopy],
      stockControl: trueFalse[data.stockControl],
      seatAllocation: trueFalse[data.seatAllocation],
      stockOversell: trueFalse[data.stockOversell],
      posLayout: trueFalse[data.posLayout],
      cinemaLogo: trueFalse[data.cinemaLogo],
      includeChannelsOnTicket: trueFalse[data.includeChannelsOnTicket],
      customerReceipts: +data.customerReceipts,
      includeTax: trueFalse[data.includeTax],
      orderCreation: +data.orderCreation,
      automaticOfflineUpload:trueFalse[data.automaticOfflineUpload]
    };
    try {
      setPending();
      await axiosApiInstance.patch(
        `${API_URL}/CinemaSettings/${data.cinemaSettingId}/UpdateCinemaSetting`,
        body
      );
      setResolved();
      setShowSuccess(true);
    } catch (error) {
      let errMsgs = "Unable to update meta data";

      setRejected(errMsgs);
    }
  };
  return (
    <>
      <PrivateRoute redirectTo="/dashboard/settings/cinema-setting">
        <FormError
          className="fixed z-20 transform -top-4 left-2/4 -translate-x-2/4"
          err={err}
          onDismiss={() => resetErr}
        />
        <FormError
          className="fixed z-20 transform -top-4 left-2/4 -translate-x-2/4"
          err={cinemaSettingsErr}
          onDismiss={() => {}}
        />

        <PageHeader className="!flex !justify-center">
          <h1 className="text-2xl font-semibold text-white">Cinema Settings</h1>
        </PageHeader>
        <form onSubmit={handleSubmit(onSubmit)} className="">
          <div className="bg-app-purple-2 p-3 pt-[30px] rounded-b-lg flex justify-center">
            {cinemaSettingsStatus === FETCH_STATUS.PENDING && (
              <div className="flex items-center justify-center p-3">
                {" "}
                <Spinner2 className="w-20 h-20" />
              </div>
            )}
            {cinemaSettingsStatus === FETCH_STATUS.RESOLVED && (
              <>
                <div className="space-y-3">
                  <div className="flex gap-2 flex-wrap items-baseline">
                    <p className="flex justify-end sm:w-[12rem]">
                      Include Merchant copy
                    </p>
                    <StatusToggler
                      options={[
                        { key: "Yes", value: "true" },
                        { key: "No", value: "false" },
                      ]}
                      register={register}
                      statusKey="includeMerchantCopy"
                      errMsg={errors?.includeMerchantCopy?.message}
                    />
                  </div>
                  <div className="flex gap-2 flex-wrap items-baseline">
                    <p className="flex justify-end sm:w-[12rem]">
                      Enable Stock
                    </p>
                    <StatusToggler
                      options={[
                        { key: "Yes", value: "true" },
                        { key: "No", value: "false" },
                      ]}
                      register={register}
                      statusKey="stockControl"
                      errMsg={errors?.stockControl?.message}
                    />
                  </div>
                  <div className="flex gap-2 flex-wrap items-baseline">
                    <p className="flex justify-end sm:w-[12rem]">
                      Stock Oversell
                    </p>
                    <StatusToggler
                      options={[
                        { key: "Yes", value: "true" },
                        { key: "No", value: "false" },
                      ]}
                      register={register}
                      statusKey="stockOversell"
                      errMsg={errors?.stockOversell?.message}
                    />
                  </div>
                  <div className="flex gap-2 flex-wrap items-baseline">
                    <p className="flex justify-end sm:w-[12rem]">
                      Enable P.O.S Layout
                    </p>
                    <StatusToggler
                      options={[
                        { key: "Yes", value: "true" },
                        { key: "No", value: "false" },
                      ]}
                      register={register}
                      statusKey="posLayout"
                      errMsg={errors?.posLayout?.message}
                    />
                  </div>
                  <div className="flex gap-2 flex-wrap items-baseline">
                    <p className="flex justify-end sm:w-[12rem]">
                      Enable Seat Allocation
                    </p>
                    <StatusToggler
                      options={[
                        { key: "Yes", value: "true" },
                        { key: "No", value: "false" },
                      ]}
                      register={register}
                      statusKey="seatAllocation"
                      errMsg={errors?.seatAllocation?.message}
                    />
                  </div>
                  <div className="flex gap-2 flex-wrap items-baseline">
                    <p className="flex justify-end sm:w-[12rem]">
                      Include Cinema Logo
                    </p>
                    <StatusToggler
                      options={[
                        { key: "Yes", value: "true" },
                        { key: "No", value: "false" },
                      ]}
                      register={register}
                      statusKey="cinemaLogo"
                      errMsg={errors?.cinemaLogo?.message}
                    />
                  </div>
                  <div className="flex gap-2 flex-wrap items-baseline">
                    <p className="flex justify-end sm:w-[12rem]">
                      Include Channels On Ticket
                    </p>
                    <StatusToggler
                      options={[
                        { key: "Yes", value: "true" },
                        { key: "No", value: "false" },
                      ]}
                      register={register}
                      statusKey="includeChannelsOnTicket"
                      errMsg={errors?.includeChannelsOnTicket?.message}
                    />
                  </div>
                  <div className="flex gap-2 flex-wrap items-baseline">
                    <p className="flex justify-end sm:w-[12rem]">Include Tax</p>
                    <StatusToggler
                      options={[
                        { key: "Yes", value: "true" },
                        { key: "No", value: "false" },
                      ]}
                      register={register}
                      statusKey="includeTax"
                      errMsg={errors?.includeTax?.message}
                    />
                  </div>
                  <div className="flex gap-2 flex-wrap items-baseline">
                    <p className="flex justify-end sm:w-[12rem]">
                      Customer Receipt
                    </p>
                    <StatusToggler
                      options={[
                        { value: "0", key: "Enabled" },
                        { value: "1", key: "Disabled" },
                        // { value: "2", key: "Variable" },
                      ]}
                      register={register}
                      statusKey="customerReceipts"
                      errMsg={errors?.customerReceipts?.message}
                    />
                  </div>
                  <div className="flex gap-2 flex-wrap items-baseline">
                    <p className="flex justify-end sm:w-[12rem]">
                      Order Creation
                    </p>
                    <StatusToggler
                      options={[
                        { value: "0", key: "Realtime" },
                        { value: "1", key: "Batched" },
                      ]}
                      register={register}
                      statusKey="orderCreation"
                      errMsg={errors?.orderCreation?.message}
                    />
                  </div>
                  <div className="flex gap-2 flex-wrap items-baseline">
                    <p className="flex justify-end sm:w-[12rem]">
                      Offline Upload
                    </p>
                    <StatusToggler
                      options={[
                        { key: "Automated", value: "true" },
                        { key: "Manual", value: "false" },
                      ]}
                      register={register}
                      statusKey="automaticOfflineUpload"
                      errMsg={errors?.automaticOfflineUpload?.message}
                    />
                  </div>

                  <div className="pt-4">
                    <button
                      className="px-2 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm flex items-center justify-center w-[20rem] py-2"
                      type="submit"
                    >
                      <span className="uppercase"> Update Cinema Setting </span>
                      <Spinner
                        className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                        status={status}
                      />
                    </button>
                  </div>
                </div>
              </>
            )}
            <SuccessModal
              show={showSuccess}
              onClick={() => setShowSuccess(false)}
              message={"Settings updated successfully"}
            />
          </div>
        </form>
      </PrivateRoute>
    </>
  );
};

export { CinemaSetting };
