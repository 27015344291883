import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { createChannelInit, createChannelSchema, trueFalse } from "../../utils";
import { CancelSaveToolbar, FormError, Input, StatusToggler } from "../UI";
import { values } from "lodash";

const PaymentChannelForm = ({ onSave, err, channel, status, resetErr }) => {
  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    register,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createChannelSchema),
    defaultValues: createChannelInit,
  });


  const handleChannelSubmit = (value) => {
    try {
      onSave({ ...value, isActive: trueFalse[value.isActive] });
    } catch (error) {}
  };
  useEffect(() => {
    if (channel) {
      reset({
        name: channel?.name,
        isActive: channel?.isActive ? "true" : "false",
      });
    }
  }, [channel]);
  return (
    <>
        <FormError
          className="absolute top-0 z-20 transform left-2/4 -translate-x-2/4"
          err={err}
          onDismiss={resetErr}
        />
      <form onSubmit={handleSubmit(handleChannelSubmit)}>
        <CancelSaveToolbar
          backPath={"/dashboard/finance/payment-channel"}
          status={status}
        />
        <section className="relative w-full px-3 py-8 bg-app-purple-2 space-y-4">
          <Input
            label="Channel:"
            id="channel"
            wrapperClass="!flex items-center justify-center gap-4"
            errorMsg={errors?.channels?.message}
            {...register(`name`)}
          />
          <StatusToggler
            options={[
              { key: "Active", value: "true" },
              { key: "Inactive", value: "false" },
            ]}
            register={register}
            statusKey="isActive"
            className="!justify-center"
            radioLabel="Status"
            errMsg=""
          />
        </section>
      </form>
    </>
  );
};

export { PaymentChannelForm };
