import { useMemo } from "react";
import Crumb from "./Crumb";
import { useLocation, useNavigate } from "react-router-dom";
import { useUniqueId } from "../../hooks";



const BreadCrumb = () => {
  const route = useLocation();

  const generateCrumbs = () => {
    // Remove any query parameters, as those aren't included in breadcrumbs
    const asPathWithoutQuery = route.pathname.split("?")[0];

    // Break down the path between "/"s, removing empty entities
    // Ex:"/my/nested/path" --> ["my", "nested", "path"]
    const asPathNestedRoutes = asPathWithoutQuery
      .split("/")
      .filter((p) => p.length > 0 && !/\d/.test(p))
      // .slice(0, -1);

    // Iterate over the list of nested route parts and build
    // a "crumb" object for each one.
    const crumblist = asPathNestedRoutes.map((subpath, idx) => {
      const href = "/" + asPathNestedRoutes.slice(0, idx + 1).join("/");
      return {
        href,
        // text: subpath,
        text: subpath.charAt(0).toUpperCase() + subpath.slice(1),
      };
    });

    // Add in a default "Home" crumb for the top-level
    return crumblist;
  };

    const breadcrumbs = useMemo(generateCrumbs, [route.pathname]);
    const crumbKey = useUniqueId(breadcrumbs.length)

  return (
    <div className="flex items-center gap-1">
      {breadcrumbs.map(({ text, href }, index) => (
        <span key={crumbKey[index]} className="flex gap-1 items-center text-[#C397C3]">
          <Crumb
            text={text}
            href={href}
            last={index === breadcrumbs.length - 1}
          />
          {index < breadcrumbs.length - 1 && ">"}
        </span>
      ))}
    </div>
  );
};

export default BreadCrumb;
