import {useEffect, useRef} from "react";
import {
    ExportExcelBtn,
    InputErr,
    OrdersBySourceAndChannelTable,
    PdfButton,
    Pdiv,
    Pdiv2,
    PrivateRoute,
    Spinner,
} from "../../../components";
import {useNavigate} from "react-router-dom";
import DatePicker from "react-datepicker";
import {DateTimeInput} from "../../../components/Films/DateTimeInput";
import {useLoggedInuser} from "../../../hooks";
import {
    getAllChannels,
    getAllSources,
    getOrdersBySourceAndChannel,
    getTicketSoldData,
    reportData,
} from "../../../redux/slices";
import {FETCH_STATUS, formatDateForApi, ordersBySourceAndChannelSchema} from "../../../utils";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Element, scroller} from "react-scroll";
import {useDispatch, useSelector} from "react-redux";

const OrdersBySourceAndChannel = () => {
  const navigate = useNavigate();
const reportRef= useRef(null)
  const {
    ordersBySourceAndChannel,
    ordersBySourceAndChannelStatus,
    channels,
    sources,
   
  } = useSelector(reportData);

  const { profile } = useLoggedInuser();
  const dispatch = useDispatch();

  const {
    register,
    control,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(ordersBySourceAndChannelSchema),
    defaultValues: {
      dateFrom: new Date().toISOString(),
      dateTo: new Date().toISOString(),
      cinemaId: profile?.cinemaId,
      sourceId: "allSources",
      channelId: "allChannels",
    },
  });

  useEffect(() => {
    dispatch(getAllChannels(true));
    dispatch(getAllSources(true));
  }, [dispatch, profile]);

  const formData = getValues();
  const onSubmit = (data) => {
     dispatch(
       getTicketSoldData({
         force: true,
         dateFrom: formatDateForApi(new Date(data.dateFrom)),
         dateTo: formatDateForApi(new Date(data.dateTo)),
         cinemaId: profile.cinemaId,
       })
     );
    dispatch(
      getTicketSoldData({
        force: true,
        dateFrom: formatDateForApi(new Date(data.dateFrom)),
        dateTo: formatDateForApi(new Date(data.dateTo)),
        cinemaId: profile.cinemaId,
      })
    );
    dispatch(
      getOrdersBySourceAndChannel({
        ...data,
        dateFrom: formatDateForApi(new Date(data.dateFrom)),
        dateTo: formatDateForApi(new Date(data.dateTo)),
        force: true,
      })
    );
    scroller.scrollTo("reportContainer", {
      duration: 1500,
      delay: 0,
      smooth: true,
      offset: -100, // Scrolls to element + 50 pixels down the page
    });
  };

  

  return (
    <PrivateRoute
      redirectTo={"/dashboard/reports/orders-by-source-and-channel"}
    >
      <div className="w-[95%] mx-auto mt-6">
        <p className="font-semi-bold px-3 pb-5">Orders by Source And Channel</p>
        <div className="flex items-center justify-between p-4 px-8 h-[50px] rounded-t-lg bg-[#C96FCC]">
          <div className="flex gap-4">
            <span className="text-xl font-semibold text-white"></span>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex items-center justify-end py-3 px-4 bg-white ">
            <div className="flex gap-3 buttongroup ">
              <button
                className="p-1 px-3 rounded-md text-[#C96FCC] bg-[#ffe583] shadow-md hover:shadow-sm"
                type="button"
                onClick={() => {
                  navigate("/dashboard/reports");
                }}
              >
                Cancel
              </button>
              <button
                className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm flex items-center"
                type="submit"
              >
                <span> Run Report </span>
                <Spinner
                  className="!w-3 !h-3 mx-1 text-white fill-pink-400"
                  status={ordersBySourceAndChannelStatus}
                />
              </button>
            </div>
          </div>
          <div className="bg-app-purple-2 p-3 pt-[30px]">
            <Pdiv label={"Date Range"}>
              <div className="flex items-center">
                <Controller
                  control={control}
                  name="dateFrom"
                  render={({ field: { value, onChange } }) => (
                    <div className="relative ">
                      <DatePicker
                        placeholderText="Start Date"
                        selected={new Date(value)}
                        onChange={(value) => onChange(new Date(value))}
                        customInput={
                          <DateTimeInput
                            className="bg-app-purple-8"
                            dateFormat={"EEE, MMM dd, yyyy"}
                          />
                        }
                      />
                    </div>
                  )}
                />

                <p className="w-[6rem] text-center"> To:</p>
                <Controller
                  control={control}
                  name="dateTo"
                  render={({ field: { value, onChange } }) => (
                    <div className="relative ">
                      <DatePicker
                        placeholderText="Start Date"
                        selected={new Date(value)}
                        onChange={(value) => onChange(new Date(value))}
                        customInput={
                          <DateTimeInput
                            className="bg-app-purple-8"
                            dateFormat={"EEE, MMM dd, yyyy"}
                          />
                        }
                      />
                    </div>
                  )}
                />
              </div>
            </Pdiv>
            <Pdiv2 label={"Source"} name={"sourceId"} {...register("sourceId")}>
              <option value={""}>Select Source</option>
              <option value={"allSources"}>All Sources</option>
              {sources?.map((source, i) => {
                return (
                  <option value={source.id} key={i}>
                    {source.name}{" "}
                  </option>
                );
              })}
            </Pdiv2>
            <InputErr err={errors?.sourceId?.message} />
            <Pdiv2
              label={"Channel"}
              name={"channelId"}
              {...register("channelId")}
            >
              <option value={""}>Select Channel</option>
              <option value={"allChannels"}>All Channels</option>
              {channels?.map((channel, i) => {
                return (
                  <option value={channel.id} key={i}>
                    {channel.name}{" "}
                  </option>
                );
              })}
            </Pdiv2>
            <InputErr err={errors?.channelId?.message} />
          </div>
        </form>

        <Element name="reportContainer">
          {ordersBySourceAndChannel?.result?.length > 0 &&
            ordersBySourceAndChannelStatus !== FETCH_STATUS.REJECTED && (
              <>
                <div className="flex justify-center gap-4 mt-5">
                  <PdfButton
                    refValue={reportRef}
                    docTitle={"orders-by-source-and-channel"}
                  />
                  <ExportExcelBtn
                    id={"ordersByS&C"}
                    name={"orders-by-source-and-channel.xlsx"}
                  />
                </div>
                {/* <div className="hidden">
                  <div id={"fullReport"}>
                    <TransactionsByUserTable2 selectedDate={selectedDate} />
                  </div>
                </div> */}

                <div ref={reportRef}>
                  <OrdersBySourceAndChannelTable
                    funcProps={{ ...formData, force: true }}
                    id={"ordersByS&C"}
                  />
                </div>
              </>
            )}
          {ordersBySourceAndChannelStatus === FETCH_STATUS.REJECTED && (
            <p className="text-center text-red-500 py-3">
              Seems an error occured, Kindly refresh and try again
            </p>
          )}
          {ordersBySourceAndChannel?.result?.length < 1 && (
            <p className="text-center py-3">No data found</p>
          )}
        </Element>
      </div>
    </PrivateRoute>
  );
};
export { OrdersBySourceAndChannel };
