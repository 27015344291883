import React from "react";

const FormGroup = ({children,className}) => {
  return <div className={`flex items-center ${className}`}>{children}</div>;
};
const Label = ({children, className}) => {
  return (
    <>
      <label className={`flex justify-end w-[10rem] mr-3 ${className}`}>
        {children}
      </label>
    </>
  );
};

const Input = React.forwardRef((props, ref) => (
  <>
    <input
      ref={ref}
      className=" rounded bg-[#d0a3d3] border-0 mb-3 h-[35px] pl-2"
      {...props}
    />
  </>
));

const Select = React.forwardRef((props, ref) => (
  <>
    <select
      ref={ref}
      className="bg-[#d0a3d3] rounded-md mb-3 w-[20rem] text-black text-sm"
      {...props}
    >
      {props.children}
    </select>
    {/* <br /> */}
  </>
));

const InputErr = ({ err, className }) => {
  if (err) {
    return (
      <p
        className={`italic  text-red-400 font-light text-[10px] ml-[10.75rem] pb-2 ${className}`}
      >
        {err}
      </p>
    );
  }

  return null;
};

export {InputErr, Select, Input, Label, FormGroup}
