import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import {
  Loading,
  PageHeader,
  ScreensTable,
  PrivateRoute,
  ErrorWithWrapper,
  SeatClassModal,
} from "../../../components";
import { getScreens, getSeatClass, selectScreens } from "../../../redux/slices";
import { FETCH_STATUS, formatDateTime } from "../../../utils";

const AllScreens = () => {
  const dispatch = useDispatch();
  const { screens, screensStatus, screensErr } = useSelector(selectScreens);
  const [openSeatClassModal, setOpenSeatClassModal] = useState(false);

  const getInfo = useCallback(
    (force) => {
      dispatch(getScreens(force));
    },
    [dispatch]
  );

  useEffect(() => {
    if (screensStatus === FETCH_STATUS.IDLE) {
      dispatch(getScreens());
    }
  }, [dispatch, screensStatus]);

  return (
    <PrivateRoute redirectTo="/dashboard/screens">
      <PageHeader>
        <h1 className=" text-white font-semibold text-2xl">Screens</h1>
        <div className="flex justify-end space-x-4">
          <button
            onClick={() => setOpenSeatClassModal(true)}
            className="px-4 py-3 text-base font-medium bg-white rounded-md 
          text-app-purple-9"
          >
            Seat Class
          </button>
          <NavLink
            to="/dashboard/screens/create"
            type="button"
            className="px-4 py-3 text-base font-medium bg-white rounded-md 
          text-app-purple-9"
          >
            Add Screen
          </NavLink>
        </div>
      </PageHeader>

      <ErrorWithWrapper
        error={screensErr}
        show={screensStatus === FETCH_STATUS.REJECTED}
        retry={getInfo}
      />
      <div className="relative ">
        <Loading
          wrapperClassName="absolute top-0 left-0 right-0 min-h-[50vh]"
          show={screensStatus === FETCH_STATUS.PENDING}
        />

        {screens?.length ? <ScreensTable screens={screens} /> : null}
      </div>
      {openSeatClassModal && (
        <SeatClassModal
          show={openSeatClassModal}
          onClose={() => setOpenSeatClassModal(false)}
        />
      )}
    </PrivateRoute>
  );
};

export { AllScreens };
