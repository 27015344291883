import React, { useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  ItemsModal,
  PrivateRoute,
  SuccessModal,
  ItemFormErrorModal,
  ActiveActionModal,
  ComponenentItemTable,
  MultiSearchSelect,
} from "../../../components";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FETCH_STATUS,
  itemSchema,
  storage,
  trueFalse,
  ITEM_GROUP,
  getSelectedItem,
  formatNumberAsCurrency,
} from "../../../utils";
import { useTable } from "react-table";
import {
  getItemClasses,
  getItemGroups,
  getVendors,
  createItem,
  getItem,
  clearItem,
  editItem,
  getAllSources,
  reportData,
  // appendAddedItem,
  // appendEditedItem,
  getItems,
  getUnitOfMeasurements,
} from "../../../redux/slices";
import { useDispatch, useSelector } from "react-redux";

import ItemDefault from "../../../assets/images/itemDefaultImg.png";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import isArray from "lodash/isArray";
import { useLoggedInuser } from "../../../hooks";

const FormGroup = (props) => {
  return <div className="flex items-center ">{props.children}</div>;
};
const Label = (props) => {
  return (
    <>
      <label className=" flex justify-end w-[10rem] mr-3">
        {props.children}
      </label>
    </>
  );
};

const Input = React.forwardRef((props, ref) => {
  // console.log({props,ref})
  return (
    <>
      <input
        // value={props.value}
        // onChange={props.onChange}
        ref={ref}
        className=" rounded bg-[#d0a3d3] border-0 mb-3 h-[35px] pl-2"
        {...props}
      />
    </>
  );
});

const Select = React.forwardRef((props, ref) => (
  <>
    <select
      ref={ref}
      className="rounded bg-[#d0a3d3] border-0 mb-3 w-[180px]"
      {...props}
    >
      {props.children}
    </select>
    <br />
  </>
));

const InputErr = ({ err, className }) => {
  if (err) {
    return (
      <p
        className={`italic  text-red-400 font-light text-[10px] ml-[10.75rem] pb-2 ${className}`}
      >
        {err}
      </p>
    );
  }

  return null;
};

const ItemForm = () => {
  const [showItemsModal, setShowItemsModal] = useState(false);
  const [selection, setSelection] = useState([]);
  const [selectedComponentItems, setSelectedComponentItems] = useState([]);
  const [showComponentModal, setShowComponentModal] = useState(false);
  const [itemCreationSuccess, setItemCreationSuccess] = useState(false);
  const [fileSelected, setFileSelected] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [itemImage, setItemImage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [formError, setFormError] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { profile } = useLoggedInuser();
  //errors in the cases below are from additional logical check and don't relate to form validation
  const {
    itemGroups,
    itemClasses,
    vendorsList,
    item,
    getItemStatus,
    getItemError,
    itemsList,
    unitOfMeasurement,
  } = useSelector((state) => state.items);
  const { sources } = useSelector(reportData);

  useEffect(() => {
    dispatch(getItemClasses());
    dispatch(getItemGroups());
    dispatch(getVendors());
    dispatch(getAllSources(true));
    dispatch(getUnitOfMeasurements());
  }, [dispatch]);

  useEffect(() => {
    if (item?.imageUrl) {
      setItemImage(item.imageUrl);
    } else {
      setItemImage(ItemDefault);
    }
  }, [item]);

  const { id, itemGroup } = params;

  const {
    register,
    reset,
    setValue,
    control,
    getValues,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(itemSchema),
    defaultValues: {
      // name: item.name || "",
      // itemGroupId: item.itemGroupId || "",
      // itemClassId: item.itemClassId || "",
      // vendorId: item.vendorId || "",
      // tax: item.tax || "",
      // unitOfMeasurementId: item.unitOfMeasurementId || "",
      // unit: item.unit || 1,
      // cost: item.cost || "",
      // price: item.price || "",
      // items: [],
      // parentItems: [],
      // isActive: item.isActive || "true",
      // imageUrl: item.imageUrl || "",
      // sourceIds: item.itemSources || [],
      // serviceArea: item.serviceArea || 0,

      name: "",
      itemGroupId: "",
      itemClassId: "",
      vendorId: "",
      tax: "",
      unitOfMeasurementId: "",
      unit: 1,
      cost: "",
      price: "",
      items: [],
      parentItems: [],
      isActive: "true",
      imageUrl: "",
      sourceIds: [],
      serviceArea: 0,
    },
  });

  // setValue(
  //   "imageUrl",
  //   "https://firebasestorage.googleapis.com/v0/b/reachcinema-8bcaf.appspot.com/o/download%20(5).jpg?alt=media&token=6bc12b23-a63a-4ed7-85d1-d5ec9a699841"
  // );

  const imageChange = (e) => {
    setFileSelected(true);
    let file = e.target.files[0];
    const storageRef = ref(storage, `${file.name}`);
    uploadBytes(storageRef, file)
      .then((snapshot) => {
        getDownloadURL(ref(storage, `${file.name}`)).then((url) => {
          if (url) {
            setValue("imageUrl", url, { shouldValidate: true });
            setFileUploaded(true);
            setItemImage(url);
            //console.log(url);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (id && itemGroup) {
      dispatch(clearItem());

      dispatch(getItem({ id, itemGroup }))
        .unwrap()
        .then((item) => {
          reset({
            ...item,
            isActive: item?.isActive?.toString(),
            sourceIds: item?.itemSources
              ? item?.itemSources?.map((item) => item?.sourceId)
              : item?.itemPackageSources?.map((item) => item?.sourceId),
            name: item?.name,
            itemGroupId: item?.itemGroupId,
            itemClassId: item?.itemClassId,
            vendorId: item?.vendorId,
            tax: item?.tax,
            unitOfMeasurementId: item?.unitOfMeasurementId,
            unit: item?.unit,
            cost: item?.cost,
            price: item?.price,
            imageUrl: item?.imageUrl,
            serviceArea: item?.serviceArea,
          });
          if (itemGroup === "Parent") {
            const formatItems = item.items.map((data) => {
              return { ...data, id: data.itemId };
            });
            setSelection(formatItems);
            // setValue('name', item?.name, {shouldValidate:true})
            // setValue('cost', item?.cost, {shouldValidate:true})
            // setValue('isActive', item?.isActive.toString(), {shouldValidate:true})
            // setValue('itemGroupId', item?.itemGroupId, {shouldValidate:true})
            // setValue('itemClassId', item?.itemClassId, {shouldValidate:true})
            // setValue('vendorId', item?.vendorId, {shouldValidate:true})
            // setValue('tax', item?.tax, {shouldValidate:true})
            // setValue('unitOfMeasurementId', item?.unitOfMeasurementId, {shouldValidate:true})
            // setValue('unit', item?.unit, {shouldValidate:true})
            // setValue('price', item?.price, {shouldValidate:true})
            // setValue('serviceArea', item?.serviceArea, {shouldValidate:true})
          }
          if (item.itemGroup === "Package") {
            const formatItems = item.items.map((data) => {
              return { ...data, id: data.itemId };
            });
            setSelection([...formatItems, ...item.parentItems]);
          }
          if (itemGroup === "Recipe") {
            const formatItems = item.components.map((data) => {
              return { ...data, id: data.itemId, name: data.item };
            });

            setSelectedComponentItems(formatItems);
          }
          // if(itemGroup) {

          // reset({
          //   // ...item,
          //   isActive: item?.isActive?.toString(),
          //   sourceIds: item?.itemSources
          //     ? item?.itemSources?.map((item) => item?.sourceId)
          //     : item?.itemPackageSources?.map((item) => item?.sourceId),
          //   name: item?.name,
          //   itemGroupId: item?.itemGroupId,
          //   itemClassId: item?.itemClassId,
          //   vendorId: item?.vendorId,
          //   tax: item?.tax,
          //   unitOfMeasurementId: item?.unitOfMeasurementId,
          //   unit: item?.unit,
          //   cost: item?.cost,
          //   price: item?.price,
          //   imageUrl: item?.imageUrl,
          //   serviceArea: item?.serviceArea,
          // });
          // }
        });
    } else {
      dispatch(clearItem());
    }
  }, [id, dispatch, reset, itemGroup, setValue]);

  const watchItemGroup = watch("itemGroupId");

  let packageTotal = 0;

  if (selection && isArray(selection)) {
    selection?.forEach((item) => {
      packageTotal += +[item.price * +item.quantity];
    });
  }

  //submit function
  const onSubmit = (data) => {
    data.isActive = trueFalse[data.isActive];
    let items = selection;
    let parentItems = [];
    let components = selectedComponentItems;
    //check to ensure two items don,t have the same name
    if (
      !id &&
      itemsList.find((item) => {
        return item.name.toLowerCase() === getValues("name").toLowerCase();
      })
    ) {
      setFormError(
        `There is already an item with the name ${getValues("name")}.`
      );
      return;
    }

    //check to ensure at least one item source is selected when item is a single item or package item

    if (
      watchItemGroup !== ITEM_GROUP.PARENT &&
      watchItemGroup !== ITEM_GROUP.COMPONENT &&
      watchItemGroup !== ITEM_GROUP.SUNDRY &&
      (getValues("sourceIds")?.length < 1 || getValues("sourceIds") === false)
    ) {
      setFormError(`Ensure at least one item source is selecteed`);
      return;
    }
    //check to ensure Vendorid is selected for single items
    if (
      (watchItemGroup === ITEM_GROUP.SINGLE ||
        watchItemGroup === ITEM_GROUP.COMPONENT) &&
      !watch("vendorId")
    ) {
      setFormError("Ensure you select a vendor");
      return;
    }
    //check to ensure unit and unit of measure are selected for single items
    if (
      (watchItemGroup === ITEM_GROUP.SINGLE ||
        watchItemGroup === ITEM_GROUP.COMPONENT) &&
      (!watch("unit") || !watch("unitOfMeasurementId"))
    ) {
      setFormError("Ensure you select Unit of measure and enter the unit");

      return;
    }
    //check to ensure price of total package is equal to summation of all package children prices
    if (
      watchItemGroup === ITEM_GROUP.PACKAGE &&
      +watch("price") !== packageTotal
    ) {
      setFormError(
        `The price for the package should be ${formatNumberAsCurrency(
          packageTotal
        )} not ${formatNumberAsCurrency(
          watch("price")
        )}. Package selling price should be equal to the sum of all its children items `
      );

      return;
    }

    // check to ensure parent or package item has at least two item types
    if (
      (watchItemGroup === ITEM_GROUP.PACKAGE ||
        watchItemGroup === ITEM_GROUP.PARENT) &&
      selection.length < 1
    ) {
      setFormError(
        "Ensure you select at least two child items for a parent or package item group or switch the item group to a single item group"
      );

      return;
    }

    //check to ensure component items and parent items don't require an image

    if (
      watchItemGroup !== ITEM_GROUP.COMPONENT &&
      !getValues("imageUrl") &&
      watchItemGroup !== ITEM_GROUP.PARENT &&
      !getValues("imageUrl") &&
      watchItemGroup !== ITEM_GROUP.SUNDRY &&
      !getValues("imageUrl")
    ) {
      setFormError("Ensure you select an image");
      return;
    }
    //check to ensure Recipe item has at least one recipe item
    if (watchItemGroup === ITEM_GROUP.RECIPE && components.length < 1) {
      setFormError("Ensure you select at least one Component Item");
      return;
    }

    //check to ensure component items are filled properly
    if (
      watchItemGroup === ITEM_GROUP.RECIPE &&
      components.find((comp) => {
        return !comp.unit || !comp.quantity || comp.unitOfMeasurementId === "";
      })
    ) {
      setFormError(
        "Ensure you select a Unit of measure for each component items and also enter a value greater than 0 for the unit and quantity"
      );
      return;
    }
    //check to ensure all children of a parent item are the same price
    // if (
    //   watchItemGroup === ITEM_GROUP.PARENT &&
    //   selection.find((child) => {
    //     return +child.price !== +watch("price");
    //   })
    // ) {
    //   const oddChild = selection.find((child) => {
    //     return +child.price !== +watch("price");
    //   });
    //   setFormError(
    //     `All children items in a parent item should have the same price as their parent.... ${oddChild.name}.... seems to have a different Price`
    //   );
    //   return;
    // }

    // Check to ensure only single items are added to a parent item
    if (
      watchItemGroup === ITEM_GROUP.PARENT &&
      selection.filter((x) => {
        return x.itemGroupId !== ITEM_GROUP.SINGLE;
      }).length > 0
    ) {
      setFormError(
        " Parent Items can only have single items as their children, Kindly confirm all added children are single items"
      );
      return;
    }
    // split selection(i.e selected items) into two arrays if itemgroup is a package item because of backend
    if (watchItemGroup === ITEM_GROUP.PACKAGE) {
      items = selection.filter((singleItem) => {
        return singleItem.itemGroupId === ITEM_GROUP.SINGLE;
      });
      parentItems = selection
        .filter((singleItem) => {
          return singleItem.itemGroupId === ITEM_GROUP.PARENT;
        })
        .map((item) => {
          return { ...item, itemParentId: item.id };
        });
    }

    if (!id) {
      dispatch(
        createItem({
          ...data,
          cinemaId: profile.cinemaId,
          items,
          parentItems,
          sourceIds: data?.sourceIds,
          components: components,
        })
      )
        .unwrap()
        .then((data) => {
          dispatch(getItems());
          setItemCreationSuccess(true);
          setSuccessMessage("Item Creation was Successful");
          reset();
          setSelection([]);
          setSelectedComponentItems([]);
          setFileSelected(false);
          setItemImage(ItemDefault);
          setFormError(false);
          return;
        });
    }
    if (id) {
      if (
        itemGroup === "Single" ||
        itemGroup === "Component" ||
        itemGroup === "Recipe" ||
        itemGroup === "Sundry"
      ) {
        dispatch(
          editItem({
            itemData: { ...data, id, components: components },
            itemGroup,
          })
        )
          .unwrap()
          .then((data2) => {
            dispatch(getItems());
            dispatch(getItem({ id, itemGroup }))
              .unwrap()
              .then((item) => {
                reset({
                  ...item,
                  sourceIds: item?.itemSources
                    ? item?.itemSources?.map((item) => item?.sourceId)
                    : item?.itemPackageSources?.map((item) => item?.sourceId),
                  isActive: item?.isActive?.toString(),
                });
              });

            setItemCreationSuccess(true);
            setSuccessMessage("Item Edit was Successful");
          });
      }
      if (itemGroup === "Parent") {
        dispatch(
          editItem({
            itemData: { ...data, id, itemList: selection },
            itemGroup,
          })
        )
          .unwrap()
          .then((data2) => {
            dispatch(
              // appendEditedItem({
              //   ...data2,
              //   itemGroup,
              //   itemClass: isObject(data2?.itemClass)
              //     ? data2.itemClass?.name
              //     : data2.itemClass.toString(),
              // })

              dispatch(getItems())
            );
            setItemCreationSuccess(true);
            setSuccessMessage("Item Edit was Successful");

            return;
          });
      }

      if (itemGroup === "Package") {
        const selectedItems = selection.filter((singleItem) => {
          return singleItem.itemGroupId === ITEM_GROUP.SINGLE;
        });
        const selectedParentItems = selection.filter((singleItem) => {
          return singleItem.itemGroupId === ITEM_GROUP.PARENT;
        });

        dispatch(
          editItem({
            itemData: {
              ...data,
              id,
              items: selectedItems,
              parentItems: selectedParentItems,
            },
            itemGroup,
          })
        )
          .unwrap()
          .then((data2) => {
            dispatch(getItem({ id, itemGroup }))
              .unwrap()
              .then((item) => {
                reset({
                  ...item,
                  sourceIds: item?.itemSources
                    ? item?.itemSources?.map((item) => item?.sourceId)
                    : item?.itemPackageSources?.map((item) => item?.sourceId),
                  isActive: item?.isActive?.toString(),
                });
              });
            const formatItems = item.items.map((data) => {
              return { ...data, id: data.itemId };
            });

            setSelection([...formatItems, ...item.parentItems]);

            dispatch(getItems());

            setItemCreationSuccess(true);
            setSuccessMessage("Item Edit was Successful");

            return;
          });
      }
    }
  };
  // console.log(selection);
  const onError = (data) => {
    // console.log(data);
  };

  const columns = useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Price",
        accessor: "price",
        //the check below helps to prevent the price of a parent item from being editable because even if its edited all its children would still have the same price and total would not balance when a user buy's a package
        Cell: (props) => {
          return (
            <input
              defaultValue={props.value}
              className="px-2 py-1 w-[100px]"
              onChange={(e) => {
                selection[props.row.id].price = +e.target.value;
              }}
            />
          );
        },
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        Cell: (props) => {
          return (
            <input
              defaultValue={props.value}
              className="px-2 py-1 w-[100px]"
              onChange={(e) => {
                selection[props.row.id].quantity = +e.target.value;
              }}
            />
          );
        },
      },
      {
        Header: "Action",
        Cell: (props) => {
          return (
            <button
              className="bg-white py-1 px-2 rounded-md text-[#a86aaa]"
              onClick={() => {
                setSelection(
                  selection.filter((item, index) => {
                    return index !== +props.row.id;
                  })
                );
              }}
            >
              Remove
              <span className="bg-[#a86aaa] text-white px-1 rounded-full ">
                X
              </span>
            </button>
          );
        },
      },
    ];
    // sonarLint says watchItemGroup is an unecessary dependency
  }, [selection]);

  const data = useMemo(() => {
    return [...selection];
  }, [selection]);

  const tableInstance = useTable({
    columns,
    data,
  });

  // <option value=ITEM_GROUP.SINGLE>Single</option>
  // <option value=ITEM_GROUP.PARENT>Parent</option>
  // <option value=ITEM_GROUP.PACKAGE>Package</option>
  // recipe itemGroupId = d6eb0b4f-cb89-4c1b-a22c-e9143cd0678b
  // component itemGroupId 76a4f000-03e1-4627-8b0a-85e569a7d5e4

  const { headerGroups, getTableProps, getTableBodyProps, rows, prepareRow } =
    tableInstance;

  return (
    <PrivateRoute
      redirectTo={
        id ? `/dashboard/items/edit/${id}` : "/dashboard/items/create"
      }
    >
      <div className="w-[95%] mx-auto mt-6">
        <div className="flex items-center justify-between p-4 px-8 rounded-t-lg bg-gradient-to-b from-app-purple-4 to-app-purple-3">
          <div className="flex gap-4">
            <span className="text-xl font-semibold text-white">
              {id ? "Edit Item" : " Create Item"}
            </span>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="flex items-center justify-end px-4 py-3 bg-white ">
            <div className="flex gap-3 buttongroup ">
              <button
                className="p-1 px-3 rounded-md text-[#C96FCC] bg-[#ffe583] shadow-md hover:shadow-sm"
                type="button"
                onClick={() => {
                  dispatch(clearItem());
                  navigate("/dashboard/items");
                }}
              >
                Cancel
              </button>
              <button
                className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm"
                type="submit"
              >
                Save
              </button>
            </div>
          </div>
          <div className="p-3 bg-app-purple-2 overflow-auto">
            <p>
              Item Details{" "}
              {id ? (
                getItemStatus === FETCH_STATUS.PENDING ? (
                  <strong>Loading ...</strong>
                ) : null
              ) : null}{" "}
            </p>

            <p className="italic my-1 text-red-400">
              {id
                ? getItemStatus === FETCH_STATUS.REJECTED
                  ? JSON.stringify(getItemError)
                  : null
                : null}{" "}
            </p>

            <div className="flex justify-between mt-5">
              <div>
                {/* <Controller
                  control={control}
                  name="name"
                  render={({ field: { onChange, value } }) => (
                    <>
                      <FormGroup>
                        <Label> Name:</Label>{" "}
                        <Input value={value} name="name" onChange={onChange} />
                        
                      </FormGroup>
                      <InputErr err={errors?.name?.message} />
                    </>
                  )}
                /> */}
                <FormGroup>
                  <Label> Name:</Label>{" "}
                  <Input name="name" {...register("name")} />
                </FormGroup>
                <InputErr err={errors?.name?.message} />
                <FormGroup>
                  <Label> Item Class:</Label>{" "}
                  <Select name={"itemClassId"} {...register("itemClassId")}>
                    <option value={""}>Select Class</option>
                    {itemClasses.length > 0 &&
                      itemClasses.map((itemClass, index) => {
                        return (
                          <option value={itemClass.id} key={itemClass.id}>
                            {itemClass.name}
                          </option>
                        );
                      })}
                  </Select>
                </FormGroup>
                <InputErr err={errors?.itemClassId?.message} />
                <FormGroup>
                  <Label> Item Group:</Label>{" "}
                  <Select name={"itemGroupId"} {...register("itemGroupId")}>
                    <option value={""}>Select Group</option>
                    {itemGroups.length > 0 &&
                      itemGroups.map((itemGroup, index) => {
                        return (
                          <option value={itemGroup.id} key={itemGroup.id}>
                            {itemGroup.name}
                          </option>
                        );
                      })}
                  </Select>
                </FormGroup>
                <InputErr err={errors?.itemGroupId?.message} />
                <FormGroup>
                  <Label> Service Area:</Label>{" "}
                  <Select name={"serviceArea"} {...register("serviceArea")}>
                    <option value={""}>Select Service Area</option>
                    <option value={0}>Cinema</option>
                    <option value={1}>Fast Food</option>
                    <option value={2}>Cafe</option>
                    <option value={3}>Arcade</option>
                  </Select>
                </FormGroup>
                <InputErr
                  err={
                    errors?.serviceArea?.message ? "Select a service Area" : ""
                  }
                />
                {/* check if item group is single component or recipe */}
                {watchItemGroup === ITEM_GROUP.SINGLE ||
                watchItemGroup === ITEM_GROUP.COMPONENT ||
                watchItemGroup === ITEM_GROUP.RECIPE ||
                watchItemGroup === ITEM_GROUP.SUNDRY ? (
                  <FormGroup>
                    <Label> Vendor:</Label>{" "}
                    <Select name={"vendorId"} {...register("vendorId")}>
                      <option value={""}>Select Vendor</option>
                      {vendorsList.length > 0 &&
                        vendorsList.map((vendor, index) => {
                          return (
                            <option value={vendor.id} key={index}>
                              {vendor.name}
                            </option>
                          );
                        })}
                    </Select>
                  </FormGroup>
                ) : null}
                <InputErr err={errors?.vendorId?.message} />
                <FormGroup>
                  <Label> Sales Tax:</Label>{" "}
                  <Input name={"tax"} {...register("tax")} />
                </FormGroup>
                <InputErr err={errors?.tax?.message} />
                <FormGroup>
                  <Label> Cost Price:</Label>{" "}
                  <Input name={"cost"} {...register("cost")} type={"number"} />
                </FormGroup>
                <InputErr err={errors?.cost?.message} />
                <FormGroup>
                  <Label> Selling Price:</Label>{" "}
                  <Input
                    name={"price"}
                    {...register("price")}
                    type={"number"}
                  />
                </FormGroup>
                <FormGroup>
                  <Label> Variable Price:</Label>{" "}
                  <input
                    type="checkbox"
                    className=" focus:!border-none text-[#C96FCC] !ring-transparent"
                    {...register("isPriceModifiable")}
                  />
                </FormGroup>
                <InputErr err={errors?.price?.message} />
                {watchItemGroup === ITEM_GROUP.SINGLE ||
                watchItemGroup === ITEM_GROUP.COMPONENT ||
                watchItemGroup === ITEM_GROUP.RECIPE ||
                watchItemGroup === ITEM_GROUP.SUNDRY ? (
                  <>
                    <FormGroup>
                      <Label> Unit of Measure:</Label>
                      <Select
                        name={"unitOfMeasurementId"}
                        {...register("unitOfMeasurementId")}
                      >
                        <option value={""}>Select Unit Of Measure</option>
                        {unitOfMeasurement?.length > 0 &&
                          unitOfMeasurement?.map((unit, index) => {
                            return (
                              <option value={unit.id} key={index}>
                                {unit.name}
                              </option>
                            );
                          })}
                      </Select>
                    </FormGroup>
                    <InputErr err={errors?.unitOfMeasurementId?.message} />
                    <FormGroup>
                      <Label> Unit:</Label>{" "}
                      <Input
                        name={"unit"}
                        {...register("unit")}
                        type={"number"}
                      />
                    </FormGroup>
                    <InputErr err={errors?.unit?.message} />
                  </>
                ) : null}

                {watchItemGroup !== ITEM_GROUP.COMPONENT && (
                  <FormGroup>
                    <Label> Item Source:</Label>{" "}
                    {/* <div className="flex">
                      {sources?.map((source) => {
                        let checkedState = false;
                        if (
                          id &&
                          (itemGroup === "Single" || itemGroup === "Recipe")
                        ) {
                          item?.itemSources?.length > 0
                            ? (checkedState = item?.itemSources?.find((x) => {
                                return x.source === source.name;
                              })
                                ? true
                                : false)
                            : (checkedState = false);
                        }

                        if (id && itemGroup === "Package") {
                          item?.itemPackageSources?.length > 0
                            ? (checkedState = item?.itemPackageSources?.find(
                                (x) => {
                                  return x.source === source.name;
                                }
                              )
                                ? true
                                : false)
                            : (checkedState = false);
                        }

                        return (
                          <div
                            className="flex items-center justify-between px-2 py-1  border-[#7E208080]"
                            key={source.id}
                          >
                            {" "}
                            <span className="text-[#C96FCC] mr-1">
                              {source.name}
                            </span>{" "}
                            <input
                              type="checkbox"
                              className=" focus:!border-none text-[#C96FCC] !ring-transparent"
                              defaultChecked={checkedState}
                              {...register("sourceIds")}
                              value={source.id}
                            />{" "}
                          </div>
                        );
                      })}
                    </div> */}
                    <Controller
                      control={control}
                      name="sourceIds"
                      render={({ field: { value, onChange } }) => {
                        return (
                          <>
                            <MultiSearchSelect
                              inputClassName=""
                              listClassName="!py-2"
                              items={sources}
                              id={"sourceIds"}
                              value={getSelectedItem(sources, value, "id")}
                              onChange={(value) =>
                                onChange(value.map((v) => v.id))
                              }
                              displayValue={(value) =>
                                value.map((v) => v.name).join(", ")
                              }
                              placeholder={`Select Sources`}
                              searchOptions={{
                                keys: ["name"],
                              }}
                            />
                          </>
                        );
                      }}
                    />
                  </FormGroup>
                )}
              </div>
              <div className="mr-8">
                <div className="bg-[#d0a3d3]  rounded p-2">
                  <p>Image</p>
                  <div
                    key={itemImage}
                    className=" w-[16rem] h-[13rem] my-3 bg-no-repeat bg-contain bg-center "
                    style={{
                      backgroundImage: `url("${itemImage}")`,
                    }}
                  ></div>
                  <input
                    type="file"
                    className="bg-[#a86aaa] w-[16rem]"
                    // {...register("imageUrl")}
                    name={"imageUrl"}
                    onChange={imageChange}
                  />
                  <div className="flex justify-center">
                    <InputErr
                      className="!m-0 !w-full"
                      err={errors?.imageUrl?.message}
                    />
                  </div>
                  <div className="flex justify-center">
                    {fileSelected && (
                      <>
                        {fileSelected && !fileUploaded && (
                          <p>Upload In-Progress...</p>
                        )}
                        {getValues("imageUrl")?.length > 0 && fileUploaded && (
                          <p>Upload Successful</p>
                        )}

                        {getValues("imageUrl")?.length < 1 && fileUploaded && (
                          <p> Oops, Upload Failed</p>
                        )}
                      </>
                    )}
                  </div>
                </div>
                {id && (
                  <div className="bg-[#d0a3d3] mt-2 flex justify-evenly rounded-md">
                    <div className="p-2 ">
                      <span>Active</span>{" "}
                      <input
                        type="radio"
                        value="true"
                        name="isActive"
                        {...register("isActive")}
                        className="focus:!border-none text-[#C96FCC] !ring-transparent"
                      />
                    </div>
                    <div className="p-2 ">
                      <span>Inactive</span>{" "}
                      <input
                        type="radio"
                        value="false"
                        name="isActive"
                        {...register("isActive")}
                        className="focus:!border-none text-[#C96FCC] !ring-transparent"
                      />
                    </div>
                    <InputErr err={errors?.isActive?.message} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>

        {/* Main Table */}
        {watchItemGroup === ITEM_GROUP.PARENT ||
        watchItemGroup === ITEM_GROUP.PACKAGE ? (
          <>
            <div className="flex items-center justify-between px-2 py-3 bg-[#a86aaa] ">
              <p className="text-white">Your Items</p>
              <button
                className="rounded border-0 bg-white p-1 text-[#C96FCC]"
                onClick={() => {
                  setShowItemsModal(true);
                }}
                type="button"
              >
                Add Item
              </button>
            </div>
            <table {...getTableProps()} className="w-full">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        className="shadow-lg p-4 border border-[#7E208080] bg-white text-left"
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);

                  return (
                    <tr
                      {...row.getRowProps()}
                      className="transition duration-300 bg-app-purple-1 hover:bg-gray-100"
                    >
                      {row.cells.map((cell) => (
                        <td
                          {...cell.getCellProps()}
                          className="p-4 border border-[#7E208080] bg-"
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {showItemsModal && (
              <ItemsModal
                show={showItemsModal}
                onClose={() => {
                  setShowItemsModal(false);
                }}
                setSelection={setSelection}
                selection={selection}
                itemGroupType={watchItemGroup}
                initialItemsArray={itemsList}
              />
            )}
          </>
        ) : null}
      </div>
      {/* table for component items */}
      {watchItemGroup === ITEM_GROUP.RECIPE && (
        <ComponenentItemTable
          selectedComponentItems={selectedComponentItems}
          setSelectedComponentItems={setSelectedComponentItems}
          itemGroup={watchItemGroup}
          showComponentModal={showComponentModal}
          setShowComponentModal={setShowComponentModal}
          useCase={"componentItems"}
        />
      )}

      {formError && (
        <ItemFormErrorModal
          onClose={() => {
            setFormError(false);
          }}
          show={formError}
          message={formError}
          onClick={() => {
            setFormError(false);
          }}
        />
      )}
      <SuccessModal
        onClose={() => {
          setItemCreationSuccess(false);
        }}
        show={itemCreationSuccess}
        message={successMessage}
        onClick={() => {
          setItemCreationSuccess(false);
        }}
      />
      <ActiveActionModal />
    </PrivateRoute>
  );
};

export { ItemForm };
