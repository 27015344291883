import React from "react";
import { NavLink } from "react-router-dom";
import { FETCH_STATUS } from "../../utils";
import { Spinner } from "./Spinner";

const CancelSaveToolbar = ({
  status,
  backPath = "/",
  children,
  hideSubmit = false,
}) => {
  return (
    <div className="flex items-center justify-end p-4 bg-app-purple-5">
      <div className="inline-flex items-center space-x-7">
        {children}
        <NavLink
          to={backPath}
          className="p-2 text-center rounded min-w-[92px] bg-app-yellow text-app-purple-6"
        >
          Cancel
        </NavLink>
        {hideSubmit ? null : (
          <button
            type="submit"
            disabled={status === FETCH_STATUS.PENDING}
            className="p-2 rounded min-w-[92px] text-app-yellow bg-app-purple-4 disabled:opacity-30 inline-flex items-center justify-center space-x-2"
          >
            <span>Save</span>
            <Spinner
              className="!w-3 !h-3 text-white fill-app-yellow"
              status={status}
            />
          </button>
        )}
      </div>
    </div>
  );
};

export { CancelSaveToolbar };
