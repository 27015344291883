import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { getTableProps } from "../../utils";

import { ClientPaginatedTable, DefaultColumnFilter, StatusFilter } from "../UI";

const StaffTable = ({ staff = [] }) => {
  const columns = useMemo(
    () => [
      {
        id: "name",
        Header: "Name",
        accessor: (row) => `${row?.lastName} ${row?.firstName}`,
        Filter: DefaultColumnFilter,
      },
      {
        Header: "User number",
        accessor: "userNumber",
      },
      {
        Header: "Status",
        accessor: "isActive",
        Filter: StatusFilter,
        Cell: ({ value }) => {
          return value ? "Active" : "Inactive";
        },
      },
      {
        id: "view",
        Header: "Actions",
        Cell: ({ row: { original } }) => {
          return (
            <div className="flex space-x-6 flex-wrap items-center">
              <NavLink
                className="border border-[#A86AAA] rounded-full p-2"
                to={`/dashboard/users/${original?.id}`}
              >
                <span className="sr-only">Edit/View</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  fill="none"
                  viewBox="0 0 19 19"
                >
                  <path
                    fill="#A86AAA"
                    fillOpacity="0.8"
                    d="M18.722 3.698l-3.42-3.42a.95.95 0 00-1.343 0L1.988 12.248a.938.938 0 00-.23.372l-1.71 5.13A.95.95 0 001.25 18.95l5.13-1.71a.941.941 0 00.37-.23l11.971-11.97a.95.95 0 00.001-1.343zM5.567 15.51L2.45 16.548l1.039-3.115 11.14-11.14 2.077 2.077-11.14 11.14z"
                  ></path>
                </svg>
              </NavLink>

              <NavLink
                className="bg-app-purple-4 p-2 rounded-md text-white"
                to={`/dashboard/users/update-pin/${original?.id}`}
              >
                Edit pin
              </NavLink>
            </div>
          );
        },
      },
    ],
    []
  );
  return (
    <ClientPaginatedTable
      columns={columns}
      filterColumnsKeys={["name", "isActive"]}
      tableData={staff}
      {...getTableProps()}
      rowClass="hover:!bg-transparent !cursor-default"
    />
  );
};

export { StaffTable };
