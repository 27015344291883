import React from "react";
import { Spinner } from "../UI";
import { useNavigate } from "react-router-dom";

// submitFor props targets the id attribute attached to the report form, for submittion
const ReportWrapper = ({
  children,
  reportName,
  goBackRoute,
  reportStatus,
  submitFor,
}) => {


  const navigate = useNavigate();
  return (
    <>
      <p className="font-semi-bold px-3 pb-5">
        {`${reportName} - Set Report Parameters`}
      </p>
      <div className="flex items-center justify-between p-4 px-8 h-[50px] rounded-t-lg bg-[#C96FCC]">
        <div className="flex gap-4">
          <span className="text-xl font-semibold text-white"></span>
        </div>
      </div>
      <div className="flex items-center justify-end py-3 px-4 bg-white ">
        <div className="flex gap-3 buttongroup ">
          <button
            className="p-1 px-3 rounded-md text-[#C96FCC] bg-[#ffe583] shadow-md hover:shadow-sm"
            type="button"
            onClick={() => {
              navigate(goBackRoute);
            }}
          >
            Cancel
          </button>
          <button
            form={submitFor}
            className="p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm flex items-center"
            type="submit"
          >
            <span> Run Report </span>
            <Spinner
              className="!w-3 !h-3 mx-1 text-white fill-pink-400"
              status={reportStatus}
            />
          </button>
        </div>
      </div>
      <div className="bg-app-purple-2 p-3 pt-[30px]">{children}</div>
    </>
  );
};

export { ReportWrapper };
